<div fxLayout="column" class="first-col"fxFlex="100" >
    <mat-card>
        <mat-card-header *ngIf="isAdd" class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Reimbursement Type </mat-card-title>

         </mat-card-header>
         <mat-card-header *ngIf="!isAdd"  class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title class="title"> Reimbursement Types </mat-card-title>
            <div style="margin-right: 2%;" >
               <button mat-icon-button style="padding-right: 40px;"(click)="Add()"  matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                  </button>
               </div>
       </mat-card-header>
        <form [formGroup]="rbsTypeForm" autocomplete="off">
            <div *ngIf="isAdd">
                <div fxLayout="column" fxFlex="70%" fxLayout.xs="column">
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                        <mat-form-field  fxFlex="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>Reimbursement Type</mat-label>
                            <input formControlName="rbs_type"  [readonly]= "isView"   matInput placeholder="" noLeadingWhitespace required maxlength="64">
                        <mat-error class="con-error" *ngIf="rbsTypeForm.controls.rbs_type.errors?.required ">
                            Please enter Reimbursement Type.
                        </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                        <mat-form-field fxFlex="100%"  class="mx-2 my-2" appearance="outline">
                            <mat-label>Name in Pay-slip</mat-label>
                            <input formControlName="name" [readonly]= "isView"      matInput placeholder="" noLeadingWhitespace numberOnly required maxlength="64">
                        <mat-error class="con-error" *ngIf="rbsTypeForm.controls.name.errors?.required ">
                            Please enter Name in payslip.
                        </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                        <mat-form-field  fxFlex="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea formControlName="notes"  [readonly]= "isView"  noLeadingWhitespace  matInput maxlength="250" placeholder="Description"></textarea>
                        <mat-error class="con-error" *ngIf="rbsTypeForm.controls.notes.errors?.required ">
                            Please enter description
                        </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div fxLayout="column"  fxFlex="30%" fxLayout.xs="column">
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                        <mat-form-field  fxFlex="100%"  class="mx-2 my-2" appearance="outline" aria-disabled="true">
                        <mat-label>Effective Date</mat-label>
                        <input matInput [matDatepicker]="effective_date"  [readonly]= "isView"  [disabled]= "isView" di [min]="minDate" laceholder="DD-MM-YYYY"   formControlName="effective_date"  (keypress)="false" required>
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="effective_date"></mat-datepicker-toggle>
                        <mat-datepicker #effective_date></mat-datepicker>
                            <mat-error class="con-error" *ngIf="rbsTypeForm.controls.effective_date.errors?.required">
                                Please enter effective date
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                      <mat-form-field fxFlex="100%" class="mx-2 my-2" appearance="outline">
                          <mat-label>Amount</mat-label>
                          <input formControlName="control_amount" type="number" [readonly]= "isView"  matInput placeholder="" appRemoveSpace required maxlength="64">
                          <mat-error class="con-error" *ngIf="rbsTypeForm.controls.control_amount.errors?.required || rbsTypeForm.controls.control_amount.errors?.['whitespace'] || rbsTypeForm.controls.control_amount.errors?.min yyyy">
                              Please enter valid amount
                          </mat-error>
                          <!-- <mat-error class="con-error" *ngIf="rbsTypeForm.controls.control_amount.invalid && (rbsTypeForm.controls.control_amount.dirty || rbsTypeForm.controls.control_amount.touched)">
                            Please enter a valid bill amount.
                          </mat-error> -->
                      </mat-form-field>
                  </div>
                    <div *ngIf="isView" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <span style="padding-top: 20px;padding-left: 20px;">
                            <mat-radio-group  formControlName="rbs_status" (change)="onChangeRadio()">
                                <mat-radio-button class="mr-2" color="primary"  [disabled]= "isView"  *ngFor="let halfs of arrayValue;index as i;"   [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                            </mat-radio-group>
                        </span>
                    </div>
                    <!-- <div *ngIf="isEdit" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                      <span style="padding-top: 20px;padding-left: 20px;">
                          <mat-radio-group  formControlName="rbs_status" (change)="onChangeRadio()">
                              <mat-radio-button class="mr-2" color="primary"  [disabled]= "isView"  *ngFor="let halfs of arrayValue;index as i;"   [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                          </mat-radio-group>
                      </span>
                  </div> -->
                </div>
            </div>
            <div *ngIf="isAdd">
              <br /><p><span class="text-danger">
                      Note: The provided amount represents the maximum limit for an employee on a monthly basis.
                      </span>
                    </p>
              <br />
          </div>
            <div *ngIf="isAdd">
                <div fxLayoutAlign="center" *ngIf="isAdd">
                    <button *ngIf="!isView" class="btn btn-primary mr-2 mb-2" [disabled]= "isView" (click)="AddData()" type="submit"><span>Submit</span></button>
                    <button class="btn btn-danger mr-2 mb-2"   (click)="cancel()"><span >Cancel</span></button>
                    <br />
                </div>
            </div>

        </form>


    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="column" *ngIf="isdata">
        <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
            <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
              <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
              </mat-form-field>
            </span>
          </div>
<form [formGroup]="rbsTypeForm" autocomplete="off" >
        <div fxLayout="column" class="table-pad">
         <div class="mat-elevation-z1  example-container">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S.No</th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                </ng-container>
                <ng-container matColumnDef="rbs_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reimbursement Type </th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.rbs_type}}&nbsp;</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name in Pay-slip </th>
                    <td mat-cell *matCellDef="let row">
                        <span >{{row.name}}&nbsp;</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="control_amount" >
                    <th mat-header-cell *matHeaderCellDef style="text-align: right !important;"> Control Amount </th>
                    <td mat-cell *matCellDef="let row" style="text-align: right;">
                        <span>{{row.control_amount | currency:"INR" }}&nbsp;</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="effective_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Effective Date </th>
                    <td mat-cell *matCellDef="let row">
                        <span >{{row.effective_date| date:'dd/MM/yyyy'}}&nbsp;</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rbs_status">
                    <th mat-header-cell *matHeaderCellDef  style="padding-left: 60px"> Status </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                        <mat-radio-group>
                            <mat-radio-button  class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;" (change)="changeStatus(halfs.id,row)" [checked]="halfs.id == row.rbs_status"  [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                        <button mat-icon-button ><mat-icon (click)="view(row)"  title="View">visibility</mat-icon> </button>
                        <button mat-icon-button><mat-icon (click)="edit(row)"    title="Edit" >edit   </mat-icon> </button>

                     </td>
                </ng-container>
                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
    </div>
</form>
    </div>


</mat-card>
</div>


