<div fxLayout="column" fxFill class="custom-pgr-height-auto first-col">
    <mat-card fxFill>
      <mat-card-header class="headerbox">
        <div fxLayout="row" fxFlex="50">
            <mat-card-title class="title"> Form 16</mat-card-title>
        </div>
        </mat-card-header>
   
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card">      
            <div fxLayout="column" fxFlex="5">
            </div>       
            <div fxLayout="column" fxFlex="90" class="custom-payroll-div">
                <form>
                    <div class="form-row">
                      <div class="form-group col-md-6">       
                        <button type="button" class="btn btn-primary">Upload File</button>
                      </div>
                    
                    </div>
                  </form>
                  <mat-table [dataSource]="dataSource"   multiTemplateDataRows class="mat-elevation-z8" style="width:100%;display:table;">
                    <!-- Date Column -->
                    <ng-container matColumnDef="Employee_Id">
                      <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Employee Id</th>
                      <td  mat-cell  *matCellDef="let element" class="custom-investment-td"> {{element.Employee_Id}} </td>
                    </ng-container>
                  
                    <!-- Month Name Column -->
                    <ng-container matColumnDef="Employee_Name">
                      <th   mat-header-cell *matHeaderCellDef class="custom-investment-th"> Employee Name </th>
                      <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.Employee_Name}} </td>
                    </ng-container>
            
                    <!-- Amount Type Column -->
                    <ng-container matColumnDef="Pan_Number">
                        <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Pan Number </th>
                        <td  mat-cell *matCellDef="let element" class="custom-investment-td">{{element.Pan_Number}}
                        </td>
                      </ng-container>
                    <!-- Active Column -->
                      <ng-container matColumnDef="Active">
                        <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Active </th>
                        <td  mat-cell *matCellDef="let element" class="custom-investment-td"> <mat-icon style="cursor: pointer;" title="View" >cloud_download</mat-icon> 
                        </td>
                      </ng-container>   
                    
                      
                    <tr mat-header-row  class="dashboard-1 custom-investment-header" *matHeaderRowDef="displayedColumns;"></tr>
                    
            
                  
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-detail-row"></tr>
                  <!-- <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row> -->
             </mat-table>
            </div>
             <div fxLayout="column" fxFlex="5">
            </div>

        </div>
    </mat-card>
</div>                    
