import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[preventLeadingZero]'
})
export class PreventLeadingZeroDirective {

  constructor(private ngControl: NgControl) {}
  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // If the value starts with '0' and is not an empty string
    if (value.startsWith('0') && value.length > 0) {
      value = value.replace(/^0+/, '');
      this.ngControl.control?.setValue(value);
    }
  }
}
