import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserData } from '../other-deduction/other-deduction.component';
import { UntypedFormBuilder } from '@angular/forms';
import { PayrollService } from '../../payroll.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-employee-pay-groups',
  templateUrl: './employee-pay-groups.component.html',
  styleUrls: ['./employee-pay-groups.component.scss']
})
export class EmployeePayGroupsComponent implements OnInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  EmployeePayGroupForm: any = UntypedFormGroup;
  Locations: any = [];
  payGroups: any = [];
  companyDBName: any;
  displayedColumns = ['id', 'empcode', 'empname', 'location', 'deptname', 'designation', 'paygroup', 'ctc', 'assignedon']
  dataSource: MatTableDataSource<UserData> = <any>[];
  pageLoading = true;
  filteredLocationList: any = [];
  filteredPayGroup: any = [];
  searchControlForLocation = new FormControl('');
  searchControlForPayGroup = new FormControl('');


  constructor(
    private companyService: CompanySettingService,
    private formBuilder: UntypedFormBuilder,
    private payrollService: PayrollService,
    private router: Router,

  ) {
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
  }

  ngOnInit(): void {

    this.EmployeePayGroupForm = this.formBuilder.group({
      employeeName: [''],
      location: [''],
      payGroup: [''],
    })
    this.getpayrollincomegroups();
    this.getWorkLocation();
    this.searchControlForLocation.valueChanges.subscribe(searchText => {
      this.filterLocationList(searchText)
    });
    this.searchControlForPayGroup.valueChanges.subscribe(searchText => {
      this.filterPayGroup(searchText)
    });
  }
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.Locations = result.data;
      this.filteredLocationList = this.Locations;
    })

  }
  getpayrollincomegroups() {
    this.payrollService.getpayrollincomegroups().subscribe((result) => {
      this.payGroups = result.data;
      this.filteredPayGroup = this.payGroups;
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  search() {
    if (this.EmployeePayGroupForm.valid) {
      let data = {
        emp_name_value: this.EmployeePayGroupForm.controls.employeeName.value,
        location_value: this.EmployeePayGroupForm.controls.location.value,
        paygroup_id_value: this.EmployeePayGroupForm.controls.payGroup.value,
      }

      if (data.emp_name_value === '') {
        data.emp_name_value = null
      }
      if (data.location_value === '') {
        data.location_value = null
      }
      if (data.paygroup_id_value === '') {
        data.paygroup_id_value = null
      }

      this.payrollService.getEmployeeDetailsByPaygroup(data).subscribe((result: any) => {

        if (result.status) {
          this.dataSource = new MatTableDataSource(result.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        }
        else {
          this.dataSource = new MatTableDataSource(result.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        }
      })
    }
  }

  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["Payroll/EmployeePayGroups"]))
  }
  filterLocationList(searchText: any) {
    this.filteredLocationList = this.Locations.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) || val.cityname.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLocationList.length <= 0) {
      this.searchControlForLocation.setValue('');
    }
  }
  filterPayGroup(searchText: any) {
    this.filteredPayGroup = this.payGroups.filter((val: any) =>
      val.group_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredPayGroup.length <= 0) {
      this.searchControlForPayGroup.setValue('');
    }
  }
}
