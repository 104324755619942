<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title">
                Branch Wise Monthly Attendance Report
            </mat-card-title>
        </mat-card-header>
        <br />
        <form fxLayout="column" [formGroup]="searchForm">
            <div fxLayout="row" fxLayout.lt-lg="column">
                <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>City-Branch </mat-label>
                        <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                            multiple #locationSelect>
                            <button mat-raised-button class="mat-primary fill text-sm"
                                (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                                *ngIf="!locationIshide">Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm"
                                (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                                Deselect All
                            </button>
                            <input matInput [formControl]="searchControlforLoc" type="text" placeholder="  Search"
                                class="search-align">
                            <mat-option *ngFor="let res of filteredLocations" [value]="res"
                                (click)="onchangeLocation(res)">
                                {{res.cityname}}-{{res.location}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                            Please select option
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="46" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Manager</mat-label>
                        <mat-select placeholder="" name="managers" formControlName="managers" required multiple
                            #managerSelect>
                            <button mat-raised-button class="mat-primary fill text-sm"
                                (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                                *ngIf="!mgrIshide">Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm"
                                (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                                Deselect All
                            </button>
                            <input matInput [formControl]="searchControlforRM" type="text" placeholder="  Search"
                                class="search-align">
                            <mat-option *ngFor="let res of filteredManagers" [value]="res"
                                (click)="onchangeManager(res)">
                                {{res.manager_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
                            Please select option
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <mat-select formControlName="Users" tabindex="1">
                            <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                                matInput>
                            <mat-option value="0"> All Employees </mat-option>
                            <mat-option *ngFor="let list of filteredEmployees"
                                [value]="list.empid">{{list.empname}}</mat-option>

                            <!-- <ng-container *ngFor="let e of employeelist">
                            <mat-option [value]="e.empid">{{ e.empname }}</mat-option>
                        </ng-container> -->
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Month and Year</mat-label>
                        <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                            placeholder="" (click)="fromDate.open()" formControlName="fromDate" />
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #fromDate startView="multi-year"
                            (monthSelected)="setMonthAndYear($event, fromDate)">
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
                        Search
                    </button>
                    <span class="line" style="color: #1898d5; font-size: 16px"></span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
                        <span>Clear</span>
                    </button>
                </div>

                <div fxFlex="50" fxLayoutAlign="end" *ngIf="List.length > 0">
                    <div style="padding-right: 2%;">
                        <span class="exlprint"> | </span>
                        <button mat-icon-button>
                            <a title="PDF" [tabIndex]="4" (keyup.enter)="exportPDF()"> <img (click)="exportPDF()"
                                    src="./assets/images/icons/pdf.png" width="20px" height="20px"></a>
                        </button>
                        <span class="exlprint"> | </span>
                        <button mat-icon-button>
                            <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()"> <img
                                    (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                            </a>
                        </button>
                    </div>
                </div>

            </div>
        </form>

        <table *ngIf="List.length > 0" class="rTable">
            <thead class="rHeader">
                <tr>
                    <th class="red-icon">A - Absent</th>
                    <th class="green-icon">P - Present</th>
                    <th class="blue-icon">W - Week-Off</th>
                    <th class="orange-icon">L -Leave</th>
                    <th class="yellow-icon">H - Holiday</th>
                    <th class="light-icon">HD - Half Day</th>
                    <th class="wp-icon">WP - Week-off Present</th>
                    <th class="hp-icon">HP - Holiday Present</th>
                </tr>
            </thead>
        </table>
        <div class="p-2" *ngIf="List.length > 0">
            <div class="example-container" style="padding: 10px" #table>
                <table style="width: 100%" *ngIf="List.length > 0">
                    <thead>
                        <tr>
                            <th><span style="font-size: 16px">S. No. </span></th>
                            <th><span style="font-size: 16px">Employee </span></th>
                            <ng-container *ngFor="let a of headersList[0]; let i = index">
                                <th>
                                    <u>{{ a | date : "d" }} </u><br />
                                    {{ headersList[1][i] | limit : 2 }}
                                </th>
                            </ng-container>
                            <th><span style="font-size: 16px">Absents</span></th>
                            <th><span style="font-size: 16px">Presents</span></th>
                            <th><span style="font-size: 16px">Late Count</span></th>
                            <th><span style="font-size: 16px">Total</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of List">
                            <tr style="width: auto">
                                <ng-container *ngFor="let e of item">
                                    <td
                                        [ngStyle]="{ 'background-color':  e == 'H' || e == 'W' ? '#E2E5DE' : '#ffffff'  }">
                                        <!-- <span [hidden]="e == 'H' || e == 'W'" [ngStyle]="{ color: getColor(e) }">
                                            {{ e }}
                                        </span> -->

                                        <span [ngStyle]="{ color: getColor(e) }">
                                            {{ e }}
                                        </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div style="text-align: center; padding-top: 20px" *ngIf="List.length === 0">
                    No data found.
                </div>
            </div>
        </div>
    </mat-card>
</div>