<div fxLayout="column" style="background-color: #ffff;">

    <mat-card-header class="">
        <mat-card-title> Reimbursement Type </mat-card-title>
    </mat-card-header>
    <div *ngIf="isadd">
        <form [formGroup]="reimbursementForm" autocomplete="off">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Reimbursement Type</mat-label>
                    <input formControlName="reimbursementType" noLeadingWhitespace onlyAlphabets matInput required maxlength="100">
                    <mat-error class="con-error" *ngIf="reimbursementForm.controls.reimbursementType.errors?.required ">
                        {{EM1}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Name in Payslip</mat-label>
                    <input formControlName="nameInSlip" matInput noLeadingWhitespace onlyAlphabets maxlength="100">
                  
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input formControlName="amount" matInput placeholder="Amount per month" required 
                    onlyNumber minlength="2" maxlength="6">
                    <mat-error class="con-error" *ngIf="reimbursementForm.controls.amount.errors?.required ||
                    reimbursementForm.controls.amount.errors?.['whitespace'] ">
                        {{EM1}}
                    </mat-error>
                </mat-form-field>

                 </div>
               <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                 <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Date </mat-label>
                    <input matInput readonly [matDatepicker]="date" placeholder="DD-MM-YYYY" formControlName="date"
                        required (click)="date.open()">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                    </mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                    <mat-error class="con-error" *ngIf="reimbursementForm.controls.date.errors?.required">
                        {{EM2}}
                    </mat-error>
                </mat-form-field>

                
               </div>
               <br>

            <div fxLayoutAlign="center">
                <button class="m-1 mat-stroked-button button-save" (click)="submit()"
                    type="submit">
                    <span>Submit</span>
                </button>

                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>

        </form>
    </div>

    <div *ngIf="isview">
        <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between center">
           <span style="padding-left: 1%">
            <mat-form-field fxFlex fxFlex.xs="100%" appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="" >
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </span>
        <span style="padding-right: 1%">
            <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue"
                (click)="add()" type="button">
                <span>Add</span><br>
            </button>
        </span>
        </div>
        <div class="tableP">
        <div class="mat-elevation-z1">
            <table mat-table  [dataSource]="dataSource" matSort >
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>

                <ng-container matColumnDef="reimbursement">
                   <th mat-header-cell *matHeaderCellDef mat-sort-header> Reimbursement Type </th>
                   <td mat-cell *matCellDef="let row" >
                 </td>
               </ng-container>

               <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount </th>
                <td mat-cell *matCellDef="let row" >
                </td>
              </ng-container>

              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Department </th>
                <td mat-cell *matCellDef="let row" >
                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective Date </th>
                <td mat-cell *matCellDef="let row" >
                </td>
              </ng-container>

                <ng-container matColumnDef="status">
                   <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status </th>
                   <td mat-cell *matCellDef="let row">
                    </td>
               </ng-container>

                <ng-container matColumnDef="action">
                   <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                  <td mat-cell *matCellDef="let row">
                   <button mat-icon-button matTooltip="Edit">
                       <mat-icon>edit</mat-icon>
                   </button>

                      <button mat-icon-button matTooltip="Save">
                       <mat-icon>save</mat-icon>
                   </button>

                    </td>
               </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6"  style="text-align: center;">No data found</td>
                </tr>
            </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

        </div>
    </div>
    </div>
</div>
