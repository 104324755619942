<div fxLayout="column" class="first-col" [formGroup]="finalSettlementForm">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title>Payroll Final Settlement</mat-card-title>
        </mat-card-header>
        <div fxLayout="row" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column"
                style="padding-left: 20px; margin-top: 10px;">
                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="employeeName" required>
                        <ng-container *ngFor=" let employee of employeeList">
                            <mat-option value="employee.id">{{employee.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="finalSettlementForm.controls.employeeName.errors?.required">
                        This Field is required
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign.xs="center center">
                    <span ngStyle.gt-md="margin-top: 5%" ngStyle.lt-lg="margin-top: 5%" ngStyle.xs="margin-top: 0px">
                        <button class="btn btn-primary mr-2 mb-2" (click)="onSelection()" tabIndex="2">
                            Search
                        </button>
                        <span class="line" style="color: #1898d5; font-size: 16px"></span>
                        <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
                            <span>Clear</span>
                        </button>
                    </span>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign.xs="center center" ngStyle.gt-md="margin-top: 2%"
                ngStyle.lt-lg="margin-top: 5%" ngStyle.xs="margin-top: 0px" fxLayoutAlign="end">
                <span>
                    <button *ngIf="displayFinalSettlement" mat-icon-button (keyup.enter)="exportPDF()" [tabIndex]="3">
                        <mat-icon class="styles" title='Download' (click)='exportPDF()'>download</mat-icon>
                    </button>
                </span>
            </div>

        </div>
        <div *ngIf="displayFinalSettlement">
            <!-- <div class="headerp">
                Payroll Final Settlement 
            </div> -->

            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                style="padding-left: 20px; margin-top: 10px;">
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee ID</mat-label>
                    <input matInput formControlName="empid" readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <input matInput formControlName="empname" readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Designation</mat-label>
                    <input matInput formControlName="designation" readonly>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                style="padding-left: 20px;">
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Date of Joining</mat-label>
                    <input matInput formControlName="dateofjoin" readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Years of Employement</mat-label>
                    <input matInput formControlName="yrsOfService" readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Date of Relieving</mat-label>
                    <input matInput formControlName="dateofrelieving" readonly>
                </mat-form-field>
            </div>

            <div class="mat-elevation-z1 example-container">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="component">
                        <th mat-header-cell *matHeaderCellDef>Components</th>
                        <td mat-cell *matCellDef="let element" style="width: 35%;">{{element.component}} </td>
                    </ng-container>
                    <ng-container matColumnDef="temp1">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> {{element.temp1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="deduction">
                        <th mat-header-cell *matHeaderCellDef> Deductions</th>
                        <td mat-cell *matCellDef="let element"> {{element.deduction}} </td>
                    </ng-container>
                    <ng-container matColumnDef="temp2">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> {{element.temp2}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <br>
            <div class="mat-elevation-z1 example-container">
                <table mat-table [dataSource]="dataSource2">
                    <ng-container matColumnDef="component">
                        <th mat-header-cell *matHeaderCellDef> Other Benefits </th>
                        <td mat-cell *matCellDef="let element" style="width: 35%;">{{element.component}} </td>
                    </ng-container>
                    <ng-container matColumnDef="temp1">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> {{element.temp1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="deduction">
                        <th mat-header-cell *matHeaderCellDef> Other Deductions</th>
                        <td mat-cell *matCellDef="let element"> {{element.deduction}} </td>
                    </ng-container>
                    <ng-container matColumnDef="temp2">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> {{element.temp2}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>

        </div>

    </mat-card>

</div>