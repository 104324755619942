<div fxLayout="column" class="first-col"fxFlex="100" >
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Masters For Status </mat-card-title>
              <div style="margin-right: 2%;" *ngIf="!isAdd" >
                 <button mat-icon-button style="padding-right: 40px;"(click)="Add()"  matTooltip="Add New">
                      <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                    </button>
                 </div>
         </mat-card-header>

         <form [formGroup]="statusForm" autocomplete="off" >
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
            <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%;padding-left: 1%;">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Masters For Status </mat-label>
                    <input formControlName="statusdata" noLeadingWhitespace matInput placeholder="" required maxlength="64">
                    <!-- <mat-error class="con-error" *ngIf="statusForm.controls.statusdata.errors?.cannotContainSpace">
                    Please enter valid data
                </mat-error> -->
                <mat-error class="con-error" *ngIf="statusForm.controls.statusdata.errors?.required">
                    Please enter status.
                </mat-error>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2"  (click)="AddData()"   type="submit">
                    <span>Submit</span>
                </button>

                <button class="btn btn-danger mr-2 mb-2"  (click)="cancel()">
                    <span >Cancel</span>
                </button>
                <br />
            </div>


        </div>
    </form>
<!-- <mat-divider></mat-divider> -->
<div fxLayout="column" *ngIf="isdata">
    <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
        <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
          <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>
<form [formGroup]="statusForm" autocomplete="off" >
    <div fxLayout="column" class="table-pad">
     <div class="mat-elevation-z1  example-container">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>

            <ng-container matColumnDef="clientStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name of the Section </th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="enable != row.id">{{row.display_name}}&nbsp;</span>
                    <mat-form-field appearance="outline" *ngIf="enable === row.id">
                        <input formControlName="statusdata" value="row.display_name" matInput placeholder="" >
                        <!-- <mat-error class="con-error" *ngIf="statusForm.controls.statusdata.errors?.required ||statusForm.controls.statusdata.errors?.['whitespace']">
                          {{msgEM1}}
                      </mat-error> -->
                      </mat-form-field>

                </td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                      <mat-radio-group  >
                        <mat-radio-button [disabled]="disableStatus(row)"  class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;" (change)="status(halfs.id,row.id,row.display_name)" [checked]="halfs.id == row.is_active"  [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                      </mat-radio-group>
                </td>
            </ng-container>


            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef></th>
                <!-- <td mat-cell *matCellDef="let row"> </td> -->
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button *ngIf="!row.is_default && isEdit" (keyup.enter)="edit($event,row)">
                    <mat-icon (click)="edit($event,row)"  title="Edit">edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!row.is_default && isDelete" (keyup.enter)="delete($event,row.id)" >
                    <mat-icon (click)="delete($event,row.id)"  title="Delete">delete</mat-icon>
                </button>
                <button mat-icon-button *ngIf="enable == row.id && isSave" (keyup.enter)="save($event,row,statusForm.controls.statusdata.value)">
                <mat-icon (click)="save($event,row,statusForm.controls.statusdata.value)"  title="Save">save</mat-icon>
            </button>
            <button mat-icon-button *ngIf="enable == row.id" (keyup.enter)="canceledit($event,row.id)"> 
                <mat-icon (click)="canceledit($event,row.id)"  title="Cancel"  >cancel</mat-icon>
            </button>
         </td>
            </ng-container>


            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
            </tr>
        </table>

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
    </div>
</div>
</form>
</div>


</mat-card>
</div>


        
    <!-- <form [formGroup]="statusForm" autocomplete="off" >
        <div fxLayout="column" fxLayout.xs="column" >
            <div fxLayout="row" fxLayout.xs="row"style="padding-top: 5%;padding-left: 7%;">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Status</mat-label>
                    <input formControlName="statusdata" matInput placeholder="" appRemoveSpace required maxlength="64">
                    <mat-error class="con-error" *ngIf="  statusForm.controls.statusdata.errors?.required">
                        Please Enter status
                    </mat-error>
                </mat-form-field>
                <div fxLayout="column" fxLayout.xs="column" >
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 5%;padding-left: 7%;">
                    <button class="btn btn-primary mr-2 mb-2"  (click)="AddData()"   type="save">
                        <span>Save</span>
                    </button>
                    </div>
                </div>
                <div fxLayout="column" fxLayout.xs="column" >
                    <div fxLayout="row" fxLayout.xs="row" style="padding-top: 5%;padding-left: 7%;">
                    <button class="btn btn-danger mr-2 mb-2"  (click)="cancel()">
                        <span >Cancel</span>
                    </button>
                    </div>
                
                </div>
            </div>
        </div>
          
        <div fxLayout="column" class="table-pad" style="padding-top: 5%;padding-left: 7%;">
             <div class="mat-elevation-z1  example-container">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef>S.No</th>
                        <td mat-cell *matCellDef="let element; let i=index;">
                            {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                    </ng-container>
                    <ng-container matColumnDef="clientStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of the Section</th>
                        <td mat-cell *matCellDef="let row" >
                            <span *ngIf="enable != row.id">{{row.display_name}}&nbsp;</span>
                            <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                <input formControlName="statusdata" value="row.display_name" matInput placeholder="" >
                              </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status </th>
                        <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                              <mat-radio-group  >
                                <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;" (change)="status(halfs.id,row.id,row.display_name)" [checked]="halfs.id == row.is_active"  [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                              </mat-radio-group>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button *ngIf="!row.is_default && isEdit" (keyup.enter)="edit($event,row)" >
                            <mat-icon (click)="edit($event,row)"  title="Edit">edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="!row.is_default && isDelete" (keyup.enter)="delete($event,row.id)" >
                            <mat-icon (click)="delete($event,row.id)"  title="Delete">delete</mat-icon>
                        </button>
                     
                        <button mat-icon-button *ngIf="enable == row.id && isSave" (keyup.enter)="save($event,row,statusForm.controls.statusdata.value)">
                        <mat-icon (click)="save($event,row,statusForm.controls.statusdata.value)"  title="Save">save</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="enable == row.id" (keyup.enter)="canceledit($event,row.id)"> 
                        <mat-icon (click)="canceledit($event,row.id)"  title="Cancel"  >cancel</mat-icon>
                    </button>
                 </td>
                    </ng-container>


                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                    </tr>
                </table>
 -->
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                <!-- <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>
    </form>

    </mat-card>
</div> -->
