import { Component, OnInit, HostListener } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { Router, RouterModule } from '@angular/router';
import { CompanySettingService } from 'src/app/services/companysetting.service';
declare var Razorpay: any;
@Component({
  selector: 'app-add-renewal-uesrs',
  templateUrl: './add-renewal-uesrs.component.html',
  styleUrls: ['./add-renewal-uesrs.component.scss'],
})
export class AddRenewalUesrsComponent implements OnInit {
  message: any = 'Not yet stared';
  paymentId = '';
  error = '';
  title = 'angular-razorpay-intergration';
  options = {
    key: 'rzp_test_AAxMUhOM5m2fuV',
    amount: '200',
    name: 'SPRYPLE',
    description: 'Web Development',
    image: 'assets/images/FavIcon.png',
    order_id: '',
    handler: function (response: any) {
      var event = new CustomEvent('payment.success', {
        detail: response,
        bubbles: true,
        cancelable: true,
      });
      window.dispatchEvent(event);
    },
    prefill: {
      name: '',
      email: '',
      contact: '',
    },
    notes: {
      address: '',
    },
    theme: {
      color: '#28acaf',
    },
  };
  addausersForm: any = UntypedFormGroup;
  renewalausersForm: any = UntypedFormGroup;
  addvalue = 0;
  renewalvalue = 0;
  disable: boolean = true;
  disablerenew: boolean = false;
  renewalhide: boolean = false;
  addusersamount: boolean = false;
  renewalusersamount: boolean = false;
  addUsersDisplayInfohide: boolean = false;
  adddisplayrenewalhide: boolean = false;
  addrenewalscreen: boolean = false;
  resumesubscription: boolean = true;
  addUsersDisplayInfoamount: any;
  addUsersPayamount: any;
  addUsersDisplayInfoUserCount: any;
  renewalDisplayInfoamount: any;
  renewaldate: any;
  resumedate: any;
  resumecount: any;
  resumeamount: any;
  resumevalidfrom: any;
  validto: any;
  clientplandetailid: any;
  resumevaliditydate: any;
  addUsersDisplayInfovaliddate: any;
  monthlycost: any;
  clientname: any;
  email: any;
  contactnumber: any;
  array: any;
  isrenewal: boolean = false;
  pipe = new DatePipe('en-US');
  validatedate: any;
  RenewValidatedate: any;
  expiryDate: any;
  activeemployeecount: any;
  getSubscriptionDetails: any = [];
  nextRenewalDate: any;
  currentplanValidityDate: any;
  nextRenewalFlag: boolean = false;
  addUser_ClientPlanDetailedId: any;
  addUser_usercount: any;
  addUser_validTo: any;
  isYearly: any = 'y';
  isMonthly: any = 'm';
  constructor(
    public datePipe: DatePipe,
    private router: Router,
    public dialog: MatDialog,
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanySettingService
  ) {

    this.getActiveEmployeesCount();
  }

  ngOnInit(): void {
    // this.getUsers();
    this.addausersForm = this.formBuilder.group({
      addexistingusers: [''],
      addusers: [''],
    });
    this.renewalausersForm = this.formBuilder.group({
      renewalexistingusers: [''],
      addrenewalusers: [''],
      renewtype: [''],
    });
  }

  handleMinus() {
    this.addUsersDisplayInfohide = false;
    this.addvalue--;
    if (this.addvalue < 1) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }
  handlePlus() {
    if (this.addvalue <= 9999) {
      this.addvalue++;
      this.disable = false;
      this.addUsersDisplayInfohide = false;
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'You can add upto 9999 users only',
      });
    }

  }

  handleMinusrenew() {
    this.renewalvalue--;
    this.renewUsersDisplayInformation();
    if (this.renewalvalue < Number(this.activeemployeecount)) {
      this.disablerenew = true;
    } else {
      this.disablerenew = false;
    }
  }
  handlePlusrenew() {
    this.renewalvalue++;
    this.renewUsersDisplayInformation();

    this.disablerenew = false;
  }
  getClientPlanDetails(id: any) {
    let data = {
      client_id_value: id,
    };

    this.adminService.getClientPlanDetails(data).subscribe((result: any) => {

      if (result.status) {
        this.currentplanValidityDate = this.datePipe.transform(result.data[0].valid_to, 'y-MM-dd');
        this.RenewValidatedate = this.datePipe.transform(result.data[0].valid_to, 'yyyy-MM-dd');

        this.addUser_ClientPlanDetailedId = result.data[0].client_plan_detail_id;
        this.addUser_usercount = result.data[0].user_count;
        this.addUser_validTo = result.data[0].valid_to;

        let comparedate = result.data[0].valid_to;
        let clientdata = result.data[0].valid_to.split('-');
        this.resumevaliditydate = clientdata[2] + '-' + clientdata[1] + '-' + clientdata[0];

        let data = {
          client_plan_detail_id: this.addUser_ClientPlanDetailedId,
          valid_to: this.addUser_validTo,
          user_count: this.addUser_usercount,
          duration_flag: this.isYearly
        }
        this.adminService.resumeSubscriptionDisplayInfo(data).subscribe((result: any) => {

          this.resumedate = result.data[0].validity;
          this.resumecount = result.data[0].user_count;
          this.resumeamount = result.data[0].amount_value;
          this.resumevalidfrom = result.data[0].valid_from;
          let data = result.data[0].validity.split('-');
          new Date().getMonth() == new Date(comparedate).getMonth() ? (this.isrenewal = false) : new Date().getMonth() + 1 ==
            new Date(data[0] + '-' + data[1] + '-' + data[2]).getMonth()
            ? (this.isrenewal = false) : (this.isrenewal = true);
          this.calculateDateDiff(this.RenewValidatedate);
          if (new Date() > new Date(comparedate)) {
            this.resumesubscription = true;
            this.addrenewalscreen = false;
            this.addusersamount = false;
            this.renewalusersamount = false;
          } else {
            this.resumesubscription = false;
            this.addrenewalscreen = true;
          }
        });
      }
      this.renewUsersDisplayInformation();
    });
  }
  /**get All users count details */
  getUsers(id: any) {
    this.adminService.getUsers(id).subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.renewalvalue = result.data[0].user_count;
        this.addausersForm.controls.addexistingusers.setValue(
          result.data[0].user_count
        );
        this.renewalausersForm.controls.renewalexistingusers.setValue(
          result.data[0].user_count
        );
      }
    });
  }
  /**renwal screen enable last date */
  enableRenewButton(date: any) {
    let data = {
      // date:this.datePipe.transform(new Date(), "y-MM-dd")
      date: date,
    };

    this.adminService.enableRenewButton(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity == 1) {
        this.renewalhide = false;
      } else if (result.status && result.data[0].validity == 0) {
        this.renewalhide = true;
        this.renewaldate = result.data[0].valid_to;
      }
    });
  }
  /**addUsersDisplayInfo for display information purpose. */
  addUsersDisplayInfo() {
    if (this.addvalue <= 9999) {
      this.addusersamount = true;
      this.renewalusersamount = false;
      this.addUsersDisplayInfoUserCount = '';
      this.addUsersDisplayInfoamount = '';
      this.addUsersDisplayInfovaliddate = '';
      this.addUsersPayamount = '';
      if (this.addvalue > 0) {
        let data = {
          client_plan_detail_id_value: this.addUser_ClientPlanDetailedId,
          valid_to_value: this.addUser_validTo,
          user_count_value: this.addvalue,
          duration_flag: this.isYearly

        };
        this.adminService.addUsersDisplayInfo(data).subscribe((result: any) => {
          if (result.status) {
            this.addUsersDisplayInfohide = true;
            this.addUsersDisplayInfoUserCount = result.data[0].user_count;
            this.addUsersDisplayInfoamount = result.data[0].amount;
            this.addUsersDisplayInfovaliddate = result.data[0].validity;
            this.addUsersPayamount = result.data[0].amount_value;
            //  let date1:any =new Date( );
            //  let date2:any = new Date(this.validatedate)
            //  let dayscount = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24))+1;
            //  this.addUsersDisplayInfoamount = Math.floor((this.addvalue*Number( this.monthlycost)*dayscount)/30);
            //  this.addUsersDisplayInfovaliddate = result.data[0].validity
          }
        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Please Add users',
        });
      }
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'You can add upto 9999 users only',
      });
    }



  }
  /**renew Users Display Information for display information purpose. */
  renewUsersDisplayInformation() {
    this.addusersamount = false;
    this.renewalDisplayInfoamount = '';
    this.renewalusersamount = true;

    let data = {
      client_renewal_detail_id_value: this.clientplandetailid,
      user_count_value: this.renewalvalue,
      valid_to_value: this.RenewValidatedate,
      duration_flag: this.isYearly,
    };

    this.adminService.renewUsersDisplayInformation(data).subscribe((result: any) => {
      this.validto = result.data[0].valid_to;
      this.renewalDisplayInfoamount = result.data[0].cost_for_renewal;

      if (result.status) {
        this.adddisplayrenewalhide = true;
        this.expiryDate = result.data[0].valid_to;
        // this.currentplanValidityDate =this.datePipe.transform(result.data[0].current_validity, 'y-MM-dd');
        let month =
          'Renew for a month. Validity will extend to ' +
          result.data[0].valid_to +
          ' and costs Rs.' +
          result.data[0].cost_for_renewal;
        let year =
          'Renew for an year. Validity will extend to ' +
          result.data[0].valid_to +
          ' and costs Rs.' +
          result.data[0].cost_for_renewal;
        // this.array=[{id:"m",value:month},{id:"y",value:year}]  Math.floor((this.renewalvalue*this.monthlycost*30)/30)
        this.array = [{ id: 'y', value: year }];
      }
    });
  }
  daysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
  }

  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/Subscription']));
  }

  paynow(flag: any) {
    this.paymentId = '';
    this.error = '';
    this.options.prefill.name = this.clientname;
    this.options.prefill.email = this.email;
    this.options.prefill.contact = this.contactnumber;

    if (this.addvalue > 0 && this.addusersamount && flag == true) {
      let dataamount: any = Math.floor(this.addUsersPayamount * 100);
      this.options.amount = dataamount; //paise
      var rzp1 = new Razorpay(this.options);
      rzp1.open();
      rzp1.on('payment.failed', (response: any) => {
        //this.message = "Payment Failed";
        // Todo - store this information in the server
        rzp1.close();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Your Payment failed.Please try again.',
        });
        //this.error = response.error.reason;
      });
    }

    else if (this.renewalusersamount && flag == false) {
      if (this.renewalausersForm.controls.renewtype.value != '') {
        let dataamount: any = Math.floor(this.renewalDisplayInfoamount * 100);
        this.options.amount = dataamount; //paise
        var rzp1 = new Razorpay(this.options);
        rzp1.open();
        rzp1.on('payment.failed', (response: any) => {
          rzp1.close();
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Your Payment failed.Please try again.',
          });
          //this.error = response.error.reason;
        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Please select yearly. ',
        });
      }
    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please Add users',
      });
    }
  }

  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    this.message = 'Success Payment';
    /** adding extra users  */
    if (this.addusersamount) {
      let totalUsers = this.renewalvalue + this.addvalue;
      let data = {
        // client_renewal_detail_id_value: this.clientplandetailid,
        client_plan_detail_id_value: this.clientplandetailid,
        valid_to_value: this.validatedate,
        user_count_value: this.addvalue,
        created_by_value: 1,
        payment_reference_number_value: event.detail.razorpay_payment_id,
        payment_date_value: this.datePipe.transform(new Date(), 'y-MM-dd'),
        payment_status_value: 'Paid',
        duration_flag: this.isYearly,
        // email data
        customerName: this.clientname,
        existUsers: this.renewalvalue,
        newUsers: this.addvalue,
        totalUsers: totalUsers,
        customerEmail: this.email
      };
      this.adminService.addUsers(data).subscribe((result: any) => {
        if (result.status) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/Admin/add-renewal-users']));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Congratulations! You have successfully added users.',
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'unable to success payment for addUsers.',
          });
        }
      });
    }
    /** current plan renewals  */
    else if (this.renewalusersamount) {
      var datePieces = this.validto.split('-');
      var preFinalDate = [datePieces[2], datePieces[1], datePieces[0]];
      let data = {
        client_plan_detail_id_value: this.clientplandetailid,
        user_count_value: this.renewalvalue,
        valid_to_value: this.validatedate,
        // renew_type: this.renewalausersForm.controls.renewtype.value,
        renew_type: this.isYearly,
        created_by_value: 1,
        payment_reference_number_value: event.detail.razorpay_payment_id,
        payment_date_value: this.datePipe.transform(new Date(), 'y-MM-dd'),
        payment_status_value: 'Paid',
        // 
        customerName: this.clientname,
        customerEmail: this.email,
        paidAmount: this.renewalDisplayInfoamount,
        expiryDate: this.expiryDate
      };

      this.adminService.renewUsers(data).subscribe((result: any) => {
        if (result.status) {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/Admin/add-renewal-users']));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Renewal successful! Thank you!',
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'unable to success payment for renewal.',
          });
        }
      });

    }
    /** resume expired subscription  */
    else if (this.resumesubscription) {
      let datas = this.resumedate.split('-');
      let data = {
        client_plan_detail_id_value: this.clientplandetailid,
        user_count_value: this.resumecount,
        amount_value: this.resumeamount,
        valid_from_value: this.resumevalidfrom,
        valid_to_value: datas[2] + '-' + datas[1] + '-' + datas[0],
        created_by_value: 1,
        payment_reference_number_value: event.detail.razorpay_payment_id,
        payment_date_value: this.datePipe.transform(new Date(), 'y-MM-dd'),
        payment_status_value: "Paid",
      };

      this.adminService.resumeSubscription(data).subscribe((result: any) => {
        if (result.status) {

          sessionStorage.removeItem('user');
          sessionStorage.removeItem('sidemenu');
          sessionStorage.removeItem('activeModule');
          sessionStorage.removeItem('token');
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Resume subscription successfully.',
          });
          var login = '/Login';
          this.router.navigate([login]);
          sessionStorage.removeItem('companyName');
          sessionStorage.clear();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to resume subscription.',
          });
        }
      });
    }
  }

  /**get all subscription details. */
  getClientSubscriptionDetails() {
    this.companyService.getClientSubscriptionDetails().subscribe((data: any) => {
      if (data.status && data.data.length != 0) {
        this.getClientPlanDetails(Number(data.data[0].client_id));
        this.getClientSubscriptionRenewalDetails(Number(data.data[0].client_id));
        this.renewalvalue = data.data[0].user_count;
        this.addausersForm.controls.addexistingusers.setValue(
          data.data[0].user_count
        );
        this.renewalausersForm.controls.renewalexistingusers.setValue(
          data.data[0].user_count
        );
        this.validto = this.datePipe.transform(data.data[0].valid_to, 'yyyy-MM-dd');
        this.validatedate = this.datePipe.transform(data.data[0].valid_to, 'yyyy-MM-dd');
        this.clientplandetailid = data.data[0].client_plan_detail_id;
        this.monthlycost = data.data[0].cost_per_user_monthly_bill;
        this.clientname = data.data[0].contact_name;
        this.email = data.data[0].company_email;
        this.contactnumber = data.data[0].mobile_number;
        let cdate = this.datePipe.transform(data.data[0].valid_to, 'yyyy-MM-dd')
        if (cdate == this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
          this.isrenewal = true;
        }
        // this.renewUsersDisplayInformation();
      }
    });
  }

  resumesubscriptionpay() {
    this.paymentId = '';
    this.error = '';
    this.options.prefill.name = this.clientname;
    this.options.prefill.email = this.email;
    this.options.prefill.contact = this.contactnumber;
    let dataamount: any = Math.floor(this.resumeamount * 100);
    this.options.amount = dataamount; //paise
    var rzp1 = new Razorpay(this.options);
    rzp1.open();
    rzp1.on('payment.failed', (response: any) => {
      //this.message = "Payment Failed";
      // Todo - store this information in the server
      rzp1.close();
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Your Payment failed.Please try again.',
      });
    });
  }

  getActiveEmployeesCount() {
    this.companyService.getActiveEmployeesCount().subscribe((data: any) => {
      if (data.status && data.data.length != 0) {
        this.getClientSubscriptionDetails();
        this.activeemployeecount = data.data[0].active_employees_count;
      }
    });
  }

  getClientSubscriptionRenewalDetails(id: any) {
    this.companyService.getSubscriptionDetailsByClientId(id).subscribe((result: any) => {
      if (result.status) {
        this.getSubscriptionDetails = result.data;
        //this.currentplanValidityDate =this.getSubscriptionDetails[0].valid_to;
        if (this.getSubscriptionDetails.length > 1) {
          this.nextRenewalFlag = true;
          let renValid = this.getSubscriptionDetails.length - 1;
          this.nextRenewalDate = this.getSubscriptionDetails[renValid].valid_to;
        }
      }
    });
  }

  back() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/Subscription"]));
  }

  calculateDateDiff(date: any) {
    var givenDate: any = new Date(date);
    var nextDate: any = new Date(date);
    nextDate.setMonth(nextDate.getMonth() - 1);
    var currentDate: any = new Date();
    var pendingDays: any = Math.floor((givenDate - currentDate) / (1000 * 60 * 60 * 24));
    this.isrenewal = !!(pendingDays > 30);
  }

  onlyNumbers(event: any) {

    if (event.keyCode >= 48 && event.keyCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  //**END */
}
