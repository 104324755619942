<div fxLayout="column" fxFill class="custom-pgr-height-auto first-col">
    <mat-card fxFill>
      <mat-card-header class="headerbox">
        <div fxLayout="row" fxFlex="50">
            <mat-card-title class="title"> Form 16</mat-card-title>
        </div>
        </mat-card-header>
    
          <div fxLayout="row" fxLayout.xs="column" class="custom-card">      
            <div fxLayout="column" fxFlex="5">
            </div>       
            <div fxLayout="column" fxFlex="90" class="custom-payroll-div">
                <form [formGroup]="employeeFinancialYearForm" autocomplete="off">
                    <div fxLayout="row" fxLayout.xs="column" fxFlex="100" fxLayoutGap="10px">
                      <mat-form-field appearance="outline" fxFlex="28" fxFlex.xs="100">
                            <mat-label>Financial Year</mat-label>
                            <mat-select formControlName="financialYear" (selectionChange)="status()">
                                <mat-option value="0">Financial Year 1</mat-option>
                                <mat-option value="1">Financial Year 2</mat-option>                                   
                            </mat-select>
                        </mat-form-field>
                      <div  *ngIf="isShowDownloadView !=''" style="padding-top: 1%;">   
                        <span>    
                        <button class="btn btn-primary custom mr-2 mb-2" (click)="download()" >Download</button>
                        <button  class="btn btn-danger mr-2 mb-2" (click)="cancel()">Cancel</button>
                      </span>
                      </div>
                     
                    </div>
                  </form>
          
            </div>
             <div fxLayout="column" fxFlex="5">
            </div>

        </div>
    </mat-card>
</div>                    
