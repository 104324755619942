<div fxLayout="column" class="first-col">
    <mat-card>

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Announcements </mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isview">
                <button mat-icon-button style="padding-right: 40px;" [tabIndex]="[1]" (click)="Add()"
                    matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <form [formGroup]="announcementForm" autocomplete="off">
            <div *ngIf="ishide" class="tableP">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <mat-form-field fxFlex.xs="100" fxFlex="30" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Topic</mat-label>
                        <mat-select formControlName="topic" [disabled]="iseditingdata" required>
                            <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                class="search-align">
                            <ng-container>
                                <div *ngFor="let a of filteredList">
                                    <mat-option [value]="a.id">{{ a.topic }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error"
                            *ngIf="announcementForm.controls.topic.errors?.required || announcementForm.controls.topic.errors?.['whitespace']">
                            {{ EM2 }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.xs="100" fxFlex="30" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Title</mat-label>
                        <input formControlName="title" required [readonly]="isviewdata" matInput placeholder=""
                            maxlength="30" noLeadingWhitespace />
                        <mat-error class="con-error" *ngIf="announcementForm.controls.title.errors?.required">
                            {{ EM1 }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <mat-form-field fxFlex.xs="100" fxFlex="30" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Publish From </mat-label>
                        <input matInput readonly required [matDatepicker]="date" [disabled]="isviewdata" [min]="min"
                            placeholder="DD-MM-YYYY" formControlName="fromdate" (click)="date.open()" />

                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                        </mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                        <mat-error class="con-error" *ngIf="announcementForm.controls.fromdate.errors?.required">
                            {{ EM2 }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100%" fxFlex="30" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Publish To</mat-label>
                        <input required matInput readonly [matDatepicker]="todate" [disabled]="isviewdata||istodate"
                            [min]="minAnounceDate" placeholder="DD-MM-YYYY" formControlName="todate"
                            (click)="todate.open()" />
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="todate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #todate></mat-datepicker>
                        <mat-error class="con-error" *ngIf="announcementForm.controls.todate.errors?.required">
                            {{ EM2 }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <label style="margin-left: 10px">Description</label><br />

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <angular-editor style="margin-left: 10px" [config]="config" [disabled]="isviewdata"
                        formControlName="description"></angular-editor><br />
                </div>
                <mat-error class="con-error" style="font-size: 11px; padding-left: 1%" *ngIf="
            issubmit && announcementForm.controls.description.errors?.required
          ">
                    {{ EM1 }}
                </mat-error>
                <br>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2 custom" type="submit" (click)="draft()"
                        *ngIf="issavedraft">
                        <span>Save as draft</span>
                    </button>
                    <button class="btn btn-primary mr-2 mb-2" type="submit" *ngIf="isedit" (click)="submit()">
                        <span>Update</span>
                    </button>
                    <button class="btn btn-primary mr-2 mb-2" type="submit" *ngIf="ispublish" (click)="submit()">
                        <span>Submit</span>
                    </button>
                    <button class="btn btn-primary mr-2 mb-2 custom" type="submit" *ngIf="isrepublish"
                        (click)="submit()">
                        <span style="text-align: center">RePublish</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="close()">
                        <span *ngIf="isviewdata">Close</span>
                        <span *ngIf="!isviewdata">Cancel</span>
                    </button>
                    <br />
                </div>
            </div>
        </form>

        <div fxLayout="column" *ngIf="isview">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" [tabIndex]="[2]" (keyup.enter)="applyFilter($event)"
                            matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </span>
            </div>
            <div fxLayout="column" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="topic">
                            <th mat-header-cell *matHeaderCellDef>Topic</th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{ row.topic }} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{ row.title }} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="publishedon">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Published From</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span>{{ row.fromdate | date : "dd-MM-yyyy" }} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="publishedTo">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Published To</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span>{{ row.todate | date : "dd-MM-yyyy" }} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{ row.status }} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="edit($event, row)"
                                    *ngIf="row.status == 'Pending'">

                                    <mat-icon title="Edit" (click)="edit($event, row)"
                                        *ngIf="row.status == 'Pending'">edit</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="edit($event, row)"
                                    *ngIf="row.status == 'Published'">

                                    <mat-icon title="Edit" (click)="edit($event, row)"
                                        *ngIf="row.status == 'Published'">edit</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="view($event, row)">

                                    <mat-icon title="View" (click)="view($event, row)">visibility</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="republish($event, row)"
                                    *ngIf="row.status == 'Completed'">

                                    <mat-icon title="Republish" (click)="republish($event, row)"
                                        *ngIf="row.status == 'Completed'">publish</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="delete($event, row)"
                                    *ngIf="row.status == 'Pending'">

                                    <mat-icon class="mr-2" title="Delete" (click)="delete($event, row)"
                                        *ngIf="row.status == 'Pending' ">delete</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="delete($event, row)"
                                    *ngIf="row.status == 'Published'">

                                    <mat-icon title="Stop" (click)="stop($event, row)"
                                        *ngIf="row.status == 'Published'">not_interested</mat-icon>
                                </button>
                                <!-- <mat-icon title="Publish"  (click)="republish($event,row)" *ngIf="row.status=='Save as draft'">publish</mat-icon> -->
                            </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>

    </mat-card>
</div>