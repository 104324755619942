<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="approveCabinForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header fxLayoutAlign="space-between center">
                <mat-card-title> Approve Cabin</mat-card-title>
                <span *ngIf="isAdd"  (click)="cancel()" style="padding-right: 10px;"><mat-icon>arrow_back</mat-icon></span>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
         <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Applied Date</mat-label>                                
                    <input matInput [matDatepicker]="picker" formControlName="appliedDate">                                
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> 
                </mat-form-field>
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Reporting Manager</mat-label>
                    <mat-select required formControlName="reportManager" required>
                        <ng-container>
                            <div *ngFor="let b of reportManagerList">
                              <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                         </ng-container>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <input type="text" formControlName="empName"  matInput readonly>
                </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Cabin ID/Name</mat-label>
                    <input type="text" formControlName="cabinId"  matInput readonly>
                </mat-form-field>
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>New Cabin ID/Name</mat-label>
                    <mat-select required formControlName="newCabinId" required>
                        <ng-container>
                            <div *ngFor="let b of cabinsList">
                                 <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                          </ng-container>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Remarks</mat-label>
                  <textarea formControlName="remarks" matInput placeholder="Remarks" maxlength="100"></textarea>
                </mat-form-field>
             </div>

            <div fxLayoutAlign="center">
                <button class="m-1 mat-stroked-button button-save"
                    (click)="approveRequest()" type="submit">
                    <span>Approve</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="rejectAsset()">
                    <span>Reject</span>
                </button>
             <br />
            </div>
        </div>
        </mat-card>

    </form>
      <div fxLayout="column" class="tableP" *ngIf="isdata">
        <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">
            <span style="padding-bottom: 5px; padding-left: 10px;">
                <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
                 </span>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="cname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Applied Date</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cabin </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="editRequest(row)">
                            visibility</mat-icon>
                     
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>