import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-halfday-dialogbox',
  templateUrl: './halfday-dialogbox.component.html',
  styleUrls: ['./halfday-dialogbox.component.scss']
})
export class HalfdayDialogboxComponent implements OnInit {
halfdayForm!:UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<HalfdayDialogboxComponent>,
 
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder,) { 

    }
  regularType:any = [ {id:1,name:"Attendance"},{id:2,name:"Leave"} ]
  halfDayType!:string;
  isAttendance:boolean=false;
  ngOnInit(): void {
    this.halfdayForm = this.formBuilder.group({
      regType:['']
    })
  
    switch (this.data.halfDaytype) {
      case 'A1':
        this.halfDayType = "First Half Attendance"
          break;
      case 'A2':
        this.halfDayType = "Second Half Attendance"
          break;
      case 'L1':
        this.halfDayType = "First Half Leave"
          break;
      case 'L2':
        this.halfDayType = "Second Half Leave"
          break;
    }
  }
  
  submit(){
    if(this.halfdayForm.valid){
      if(this.halfdayForm.controls.regType.value == '1'){
        this.attendanceSubmit();

      } else if(this.halfdayForm.controls.regType.value == '2') {
        this.leaveSubmit();
      }
    }
  }
  attendanceSubmit(){
    this.dialogRef.close('attendance');
  }
  leaveSubmit(){
    this.dialogRef.close('leave');
  }
}
