<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Approval For Permissions </mat-card-title>
    </mat-card-header>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"
        style="padding-top: 5px;height: 70px;">
        <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
          <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
          <mat-label>Search </mat-label>
          <input (keyup)="applyFilter($event)" [tabIndex]="1" matInput placeholder="" maxlength="100" />
        </mat-form-field>
      </div>
      <div fxLayout="column" class="table-pad">
        <form [formGroup]="permissionsForm" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
          <div class="mat-elevation-z1 example-container">
            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> S. No. </th>
                <td mat-cell *matCellDef="let row;let i = index;">{{i+1}} </td>
              </ng-container>

              <ng-container matColumnDef="usage_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.usage_date | date:'dd-MM-yyyy'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="employee_code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. ID </th>
                <td mat-cell *matCellDef="let row"> {{row.empcode}}</td>
              </ng-container>

              <ng-container matColumnDef="employee_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp. Name </th>
                <td mat-cell *matCellDef="let row"> {{row.employee_name}}</td>
              </ng-container>

              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Designation </th>
                <td mat-cell *matCellDef="let row"> {{row.designation}}</td>
              </ng-container>

              <ng-container matColumnDef="permission_duration">
                <th mat-header-cell *matHeaderCellDef class="no-break">Permission Duration</th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="enable != row.id">{{ row.duration}} Minutes </span>
                  <span *ngIf="enable === row.id">
                    <mat-form-field appearance="outline">
                      <input formControlName="permissionDuration" matInput placeholder="{{ row.duration  }}"
                        maxlength="100" onlyNumber maxlength="3" />
                    </mat-form-field>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">

                  <button mat-icon-button [tabIndex]="10" (keyup.enter)="editRequest($event,row)"
                    *ngIf="enable != row.id ">
                    <mat-icon class="mr-2" title="Edit" (click)="editRequest($event,row)">
                      edit</mat-icon>
                  </button>


                  <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="acceptApproval($event,row)"
                    *ngIf="enable != row.id">
                    <mat-icon class="mr-1" title="Approve"
                      (click)="acceptApproval($event,row)">check_circle_outline</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="rejectApproval($event,row)"
                    *ngIf="enable != row.id">
                    <mat-icon class="mr-1" title="Reject" (click)="rejectApproval($event,row)">highlight_off</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="saveApproval(row)" *ngIf="enable == row.id">
                    <mat-icon class="mr-1" title="Save" (click)="saveApproval(row)">save</mat-icon>
                  </button>

                  <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="cancel()" *ngIf="enable == row.id">
                    <mat-icon class="mr-1" title="Cancel" (click)="cancel()">cancel</mat-icon>
                  </button>
                </td>

              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

          </div>
        </form>
      </div>
    </div>
  </mat-card>
</div>