<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Onboarding Pending Checklist </mat-card-title>
    </mat-card-header>

    <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
      <form [formGroup]="checklistForm" autocomplete="off">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center" class="dev-Pad">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Employee Name</mat-label>
            <input type="text" formControlName="employeeName" matInput placeholder="" readonly />
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Joining Date</mat-label>
            <input type="text" readonly formControlName="joiningDate" matInput placeholder="" />
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Designation</mat-label>
            <input type="text" readonly formControlName="designation" matInput placeholder="" />
          </mat-form-field>
        </div>

        <div fxLayout="column" class="table-pad">
          <div class="mat-elevation-z1 example-container">
            <mat-table #table [dataSource]="checklistDataSource" matSort>

              <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef>S.No</mat-header-cell>
                <mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="dept">
                <mat-header-cell *matHeaderCellDef>Department</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.department_name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="approver">
                <mat-header-cell *matHeaderCellDef>Approver Name</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.approvername}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="checklist">
                <mat-header-cell *matHeaderCellDef>Checklist</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.checklist_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="toggle($event, row)"
                    [checked]="row.status == 'Completed'" [disabled]="row.status == 'Completed'">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>


              <mat-header-row class="title" *matHeaderRowDef="checklistdisplayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row;columns:checklistdisplayedColumns" (click)="selection.toggle(row)"></mat-row>
            </mat-table>
          </div>
          <section style="padding-top: 1%;padding-left:7px">
            <mat-checkbox class="example-margin" [disabled]="isdisable" (change)="change()" formControlName="isChecked"
              [(ngModel)]="checked">No Due</mat-checkbox>
          </section>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
              <mat-label>Add Comments</mat-label>
              <input type="text" formControlName="remarks" matInput placeholder="" maxlength="250" />
            </mat-form-field>
          </div>
        </div>

        <div fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" (click)="saveRequest()" type="submit">
            <span>Submit</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
            <span>Cancel</span>
          </button>
          <br />
        </div>

      </form>
    </div>



    <div fxLayout="column" *ngIf="isdata">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayout.lt-lg="column" class="dev-Pad">
        <div fxLayout="row" fxLayout.xs="column">
          <form [formGroup]="hrOnboardingForm" autocomplete="off" fxFlex.lt-lg="50">

            <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
              <mat-label>Joining Date</mat-label>
              <input matInput readonly [matDatepicker]="searchDate" [min]="minDate" [max]="maxDate"
                placeholder="DD/MM/YYYY" formControlName="searchDate" (click)="searchDate.open()" [tabIndex]="1">
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="searchDate"></mat-datepicker-toggle>
              <mat-datepicker #searchDate></mat-datepicker>
            </mat-form-field>
          </form>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" (keyup.enter)="applyFilter($event)" matInput
              placeholder="" maxlength="100">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
          </mat-form-field>
        </div>
        <span ngStyle.gt-md="padding-top: 9px;" fxLayoutAlign.lt-lg="center">
          <button class="btn btn-danger mr-2 mb-2" [tabIndex]="3" (keyup.enter)="cancel()" (click)="cancel()">
            <span>Clear</span>
          </button>
        </span>
      </div>

      <div fxLayout="column" *ngIf="isdata" class="table-pad">
        <div class="mat-elevation-z1 example-container">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef> S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name </th>
              <td mat-cell *matCellDef="let element"> {{element.empname}} </td>
            </ng-container>

            <ng-container matColumnDef="hiredate">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Hired Date </th>
              <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.hiredon |
                date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="joindate">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Joining Date </th>
              <td mat-cell *matCellDef="let element"> {{element.dateofjoin | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.final_status == 'Completed' ">
                  Completed
                </span>
                <span *ngIf="element.final_status == 'Pending Checklist' ">
                  Pending
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row;let i = index;">
                <button mat-icon-button matTooltip="Edit" [tabIndex]="i+4" (keyup.enter)="addChecklistOverview(row)"
                  *ngIf="row.final_status != 'Completed' " (click)="addChecklistOverview(row)">
                  <mat-icon>edit</mat-icon>
                </button>
                <mat-icon title="Completed" *ngIf="row.final_status == 'Completed' "></mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6" style="text-align: center;">No data found</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>