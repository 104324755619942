<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Excel Upload  </mat-card-title>
            <div style="margin-right: 2%;">
                <a mat-icon-button [href]="getExampleFileUrl()" download="excel-upload.xlsx" matTooltip="Excel Template">
                    <mat-icon>download</mat-icon>
                </a>
            </div>
          </mat-card-header>
        <mat-divider></mat-divider>
        <div class="tableP">
        <div *ngIf="!isLoading" fxLayout="column" class="mx-2 my-2">
            <!-- <h2>File Upload & Image Preview</h2> -->

            <!-- Upload  -->
            <form id="file-upload-form" class="uploader" *ngIf="isadd">
                <input id="file-upload" type="file" name="fileUpload" (change)="onChange($event)" #inputFile
                    multiple="false" accept=".xls,.xlsx" />

                <label for="file-upload" id="file-drag">
                    <img id="file-image" src="#" alt="Preview" class="hidden">
                    <div id="start">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <!-- <div>Select a file </div> -->
                        <div id="notimage" class="hidden">Please select an image</div>
                        <span id="file-upload-btn" class="btn btn-primary custom mr-2 mb-2">Select a file</span>
                    </div>
                    <div id="response" class="hidden">
                        <div id="messages"></div>
                        <progress class="progress" id="file-progress" value="0">
                            <span>0</span>%
                        </progress>
                    </div>
                </label>
            </form>
            <div fxLayout="row" fxLayout.xs="column" class="mx-2 my-2" fxLayoutAlign="center"
                fxLayoutAlign.xs="center center">


                <div id="table" class="mat-elevation-z1 example-container">
                <table class="sjs-table" fxFlex="100">
                    <tbody>
                        <tr *ngFor="let row of viewdata">
                            <td *ngFor="let val of row">
                                {{val}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isview">
                <button tabindex="13" class="m-1 mat-stroked-button button-save" (click)="SaveUploadedData();">
                    <span>Submit</span>
                </button>
                <button tabindex="23" class="m-1 mat-stroked-button button-clear" (click)="removeData()">Cancel</button>

            </div>
        </div>
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner color="primary" mode="indeterminate"
            backdropEnabled="true"
            >
            </mat-progress-spinner>
        </div>
    </div>
    </mat-card>

</div>