<div style="min-height: 700px;">
  <div *ngIf="addempdetails">
      <div fxLayout="column">
          <mat-card fxLayout="column">
              <form [formGroup]="employeeAddForm" autocomplete="off">

                  <div *ngIf="emp">
                      <mat-card-header>
                          <mat-card-title > Add Employee Details </mat-card-title>
                      </mat-card-header><br>
                      <mat-progress-bar mode="determinate" value="30"></mat-progress-bar><br>
                      <mat-divider></mat-divider>
                      <mat-card-header class="">
                          <mat-card-title > General Information </mat-card-title>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>First Name</mat-label>
                              <input type="text" formControlName="firstname" onlyAlphabets matInput placeholder="" maxlength="100"
                                  required>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.firstname.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                            <mat-label>Middle Name</mat-label>
                            <input type="text" formControlName="middlename" onlyAlphabets matInput placeholder="" maxlength="100">
                            <!-- <input *ngIf="isview"  formControlName="middlename" matInput placeholder="" > -->
                        </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Last Name</mat-label>
                              <input type="text" formControlName="lastname" onlyAlphabets matInput placeholder="" maxlength="100"
                                  required>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.lastname.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>


                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Contact Number</mat-label>
                              <input type="text" onlyNumber formControlName="contactnumber" matInput placeholder=""
                                  maxlength="10" minlength="10" required>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.contactnumber.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.contactnumber.errors?.minlength">
                                  {{msgLM2}}
                              </mat-error>
                              <!-- <input *ngIf="isview" formControlName="contactnumber" matInput placeholder="" > -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Personal Email</mat-label>
                              <input type="text" formControlName="personalemail" matInput placeholder=""
                                  maxlength="100" required>
                              <mat-error class="con-error"
                              *ngIf="employeeAddForm.controls.personalemail.errors?.required">
                              {{msgLM1}}
                          </mat-error>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.personalemail.errors?.email">
                                  {{msgLM2}}
                              </mat-error>
                              <!-- <input *ngIf="isview"  formControlName="personalemail" matInput placeholder="" > -->
                          </mat-form-field>

                      </div>

                      <mat-card-header class="">
                          <mat-card-title > Personal Information </mat-card-title>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Date of Birth</mat-label>
                              <input required matInput readonly [matDatepicker]="dateofbirth" [min]="minDate"
                                  [max]="maxBirthDate" placeholder="DD-MM-YYYY"  formControlName="dateofbirth" (click)="dateofbirth.open()">
                              <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofbirth">
                              </mat-datepicker-toggle>
                              <mat-datepicker #dateofbirth></mat-datepicker>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.dateofbirth.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Blood Group</mat-label>
                              <mat-select formControlName="bloodgroup">
                                  <ng-container>
                                      <div *ngFor="let b of bloodGroupdetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.bloodgroup}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Gender</mat-label>
                              <mat-select required formControlName="gender" required>
                                  <ng-container>
                                      <div *ngFor="let b of genderDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value="{{b.id}}">{{b.gender}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.gender.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>


                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">



                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Emergency Contact</mat-label>
                              <input type="text" onlyNumber formControlName="emergencycontact" matInput placeholder=""
                                  maxlength="10">

                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Aadhar Number</mat-label>
                              <input type="text" onlyNumber formControlName="aadharnumber" matInput placeholder=""
                                  maxlength="12">

                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Marital Status</mat-label>
                              <mat-select formControlName="maritalstatus" required>
                                  <ng-container>
                                      <div *ngFor="let b of maritalStatusDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value="{{b.id}}">{{b.maritalstatus}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeAddForm.controls.maritalstatus.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>


                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>PAN Number</mat-label>
                              <input type="text" formControlName="pan" matInput placeholder="" maxlength="10">
                              <!-- <input *ngIf="isview"  formControlName="middlename" matInput placeholder="" > -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Passport Number</mat-label>
                              <input type="text" formControlName="passport" matInput placeholder="" maxlength="12">
                              <!-- <input *ngIf="isview" formControlName="contactnumber" matInput placeholder="" > -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>ESI Number</mat-label>
                              <input type="text" onlyNumber formControlName="esi" matInput placeholder=""
                                  maxlength="17">
                              <!-- <input *ngIf="isview"  formControlName="middlename" matInput placeholder="" > -->
                          </mat-form-field>

                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">


                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>UAN Number</mat-label>
                              <input type="text" onlyNumber formControlName="uanumber" matInput placeholder=""
                                  maxlength="12">
                              <!-- <input *ngIf="isview" formControlName="contactnumber" matInput placeholder="" > -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>PF Number</mat-label>
                              <input type="text" onlyNumber formControlName="pfaccountnumber" matInput placeholder=""
                                  maxlength="22">
                              <!-- <input *ngIf="isview"  formControlName="personalemail" matInput placeholder="" > -->
                          </mat-form-field>

                      </div>


                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end">

                          <mat-checkbox formControlName="checked" color="primary">Same as residence address
                          </mat-checkbox>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Residence Address</mat-label>
                              <input type="text" formControlName="address" matInput placeholder="" maxlength="100"
                                  required>
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.address.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Permanent Address</mat-label>
                              <input type="text" formControlName="paddress" matInput placeholder="" maxlength="100">
                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>

                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Country</mat-label>
                              <mat-select required formControlName="country" required>
                                  <ng-container>
                                      <div *ngFor="let b of CountryDetails">

                                          <mat-option value={{b.id}}>{{b.country}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.country.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Country</mat-label>
                              <mat-select formControlName="pcountry">
                                  <ng-container>
                                      <div *ngFor="let b of permanentCountryDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.country}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>State</mat-label>
                              <mat-select formControlName="state" required>
                                  <ng-container>
                                      <div *ngFor="let b of stateDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.state}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.state.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>State</mat-label>
                              <mat-select formControlName="pstate">
                                  <ng-container>
                                      <div *ngFor="let b of permanentStateDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.state}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>City</mat-label>
                              <mat-select formControlName="city" required>
                                  <ng-container>
                                      <div *ngFor="let b of cityDetails">
                                          <mat-option value={{b.id}}>{{b.location}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.city.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>City</mat-label>
                              <mat-select formControlName="pcity">
                                  <ng-container>
                                      <div *ngFor="let b of permanentCityDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.location}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Pincode</mat-label>
                              <input type="text" onlyNumber formControlName="pincode" matInput placeholder=""
                              minlength="6" maxlength="6" required>
                              <mat-error class="con-error" *ngIf="employeeAddForm.controls.pincode.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                            <mat-error class="con-error" *ngIf="employeeAddForm.controls.pincode.errors?.minlength">
                              {{msgLM54}}
                            </mat-error>
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                              <mat-label>Pincode</mat-label>
                              <input type="text" onlyNumber formControlName="ppincode" matInput placeholder=""
                              minlength="6"   maxlength="6">

                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>


                      </div>
                      <div class="form-group row ml-1 mt-3" fxLayout.xs="column" fxLayoutAlign="space-between center" >
                          <div class="col-sm-6" style="padding:0px">
                              <button class="col-md-2 btn btn-danger" (click)="firstcancel()">Cancel</button>
                          </div>
                          <div class="col-sm-6 text-right">
                              <button class="col-md-2 btn btn-primary" [disabled]="!employeeAddForm.valid "
                                  (click)="firstNext()">Next</button>
                          </div>
                      </div>

                  </div>
              </form>
              <div *ngIf="family">
                  <form [formGroup]="employeefamilyAddForm" autocomplete="off">

                      <mat-card-header class="">
                          <mat-card-title > Add Employee Details </mat-card-title>
                      </mat-card-header><br>
                      <mat-progress-bar mode="determinate" value="60"></mat-progress-bar><br>
                      <mat-divider></mat-divider>
                      <mat-card-header class="">
                          <mat-card-title > Bank Information </mat-card-title>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Bank Name</mat-label>
                              <input type="text" formControlName="bankname" matInput placeholder="" maxlength="100">
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>IFSC Code</mat-label>
                              <input type="text" formControlName="ifsccode" matInput placeholder="" maxlength="10">
                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Name as per Bank account</mat-label>
                              <input type="text" formControlName="nameasperbankaccount" matInput placeholder=""
                                  maxlength="100">
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Branch Name</mat-label>
                              <input type="text" formControlName="branchname" matInput placeholder="" maxlength="100">
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Account Number</mat-label>
                              <input type="text" onlyNumber formControlName="bankaccountnumber" matInput
                                  placeholder="" maxlength="16">
                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>

                      </div>
                      <mat-divider></mat-divider>
                      <mat-card-header class="">
                          <mat-card-title > Family Details </mat-card-title>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="!isviewemployee">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>First Name</mat-label>
                              <input type="text" formControlName="familyfirstname" matInput placeholder=""
                                  maxlength="100" required>
                              <mat-error class="con-error"
                                  *ngIf="employeefamilyAddForm.controls.familyfirstname.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Last Name</mat-label>
                              <input type="text" formControlName="familylastname" matInput placeholder=""
                                  maxlength="100" required>
                              <mat-error class="con-error"
                                  *ngIf="employeefamilyAddForm.controls.familylastname.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                              <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Gender</mat-label>
                              <mat-select formControlName="familygender" required>
                                  <ng-container>
                                      <div *ngFor="let b of genderDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option [value]="b">{{b.gender}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeefamilyAddForm.controls.familygender.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>

                      </div>

                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="!isviewemployee">
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Contact Number</mat-label>
                              <input type="text" onlyNumber formControlName="familycontact" matInput placeholder=""
                                  maxlength="10">

                          </mat-form-field>

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Date of Birth</mat-label>
                              <input matInput readonly [matDatepicker]="familydateofbirth" [max]="maxDate"
                              placeholder="DD-MM-YYYY"  formControlName="familydateofbirth" (click)="familydateofbirth.open()">
                              <mat-datepicker-toggle class="datepicker" matSuffix [for]="familydateofbirth">
                              </mat-datepicker-toggle>
                              <mat-datepicker #familydateofbirth></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Relationship</mat-label>
                              <mat-select required formControlName="relation">
                                  <ng-container>
                                      <div *ngFor="let b of employeeRelationship">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option [value]="b">{{b.relationship}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeefamilyAddForm.controls.relation.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="!isviewemployee">
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Status</mat-label>
                              <mat-select required formControlName="familystatus">
                                  <ng-container>
                                      <div>
                                          <mat-option value="Alive" >Alive</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <!-- <input type="text" readonly formControlName="familystatus" matInput placeholder=""> -->
                              <mat-error class="con-error"
                                  *ngIf="employeefamilyAddForm.controls.familystatus.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                          </mat-form-field>
                          <div class="col-sm-6 mx-2 my-2 text-right">
                              <button class="col-md-2 btn btn-primary" (click)="addfamily()"><span
                                      *ngIf="isfamilyedit">
                                      Update
                                  </span>
                                  <span *ngIf="!isfamilyedit">
                                      Add
                                  </span> </button>
                              <!-- <button  class="col-md-2 btn btn-primary" (click)="addfamily()" *ngIf="isfamilyedit">Update</button> -->
                          </div>
                          <div class="col-sm-6 mx-2 my-2 text-end">
                              <button class="col-md-2 btn btn-danger" (click)="clearfamily()">Clear</button>
                          </div>

                      </div>
                  </form>

                  <div class="mat-elevation-z8" *ngIf="familyDetails.length > 0">
                      <table mat-table [dataSource]="dsFamily">
                          <ng-container matColumnDef="position">
                              <th mat-header-cell *matHeaderCellDef> SNo </th>
                              <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>
                          </ng-container>


                          <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef> Name </th>
                              <td mat-cell *matCellDef="let data"> {{data.firstname}} {{data.lastname}}</td>
                          </ng-container>


                          <ng-container matColumnDef="relation">
                              <th mat-header-cell *matHeaderCellDef> Relationship </th>
                              <td mat-cell *matCellDef="let data"> {{data.relationshipname}} </td>
                          </ng-container>
                          <ng-container matColumnDef="gender">
                              <th mat-header-cell *matHeaderCellDef> Gender </th>
                              <td mat-cell *matCellDef="let data"> {{data.gendername}} </td>
                          </ng-container>
                          <ng-container matColumnDef="contact">
                              <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                              <td mat-cell *matCellDef="let data"> {{data.contactnumber!=null?data.contactnumber:''}} </td>
                          </ng-container>


                          <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef> Status </th>
                              <td mat-cell *matCellDef="let data"> {{data.status}} </td>
                          </ng-container>


                          <ng-container matColumnDef="action">
                              <th mat-header-cell *matHeaderCellDef> Action </th>
                              <td mat-cell *matCellDef="let element; let i = index;">
                                  <mat-icon title="Edit" (click)="editfamily(i)" *ngIf="!isviewemployee">edit</mat-icon>
                                  <mat-icon title="Delete" (click)="deletefamily(i)" *ngIf="!isviewemployee">delete</mat-icon>
                              </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                  </div>
                  <div class="form-group row ml-1 mt-3">
                      <div class="col-sm-6" style="padding:0px">
                          <button class="col-md-2 btn btn-primary" (click)="firstprev()">Prev</button>
                      </div>
                      <div class="col-sm-6 text-right">
                          <button class="col-md-2 btn btn-primary" [disabled]="familyDetails.length == 0" (click)="secondnext()">Next</button>
                      </div>
                  </div>
              </div>


              <form [formGroup]="employeeworkAddForm" autocomplete="off">
                  <div *ngIf="work">

                      <mat-card-header class="">
                          <mat-card-title > Add Employee Details </mat-card-title>
                      </mat-card-header><br>
                      <mat-progress-bar mode="determinate" value="100"></mat-progress-bar><br>
                      <mat-divider></mat-divider>
                      <mat-card-header class="">
                          <mat-card-title > Work Information </mat-card-title>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Employee ID</mat-label>
                              <input formControlName="empid" matInput placeholder="Employee ID" [readonly]="isview">
                              <!-- <input *ngIf="isview"  formControlName="firstname" matInput placeholder="" readonly> -->
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Date of Joining</mat-label>
                              <input required matInput readonly [matDatepicker]="dateofjoin" [min]="mindatofjoin"
                                  [max]="maxDate" placeholder="DD-MM-YYYY"  formControlName="dateofjoin" (click)="dateofjoin.open()">
                              <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofjoin">
                              </mat-datepicker-toggle>
                              <mat-datepicker #dateofjoin></mat-datepicker>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.dateofjoin.errors?.required">
                                  {{msgLM1}}
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Official Email</mat-label>
                              <input type="text" maxlength="100" formControlName="officeemail" matInput
                                  placeholder="" required>

                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.officeemail.errors?.email">
                                  {{msgLM2}}
                              </mat-error>
                              <mat-error class="con-error"
                              *ngIf="employeeworkAddForm.controls.officeemail.errors?.required">
                              {{msgLM1}}
                          </mat-error>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">




                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Employee Status</mat-label>
                              <mat-select formControlName="status" required>
                                  <ng-container>
                                      <div>
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value="Active">Active</mat-option>
                                          <mat-option *ngIf="this.editemployee" value="Inactive">Inactive</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.status.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Employment Type</mat-label>
                              <mat-select required formControlName="employmenttype">
                                  <ng-container>
                                      <div *ngFor="let b of EmploymentTypeDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option value={{b.id}}>{{b.employmenttype}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.employmenttype.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Designation</mat-label>
                              <mat-select required formControlName="designation">
                                  <ng-container>
                                      <div *ngFor="let b of availableDesignations">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option [value]="b.id">{{b.designation}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.designation.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Department</mat-label>
                              <mat-select required formControlName="department">
                                  <ng-container>
                                      <div *ngFor="let b of availableDepartments">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option [value]="b.id">{{b.deptname}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.department.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                            <mat-label>Role</mat-label>
                            <mat-select required formControlName="usertype">
                                <ng-container>
                                    <div *ngFor="let b of availableRole">
                                        <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                        <mat-option [value]="b.id">{{b.name}}</mat-option>
                                    </div>

                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeworkAddForm.controls.usertype.errors?.required">
                                {{msgLM3}}
                            </mat-error>
                        </mat-form-field>
                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Manager</mat-label>
                              <mat-select required formControlName="reportingmanager">
                                  <ng-container *ngFor="let b of availablereportingmanagers">
                                      <mat-option [value]="b.id">{{b.employee_name}}</mat-option>
                                  </ng-container>
                                  <mat-option value="Self" *ngIf="isself">Self</mat-option>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.reportingmanager.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                          <!-- <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Shifts</mat-label>
                              <mat-select required formControlName="shift">
                                  <ng-container>
                                      <div *ngFor="let b of availableShifts">
                                          <mat-option [value]="b.id">{{b.shiftname}}</mat-option>
                                      </div>
                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.shift.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field> -->
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">


                          <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                              <mat-label>Company Location</mat-label>
                              <mat-select required formControlName="companylocation">
                                  <ng-container>
                                      <div *ngFor="let b of worklocationDetails">
                                          <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                          <mat-option [value]="b.id">{{b.cityname}}</mat-option>
                                      </div>

                                  </ng-container>
                              </mat-select>
                              <mat-error class="con-error"
                                  *ngIf="employeeworkAddForm.controls.companylocation.errors?.required">
                                  {{msgLM3}}
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">



                      </div>
                      <mat-card-header class="">
                          <mat-card-title > Work Experience </mat-card-title>
                          <mat-icon title="Add" (click)="addexperience()"  *ngIf="!isviewemployee">add</mat-icon>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                          <div formArrayName="exp">
                              <div *ngFor="let experience of exp().controls; let empIndex=index">
                                  <div [formGroupName]="empIndex" fxLayout.xs="column">
                                      <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2"
                                          appearance="outline">
                                          <mat-label>Company Name</mat-label>
                                          <input type="text" maxlength="100" formControlName="companyname" matInput
                                              placeholder="" appRemoveSpace>
                                      </mat-form-field>
                                      <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2"
                                          appearance="outline">
                                          <mat-label>From </mat-label>
                                          <input matInput readonly [matDatepicker]="wfromdate" [max]="workDisableFromDates[empIndex]"
                                          placeholder="DD-MM-YYYY"  formControlName="wfromdate" (click)="wfromdate.open()" (dateChange)="dateChange('workFromDate', $event,empIndex)">
                                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="wfromdate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #wfromdate></mat-datepicker>
                                      </mat-form-field>
                                      <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2"
                                          appearance="outline">
                                          <mat-label>To </mat-label>
                                          <input matInput readonly [matDatepicker]="wtodate" [min]="workDisableToDates[empIndex]" [max]="maxDate"
                                          placeholder="DD-MM-YYYY"  formControlName="wtodate" (click)="wtodate.open()" (dateChange)="dateChange('workToDate', $event,empIndex)">
                                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="wtodate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #wtodate></mat-datepicker>
                                      </mat-form-field>
                                      <div fxFlex.xs="100" fxFlex="10" style="padding:20px" class="mx-2 my-2 mr2"
                                          appearance="outline">
                                          <!-- <mat-icon (click)="addexperience()">add</mat-icon> -->
                                          <mat-icon title="Delete" *ngIf="!isviewemployee" (click)="removeWork(empIndex)">delete</mat-icon>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- <mat-form-field fxFlex.xs="100" fxFlex="15" class="mx-2 my-2" appearance="outline">
                              <mat-label>Actions</mat-label>
                              <input formControlName="middlename" matInput placeholder="" appRemoveSpace >
                          </mat-form-field>  -->



                      </div>
                      <mat-card-header class="">
                          <mat-card-title > Education Details </mat-card-title>
                          <mat-icon title="Add" fxLayoutAlign="end" *ngIf="!isviewemployee" (click)="addeducation()">add</mat-icon>
                      </mat-card-header>
                      <mat-divider></mat-divider>
                      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center" >

                          <div formArrayName="edu" style="margin-top: 5px;">
                              <div *ngFor="let employee of edu().controls; let empIndex=index">
                                  <div [formGroupName]="empIndex" fxLayout.xs="column">
                                      <mat-form-field fxFlex.xs="100"  class="custom-em-course" appearance="outline">
                                          <mat-label>Course</mat-label>
                                          <input type="text" maxlength="100" formControlName="course" matInput
                                              placeholder="" appRemoveSpace>
                                      </mat-form-field>
                                      <mat-form-field fxFlex.xs="100" class="custom-em-institute"  appearance="outline">
                                          <mat-label>Institute Name</mat-label>
                                          <input type="text" maxlength="100" formControlName="institutename" matInput
                                              placeholder="" appRemoveSpace>
                                      </mat-form-field>
                                      <mat-form-field fxFlex.xs="100"  appearance="outline">
                                          <mat-label>From </mat-label>
                                          <input matInput readonly [matDatepicker]="efromdate" [max]="eduDisableFromDates[empIndex]"
                                          placeholder="DD-MM-YYYY"  formControlName="efromdate" (click)="efromdate.open()" (dateChange)="dateChange('eduFromDate', $event,empIndex)">
                                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="efromdate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #efromdate ></mat-datepicker>
                                          <!-- <mat-datepicker #efromdate startView="multi-year" (monthSelected)="closeDatePicker($event, efromdate)"></mat-datepicker> -->
                                      </mat-form-field>
                                      <mat-form-field fxFlex.xs="100"  appearance="outline">
                                          <mat-label>To </mat-label>
                                          <input matInput readonly [matDatepicker]="etodate" [min]="eduDisableToDates[empIndex]" [max]="maxDate"
                                          placeholder="DD-MM-YYYY"  formControlName="etodate" (click)="etodate.open()" (dateChange)="dateChange('eduToDate', $event,empIndex)">
                                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="etodate">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #etodate></mat-datepicker>
                                      </mat-form-field>
                                      <div fxFlex.xs="100" fxFlex="2" style="padding:20px"
                                          appearance="outline">
                                          <!-- <mat-icon (click)="addeducation()">add</mat-icon> -->
                                          <mat-icon title="Delete" (click)="removeeducation(empIndex)" *ngIf="!isviewemployee">delete
                                          </mat-icon>
                                      </div>


                                  </div>
                              </div>
                          </div>





                      </div>




                      <div class="form-group row ml-1 mt-3">
                          <div class="col-sm-6" style="padding:0px">
                              <button class="col-md-2 btn btn-primary" (click)="secondprev()">Prev</button>
                          </div>
                          <div class="col-sm-6 mr2 text-right">
                              <button class="col-md-2 mr-2 btn btn-primary" (click)="save()"
                                  *ngIf="this.editemployee">Save</button>
                              <button class="col-md-2 mr-2 btn btn-primary" (click)="save()" *ngIf="this.addemployee"
                                  [disabled]="!employeeworkAddForm.valid">Submit</button>
                              <button class="col-md-2  btn btn-danger" (click)="close()">Close</button>
                          </div>
                      </div>
                  </div>

              </form>
          </mat-card>
          <mat-divider></mat-divider>
      </div>

  </div>

  <div *ngIf="viewdetails">
      <mat-card fxLayout="column">
          <mat-card-header>
              <mat-card-title >Employee Master </mat-card-title>
          </mat-card-header><br>
          <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end start" fxLayoutAlign.xs="start end">
              <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue"
                  (click)="addemp()" type="button">
                  <span>Add</span>
              </button>
          </div><br>
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">
              <mat-form-field fxFlex.xs="100%" appearance="outline">
                  <mat-label>Search</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
                  <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
          </div>
          <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="employeeid">
                      <th mat-header-cell *matHeaderCellDef> Employee ID </th>
                      <!-- <td mat-cell *matCellDef="let row"> {{row.designation}} </td> -->
                      <td mat-cell *matCellDef="let row">
                          <span>{{row.empid}} </span>

                      </td>
                  </ng-container>



                  <ng-container matColumnDef="firstname">
                      <th mat-header-cell *matHeaderCellDef style="padding-right: 100px"> First Name </th>
                      <td mat-cell *matCellDef="let row">
                          <span>{{row.firstname}} </span>
                      </td>

                  </ng-container>
                  <ng-container matColumnDef="middlename">
                    <th mat-header-cell *matHeaderCellDef style="padding-right: 100px"> Middle Name </th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.middlename}} </span>
                    </td>

                </ng-container>
                <ng-container matColumnDef="lastname">
                  <th mat-header-cell *matHeaderCellDef style="padding-right: 100px"> Last Name </th>
                  <td mat-cell *matCellDef="let row">
                      <span> {{row.lastname}} </span>
                  </td>

              </ng-container>
                  <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef> Status </th>
                      <!-- <td mat-cell *matCellDef="let row"> {{row.designation}} </td> -->
                      <td mat-cell *matCellDef="let row">
                          <span>{{row.status}} </span>

                      </td>
                  </ng-container>


                  <ng-container matColumnDef="Action">
                      <th mat-header-cell *matHeaderCellDef> Action </th>

                      <!-- <td mat-cell *matCellDef="let row"> {{row.id}} </td> -->
                      <td mat-cell *matCellDef="let row">
                          <mat-icon class="mr-2" title="Edit" (click)="editEmployee(row)">edit</mat-icon>
                          <mat-icon class="mr-2" title="View" (click)="employeeview(row)">visibility</mat-icon>

                      </td>

                  </ng-container>


                  <tr class="headerbox title" mat-header-row *matHeaderRowDef="empdisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: empdisplayedColumns;"></tr>


                  <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="6" style="text-align: center;" >No data found.</td>
                  </tr>
              </table>

              <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>


          </div>
          <div style="text-align: center;padding-top: 20px;" *ngIf="employeedetails.length === 0">No data found.</div>

      </mat-card>

  </div>
</div>
