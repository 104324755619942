import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-faq-screen',
  templateUrl: './faq-screen.component.html',
  styleUrls: ['./faq-screen.component.scss']
})
export class FaqScreenComponent implements OnInit {
  faqForm!: FormGroup;
  constructor( private mainService:CompanySettingService, private moduleService: LoginService, private formBuilder:FormBuilder ) { }
panelOpenState = false;
allModuleDetails:any =[];
faqList:any =[];
companyName:any;
selectedModule:any;

  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName')
    this.faqForm = this.formBuilder.group({
      module: [],
    });
    this. getModules();
    this.faqForm.get('module')?.valueChanges.subscribe((selectedValue: any) => {
      this.selectedModule = selectedValue;
      this.getFAQList();
    })
    

  }

  getModules() {
    this.moduleService.getModules('modulesmaster', null, 1, 100,this.companyName).subscribe((result) => {
      if (result && result.status) {
        this.allModuleDetails = result.data;
        // this.allModuleDetails = this.moduleList.concat(this.moduleList2)
       }
    });
  }

  getFAQList() {
    let data =
     {
       "module_id_value": this.selectedModule,
       "role_id_value":null,
       "companyName":this.companyName,
     }
   this.mainService.getFrequentlyAskedQuestions(data).subscribe((res:any)=>{
     if(res.status) {
       this.faqList = res.data;
     } else {
       this.faqList = [];
     }
   })
  }
}
