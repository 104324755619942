<div [formGroup]="ESIReport" class="tableP" fxLayoutAlign="space-between" style="margin-left: 10px;">
    <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Month and Year</mat-label>
            <input matInput readonly [matDatepicker]="fromDateForESI" placeholder="From Date" [formControl]="dateESI"
                [min]="minDateForESI" [max]="maxDateForESI" (click)="fromDateForESI.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDateForESI"></mat-datepicker-toggle>
            <mat-datepicker #fromDateForESI startView="multi-year"
                (monthSelected)="setMonthAndYearESI($event, fromDateForESI)" panelClass="example-month-picker">
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex class="mx-2 my-2">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
                <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
                <ng-container>
                    <div *ngFor="let b of filteredStates">
                        <mat-option value="{{ b.state_id }}">{{
                            b.state
                            }}</mat-option>
                    </div>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign.xs="center center">
            <span ngStyle.gt-md="margin-top: 10%" ngStyle.lt-lg="margin-top: 10%" ngStyle.xs="margin-top: 0px">
                <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
                    Search
                </button>
            </span>
        </div>
    </div>
    <div style="margin-top: 10px; padding-right: 2%" fxLayout="row xs-column" fxLayoutAlign="end  center">
        <div>
            <span class="exlprint"> | </span>
            <a title="PDF" (keyup.enter)="downloadPDF()">
                <img (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>

            <span class="exlprint"> | </span>
            <a title="Excel">
                <img (click)="exportAsXLSXforESI()" src="./assets/images/icons/excel.png" />
            </a>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div class="tablePadding">
    <div class="mat-elevation-z1 example-container">
        <div id="table" #table="">
            <table id="table" mat-table [dataSource]="dataSourceESI" matSort>
                <ng-container matColumnDef="ipnumber">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                        ESI No.
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span style="font-size: smaller" *ngIf="row.ESI == 'Grand Total'"><b>{{ row.ESI }}</b>
                        </span>
                        <span style="font-size: smaller" *ngIf="row.ESI != 'Grand Total'">{{ row.ESI }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ipname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
                        Emp. Name
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{ row.Employee_Name }} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nofdays">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                        Paid Days
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.ESI != 'Grand Total'">{{ row.paid_days == null ? 0 : row.paid_days }}
                        </span>
                        <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.paid_days == null ? null : row.paid_days }}</b>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="totawages">
                    <th mat-header-cell *matHeaderCellDef>Gross Salary</th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.ESI != 'Grand Total'">{{ row.Gross_Salary }}
                        </span>
                        <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.Gross_Salary }}</b>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ipcontribution">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                        Employee Contribution
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.ESI != 'Grand Total'">{{ row.employee_esi_value }}
                        </span>
                        <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.employee_esi_value }}</b></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="reasonforzeroworkingdays">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                        Employer Contribution
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.ESI != 'Grand Total'">{{ row.employer_esi_value }}
                        </span>
                        <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.employer_esi_value }}</b></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastworkingdays">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
                        Total Contribution
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.ESI != 'Grand Total'">{{ row.total_contribution }}
                        </span>
                        <span *ngIf="row.ESI == 'Grand Total'"><b>{{ row.total_contribution }}</b></span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsForESI"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsForESI"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7" style="text-align: center">
                        No data found
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizesESI() : [5, 10, 20]"></mat-paginator>
    </div>
</div>