import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReportsService } from '../../reports.service';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { MatSelect } from '@angular/material/select';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin-attendance-detailed-report',
  templateUrl: './admin-attendance-detailed-report.component.html',
  styleUrls: ['./admin-attendance-detailed-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminAttendanceDetailedReportComponent implements OnInit {
  searchForm: any = FormGroup;
  List: any[] = [
  ];
  employeelist: any;
  Users: any;
  minDate = new Date('2020/01/01'); maxDate = new Date();
  userSession: any;
  date = new Date();
  firstDay: any;
  lastDay: any;
  startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  dateDayArray: any = [];
  obj: any;
  monthdata: any;
  year: any;
  months = [{ id: 0, month: 'Jan' }, { id: 1, month: 'Feb' }, { id: 2, month: 'Mar' }, { id: 3, month: 'Apr' }, { id: 4, month: 'May' }, { id: 5, month: 'Jun' }, { id: 6, month: 'Jul' }, { id: 7, month: 'Aug' }, { id: 8, month: 'Sep' }, { id: 9, month: 'Oct' }, { id: 10, month: 'Nov' }, { id: 11, month: 'Dec' }]
  headersList: any = [];
  @ViewChild('table') table!: ElementRef;
  companyName: any;
  constructor(public reportsService: ReportsService, public datePipe: DatePipe,
    public formBuilder: UntypedFormBuilder, private router: Router,
    public dialog: MatDialog, private companyService: CompanySettingService,
    private spinner: NgxSpinnerService) {
    this.companyName = sessionStorage.getItem("companyName");
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  filter = new UntypedFormControl();
  // searchForm = this.formBuilder.group({
  //    fromDate: [new Date()], toDate: [new Date()], Users: ['0'] ,
  //    companylocation: [''],
  //    managers: [''],});

  searchControl = new FormControl('');
  searchControlforLoc = new FormControl('');
  searchControlforRM = new FormControl('');
  filteredLocations: any = [];
  filteredManagers: any = [];
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'empname', 'attendancedate', 'firstlogintime',
    'lastlogouttime', 'totalhours', 'breaks', 'breaktime', 'productivehours', 'action'];
  isLoading = false;

  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  filteredEmployees: any = [];

  ngOnInit() {

    this.searchForm = this.formBuilder.group(
      {
        companylocation: ["", Validators.required],
        managers: ["", Validators.required],
        fromDate: [new Date()],
        toDate: [new Date()],
        Users: ['0']
      });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    // this.Searchform();
    this.getEmployeelist();

    this.getDateArray(this.startDate, this.endDate);
    this.getWorkLocation();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmp(searchText);
    });
    this.searchControlforLoc.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });
    this.searchControlforRM.valueChanges.subscribe(searchText => {
      this.filterRM(searchText);
    });
  }
  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.companylocation.setValue(array)
    let locations = this.searchForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.searchForm.controls.companylocation.setValue('')
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.managers.setValue(array)
    let managerdata = this.searchForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelist();
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
    select.value = [];
    this.searchForm.controls.managers.setValue('')
    this.selectedManagers = [];
  }

  getWorkLocation() {
    this.managersDetails = [];
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName
    }
    this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.managersDetails = res.data;
        this.filteredManagers = this.managersDetails;
      }
    })

  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.searchForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelist()
  }
  getDateArray(start: any, end: any) {
    const arr = [];
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    for (const val of arr) {

      this.obj = {
        date: val,
        day: val.toLocaleDateString('en-US', { weekday: 'short' }),
      };

      this.dateDayArray.push(this.obj);
    }
  }
  getEmployeelist() {
    let obj = {
      "rm_id": JSON.stringify(this.selectedManagers),
      "location_id": JSON.stringify(this.selectedLocations),
    };
    this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
      if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.filteredEmployees = this.employeelist;
        this.searchForm.controls.Users.setValue('0');
      }

    });
  }
  //All Employees API
  Searchform() {
    if (this.searchForm.valid) {
      this.List = [];
      this.dataSource = new MatTableDataSource(this.List);
      let fromDate = new Date(this.searchForm.controls.fromDate.value).getFullYear() + '-' + (new Date(this.searchForm.controls.fromDate.value).getMonth() + 1) + '-' + '01';
      let userId = this.searchForm.controls.Users.value;
      let data = {};
      if (userId == '0') {
        userId = null;
        data = {
          'manager_employee_id': JSON.stringify(this.selectedManagers),
          'location_id': JSON.stringify(this.selectedLocations),
          'employee_id': userId,
          "calendar_date": fromDate,

        }
      } else {
        data = {
          'manager_employee_id': null,
          'location_id': JSON.stringify(this.selectedLocations),
          'employee_id': userId,
          "calendar_date": fromDate
        }
      }
      this.spinner.show();
      this.isLoading = true;
      this.reportsService.getBranchwiseAttendanceMonthlyReport(data).subscribe((res: any) => {
        this.headersList = [];
        this.List = [];
        if (res.status) {
          let i = 0;
          res.data.forEach((e: any) => {
            if (i < 2) {
              let header = JSON.parse(e.result);
              this.headersList.push(header);
            } else {
              let header = JSON.parse(e.result);
              this.List.push(header);
            }
            i++;

          });

        }

        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.List);
        this.spinner.hide();
      }, error => {
        this.isLoading = false;
        error.error.text;
        this.spinner.hide();
      });
    }
  }




  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/AdminMonthlyDetailReport"]));
  }
  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      //  width: '500px',position:{top:`70px`},
      data: { attendanceid: item.attendanceid, }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  exportAsXLSX() {
    let date = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "MM-YYYY"))
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Monthly_Detail_Report');
    XLSX.writeFile(wb, date + '-' + 'Monthly_Detail_Report_for_manager.xlsx');

  }

  getColor(i: string): String {
    let color = ''
    if (i == "P") {
      return color = 'green'
    } else if (i == "H") {
      return color = '#800000';
    } else if (i == "W") {
      return color = 'blue';
    } else if (i == "L") {
      return color = 'orange';
    } else if (i == "HD") {
      return color = '#ce06e4';

    } else if (i == "WP") {
      return color = '#06c3e4';

    } else if (i == "HP") {
      return color = '#06e471';

    }
    else {
      return color = 'red';
    }
  }
  public exportPDF(): void {
    let date = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: date + "-" + "Attendance Monthly Detailed Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: date + " - " + "Attendance Monthly Detailed Report \n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html,

      ], styles: {
        'html-table': {
          background: 'white' // it will add a yellow background to all <STRONG> elements
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A3'
      //'portrait'
    }).download("Attendance Monthly Detailed Report.pdf");

  }

  cdate = new UntypedFormControl(moment());
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.cdate.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.searchForm.controls.fromDate.setValue(ctrlValue);
    datepicker.close();
  }
  filterEmp(searchText: any) {
    this.filteredEmployees = this.employeelist.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployees.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLoc.setValue('');
    }
  }
  filterRM(searchText: any) {
    this.filteredManagers = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredManagers.length <= 0) {
      this.searchControlforRM.setValue('');
    }
  }
}
