<div fxLayout="column" fxFill class="first-col">
    <form [formGroup]="errorMessagesForm" autocomplete="off">
        <mat-card fxFill>
            <mat-card-header>
                <mat-card-title> Message Master </mat-card-title>
            </mat-card-header>
            <div fxLayout="column">
                <div fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="end" class="dev-Pad"
                    style="padding-top: 5px;height: 70px;">
                    <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [tabIndex]="[1]"
                            (keyup.enter)="applyFilter($event)" placeholder="Search" #input>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table #table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="sno">
                                <mat-header-cell *matHeaderCellDef style="max-width: 85px;">S. No.</mat-header-cell>
                                <mat-cell *matCellDef="let element; let i=index;" style="max-width: 85px;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="code">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="code"
                                    style="max-width: 100px;">Code</mat-header-cell>
                                <mat-cell *matCellDef="let row" style="max-width: 100px;">{{row.code}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="screenName">
                                <mat-header-cell *matHeaderCellDef>Screen Name</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.screenname}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="message">
                                <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <span *ngIf="enable != row.id">{{row.message}}&nbsp;</span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <textarea minlength="10" rows="4" cols="40" formControlName="message"
                                                value={{row.message}} matInput placeholder="{{row.message}}"
                                                maxlength="250">
                                </textarea>
                                            <mat-error class="con-error"
                                                *ngIf="errorMessagesForm.controls.message.errors?.required">
                                                {{requiredField}}
                                            </mat-error>
                                            <mat-error class="con-error"
                                                *ngIf="errorMessagesForm.controls.message.errors?.minlength">
                                                Please enter minimum 10 chars.
                                            </mat-error>
                                        </mat-form-field>

                                    </span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <!-- <td mat-cell *matCellDef="let row"> </td> -->
                                <mat-cell *matCellDef="let row; let i = index;" style="padding-right: 8%;">
                                    <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="edit($event, row)"
                                        *ngIf="enable != row.id">
                                        <mat-icon (click)="edit($event,row)" title="Edit"
                                            *ngIf="enable != row.id">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [tabIndex]="[i+1]"
                                        (keyup.enter)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)"
                                        *ngIf="enable == row.id">
                                        <mat-icon
                                            (click)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)"
                                            title="Save" *ngIf="enable == row.id">save</mat-icon>

                                    </button>
                                    <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="canceledit($event,row.id)"
                                        *ngIf="enable == row.id">
                                        <mat-icon (click)="canceledit($event,row.id)" title="Cancel"
                                            *ngIf="enable == row.id">cancel</mat-icon>
                                    </button>

                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="title"
                                *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row;columns:displayedColumns"></mat-row>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell noData-cell" colspan="5" style="text-align: center;">No data found
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>

            </div>
        </mat-card>
    </form>
</div>