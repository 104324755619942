import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { AdminService } from '../../admin.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-client-master',
  templateUrl: './client-master.component.html',
  styleUrls: ['./client-master.component.scss']
})
export class ClientMasterComponent implements OnInit {
  clientMasterForm: any = UntypedFormGroup;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  valid: boolean = false;
  msgEM1:any;
  msgEM84:any;
  msgEM85:any;
  msgEM88:any;
  msgEM89:any;
  msgEM90:any
  msgEM91:any;
  msgEM92:any;
  msgEM151:any;
  msgEM152:any;
  todayDate:any=new Date();
  displayedColumns: string[] = ['sno','client','contactName','mobile','city', 'status', 'Action'];
  companyClientsData: any = [];
  arrayValue: any =[
   { id:'Active',value:'Active'},
   { id:'Inactive',value:'Inactive'}
  ]
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  @ViewChild(MatPaginator)  paginator!: MatPaginator;
  @ViewChild(MatSort)  sort!: MatSort;
    messagesDataList: any = [];
    industryTypeList: any[] = [];
    countryDetails: any = [];
    stateDetails: any = [];
    cityDetails: any = [];
    companyDBName:any;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog, private LM: CompanySettingService,private adminService:AdminService,
    private emsService:EmsService,private mainService: MainService,private companyService: CompanySettingService) {
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.companyDBName = sessionStorage.getItem("companyName")
    this.getCompanyClients();
    this.getMessages();
    this.getIndustryTypes();
    this.getCountry();
    this.clientMasterForm = this.formBuilder.group(
      {
        companyName: ["",[Validators.required]],
        IndustryType: ["",[Validators.required]],
        contactName: ["",[Validators.required]],
        mobile: ["",[Validators.required]],
        email: ["",[Validators.required]],
        address1: ["",[Validators.required]],
        address2: ["",[Validators.required]],
        country: ["",[Validators.required]],
        state: ["",[Validators.required]],
        city: ["",[Validators.required]],
        pincode: ["",[Validators.required]],
        gstNumber: [""],

      },
    );

    this.clientMasterForm.get('country')?.valueChanges.subscribe((selectedValue: any) => {
      this.stateDetails= [];
      this.companyService.getStatesc(selectedValue).subscribe((data)=>{
        this.stateDetails=data.data;
 
      })
    })

    this.clientMasterForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.clientMasterForm.controls.city.setValue("");
      this.cityDetails = [];
       this.companyService.getCities(selectedValue).subscribe((data)=>{
          this.cityDetails=data.data
         })
    })
  }

  getIndustryTypes() {
    this.industryTypeList = [];
    this.mainService.getIndustryTypes('industry_type_master', null, 1, 10, 'spryple_dev').subscribe(result => {
      this.industryTypeList = result.data;
    })
  }

  getCountry() {
    this.countryDetails = []
    this.companyService.getPreonboardingCountry('countrymaster', null, 1, 10, 'spryple_dev').subscribe(result => {
      this.countryDetails = result.data;
    })
  }

  stopLeadingZero(event:any) {
    const input = event.target.value;
    if (input.length === 0 && event.which === 48) {
      event.preventDefault();
    }
  }
  validateemail(){
    this.router.navigate(['Validateemail'])
  }

  saveClient() {
    
      if (this.clientMasterForm.valid) {
        var data = {
          id_value: null,
          company_name_value: this.clientMasterForm.controls.companyName.value,
          i_type: this.clientMasterForm.controls.IndustryType.value,
          industry_type_val: null,
          contact_name_value: this.clientMasterForm.controls.contactName.value,
          mobile_number_value: this.clientMasterForm.controls.mobile.value,
          company_email_value: this.clientMasterForm.controls.email.value,
          company_address_value: this.clientMasterForm.controls.address1.value,
          company_address2_value: this.clientMasterForm.controls.address2.value,
          country_id_value: this.clientMasterForm.controls.country.value,
          state_id_value: this.clientMasterForm.controls.state.value,
          city_id_value: this.clientMasterForm.controls.city.value,
          pincode_value: this.clientMasterForm.controls.pincode.value,
          gst_number_value: this.clientMasterForm.controls.gstNumber.value,
          created_by:this.userSession.id,
          updated_by_value:this.userSession.id,
          status_value: 'Active',
          companyName:this.companyDBName
        }
        this.adminService.setCompanyClient(data).subscribe((res: any) => {

         if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/client-master"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Data saved sucessfully"
            });


          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data:"Unable to Save Data"
            });
          }
        })
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.msgEM92
        });


      }


  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    // this.clientMasterForm.controls.clientName.setValue('')
  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/client-master"]));
  }
  
  status(status: any) {

  }

  edit(w: any, i: any) {
    this.clientMasterForm.controls.clientName.setValue(i.deptname);
    this.enable = i.id;
    this.isEdit = false;
    this.isSave = true;
  }
  
  updateClient(event: any, id: any, deptname: any, datas: any) {

  }

  canceledit(event: any, id: any) {
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.ngOnInit();

  }

  getCompanyClients() {
    this.adminService.getCompanyClients(this.companyDBName).subscribe((info:any) => {
      if (info.status && info.data.length != 0) {
        this.companyClientsData = info.data;
        this.dataSource = new MatTableDataSource(this.companyClientsData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }

    })
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
     
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  
  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.emsService.getMessagesListApi(data).subscribe((result: any) => {
      if(result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {
          
         if (e.code == "EM1") {
          this.msgEM1 = e.message
         } 
         else if (e.code == "EM84") {
           this.msgEM84 = e.message;
          }
          else if (e.code == "EM85") {
           this.msgEM85 =e.message
         }
         else if (e.code == "EM88") {
           this.msgEM88 =e.message
         } 
         else if (e.code == "EM89") {
          this.msgEM89 =e.message
        } 
        else if (e.code == "EM90") {
          this.msgEM90 =e.message
        }
        else if (e.code == "EM91") {
          this.msgEM91 =e.message
        }

        else if (e.code == "EM92") {
          this.msgEM92 =e.message
        }

        else if (e.code == "EM151") {
          this.msgEM151 =e.message
        }

        else if (e.code == "EM152") {
          this.msgEM152 =e.message
        }

          })
      } else {
        this.messagesDataList = [];
      }
    })
  }

    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }


}
