import { Component, OnInit,ViewChild,Inject,LOCALE_ID } from '@angular/core';
import { AbstractControl, UntypedFormGroup,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
import { ClaimsService } from '../../claims.service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxSpinnerService } from 'ngx-spinner';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
export interface UserData {
  rbs_type: string;
  name: string;
  notes: string;
  status: string;
  id: number;
  total: number;
}

@Component({
  selector: 'app-claims-reimbursement-types',
  templateUrl: './claims-reimbursement-types.component.html',
  styleUrls: ['./claims-reimbursement-types.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ClaimsReimbursementTypesComponent implements OnInit {
  rbsTypeForm!: UntypedFormGroup;
  userSession:any;
  pipe = new DatePipe(this.locale);
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  isDelete: boolean = true;
  enable: any = null;
  valid: boolean = false;
  rbsData:any =[];
  displayedColumns: string[] = ['sno','rbs_type','name','control_amount','effective_date', 'rbs_status', 'Action'];
  minDate:any;
  arrayValue: any;
  effective_date:any= new Date();
  dataSource: MatTableDataSource<UserData> = <any>[];
  selected_row:any;
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
   // messagesDataList: any = [];

  constructor(@Inject(LOCALE_ID) private locale: string,private claims:ClaimsService,private Company:CompanySettingService,private formBuilder: UntypedFormBuilder,
    private router: Router, private dialog: MatDialog,private spinner: NgxSpinnerService,) {
      this.userSession = JSON.parse(sessionStorage.getItem('user') || '');

     }


  ngOnInit(): void {
    this.rbsTypeForm = this.formBuilder.group({
      rbs_type: ["",[Validators.required ,Validators.maxLength(50)]],
      name: ["",[Validators.required ]],
      notes: ["",[Validators.required ]],
      control_amount: ['',[Validators.required,Validators.min(0.01), Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
      effective_date:['',[Validators.required]],
      rbs_status:["",[Validators.required]],
    }, );

    this.minDate = new Date();
    this.getReimbursementTypes();
  }

  getReimbursementTypes() {
    this.rbsData = [];
    this.spinner.show();
    this.claims.getClaimsReimbursementTypes(1, 100).subscribe((res:any)=>{
      this.spinner.hide();
      if(res.status) {
        this.rbsData =  res.data;
        this.arrayValue = res.statusValues;
      }
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    })
  }



  Add() {
    this.selected_row = null;
    this.enable = null;
    this.isAdd = true;
    this.isEdit=false;
    this.isView=false;
    this.isdata = false;
    this.rbsTypeForm.controls.rbs_type.setValue('');
    this.rbsTypeForm.controls.name.setValue('');
    this.rbsTypeForm.controls.notes.setValue('');
    this.rbsTypeForm.controls.control_amount.setValue('');
    this.rbsTypeForm.controls.effective_date.setValue('');
    this.rbsTypeForm.controls.rbs_status.setValue(1);
  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Claims/ClaimsReimbursementTypes"]));
  }


edit(row: any) {
  this.isView = false;
  this.selected_row = row;
    this.enable = row.id;
    this.isAdd = true;
    this.isEdit=true;
    this.isdata = false;
    this.rbsTypeForm.controls.rbs_type.setValue(row.rbs_type);
    this.rbsTypeForm.controls.name.setValue(row.name);
    this.rbsTypeForm.controls.effective_date.setValue(new Date(row.effective_date))
    this.rbsTypeForm.controls.notes.setValue(row.notes);
    this.rbsTypeForm.controls.control_amount.setValue(row.control_amount);
    this.rbsTypeForm.controls.rbs_status.setValue(row.rbs_status);
  }

  view(row: any) {
    this.isView = true;
    this.isEdit=false;
      //this.selected_row = row;
      //this.enable = row.id;
      this.isAdd = true;
      this.isdata = false;
      this.rbsTypeForm.controls.rbs_type.setValue(row.rbs_type);
      this.rbsTypeForm.controls.name.setValue(row.name);
      this.rbsTypeForm.controls.effective_date.setValue(new Date(row.effective_date))
      this.rbsTypeForm.controls.notes.setValue(row.notes);
      this.rbsTypeForm.controls.control_amount.setValue(row.control_amount);
      this.rbsTypeForm.controls.rbs_status.setValue(row.rbs_status);

    }
    
    changeStatus(status:any,row:any){  
      let dialogRef = this.dialog.open(ComfirmationDialogComponent, { position: { top: `70px` },   disableClose: true,
        data: { message: "Are you sure you want to change status for "+ row.rbs_type +" ?", YES: 'YES', NO: 'No' }
      });  
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'YES') {
            if(row.id > 0){
              let data = {
                rbs_type_id: row.id,
                rbs_type:row.rbs_type.trim().replace(/\s+/g, ' '),
                name:row.name.trim().replace(/\s+/g, ' '),
                notes:row.notes.trim().replace(/\s+/g, ' '),
                amount:Number(row.control_amount).toFixed(2),
                rbs_status:status,
                created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
                created_by:this.userSession.id
              }
              this.setReimbursementType(data);
          }
        }
        else{
          this.getReimbursementTypes();
        }
      });  
 }

 AddData(){
    if (!this.rbsTypeForm.valid) {
      return;
    }

    let data = {
      rbs_type_id: this.selected_row && this.selected_row.id? this.selected_row.id:0,
      rbs_type:this.rbsTypeForm.controls.rbs_type.value.trim().replace(/\s+/g, ' '),
      name:this.rbsTypeForm.controls.name.value.trim().replace(/\s+/g, ' '),
      notes:this.rbsTypeForm.controls.notes.value.trim().replace(/\s+/g, ' '),
      amount:Number(this.rbsTypeForm.controls.control_amount.value).toFixed(2),
      rbs_status:this.rbsTypeForm.controls.rbs_status.value,
      created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      created_by:this.userSession.id
    }

    if(data.rbs_type && data.rbs_type.toString().length){
      this.setReimbursementType(data);
    }
 }

 setReimbursementType(data:any){
    if(data.rbs_type && data.rbs_type.toString().length){
      this.spinner.show();
      this.claims.setClaimsReimbursementTypes(data).subscribe((res:any)=>{
        this.spinner.hide();
        if(res.status) {
          this.enable = null;
          this.selected_row = null;
          this.isdata = true;
          this.isAdd = false;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Claims/ClaimsReimbursementTypes"]));
          this.dataSource.paginator = this.paginator;
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data:res.data[0].resultmsg,
        });
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: (res && res.data && res.data[0] && res.data[0].resultmsg)? res.data[0].resultmsg: "Unable to add Reimbursement type. Please try again. "
        });
      }
    })
  }
 }
  canceledit(event: any, id: any) {
    this.isdata = true;
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.isDelete = true;
    this.ngOnInit();
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }

    }



    disableStatus(row:any){
      if(row && row.is_default)
        return true;
      else if(this.enable == row.id)
        return true;
      else return false;
    }

    onChangeRadio(){

    }
    getFormattedDateTime(date:any){
      return this.claims.getFormattedDateTime(date);
    }
    getStatus(row:any){
      let status  = '';
      this.arrayValue.forEach((e:any) => {
        if(e.id === row.rbs_status){
          status = e.name;
        }
      });
      return status;
    }

}


