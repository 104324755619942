import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApprovalAttendanceComponent } from './pages/approval-attendance/approval-attendance.component';
import { AttendanceUploadexcelComponent } from './pages/attendance-uploadexcel/attendance-uploadexcel.component';
import { EmployeDashboardComponent } from './pages/employe-dashboard/employe-dashboard.component';
import { ManagerDashboardComponent } from './pages/manager-dashboard/manager-dashboard.component';
import { ApprovalAttendanceListComponent } from './pages/approval-attendance-list/approval-attendance-list.component';
import { AttendanceRequestBehalfComponent } from './pages/attendance-request-behalf/attendance-request-behalf.component';
import { AttendanceRequestComponent } from './pages/attendance-request/attendance-request.component';
import { MainComponent } from 'src/app/pages/main/main.component';
import { LMSAccessGuard } from '../../LMS-access.guard';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';
import { ShiftConfigureComponent } from './pages/shift-configure/shift-configure.component';
import { ApprovalHistoryComponent } from './pages/approval-history/approval-history.component';
import { ShiftChangeApproveComponent } from './pages/shift-change-approve/shift-change-approve.component';
import { ShiftChangeRequestComponent } from './pages/shift-change-request/shift-change-request.component';
import { SummaryReportComponent } from '../reports/pages/summary-report/summary-report.component';
import { DetailReportComponent } from '../reports/pages/detail-report/detail-report.component';
import { EmployeMonthlyDetailReportComponent } from '../reports/pages/employe-monthly-detail-report/employe-monthly-detail-report.component';
import { ShiftsWiseReportComponent } from '../reports/pages/shifts-wise-report/shifts-wise-report.component';
import { LateAttendanceReportComponent } from '../reports/pages/late-attendance-report/late-attendance-report.component';
import { AdminAttendanceDetailedReportComponent } from '../reports/pages/admin-attendance-detailed-report/admin-attendance-detailed-report.component';
import { AdminShiftwiseEmpReportComponent } from '../reports/pages/admin-shiftwise-emp-report/admin-shiftwise-emp-report.component';
import { WeekoffConfigureComponent } from './pages/weekoff-configure/weekoff-configure.component';
import { HrAttendanceRegularizationComponent } from './pages/hr-attendance-regularization/hr-attendance-regularization.component';
import { EmployeeAttendanceRegularaizationComponent } from './pages/employee-attendance-regularaization/employee-attendance-regularaization.component';
import { RegularaizationApprovalComponent } from './pages/regularaization-approval/regularaization-approval.component';
import { ManagerAttendanceRegularizationComponent } from './pages/manager-attendance-regularization/manager-attendance-regularization.component';
import { RotationalShiftConfigurationComponent } from './pages/rotational-shift-configuration/rotational-shift-configuration.component';
import { RotationalShiftAssignComponent } from './pages/rotational-shift-assign/rotational-shift-assign.component';
import { PermissionsApproveComponent } from './pages/permissions-approve/permissions-approve.component';
import { PermissionsRequestComponent } from './pages/permissions-request/permissions-request.component';
import { PermissionHistoryComponent } from './pages/permission-history/permission-history.component';
import { RotationalShiftReassignComponent } from './pages/rotational-shift-reassign/rotational-shift-reassign.component';
import { AdminAttendanceSummaryReportComponent } from '../reports/pages/admin-attendance-summary-report/admin-attendance-summary-report.component';
import { ReportUsersConfigurationComponent } from '../reports/pages/report-users-configuration/report-users-configuration.component';
import { CommonReportsComponent } from '../reports/pages/common-reports/common-reports.component';
import { EmployeeSummaryReportComponent } from '../reports/pages/employee-summary-report/employee-summary-report.component';
import { EmployeeLateAttendanceReportComponent } from '../reports/pages/employee-late-attendance-report/employee-late-attendance-report.component';
import { HrRegularizationComponent } from './pages/hr-regularization/hr-regularization.component';
import { FutureShiftsReportComponent } from '../reports/pages/future-shifts-report/future-shifts-report.component';
import { OTReportsComponent } from '../reports/pages/otreports/otreports.component';
import { CurrentDayPunchDetailsComponent } from '../reports/pages/current-day-punch-details/current-day-punch-details.component';
import { CheckinOutSummaryReportComponent } from '../reports/pages/checkin-out-summary-report/checkin-out-summary-report.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: 'Approval', component: ApprovalAttendanceComponent },
      {
        path: 'ApprovalList',
        component: ApprovalAttendanceListComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'Request',
        component: AttendanceRequestComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'RequestofEmployee',
        component: AttendanceRequestBehalfComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'EmployeeDashboard',
        component: EmployeDashboardComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ManagerDashboard',
        component: ManagerDashboardComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'uploadExcel',
        component: AttendanceUploadexcelComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'ChangePassword', component: ChangePasswordComponent },
      {
        path: 'ShiftConfigure',
        component: ShiftConfigureComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ApprovedHistory',
        component: ApprovalHistoryComponent,
        canActivate: [LMSAccessGuard],
      },
      { path: 'shift-change-approve', component: ShiftChangeApproveComponent },
      {
        path: 'shift-change-request',
        component: ShiftChangeRequestComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'SummaryReport',
        component: SummaryReportComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'DetailReport',
        component: DetailReportComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'MonthlyDetailReport',
        component: EmployeMonthlyDetailReportComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'LateAttendanceReport',
        component: LateAttendanceReportComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'ShiftsWiseReport',
        component: ShiftsWiseReportComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'AdminMonthlyDetailReport',
        component: AdminAttendanceDetailedReportComponent,
      },
      {
        path: 'AdminShiftsWiseReport',
        component: AdminShiftwiseEmpReportComponent,
      },
      { path: 'WeekoffConfiguration', component: WeekoffConfigureComponent },
      {
        path: 'hrAttendanceRegularization',
        component: HrAttendanceRegularizationComponent,
      },
      {
        path: 'employeeAttendanceRegularization',
        component: EmployeeAttendanceRegularaizationComponent,
      },
      {
        path: 'regularizationApproval',
        component: RegularaizationApprovalComponent,
      },
      {
        path: 'managerAttendanceRegularization',
        component: ManagerAttendanceRegularizationComponent,
      },
      {
        path: 'ShiftRotationMaster',
        component: RotationalShiftConfigurationComponent,
      },
      {
        path: 'AssignShiftRotation',
        component: RotationalShiftAssignComponent,
      },
      { path: 'permissionsApprove', component: PermissionsApproveComponent },
      { path: 'permissionsRequest', component: PermissionsRequestComponent },
      { path: 'permissionHistory', component: PermissionHistoryComponent },
      {
        path: 'reassignShiftRotation',
        component: RotationalShiftReassignComponent,
      },
      {
        path: 'admin-monthly-attendance-summary',
        component: AdminAttendanceSummaryReportComponent,
      },
      {
        path: 'reportUsersConfiguration',
        component: ReportUsersConfigurationComponent,
      },
      { path: 'commonReports', component: CommonReportsComponent },
      {
        path: 'employeeSummaryReport',
        component: EmployeeSummaryReportComponent,
      },
      {
        path: 'employeeLateAttendanceReport',
        component: EmployeeLateAttendanceReportComponent,
      },
      { path: 'hrRegularization', component: HrRegularizationComponent },
      { path: 'futureShiftReport', component: FutureShiftsReportComponent },
      {
        path: 'OTReport',
        component: OTReportsComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'currentdayDetailReport',
        component: CurrentDayPunchDetailsComponent,
        canActivate: [LMSAccessGuard],
      },
      {
        path: 'checkInOutSummaryReport',
        component: CheckinOutSummaryReportComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AttendanceRoutingModule {}
