
<div fxLayout="column" class="first-col">
    <mat-card>
      <mat-card-header class="headerbox">
          <mat-card-title class="title">Client Associates Report</mat-card-title>
        </mat-card-header>
       <mat-card-content>
      
    <div   fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" [formGroup]="searchForm" >
      <div fxLayout="row" fxflex="100" fxLayout.xs="column" fxLayout.lt-lg="row"   fxLayoutAlign="space-between">
        <div  fxLayoutAlign="space-between" > 
           
          <mat-form-field   class="mx-2 my-2" appearance="outline">
            <mat-label>Associate Type</mat-label>
            <mat-select placeholder="" name="associateTypes" formControlName="associateTypes" required multiple #associateTypeSelect>
                <button mat-raised-button class="mat-primary fill text-sm"
                    (click)="associateSelectAll(associateTypeSelect, associateTypes, searchForm.associateTypes)"
                    *ngIf="!empIshide">Select All
                </button>
                <button mat-raised-button class="mat-accent fill text-sm"
                    (click)="associateDeselectAll(associateTypeSelect)" *ngIf="empIshide">
                    Deselect All
                </button>
                <mat-option *ngFor="let res of associateTypes" [value]="res" (click)="onchangeAssociates(res)">
                    {{res.employee_type}}
                </mat-option>
            </mat-select>
            <mat-error class="con-error" *ngIf="searchForm.controls.associateTypes.errors?.required">
                Please select option
            </mat-error>
          </mat-form-field>
          <mat-form-field   class="mx-2 my-2" appearance="outline">
            <mat-label>Qualifications </mat-label>
            <mat-select placeholder="" name="qualifications" formControlName="qualifications" required multiple #qualificationSelect>
                <button mat-raised-button class="mat-primary fill text-sm"
                    (click)="qualificationSelectAll(qualificationSelect, qualifications, searchForm.qualifications)"
                    *ngIf="!qualIshide">Select All
                </button>
                <button mat-raised-button class="mat-accent fill text-sm"
                    (click)="qualificationDeselectAll(qualificationSelect)" *ngIf="qualIshide">
                    Deselect All
                </button>
                <mat-option *ngFor="let res of qualifications" [value]="res" (click)="onchangeQualifications(res)">
                    {{res.qualification_type}}
                </mat-option>
            </mat-select>
            <mat-error class="con-error" *ngIf="searchForm.controls.qualifications.errors?.required">
                Please select option
            </mat-error>
          </mat-form-field>
          <mat-form-field   class="mx-2 my-2" appearance="outline">
            <mat-label>Specializations </mat-label>
            <mat-select placeholder="" name="specializations" formControlName="specializations" required  multiple #specializationSelect>
                <button mat-raised-button class="mat-primary fill text-sm"
                    (click)="specializationSelectAll(specializationSelect, specializations, searchForm.specializations)"
                    *ngIf="!spIshide">Select All
                </button>
                <button mat-raised-button class="mat-accent fill text-sm"
                    (click)="specializationDeselectAll(specializationSelect)" *ngIf="spIshide">
                    Deselect All
                </button>
                <mat-option *ngFor="let res of specializations" [value]="res" (click)="onchangeSpecializations(res)">
                    {{res.specialization_type}}
                </mat-option>
            </mat-select>
            <mat-error class="con-error" *ngIf="searchForm.controls.specializations.errors?.required">
                Please select option
            </mat-error>
          </mat-form-field>
        </div>
         
        
      </div>
      <div fxLayout="row"  fxLayoutAlign="center">
        <div   fxLayout="row" fxLayoutAlign="center">
          <span style="margin-left: auto;">
            <button  [tabIndex]="4" class="btn btn-primary mr-2 mb-2" (click)="Searchform()"> Search </button>
            <span class="line" style="color:#1898D5;font-size:16px"></span>
            <button  [tabIndex]="5" class="btn btn-danger mr-2 mb-2" (click)="resetform()">  <span>Clear</span> </button>
          </span>
        </div>
      </div>
      <div   fxLayout="row xs-column" fxLayoutAlign="end"  style="margin-right: 3%;">
        <div *ngIf="List && List.length > 0">   
            <button mat-icon-button>
              <a title="PDF" [tabIndex]="6" (keyup.enter)="exportPDF()"> 
                <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px">
              </a>
            </button>
            <span class="exlprint"> | </span>
            <button mat-icon-button>
              <a title="Excel" [tabIndex]="7" (keyup.enter)="exportAsXLSX()"> 
                <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
              </a>
            </button>
          </div>  
          <div  fxLayoutAlign="end">
            <mat-form-field  appearance="outline">
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input [tabIndex]="4">
              <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            </mat-form-field>
          </div>
      </div>
    </div>
  <div  fxLayout="column" class="table-pad">
      <div class="mat-elevation-z1 example-container">
        <div id="table" #table=''>
        <table   mat-table [dataSource]="dataSource" style="">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No</th>
            <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
        </ng-container>
          <ng-container matColumnDef="employee_name">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:nowrap ;"> Name</th>
            <td mat-cell *matCellDef="let row;" style="white-space:nowrap;">{{row.employee_name}}</td>
          </ng-container>
          <ng-container matColumnDef="contact">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:initial">Contact Number</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.contact}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Email Address</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.email}} </td>
          </ng-container>
          <ng-container matColumnDef="client_name">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Client</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.client_name}} </td>
          </ng-container>
          <ng-container matColumnDef="employee_type">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Associate type</th>
            <td mat-cell *matCellDef="let row"  style="text-align: center;"> {{row.employee_type}} </td>
          </ng-container>
          <ng-container matColumnDef="qualification_type">
            <th mat-header-cell *matHeaderCellDef style="text-align: right;white-space:nowrap;">Qualification</th>
            <td mat-cell *matCellDef="let row" style="white-space:nowrap;text-align: center;" >{{row.qualification_type}}  </td>
          </ng-container>

          <ng-container matColumnDef="specialization_type">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:nowrap;">Specialization</th>
            <td mat-cell *matCellDef="let row"  style="text-align: center;white-space:nowrap;"> {{row.specialization_type}} </td>
          </ng-container>
          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Created By</th>
            <td mat-cell *matCellDef="let row"  style="text-align: center;"> {{row.created_by}} </td>
  
          </ng-container>
          <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
          </tr>
  
        </table>
         </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  </mat-card>
  </div>
  
