import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from '../../excel-service.service';
import { ReportsService } from '../../reports.service';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as XLSX from 'xlsx';


import * as _moment from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import {default as _rollupMoment} from 'moment';
const moment =  _moment;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SummaryReportComponent implements OnInit {
  List: any[] = [

  ];
  @ViewChild('table') table!: ElementRef;

  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
   today = new Date();
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
  minToDate: any;
  pageLoading=true;
  constructor(public reportsService: ReportsService, public datePipe: DatePipe, public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog, private excelService: ExcelServiceService) { }
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata:any;
  year:any;
  months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
  searchForm = this.formBuilder.group({ fromDate: [this.maxDate], toDate: [this.maxDate], Users: ['0'] });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno','empname', 'attendancedate', 'firstlogintime',
  'lastlogouttime', 'totalhours', 'productivehours','ot','ph', 'action'];
  isLoading = false;
  filteredEmployees: any = [];
  searchControl = new FormControl('');
  ngOnInit() {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
     this.Searchform();
    this.getEmployeelist();
    this.searchForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue != null) {
        this.minToDate = selectedValue._d;    
      }
    });
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });
  }
  getEmployeelist() {
    let obj={
     "rm_id":this.userSession.id,
    };
this.reportsService.getTotalEmployeslistByManagerId(obj).subscribe((res: any) => {
 if (res.status) {
   this.employeelist = [];
   this.employeelist = res.data;
   this.filteredEmployees = this.employeelist
   this.searchForm.controls.Users.setValue('0');
 }

});
}
  //All Employees API
  Searchform() {
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);
  

    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-d");
    let userId = this.searchForm.controls.Users.value;
    if (userId == '0') {
      userId=null;
    }
    let data = {
      "manager_empid":this.userSession.id,
      'employee': userId,
      'fromdate': fromDate,
      'todate': toDate
    }
    this.isLoading = true;
    this.reportsService.getAttendanceSummaryReport(data).subscribe((res: any) => {
      this.List=res.data;
      this.List.forEach((e:any)=>{
        if(e.breaks!=null){
          e.breaks=e.breaks.split(',')
        }
      })

      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.List);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
    }, error => {
      this.isLoading = false;
      error.error.text
    });
  }


  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(this.maxDate);
    this.searchForm.controls.toDate.setValue(this.maxDate);
    this.searchForm.controls.Users.setValue('0');
    this.Searchform();



  }

  openDialog(item:any): void {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      width: '1200px',position:{top:`70px`},
      data: {attendanceid:item.attendanceid ,}
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  exportAsXLSX() {

    let fromdate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-yyyy");
    let todate = this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-yyyy");
  
    // Format the data to match the required columns
    const exportData = this.List.map((row, index) => ({
      "S.No": index + 1,
      "Employee Name": row.empname,
      "Date": this.datePipe.transform(row.attendancedate, "dd-MM-yyyy"),
      "First In": this.datePipe.transform(row.firstlogintime, "dd-MM-yyyy HH:mm:ss"),
      "Last Out": this.datePipe.transform(row.lastlogouttime, "dd-MM-yyyy HH:mm:ss"),
      "Total Hours": row.totalhours,
      "Productive Hours": row.productivehours,
      "OT Hours": row.overtime_minutes,
      "Permission Hours": row.permissionhours
    }));
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance_Summary_Report');
  
    /* save to file */
    XLSX.writeFile(wb, fromdate + " - " + todate + " - " + 'Attendance_Summary_Report.xlsx');
  

  }

  getPageSizes(): number[] {
      
  var customPageSizeArray = [];
  if (this.dataSource.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource.data.length);
  return customPageSizeArray;
  }
  

  filterEmployees(searchText: any) {
    this.filteredEmployees = this.employeelist.filter((val:any) =>
      val.empcode.toLowerCase().includes(searchText.toLowerCase()) ||
      val.empname.toLowerCase().includes(searchText.toLowerCase()) 
    );
    if(this.filteredEmployees.length<=0){
      this.searchControl.setValue('');
    }else{
      
    }
  }

  downloadPDF() {
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
    let data = {
      'list': this.List,
      'report_date': fromdate +' '+'_'+' '+todate
    }
    this.reportsService.getAttendanceSummaryReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'attendance_summary_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}

