
<div fxLayout="column" class="first-col"fxFlex="100" style="padding-top: 2%;font: small-caption;" *ngIf="!localdata">
  <mat-card style="align-items: center;"><mat-label>Client details not found.</mat-label></mat-card>
</div>
<div fxLayout="column" class="first-col"fxFlex="100" style="padding-top: 2%;font: small-caption;" *ngIf="localdata">
<mat-card>
    <div fxLayout="row"  fxFlex.xs="100" fxFlex="100" class="mx-2 my-2"  fxLayoutAlign.xs="center" *ngIf="localdata">
      <div  fxLayout="column"  fxFlex.xs="100" fxFlex="65" style="padding-right: 2%;" fxLayoutAlign="space-between">              
        <!-- <div *ngIf="addActivityScreen" fxFlex="100"  fxLayoutAlign="space-between center" >
           <img  style="width: 50%;height:auto;" [src]="activityImageDataUrl.imageData" *ngIf="activityImageDataUrl && activityImageDataUrl.imageData" >
        </div>  -->
        <div fxLayout="column"  fxFlex="100"  fxLayoutAlign.xs="center" style="padding-top: 2%;" >
          <mat-label> <span class="title"    style="font-size: large;font-weight: 500;" >  Client Details   </span> </mat-label>
              <mat-card style="width:100%; margin:10px;padding: 1%;height: auto;" class="outline" style="background-color:aliceblue;">
                  <div fxLayout="column" style="font-weight: 500;">
                    <div fxLayout="column" fxFlex="65"  style="padding:5px">
                      <mat-card-title class="title"  >
                        <div fxLayout="row" fxFlex ="95" style="flex-direction: column;">
                          {{localdata.client_name}} 
                        </div>
                      
                        <div fxLayout="row" fxFlex ="5" style="flex-direction: column;">
                          <div  style="align-self: center;    text-align: center;" *ngIf="!isEditClientEnabled">  
                            <mat-icon (click)="selectAddClient()" >edit</mat-icon>  
                          </div>
                        </div>
                      </mat-card-title>
                    </div>
                      <mat-divider style="margin-top: 5px;"></mat-divider>
                    
                      <mat-label style="font-weight: 500; margin-top: 5px;">
                        <div fxLayout="row" fxFlex="25"  ><span >Contact Person:</span></div>
                        <div fxLayout="row" fxFlex="75" >
                            <span  > {{localdata.contact_person_name}}{{localdata.contact?',':''}}  &nbsp;
                              <mat-icon *ngIf="localdata.contact && localdata.contact.toString().length" class="mat-icon-size"> call &nbsp;</mat-icon>{{localdata.contact}}{{localdata.email?',':''}}  &nbsp; 
                            <mat-icon *ngIf="localdata.email && localdata.email.toString().length" class="mat-icon-size"> email &nbsp;</mat-icon>{{localdata.email | lowercase}} &nbsp; 
                          </span> 
                        </div>
                      </mat-label>
                    
                    
                      <mat-divider style="margin-top: 5px;"></mat-divider>
                        <mat-label  style="font-weight: 500; margin-top: 5px;">
                          <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">Address:</span></div>
                          <div fxLayout="row" fxFlex="75" ><span >
                            {{localdata.address1}} {{localdata.address2 ? ', '+localdata.address2:'' }} 
                          </span></div>
                        </mat-label>
                        <mat-divider  style="margin-top: 5px;"></mat-divider>
                        <mat-label style="font-weight: 500; margin-top: 5px;">
                          <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">City:</span></div>
                          <div fxLayout="row" fxFlex="75">
                            <span >{{localdata.cityname}} {{localdata.pincode ? ', Pincode: '+localdata.pincode:'' }}</span></div>
                        </mat-label>
                        <mat-divider  style="margin-top: 5px;"></mat-divider>
                        <mat-label style="font-weight: 500; margin-top: 5px;">
                          <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">State:</span></div>
                          <div fxLayout="row" fxFlex="75" ><span >{{localdata.statename}}</span></div>
                        </mat-label>
                        <mat-divider  style="margin-top: 5px;"></mat-divider>
                        <mat-label style="font-weight: 500; margin-top: 5px;"> 
                          <mat-divider  style="margin-top: 5px;"></mat-divider>
                          <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">Country:</span></div>
                          <div fxLayout="row" fxFlex="75" ><span >{{localdata.countryname}}</span></div>
                        </mat-label>
                        <mat-divider  style="margin-top: 5px;"></mat-divider>
                        <mat-label style="font-weight: 500; margin-top: 5px;">
                          <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">Description:</span></div>
                          <div fxLayout="row" fxFlex="75" ><span >{{localdata.notes}}</span></div>
                        </mat-label>
                         <mat-divider  *ngIf="localdata && localdata.target_price" style="margin-top: 5px;"></mat-divider>
                      <mat-label style="font-weight: 500; margin-top: 5px;" *ngIf="localdata && localdata.target_price">
                        <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">Expected Price:</span></div>
                        <div fxLayout="row" fxFlex="25" ><span ><b>{{localdata && localdata.target_price?(localdata.target_price | currency:'INR'):''}}</b></span></div>
                      </mat-label>
                        <mat-divider  style="margin-top: 5px;"></mat-divider>
                      <div fxLayout="column" fxFlex="35" style="padding:5px;align-items: flex-end;align-self: self-end;">
                        <img class="img-fluid custom-image" style="width: 50%;"   [src]="getClientImageDisplay(localdata)">
                        <span *ngIf="localdata.emp_created">Created on : <b>{{localdata && localdata.created_on?getFormattedDateTime(localdata.created_on):''}}</b>  by <b>{{localdata && localdata.emp_created?localdata.emp_created:''}} </b></span>
                      </div>   
                     
                  </div> 
              </mat-card>
          
        </div>
        <div fxLayout="column"  fxFlex="100"  fxFlex.xs="100%" fxLayout.xs="center" fxLayoutAlign="space-between" style="padding: 1%;" >
          <div fxLayout="row"  fxLayoutAlign="space-between center"   style="background-color: #28acaf;color: white;height: 45px;padding: 1%;"> 
            <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                <p  class="main_title"  style="align-self: end; font-size: large;font-weight: 500;"> Activities <span *ngIf="activitiesData && activitiesData.length">({{activitiesData.length}})</span></p>
              </div>
              <div style="margin-left: 50%;" >
                <button mat-icon-button (click)="addScreen('activity')"  [disabled]="isWebRestricted? true : false"  matTooltip="Add Activity" text="Add Activity">{{addActivityScreen?'Hide Activity':'Add Activity'}}
                  <mat-icon style="font-size: 25px">add_circle</mat-icon>
                </button>
              </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly right" >
              <mat-icon *ngIf="showActivitesData" (click)="showActivitesData = !showActivitesData">expand_more</mat-icon>
              <mat-icon *ngIf="!showActivitesData" (click)="showActivitesData = !showActivitesData">expand_less</mat-icon>
            </div>
          </div>
          <div  fxLayout="column"  fxLayoutAlign="space-between" >
            <div style="display: flex;padding: 1em" *ngIf="showActivitesData" >
              <mat-icon style="color: green;">location_on</mat-icon><span style="padding-top: 5px;color: green;" class="no-break">Location matched</span> &nbsp;&nbsp;&nbsp;&nbsp;
              <mat-icon style="color: red;">location_on</mat-icon> <span style="padding-top: 5px;color: red;" class="no-break">Location not matched </span> &nbsp;&nbsp;&nbsp;&nbsp;
              <mat-icon style="color: lightcoral;">location_off</mat-icon><span style="padding-top: 5px;color: lightcoral;" class="no-break">Location not available for Activity</span> &nbsp;&nbsp;
            </div>
           
            <div *ngIf="showActivitesData" >
              <ul>
                <li>
                  <span class="text-warning">
                      If client's location in Add client is not available, location from First Visit from Activity is considered as client's location.
                  </span>
                </li>
              </ul>
            </div>
            <div fxLayout="column"  fxFlex="100"  fxLayoutAlign.xs="center" style="padding-top: 2%;" style="font-size: small;" *ngIf="showActivitesData" >
              <!-- <div  >
                <mat-label  >Total Visits:&nbsp; {{totalVisits}}</mat-label>
              </div> -->
            
              <mat-card-title class="title"  style="font-size: small;">{{activitiesData && activitiesData.length?'':'No activities added.'}}  </mat-card-title>
                <ng-container *ngFor="let activity of activitiesData;index as i;">
                  
    
                <mat-card style="width:100%; margin:2px;padding: 1%;height: auto;" class="outline" [ngStyle]="{'background-color': activity.created_by == userSession.id ? 'ghostwhite' : 'beige' }">
                    <!-- <mat-label fxLayoutAlign="center" style="font-size: 17px;">{{activity.created_on}}</mat-label> -->
                    <!-- <mat-divider></mat-divider> -->
                    <div fxLayout="column"   fxLayoutAlign="space-evenly" style="padding-left:5px;padding-right: 5px;padding-left: 2px;padding-bottom: 2px;font-weight: 500;">
                      <div fxLayout="row" fxFlex="100"  fxLayoutAlign="space-between" >
                        <div fxLayout="column" fxFlex="5">
                          <mat-icon   (click)="activity.display = !activity.display">{{activity.display ? 'expand_less':'expand_more'}}</mat-icon>
                        </div>
                        <mat-label fxLayout="column" fxFlex="10"  >{{activity.activity_type}}</mat-label>
                        <mat-label fxLayout="column" fxFlex="25"   >{{getFormattedDateTime(activity.created_on)}}</mat-label>
                        <mat-label fxLayout="column" fxFlex="20"  >{{activity.client_status}}</mat-label>
                        <mat-label fxLayout="column" fxFlex="20"  >{{activity.activity_status}}</mat-label>
                        <div fxLayout="column" fxFlex="5">
                          <i  (click)="openMapDialog()"  *ngIf="activity.activity_type==='VISIT' && activity.lat && activity.lat.toString().length && activity.lng && activity.lng.toString().length" [ngStyle]="{'color': activity.location_icon_color}"   class="material-icons"> {{activity.location_icon_val}}</i> 
                        </div>
                        
                       
                    </div>
                    </div>
                    <!-- <div fxLayout="column"   fxLayoutAlign="space-evenly" >
                      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between left" >
                        <mat-label fxLayout="column" fxFlex="10"  style="padding:5px;font-weight: 500;">Follow-up:</mat-label>
                        <mat-label fxLayout="column" fxFlex="25"  style="padding:5px;font-weight: 500;">{{getFormattedDateTime(activity.next_follow_up_on)}}</mat-label>
                        <mat-label fxLayout="column" fxFlex="35"  style="padding:5px;font-weight: 500;">{{activity.activity_status}}</mat-label>
                        <div fxLayout="column" fxFlex="5">
                          <mat-icon style="padding:1px;font-weight: 500;" (click)="activity.display = !activity.display">{{activity.display ? 'expand_less':'expand_more'}}</mat-icon>
                        </div>
                      </div>
                    </div> -->
                    
                    <div fxLayout="column"   fxLayoutAlign="space-evenly" >
                      <div fxLayout="row"    *ngIf="activity.display">
                        <div fxLayout="column" fxFlex="75"  style="padding:5px">  
                          <mat-label  *ngIf="activity.location && activity.location.toString().length && activity.activity_type==='VISIT'" style="padding-bottom: 1%;">
                            <div fxLayout="row" fxFlex="20"  ><span style="font-weight: 500;">Location:</span></div>
                            <div fxLayout="row" fxFlex="80" style="padding-left: 10px;"><span >{{activity.location}}</span></div>
                          </mat-label>
                          <mat-divider *ngIf="activity.location && activity.location.toString().length" style="padding-bottom: 1%;"></mat-divider>
                            <mat-label *ngIf="activity.notes && activity.notes.toString().length" style="padding-bottom: 1%;">
                              <div fxLayout="row" fxFlex="20"  ><span style="font-weight: 500;">Description:</span></div>
                              <div fxLayout="row" fxFlex="80" style="padding-left: 10px;overflow-wrap: anywhere;"><span >{{activity.notes}}</span></div>
                            </mat-label>
                            <mat-divider  *ngIf="activity.notes && activity.notes.toString().length" style="padding-bottom: 1%;"></mat-divider>
                            <mat-label style="padding-bottom: 1%;">
                              <div fxLayout="row" fxFlex="20"  ><span style="font-weight: 500;">Follow-up:</span></div>
                              <div fxLayout="row" fxFlex="80" style="padding-left: 10px;"><span >{{getFormattedDateTime(activity.next_follow_up_on)}}</span></div>
                            </mat-label>
                        </div>
                        <div fxLayout="column" fxFlex="25" style="padding:5px;align-items: flex-end;align-self: self-end;" >
                          <img class="img-fluid custom-image" style="width: 80%;"  *ngIf="activity.imageData && activity.imageData.success" [src]="activity.imageData.image">
                          <span *ngIf="activity.empname" style="white-space: nowrap;">{{activity.empname? 'Created By '+activity.empname:'' }}</span>
                        </div> 
                        
                      </div>
                    </div>
                    
                    
                </mat-card>
                </ng-container>
              
            
            </div>
          </div>
        </div>
        <div fxLayout="column"  fxFlex="100"  fxFlex.xs="100%" fxLayout.xs="center" fxLayoutAlign="space-between" style="padding: 1%;" *ngIf="localdata && localdata.final_price">
          <div fxLayout="row"  fxLayoutAlign="space-between center"  style="background-color: #28acaf;color: white;height: 45px;padding: 1%;"> 
            <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                <p  class="main_title"  style="align-self: end; font-size: large;font-weight: 500;"> Payments <span *ngIf="paymentsData && paymentsData.length">({{paymentsData.length}})</span></p>
              </div>
              <div style="margin-left: 45%;" >
                <button mat-icon-button (click)="addScreen('pay')"  [disabled]="isWebRestricted? true : false"  matTooltip="Add Payment" text="Add Payment"> {{addPaymentScreen ? 'Hide Payment':'Add Payment'}}
                  <mat-icon style="font-size: 25px">add_circle</mat-icon>
                </button>
              </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly right" >
              <mat-icon *ngIf="showPayments" (click)="showPayments = !showPayments">expand_more</mat-icon>
              <mat-icon *ngIf="!showPayments" (click)="showPayments = !showPayments">expand_less</mat-icon>
            </div>
          </div>
          <div  fxLayout="column" style="padding-top:1%;"  [hidden]="!showPayments">
              
              <div  fxLayout="row"  fxFlex="100"  fxFlex.xs="100%" fxLayout.xs="center" fxLayoutAlign="space-between" style="margin-bottom: 5px;">
                <span style="font-weight: 500; margin: 5px;"  *ngIf="localdata.final_price" >Final Price: &nbsp;&nbsp;<b>{{localdata && localdata.final_price?(localdata.final_price | currency:'INR'):''}}</b> &nbsp; &nbsp; 
                  <!-- <mat-icon style="font-size: large;margin-bottom:-5px;cursor: pointer;" *ngIf="userSession.is_sales_manager" (click)="changeFinalPrice()" >edit</mat-icon> -->
                </span>
                <span style="font-weight: 500; margin: 5px;align-items: self-end;" *ngIf="collectedTotal">Collected Amount: &nbsp;&nbsp;<b>{{collectedTotal ? (collectedTotal | currency:'INR'):''}}</b></span>
              </div>
              
              <div fxLayout="row"  id="table" #table=''>
                <table   mat-table [dataSource]="dataSource" style="">
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No</th>
                    <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                </ng-container>
                  <ng-container matColumnDef="created_on">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:nowrap ;"> Collected On</th>
                    <td mat-cell *matCellDef="let row;" style="white-space:nowrap;">{{row.created_on | date: 'dd-MM-yyyy'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="mode">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;white-space:initial">Payment Method</th>
                    <td mat-cell *matCellDef="let row" style="text-align: center;"> {{row.mode}} </td>
                  </ng-container>
                  <ng-container matColumnDef="collected_amount">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right;">Collected Amount</th>
                    <td mat-cell *matCellDef="let row" style="text-align: right;"> {{row.collected_amount | currency:'INR'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="pending_amount">
                    <th mat-header-cell *matHeaderCellDef style="text-align: right;">Pending Amount</th>
                    <td mat-cell *matCellDef="let row" style="text-align: right;"> {{row.pending_amount | currency:'INR'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="created_by">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Created By</th>
                    <td mat-cell *matCellDef="let row"  style="text-align: center;"> {{row.created_by_name}} </td>
                  </ng-container>
                  <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
          
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
                  </tr>
          
                </table>
               </div>
               <div fxLayout="row" >
              <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
            
          </div>
           

        </div>
      </div>
      <div fxFlex.xs="100" fxFlex="35"  fxLayoutAlign.xs="center" style="padding-right: 1%;padding-top: 2%;" *ngIf="localdata && !addActivityScreen && !addPaymentScreen" >
        <mat-label> <span class="title"    style="font-size: large;font-weight: 500;" >   Summary  </span> </mat-label>
              <mat-card style="width:100%; margin:10px;padding: 1%;height: auto;" class="outline" style="background-color:aliceblue;">
                  <div fxLayout="column" style="font-weight: 500;" *ngIf="activitiesTotals && activitiesTotals.length">
                    <div fxLayout="column" fxFlex="65"  style="padding:5px">
                      <mat-card-title class="title"  >
                        <div fxLayout="row" fxFlex ="95" style="flex-direction: column;">
                          Activity Totals
                        </div>
                      </mat-card-title>
                    </div>
                        <div *ngFor="let t of activitiesTotals">
                          <mat-divider style="margin-top: 5px;"></mat-divider>
                          <mat-label  style="margin-left: 5px; margin-top: 5px;">
                            <div fxLayout="row" fxFlex="25"  ><span style="">{{t.type? ''+t.type+'`S:':'' }}</span></div>
                            <div fxLayout="row" fxFlex="75" ><span >
                                {{t.count?t.count:0 }}
                            </span></div>
                          </mat-label>
                        </div>
                  </div> 
              </mat-card>
      </div>
      <div fxFlex.xs="100" fxFlex="35"  fxLayoutAlign.xs="center" style="padding-right: 1%;" *ngIf="localdata && addActivityScreen" >
        <!-- <mat-card-header class="headerbox" style="">
          <mat-card-title class="title"> Activity </mat-card-title>
        </mat-card-header> -->
        <form [formGroup]="addActivity" autocomplete="off" style="padding-top: 1%;" *ngIf="!isWebRestricted">
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center"  style="align-items: baseline;">
              <mat-form-field   fxFlex="90" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline" >
                <mat-label>Activity Type </mat-label>
                    <mat-select    formControlName="activityTypeId" required >
                        <ng-container >
                            <div *ngFor="let t of activityTypeList">
                                <mat-option [value]="t.id">{{t.display_name}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="addActivity.controls.activityTypeId.errors?.required">
                            Please select activity type
                    </mat-error>
              </mat-form-field>
              <button class="btn" style="background-color: unset;" type="button" *ngIf="addActivityScreen">
                <i  (click)="openWebcamModalVisit()"  class="material-icons" [ngStyle]="{'color':(activityImageDataUrl && activityImageDataUrl.imageData)?'green':'red'}">  camera_alt  </i>  
                  </button>
            <!-- <mat-label style="font-variant: all-petite-caps;align-self: start;" *ngIf="activityImageDataUrl && activityImageDataUrl.imageData">Image Uploaded</mat-label> -->
                        
              <!-- <img *ngIf="activityImageDataUrl && activityImageDataUrl.imageData" [src]="activityImageDataUrl.imageData" alt="Captured Image" style=" width: 50px; height: auto;">   
              <mat-icon *ngIf="!(activityImageDataUrl && activityImageDataUrl.imageData)" (click)="openWebcamModalVisit()" >camera</mat-icon> -->
            </div>
          
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
              <mat-form-field   fxFlex="100"  class="mx-2 my-2" appearance="outline">
                <mat-label>Follow-up Date</mat-label>
                <input matInput [matDatepicker]="nextFollowUpDate" [min]="minDate" placeholder="" formControlName="nextFollowUpDate"  (keypress)="false" required>
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="nextFollowUpDate"></mat-datepicker-toggle>
                <mat-datepicker #nextFollowUpDate></mat-datepicker>
                <mat-error class="con-error" *ngIf="addActivity.controls.nextFollowUpDate.errors?.required">
                    Please enter follow-up date
              </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center"> 
              <mat-form-field   fxFlex="100"  class="mx-2 my-2" appearance="outline">
                <mat-label>Follow-up Time  <!-- <span style="color: red">*</span> -->  </mat-label>
                <input matInput matTimepicker  placeholder="" formControlName="nextFollowUpTime"  >
                <mat-error class="con-error" *ngIf="addActivity.controls.nextFollowUpTime.errors?.required">
                    Please select followup time
                </mat-error>
            </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
              <mat-form-field   fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"   class="mx-2 my-2" appearance="outline" >
                <mat-label>Demo Status </mat-label>
                    <mat-select    formControlName="activityStatusId" required   >
                        <ng-container >
                            <div *ngFor="let s of activityStatusList">
                                <mat-option [value]="s.id">{{s.display_name}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="addActivity.controls.activityStatusId.errors?.required">
                              Please select demo status
                    </mat-error>
              </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field   fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline" >
              <mat-label>Status </mat-label>
                  <mat-select formControlName="clientStatusId" required >
                      <ng-container >
                          <div *ngFor="let c of clientStatusList">
                              <mat-option [value]="c.id">{{c.display_name}}</mat-option>
                          </div>
                      </ng-container>
                  </mat-select>
                  <mat-error class="con-error" *ngIf="addActivity.controls.clientStatusId.errors?.required">
                          Please select status
                  </mat-error>
              </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea matInput  formControlName="activityNotes"  required matInput placeholder="" maxlength="500" style="height: 75px;"></textarea>
              <mat-error class="con-error" *ngIf="addActivity.controls.activityNotes.errors?.required">
                  Please enter description
            </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="showFinalPrice">
            <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline">
              <mat-label>Final Price</mat-label>
              <input  formControlName="finalPrice" matInput placeholder="" onlyNumber  >
              <mat-error class="con-error" *ngIf="addActivity.controls.finalPrice.errors?.required">
                  Please enter Final Price
            </mat-error>
            </mat-form-field>
          </div>
           
          <div style="align-self: center;    text-align: center;" *ngIf="!isWebRestricted">    
            <button [tabIndex]="7" class="btn btn-primary mr-2 mb-2"   (click)="addCrmActivity()" type="submit" [disabled]='isEditClientEnabled'>
                <span>Submit</span>
            </button>
            <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="closeCrmActivity()" type="reset" [disabled]='isEditClientEnabled'>
                <span>Cancel</span>
            </button> 
        </div>
        
          </form>
      </div>
      <div fxFlex.xs="100" fxFlex="35"  fxLayoutAlign.xs="center" style="padding-right: 1%;" *ngIf="localdata && localdata.final_price && addPaymentScreen" >
        <!-- <mat-card-header class="headerbox" style="">
          <mat-card-title class="title"> Activity </mat-card-title>
        </mat-card-header> -->
        <form [formGroup]="addPayment" autocomplete="off" style="padding-top: 1%;" *ngIf="!isWebRestricted">
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex="100"  class="mx-2 my-2" appearance="outline">
              <mat-label>Collected Date</mat-label>
              <input matInput [matDatepicker]="collectedDate" [min]="minDate" placeholder="" formControlName="collectedDate"  (keypress)="false" required>
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="collectedDate"></mat-datepicker-toggle>
              <mat-datepicker #collectedDate></mat-datepicker>
              <mat-error class="con-error" *ngIf="addPayment.controls.collectedDate.errors?.required">
                  Please enter collected date
            </mat-error>
            </mat-form-field>
          </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center"  style="align-items: baseline;">
              <mat-form-field   fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline" >
                <mat-label>Payment Method</mat-label>
                    <mat-select    formControlName="modeId" required >
                        <ng-container >
                            <div *ngFor="let p of paymentModes">
                                <mat-option [value]="p.mode_id">{{p.mode}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="addPayment.controls.modeId.errors?.required">
                            Please select payment method
                    </mat-error>
              </mat-form-field>
            </div>
           
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" >
              <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline">
                <mat-label>Collected Amount</mat-label>
                <input  formControlName="collectedAmount" matInput placeholder=""  onlyNumber>
                <mat-error class="con-error" *ngIf="addPayment.controls.collectedAmount.errors?.required">
                    Please enter collected amount
              </mat-error>
              <mat-error class="con-error" *ngIf="addPayment.controls.collectedAmount.errors?.min">
                  Please enter valid amount
              </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" style="FONT-SIZE:medium;FONT-WEIGHT:400;margin:10px;">
              <mat-label>Pending Amount:&nbsp; {{getPendingAmout() | currency:'INR'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
              <mat-form-field fxFlex="100" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput  formControlName="paymentNotes"  required matInput placeholder="" maxlength="500" style="height: 75px;"></textarea>
                <mat-error class="con-error" *ngIf="addPayment.controls.paymentNotes.errors?.required">
                    Please enter description
              </mat-error>
              </mat-form-field>
            </div>
          
           
          <div style="align-self: center;    text-align: center;" *ngIf="!isWebRestricted">    
            <button [tabIndex]="7" class="btn btn-primary mr-2 mb-2" [disabled]="paymentInProcess"  (click)="setPayment()" type="submit" >
                <span>Submit</span>
            </button>
            <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="closePayment()" type="reset" >
                <span>Cancel</span>
            </button> 
        </div>
        
          </form>
      </div>
    </div>
    
  </mat-card>
  
</div>