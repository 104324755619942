import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelServiceService } from '../../excel-service.service';
import { ReportsService } from '../../reports.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ShiftEmployeesDialogComponent } from '../shift-employees-dialog/shift-employees-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

// import {default as _rollupMoment} from 'moment';
const moment = _moment;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-shifts-wise-report',
  templateUrl: './shifts-wise-report.component.html',
  styleUrls: ['./shifts-wise-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ShiftsWiseReportComponent implements OnInit {

  constructor(public reportsService: ReportsService, public datePipe: DatePipe,
    public formBuilder: FormBuilder, public dialog: MatDialog,
    private excelService: ExcelServiceService, private reportService: ReportsService,private spinner: NgxSpinnerService) { }

  List: any[] = [
  ];
  employeelist: any;
  Users: any;
  minDate = new Date('2020/01/01'); maxDate = new Date();
  userSession: any;
  headersList: any = [];
  @ViewChild('table') table!: ElementRef;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  filter = new FormControl();
  shiftReportForm: any = FormGroup;

  shiftDataList: any = [];
  employeesList: any = [];
  headerlist: any = [];
  rmId: any;
  ngOnInit() {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.rmId = this.userSession.id;
    this.shiftReportForm = this.formBuilder.group(
      {
        fromDate: [new Date()],
        toDate: [new Date()],

      });
    this.getShiftWiseReportList();
  }

  Searchform() {
    this.getShiftWiseReportList();
  }

  getShiftWiseReportList() {
    this.shiftDataList = [];
    let fromDate = this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "y-MM-d");
    let data = {
      "rm_id_value": this.rmId,
      "from_date_value": fromDate,
      "to_date_value": toDate,
    }
    this.spinner.show();
    this.reportService.getShiftsWiseEmployeesCountsByDates(data).subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.headersList.push(this.shiftDataList[0]);
        this.headersList.slice(1);
        this.spinner.hide();
      } else {
        this.shiftDataList = [];
        this.spinner.hide();
      }
      this.spinner.hide();
    })
  }

  getObjectKeys(obj: any): string[] { return Object.keys(obj); }

  view(data: any) {
    let givenData = {
      "rm_id_value": this.rmId,
      "date_value": data,
    }
    let dialogRef = this.dialog.open(ShiftEmployeesDialogComponent, {
      width: '600px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });

  }


  resetform() {
    this.shiftReportForm.reset();
    this.shiftReportForm.controls.fromDate.setValue(new Date());
    this.shiftReportForm.controls.toDate.setValue(new Date());
    this.Searchform();
  }

  removeLastColumn(table: HTMLTableElement): void {
    const rows = table.querySelectorAll('tr');
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td, th');
      if (cells.length > 0) {
        const lastCell = cells[cells.length - 1];
        row.removeChild(lastCell);
      }
    });
  }


  public exportPDF(): void {
    let fdate = (this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "dd-MM-YYYY"))
    let tdate = (this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "dd-MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    this.removeLastColumn(pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: fdate + " " + " " + "To" + " " + tdate + "-" + "Shift Wise Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: fdate + " " + " " + "To" + " " + tdate + " - " + "Shift Wise Report \n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html,

      ], styles: {
        'html-table': {
          background: 'white' // it will add a yellow background to all <STRONG> elements
        }
      },
      pageOrientation: 'landscape',
      pageSize: "EXECUTIVE"
      //'portrait'
    }).download("Shift Wise Report.pdf");
    this.Searchform();
  }

}
