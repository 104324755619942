<div [formGroup]="searchForm" style="padding-top: 1%;">
    <div fxLayout fxLayout="row" fxLayoutAlign.xs="center center" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" style="padding-left: 1%;">
            <div fxLayout="row" fxLayout.xs="column">

                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Month and Year</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                        [min]="minDate" [max]="max" (click)="fromDate.open()">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>


            </div>
            <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center">
                <span ngStyle.gt-md="margin-top: 5%">
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="3">
                        Search
                    </button>
                    <span class="line" style="color: #1898d5; font-size: 16px"></span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                        <span>Clear</span>
                    </button>
                </span>
            </div>
        </div>

        <div fxLayout="row xs-column" fxLayoutAlign="end" style="margin-top: 10px;margin-right: 3%">
            <div>

                <span class="exlprint"> | </span>
                <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                </a>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div style="margin-top:5px;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center">
    <div>
        <span class="exlprint">
            <mat-form-field appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
            </mat-form-field>
        </span>

    </div>
</div>


<div class="tablePadding">
    <div class="mat-elevation-z1 example-container">
        <div id="table" #table=''>

            <table id="table" mat-table [dataSource]="dataSource" matSort>


                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                    <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
                    <div *ngIf="disCol === 'S.No'">
                        <td mat-cell *matCellDef="let element; let i=index;">
                            {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                    </div>
                    <div *ngIf="disCol !== 'S.No'">
                        <td mat-cell *matCellDef="let element"><span>{{element[disCol]}} </span></td>
                    </div>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="text-align: center;">No data
                        found</td>
                </tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>



    </div>

</div>