<div [formGroup]="detailedLeaveReports" class="tableP" style="margin-left: 10px;">
  <div fxLayout="row" fxLayout.xs="column">
    <mat-form-field fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline" *ngIf="ishide"
      style="padding-right: 3px">
      <mat-label>Employee Name and Id</mat-label>
      <mat-select fxFlex.xs="100" formControlName="employeeId" required>
        <input matInput [formControl]="searchControlforEmpname" type="text" placeholder="  Search" class="search-align">
        <mat-option value="All">All </mat-option>
        <ng-container *ngFor="let e of filteredEmpname">
          <mat-option [value]="e.empid">{{ e.empname }} - {{e.empcode}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
      <mat-select formControlName="DateFormate" required>
        <mat-option value="currentWeek"> Current Week</mat-option>
        <mat-option value="lastWeek">Last Week</mat-option>
        <mat-option value="currentMonth"> Current Month</mat-option>
        <mat-option value="lastMonth"> Last Month</mat-option>
        <mat-option value="quaterOne"> Quarter 1(Jan-Mar)</mat-option>
        <mat-option value="quaterTwo"> Quarter 2(Apr-Jun)</mat-option>
        <mat-option value="quaterThree"> Quarter 3(Jul-Sep)</mat-option>
        <mat-option value="quaterFour"> Quarter 4(Oct-Dec)</mat-option>
        <mat-option value="custom"> Custom </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isCustom" fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column">
    <mat-form-field fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
      <mat-label>From Date</mat-label>
      <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" formControlName="fromDate"
        (click)="fromDate.open()" required />
      <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker #fromDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
      <mat-label>To Date</mat-label>
      <input matInput readonly [matDatepicker]="toDate" [min]="minFromDate" placeholder="To Date"
        formControlName="toDate" (click)="toDate.open()" required />
      <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column">
    <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
      <mat-label>Leave Type</mat-label>
      <mat-select formControlName="leaveType" required>
        <input matInput [formControl]="searchControlforLeaveType" type="text" placeholder="  Search"
          class="search-align">
        <mat-option value="All"> All </mat-option>
        <ng-container *ngFor="let e of filteredLeaveTypes">
          <mat-option [value]="e.id">{{ e.leavename }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
      <mat-label>Leave Status</mat-label>
      <mat-select formControlName="leaveStatus" required>
        <mat-option value="All"> All</mat-option>
        <mat-option value="Approved">Approved</mat-option>
        <mat-option value="Submitted"> Submitted</mat-option>
        <mat-option value="Rejected"> Rejected</mat-option>
        <mat-option value="Cancel"> Cancelled</mat-option>
        <mat-option value="Deleted"> Deleted</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
      <mat-label>Designations</mat-label>
      <mat-select formControlName="designation" required>
        <input matInput [formControl]="searchControlforDesignation" type="text" placeholder="  Search"
          class="search-align">
        <mat-option value="All">All</mat-option>

        <ng-container *ngFor="let b of filteredDesignations">
          <mat-option [value]="b.id">{{ b.designation }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" style="justify-content: center" class="showLine">
    <span>
      <button class="btn btn-primary mr-2 mb-2" (click)="SearchLeaveDetailsForm()">
        Search
      </button>
    </span>
    <button class="btn btn-danger mr-2 mb-2" (click)="clearLeaveDetailsForm()">
      <span>Clear</span>
    </button>
  </div>
</div>
<div fxLayout="row xs-column" fxLayoutAlign="end  center" *ngIf="arrayList.length > 0">
  <div style="padding-right: 25px">
    <!-- <span class="exlprint"> | </span> -->
    <a title="PDF" (keyup.enter)="downloadPDF()"> <img (click)="downloadPDF()" src="./assets/images/icons/pdf.png"
        width="20px" height="20px" /></a>
    <span class="exlprint"> | </span>
    <a title="Excel">
      <img (click)="exportAsXLSXleaveDetailedReport()" src="./assets/images/icons/excel.png" />
    </a>
  </div>
</div>
<div class="tablePadding">
  <div class="mat-elevation-z1 example-container">
    <div id="table" #table="">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef>S. No.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
          </td>
        </ng-container>
        <ng-container matColumnDef="employeeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Emp. Name</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.employee_name }} </span>
          </td>
        </ng-container><ng-container matColumnDef="employeeId">
          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
            Emp. ID
          </th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.employee_code }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="leaveType">
          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
            Leave Type
          </th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.leavetype }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.designation }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="appliedDate">
          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap">
            Applied On
          </th>
          <td mat-cell *matCellDef="let row" style="white-space: nowrap">
            <span>{{ row.appliedon | date : "dd-MM-yyyy" }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>From</th>
          <td mat-cell *matCellDef="let row" style="white-space: nowrap">
            <span>{{ row.fromdate | date : "dd-MM-yyyy" }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="toDate">
          <th mat-header-cell *matHeaderCellDef>To</th>
          <td mat-cell *matCellDef="let row" style="white-space: nowrap">
            <span>{{ row.todate | date : "dd-MM-yyyy" }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="noOfDays">
          <th mat-header-cell *matHeaderCellDef>Days</th>
          <td mat-cell *matCellDef="let row" style="text-align: center">
            <span>{{ row.leavecount }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.leavestatus }} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="approvedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap">
            Approved By
          </th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.approvedby }} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="10" style="text-align: center">
            No data found
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
  </div>
</div>