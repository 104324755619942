<form [formGroup]="leaveTypeForm" autocomplete="off" mat-dialog-content>
<div class="example-container" style="text-align: center;">
    
      <h2 style="text-align: centre;">Add</h2>
      <div fxLayout="column">
          <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Leave Type Name</mat-label>
            <input matInput formControlName="leavetypename" placeholder="" required maxlength="50">
            <mat-error class="con-error" *ngIf="leaveTypeForm.controls.leavetypename.errors?.required">
              This field is required
            </mat-error>
          </mat-form-field>
          <br>
          <div class="extra" style="color: red;" *ngIf="isLeaveAlreadyExists">
            This leavetype  existed
          </div>
        
          <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Display Name</mat-label>
            <input matInput formControlName="displayname" placeholder="" required maxlength="100">
            <mat-error class="con-error" *ngIf="leaveTypeForm.controls.displayname.errors?.required">
              This field is required
          </mat-error>
          <!-- <mat-error class="con-error" *ngIf="isLeaveAlreadyExists">
            This field required
        </mat-error> -->
          
          </mat-form-field><br>
          <div class="extra" style="color: red;" *ngIf="isDisplayAlreadyExists">
            This displayname  existed
          </div>
   
          <mat-form-field class="popup" fxFlex.xs="100" style="padding-left: 10px;
          padding-right: 10px;"> 
            <mat-label>Color</mat-label>
            <input style="width: 50%;" matInput formControlName="colors" type="color"   placeholder="" required>
            <mat-error  *ngIf="leaveTypeForm.controls.colors.errors?.required">
              This field is required
            </mat-error>
            
            <!-- <mat-error *ngIf="isLeaveColorAlreadyExists" >
              This color  existeddd
            </mat-error> -->
           
          </mat-form-field><br>
          <div class="extra" style="color: red;"  *ngIf="isLeaveColorAlreadyExists" >
            This color  existed
          </div>
          </div>

    
  </div>
  <div fxLayoutAlign="center" mat-dialog-actions>
    <button class="btn btn-primary mr-2 mb-2"  (click)="submit()" >
        <span>Submit</span>
    </button>
    <!-- [disabled]="!leaveTypeForm.valid " -->
    <button class="btn btn-danger mr-2 mb-2"   (click)="cancel()" [mat-dialog-close]="data.NO">
        <span >Cancel</span>
    </button>
    <br />
</div>
</form>