 
  <div mat-dialog-content>
    {{data.message}}
   </div>
   <div mat-dialog-actions 
   fxLayout="row" style="justify-content: space-between; margin-top: 5px;">
   <button class="btn btn-primary mr-2 mb-2"  [mat-dialog-close]="data.YES">Yes</button>
   <button class="btn btn-danger mr-2 mb-2"  [mat-dialog-close]="data.NO" cdkFocusInitial>No</button>
   </div>
  
  
   