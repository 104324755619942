<div fxLayout="column">
  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title">Dashboard</mat-card-title>
    </mat-card-header>
    <form [formGroup]="adminTicketForm">
      <div fxLayout="row">
        <div fxFlex="40">
          <mat-card class="ticketOpen">
            <div style="margin: 10px;">
              <div><img class="image" src="../../../../../assets/img/TodayTicketOpened-icon.png" alt=""></div>
              <div style="margin: 25px;">Today Tickets Opened</div>
              <div style="font-size: 40px; margin-top: 30px;">10</div>
            </div>
          </mat-card>
        </div>
        <div fxFlex="60">
          <mat-card class="ticketStatus">
            <div style="margin-top: 10px;">
              <div style="text-align: center;"><img class="image" src="../../../../../assets/img/TicketStatus-icon.png"
                  alt=""></div>
              <div style="margin-top:10px; text-align:center; font-size: 22px; font-weight: 500;">Ticket Status</div>
              <div fxLayout="row" style="margin-top: 15px;">
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="30">* In Progress</div>
                    <div fxFlex="30">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage">
                        </div>
                      </div>
                    </div>
                    <div fxFlex="40"> {{progressPercentage}}% </div>
                  </div>
                </div>
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="30">* Pending</div>
                    <div fxFlex="30">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage"></div>
                      </div>
                    </div>
                    <div fxFlex="40" style="margin-left: 13px;"> {{progressPercentage}}% </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" style="margin-top: 15px;">
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="30">* On Hold</div>
                    <div fxFlex="30">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage">
                        </div>
                      </div>
                    </div>
                    <div fxFlex="40"> {{progressPercentage}}% </div>

                  </div>
                </div>
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="30">* ReOpened</div>
                    <div fxFlex="30">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage"></div>
                      </div>
                    </div>
                    <div fxFlex="40" style="margin-left: 13px;"> {{progressPercentage}}% </div>
                  </div>
                </div>
              </div>
              <div fxLayout="row" style="margin-top: 15px;">
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="30">* Cancelled</div>
                    <div fxFlex="30">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage">

                        </div>

                      </div>
                    </div>
                    <div fxFlex="40"> {{progressPercentage}}% </div>

                  </div>
                </div>
                <div fxFlex="50">
                  <div fxLayout="row">
                    <div fxFlex="70">* Awaiting Customer Response</div>
                    <div fxFlex="25">
                      <div class="progress-container">
                        <div class="progress-bar" [style.width.%]="progressPercentage"></div>
                      </div>
                    </div>
                    <div fxFlex="5"> {{progressPercentage}}% </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="40">
          <mat-card class="supportStatus">
            <h2>Support Status (Weekly)</h2>
            <div fxLayoutAlign="center">
              <chart #chart style="display: block; width: 280px; height: 300px;" [type]="doughChartType"
                [data]="doughnutChartData" [options]="doughnutChartOptions">
              </chart>
            </div>
          </mat-card>
        </div>
        <div fxFlex="60">
          <mat-card class="moduleTickets">
            <div fxLayout="column">
              <div>
                <h2>Module Ticket Insights</h2>
              </div>
              <div fxFlex="100">
                <chart #chart [type]="BarChartType" [data]="BarChartData" [options]="BarChartOptions"></chart>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div>
        <mat-card class="pendingTicket">
          <div fxLayout="column">
            <div fxLayoutAlign="space-between center">
              <div>
                <h2>Pending Ticket Analysis</h2>
              </div>
              <div>

                <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                  <mat-label>Month and Year</mat-label>
                  <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                    [min]="minDate" [max]="maxDate" (click)="fromDate.open()">
                  <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                  <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                    panelClass="example-month-picker">
                  </mat-datepicker>

                </mat-form-field>
              </div>
            </div>
            <div>
              <chart #chart [type]="chartType" [data]="chartData" [options]="chartOptions"></chart>
            </div>
            <!-- <div fxLayoutAlign="center" style="margin-top: 10px;">
              <h3>Based on raised ticket dates</h3>
            </div> -->
          </div>
        </mat-card>
      </div>
    </form>
  </mat-card>

</div>