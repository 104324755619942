<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="title">Overtime Reports</mat-card-title>
        </mat-card-header>
        <div fxLayout="column" [formGroup]="searchForm" style="padding: 1%;">
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Locations</mat-label>
                    <mat-select formControlName="location" required>
                        <ng-container>
                            <input class="search-align" type="text" placeholder="Search" [formControl]="searchControl"
                                matInput>
                            <mat-option value='0'>All Locations</mat-option>
                            <mat-option [value]="a.id"
                                *ngFor="let a of filteredLocations">{{a.location}}-{{a.cityname}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="searchForm.controls.location.error?.required">This
                        field is required.</mat-error>
                </mat-form-field>
                <!-- <mat-form-field fxFlex="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Month and Year</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                    [max]="max" (click)="fromDate.open()" required>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field> -->
                <mat-form-field fxFlex="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Month and Year</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                        [min]="minDate" [max]="max" (click)="fromDate.open()" required>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                        panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>


                <!-- <mat-form-field fxFlex="45" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="empname" required>
                        <input class="search-sty" type="text" placeholder="Search" [formControl]="searchControl"
                            matInput>
                        <mat-option value="0"> All Employees </mat-option>
                        <mat-option *ngFor="let a of filteredEmployees" [value]="a.empid">{{a.empname}}</mat-option>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="searchForm.controls.empname.errors?.required">This field is
                        required.</mat-error>
                </mat-form-field> -->
            </div>
            <div fxLayout="row" fxFlex.xs="100%" fxLayoutAlign="center" class="showLine">
                <span>
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="5">
                        Search
                    </button>
                </span>
                <!-- <span class="line" style="color:#1898D5;font-size:16px"></span> -->
                <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="6">
                    <span>Clear</span>
                </button>
            </div>

        </div>
        <div style="margin-bottom: 1%;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center"
            *ngIf="arrayList.length > 0">
            <div>
                <span class="exlprint"> | </span>
                <a title="PDF"> <img (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px"
                        height="20px"></a>

                <span class="exlprint"> | </span>
                <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                </a>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end"
            style="height: 70px;padding-top: 1%;">
            <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
                style="padding-right: 16px;">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput maxlength="100">
            </mat-form-field>
        </div>
        <div fxLayout="column" class="table-pad">
            <div class="mat-elevation-z1 example-container">
                <div id="table" #table=''>
                    <table mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}
                                <!-- {{element.id}} -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. ID</th>
                            <td mat-cell *matCellDef="let row;">{{row.employeeid}}</td>

                        </ng-container>
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                            <td mat-cell *matCellDef="let row;">{{row.empname}}</td>

                        </ng-container>
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Location</th>
                            <td mat-cell *matCellDef="let row;">{{row.location}}</td>

                        </ng-container>
                        <!-- <ng-container matColumnDef="attendancedate">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                                date:'dd-MM-yyyy'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="firstlogintime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">First In</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.firstlogintime|
                                date:'dd-MM-yyyy HH:mm:ss'}} </td>

                        </ng-container>
                        <ng-container matColumnDef="lastlogouttime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Last Out</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.lastlogouttime |
                                date:'dd-MM-yyyy HH:mm:ss'}} </td>

                        </ng-container>
                        <ng-container matColumnDef="totalhours">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total Hours</th>
                            <td mat-cell *matCellDef="let row"> {{row.totalhours}} </td>

                        </ng-container> -->

                        <!-- <ng-container matColumnDef="breaks">
                  <th mat-header-cell *matHeaderCellDef>Breaks</th>
                  <td mat-cell *matCellDef="let row">
                  <ng-container *ngFor="let number of row.breaks">
                    {{number}}<br>
        
                  </ng-container>
                </td>
                </ng-container>
                <ng-container matColumnDef="breaktime">
                  <th mat-header-cell *matHeaderCellDef>Break Time</th>
                  <td mat-cell *matCellDef="let row"> {{row.breaktime}} </td>
        
                </ng-container> -->

                        <!-- <ng-container matColumnDef="productivehours">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Productive Hours</th>
                            <td mat-cell *matCellDef="let row"> {{row.productivehours}} </td>
                        </ng-container> -->

                        <ng-container matColumnDef="ot">
                            <th mat-header-cell *matHeaderCellDef>OT Hours</th>
                            <td mat-cell *matCellDef="let row"> {{row.total_overtime_hours}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row" (keyup.enter)="openDialog(row)">
                                <button mat-icon-button (click)="openDialog(row)" [tabIndex]="8">
                                    <a style="color: blue">
                                        <u>Details</u>
                                    </a>
                                </button>

                            </td>

                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                        </tr>

                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>

    </mat-card>
</div>