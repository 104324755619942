import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PayrollService } from '../../payroll.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe, Location } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AssignPaygroupPopupComponent } from '../assign-paygroup-popup/assign-paygroup-popup.component';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-re-assign-paygroup',
  templateUrl: './re-assign-paygroup.component.html',
  styleUrls: ['./re-assign-paygroup.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})

export class ReAssignPaygroupComponent implements OnInit {
  ReAssignpaygroupform!: UntypedFormGroup;
  empdata: any = [];
  activedata: any = [];
  expandedElement: any;
  hide: boolean = false;
  validsubmitlag: boolean = false;
  today = new Date();
  nextMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
  messagesList: any = [];
  searchControl = new FormControl('');
  filteredEmpData: any = [];

  pipe = new DatePipe('en-US');
  PR1: any;
  PR30: any;
  PR31: any;
  PR32: any;
  PR35: any;
  PR36: any;
  PR43: any;
  PR44: any;
  arrayValue: any = [{ Value: 0, name: 'Change the employee CTC without changing the pay group' }, { Value: 1, name: 'Change the employee CTC and pay group' }];
  displayedColumns: string[] = ['radio', 'Name', 'Start_Range', 'End_Range', 'Earnings', 'Deductions'];
  dataSource!: MatTableDataSource<any>;
  paygroupid: any;
  esi_applicable: any;
  show: boolean = false;
  paygroupidforvalidate: any;
  modifydata: any;
  ComponentWiseValuesForPayGroupAssignment: any = [];
  userSession: any;
  employeeId: any;
  constructor(private router: Router, private ActivatedRoute: ActivatedRoute, private formBuilder: UntypedFormBuilder, private PR: PayrollService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getMessagesList();
    this.getEmployeesForChangePayGroup()
    // this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    // this.employeeId = this.userSession.id;

    this.ReAssignpaygroupform = this.formBuilder.group(
      {
        empName: ["", Validators.required],
        currentCTC: ["", Validators.required],
        currentpayGroup: [""],
        newCTC: ["", Validators.required],
        fromDate: [new Date(this.nextMonth)],
        changePaygroup: [0]
      });
    this.ReAssignpaygroupform.get('empName')?.valueChanges.subscribe((selectedValue: any) => {
      this.paygroupidforvalidate = selectedValue.income_group_id;
      this.paygroupid = selectedValue.income_group_id;
      this.employeeId = selectedValue.id;
      this.ReAssignpaygroupform.controls.currentCTC.setValue(selectedValue.cost_to_company);
      this.ReAssignpaygroupform.controls.currentpayGroup.setValue(selectedValue.group_name);
      this.show = true;
    });
    this.ReAssignpaygroupform.get('changePaygroup')?.valueChanges.subscribe((selectedValue: any) => {
      this.paygroupid = this.paygroupidforvalidate;
      if (selectedValue == 0) {
        this.hide = false;
        this.validsubmitlag = false;
      }
    });

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmpData(searchText)
    })


  }
  /** */
  ValidateSubmit() {
    if (this.ReAssignpaygroupform.valid) {
      let data = {
        pay_group_id: this.paygroupidforvalidate,
        ctc_value: this.ReAssignpaygroupform.controls.newCTC.value
      }
      if (this.ReAssignpaygroupform.controls.changePaygroup.value == 0) {

        this.PR.validateCtcWithPayGroupLimit(data).subscribe(async (result: any) => {
          if (result.data[0][0].output == 0) {
            this.validsubmitlag = true;
            await this.datas(this.employeeId);

          }
          else {
            this.validsubmitlag = false;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: result.data[0][0].output
            });

          }


        })
      }
      else {
        this.validsubmitlag = true;
        this.getPayGroupsForCtc();

      }
    }

  }


  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/reAssignPaygroup"]));
  }

  getEmployeesForChangePayGroup() {
    this.empdata = [];
    this.PR.getEmployeesForChangePayGroup().subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.empdata = result.data[0];
        this.filteredEmpData = this.empdata;
      }
    })

  }
  /** Active components values for paygroup*/
  getActiveComponentsValuesForPayGroup(data: any) {
    this.activedata = [];
    this.PR.getActiveComponentsValuesForPayGroup(data).subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.activedata = result.data;
      }
    })

  }
  async data(element: any) {
    this.paygroupid = element.id;
    let data = {
      CTC: this.ReAssignpaygroupform.controls.newCTC.value,
      paygroupid: element.id
    }
    this.esi_applicable = 0;
    this.ComponentWiseValuesForPayGroupAssignment = []
    this.PR.getComponentWiseValuesForPayGroupAssignment(data).subscribe((result: any) => {

      if (result.status && result.data.length > 0) {
        this.modifydata = result.data;
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].component_type == "Earnings") {
            this.ComponentWiseValuesForPayGroupAssignment.push(result.data[i]);

          }
          else if (result.data[i].component_name == "ESIC" && result.data[i].component_short_name == 'esi') {
            this.esi_applicable = 1;
          }
        }
        if (result.data[0].component_short_name == 'esi_error') {

          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: result.data[0].component_name
          });
          // this.getPayGroupsForCtc()

        }
        else {
          let dialogRef = this.dialog.open(AssignPaygroupPopupComponent, {
            width: '600px', position: { top: `70px` },
            disableClose: true,
            data: result.data
          });
        }


      }



    });
  }

  async datas(element: any): Promise<string> {
    // Simulating an asynchronous operation, e.g., an HTTP request
    return new Promise<string>((resolve) => {

      let data = {
        CTC: this.ReAssignpaygroupform.controls.newCTC.value,
        paygroupid: this.paygroupidforvalidate
      }
      this.esi_applicable = 0;
      // this.ComponentWiseValuesForPayGroupAssignment=[]
      this.PR.getComponentWiseValuesForPayGroupAssignment(data).subscribe((result: any) => {

        if (result.status && result.data.length > 0) {
          // this.modifydata = result.data;
          // this.modifyCtcOfAnEmployee()

          for (let i = 0; i < result.data.length; i++) {

            if (result.data[i].component_type == "Earnings") {
              this.ComponentWiseValuesForPayGroupAssignment.push(result.data[i]);



            }
            else if (result.data[i].component_name == "ESIC" && result.data[i].component_short_name == 'esi') {
              this.esi_applicable = 1;

            }

          }

          this.modifydata = this.ComponentWiseValuesForPayGroupAssignment;
          this.modifyCtcOfAnEmployee();



        }



      });


    });
  }







  getComponentWiseValuesForPayGroupAssignment(datas: any) {
    this.paygroupid = datas.id;
    let data = {
      CTC: this.ReAssignpaygroupform.controls.currentCTC.value,
      paygroupid: datas.id
    }
    this.PR.getComponentWiseValuesForPayGroupAssignment(data).subscribe((result: any) => {
      // let dialogRef = this.dialog.open(AssignPaygroupPopupComponent, {
      //   width: '390px',position:{top:`70px`},
      //   disableClose: true,
      //   data:datas      
      // });

    })



  }
  /**This functin used for to get paygroup details for selected employee */
  getPayGroupsForCtc() {
    if (this.validsubmitlag) {
      let data = {
        empid: this.employeeId,
        ctc: this.ReAssignpaygroupform.controls.newCTC.value,
        date: this.pipe.transform(new Date(this.nextMonth), 'yyyy-MM-dd')
      }
      this.PR.getPayGroupsForCtcReassign(data).subscribe((result: any) => {

        this.dataSource = new MatTableDataSource;

        this.paygroupid = '';
        if (result.status && result.data.length > 0) {
          this.dataSource = result.data
          this.hide = true;
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.PR44
          });

        }
      })
    }


  }
  modifyCtcOfAnEmployee() {
    if (this.paygroupid != '') {
      const componentsdata: any = {};
      for (let i = 0; i < this.ComponentWiseValuesForPayGroupAssignment.length; i++) {
        componentsdata[this.ComponentWiseValuesForPayGroupAssignment[i].component_short_name] = this.ComponentWiseValuesForPayGroupAssignment[i].amount_value;
      }
      let data = {
        empid: this.employeeId,
        CTC: this.ReAssignpaygroupform.controls.newCTC.value,
        paygroupid: this.paygroupid,
        data: componentsdata,
        esi_applicable: this.esi_applicable,
        date: this.pipe.transform(new Date(this.nextMonth), 'yyyy-MM-dd')
      }
      this.PR.modifyCtcOfAnEmployee(data).subscribe((result: any) => {
        if (result.status) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Payroll/reAssignPaygroup"]));

          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Cost to Company is updated for this employee successfully'
          });


        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'The selected pay group is assigned and Cost to Company is updated for this employee successfully.'
          });

        }
      })

    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.PR43
      });
    }

  }
  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.PR.getErrorMessages(null, 1, 1000).subscribe((res: any) => {
      if (res.status && res.data && res.data.length > 0) {
        this.messagesList = res.data;
        this.messagesList.forEach((e: any) => {
          if (e.code == "PR1") {
            this.PR1 = e.message
          } else if (e.code == "PR30") {
            this.PR30 = e.message
          }
          else if (e.code == "PR31") {
            this.PR31 = e.message
          }
          else if (e.code == "PR32") {
            this.PR32 = e.message
          }
          else if (e.code == "PR35") {
            this.PR35 = e.message
          }
          else if (e.code == "PR36") {
            this.PR36 = e.message
          }
          else if (e.code == "PR43") {
            this.PR43 = e.message
          }
          else if (e.code == "PR44") {
            this.PR44 = e.message
          }
        })
      }

    })
  }

  filterEmpData(searchText: any) {
    this.filteredEmpData = this.empdata.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase()) || val.empid.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredEmpData.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}


