
export const environment = {
  production: true,

  /** spryple sreeb AWS */
   apiUrl: 'https://sreeb.spryple.com:6060/', 


  /**-------------------------------------------------- */

  dbName: sessionStorage.getItem('companyName'),
};
