<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Approve Shift Change Request </mat-card-title>
    </mat-card-header>

    <div fxLayout="column" class="main-Pad" *ngIf="isNew">
      <form fxLayout="column" [formGroup]="shiftApproveForm" autocomplete="off">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Applied Date</mat-label>
            <input matInput readonly [matDatepicker]="appliedDate" [min]="minTodate" formControlName="appliedDate">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="appliedDate"></mat-datepicker-toggle>
            <mat-datepicker #appliedDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Employee</mat-label>
            <input formControlName="employee" matInput readonly>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>From Date</mat-label>
            <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" formControlName="fromDate"
              (click)="fromDate.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>To Date</mat-label>
            <input matInput readonly [matDatepicker]="toDate" [min]="minTodate" formControlName="toDate"
              (click)="toDate.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>


          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>From Shift</mat-label>
            <input formControlName="fromShift" matInput readonly>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>To Shift</mat-label>
            <input formControlName="toShift" matInput readonly>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign.xs="center">

          <mat-form-field fxFlex="66" fxFlex.lt-lg="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline"
            style="padding-right: 5px;">
            <mat-label>Reason</mat-label>
            <textarea formControlName="reason" maxlength="250" matInput readonly rows="1"></textarea>
          </mat-form-field>
        </div>
        <div class="section"><b>Shift Swap</b></div>
        <hr class="line_break">
        <div fxLayout="row" fxLayout.xs="column">

          <mat-form-field *ngIf="!isUpdate" fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2"
            appearance="outline">
            <mat-label>Swap Employee</mat-label>
            <mat-select formControlName="assignEmployee" required>
              <ng-container>
                <span *ngFor="let list of swapEmployeesList">
                  <mat-option [value]="list.id" (onSelectionChange)="onChange(list)">{{list.employee_name}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="shiftApproveForm.controls.assignEmployee.errors?.required">
              <!-- {{requiredOption}} -->
              Please select an option
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="isUpdate" fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2"
            appearance="outline">
            <mat-label>Swap Employee</mat-label>
            <input formControlName="assignEmployee" matInput readonly>
          </mat-form-field>

          <mat-form-field *ngIf="!isUpdate" fxFlex="66" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="description" maxlength="250" matInput rows="1"></textarea>
          </mat-form-field>

          <mat-form-field *ngIf="isUpdate" fxFlex="66" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="description" maxlength="250" matInput readonly rows="1"></textarea>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="center">
          <button *ngIf="!isUpdate" class="btn btn-primary mr-2 mb-2 custom" (click)="submit()" type="submit">
            <span>Approve</span>
          </button>

          <button *ngIf="!isUpdate" class="btn btn-danger mr-2 mb-2" (click)="reject()" type="button">
            <span>Reject</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="resetForm()">
            <span>Cancel</span>
          </button>
          <br />
        </div>
      </form>
    </div>

    <div *ngIf="!isNew">

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end"
        style="height: 70px;padding-top: 1%;">
        <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline"
          style="padding-right: 6px;">
          <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
          <mat-label>Search</mat-label>
          <input (keyup)="applyFilter($event)" matInput maxlength="100">
        </mat-form-field>
      </div>

      <div fxLayout="column" class="table-pad">
        <div class="example-container mat-elevation-z1">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef class="no-break">Applied Date </th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.applieddate | date:'dd-MM-yyyy'}}</span>

              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name </th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.employee_name}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="fromshift">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> From Shift </th>
              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <span>{{row.fromshift}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="toshift">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> To Shift </th>
              <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                <span>{{row.toshift}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.requeststatus}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                <button mat-icon-button matTooltip="Review & Approve" *ngIf="row.requeststatus=='Submitted'"
                  [tabindex]="[i+3]" (keyup.enter)="editRequest($event,row)">
                  <mat-icon (click)="editRequest($event,row)">visibility</mat-icon>
                </button>
                <button mat-icon-button matTooltip="View" *ngIf="row.requeststatus !='Submitted'" [tabindex]="[i+3]"
                  (keyup.enter)="view(row)">
                  <mat-icon (click)="view(row)">visibility</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card>
</div>