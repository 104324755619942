import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReportsService } from '../../reports.service';

export interface Shift {
  shift: string;
  from_date: string;
  to_date: string;
}

export interface ShiftData {
  employee_name: string;
  shifts: Shift[];
  week_off: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-future-shifts-report',
  templateUrl: './future-shifts-report.component.html',
  styleUrls: ['./future-shifts-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FutureShiftsReportComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'employee_name', 'shift', 'from_date', 'to_date', 'week_off'];
  dataSource = new MatTableDataSource<ShiftData>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  requestForm: any = UntypedFormGroup;
  minFromDate: Date = new Date(); // Set to current date
  maxFromDate: Date | undefined;
  minToDate: Date | undefined;
  maxToDate: Date | undefined;
  requiredOption: string = 'This field is required';
  userSession: any;
  pageLoading = true;

  constructor(
    private fb: FormBuilder,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.requestForm = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required]
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resetDateConstraints();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fromDateChange(eventType: string, event: MatDatepickerInputEvent<Date>): void {
    if (eventType === 'input') {
      const fromDate = event.value;
      this.minToDate = fromDate ? new Date(fromDate) : undefined;
      this.requestForm.controls['toDate'].setValue('');  // Reset the toDate when fromDate changes
    }
  }

  toDateChange(eventType: string, event: MatDatepickerInputEvent<Date>): void {
    if (eventType === 'input') {
      const toDate = event.value;
      this.maxFromDate = toDate ? new Date(toDate) : undefined;
    }
  }

  resetForm(): void {
    this.requestForm.reset();
    this.resetDateConstraints();
    this.dataSource.data = [];
  }

  resetDateConstraints(): void {
    this.minToDate = undefined;
    this.maxFromDate = undefined;
  }
  searchForm(): void {
    let data = {
      empid: this.userSession.id,
      fromdate: this.requestForm.controls.fromDate.value,
      toDate: this.requestForm.controls.toDate.value,
    }
    this.reportsService.getFutureDatesShiftReports(data).subscribe((res: any) => {
      if (res.status) {
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

}
