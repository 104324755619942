import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ChartComponent } from 'angular2-chartjs';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-help-admin-dashboard',
  templateUrl: './help-admin-dashboard.component.html',
  styleUrls: ['./help-admin-dashboard.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class HelpAdminDashboardComponent implements OnInit {
  totalTickets: any = 1200;
  inProgressCount: any = 900;
  @ViewChild(ChartComponent)
  chart!: ChartComponent;
  date = new FormControl(moment());
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }


  get progressPercentage(): number {
    return (this.inProgressCount / this.totalTickets) * 100;
  }

  constructor(private formBuilder: UntypedFormBuilder,
    public datePipe: DatePipe) { }
  displayedColumns: string[] = ['sno', 'ticketno', 'organisation', 'issuedate', 'module', 'screen', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  adminTicketForm: any = UntypedFormGroup;
  ticketHistoryForm: any = UntypedFormGroup;
  doughChartType: any = 'doughnut';
  doughnutChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '65%',
    legend: { position: 'bottom' }
  };
  doughnutChartData: any = {
    labels: ['Development', 'QA', 'Implementation'],
    datasets: [
      {
        backgroundColor: ["#0A77FB", "#6CEDD0", "#BD73E0", "#118ab2", "#ffd166", "#06d6a0", "#28acaf", "#ef476f", "#e52165", "#e6194B",
          "#f58231", "#ffe119", "#bfef45", "#3cb44b", "#42d4f4", "#4363d8", "#911eb4", "#f032e6", "#7FDBFF"],

        data: [40, 20, 40]
      }
    ]
  };
  BarChartType: any = 'bar';
  BarChartData = {
    labels: [' Employee', 'Attendance', 'Leave', 'Payroll', 'crm'],
    datasets: [
      {
        barThickness: 40,
        label: "Ticket Count",
        data: ['467', '576', '572', '79', '92',
          '574', '573', '576'],
        backgroundColor: '#7E7DBA'
      },]

  };
  BarChartOptions = {
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          suggestedMax: 100, //max
          beginAtZero: true,
          stepSize: 20,
          padding: 10,
          maxTicksLimit: 11
        },
        scaleLabel: {
          display: true,
          labelString: 'Count' // Y-axis label
        }
      }]
    }
  };

  chartType: any = 'bar';
  chartData = {
    labels: ['01-05-2024', '02-05-2024', '03-05-2024', '04-05-2024', '05-05-2024', '06-05-2024', '07-05-2024', '08-05-2024', '09-05-2024', '10-05-2024', '11-05-2024', '12-05-2024', '13-05-2024', '14-05-2024', '15-05-2024', '16-05-2024', '17-05-2024', '18-05-2024', '19-05-2024', '20-05-2024', '21-05-2024', '22-05-2024', '23-05-2024', '24-05-2024', '25-05-2024', '26-05-2024', '27-05-2024', '28-05-2024', '29-05-2024', '30-05-2024', '31-05-2024'],
    datasets: [
      {
        barThickness: 20,
        label: "Tickets Pending",
        data: ['467', '576', '572', '79', '92',
          '574', '573', '576', '1000'],
        backgroundColor: '#DB2DA8'
      },

    ]
  };
  chartOptions = {
    responsive: true,
    aspectRatio: 3,

    scales: {
      yAxes: [{

        display: true,
        ticks: {
          suggestedMax: 100, //max
          beginAtZero: true,
          stepSize: 20,
          padding: 10,
          maxTicksLimit: 7,

        },
        scaleLabel: {
          display: true,
          labelString: 'Pending Tickets Count' // Y-axis label
        },

      }],

    },



  };
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  ngOnInit(): void {

    this.adminTicketForm = this.formBuilder.group({
      appliedDate: [],
      modules: [''],
      screens: [''],
      description: [''],
      document: [''],
      ticketId: [''],
      organization: [''],
      employee: [''],
      status: [''],
      fromDate: [new Date()],
      toDate: [new Date()],
    })

  }

}
