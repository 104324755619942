<div fxLayout="column" class="first-col">

  <mat-card>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> ESI Report </mat-card-title>
    </mat-card-header>
    <div [formGroup]="searchForm" class="tableP" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayout.xs="column">
        <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
          <mat-label>Month and Year</mat-label>
          <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
            [min]="minDate" [max]="max" (click)="fromDate.open()">
          <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
            panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex class="mx-2 my-2">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
            <ng-container>
              <div *ngFor="let b of filteredStateList">
                <mat-option value="{{ b.state_id }}">{{ b.state }}</mat-option>
              </div>
            </ng-container>
          </mat-select>
          <mat-error class="con-error" *ngIf="searchForm.controls.state.errors?.required">
            Please select an option
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign.xs="center center">
          <span ngStyle.gt-md="margin-top: 5%" ngStyle.lt-lg="margin-top: 5%" ngStyle.xs="margin-top: 0px">
            <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
              Search
            </button>
            <span class="line" style="color: #1898d5; font-size: 16px"></span>
            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
              <span>Clear</span>
            </button>
          </span>
        </div>
      </div>
      <div style="margin-top: 10px;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center">
        <div>
          <span class="exlprint" (key.press)="downloadPDF()"> | </span>
          <a title="PDF"> <img (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px"
              height="20px"></a>

          <span class="exlprint"> | </span>
          <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
          </a>
        </div>
      </div>
    </div>

    <div class="tablePadding">
      <div class="mat-elevation-z1 example-container">
        <div id="table" #table=''>
          <table id="table" mat-table [dataSource]="dataSource" matSort>
            <!-- <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef > S.No</th>
            <td mat-cell *matCellDef="let element; let i=index;">
             {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
          </ng-container> -->
            <ng-container matColumnDef="ipnumber">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> ESI No. </th>
              <td mat-cell *matCellDef="let row">
                <span style="font-size: smaller;" *ngIf="row.ESI == 'Grand Total'"><b>{{row.ESI}}</b> </span>
                <span style="font-size: smaller;" *ngIf="row.ESI != 'Grand Total'">{{row.ESI}} </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="ipname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">Emp. Name </th>
              <td mat-cell *matCellDef="let row"><span>{{row.Employee_Name}} </span></td>
            </ng-container>
            <ng-container matColumnDef="nofdays">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Paid Days </th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.ESI != 'Grand Total'">{{row.lopcount==null?0:row.lopcount}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.lopcount==null?0:row.lopcount}}</b> </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="totawages">
              <th mat-header-cell *matHeaderCellDef>Gross Salary </th>
              <td mat-cell *matCellDef="let row"><span *ngIf="row.ESI != 'Grand Total'">{{row.Gross_Salary}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.Gross_Salary}}</b> </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="ipcontribution">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Employee Contribution</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.ESI != 'Grand Total'">{{row.employee_esi_value}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.employee_esi_value}}</b></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="reasonforzeroworkingdays">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Employer Contribution</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.ESI != 'Grand Total'">{{row.employer_esi_value}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.employer_esi_value}}</b></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="lastworkingdays">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total Contribution</th>
              <td mat-cell *matCellDef="let row">
                <span *ngIf="row.ESI != 'Grand Total'">{{row.total_contribution}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.total_contribution}}</b></span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
            </tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>



      </div>

    </div>
    <!-- <mat-card>

      <mat-card-content style="width:100%;overflow-x: auto">
        <table id="table" class="table  table-striped" >
          <thead style="background-color: #28acaf;">
          <tr >
            <th scope="col" class="space">Name</th>
            <th scope="col" class="space">Emp ID</th>
            <th scope="col" class="space">Leave type</th>
            <th scope="col" class="space">Designation</th>
            <th scope="col" class="space">Applied Date</th>
            <th scope="col" class="space">From Date</th>
            <th scope="col" class="space">To Date</th>
            <th scope="col" class="space">No.of Days</th>
            <th scope="col" class="space">Status</th>
            <th scope="col" class="space">Approved By</th>

          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of arrayList| paginate : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };index as i;">

            <tr scope="row" class="spacetd">
              <td >{{item.employee_name}}</td>
              <td>{{item.employee_code}}</td>
              <td>{{item.leavetype}}</td>
              <td>{{item.designation}}</td>
              <td>{{item.appliedon | date:'mediumDate'}}</td>
              <td>{{item.fromdate | date:'mediumDate'}}</td>
              <td>{{item.todate | date:'mediumDate'}}</td>
              <td>{{item.leavecount+' days'}}</td>
              <td>{{item.leavestatus}}</td>
              <td>{{item.approvedby}}</td>
            </tr>
          </ng-container>
          <tr *ngIf="arrayList && arrayList.length === 0">
            <td colspan="10" style="text-align: center;">No Data Found</td>
          </tr>
          </tbody>

        </table>
      </mat-card-content>
      <div *ngIf="arrayList && arrayList.length  != 0" >
        <div class="row" *ngIf="count > 5" >
          <div class="col-md-6" >
            <div  class="d-flex flex-row-reverse bd-highlight mb-2" style="float: left;">
              <div class="d-flex justify-content-start">
                <label class="col-md-4 col-form-label" >Show:</label>
                <div class="col-md-8" >
                  <select style="width: 150px;" (change)="onTableSizeChange($event)" class="custom-select select1">
                    <option value="5">
                      5 Entries
                    </option>
                    <option  value="25">
                      25 Entries
                    </option>
                    <option  value="50">
                      50 Entries
                    </option>
                    <option value="{{count}}">
                      All
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">

            <div class="d-flex justify-content-end">

              <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </mat-card> -->

    <!--<div style="text-align: center;padding-top: 20px;" *ngIf="List.length === 0">No data found in selected date-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<mat-card>-->
    <!--<mat-card-header>-->
    <!--<mat-card-title style="text-align: center"> Pending CompOff </mat-card-title>-->
    <!--</mat-card-header>-->
    <!--<mat-divider></mat-divider>-->
    <!--<div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">-->
    <!--&lt;!&ndash;<mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">&ndash;&gt;-->
    <!--&lt;!&ndash;<mat-label>Filter</mat-label>&ndash;&gt;-->
    <!--&lt;!&ndash;<input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>&ndash;&gt;-->
    <!--&lt;!&ndash;<mat-icon matSuffix>search</mat-icon>&ndash;&gt;-->
    <!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="mat-elevation-z8" style="margin-top: 2%;margin-left:20px;margin-bottom:20px; margin-right:20px;">-->
    <!--<table mat-table [dataSource]="dataSource" matSort>-->

    <!--&lt;!&ndash; ID Column &ndash;&gt;-->
    <!--<ng-container matColumnDef="employeeName">-->
    <!--<th mat-header-cell *matHeaderCellDef mat-sort-header class="table" > Employee Name</th>-->
    <!--<td mat-cell *matCellDef="let row" class="table" >{{row.employee_name}} </td>-->
    <!--</ng-container>-->
    <!--<ng-container matColumnDef="employeeId"  >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header> Employee Id</th>-->
    <!--<td mat-cell *matCellDef="let row"class="table"  > {{row.employee_id}}</td>-->
    <!--</ng-container>-->
    <!--<ng-container matColumnDef="leaveType" >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header> Leave Type </th>-->
    <!--<td mat-cell *matCellDef="let row" class="table"  > {{row.leavetype}}</td>-->
    <!--</ng-container>-->
    <!--&lt;!&ndash; Progress Column  empid&ndash;&gt;-->
    <!--<ng-container matColumnDef="designation" class="table">-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header style="white-space: nowrap"> Designation</th>-->
    <!--<td mat-cell *matCellDef="let row" class="table"> {{row.designation}}</td>-->
    <!--&lt;!&ndash;<td mat-cell *matCellDef="let row" > {{designation | date:'mediumDate'}}</td>&ndash;&gt;-->

    <!--</ng-container>-->
    <!--&lt;!&ndash;<ng-container matColumnDef="toDate">&ndash;&gt;-->
    <!--&lt;!&ndash;<th mat-header-cell *matHeaderCellDef mat-sort-header> To Date </th>&ndash;&gt;-->
    <!--&lt;!&ndash;<td mat-cell *matCellDef="let row" style="width: 10%;" > {{row.todate  | date:'mediumDate'}}</td>&ndash;&gt;-->
    <!--&lt;!&ndash;</ng-container>&ndash;&gt;-->
    <!--&lt;!&ndash; Name Column &ndash;&gt;-->
    <!--<ng-container matColumnDef="appliedDate"  >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header style="white-space: nowrap"> Applied Date </th>-->
    <!--<td mat-cell *matCellDef="let row" class="table"  style="text-align: center;" > {{row.appliedon | date:'mediumDate'}}  </td>-->
    <!--</ng-container>-->

    <!--&lt;!&ndash; Color Column &ndash;&gt;-->
    <!--<ng-container matColumnDef="startDate"  >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header style="white-space: nowrap"> Start Date </th>-->
    <!--<td mat-cell *matCellDef="let row" class="table" style="text-align: center;" > {{row.fromdate | date:'mediumDate'}} </td>-->
    <!--</ng-container>-->
    <!--<ng-container matColumnDef="toDate" >-->
    <!--<th mat-header-cell *matHeaderCellDef mat-sort-header class="table"> To Date</th>-->
    <!--<td mat-cell *matCellDef="let row" class="table" style="text-align: center;" > {{row.todate | date:'mediumDate'}} </td>-->

    <!--</ng-container>-->
    <!--<ng-container matColumnDef="noOfDays" >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header> No.Of Days</th>-->

    <!--<td mat-cell *matCellDef="let row" style="text-align: center" class="table"> {{row.leavecount}}</td>-->

    <!--</ng-container>-->
    <!--<ng-container matColumnDef="status"  >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table" mat-sort-header> Status</th>-->

    <!--<td mat-cell *matCellDef="let row" class="table" > {{row.leavestatus}}</td>-->

    <!--</ng-container>-->
    <!--<ng-container matColumnDef="approvedBy"  >-->
    <!--<th mat-header-cell *matHeaderCellDef class="table"mat-sort-header> Approved By</th>-->

    <!--<td mat-cell *matCellDef="let row" class="table" ><span data-title="{{row.reason}}" ><i class="material-icons" aria-hidden="true">info_outline</i></span>-->

    <!--</td>-->
    <!--</ng-container>-->
    <!--&lt;!&ndash; Color Column &ndash;&gt;-->
    <!--&lt;!&ndash;<ng-container matColumnDef="noOfDays">&ndash;&gt;-->
    <!--&lt;!&ndash;<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%; padding: 0px;padding-left: 5%;" > No Of Days </th>&ndash;&gt;-->
    <!--&lt;!&ndash;<td mat-cell *matCellDef="let row" style="width: 15%; padding: 0px; text-align: center;  cursor: pointer;">&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<i class="material-icons mr-1" (click)="compoffApprove(row,'Approved',null)" title="Approve">check_box</i>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<i class="material-icons mr-1" (click)="compoffReject(row)" title="Reject">cancel_presentation</i>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;<i class="material-icons" (click)="compoffReview(row)" title="Review and approve">preview</i>&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;&lt;!&ndash;<a routerLink="/" (click)="onApproveClick(row)">Review and Approve</a>&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;</td>&ndash;&gt;-->
    <!--&lt;!&ndash;</ng-container>&ndash;&gt;-->
    <!--&lt;!&ndash; Color Column &ndash;&gt;-->
    <!--<tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
    <!--<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->

    <!--&lt;!&ndash; Row shown when there is no matching data. &ndash;&gt;-->
    <!--<tr class="mat-row" *matNoDataRow>-->
    <!--<td class="mat-cell" colspan="9">No data available</td>-->
    <!--</tr>-->
    <!--</table>-->

    <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
    <!--</div>-->
    <!--</mat-card>-->
    <!-- <ngx-spinner size="medium" type='ball-fussion'></ngx-spinner> -->
  </mat-card>
</div>