import { Component, OnInit,LOCALE_ID,Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ViewChild,ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from 'src/app/modules/reports/excel-service.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { CrmService } from '../../crm.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-crm-client-associate-report',
  templateUrl: './crm-client-associate-report.component.html',
  styleUrls: ['./crm-client-associate-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmClientAssociateReportComponent implements OnInit {

  List: any[] = [

  ];
  @ViewChild('table') table!: ElementRef;
  pipe:any;
  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
  maxDate = new Date();
  minToDate: any;
  pageLoading=true;
  searchForm:  any = FormGroup;
  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  associateTypes: any[] = [];
  qualifications: any[] = [];
  specializations: any[] = [];

  locationIshide: boolean = false;
  locationIschecked: boolean = false;

  mgrIshide: boolean = false;
  empIshide: boolean = false;
  qualIshide: boolean = false;
  spIshide: boolean = false;

  empIschecked: boolean = false;
  qualIschecked: boolean = false;
  spIschecked: boolean = false;

  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  selectedAssociationTypes: any = [];
  selectedQualifications: any = [];
  selectedSpecializations: any = [];
  companyName:any;
  is_super_admin:any = false;
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata:any;
  year:any;
  months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns:string[]=['sno','employee_name','contact','email','client_name','employee_type','qualification_type','specialization_type','created_by'];
  isLoading = false;
  
  constructor(@Inject(LOCALE_ID) private locale: string,public reportsService: ReportsService, public datePipe: DatePipe, 
  public formBuilder: UntypedFormBuilder, private crm:CrmService,private spinner: NgxSpinnerService,
  private companyService: CompanySettingService, public dialog: MatDialog,
   private excelService: ExcelServiceService) { 
    this.companyName = sessionStorage.getItem("companyName");
  }

   
  ngOnInit(): void {
    this.searchForm=this.formBuilder.group({
      associateTypes:["", Validators.required],
      qualifications: ["", Validators.required],
      specializations: ["", Validators.required]
      });
    this.pipe = new DatePipe(this.locale);
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getClientAssociateTypes();
    this.getClientAssociateQualifications();
    this.getClientAssociateSpecilizations();
 }

Searchform() {
  this.List = [];
  let obj={
    "associate_type_ids":JSON.stringify(this.selectedAssociationTypes),
    "qualification_ids" :JSON.stringify(this.selectedQualifications),
    "specification_ids" :JSON.stringify(this.selectedSpecializations),
    "search_param":null
    };
  this.isLoading = true;
   this.spinner.show();
  this.crm.getCrmClientAssociatesReport(obj).subscribe((res: any) => {
    this.spinner.hide();
    this.List=res.data;
    this.isLoading = false;
    this.dataSource = new MatTableDataSource(this.List);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageLoading=false;
  }, error => {
    this.isLoading = false;
    error.error.text
  });
}

resetform() {
  this.dataSource.data = [];
  this.searchForm.reset();
  this.searchForm.controls.qualifications.setValue('0');
  this.searchForm.controls.associateTypes.setValue('0');
  this.searchForm.controls.specializations.setValue('0');
  this.Searchform();
}

openDialog(item:any): void {
  const dialogRef = this.dialog.open(DialogDetailComponent, {
    width: '1000px',position:{top:`70px`},   data: {attendanceid:item.attendanceid ,}
  });

  dialogRef.afterClosed().subscribe(() => {  });
}

exportAsXLSX() {
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'employee activity Report');
  /* save to file */
  XLSX.writeFile(wb,'Client related Associates Report.xlsx');

}
getPageSizes(): number[] {
    
var customPageSizeArray = [];
if (this.dataSource.data.length > 5) {
  customPageSizeArray.push(5);
}
if (this.dataSource.data.length > 10) {
  customPageSizeArray.push(10);
}
if (this.dataSource.data.length > 20) {
  customPageSizeArray.push(20);
}
customPageSizeArray.push(this.dataSource.data.length);
return customPageSizeArray;
}

public exportPDF(): void {
  const pdfTable = this.table.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML);
  pdfMake.createPdf({
    info: {
      title:"Client related Associates Report",
      author:'Sreeb tech',
      subject:'Theme',
          keywords:'Report'
    },
    footer: function (currentPage:any, pageCount:any) {
      return {
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              }
            ],
            fonts: {
              Arial: {
                normal: 'Arial.ttf',
                bold: 'Arial-Bold.ttf',
                italics: 'Arial-Italic.ttf',
                bolditalics: 'Arial-BoldItalic.ttf'
              }
            },
            alignment: 'center'
          }
        ]
      };
    },
    content: [ 
      {
        text: "Client related Associates Report\n\n",
        style: 'header',
        alignment: 'center',
        fontSize: 14
      },  
      html,      
    ],
    pageOrientation: 'landscape'//'portrait'
  }).download("Client related Associates Report.pdf");
}

getFormattedDateTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

getFormattedTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return  date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

convertToKms(distance:any){
  let value = { val:distance,key:' mtrs'};
  if(distance >= 1000){
    value.val = distance/1000;
    value.key = ' km'
  }
  if(!distance) 
    value.key ='';
    return value;
}

getFormattedTimeDifference(seconds :any = null,type:any = 'Travel'){
  if(seconds  && seconds .toString().length) {
    var hours = Math.floor(seconds / 60 / 60);
    var minutes = Math.floor(seconds / 60 % 60);
    var remainingSeconds = seconds % 60;
    if(type === 'Stay')
    return ( hours? hours+ 'hours ':'')+ (minutes && minutes > 3 ?minutes + 'min ':''); // + (remainingSeconds && remainingSeconds > 45 ?remainingSeconds + 'sec':'');
    else 
    return ( hours? hours+ 'hours ':'')+ (minutes?minutes + 'min ':'') + (remainingSeconds?remainingSeconds + 'sec':'');
   
  }
  else return '';
}

getAddress(r:any,type:any ){
  let address = '';
  if(type === 'start'){
    if(r.start_address && r.start_address.toString().length > 0){
      address = r.start_address;
    }
    else if(r.location && r.location.toString().length  > 0){
      address = r.location;
    }
  }
  if(type === 'end'){
    if(r.end_address && r.end_address.toString().length > 0){
      address = r.end_address;
    }
    else if(r.location && r.location.toString().length  > 0){
      address = r.location;
    }
  }
  return address;
}

changeDisplay(row:any,det:any){
    row.details?.forEach((d:any)=>{
      d.display = false;
      if(det.client_id){
        if(d.client_id && d.client_id === det.client_id  ){
          d.display = true;
        }
      }else{
        if(d.requestid && d.requestid === det.requestid  ){
          d.display = true;
        }
      }
    });
  }

 
locationDeselectAll(select: MatSelect) {
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('')
}

onchangeManager(value:any) {
  this.selectedManagers=[];
    let mgr = this.searchForm.controls.managers.value;
    mgr.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
   // this.getEmployeelistForSuperAdmin();
}

managerDeselectAll(select: MatSelect) {
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];
  select.value = [];
  this.searchForm.controls.managers.setValue('')
  this.selectedManagers=[];
}


managerSelectAll(select: MatSelect, values: any, array: any) {
  this.mgrIshide = true;
this.mgrIschecked = true;
select.value = values;
array = values;
this.searchForm.controls.managers.setValue(array)
let managerdata = this.searchForm.controls.managers.value;
managerdata.forEach((e: any) => {
 this.selectedManagers.push(Number(e.manager_emp_id));
});
//this.getEmployeelistForSuperAdmin();
}

onchangeLocation(value:any) {
    this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
}
locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getManagersList() {
  this.managersDetails =[];
  let data={
    location_id :JSON.stringify(this.selectedLocations),
    companyName : this.companyName
  }
  this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
            this.managersDetails = res.data;
     }
  })
}
getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
    this.worklocationDetails = result.data;
  })
} 

getClientAssociateTypes() {
  this.managersDetails =[];
  this.crm.getCrmClientAssociateTypes({ employee_status: 1, companyName: this.companyName }).subscribe((result) => {
    this.associateTypes = result.data;
  })
}



getClientAssociateQualifications() {
  this.managersDetails =[];
  this.crm.getCrmClientAssociateQualificationTypes({ qualification_status: 1, companyName: this.companyName }).subscribe((result) => {
    this.qualifications = result.data;
  })
} 
getClientAssociateSpecilizations() {
  this.managersDetails =[];
  this.crm.getCrmClientAssociateSpecializationTypes({ specialization_status: 1,qualification_type_id:null, companyName: this.companyName }).subscribe((result) => {
    this.specializations = result.data;
  })
} 


associateSelectAll(select: MatSelect, values: any, array: any) {
  this.empIshide = true;
  this.empIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.associateTypes.setValue(array)
  let associateTypes = this.searchForm.controls.associateTypes.value;
  associateTypes.forEach((e: any) => {
    this.selectedAssociationTypes.push(e.employee_type_id);
  });
   
}

qualificationSelectAll(select: MatSelect, values: any, array: any) {
  this.qualIshide = true;
  this.qualIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.qualifications.setValue(array)
  let qualifications = this.searchForm.controls.qualifications.value;
  qualifications.forEach((e: any) => {
    this.selectedQualifications.push(e.qualification_type_id);
    });
  }

  specializationSelectAll(select: MatSelect, values: any, array: any) {
    this.qualIshide = true;
    this.qualIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.specializations.setValue(array)
    let specializations = this.searchForm.controls.specializations.value;
    specializations.forEach((e: any) => {
      this.selectedSpecializations.push(e.specialization_type_id);
      });
    }

    associateDeselectAll(select: MatSelect) {
      this.empIshide = false;
      this.empIschecked = false
      this.selectedAssociationTypes = [];
      select.value = [];
      this.searchForm.controls.associateTypes.setValue('');
    }
    qualificationDeselectAll(select: MatSelect) {
      this.qualIshide = false;
      this.qualIschecked = false
      this.selectedQualifications = [];
      select.value = [];
      this.searchForm.controls.qualifications.setValue('');
    }

    specializationDeselectAll(select: MatSelect) {
      this.spIshide = false;
      this.spIschecked = false
      this.selectedSpecializations = [];
      select.value = [];
      this.searchForm.controls.specializations.setValue('');
    }

    onchangeAssociates(value:any) {
      this.selectedAssociationTypes=[];
      let ast = this.searchForm.controls.associateTypes.value;
      ast.forEach((e: any) => {
        this.selectedAssociationTypes.push(e.employee_type_id);
      });
      if(this.selectedAssociationTypes.length === this.associateTypes.length){
        this.empIshide = true;
      } else this.empIshide = false;
  }

  onchangeQualifications(value:any) {
    this.selectedQualifications=[];
    let sq = this.searchForm.controls.qualifications.value;
    sq.forEach((e: any) => {
      this.selectedQualifications.push(e.qualification_type_id);
    });
    if(this.selectedQualifications.length === this.qualifications.length){
      this.qualIshide = true;
    } else this.qualIshide = false;
}

onchangeSpecializations(value:any) {
  this.selectedSpecializations=[];
  let ss = this.searchForm.controls.specializations.value;
  ss.forEach((e: any) => {
    this.selectedSpecializations.push(e.specialization_type_id);
  });
  if(this.selectedSpecializations.length === this.specializations.length){
    this.spIshide = true;
  } else this.spIshide = false;
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

getEmployeelistForSuperAdmin() {
    let obj={
    "rm_id":JSON.stringify(this.selectedManagers),
    "location_id" :JSON.stringify(this.selectedLocations),
    };
    this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
        if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.searchForm.controls.Users.setValue('0');
        }
    });
  }
}


  
  


