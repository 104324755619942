<div fxLayout="column" class="first-col">
  <mat-card>
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Permissions Request </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isPermissionList">
        <button mat-icon-button style="padding-right: 40px;" (click)="addNewPermission()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>

    <div fxLayout="column" *ngIf="isNewPermission" class="main-Pad">
      <form [formGroup]="permissionsForm" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
        <div *ngIf="displayAddForm">
          <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
            style="margin-top: 10px;">
            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label>Select a date </mat-label>
              <input matInput readonly required [min]="minpermissionDate" [max]="maxpermissionDate"
                [matDatepicker]="permissionDate" placeholder="DD-MM-YYYY" (click)="permissionDate.open()"
                formControlName="permissionDate" [tabIndex]="3" [matDatepickerFilter]="myDateFilter">
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="permissionDate"></mat-datepicker-toggle>
              <mat-datepicker #permissionDate></mat-datepicker>
              <mat-error class="con-error" *ngIf="permissionsForm.controls.permissionDate.errors?.required">
                Please select a date
              </mat-error>
            </mat-form-field>



            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label>Permission Duration in Minutes</mat-label>
              <input matInput required formControlName="permissionDuration" maxlength='3' placeholder="minutes"
                (keypress)="numberOnlyWithoutZero($event)" (change)="onchangeTime()">
              <mat-error class="con-error" *ngIf="permissionsForm.controls.permissionDuration.errors?.required">
                This field is required.
              </mat-error>
            </mat-form-field>

          </div>
          <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
            style="margin-top: 20px;">
            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label> Reason</mat-label>
              <textarea matInput required noLeadingWhitespace formControlName="remarks" placeholder=""
                maxlength="250"></textarea>
              <mat-error class="con-error" *ngIf="permissionsForm.controls.remarks.errors?.required">
                This field is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="center" style="margin-top: 30px;">
            <button [disabled]="btnDisable" class="btn btn-primary mr-2 mb-2" type="submit" (click)=" onSubmit()">
              <span>Submit</span>
            </button>

            <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
              <span>Cancel</span>
            </button>
            <br />
          </div>
        </div>
        <div *ngIf="!displayAddForm">
          <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
            style="margin-top: 10px;">
            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label>Permission date </mat-label>
              <input matInput readonly required [min]="minpermissionDate" [max]="maxpermissionDate"
                [matDatepicker]="permissionDate" placeholder="DD-MM-YYYY" (click)="permissionDate.open()"
                formControlName="Date" [tabIndex]="3" [matDatepickerFilter]="myDateFilter" readonly>
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="permissionDate"></mat-datepicker-toggle>
              <mat-datepicker #permissionDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label>Permission Duration in Minutes</mat-label>
              <input matInput required formControlName="Duration" maxlength='3' placeholder="minutes" readonly>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
            style="margin-top: 20px;">
            <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline"
              style="height: 60px;">
              <mat-label> Reason</mat-label>
              <textarea matInput required formControlName="reason" placeholder="" maxlength="250" readonly></textarea>

            </mat-form-field>
          </div>
          <div fxLayoutAlign="center" style="margin-top: 30px;">

            <button class="btn btn-danger mr-2 mb-2" (click)="clearDisplay()">
              <span>Cancel</span>
            </button>
            <br />
          </div>
        </div>
      </form>
    </div>


    <div fxLayout="column" *ngIf="isPermissionList" class="tableP" style="margin-top: 20px">

      <div class="example-container mat-elevation-z1">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> S. No.</th>
            <td mat-cell *matCellDef="let element; let i=index;">
              {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
          </ng-container>

          <ng-container matColumnDef="usage_date">
            <th mat-header-cell *matHeaderCellDef class="no-break"> Date</th>
            <td mat-cell *matCellDef="let row" class="no-break"> {{row.usage_date | date:'dd-MM-yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="permission_duration">
            <th mat-header-cell *matHeaderCellDef class="no-break">Permission Duration</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="enable != row.id">{{ row.duration}} Minutes</span>
              <span *ngIf="enable === row.id">
                <form [formGroup]="permissionsForm" autocomplete="off" style="padding-left: 1%;padding-top: 1%;">
                  <mat-form-field appearance="outline">
                    <input formControlName="permissionDuration" matInput placeholder=""
                      (keypress)="numberOnlyWithoutZero($event)" maxlength="3" (change)="onchangeTime()" />
                  </mat-form-field>
                </form>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="submission_status">
            <th mat-header-cell *matHeaderCellDef class="no-break">Submission Status</th>
            <td mat-cell *matCellDef="let row" class="no-break"> {{row.submission_status}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
              <button mat-icon-button
                *ngIf="enable != row.id && row.submission_status == 'Submitted'  &&  row.is_day_completed == 0"
                [tabIndex]="10" (keyup.enter)="editRequest($event, row)">
                <mat-icon class="mr-2" title="Edit" (click)="editRequest($event, row)">
                  edit</mat-icon>
              </button>&nbsp;
              <button mat-icon-button [tabIndex]="10" (keyup.enter)="cancelRequest($event,row)"
                *ngIf="enable != row.id && row.submission_status == 'Approved'  &&  row.is_day_completed == 0">
                <mat-icon class="mr-2" title="Cancel" (click)="cancelRequest($event,row)">
                  cancel</mat-icon>
              </button>

              <button [disabled]="btnDisable" mat-icon-button *ngIf="enable == row.id  &&  row.is_day_completed == 0"
                (keyup.enter)=" save(row)">
                <mat-icon (click)="save(row) " title="Save">save</mat-icon>
              </button>

              <button mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="cancel()"
                *ngIf="enable == row.id  &&  row.is_day_completed == 0">
                <mat-icon class="mr-1" title="Cancel" (click)="cancel()">cancel</mat-icon>
              </button>

              <button mat-icon-button [tabIndex]="[i+1]"
                *ngIf="enable != row.id && row.submission_status == 'Submitted' &&  row.is_day_completed == 0"
                (keyup.enter)="deleteRequest(row)">
                <mat-icon class="mr-1" title="Delete" (click)="deleteRequest(row)">delete</mat-icon>
              </button>

              <button mat-icon-button [tabIndex]="12" *ngIf="row.is_day_completed == 1"
                (keyup.enter)="requestView(row)">
                <mat-icon class="mr-2" title="View" (click)="requestView(row)">
                  visibility</mat-icon>
              </button>
            </td>

          </ng-container>
          <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </div>

    </div>

  </mat-card>
</div>