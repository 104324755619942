<mat-sidenav-container class="example-sidenav-container" autosize="true" style="overflow: hidden;">

  <mat-sidenav #sidenav *ngIf="flag" class="example-sidenav mt_snav" mode="side" opened="true">
    <mat-nav-list class="dashboard">
      <a mat-list-item>
        <div class="row" style="flex-flow: column" matTooltip={{istoolTip}} (click)='toggleBack($event)'>
          <mat-icon style="color:#28acaf">menu</mat-icon>
        </div>
      </a>
      <span *ngFor="let item of menuList">
        <a mat-list-item *ngIf="item && item.children && (item.children.length ||  item.id ==3 )">
          <div class="row" style="flex-flow: column" (mouseover)="sidemenuHover(item)" (mouseleave)="onLeave()"
            #tooltip="matTooltip" matTooltip={{moduleName}} [matTooltipPosition]="position.value"
            [ngClass]="{'hovered': hoverimgId === item.id}">
            <img [src]="item.iconpath" [ngClass]="{ 'hovered': hoverimgId === item.id}" (mouseover)="onHover(item)"
              (mouseleave)="onLeave()"
              style="height: 30px; width: 45px;max-inline-size: fit-content;  transition: all 0.3s ease;"
              [routerLink]="[item.default_url]" [state]="item.functionalities" [routerLinkActive]="['active']"
              (click)="toggleChild(item,item.default_url)" />
            <!-- 'selected': selectedItemId === item.id, -->
          </div>
        </a>
      </span>
    </mat-nav-list>
  </mat-sidenav>

  <div class="tab-view" style="width:100%;" *ngIf="flag && !isMobile">
    <div class="" [ngStyle]="{'width': isExpanded ? (sideNavigationWidth)+'%' : '0%'}" *ngIf=" toggleExpand()">
      <mat-drawer-container class="example-container mt_mdc">
        <mat-drawer mode="side" opened class="mt_dr">
          <div class="mt_dr_d1">

            <span *ngFor="let item of menuList">
              <span *ngIf="item.displayStatus && item.children">

                <span *ngIf="item.children && item.children[0]" style="margin: top 10px;overflow-x: hidden;">
                  <mat-expansion-panel *ngFor="let child of item.children"
                    [expanded]="(child.displayName===self)?true:child.isOpen" [disabled]="(child.displayName===self)"
                    [hideToggle]="child.displayName===self" style="background: white;">
                    <mat-expansion-panel-header style="background: white;">
                      <mat-toolbar class="mt_tlb">
                        <span style="background: white;"> {{ child.displayName != self ? child.displayName:
                          item.modulename }}</span>
                      </mat-toolbar>
                    </mat-expansion-panel-header>

                    <span *ngIf="child.subChildren && child.subChildren[0]" style="background-color: white;">
                      <mat-nav-list *ngFor="let subchild of filterByStatus(child.subChildren)" style="height: 45px;"
                        autoFocus="false">
                        <mat-list-item [routerLink]="[subchild.routename]" [state]="subchild.functionalities"
                          style="font: -webkit-control;" [routerLinkActive]="['active']"
                          (click)="toggleChild(item,subchild.routename)"
                          (keyup.enter)="toggleChild(item,subchild.routename)">
                          <span style="font:auto;"> {{ subchild.screen_name }}</span>
                        </mat-list-item>
                      </mat-nav-list>
                    </span>

                  </mat-expansion-panel>

                </span>
              </span>
            </span>

          </div>
        </mat-drawer>

      </mat-drawer-container>
    </div>
    <div class="" [ngStyle]="{'width': isExpanded ? (100-sideNavigationWidth)+'%' : '100%'}"
      style="background-color: white;">
      <div style="flex-flow: column;margin-left: 1%;overflow-x: hidden;overflow-y:auto; height:calc(100vh - 80px);">
        <section>
          <router-outlet></router-outlet>
        </section>
      </div>
    </div>

  </div>

  <div class="mobile-view" style="width:100%;" *ngIf="flag && isMobile">
    <div class="" *ngIf=" toggleExpand()">
      <mat-drawer-container class="example-container" style="border: none;overflow-x: hidden;">
        <mat-drawer mode="side" opened class="sideNavCss"
          style="overflow-x: hidden;position: relative;padding-top: 15px;border: none;transform: scale; visibility: collapse;padding-bottom: 100%;">
          <div style="flex-flow: column;margin-left: 1%;overflow-x: hidden;overflow-y:auto; height:calc(100vh - 80px);">

            <span *ngFor="let item of menuList">
              <span *ngIf="item.displayStatus && item.children">

                <span *ngIf="item.children && item.children[0]" style="margin: top 10px;overflow-x: hidden;">

                  <mat-expansion-panel *ngFor="let child of item.children"
                    [expanded]="(child.displayName===self)?true:child.isOpen" [disabled]="(child.displayName===self)"
                    [hideToggle]="child.displayName===self" style="background: white;">
                    <mat-expansion-panel-header style="background: white;">
                      <mat-toolbar
                        style="align-items: end; height: 40px;color:#28acaf;   font-size: medium;    margin-left: 15px;background: white;">
                        <span style="background: white;"> {{ child.displayName != self ? child.displayName:
                          item.modulename }}</span>
                      </mat-toolbar>
                    </mat-expansion-panel-header>

                    <span *ngIf="child.subChildren && child.subChildren[0]" style="background-color: white;">
                      <mat-nav-list *ngFor="let subchild of child.subChildren" style="height: 45px;">
                        <mat-list-item [routerLink]="[subchild.routename]" [state]="subchild.functionalities"
                          [routerLinkActive]="['active']" style="font: -webkit-control;"
                          (click)="toggleMobileChild(item,subchild.routename);">
                          <span style="font:auto;"> {{ subchild.screen_name }}</span>
                        </mat-list-item>
                      </mat-nav-list>
                    </span>

                  </mat-expansion-panel>

                </span>
              </span>
            </span>

          </div>
        </mat-drawer>

      </mat-drawer-container>
    </div>
    <div class="">
      <div style="flex-flow: column;margin-left: 1%;overflow-x: hidden;overflow-y:auto; height:calc(100vh - 80px);">
        <section>
          <router-outlet></router-outlet>
        </section>
      </div>
    </div>
  </div>

  <div style="width:100%;" *ngIf="!flag">
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>

</mat-sidenav-container>