
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Spryple';

constructor(public router:Router,private bnIdle: BnNgIdleService,private dialogRef: MatDialog) {

}
  ngOnInit(): void {
  this.bnIdle.startWatching(1800).subscribe((res) => {
     if (res) {
     sessionStorage.removeItem('user');
      sessionStorage.removeItem('sidemenu');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('moduleData');
      sessionStorage.removeItem('compOffEnabled');
      sessionStorage.removeItem('selectedModule');
      sessionStorage.removeItem('expirydate');
      sessionStorage.removeItem('activeChild');
      var login = '/Login'
      this.router.navigate([login]);
        sessionStorage.removeItem('companyName');
        this.dialogRef.closeAll();
      }
    });
 }


}
