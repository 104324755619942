<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title> Permissions Approval History </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end"
                style="height: 70px;padding-top: 1%;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" [tabIndex]="1" placeholder="Search" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <div class="tableP">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> S. No. </th>
                            <td mat-cell *matCellDef="let row;let i = index;">{{i+1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="employee_code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp.
                                ID </th>
                            <td mat-cell *matCellDef="let row"> {{row.emp_code}}</td>
                        </ng-container>

                        <ng-container matColumnDef="employee_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp.
                                Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.employee_name}}</td>
                        </ng-container>

                        <ng-container matColumnDef="designation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                                Designation </th>
                            <td mat-cell *matCellDef="let row"> {{row.designation}}</td>
                        </ng-container>

                        <ng-container matColumnDef="permission_duration">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Permission Duration</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span *ngIf="!isEdit">{{row.duration}} Minutes</span>
                                <!-- <span *ngIf="isEdit">
                              <input formControlName>
                              </span> -->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="usage_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.usage_date |
                                date:'dd-MM-yyyy'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="submission_status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> status</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.submission_status}}
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>