<div fxLayout="column" class="first-col">

    <mat-card fxLayout="column">
        <mat-card-header *ngIf="isaddnew" class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Leave Policies </mat-card-title>
            <div *ngIf="addBtn1 && isLeaveModuleActive" style="margin-right: 2%;">
                <button mat-icon-button style="padding-right: 40px;" (click)="addLeaveConfigure()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <mat-card-header class="headerbox" *ngIf="!isaddnew">

            <mat-card-title class="title">
                <div fxLayout="row"><span> <button mat-icon-button (click)="backArrow()">
                            <mat-icon style="font-weight: bold ;" title="Back">arrow_back</mat-icon>
                        </button></span><span style="padding-top: 9px;">
                        Leave Policies</span></div>
            </mat-card-title>
        </mat-card-header>


        <div *ngIf="isaddnew" fxLayout="column">

            <div class="tableP">
                <form [formGroup]="leavepoliciesForm" autocomplete="off">
                    <h2>Common rules for all leave types</h2>
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef> S. No. </th>
                                <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>

                            </ng-container>


                            <ng-container matColumnDef="configurationrules">
                                <th mat-header-cell *matHeaderCellDef> Configuration Rules </th>
                                <td mat-cell *matCellDef="let data">{{data.ruledescription}}</td>
                            </ng-container>

                            <ng-container matColumnDef="data">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: auto">Value</th>
                                <td mat-cell *matCellDef="let data ;let i = index;" style="padding-top: 1%;">

                                    <div *ngIf="i==0">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="fridayMondayleave" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of arrayValue">
                                                        <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                    </div>

                                                </ng-container>
                                            </mat-select>

                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            (data.value == '1') ? "Yes":"No"}}</span>

                                    </div>
                                    <div *ngIf="i==1">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="cmpHolidayLeave" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of arrayValue">
                                                        <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                    </div>

                                                </ng-container>
                                            </mat-select>

                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            (data.value == '1') ? "Yes":"No"}}</span>

                                    </div>



                                    <div *ngIf="i==2">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <input formControlName="pastdays" onlyNumber matInput maxlength="2">
                                            <mat-error class="con-error"
                                                *ngIf="this.leavepoliciesForm.controls.pastdays?.required">
                                                {{msgLM1}}
                                            </mat-error>
                                        </mat-form-field>
                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            data.value }}</span>
                                        <!-- <input type="text" style="width: 60%;" onlyNumber formControlName="pastdays" matInput [readonly]="!isEditDefaultRules" placeholder="{{data.value}}" maxlength="2"> -->

                                    </div>
                                    <div *ngIf="i==3">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="email" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of arrayValue">
                                                        <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                                        <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                    </div>

                                                </ng-container>
                                            </mat-select>

                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            (data.value == '1') ? "Yes":"No"}}</span>

                                    </div>
                                    <div *ngIf="i==4">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="leavecycleyear" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of arrayValueOne">
                                                        <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                                                        <mat-option
                                                            value="{{b.id}}">{{b.leave_cycle_year_name}}</mat-option>
                                                    </div>

                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            (data.value == '1') ? "Jan-Dec":"Apr-Mar"}}</span>

                                    </div>
                                    <div *ngIf="i==5">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="carrayForwordLeaveTypeId" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of activeLeaveTypesForCarryForword">
                                                        <mat-option [value]="b.id">{{b.leavename}}</mat-option>
                                                        <!--<mat-option value="" selected>Select </mat-option>-->
                                                    </div>

                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{
                                            "Select"}}</span>

                                    </div>
                                    <div *ngIf="i==6">
                                        <mat-form-field appearance="outline" *ngIf="isEditDefaultRules"
                                            style="width:120px">
                                            <mat-select formControlName="maxLeavesCarrayForwordValue" style="width:50%">
                                                <ng-container>
                                                    <div *ngFor="let b of valuesForCarryForwordleaves">
                                                        <!--<mat-option value="" selected>Select </mat-option>-->
                                                        <mat-option [value]="b.value">{{b.name}}</mat-option>
                                                    </div>

                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>

                                        <span class="form-control" *ngIf="!isEditDefaultRules"
                                            style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{"Select"}}</span>

                                    </div>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="addditionalinformation">
                                <th mat-header-cell *matHeaderCellDef> Additional Information </th>
                                <td mat-cell *matCellDef="let data" style="padding-left: 1%;">
                                    {{data.rule_additional_info}}</td>

                            </ng-container>


                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>

                        </table>

                    </div><br>
                    <div fxLayoutAlign="center">
                        <button *ngIf="isEditDefaultRules" class=" btn btn-primary mr-2"
                            (click)="saveDefaultrules()">Update
                        </button>
                        <button *ngIf="isEditDefaultRules" class="btn btn-danger mr-2"
                            (click)="cancelDefaultRules()">Cancel
                        </button>
                        <button *ngIf="!isEditDefaultRules" style="float: right" class=" btn btn-primary"
                            (click)="editDefaultRules()">Edit
                        </button>
                    </div>

                </form>


            </div><br>
            <mat-divider></mat-divider>

            <div class="tableP">
                <div class="mat-elevation-z1 example-container2">
                    <table mat-table [dataSource]="dataSource2" matSort>

                        <ng-container matColumnDef="leavetypename">
                            <th mat-header-cell *matHeaderCellDef> Leave Type Name </th>
                            <td mat-cell *matCellDef="let leave; let i = index;">{{ leave.leavename }} </td>

                        </ng-container>
                        <ng-container matColumnDef="displayname">
                            <th mat-header-cell *matHeaderCellDef> Display Name </th>
                            <td mat-cell *matCellDef="let leave; let i = index;">{{ leave.display_name }} </td>

                        </ng-container>
                        <ng-container matColumnDef="daysperyear">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center"> Days Per Year </th>
                            <td mat-cell *matCellDef="let leave; let i = index;" style="text-align: center"> {{
                                leave.leavetypecount }}</td>

                        </ng-container>


                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef style="padding-left: 60px"> Color </th>

                            <td mat-cell *matCellDef="let leave; let i = index;" style="padding-left: 60px"><span
                                    [style.background-color]="leave.leavecolor" class="customColor"></span></td>

                        </ng-container>


                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let leave; let i = index;">{{(leave.status == 'Active') ?
                                'Active':'Inactive'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let leave; let i = index;">
                                <button type="button" class="btn btn-success"
                                    (click)="editLeaveTypeName(leave)">Edit</button>
                            </td>

                        </ng-container>


                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let leave; columns: displayedColumns2;"></tr>

                    </table>
                </div>
            </div>

        </div>
        <div *ngIf="!isaddnew" fxLayout="column">
            <div *ngIf="addBtn2" fxLayout="row " class="my-2" fxFlex.xs="100%" fxLayoutAlign="end"
                style="padding-top: 1%;">
                <button class="btn btn-primary custom1 mr-2 mb-2" (click)="addnewleave()" type="button">
                    <span>Add New Leave Type</span>
                </button>
            </div>
            <form fxLayout="column" [formGroup]="addleaveForm" autocomplete="off">
                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                    style="padding-top: 1%;padding-left: 1%;">
                    <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Leave Type</mat-label>
                        <mat-select formControlName="leaveid" required>
                            <ng-container>
                                <div *ngFor="let b of leaveTypes">
                                    <mat-option [value]="b.id">{{b.leavename}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="addleaveForm.controls.leaveid.errors?.required">
                            {{msgLM3}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="!isLossOfPayView" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Display Name</mat-label>
                        <input type="text" formControlName="displayname" matInput placeholder="" maxlength="100"
                            *ngIf="isnewleave" required>
                        <input type="text" formControlName="displayname" matInput placeholder="" maxlength="100"
                            readonly="true" *ngIf="!isnewleave" required>
                        <mat-error class="con-error" *ngIf="addleaveForm.controls.displayname.errors?.required">
                            This field is required
                        </mat-error>
                    </mat-form-field>

                    <!-- this is for Loss of pay modification -->
                    <mat-form-field *ngIf="isLossOfPayView" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Display Name</mat-label>
                        <input type="text" formControlName="displayname" matInput placeholder="" maxlength="100"
                            required>
                        <mat-error class="con-error" *ngIf="addleaveForm.controls.displayname.errors?.required">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row " fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="isadvanced">

                    <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
                        <mat-label>Which leave type should be taken as advance leave</mat-label>
                        <mat-select formControlName="advancedleaveid">
                            <ng-container>
                                <div *ngFor="let b of advanceLeavetypes">
                                    <mat-option value={{b.id}}>{{b.leavename}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-divider></mat-divider><br>


                <div class="mat-elevation-z1" *ngIf="tabledata &&isnewleave">
                    <table mat-table [dataSource]="dataSource3" matSort>

                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef> S. No. </th>
                            <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>

                        </ng-container>


                        <ng-container matColumnDef="configurationrules">
                            <th mat-header-cell *matHeaderCellDef> Configuration Rules </th>
                            <td mat-cell *matCellDef="let data" style="padding-left: 10px;">{{data.ruledescription}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="data">
                            <th mat-header-cell *matHeaderCellDef>Value</th>
                            <td mat-cell *matCellDef="let data " style="padding-left: 1%;">
                                <div *ngIf="data.rulename == 'LEAVES_MAX_COUNT_PER_YEAR'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input type="text" onlyNumber formControlName="LEAVES_MAX_COUNT_PER_YEAR"
                                            matInput placeholder="" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MAX_COUNT_PER_YEAR.errors?.required">
                                            {{msgLM1}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_CREDIT_FREQUENCY'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required formControlName="LEAVES_CREDIT_FREQUENCY"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValuess">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_CREDIT_FREQUENCY.errors?.required">
                                            {{msgLM3}}
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_WEEKENDS_INCLUDED'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required formControlName="LEAVES_WEEKENDS_INCLUDED"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValues">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_WEEKENDS_INCLUDED.errors?.required">
                                            {{msgLM3}}
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_COMPANY_HOLIDAYS_INCLUDED'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required formControlName="LEAVES_COMPANY_HOLIDAYS_INCLUDED"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValues">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_COMPANY_HOLIDAYS_INCLUDED.errors?.required">
                                            {{msgLM3}}
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MAX_CAP_FOR_ONE_INSTANCE'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MAX_CAP_FOR_ONE_INSTANCE" matInput placeholder=""
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MAX_CAP_FOR_ONE_INSTANCE.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MIN_SERVICE_ELIGIBILITY'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MIN_SERVICE_ELIGIBILITY" matInput placeholder=""
                                            required maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MIN_SERVICE_ELIGIBILITY.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MIN_DAYS_PRIOR_APPLICATION'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MIN_DAYS_PRIOR_APPLICATION" matInput placeholder=""
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MIN_DAYS_PRIOR_APPLICATION.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_COUNT_TO_BE_CARRIED_FORWARD'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_COUNT_TO_BE_CARRIED_FORWARD" matInput placeholder=""
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_COUNT_TO_BE_CARRIED_FORWARD.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MAX_AVAIL_COUNT'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber formControlName="LEAVES_MAX_AVAIL_COUNT"
                                            matInput placeholder="" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MAX_AVAIL_COUNT.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MIN_DAYS_FOR_DOCUMENT_UPLOAD'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MIN_DAYS_FOR_DOCUMENT_UPLOAD" matInput
                                            placeholder="" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MIN_DAYS_FOR_DOCUMENT_UPLOAD.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_GAP_BETWEEN_TERMS'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_GAP_BETWEEN_TERMS" matInput placeholder=""
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_GAP_BETWEEN_TERMS.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'MAX_AVAIL_COUNT'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <input required type="text" onlyNumber formControlName="MAX_AVAIL_COUNT"
                                            matInput placeholder="" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.MAX_AVAIL_COUNT.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_MAX_COUNT_PER_TERM'">
                                    <mat-form-field appearance="outline" *ngIf="isterm" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MAX_COUNT_PER_TERM" matInput placeholder=""
                                            maxlength="3">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MAX_COUNT_PER_TERM.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" *ngIf="!isterm" style="width:120px">
                                        <input required type="text" onlyNumber
                                            formControlName="LEAVES_MAX_COUNT_PER_TERM" matInput placeholder=""
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_MAX_COUNT_PER_TERM.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_ELIGIBLE_ON_WEEKOFFS'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required formControlName="LEAVES_ELIGIBLE_ON_WEEKOFFS"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValues">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_ELIGIBLE_ON_WEEKOFFS.errors?.required">{{msgLM3}}</mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_ELIGIBLE_ON_COMPANY_HOLIDAYS'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required formControlName="LEAVES_ELIGIBLE_ON_COMPANY_HOLIDAYS"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValues">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_ELIGIBLE_ON_COMPANY_HOLIDAYS.errors?.required">{{msgLM3}}</mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_LAPSED_CONVERSION_TO_PERKS_APPLICABLE'">
                                    <mat-form-field appearance="outline" style="width:120px">
                                        <mat-select required
                                            formControlName="LEAVES_LAPSED_CONVERSION_TO_PERKS_APPLICABLE"
                                            style="width:50%">
                                            <ng-container>
                                                <div *ngFor="let b of arrayValues">
                                                    <mat-option [value]="b.Value">{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_LAPSED_CONVERSION_TO_PERKS_APPLICABLE.errors?.required">{{msgLM3}}</mat-error>
                                    </mat-form-field>

                                </div>
                                <div *ngIf="data.rulename == 'COMPOFF_MIN_WORKING_HOURS_FOR_ELIGIBILITY'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required type="text" style="width: 60%;" onlyNumber
                                            formControlName="COMPOFF_MIN_WORKING_HOURS_FOR_ELIGIBILITY" matInput
                                            placeholder="" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.COMPOFF_MIN_WORKING_HOURS_FOR_ELIGIBILITY.errors?.required">{{msgLM3}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'COMPOFF_MAX_BACKDATED_DAYS_PERMITTED_FOR_SUBMISSION'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required type="text" onlyNumber
                                            formControlName="COMPOFF_MAX_BACKDATED_DAYS_PERMITTED_FOR_SUBMISSION"
                                            matInput maxlength="3">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.COMPOFF_MAX_BACKDATED_DAYS_PERMITTED_FOR_SUBMISSION.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div
                                    *ngIf="data.rulename == 'COMPOFF_THRESHOLD_DAYS_TO_LAPSE_OR_CONVERT_LEAVES_TO_PERKS'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required type="text" style="width: 60%;" onlyNumber
                                            formControlName="COMPOFF_THRESHOLD_DAYS_TO_LAPSE_OR_CONVERT_LEAVES_TO_PERKS"
                                            matInput placeholder="">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.COMPOFF_THRESHOLD_DAYS_TO_LAPSE_OR_CONVERT_LEAVES_TO_PERKS.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'leavecolor'">
                                    <input required style="width: 50%;" matInput formControlName="leavecolor"
                                        value="leavecolor" type="color">
                                    <div class="con-error" *ngIf="isLeaveColorAlreadyExists&&!editingleavetype "
                                        style="color: red;">This color already existed</div>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_ENCASHMENT_MIN_COUNT_ELIGIBILITY'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required style="width: 50%;" onlyNumber matInput
                                            formControlName="LEAVES_ENCASHMENT_MIN_COUNT_ELIGIBILITY" type="text"
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_ENCASHMENT_MIN_COUNT_ELIGIBILITY.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div
                                    *ngIf="data.rulename == 'SICK_LEAVES_MIN_DAYS_PRIOR_APPLICATION_FOR_KNOWN_AILMENTS'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required style="width: 50%;" onlyNumber matInput
                                            formControlName="SICK_LEAVES_MIN_DAYS_PRIOR_APPLICATION_FOR_KNOWN_AILMENTS"
                                            type="text" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.SICK_LEAVES_MIN_DAYS_PRIOR_APPLICATION_FOR_KNOWN_AILMENTS.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_LAPSE_PERIOD'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required style="width: 50%;" onlyNumber matInput
                                            formControlName="LEAVES_LAPSE_PERIOD" type="text" maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_LAPSE_PERIOD.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="data.rulename == 'LEAVES_ELIGIBILITY_MINIMUM_HOURS'">
                                    <mat-form-field appearance="outline" style="width: 120px">
                                        <input required matInput onlyNumber
                                            formControlName="LEAVES_ELIGIBILITY_MINIMUM_HOURS" type="text"
                                            maxlength="2">
                                        <mat-error class="con-error"
                                            *ngIf="addleaveForm.controls.LEAVES_ELIGIBILITY_MINIMUM_HOURS.errors?.required">{{msgLM1}}</mat-error>
                                    </mat-form-field>
                                </div>

                                <!-- <input style="width: 50%;" matInput formControlName="colors" type="color"   placeholder="Color" required> -->

                                <!-- <div *ngIf="i==2">
                                        <mat-select formControlName="leavecycleyear" >
                                            <ng-container>
                                                <div *ngFor="let b of arrayValuess">
                                                    <mat-option value={{b.value}}>{{b.name}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                        <span class="form-control" *ngIf="!isEditDefaultRules" style="background: #e8e8e8 !important;border-color: #e8e8e8 !important;opacity: 0.6 !important;width: 60%">{{ (data.value == '1') ? "Jan-Dec":"Apr-Mar"}}</span>

                                    </div>-->
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="addditionalinformation">
                            <th mat-header-cell *matHeaderCellDef style="padding-left:25px;"> Additional Information
                            </th>
                            <td mat-cell *matCellDef="let data" style="padding-left:25px;">{{data.rule_additional_info}}
                            </td>

                        </ng-container>
                        <!-- <div > -->
                        <ng-container matColumnDef="actions">

                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let data; let i = index;"><mat-slide-toggle [checked]="ischecked"
                                    (change)="toglechange($event,data)"></mat-slide-toggle> </td>


                        </ng-container>

                        <!-- </div> -->



                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                        <tr mat-row *matRowDef="let data; columns: displayedColumns3;"></tr>

                    </table><br>


                </div><br>

            </form>
            <!-- <div class="form-group row ml-1 mt-3" *ngIf="!isnewleave">
                <div class="col-sm-6 mr2 text-right">
               
                    <button class="col-md-3 mr-2 btn btn-primary"  (click)="Activateleaves()"  >Activate</button>
                    <button class="col-md-3  btn btn-danger"   (click)="cancelledleave()">Cancel</button>
                </div>

            </div> -->
            <div *ngIf="tabledata && advanceflag " class="form-group row" fxLayoutAlign="center">
                <!-- <button class="col-md-3 btn btn-default" (click)="setLeaveStatus(true)" *ngIf="isactivate">Activate</button> -->
                <button class="btn btn-danger mr-2 mb-2 custom" (click)="setLeaveStatus(false)"
                    *ngIf="isdeactivate">Deactivate</button>

                <!-- <button class="col-md-2 mr-2 btn btn-primary" (click)="setleavepolicies()"
                        *ngIf="this.editemployee">Save</button> -->
                <button class="btn btn-primary mr-2 mb-2" (click)="setleavepolicies()"
                    *ngIf="!editingleavetype">Submit</button>
                <button class="btn btn-primary mr-2 mb-2" (click)="setleavepolicies()"
                    *ngIf="editingleavetype">Update</button>
                <button class="btn btn-primary mr-2 mb-2 custom2" (click)="setLeaveStatus(true)"
                    *ngIf="!isnewleave">Activate</button>

            </div>
            <div fxLayoutAlign="center" *ngIf="addadvanced">
                <button class="btn btn-danger mr-2 mb-2 custom" (click)="setLeaveStatus(false)"
                    *ngIf="isdeactivate">Deactivate</button>
                <button class="btn btn-primary mr-2 mb-2" (click)="setadvanceleavepolicies()">Update</button>
                <button class="btn btn-danger mr-2 mb-2" (click)="cancelledleave()">Cancel</button>

            </div>
            <!-- <div *ngIf="tabledata && !advanceflag" >
                <div  fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">
                    <button class="col-md-1  btn btn-danger"   (click)="cancelledleave()">Close</button>
                </div>
            </div> -->
            <!-- this is for loss of pay leave display name edit  -->
            <div fxLayoutAlign="center" *ngIf="isLossOfPayView">
                <button class="btn btn-primary mr-2 mb-2" (click)="updateLeaveDisplayName()">Update</button>
                <button class="btn btn-danger mr-2 mb-2" (click)="cancelledleave()">Close</button>
            </div>

        </div>

    </mat-card>






</div>