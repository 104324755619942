import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {  MapMarker } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable,of } from 'rxjs';
import { startWith, map ,catchError} from 'rxjs/operators';
import {CrmService} from "../../crm.service";
import { HttpClient } from '@angular/common/http';
import {MapService} from "src/app/modules/crm/map.service"; 
@Component({
  selector: 'app-crm-search-location',
  templateUrl: './crm-search-location.component.html',
  styleUrls: ['./crm-search-location.component.scss']
})
export class CrmSearchLocationComponent implements OnInit {
  //@ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
  myControl = new FormControl();
  @ViewChild('addressInput') addressInput!: ElementRef;
  displayOptions: any[] = [];
  filteredOptions: Observable<void> | undefined ;
  //@ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap | undefined;
  map:any; // google.maps.Map;
  
  marker:any; //google.maps.marker.AdvancedMarkerElement | undefined;
  markers:any; // google.maps.Marker[] = []; 
  markerInMap:any; // = new google.maps.Marker({});
  infoWindow:any; // = google.maps.InfoWindow;
  center:any={lat :0,lng: 0}; // = new google.maps.LatLng(17.457129626588678, 78.37068316931055);
   place:any ;
  service:any;
  markerPositions: any=[]; 
  infoContent :any;
  //apiLoaded: Observable<boolean>;
  isSelected:any = false;
  googleApiKey:any = null;
  userSession:any = null;
  mapInitialized:any=false;
  mapOptions: google.maps.MapOptions = {
    zoom: 12,
  };
  constructor(private crm:CrmService,private httpClient:HttpClient,private mapService:MapService,) {
    if(!this.mapInitialized){
      this.mapService.initMap().then(() => {
        this.initAsyncMap();
        this.initAsyncMap2();
        // Code to execute after the map has been initialized
        this.mapInitialized = true;
      }).catch((error: any) => {
        // Handle any errors that occurred during map initialization
        console.error('Error initializing map:', error);
      });
    }
    
    //this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key={key}&libraries=places&v=weekly', 'callback')
   // .pipe(map(() => true),catchError(() => of(false)), );
   }
   initAsyncMap2() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressInput.nativeElement,
      //{ types: ['geocode']   } // Restrict to addresses
    );
    
    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();
      if(place.geometry && place.geometry.location){
         this.createMarker(place);
      }
    });
  }
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if(!this.mapInitialized){
      this.mapService.initMap().then(() => {
        this.initAsyncMap();
        this.initAsyncMap2();
        // Code to execute after the map has been initialized
        this.mapInitialized = true;
      }).catch((error: any) => {
        // Handle any errors that occurred during map initialization
        console.error('Error initializing map:', error);
      });
    }
    // this.myControl.setValue('');
    // this.myControl.valueChanges.subscribe((val: any) => {
    //   //if(!this.isSelected){
    //     if(val.toString().length>=3){
    //       this.initAsyncMapPlaces(val);
    //     }
    // });
  }

  // async initAsyncMapPlaces(val:any): Promise<void> {
  //   if(typeof val === 'string'){
  //     this.isSelected = false;
  //     this.displayOptions = [];
  //     this.service =  new google.maps.places.PlacesService(this.map);
  //     this.service.findPlaceFromQuery( {query: val,fields: ["name", "geometry"] },(places:any, status:any) => {
  //       if (status === google.maps.places.PlacesServiceStatus.OK && places) {
  //         places?.forEach(async ( place:any ) => {
  //           this.displayOptions?.push(place);
  //         });
  //       }
  //     });
  //   }
  // }
  async initAsyncMap()  {
    this.displayOptions = [];
    navigator.geolocation.getCurrentPosition( (position) => {
      this.center ={lat : position.coords.latitude,lng: position.coords.longitude};
    },(error) => console.log(error));
    // const script = document.createElement('script');
    // //script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
    // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4&libraries=places&v=weekly`;
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
   
    // Request needed libraries.
    //@ts-ignore
    const [{ Map }, { AdvancedMarkerElement }] = await Promise.all([
      google.maps.importLibrary("marker"),
      google.maps.importLibrary("places"),
    ]);
    this.map = google.maps.Map;
    this.marker = google.maps.marker.AdvancedMarkerElement ;
    this.markers= google.maps.Marker;
    this.markerInMap = new google.maps.Marker({});
    this.infoWindow = google.maps.InfoWindow;
    
    this.infoWindow = new google.maps.InfoWindow({});
    this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: this.center,
      zoom: 13, 
      mapTypeControl: false,
    });
    // this.addMarker(this.center);
  }

  selectValue(option:any) {
    this.isSelected = true;
    this.myControl.setValue(option.name);
    this.createMarker(option);
  }
  
  // Helper function to create an info window.
  //  updateInfoWindow(content:any, center:any) {
    
   
  //   this.infoWindow.open({
  //     map:this.map,
  //     anchor: this.marker,
  //     shouldFocus: false,
  //   });
  //   this.infoWindow.open(this.map);
  // }

  createMarker(place:any) {
    this.markers = [];
    //this.markerInMap?.Remove();
    if (!place.geometry || !place.geometry.location) return;
    
    // let marker = new google.maps.Marker({
    //   map: this.map,
    //   position: place.geometry.location,
    // });
    // this.markerInMap = new google.maps.Marker({
    //   map: this.map,
    //   position: place.geometry.location
    // });
    this.addMarker(place.geometry.location);
    //this.marker = place.geometry.location;
    this.map.setCenter(place.geometry.location);
   // google.maps.event.addListener(this.marker, "click", () => {
      //this.infoWindow.setContent(content);
      this.infoWindow.setPosition(place.geometry.location);
      //this.infoWindow? this.infoWindow.open(marker):null; 
    //});
  }

// Sets the map on all markers in the array.
setMapOnAll() {
  for (let i = 0; i < this.markers.length; i++) {
    this.markers[i].setMap(this.map);
  }
}

addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
  const marker = new google.maps.Marker({
    position,
    map:this.map,
  });

  //this.markers.push(marker);
}
 
  
openInfo(marker: MapMarker, content: any) {  
  this.infoContent = content;  
  this.infoWindow? this.infoWindow.open(marker):null; 
}
}
