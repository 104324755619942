import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin.service';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';

@Component({
  selector: 'app-payroll-policies',
  templateUrl: './payroll-policies.component.html',
  styleUrls: ['./payroll-policies.component.scss']
})
export class PayrollPoliciesComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router,
    private dialog: MatDialog, private payrollService: PayrollService,) {
  }
  payrollPoliciesForm!: FormGroup;

  displayedColumns: string[] = ['sno', 'configurationrules', 'data'];
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  companyDBName: any = environment.dbName;
  defaultRuleInfo: any = [];
  isEditDefaultRules: boolean = false;
  defaultDropdownList: any = [{ value: '1', name: 'Yes' }, { value: '0', name: 'No' }];

  msgLM1: any;
  msgLM2: any;
  msgLM3: any;
  msgLM21: any;
  msgLM110: any;
  msgLM111: any;
  msgLM133: any;
  msgLM134: any;
  msgLM135: any;
  msgLM136: any;

  ngOnInit(): void {
    this.payrollPoliciesForm = this.formBuilder.group(
      {
        noOfInstallments: [""],
        monthsToResumeLastRecovery: [""],
        otAmountIncludeSalary: [""],
        isCompanyCoveredUnderGratuityAct: [""],
        isGratuityMonthlyPaid: [""],
        isVariableDaApplicable: [""],
        isAbsentConsiderLop: [""],
        isDynamicPayrollApplicable: [""],
        leavesThresholdForDynamicPayroll: [""],

      },
    );
    this.getPayrollRules();
  }
  /**Common ems rules */
  getPayrollRules() {
    this.payrollService.getPayrollPolicies().subscribe((result) => {
      this.defaultRuleInfo = result.data;
      this.dataSource = new MatTableDataSource(this.defaultRuleInfo);

      for (let i = 0; i < this.defaultRuleInfo.length; i++) {

        if (this.defaultRuleInfo[i].rule_name == 'NUMBER_OF_INSTALLMENTS_FOR_ADV_SAL_RECOVERY') {
          this.payrollPoliciesForm.controls.noOfInstallments.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.noOfInstallments.disable();
        }
        else if (this.defaultRuleInfo[i].rule_name == 'MONTHS_TO_RESUME_ADV_SAL_AFTER_LAST_RECOVERY') {
          this.payrollPoliciesForm.controls.monthsToResumeLastRecovery.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.monthsToResumeLastRecovery.disable();

        }
        else if (this.defaultRuleInfo[i].rule_name == 'OVER_TIME_AMOUNT_TO_BE_INCLUDED_IN_SALARY') {
          this.payrollPoliciesForm.controls.otAmountIncludeSalary.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.otAmountIncludeSalary.disable();
        }
        else if (this.defaultRuleInfo[i].rule_name == 'IS_COMPANY_COVERED_UNDER_GRATUITY_ACT') {
          this.payrollPoliciesForm.controls.isCompanyCoveredUnderGratuityAct.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.isCompanyCoveredUnderGratuityAct.disable();
        }
        else if (this.defaultRuleInfo[i].rule_name == 'IS_GRATUITY_MONTHLY_PAID') {
          this.payrollPoliciesForm.controls.isGratuityMonthlyPaid.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.isGratuityMonthlyPaid.disable();
        }
        else if (this.defaultRuleInfo[i].rule_name == 'IS_VARIABLE_DA_APPLICABLE') {
          this.payrollPoliciesForm.controls.isVariableDaApplicable.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.isVariableDaApplicable.disable();

        }  else if (this.defaultRuleInfo[i].rule_name == 'IS_ABSENT_CONSIDERED_AS_LOP') {
          this.payrollPoliciesForm.controls.isAbsentConsiderLop.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.isAbsentConsiderLop.disable();

        } else if (this.defaultRuleInfo[i].rule_name == 'IS_DYNAMIC_PAYROLL_PRCESSING_APPLICABLE') {
          this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.disable();

        } else if (this.defaultRuleInfo[i].rule_name == 'NUMBER_OF_LEAVES_THRESHOLD_FOR_DYNAMIC_PAYROLL_PROCESSING') {
          this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.setValue(this.defaultRuleInfo[i].value);
          this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.disable();
        } 
      }
    })
  }

  saveDefaultrules() {

    if (this.payrollPoliciesForm.valid) {

      if( this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.value == 1 &&
        this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.value == null){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Please enter number of leaves threshold for dynamic payroll processing',
          });
        } 
        else{

      for (let i = 0; i < this.defaultRuleInfo.length; i++) {

        if (this.defaultRuleInfo[i].rule_name == 'NUMBER_OF_INSTALLMENTS_FOR_ADV_SAL_RECOVERY') {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.noOfInstallments.value;

        }
        else if (this.defaultRuleInfo[i].rule_name == "MONTHS_TO_RESUME_ADV_SAL_AFTER_LAST_RECOVERY") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.monthsToResumeLastRecovery.value;

        }
        else if (this.defaultRuleInfo[i].rule_name == "OVER_TIME_AMOUNT_TO_BE_INCLUDED_IN_SALARY") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.otAmountIncludeSalary.value;

        }
        else if (this.defaultRuleInfo[i].rule_name == "IS_COMPANY_COVERED_UNDER_GRATUITY_ACT") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.isCompanyCoveredUnderGratuityAct.value;

        }
        else if (this.defaultRuleInfo[i].rule_name == "IS_GRATUITY_MONTHLY_PAID") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.isGratuityMonthlyPaid.value;

        }
        else if (this.defaultRuleInfo[i].rule_name == "IS_VARIABLE_DA_APPLICABLE") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.isVariableDaApplicable.value;
       
        } 
        else if (this.defaultRuleInfo[i].rule_name == "IS_ABSENT_CONSIDERED_AS_LOP") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.isAbsentConsiderLop.value;
       
        } else if (this.defaultRuleInfo[i].rule_name == "IS_DYNAMIC_PAYROLL_PRCESSING_APPLICABLE") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.value;
       
        } else if (this.defaultRuleInfo[i].rule_name == "NUMBER_OF_LEAVES_THRESHOLD_FOR_DYNAMIC_PAYROLL_PROCESSING") {
          this.defaultRuleInfo[i].value = this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.value;
       
        } 
      }
      var info = {
        ruleData: this.defaultRuleInfo
      }

      this.payrollService.setPayrollRuleValues(info).subscribe((result) => {

        if (result.status) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: result.message
          });
          this.isEditDefaultRules = false;
          this.getPayrollRules();
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to update Payroll policies. Please try again.'
          });
        }
      });

    }
    }

  }


  cancelDefaultRules() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/payrollPolicies"]));
  }
  editDefaultRules() {

    this.isEditDefaultRules = true;
    this.payrollPoliciesForm.controls.noOfInstallments.enable();
    this.payrollPoliciesForm.controls.monthsToResumeLastRecovery.enable();
    this.payrollPoliciesForm.controls.otAmountIncludeSalary.enable();
    this.payrollPoliciesForm.controls.isCompanyCoveredUnderGratuityAct.enable();
    this.payrollPoliciesForm.controls.isGratuityMonthlyPaid.enable();
    this.payrollPoliciesForm.controls.isVariableDaApplicable.enable();
    this.payrollPoliciesForm.controls.isAbsentConsiderLop.enable();
    this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.enable();
    this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.enable();

    for (let i = 0; i < this.defaultRuleInfo.length; i++) {

      if (this.defaultRuleInfo[i].rule_name == 'NUMBER_OF_INSTALLMENTS_FOR_ADV_SAL_RECOVERY') {
        this.payrollPoliciesForm.controls.noOfInstallments.setValue(this.defaultRuleInfo[i].value)
      }
      else if (this.defaultRuleInfo[i].rule_name == 'MONTHS_TO_RESUME_ADV_SAL_AFTER_LAST_RECOVERY') {
        this.payrollPoliciesForm.controls.monthsToResumeLastRecovery.setValue(this.defaultRuleInfo[i].value)

      }
      else if (this.defaultRuleInfo[i].rule_name == 'OVER_TIME_AMOUNT_TO_BE_INCLUDED_IN_SALARY') {
        this.payrollPoliciesForm.controls.otAmountIncludeSalary.setValue(this.defaultRuleInfo[i].value)

      }
      else if (this.defaultRuleInfo[i].rule_name == 'IS_COMPANY_COVERED_UNDER_GRATUITY_ACT') {
        this.payrollPoliciesForm.controls.isCompanyCoveredUnderGratuityAct.setValue(this.defaultRuleInfo[i].value)

      }
      else if (this.defaultRuleInfo[i].rule_name == 'IS_GRATUITY_MONTHLY_PAID') {
        this.payrollPoliciesForm.controls.isGratuityMonthlyPaid.setValue(this.defaultRuleInfo[i].value)

      }
      else if (this.defaultRuleInfo[i].rule_name == 'IS_VARIABLE_DA_APPLICABLE') {
        this.payrollPoliciesForm.controls.isVariableDaApplicable.setValue(this.defaultRuleInfo[i].value)

      }  else if (this.defaultRuleInfo[i].rule_name == 'IS_ABSENT_CONSIDERED_AS_LOP') {
        this.payrollPoliciesForm.controls.isAbsentConsiderLop.setValue(this.defaultRuleInfo[i].value)

      } else if (this.defaultRuleInfo[i].rule_name == 'IS_DYNAMIC_PAYROLL_PRCESSING_APPLICABLE') {
        this.payrollPoliciesForm.controls.isDynamicPayrollApplicable.setValue(this.defaultRuleInfo[i].value)

      } else if (this.defaultRuleInfo[i].rule_name == 'NUMBER_OF_LEAVES_THRESHOLD_FOR_DYNAMIC_PAYROLL_PROCESSING') {
        this.payrollPoliciesForm.controls.leavesThresholdForDynamicPayroll.setValue(this.defaultRuleInfo[i].value)

      } 
     
    }
  }


}
