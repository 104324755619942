import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.scss']
})
export class DialogDetailComponent implements OnInit {
  arrString: any;

  constructor(private reportsService: ReportsService,
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'time', 'category', 'location', 'source'];
  List: any[] = [];
  isLoading = false;
  isRegularization = false;
  employeeSummaryReport = false;
  imageurls = [{
    base64String: "assets/img/new-profile.png"
  }];
  Inimage: any;
  Outimage: any;
  base64String: any;
  employeeData: any;
  attendanceDate: any;
  empId: any;
  empName: any;
  empTotalHrs: any;
  empBreaktime: any;
  empProductHrs: any;
  empOtHrs: any;
  empPermisionHrs: any;
  firstIn: any;
  lastOut: any;
  location: any;
  source: any;
  ngOnInit(): void {
    this.getAttendanceDetailsByAttendanceId()
  }
  onOkClick(): void {
    this.dialogRef.close();
  }
  getAttendanceDetailsByAttendanceId() {
    let obj = {
      'attendanceid': this.data.attendanceid,
      'type': this.data.type,
    }
    if (obj.type === 'employee') {
      this.employeeSummaryReport = true;
    }
    this.List = [];
    this.reportsService.getAttendanceDetailsByAttendanceId(obj).subscribe((res: any) => {
      if (res.status) {

        this.List = res.data;
        let newList = this.List[0].details
        this.employeeData = JSON.parse(newList)[0];
        this.attendanceDate = this.employeeData.attendancedate;
        this.empId = this.employeeData.empcode;
        this.empName = this.employeeData.empname;
        this.empTotalHrs = this.employeeData.totalhours == null ? null : this.employeeData.totalhours.split('.')[0];
        this.empBreaktime = this.employeeData.breaktime == null ? null : this.employeeData.breaktime.split('.')[0];
        this.empProductHrs = this.employeeData.productivehours == null ? null : this.employeeData.productivehours.split('.')[0];
        this.empOtHrs = this.employeeData.overtime_minutes == null ? null : this.employeeData.overtime_minutes.split('.')[0];
        this.empPermisionHrs = this.employeeData.permissionhours == null ? null : this.employeeData.permissionhours.split('.')[0];
        this.firstIn = this.employeeData.firstlogintime;
        this.lastOut = this.employeeData.lastlogouttime;
        this.location = this.employeeData.empname;
        this.source = this.employeeData.empname;
        /** */
        const prefix = 'base64:type252:';
        const originalStringPunchIn = this.employeeData.punchin_image;
        const originalStringPunchOut = this.employeeData.punchout_image;
        /**Remove Prefix from getting IN and OUT Images */
        const base64StringIn = this.employeeData.punchin_image == null ? null : originalStringPunchIn.startsWith(prefix) ?
          originalStringPunchIn.slice(prefix.length) : originalStringPunchIn;
        const base64StringOut = this.employeeData.punchout_image == null ? null : originalStringPunchOut.startsWith(prefix) ?
          originalStringPunchOut.slice(prefix.length) : originalStringPunchOut;
        /** */
        /**Add In and Out Images */
        this.Inimage = this.employeeData.punchin_image == null ? null : "data:image/png;base64," + base64StringIn;
        this.Outimage = this.employeeData.punchout_image == null ? null : "data:image/png;base64," + base64StringOut;
        if (this.employeeData.regularization_flag == 1) {
          this.isRegularization = true;
        }

        let detaledList = this.employeeData.punchdetails
        if (detaledList.length > 0) {
          this.dataSource = new MatTableDataSource(detaledList);
          this.isLoading = false;
        }
      }
    })
  }
}
