import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmsService } from 'src/app/modules/ems/ems.service';

@Component({
  selector: 'app-report-popup-cm',
  templateUrl: './report-popup-cm.component.html',
  styleUrls: ['./report-popup-cm.component.scss'],
})
export class ReportPopupCmComponent implements OnInit {
  earningData = [
    { id: 1, name: 'Employee Status' },
    { id: 2, name: 'Employee Type' },
    { id: 3, name: 'Department' },
    { id: 4, name: 'Designation' },
    { id: 5, name: 'Location' },
    { id: 6, name: 'Gender' },
    { id: 7, name: 'Blood Group' },
    { id: 7, name: 'Marital Status' },
    { id: 9, name: 'Shifts' },
    { id: 10, name: 'Reporting Manager' },
  ];
  userSession: any;
  get earningsFormArray() {
    return this.reportpopupForm.controls.earnings as UntypedFormArray;
  }
  reportpopupForm: any = UntypedFormGroup;
  status!: boolean;
  data: any = [];
  isindeterminate: boolean = false;
  ischecked: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ReportPopupCmComponent>,
    @Inject(MAT_DIALOG_DATA)
    public message: string,
    private ES: EmsService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {
    this.reportpopupForm = this.formBuilder.group({
      check: [],
      earnings: new UntypedFormArray([]),
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.addCheckboxes();
    this.getEmsEmployeeColumnConfigurationValue();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  /**This function used for select all functionality. */
  onChange($event: any) {
    if ($event.checked) {
      this.earningsFormArray.setValue([1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
      this.isindeterminate = false;
      this.ischecked = true;
    } else {
      this.earningsFormArray.setValue([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      this.isindeterminate = false;
      this.ischecked = false;
    }
  }
  ngOnInit(): void {}

  private addCheckboxes() {
    this.earningData.forEach(() =>
      this.earningsFormArray.push(new UntypedFormControl(false))
    );
  }

  getEmsEmployeeColumnConfigurationValue() {
    this.ES.getEmsEmployeeColumnConfigurationValue(
      this.userSession.id
    ).subscribe((result: any) => {
      this.data = result.data;
      let arr = JSON.parse(JSON.stringify(result.data[0].configurations)).split(
        ','
      );
      if (
        Number(arr[0]) == 1 &&
        Number(arr[1]) == 1 &&
        Number(arr[2]) == 1 &&
        Number(arr[3]) == 1 &&
        Number(arr[4]) == 1 &&
        Number(arr[5]) == 1 &&
        Number(arr[6]) == 1 &&
        Number(arr[7]) == 1 &&
        Number(arr[8]) == 1 &&
        Number(arr[9] == 1)
      ) {
        //  this.reportpopupForm.controls.check.setValue(true)
        this.ischecked = true;
      } else if (
        Number(arr[0]) ||
        Number(arr[1]) ||
        Number(arr[2]) ||
        Number(arr[3]) ||
        Number(arr[4]) ||
        Number(arr[5]) ||
        Number(arr[6]) ||
        Number(arr[7]) ||
        Number(arr[8]) ||
        Number(arr[9])
      ) {
        // this.reportpopupForm.controls.check.setValue(false);
        this.isindeterminate = true;
      } else {
        // this.reportpopupForm.controls.check.setValue(false)
        this.isindeterminate = false;
        this.ischecked = false;
      }
      this.earningsFormArray.setValue([
        Number(arr[0]),
        Number(arr[1]),
        Number(arr[2]),
        Number(arr[3]),
        Number(arr[4]),
        Number(arr[5]),
        Number(arr[6]),
        Number(arr[7]),
        Number(arr[8]),
        Number(arr[9]),
      ]);
    });
  }
  save() {
    const earningselectedIds = this.reportpopupForm.value.earnings
      .map((checked: any, i: any) => (checked ? 1 : 0))
      .filter((v: any) => v !== null);
    let data = {
      empid: this.userSession.id,
      employee_status_value: earningselectedIds[0],
      employee_type: earningselectedIds[1],
      department_value: earningselectedIds[2],
      designation_value: earningselectedIds[3],
      location_value: earningselectedIds[4],
      gender_value: earningselectedIds[5],
      blood_group_value: earningselectedIds[6],
      marital_status_value: earningselectedIds[7],
      shift_value: earningselectedIds[8],
      reporting_manager_value: earningselectedIds[9],
    };
    this.ES.setEmsEmployeeColumnConfigurationValues(data).subscribe(
      (res: any) => {
        if (res.status) {
          this.dialogRef.close();
          // this.router
          //   .navigateByUrl('/', { skipLocationChange: true })
          //   .then(() => this.router.navigate(['/Attendance/commonReports']));
        }
      }
    );
  }
}
