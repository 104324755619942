<div>
    <br>
    <br>
    <h2>Frequently Asked Questions</h2>

    <div fxLayout="column" >
        <form [formGroup]="faqForm" >
            
          <mat-form-field fxFlex="30" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Module Name</mat-label>
            <mat-select formControlName="module">
              <ng-container>
                <span *ngFor="let list of allModuleDetails">
                  <mat-option [value]="list.id" >{{list.modulename}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Screen Name</mat-label>
            <mat-select formControlName="screen" >
              <ng-container>
                <span *ngFor="let list of moduleList">
                  <mat-option [value]="list.id">{{list.name}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
          </mat-form-field> -->

        </form>
       
        <div>
          <mat-accordion>
       <span *ngIf="faqList.length > 0" >
        <mat-expansion-panel *ngFor="let value of faqList;index as i;" class="card" [togglePosition]="'before'">
          <mat-expansion-panel-header>
            <mat-panel-title class="header" >{{value.question}}</mat-panel-title>
          </mat-expansion-panel-header>
        <p style="font-size: 16px;" >{{value.answer}}</p>
        </mat-expansion-panel>
       </span>
       <span *ngIf="faqList.length === 0" style="text-align: center;" >
        <p>No data found</p>
        </span>
            <br>
          </mat-accordion>
        </div>
    </div>
</div>