<form [formGroup]="reportpopupForm" autocomplete="off">
    <mat-checkbox (change)='onChange($event)' [checked]="ischecked" [indeterminate]="isindeterminate" color='primary'>
        Select All</mat-checkbox>
    <p formArrayName="earnings" *ngFor="let order of earningsFormArray.controls; let i = index">
        <input type="checkbox" [formControlName]="i">
        {{earningData[i].name}}
    </p>

    <div fxLayoutAlign="center">
        <button class="btn btn-primary mr-2 mb-2" (click)="save()">Save</button>
        <button class="btn btn-danger mr-2 mb-2" (click)="onNoClick()">Cancel</button>
    </div>
</form>