import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { PopupComponent, PopupConfig } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { DatePipe } from '@angular/common';
// import { EmsService } from 'src/app/modules/ems/ems.service';


@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup: any = UntypedFormGroup;
  email: any;
  password: any;
  msgLM14: any;
  msgLM1: any;
  msgLM2: any;
  employeeId: any;
  issubmit: boolean = false;
  companyName: any;
  userlocalSessionemail: any;
  userlocalSessionpassword: any;
  userlocalSessionrememberme: any;
  pipe = new DatePipe('en-US');
  expiryDate: any;
  constructor(private formBuilder: UntypedFormBuilder, private dialog: MatDialog,
    private tss: LoginService, private router: Router,
    private activatedRoute: ActivatedRoute,) {
  }
  messagesDataList: any = [];

  ngOnInit() {
    let params: any = this.activatedRoute.snapshot.params;
    this.companyName = params.companyName;

    this.createForm();
    this.formGroup.controls.username.setValue(localStorage.getItem("username"));
    this.formGroup.controls.password.setValue(localStorage.getItem("password"));
    this.formGroup.controls.comapnyname.setValue(localStorage.getItem("comapnyname"));
    this.formGroup.controls.rememberme.setValue((localStorage.getItem("rememberme") == 'false') ? 0 : 1);
  }
  hide = true;

  createForm() {
    this.formGroup = this.formBuilder.group({
      'comapnyname': ['', Validators.required],
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'rememberme': ['']
    });
  }
  login() {
    this.issubmit = true;
    this.email = this.formGroup.controls.username.value.trim();
    this.password = this.formGroup.controls.password.value.trim();
    let companycode = this.formGroup.controls.comapnyname.value.trim().toLowerCase();
    let data = {
      email: this.email,
      password: this.password,
      companyName: companycode,
    }
    if (this.formGroup.valid) {

      if (this.formGroup.controls.rememberme.value == true) {
        localStorage.setItem("comapnyname", companycode);
        localStorage.setItem("username", this.formGroup.controls.username.value);
        localStorage.setItem("password", this.formGroup.controls.password.value);
        localStorage.setItem("rememberme", this.formGroup.controls.rememberme.value);
      } else if (this.formGroup.controls.rememberme.value == false) {
        localStorage.setItem("comapnyname", '');
        localStorage.setItem("username", '');
        localStorage.setItem("password", '');
        localStorage.setItem("rememberme", 'false');
      }
      this.tss.Savelogin(data).subscribe((data) => {
        if (data.status === true) {
          sessionStorage.setItem('companyName', companycode);
          let empdata = data.result[0];
          empdata.is_manager = 0;
          empdata.is_sales_manager = 0;
          empdata.is_sales_admin = 0;
          empdata.is_sales_executive = 0;
          empdata.roles?.forEach((r: any) => {
            if (r.role_name.toLowerCase().replace(' ', '').includes('salesmanager'))
              empdata.is_sales_manager = 1;
            if (r.role_name.toLowerCase().replace(' ', '').includes('manager'))
              empdata.is_manager = 1;
            if (r.role_name.toLowerCase().replace(' ', '').includes('salesadmin'))
              empdata.is_sales_admin = 1;
            if (r.role_name.toLowerCase().replace(' ', '').includes('salesexecutive'))
              empdata.is_sales_executive = 1;
          });
          this.expiryDate = this.pipe.transform(data.expirydate, 'y-MM-dd');
          sessionStorage.setItem('token', data.token);
          sessionStorage.setItem('user', JSON.stringify(empdata));
          sessionStorage.setItem('expirydate', this.expiryDate);
          sessionStorage.setItem('moduleData', '');
          sessionStorage.setItem('client_Id', data.client_Id);
          if (empdata.roles[0].role_name == "Product Admin") {
            this.router.navigate(['/Admin/product-admin-dashboard'])
          } else if (empdata.roles[0].role_name == "Product Manager") {
            this.router.navigate(['/Admin/admin_ticket'])
          } else if (empdata.roles[0].role_name == "Product Employee") {
            this.router.navigate(['/Admin/employee_ticket'])
          }
          else {
            /** 1st condition - if user count is null */
            if (data.subscribedUserCount == null) {
              /** if login user is super admin */
              if (data.result[0].is_super_admin == 1) {
                this.employeeId = data.result[0].id;
                this.router.navigate(['/Admin/add-renewal-users'])

              }
              /** if login user is employee */
              else {
                this.router.navigate(['/Login']);
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: 'Your subscription validity is expired. Please contact your Admin.'
                });
              }
            }
            /** 2nd condition - if user count is not null */
            else {
              /** 3rd condition - if Active users count is greater than Subscribed User count */
              if (data.activeUserCount > data.subscribedUserCount) {
                /** if login user is super admin */
                if (data.result[0].is_super_admin == 1) {
                  this.employeeId = data.result[0].id;
                  this.router.navigate(['/Admin/add-renewal-users'])
                }
                /** if login user is employee */
                else {
                  this.router.navigate(['/Login']);
                  let dialogRef = this.dialog.open(ReusableDialogComponent, {
                    position: { top: `70px` },
                    disableClose: true,
                    data: 'Unable to Login. Please contact your Admin.'
                  });
                }
              } else {

                this.employeeId = empdata.id;
                if (empdata.firstlogin == "Y") {

                  this.router.navigate(['/ChangePassword'])
                }
                // else if (empdata.roles[0].role_name == "Product Admin") {
                //   this.router.navigate(['/Admin/product-admin-dashboard'])

                // }
                else if (data.result[0].is_super_admin == 1) {
                  this.router.navigate(['/main/client-superadmin-dashboard'])
                }
                else if (data.result[0].is_super_admin == 0) {
                  this.router.navigate(['/main/MainDashboard'])
                }

              }
            }
          }
        } else if
          (data.message == 'dbnotthere') {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Invalid company code or credentials. Please verify and retry.'
          });

        } else {
          this.router.navigate(['/Login']);
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Invalid company short code or credentials. Please enter valid company short code or credentials.'
          });
        }

      });

    }

  }

  ForgotPassword() {
    this.router.navigate(['ForgotPassword'], { state: { companyName: this.companyName } })
  }
  // getErrorMessages(errorCode:any){
  //   this.tss.getErrorMessages(errorCode,1,100).subscribe((result)=>{

  //     if(result.status && errorCode == 'LM1')
  //     {
  //       this.msgLM1 = result.data[0].errormessage
  //     }
  //     else if(result.status && errorCode == 'LM2')
  //     {
  //       this.msgLM2 = result.data[0].errormessage
  //     }
  //     else if(result.status && errorCode == 'LM14')
  //     {
  //       this.msgLM14 = result.data[0].errormessage
  //     }


  //   })
  // }
  getMessagesList() {

    this.tss.getErrorMessages(null, 1, 1000).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "LM1") {
            this.msgLM1 = e.errormessage
          } else if (e.code == "LM2") {
            this.msgLM2 = e.errormessage
          } else if (e.code == "LM14") {
            this.msgLM14 = e.errormessage
          }
        })
      } else {
        this.messagesDataList = [];
      }

    })
  }



  // getEmployeeEmailData() {
  //   this.emsService.getEmployeeEmailDataByEmpid(this.employeeId)
  //     .subscribe((res: any) => {
  //       // this.employeeEmailData = JSON.parse(res.data[0].jsonvalu)[0];
  //      })
  // }
  // getError(el:any) {
  //   switch (el) {
  //     case 'user':
  //       if (this.formGroup.get('username').hasError('required')) {
  //         return 'Username required';
  //       }
  //       break;
  //     case 'pass':
  //       if (this.formGroup.get('password').hasError('required')) {
  //         return 'Password required';
  //       }
  //       break;
  //     default:
  //       return '';
  //   }
  // }

  noWhiteSpaces(event: any) {
    let charcode = event.keyCode;
    let companyName = this.formGroup.controls.comapnyname.value;
    let userName = this.formGroup.controls.username.value;
    let password = this.formGroup.controls.password.value;
    if ((companyName.length === 0 || userName.length === 0 || password.length === 0) && charcode === 32) {
      return false;
    } else {
      return true;
    }
  }
}
