import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { ReportsService } from '../../reports.service';
@Component({
  selector: 'app-ot-reports-dialog',
  templateUrl: './ot-reports-dialog.component.html',
  styleUrls: ['./ot-reports-dialog.component.scss']
})
export class OtReportsDialogComponent implements OnInit {
  dataSource: any;
  empName:any;
  employeeId:any;
  displayedColumns = ['sno', 'date', 'ot'];
  List: any = [];
  headersList: any = [];
  constructor(public reportsService: ReportsService,
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
selectedDate:any;
  ngOnInit(): void {
    this.Searchform(this.data);
    this.empName = this.data.empname
    this.employeeId = this.data.employeeid
  }
  onOkClick(): void {
    this.dialogRef.close();
  }
  Searchform(datas:any) {
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);  
    let data = {
      "manager_empid":null,
      'employee': datas.empid,
      'fromdate': datas.fromdate,
      'todate': datas.toDate
    }
   this.dataSource = []
    this.reportsService.getAttendanceSummaryReport(data).subscribe((res: any) => {
      /** For remove OT data null or 00:00:00*/
      for(let i=0;i<res.data.length;i++){
        if(res.data[i].overtime_minutes != null && res.data[i].overtime_minutes != "00:00:00" ){
          this.dataSource .push(res.data[i]);
        }
      }


    })
  }  
}
