<div fxLayout="column" fxFill class="custom-pgr-height-auto">
    <mat-card fxFill>
        <mat-card-header class="headerbox">
            <div fxFlex="100"  fxLayout="row" fxLayoutAlign="space-between center">
                <span>
                    <span  (click)="Back()">
                        <button mat-icon-button title="Back"> <mat-icon>arrow_back</mat-icon></button>
                       </span>
                       <span class="title">Pay Slip</span>
                </span>
               <span  (click)="Download()">
                        <button mat-icon-button title="Download"> <mat-icon>download</mat-icon></button>
                       </span>
           
            </div>
            </mat-card-header>
          <div id="payslip" #payslip>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" class="custom-card div-pad">

            <div fxLayout="column" class="custom-payroll-div">
                <div fxLayout="row" fxFlex="100" fxLayout.xs="column" fxLayoutAlign="end">
                 
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                  <div fxFlex.xs="100" fxFlex="100" style="border-bottom: 1px solid #a0a0a0;">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" >
                      <div fxLayout="row">  <img [src]="pic" style="width: 40px;float:right;" alt="">
                        <span style="font-size: 17px;font-weight: 500;padding: 10px;">{{companyName}}</span>
                      </div>
                      <div fxLayout="column" style="padding-right: 2%;font-size: 16px;" >
                         <span>{{companylocation}}</span>
                         <span>{{companyCity}}</span>
                      </div>
                  </div>
                </div>
              </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" style="border-bottom: 1px solid #a0a0a0;">
                    <div fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" >
                        <h3 style="margin: 20px 0 0px;font-size: 17px;font-weight: 500;">Payslip for the month of {{payperiod}}</h3>
                        <h3 style="margin: 20px 0 10px;font-weight: 500;">Employee Pay Summary</h3>
                        <div class="form-row">
                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">Employee Name</label>
                            <p>{{empname}}</p>
                        </div>
                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">Designation</label>
                            <p>{{designation}}</p>
                        </div>
                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">Date of Joining</label>
                            <p>{{dateofjoin | date}}</p>
                        </div>
                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">Pay Period</label>
                            <p>{{payperiod}}</p>
                        </div>

                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">UAN Number</label>
                            <p>{{uanumber}}</p>
                        </div>
                        <div class="form-group col-md-12" style="padding-bottom: 0px !important;padding-top: 0px !important;display: flex;">
                            <label style="color:#a0a0a0;width:200px;">ESI Number</label>
                            <p>{{esi}}</p>
                        </div>

                    </div>
                    </div>
                   <div fxFlex.xs="100" fxFlex="50" class="mx-2 my-2">
                    <div class="card card-stats card-warning cmn-leave">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers text-center">
                                        <p style="color:#a0a0a0;">Net Pay</p>
                                        <p class="card-category" style="color: lightseagreen;font-size: 30px;"><img src="./assets/img/currency-green.png" width="30" height="28" style="margin-top: -4px;"> {{netamount}}</p>
                                        <p style="color:#a0a0a0;">Paid Days: {{paiddays}} | LOP Days: {{lop}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table>
                            <thead>
                              <tr class="dashboard-1 custom-investment-header">
                                <th scope="col" class="custom-investment-th" style="width:50%;">Leave Type</th>
                                <th scope="col" class="custom-investment-th" style="float:right;">Balance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of leavesdata;let i = index">
                                <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                                      <td class="custom-investment-td" style="float:right;">{{item.data_value| number:'1.2'}}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                   </div>
                </div>
                <div fxLayout="row" fxFlex="100" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <div fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" >
                        <table>
                            <thead>
                              <tr class="dashboard-1 custom-investment-header">
                                <th scope="col" class="custom-investment-th" style="width:50%;">Earnings</th>
                                <th scope="col" class="custom-investment-th" style="float:right;">Amount <img src="./assets/img/currency-white.png"></th>
                               </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of earningsdata;let i = index">
                                <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                                      <td class="custom-investment-td" style="float:right;">{{item.data_value| number:'1.2'}}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                   <div fxFlex.xs="100" fxFlex="50" class="mx-2 my-2">
                    <table>
                        <thead>
                          <tr class="dashboard-1 custom-investment-header">
                            <th scope="col" class="custom-investment-th" >Deductions</th>
                            <th scope="col" class="custom-investment-th" style="float:right;">Amount <img src="./assets/img/currency-white.png"></th>
                          </tr>

                        </thead>
                        <tbody>
                          <tr *ngFor="let item of deductiondata;let i = index">
                            <th scope="row" class="custom-investment-td">{{item.component_name}}</th>
                              <td class="custom-investment-td" style="float:right;">{{item.data_value | number:'1.2'}}</td>
                          </tr>
                        </tbody>
                    </table>
                   </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" style="background: rgb(233 247 230); padding: 10px 10px 0px 10px;margin-bottom: 1rem;">
                    <p style="text-align: center;margin-right: 5px !important;font-size: 16px;">Total Net Payable</p><p style="font-size: 16px;"> <img src="./assets/img/currency-block.png"> {{netamount}}&nbsp;</p><p style="font-size: 13px;margin-top: 3px !important;"> (Indian Rupee {{words}})</p>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" style="text-align: center;margin:auto;;">
                    <p  style="text-align: center;">** Total Net Payable = Gross Earnings - Total Deductions</p>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" style="text-align: center;margin:auto;">
                    <p style="text-align: center;">-- This is a system generated payslip, hence signature is not required --</p>
                </div>
            </div>
        </div>
        </div>
    </mat-card>
</div>
