<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title">Employee Tax Regime Selection</mat-card-title>
        </mat-card-header>

        <div fxLayout="column" class="dev-Pad">

            <div class="table-pad">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> S.No </th>
                            <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) + (paginator.pageIndex *
                                paginator.pageSize) }}</td>
                        </ng-container>

                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Code </th>
                            <td mat-cell *matCellDef="let row"> {{row.empcode}}</td>
                        </ng-container>

                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                        </ng-container>

                        <ng-container matColumnDef="regime">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Regime </th>
                            <td mat-cell *matCellDef="let row">
                                <!-- <mat-radio-group  >
                                <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of regimes;index as i;" (change)="status(halfs.id,row.regimeName)" [checked]="halfs.id == row.status"  [value]="halfs.id"  >{{halfs.regimeName}}</mat-radio-button>
                                 </mat-radio-group> -->
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1">Old Regime</mat-radio-button>
                                    <mat-radio-button value="2">New Regime</mat-radio-button>
                                </mat-radio-group>
                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                </div>

            </div>

        </div>
        <br>
        <div fxLayoutAlign="center">
            <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
                <span>Submit</span>
            </button>

            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                <span>Cancel</span>
            </button>
            <br />
        </div>
    </mat-card>
</div>