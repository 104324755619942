import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { EmsService } from '../../ems.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import { AdminService } from 'src/app/modules/admin/admin.service';
const moment =  _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-hr-resignation',
  templateUrl: './hr-resignation.component.html',
  styleUrls: ['./hr-resignation.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class HrResignationComponent implements OnInit {
  hrOnboardingForm: any = UntypedFormGroup;
  checklistForm: any = UntypedFormGroup;
  pendingchecklist: any = [];
  employeeChecklists: any = [];
  min: any = new Date();
  max: any = new Date();
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  istable: boolean = true;
  displayedColumns: string[] = ['sno', 'name', 'hiredate', 'joindate', 'status', 'action'];
  checklistdisplayedColumns: string[] = ['sno', 'dept', 'approver', 'checklist', 'status'];
  visibleList: any = ['Task Completed', 'Revoke new hire', 'Mark as not joining', 'Remove new hire'];
  checklistDataSource: MatTableDataSource<any> = <any>[];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  selection = new SelectionModel<any>(true, []);
  public selectedChecklists: any = [];
  pageLoading = true;
  messagesDataList: any;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, 
    private adminService: AdminService,private emsService: EmsService,private dialog: MatDialog) { }
  checked = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  pipe = new DatePipe('en-US');
  minDate = new Date('2000/01/01'); maxDate = new Date(Date.now() + (8.64e+7 * 90)).toISOString();
  employeeId: any;
  userSession: any;
  searchdate: any = null;
  isdisable:boolean=false;

  EX23:any;
  EX24:any;
  EX36:any;
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.checklistForm = this.formBuilder.group(
      {
        employeeName: [],
        requestDate: [],
        designation: [],
        remarks: [],
        isChecked: [],
      });
    this.hrOnboardingForm = this.formBuilder.group(
      {
        searchDate: [],
        statusUpdate: ["",],
        searchName: ["",],

      });
      this.hrOnboardingForm.get('searchDate')?.valueChanges.subscribe((selectedValue:any) => {
        this.searchdate = this.pipe.transform(selectedValue._d,'yyyy-MM-dd'),
        this.getPendingChecklist();
      })
    this.getPendingChecklist();
    this.dataSource.paginator = this.paginator;
    this.getMessagesList();
  }

  getPendingChecklist() {
    let data = {
      name: null,
      date: this.searchdate,
      eid: null,
      did: null //this.userSession.deptid
    }
    this.emsService.getEmployeResignationPendingChecklist(data).subscribe((res: any) => {
      if (res.status) {
        this.pendingchecklist = res.data;
        this.dataSource = new MatTableDataSource(this.pendingchecklist);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })
  }

  toggle(event: any, row: any) {
    let name = row.checklist_id;
    if (event.checked) {
      if (this.selectedChecklists.indexOf(name) === -1) {
        this.selectedChecklists.push(name);
        this.selection.select(row);
      }
      const selectedBox = this.selectedChecklists.length;
      const totalRows = this.checklistDataSource.data.length;
      if (selectedBox === totalRows) {
        this.isdisable = false;
      }

    } else {
      const index = this.selectedChecklists.indexOf(name);
      if (index > -1) {
        this.selectedChecklists.splice(index, 1);
        this.selection.deselect(row);
        this.isdisable = true;
        this.checked =false
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addChecklistOverview(data: any) {
    this.isAdd = true;
    this.isdata = false;
    this.checklistForm.controls.employeeName.setValue(data.empname)
    this.checklistForm.controls.requestDate.setValue(this.pipe.transform(data.requestdate,'dd-MM-yyyy'))
    this.checklistForm.controls.designation.setValue(data.designation)
    this.employeeId = data.empid;
    this.getEmployeCheckListData();
  }

  getEmployeCheckListData() {
    this.emsService.getEmployeeBoardingCheckList(this.employeeId, "Offboarding", null).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.employeeChecklists = res.data;
        for (let i = 0; i < res.data.length; i++){
          if (res.data[i].status != 'Completed') {
            this.isdisable = true;
          }else{
            this.selectedChecklists.push(res.data[i].checklist_id);
          }

        }
        this.checklistDataSource = new MatTableDataSource(this.employeeChecklists);
        this.checklistForm.controls.remarks.setValue(this.employeeChecklists[0].comment);
        this.checklistForm.controls.remarks.disable();
      }
    })
  }

  saveRequest() {
    if (this.selectedChecklists.length > 0) {
      let data = {
        cid: this.selectedChecklists,
        eid: this.employeeId,
        did: this.userSession.deptid,
        cmmt: this.checklistForm.controls.remarks.value,
        status: "Completed",
        fstatus: this.checked == true ? "Completed" : "Pending Checklist",
        category: "Offboarding",
        actionBy: this.userSession.id
      }
      this.emsService.separtionSetEmployeeChecklists(data).subscribe((res: any) => {
        if (res.status) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/ems/hr-resignation"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EX36
          });
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EX24
          });
        }

      })
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data:this.EX23
      });
    }
  }
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/ems/hr-resignation"]));
  }
  getPageSizes(): number[] {
     
  var customPageSizeArray = [];
  if (this.dataSource.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource.data.length);
  return customPageSizeArray;
  }
  change() {
    if (this.checked == true) {
      this.checklistForm.controls.remarks.enable();
    } else {
      this.checklistForm.controls.remarks.disable();
    }
  }

  getMessagesList() {
    let data =
      {
        "code": null,
        "pagenumber": 1,
        "pagesize": 1000
      }
      this.adminService.getExitMessagesList(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "EX23") {
            this.EX23 = e.message
          }else if (e.code == "EX24") {
            this.EX24 = e.message
          }else if(e.code =="EX36"){
            this.EX36 =e.message
          }
        })
      }
  })
  }
}
