import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalLimit]'
})
export class DecimalLimitDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove any non-digit characters except dot
    const cleanedValue = value.replace(/[^\d.]/g, '');

    // Split the value into integer and fractional parts
    const parts = cleanedValue.split('.');

    // Ensure that there is at most one dot and limit the fractional part to 2 digits
    if (parts.length > 2 || parts.some(part => part.length > 2)) {
      // If the input is not valid, reset the value
      this.ngControl.control?.setValue(null);
    } else {
      // Update the value in the form control
      this.ngControl.control?.setValue(cleanedValue);
    }
  }
}