<div fxLayout="column" class="first-col">
    <mat-card>
      <form [formGroup]="inductionForm" autocomplete="off">
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title class="title"> Invoice History </mat-card-title>
       </mat-card-header>
       <div class="div-pad">
  
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
              <span style="height: 70px;" fxFlex="50" fxLayoutAlign="end">
                <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                  <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                  <mat-label>Search</mat-label>
                  <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                </mat-form-field>
              </span>
            </div>
          <div fxLayout="column" class="table-pad">
            <div class="mat-elevation-z1 example-container ">
               <table mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S.No</th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                        {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                </ng-container>
    
                  <ng-container matColumnDef="companyname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                        Comany Name</th>
                    <td mat-cell *matCellDef="let row" > {{ row.company_name }}
                </td>
                  </ng-container>
    
                  <ng-container matColumnDef="billingdate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                        Billing Date</th>
                    <td mat-cell *matCellDef="let row" > {{ row.payment_date | date:'dd-MM-yyyy'}}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="useddate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                        Billing Period </th>
                    <td mat-cell *matCellDef="let row" > {{ row.valid_from | date:'dd-MM-yyyy'}} to {{row.valid_to | date:'dd-MM-yyyy'}} 
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="invoice">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                        Invoice</th>
                    <td mat-cell *matCellDef="let row" > {{ row.payment_reference_number }}
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                        Amount</th>
                    <td mat-cell *matCellDef="let row" > {{ row.amount_paid }}
                    </td>
                  </ng-container>
  
              
  
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header >
                      </th>
                    <td mat-cell *matCellDef="let row" ><mat-icon  class="mr-1"  title="view" (click)="view(row)">visibility</mat-icon>
                    </td>
                  </ng-container>
  
                  <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
           </div>
        </div>
      </div>
    
     </form>
    </mat-card>
    </div>
    