
import { Component, OnInit,LOCALE_ID,Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ViewChild,ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from 'src/app/modules/reports/excel-service.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { ClaimsService } from '../../claims.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-claims-employee-wise-detailed',
  templateUrl: './claims-employee-wise-detailed.component.html',
  styleUrls: ['./claims-employee-wise-detailed.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ClaimsEmployeeWiseDetailedComponent implements OnInit {
  
  List: any[] = [

  ];
  @ViewChild('table') table!: ElementRef;
  pipe:any;
  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
  maxDate = new Date();
  minToDate: any;
  pageLoading=true;
  searchForm:  any = FormGroup;
  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  companyName:any;
  is_super_admin:any = false;
  reimbursementTypes:any = [];
  //routeDistances:any = [];
  //travelModes:any =[];
  constructor(@Inject(LOCALE_ID) private locale: string,public reportsService: ReportsService, public datePipe: DatePipe, 
    public formBuilder: UntypedFormBuilder, private claims:ClaimsService,private spinner: NgxSpinnerService,
    private companyService: CompanySettingService, public dialog: MatDialog, private excelService: ExcelServiceService) { 
      this.companyName = sessionStorage.getItem("companyName");
    }
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata:any;
  year:any;
  //months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
   months:any = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns:string[]=['sno','emp_name','applied_on','rbs_type','bill_date','request_amount','approved_on','approved_amount','approved_by','claim_status'];
  isLoading = false;
  ngOnInit(): void { 
    this.ReimbursementTypes();
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      fromDate: [new Date()],
      toDate: [new Date()],
      Users: ['0'],
      rbsTypes:['0']
      });
    this.pipe = new DatePipe(this.locale);
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.is_super_admin =="1"){
      this.is_super_admin = true;
      this.getWorkLocation();
    }
    else {
      this.getEmployeelist();
    }
   this.searchForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
     if (selectedValue != null) {
       this.minToDate = selectedValue._d;    
     }
   })
  
 }
  
 ReimbursementTypes(){
  this.claims.getClaimsReimbursementTypes(1,100).subscribe((res: any) => {
    if (res.status && res.data) {
    this.reimbursementTypes = res.data;
    this.searchForm.controls.rbsTypes.setValue('0');
    }
  });
 }

 getEmployeelist() {
  this.reportsService.getTotalEmployeslistByManagerId({rm_id:this.userSession.id}).subscribe((res: any) => {
    if (res.status) {
    this.employeelist = [];
    this.employeelist = res.data;
    this.searchForm.controls.Users.setValue('0');
    }
  });
}


Searchform() {
  this.List = [];
  this.dataSource = new MatTableDataSource(this.List);
  let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
  let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-d");
  let userId = this.searchForm.controls.Users.value;
  let rbs_type_id = this.searchForm.controls.rbsTypes.value;
  if (userId == '0') {
    userId=null;
  }
  let data = {
     empid: userId,
     manager_id : this.userSession.id,
    rbs_type_id:rbs_type_id,
    from_date:fromDate,
    to_date:toDate
  }
  this.isLoading = true;
   this.spinner.show();
  this.claims.getClaimsEmployeeWiseDetailedReport(data).subscribe((res: any) => {
    this.spinner.hide();
    this.List=res.data;
    this.isLoading = false;
    this.dataSource = new MatTableDataSource(this.List);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageLoading=false;
  }, error => {
    this.isLoading = false;
    error.error.text
  });
}
resetform() {
  this.dataSource.data = [];
  this.searchForm.reset();
  this.searchForm.controls.fromDate.setValue(new Date());
  this.searchForm.controls.toDate.setValue(new Date());
  this.searchForm.controls.Users.setValue('0');
  this.searchForm.controls.rbsTypes.setValue('0');
  this.Searchform();



}
openDialog(item:any): void {
  const dialogRef = this.dialog.open(DialogDetailComponent, {
    width: '1000px',position:{top:`70px`},
    data: {attendanceid:item.attendanceid ,}
  });

  dialogRef.afterClosed().subscribe(() => {  });
}

exportAsXLSX() {
  let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
  //let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'employee activity Report');
  /* save to file */
  XLSX.writeFile(wb,fromdate+" - "+'employee-detailed-tracking-Report.xlsx');

}
getPageSizes(): number[] {
    
var customPageSizeArray = [];
if (this.dataSource.data.length > 5) {
  customPageSizeArray.push(5);
}
if (this.dataSource.data.length > 10) {
  customPageSizeArray.push(10);
}
if (this.dataSource.data.length > 20) {
  customPageSizeArray.push(20);
}
customPageSizeArray.push(this.dataSource.data.length);
return customPageSizeArray;
}


public exportPDF(): void {
  const pdfTable = this.table.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML);
  pdfMake.createPdf({
    info: {
      title:"Client related Associates Report",
      author:'Sreeb tech',
      subject:'Theme',
          keywords:'Report'
    },
    footer: function (currentPage:any, pageCount:any) {
      return {
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              }
            ],
            fonts: {
              Arial: {
                normal: 'Arial.ttf',
                bold: 'Arial-Bold.ttf',
                italics: 'Arial-Italic.ttf',
                bolditalics: 'Arial-BoldItalic.ttf'
              }
            },
            alignment: 'center'
          }
        ]
      };
    },
    content: [ 
      {
        text: "Client related Associates Report\n\n",
        style: 'header',
        alignment: 'center',
        fontSize: 14
      },  
      html,      
    ],
    pageOrientation: 'landscape'//'portrait'
  }).download("Client related Associates Report.pdf");
}

getFormattedDateTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

getFormattedDateWIthMonth(date:any){

  let val = date.split(' ')[0].split('-');
  return `${String(val[2]).padStart(2, '0')}-${String(this.months[Number(val[1])-1]).padStart(2, '0')}-${val[0] % 100}`;
 // let hours = String(currentDate.getHours()).padStart(2, '0');
 // let minutes = String(currentDate.getMinutes()).padStart(2, '0');
 // let seconds = String(currentDate.getSeconds()).padStart(2, '0');
  // Create a formatted string without timezone information
  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

getFormattedTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return  date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}
 
 
 



 
locationDeselectAll(select: MatSelect) {
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('')
}

onchangeManager(value:any) {
  this.selectedManagers=[];
    let mgr = this.searchForm.controls.managers.value;
    this.selectedManagers.push(Number(mgr.manager_emp_id));
    // mgr.forEach((e: any) => {
    //   this.selectedManagers.push(Number(e.manager_emp_id));
    // });
    this.getEmployeelistForSuperAdmin();
}

managerDeselectAll(select: MatSelect) {
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];
  select.value = [];
  this.searchForm.controls.managers.setValue('')
  this.selectedManagers=[];
}


managerSelectAll(select: MatSelect, values: any, array: any) {
  this.mgrIshide = true;
this.mgrIschecked = true;
select.value = values;
array = values;
this.searchForm.controls.managers.setValue(array)
let managerdata = this.searchForm.controls.managers.value;
managerdata.forEach((e: any) => {
 this.selectedManagers.push(Number(e.manager_emp_id));
});
this.getEmployeelistForSuperAdmin();
}

onchangeLocation(value:any) {
    this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    this.selectedLocations.push(location.id);
    // location.forEach((e: any) => {
    //   this.selectedLocations.push(e.id);
    // });
    this.getManagersList();
}
locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getManagersList() {
  this.managersDetails =[];
  let data={
    location_id :JSON.stringify(this.selectedLocations),
    companyName : this.companyName
  }
  this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
            this.managersDetails = res.data;
     }
  })
}
getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
    this.worklocationDetails = result.data;
  })
} 


getEmployeelistForSuperAdmin() {
    let obj={
    "rm_id":JSON.stringify(this.selectedManagers),
    "location_id" :JSON.stringify(this.selectedLocations),
    };
    this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
        if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.searchForm.controls.Users.setValue('0');
        }
    });
  }

applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}


  
  



