<div style="width: auto;">
    <div fxFlex="90" fxLayoutAlign="center">
        <h3 style="margin: 0px;padding-top: 2%;"> <b>List of Attendance:&nbsp;<span>{{date}}</span></b></h3>
    </div>
    <div fxLayout="row wrap" fxFlex="10" fxLayoutAlign="end">
        <span>
            <button mat-icon-button>
                <mat-icon class="close-icon" color="warn" matTooltip="Close"
                    [mat-dialog-close]="data.NO">cancel</mat-icon>
            </button>
        </span>
    </div>
</div>
<div fxLayout="row" *ngIf="employeesList.length > 0" style="margin-top: 5px">
    <div>
        <button mat-icon-button (keyup.enter)="downloadPDF()">
            <a title="PDF">
                <img (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
        </button>

    </div>
</div>
<div style="padding-top: 10px;">

    <div style="height: 400px">
        <div class="mat-elevation-z1">

            <table mat-table [dataSource]="dataSource" matSort #table>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S. No.</th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                        {{ (i+1) }} </td>
                </ng-container>

                <ng-container matColumnDef="shift">
                    <th mat-header-cell *matHeaderCellDef>Shift </th>
                    <td mat-cell *matCellDef="let element"> {{element.shiftname}} </td>
                </ng-container>
                <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>Emp. Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.empname}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.status == 'Present' " style="color:green"> {{element.status}}</span>
                        <span *ngIf="element.status != 'Present' " style="color:red"> {{element.status}}</span>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5">No data found</td>
                </tr>
            </table>
        </div><br>

    </div>
</div>