<div fxLayout="column" fxFill>
    <mat-card >
      <mat-card-header class="">
        <mat-card-title class="title custom-payRoll-header"> 
            <p style="margin-right: 10px;font-size: 17px;float: left;"><b>Professional Tax</b>
            </p>
            <p style="float: left;"><mat-icon (click)="onRequestClick()" style="cursor: pointer;">add_circle</mat-icon></p>
          </mat-card-title>
      </mat-card-header>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card">            
        <div fxLayout="column" fxFlex="5">
        </div>   
        <div fxLayout="column" fxFlex="90" class="custom-payroll-div">
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-salary-div">
                      
                <!-- S.No Components Column -->
                <ng-container matColumnDef="Sno">
                  <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> S.No </th>
                  <td mat-cell *matCellDef="let element"> {{element.Sno}} </td>
                </ng-container>

                  <!-- State Column -->
                  <ng-container matColumnDef="State">
                    <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> State </th>
                    <td mat-cell *matCellDef="let element"> {{element.State}} </td>
                  </ng-container>

                  <!-- PT Slabs Column -->
                  <ng-container matColumnDef="ptSlabs">
                      <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> PT Slabs </th>
                      <td mat-cell *matCellDef="let element"  class="custom-investment-td"> <mat-icon (click)="onViewClick(element)" style="cursor: pointer;" title="View" >visibility</mat-icon> </td>
                  </ng-container>
                  <!-- Action Column -->
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Action </th>
                    <td mat-cell *matCellDef="let element"  class="custom-investment-td"> <mat-icon (click)="onEditClick(element)" style="cursor: pointer;" title="Edit" >edit</mat-icon>  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="dashboard-1 custom-investment-header"></tr>
                <tr mat-row 
                *matRowDef="let row; columns: displayedColumns;" class="custom-mat-row"></tr>
         </mat-table>
            
              
        </div>
        <div fxLayout="column" fxFlex="5">
        </div>  
      </div>
    </mat-card>
</div>                    
