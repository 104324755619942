import { Component, OnInit,LOCALE_ID,Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ViewChild,ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from 'src/app/modules/reports/excel-service.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { CrmService } from '../../crm.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { from } from 'rxjs';
import { isNumber } from 'lodash';
import { DataTransferService } from  "../../data-transfer.service";
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { Router, RouterModule } from '@angular/router';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-crm-detailed-tracking-report',
  templateUrl: './crm-detailed-tracking-report.component.html',
  styleUrls: ['./crm-detailed-tracking-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmDetailedTrackingReportComponent implements OnInit {
  
  List: any[] = [

  ];
  @ViewChild('table') table!: ElementRef;
  pipe:any;
  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
  maxDate = new Date();
  minToDate: any;
  pageLoading=true;
  searchForm:  any = FormGroup;
  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  companyName:any;
  is_super_admin:any = false;
  employeeDayActivities: any = [];
  emp_attendance: any[] = []; // Define the emp_attendance property
  clientStatusCounts: any = {};
  activityStatusCounts: any = {};
  activityTypeCounts: any = {};
  showAttendanceDetails = false;
  showActivityDetails = false;
  showTrackingDetails = false;
  showFullTable = false;
  showActivitiesTable: boolean = false; // Controls table visibility
  selectedButton: string = ''; // Tracks the selected button
  route_type:any = null;
  clientStatusSelected: { [key: string]: boolean } = {};
  activityStatusSelected: { [key: string]: boolean } = {};
  activityTypeSelected: { [key: string]: boolean } = {};

  empAttendanceDataSource = new MatTableDataSource<any>([]);
  isSearchClicked: boolean = false; // New flag to control table visibility
  show_type:any='';
  encriptPipe= new EncryptPipe();
  //travelModes:any =[];
  constructor(@Inject(LOCALE_ID) private locale: string,public reportsService: ReportsService, public datePipe: DatePipe, private router: Router,
    public formBuilder: UntypedFormBuilder, private crm:CrmService,private spinner: NgxSpinnerService,private dataTransfer:DataTransferService,
    private companyService: CompanySettingService, public dialog: MatDialog, private excelService: ExcelServiceService) { 
      this.companyName = sessionStorage.getItem("companyName");
    }
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata:any;
  year:any;
  months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
  //searchForm = this.formBuilder.group({ fromDate: [new Date()], Users: ['0'] });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns:string[]=['sno','start_time','start_address','details','spent_time','travel_time','distance_km','end_time','end_address','route'];
  displayedColumns1: string[] = ['activity_type', 'client_name', 'client_contact', 'activity_status','client_status', 'next_follow_up_on', 'final_price', 'received_amount'];
  displayedColumns2: string[] = ['attendancedate', 'firstlogintime', 'lastlogouttime', 'workedhours', 'new_visits', 'new_clients', 'visits_total', 'km_travelled','act_total'];
  dataSource1 = new MatTableDataSource<any>([]);
  redirected:any = false;
  isLoading = false;
  transferData:any = null;
  ngOnInit(): void {
    this.redirected = false;
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      fromDate: [new Date()],
      Users: ['0']
      });
      
      let dataSubscription = this.dataTransfer.data$.subscribe(data => {
        if(data){
          if(data.is_super_admin){
            this.redirected = true;
            this.employeelist = data.user;
            this.searchForm.controls.Users.setValue(this.employeelist[0].empid);
            this.searchForm.controls.fromDate.setValue(data.date_selected);
            this.Searchform();
          }
          else this.transferData = data;
        }
        });
     
    this.pipe = new DatePipe(this.locale);
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.is_super_admin =="1"){
      this.is_super_admin = true;
      this.getWorkLocation();
    }
    else {
      this.getEmployeelist();
    }

   this.searchForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
     if (selectedValue != null) {
       this.minToDate = selectedValue._d;    
     }
   })
 }
 getEmployeelist() {
  this.reportsService.getTotalEmployeslistByManagerId({rm_id:this.userSession.id}).subscribe((res: any) => {
    if (res.status) {
    this.employeelist = [];
    this.employeelist = res.data;       
    if(this.transferData && this.transferData.user){       
      this.searchForm.controls.Users.setValue(this.transferData.user[0].empid.toString());
      this.searchForm.controls.fromDate.setValue(this.transferData.date_selected);
      this.Searchform();
    } else this.searchForm.controls.Users.setValue('0');
    }
  });
}


Searchform() {
  this.List = [];
  this.dataSource = new MatTableDataSource(this.List);
  let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
  //let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-d");
  let userId = this.searchForm.controls.Users.value;
  if (userId == '0') {
    userId=null;
  }
  let data = {
    empid:userId,
    //manager_id:this.userSession.id,
    date_selected: fromDate, 
    //to_date :toDate
  }
  this.isLoading = true;
  if(userId){
    this.getSelectedEmployeeDayActivities();
    this.spinner.show();
    this.crm.getCrmSelectedEmployeeRoutes(data).subscribe((res: any) => {
      this.spinner.hide();
      this.List=res.data;
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.List);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
    }, error => {
      this.isLoading = false;
      error.error.text
    });
  }
}



resetform() {
  this.redirected = false;
  this.dataSource.data = [];
  this.searchForm.reset();
  this.searchForm.controls.fromDate.setValue(new Date());
  //this.searchForm.controls.toDate.setValue(new Date());
  this.searchForm.controls.Users.setValue('0');
  this.Searchform();
  this.route_type = '';
  this.transferData = null;
  this.employeeDayActivities = [];
  this.emp_attendance = [];  
  this.clientStatusCounts = {};
  this.activityStatusCounts = {};
  this.activityTypeCounts = {};
  this.showAttendanceDetails = false;
  this.showActivityDetails = false;
  this.showTrackingDetails = false;
  this.showFullTable = false;
  this.showActivitiesTable = false;  
  this.selectedButton = '';  
  this.route_type = null;
  this.clientStatusSelected = {};
  this.activityStatusSelected = {};
  this.activityTypeSelected = {};

  this.empAttendanceDataSource.data = [];
  this.isSearchClicked = false; 
  this.show_type = '';
}

openDialog(item:any): void {
  const dialogRef = this.dialog.open(DialogDetailComponent, {
    width: '1000px',position:{top:`70px`},
    data: {attendanceid:item.attendanceid ,}
  });

  dialogRef.afterClosed().subscribe(() => {  });
}

exportAsXLSX() {
  let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
  //let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'employee activity Report');
  /* save to file */
  XLSX.writeFile(wb,fromdate+" - "+'employee-detailed-tracking-Report.xlsx');

}
getPageSizes(): number[] {
    
var customPageSizeArray = [];
if (this.dataSource.data.length > 5) {
  customPageSizeArray.push(5);
}
if (this.dataSource.data.length > 10) {
  customPageSizeArray.push(10);
}
if (this.dataSource.data.length > 20) {
  customPageSizeArray.push(20);
}
customPageSizeArray.push(this.dataSource.data.length);
return customPageSizeArray;
}


public exportPDF(): void {
  const pdfTable = this.table.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML);
  pdfMake.createPdf({
    info: {
      title:"Client related Associates Report",
      author:'Sreeb tech',
      subject:'Theme',
          keywords:'Report'
    },
    footer: function (currentPage:any, pageCount:any) {
      return {
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              }
            ],
            fonts: {
              Arial: {
                normal: 'Arial.ttf',
                bold: 'Arial-Bold.ttf',
                italics: 'Arial-Italic.ttf',
                bolditalics: 'Arial-BoldItalic.ttf'
              }
            },
            alignment: 'center'
          }
        ]
      };
    },
    content: [ 
      {
        text: "Client related Associates Report\n\n",
        style: 'header',
        alignment: 'center',
        fontSize: 14
      },  
      html,      
    ],
    pageOrientation: 'landscape'//'portrait'
  }).download("Client related Associates Report.pdf");
}


// public exportPDF(): void {
//  // let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
//   const pdfTable = this.table.nativeElement;
//   var html = htmlToPdfmake(pdfTable.innerHTML);
//   pdfMake.createPdf({
//     info: {
//       title:"employee detailed tracking Report",
//       author:'Sreeb tech',
//       subject:'Theme',
//           keywords:'Report'
//     },
//     footer: function (currentPage:any, pageCount:any) {
//       return {
//         margin: 10,
//         columns: [
//           {
//             fontSize: 9,
//             text: [
//               {
//                 text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
//               }
//             ],
//             fonts: {
//               Arial: {
//                 normal: 'Arial.ttf',
//                 bold: 'Arial-Bold.ttf',
//                 italics: 'Arial-Italic.ttf',
//                 bolditalics: 'Arial-BoldItalic.ttf'
//               }
//             },
//             alignment: 'center'
//           }
//         ]
//       };
//     },
//     content: [ 
//       {
//         text: "employee detailed tracking Report\n\n",
//         style: 'header',
//         alignment: 'center',
//         fontSize: 14
//       },  
//       html,      
//     ],
//     pageOrientation: 'landscape'//'portrait'
//   }).download("employee detailed tracking Report.pdf");
// }

getFormattedDateTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

getFormattedTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return  date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

convertToKms(distance:any){
  return { 
    val:distance && isNumber(distance) ? Number(distance/1000).toFixed(3) + ' km':'',
    key: distance?' km':''
  };
  // if(distance >= 1000){
  //   value.val = distance/1000;
  //   value.key = ' km';
  // }
  // if(!distance) 
  //   value.key ='';
  //  return value;
}

getFormattedTimeDifference(seconds :any = null,type:any = 'Travel'){
  if(seconds  && seconds .toString().length) {
    var hours = Math.floor(seconds / 60 / 60);
    var minutes = Math.floor(seconds / 60 % 60);
    var remainingSeconds = seconds % 60;
    if(type === 'Stay')
    return ( hours? hours+ 'hours ':'')+ (minutes?minutes + 'min ':'')  + (remainingSeconds?remainingSeconds + 'sec':'');
    else 
    return ( hours? hours+ 'hours ':'')+ (minutes?minutes + 'min ':'') + (remainingSeconds?remainingSeconds + 'sec':'');
   
  }
  else return '';
}

getAddress(r:any,type:any ){
  let address = '';
  if(type === 'start'){
    if(r.start_address && r.start_address.toString().length > 0){
      address = r.start_address;
    }
    else if(r.location && r.location.toString().length  > 0){
      address = r.location;
    }
  }
  if(type === 'end'){
    if(r.end_address && r.end_address.toString().length > 0){
      address = r.end_address;
    }
    else if(r.location && r.location.toString().length  > 0){
      address = r.location;
    }
  }
  return address;
}

changeDisplay(row:any,det:any){
    row.details?.forEach((d:any)=>{
      d.display = false;
      if(det.client_id){
        if(d.client_id && d.client_id === det.client_id  ){
          d.display = true;
        }
      }else{
        if(d.requestid && d.requestid === det.requestid  ){
          d.display = true;
        }
      }
    });
  }

 
locationDeselectAll(select: MatSelect) {
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('')
}

onchangeManager(value:any) {
  this.selectedManagers=[];
    let mgr = this.searchForm.controls.managers.value;
    this.selectedManagers.push(Number(mgr.manager_emp_id));
    // mgr.forEach((e: any) => {
    //   this.selectedManagers.push(Number(e.manager_emp_id));
    // });
    this.getEmployeelistForSuperAdmin();
}

managerDeselectAll(select: MatSelect) {
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];
  select.value = [];
  this.searchForm.controls.managers.setValue('')
  this.selectedManagers=[];
}


managerSelectAll(select: MatSelect, values: any, array: any) {
  this.mgrIshide = true;
this.mgrIschecked = true;
select.value = values;
array = values;
this.searchForm.controls.managers.setValue(array)
let managerdata = this.searchForm.controls.managers.value;
managerdata.forEach((e: any) => {
 this.selectedManagers.push(Number(e.manager_emp_id));
});
this.getEmployeelistForSuperAdmin();
}

onchangeLocation(value:any) {
    this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    this.selectedLocations.push(location.id);
    // location.forEach((e: any) => {
    //   this.selectedLocations.push(e.id);
    // });
    this.getManagersList();
}
locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getManagersList() {
  this.managersDetails =[];
  let data={
    location_id :JSON.stringify(this.selectedLocations),
    companyName : this.companyName
  }
  this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
            this.managersDetails = res.data;
     }
  })
}
getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
    this.worklocationDetails = result.data;
  })
} 


getEmployeelistForSuperAdmin() {
    let obj={
    "rm_id":JSON.stringify(this.selectedManagers),
    "location_id" :JSON.stringify(this.selectedLocations),
    };
    this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
        if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.searchForm.controls.Users.setValue('0');
        }
    });
  }

applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setSelectedEmployeeLocationRoutes(){
      let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "yyyy-MM-dd");
      let userId = this.searchForm.controls.Users.value;
      if(userId == '0' || userId == null){
        return;
      }    
      let data = {
        empid:userId,
        date_selected: fromDate, 
        re_calculate:0
      }
      this.crm.setCrmSelectedEmployeeLocationRoutes(data).subscribe((res: any) => {
        if (res.status) {
          this.Searchform();
        }
    });
  }

  redirectToGoogleMaps(lat: number, lng: number): void {
    let url = `https://maps.google.com/?q=${lat},${lng}`;
    window.open(url, '_blank');
  }

  displayRouteInGoogle(row:any){
    var url = `https://www.google.com/maps/dir/?api=1&origin=${Number(row.lat)},${Number(row.lng)}&destination=${Number(row.trace_end_lat)},${Number(row.trace_end_lng)}&travelmode=driving`;
    window.open(url, '_blank');
  }
   
  getSelectedEmployeeDayActivities() {
    this.employeeDayActivities = [];
    this.emp_attendance = []; // Clear previous attendance data
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
    let userId = this.searchForm.controls.Users.value;
    if (userId === '0') {
      userId = null;
    }

    let data = {
      empid: userId,
      date_selected: fromDate,
    };

    this.spinner.show();
    this.crm.getSprypleData(data, 'get_crm_selected_employee_day_activities').subscribe((res: any) => {
        this.spinner.hide();

        if (res.status) {
          let activitiesData = res.data[0].emp_activities; // Access the first element in the array and get emp_activities
          let attendanceData = res.data[0].emp_attendance; // Access attendance data if available

          if (activitiesData) {
            try {
              this.employeeDayActivities = JSON.parse(activitiesData)||[]; // Parse the JSON string
              this.dataSource1 = new MatTableDataSource(this.employeeDayActivities);
              this.dataSource1.paginator = this.paginator; // Set paginator if you want pagination
              this.dataSource1.sort = this.sort; // Set sort if you want sorting
              this.calculateCounts(); // Calculate the status counts
            } catch (e) {
              console.error('JSON parsing error:', e);
            }
          } else {
            this.employeeDayActivities = []; // Ensure the list is empty if no data is found
            this.dataSource1.data = this.employeeDayActivities; // Update data source
          }

          if (attendanceData) {
            try {
              this.emp_attendance = JSON.parse(attendanceData)|| []; // Parse the JSON string for attendance
              this.route_type = (this.emp_attendance && this.emp_attendance[0] && this.emp_attendance[0].route_type) ? this.emp_attendance[0].route_type:this.route_type;
              this.empAttendanceDataSource = new MatTableDataSource(this.emp_attendance); // Update the data source
            } catch (e) {
              console.error('JSON parsing error for attendance:', e);
            }
          } else {
            this.emp_attendance = [];
          }
        } else {
          console.error('API call failed');
        }
      },
      (error) => {
        console.error('API error:', error);
      }
    );
  }

  calculateCounts(): void {
    const clientStatusCounts: any = {};
    const activityStatusCounts: any = {};
    const activityTypeCounts: any = {};

    this.dataSource1.data.forEach((row: any) => {
      if (clientStatusCounts[row.client_status]) {
        clientStatusCounts[row.client_status]++;
      } else {
        clientStatusCounts[row.client_status] = 1;
      }

      if (activityStatusCounts[row.activity_status]) {
        activityStatusCounts[row.activity_status]++;
      } else {
        activityStatusCounts[row.activity_status] = 1;
      }

      if (activityTypeCounts[row.activity_type]) {
        activityTypeCounts[row.activity_type]++;
      } else {
        activityTypeCounts[row.activity_type] = 1;
      }
    });

    this.clientStatusCounts = clientStatusCounts;
    this.activityStatusCounts = activityStatusCounts;
    this.activityTypeCounts = activityTypeCounts;

    this.clientStatusSelected = {};
    this.activityStatusSelected = {};
    this.activityTypeSelected = {};
  }

  getTotalActivityCount(): number {
    return this.employeeDayActivities.length; // Total number of activities
  }

  getClientStatuses(): string[] {
    return Object.keys(this.clientStatusCounts);
  }

  getActivityStatuses(): string[] {
    return Object.keys(this.activityStatusCounts);
  }

  getActivityTypes(): string[] {
    return Object.keys(this.activityTypeCounts);
  }

  highlightRows(): void {
    const anyFilterActive = 
      Object.values(this.clientStatusSelected).some(Boolean) ||
      Object.values(this.activityStatusSelected).some(Boolean) ||
      Object.values(this.activityTypeSelected).some(Boolean);

    const filteredData = this.employeeDayActivities.filter((row: any) => {
      const clientStatusMatch = this.clientStatusSelected[row.client_status];
      const activityStatusMatch = this.activityStatusSelected[row.activity_status];
      const activityTypeMatch = this.activityTypeSelected[row.activity_type];

      if (anyFilterActive) {
        return clientStatusMatch || activityStatusMatch || activityTypeMatch;
      }

      return true;
    });

    this.dataSource1 = new MatTableDataSource(filteredData);
  }

  toggleClientStatus(status: string) {
    this.clearAllSelections();
    this.clientStatusSelected[status] = !this.clientStatusSelected[status];
    this.selectedButton = 'clientStatus';
    this.showActivitiesTable = true;
    this.highlightRows();
  }

  toggleActivityStatus(status: string) {
    this.clearAllSelections();
    this.activityStatusSelected[status] = !this.activityStatusSelected[status];
    this.selectedButton = 'activityStatus';
    this.showActivitiesTable = true;
    this.highlightRows();
  }

  toggleActivityType(type: string) {
    this.clearAllSelections();
    this.activityTypeSelected[type] = !this.activityTypeSelected[type];
    this.selectedButton = 'activityType';
    this.showActivitiesTable = true;
    this.highlightRows();
  }

  showAllActivities(type:any) {
    this.clearAllSelections();
    this.show_type = type;
    this.selectedButton = 'totalActivities'; // Ensure the correct button is marked as selected
    this.dataSource1 = new MatTableDataSource(this.employeeDayActivities);
  }
  showActivities(){
    this.clearAllSelections();
    this.showActivitiesTable = !this.showActivitiesTable;
    this.selectedButton = 'totalActivities'; // Ensure the correct button is marked as selected
    this.dataSource1 = new MatTableDataSource(this.employeeDayActivities);
  }

  clearAllSelections() {
    Object.keys(this.clientStatusSelected).forEach((key) => (this.clientStatusSelected[key] = false));
    Object.keys(this.activityStatusSelected).forEach((key) => (this.activityStatusSelected[key] = false));
    Object.keys(this.activityTypeSelected).forEach((key) => (this.activityTypeSelected[key] = false));
  }

  toggleAttendanceDetails() {
    this.showAttendanceDetails = !this.showAttendanceDetails;
  }

  toggleActivityDetails() {
    this.showActivityDetails = !this.showActivityDetails;
  }

  toggleTrackingDetails() {
    this.showTrackingDetails = !this.showTrackingDetails;
  }

  transform(value: string){
    return value.toLowerCase() // Convert to lowercase to ensure consistent capitalization
      .split('_')    // Split by underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); 
  }

  selectClient(event:any,row:any){
    let clientId=this.encriptPipe.transform(row.client_id.toString());
    this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}])
  }

}


  
  


