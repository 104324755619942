import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from 'src/app/pages/main/main.component';
import { ClaimsReimbursementTypesComponent } from './pages/claims-reimbursement-types/claims-reimbursement-types.component';
import { ClaimsUserRequestComponent } from './pages/claims-user-request/claims-user-request.component';
import { ClaimsApprovalsComponent } from './pages/claims-approvals/claims-approvals.component';
import { ClaimsEmployeeWiseDetailedComponent } from './pages/claims-employee-wise-detailed/claims-employee-wise-detailed.component';
import { ClaimsDetailedReportComponent } from './pages/claims-detailed-report/claims-detailed-report.component';

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      { path: 'ClaimsReimbursementTypes', component: ClaimsReimbursementTypesComponent},
      { path: 'UserClaimRequest', component: ClaimsUserRequestComponent},
      { path: 'ClaimsApprovals', component: ClaimsApprovalsComponent},
      { path: 'ClaimsEmployeeWiseDetailedReport', component: ClaimsEmployeeWiseDetailedComponent},
      { path: 'ClaimsDetailedReport', component: ClaimsDetailedReportComponent},
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimsRoutingModule { }
