<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Subscription Master</mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isAddBtn">
                <button mat-icon-button style="padding-right: 40px;" (click)="addNew()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <form [formGroup]="subscriptionForm" autocomplete="off">
            <div fxFlex="column">

                <div class="dev-Pad" *ngIf="isData">
                    <div class="row no-gutters" fxLayout.xs="column" fxLayoutAlign.xs="center">

                        <div class="col-lg-4 col-md-6">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Plan Name</mat-label>
                            <mat-select formControlName="planName" required>
                                <ng-container>
                                    <div *ngFor="let plan of plansdata">
                                        <mat-option [value]="plan.id">{{ plan.plan_name }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <!-- <input formControlName="planName" matInput required maxlength="50"
                               onlyAlphabets> -->
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.planName.errors?.required">
                                Please select an option
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                       <div class="col-lg-4 col-md-6">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Cost per User-Monthly</mat-label>
                            <input formControlName="monthlyCost" matInput required minlength="3" maxlength="5"
                                numbersOnly (keypress)="stopLeadingZero($event)">
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.monthlyCost.errors?.required">
                                This field is required
                            </mat-error>
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.monthlyCost.errors?.minlength || 
                    subscriptionForm.controls.monthlyCost.errors?.maxlength">
                                Please enter valid data
                            </mat-error>
                        </mat-form-field>
                       </div>
                        <div class="col-lg-4 col-md-6">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Cost per User-Annually</mat-label>
                            <input formControlName="yearlyCost" matInput required minlength="3" maxlength="5"
                                numbersOnly (keypress)="stopLeadingZero($event)">
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.yearlyCost.errors?.required">
                                This field is required
                            </mat-error>
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.yearlyCost.errors?.minlength || 
                    subscriptionForm.controls.yearlyCost.errors?.maxlength">
                                Please enter valid data
                            </mat-error>
                        </mat-form-field>
                    </div>

                        <div class="col-lg-4 col-md-6">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Minimum Users</mat-label>
                            <input formControlName="minUsers" numbersOnly [readonly]="true" matInput required minlength="1" maxlength="3"
                                (keypress)="stopLeadingZero($event)">
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.minUsers.errors?.required">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                       </div>

                        <div class="col-lg-4 col-md-6">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Maximum Users</mat-label>
                            <input formControlName="maxUsers" matInput  numbersOnly maxlength="4">
                        </mat-form-field>
                    </div>

                </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">


                    </div>

                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary mr-2 mb-2" (click)="saved()" *ngIf="isadd">
                            Submit
                        </button>
                        <button class="btn btn-primary mr-2 mb-2" *ngIf="editing" (click)="editsaved()">
                            Save
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            Cancel
                        </button>
                    </div>

                    <div *ngIf="existingPlanDetailsByList.length > 0" class="example-container mat-elevation-z1">
                        <table mat-table [dataSource]="dataSource2" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S.No</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator2.pageIndex * paginator2.pageSize) }} </td>
                            </ng-container>
                            <ng-container matColumnDef="lower">
                                <th mat-header-cell *matHeaderCellDef>
                                   Lower Range
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.user_count_lower_range}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="upper">
                                <th mat-header-cell *matHeaderCellDef>Upper Range</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.user_count_upper_range }} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="monthlyRate">
                                <th mat-header-cell *matHeaderCellDef>Cost per User-Monthly</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.cost_per_user_monthly_bill }} </span>
                                </td>
                            </ng-container>

                           
                            <ng-container matColumnDef="yearlyRate">
                                <th mat-header-cell *matHeaderCellDef>Cost per User-Monthly</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.cost_per_user_yearly_bill }} </span>
                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        
                    </div>

                </div>
                <div *ngIf="!isData" fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                    <span style="height: 70px;" fxFlex="50" fxLayoutAlign="end">
                      <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                      </mat-form-field>
                    </span>
                  </div>
                <div class="table-pad" *ngIf="!isData">
                    <div class="example-container mat-elevation-z1">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S.No</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>
                            <ng-container matColumnDef="plan">
                                <th mat-header-cell *matHeaderCellDef>
                                    Plan Name
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.plan_name}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="monthUser">
                                <th mat-header-cell *matHeaderCellDef>Cost per User-Monthly</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.cost_per_user_monthly_bill }} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="yearUser">
                                <th mat-header-cell *matHeaderCellDef>Cost per User-Annually</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.cost_per_user_yearly_bill }} </span>
                                </td>
                            </ng-container>

                           
                            <ng-container matColumnDef="min-user">
                                <th mat-header-cell *matHeaderCellDef>Min.Users</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.user_count_lower_range }} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="max-user">
                                <th mat-header-cell *matHeaderCellDef>Max.Users</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.user_count_upper_range }} </span>
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.status }} </span>
                                </td>
                            </ng-container> -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                    <!-- <mat-icon (click)="edit($event, row)" *ngIf="row.status == 'Submitted'"
                                        title="Edit">edit</mat-icon>
                                    <mat-icon (click)="view($event, row)" title="view">visibility </mat-icon> -->
                                </td>
                            </ng-container>
                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="8" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        
                    </div>
                </div>

            </div>
        </form>
    </mat-card>
</div>