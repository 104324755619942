<div>
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Add Course</mat-card-title>
            <div fxLayout="row">
                <button mat-icon-button matTooltip="Add New Course">
                    <mat-icon style="font-size: 30px;overflow: visible;" *ngIf="addNewChapter"
                        (click)="addNew()">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <div style="margin:10px" *ngIf="!addNewChapter">
            <form [formGroup]="addChapter">
                <div>
                    <mat-form-field fxFlex="30" fxFlex.xs="100" appearance="outline" style="margin-right: 20px;">
                        <mat-label>Course</mat-label>
                        <mat-select formControlName="courseName">
                            <mat-option *ngFor="let b of courseNames">{{b}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex=30 appearance="outline">
                        <mat-label>Chapter</mat-label>
                        <input matInput formControlName="chapter" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex="30" fxFlex.xs="100" appearance="outline">
                        <mat-label>File Type</mat-label>
                        <mat-select formControlName="fileName">
                            <mat-option *ngFor="let b of types">{{b}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" style="align-items: center;">
                    <div>
                        <input style="margin-left: 0px" type="file" formControlName="document" single />
                        &nbsp;
                    </div>
                </div>

                <div fxLayoutAlign="center" style="margin-top: 30px;">
                    <button class="btn btn-primary mr-2 mb-2" type="submit" (click)=" onSubmit()">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </form>
        </div>

        <div style="margin:10px" *ngIf="addNewChapter">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.sno}}</td>
                </ng-container>
                <ng-container matColumnDef="courseName">
                    <th mat-header-cell *matHeaderCellDef>Course</th>
                    <td mat-cell *matCellDef="let element"> {{element.courseName}}</td>
                </ng-container>
                <ng-container matColumnDef="chapter">
                    <th mat-header-cell *matHeaderCellDef>Chapter</th>
                    <td mat-cell *matCellDef="let element"> {{element.chapter}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>