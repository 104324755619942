import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReportsService } from '../../reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import * as XLSX from 'xlsx';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { CheckinOutSummaryReportDialogComponent } from '../checkin-out-summary-report-dialog/checkin-out-summary-report-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';


const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-checkin-out-summary-report',
  templateUrl: './checkin-out-summary-report.component.html',
  styleUrls: ['./checkin-out-summary-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CheckinOutSummaryReportComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;

  searchForm: any = UntypedFormGroup;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  managersDetails: any[] = [];
  companyName: any;
  filteredManagers: any = [];
  worklocationDetails: any[] = [];
  searchControl = new FormControl('');
  searchControlforLoc = new FormControl('');
  searchControlforRM = new FormControl('');
  filteredLocations: any = [];
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedManagers: any = [];
  employeelist: any;
  filteredEmployees: any = [];
  cdate = new UntypedFormControl(moment());
  List: any = [];
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  userSession: any;
  empId: any;
  isLoading = false;
  dataSource: MatTableDataSource<any> = <any>[];

  result: any = [
    {
      result: [
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',

      ],
    },
    {
      result: [
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    },
    {
      result: [
        1,
        'Mahesh  Ch',
        ['P', '09:00', '18:00',],
        ['P', '09:00', '18:00',],
        'W',
        'W',
        ['P', '09:00', '18:00',],
        ['P', '09:00', '18:00',],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        'W',
        'W',
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        'H',
        ['P', '09:00', '18:00'],
        'W',
        'W',
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        'W',
        'W',
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        ['P', '09:00', '18:00'],
        'W',
      ],
    },
  ];

  headersList: any = [];

  constructor(
    public reportsService: ReportsService,
    private companyService: CompanySettingService,
    public datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName');

    this.searchForm = this.formBuilder.group({
      companylocation: ['', Validators.required],
      managers: ['', Validators.required],
      fromDate: [new Date()],
      toDate: [new Date()],
      employees: ['0'],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getWorkLocation();
    // console.table(this.result);
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.companylocation.setValue(array);
    let locations = this.searchForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }
  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName,
    };
    this.reportsService
      .getManagersListByLocation(data)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.managersDetails = res.data;
          this.filteredManagers = this.managersDetails;
        }
      });
  }
  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false;
    this.selectedLocations = [];
    select.value = [];
    this.searchForm.controls.companylocation.setValue('');
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }
  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.managers.setValue(array);
    let managerdata = this.searchForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelist();
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false;
    this.selectedManagers = [];
    select.value = [];
    this.searchForm.controls.managers.setValue('');
    this.selectedManagers = [];
  }

  getWorkLocation() {
    this.managersDetails = [];
    this.companyService
      .getactiveWorkLocation({ id: null, companyName: this.companyName })
      .subscribe((result) => {
        this.worklocationDetails = result.data;
        this.filteredLocations = this.worklocationDetails;
      });
  }
  getEmployeelist() {
    let obj = {
      rm_id: JSON.stringify(this.selectedManagers),
      location_id: JSON.stringify(this.selectedLocations),
    };
    this.reportsService
      .getLocationWiseEmployeesForManagers(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployees = this.employeelist;
          this.searchForm.controls.employees.setValue('0');
        }
      });
  }
  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.searchForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelist();
  }
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.cdate.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.searchForm.controls.fromDate.setValue(ctrlValue);
    datepicker.close();
  }

  public exportPDF(): void {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: date + '-' + 'Attendance Monthly Detailed Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: date + ' - ' + 'Attendance Monthly Detailed Report \n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          html,
        ],
        styles: {
          'html-table': {
            background: 'white', // it will add a yellow background to all <STRONG> elements
          },
        },
        pageOrientation: 'landscape',
        pageSize: 'A3',
        //'portrait'
      })
      .download('Attendance Monthly Detailed Report.pdf');
  }

  exportAsXLSX() {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Monthly_Detail_Report');
    XLSX.writeFile(wb, date + '-' + 'Monthly_Detail_Report_for_manager.xlsx');
  }

  resetform() {
    this.searchForm.reset();
  }
  getColor(i: string): String {
    let color = '';
    if (i == 'P') {
      return (color = 'green');
    } else if (i == 'H') {
      return (color = '#800000');
    } else if (i == 'W') {
      return (color = 'blue');
    } else if (i == 'L') {
      return (color = 'orange');
    } else if (i == 'HD') {
      return (color = '#ce06e4');
    } else if (i == 'WP') {
      return (color = '#06c3e4');
    } else if (i == 'HP') {
      return (color = '#06e471');
    } else {
      return (color = 'red');
    }
  }

  Searchform() {
    let i = 0;
    this.result.forEach((e: any) => {
      if (i < 2) {
        let header = e.result;
        this.headersList.push(header);
      } else {
        let header = e.result;
        this.List.push(header);
      }
      i++;
    });

    // if (this.searchForm.valid) {
    //   let data = {

    //     manager_id: this.searchForm.controls.managers.value,
    //     employee_id: this.searchForm.controls.employees.value,
    //     calendar_date: this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-dd")

    //   }
    //   this.spinner.show();
    //   this.isLoading = true;
    //   this.reportsService.getAttendanceDetailedMonthlyReport(data).subscribe((res: any) => {
    //     this.headersList = [];
    //     this.List = [];
    //     if (res.status) {
    //       let i = 0;
    //       res.data.forEach((e: any) => {
    //         if (i < 2) {
    //           let header = JSON.parse(e.result);
    //           this.headersList.push(header);
    //         } else {
    //           let header = JSON.parse(e.result);
    //           this.List.push(header);
    //         }
    //         i++;

    //       });
    //     }

    //     this.isLoading = false;
    //     this.dataSource = new MatTableDataSource(this.List);
    //     this.spinner.hide();
    //   }, error => {
    //     this.isLoading = false;
    //     error.error.text;
    //     this.spinner.hide();
    //   });
    // }
  }

  isArray(item: any): boolean {
    return Array.isArray(item);
  }
  openDialog(item: any) {
    const dialogRef = this.dialog.open(CheckinOutSummaryReportDialogComponent, {
      width: '1000px', position: { top: `70px` },
      data: { 'employeeId': this.empId, }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}