import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { PopupComponent, PopupConfig } from '../../../../pages/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { startTransition } from 'preact/compat';
import { environment } from 'src/environments/environment';
import { EmsService } from 'src/app/modules/ems/ems.service';
export interface UserData {
  deptname: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-deparment',
  templateUrl: './deparment.component.html',
  styleUrls: ['./deparment.component.scss']
})
export class DeparmentComponent implements OnInit {
  departmentForm!: UntypedFormGroup;
  department: any;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  valid: boolean = false;
  msgEM1:any;
  msgEM84:any;
  msgEM85:any;
  msgEM88:any;
  msgEM89:any;
  msgEM90:any
  msgEM91:any;
  msgEM92:any;
  msgEM151:any;
  msgEM152:any;
  todayDate:any=new Date();
  displayedColumns: string[] = ['sno','deptname', 'status', 'Action'];
  departmentData: any = [];
  arrayValue: any;
  dataSource: MatTableDataSource<UserData>;
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)  sort!: MatSort;
  companyDBName:any = environment.dbName;
    messagesDataList: any = [];
  constructor(private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog, private LM: CompanySettingService,private ts:LoginService,
    private emsService:EmsService) {
      this.getDepartments();
      this.dataSource = new MatTableDataSource(this.departmentData);
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getstatuslist();
    this.getMessages();
    this.departmentForm = this.formBuilder.group(
      {
        department: ["",[Validators.required]],

      },
    );
  }
  validatedepartments(data: any) {
    if (this.departmentData.length ==0) {
      this.valid = true;
    }
    else {
      if (this.departmentData.length > 0) {

        for (let i = 0; i < this.departmentData.length; i++) {
          if (data.replace(/\s{1,}/g, '').trim().toLowerCase() === this.departmentData[i].deptname.replace(/\s{1,}/g, '').trim().toLowerCase() ) {
            this.valid = false;
            break;
          }
          else {
            this.valid = true;
          }
        }
      }
    }

  }
  validateupdatedepartments(id:any,data: any) {
    if (this.departmentData.length == 0) {
      this.valid = true;
    }
    else {
      if (this.departmentData.length > 0) {
        for (let i = 0; i < this.departmentData.length; i++) {
          if (id !=this.departmentData[i].id && data.replace(/\s{1,}/g, '').trim().toLowerCase() === this.departmentData[i].deptname.replace(/\s{1,}/g, '').trim().toLowerCase()) {
            this.valid = false;
            break;
          }
          else {
            this.valid = true;
          }
        }
      }
    }
 }
  setdepartment() {
    this.validatedepartments(this.departmentForm.controls.department.value)
    this.department = this.departmentForm.controls.department.value;
    var data = {
      departmentName: this.department,
      created_by:this.userSession.id,
      created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd')+' '+this.pipe.transform(new Date(), 'HH:mm:ss'),
      companyDBName:this.companyDBName
    }
    if (this.departmentForm.valid) {
      if (this.valid) {
        this.LM.setDepartments(data).subscribe((data) => {
          this.valid = false;
         if (data.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/Department"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.msgEM88
            });


          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.msgEM84
            });
          }
        })
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.msgEM92
        });

      }

    }

  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    this.departmentForm.controls.department.setValue('')
  }
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Admin/Department"]));
    // this.enable = null;
    // this.departmentForm.reset();
    // this.isAdd = false;
    // this.isdata = true;
    // this.getDepartments();

  }
  status(status: any, id: any, deptname: any) {
    let data = {
      deptname: deptname,
      tableName: 'employee_departments',
      columnName: 'departmentid',
      id: id,
      status: status
    }
    this.LM.updateStatus(data).subscribe((result) => {
      if (result.status) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: status == 1 ? this.msgEM151:this.msgEM152
        });
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(["/Admin/Department"]));
      } else {
        this.ngOnInit();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.msgEM89
        });
      }
    })
  }
  edit(w: any, i: any) {
    this.departmentForm.controls.department.setValue(i.deptname);
    this.enable = i.id;
    this.isEdit = false;
    this.isSave = true;
    // VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);

  }
  save(event: any, id: any, deptname: any, datas: any) {
    
    if (this.departmentForm.valid) {
      // this.validatedepartments(deptname)
      this.validateupdatedepartments(id,deptname)
      this.enable = null;
      this.isEdit = true;
      this.isSave = false;
      if (this.valid) {
        let data={
          id:id,
          name:deptname,
          created_by: datas.created_by,
          created_on:datas.created_on,
          status:datas.status,
          updated_by:this.userSession.id,
          updated_on:this.pipe.transform(new Date(), 'yyyy-MM-dd')+' '+this.pipe.transform(new Date(), 'HH:mm:ss'),
        }
        this.LM.putDepartments(data).subscribe((data) => {
          if (data.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Admin/Department"]));
            // this.enable = null;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.msgEM91
            });
            this.getDepartments();
  
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.msgEM85
            });
          }
        })
      }
      else {
        this.ngOnInit();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.msgEM92
        });
  
      }
    }
  }
  canceledit(event: any, id: any) {
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.ngOnInit();

  }
  getDepartments() {
    this.LM.getDepartments('departmentsmaster', null, 1, 1000, this.companyDBName).subscribe((info:any) => {
      if (info.status && info.data.length != 0) {
      this.departmentData = info.data;
      this.dataSource = new MatTableDataSource(this.departmentData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
      }

    })

  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
     
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getstatuslist(){
    this.LM.getstatuslists().subscribe((result:any) => {
      if(result.status){
        this.arrayValue = result.data;
      }
    })
  }
  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.emsService.getMessagesListApi(data).subscribe((result: any) => {
      if(result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {
          
         if (e.code == "EM1") {
          this.msgEM1 = e.message
         } 
         else if (e.code == "EM84") {
           this.msgEM84 = e.message;
          }
          else if (e.code == "EM85") {
           this.msgEM85 =e.message
         }
         else if (e.code == "EM88") {
           this.msgEM88 =e.message
         } 
         else if (e.code == "EM89") {
          this.msgEM89 =e.message
        } 
        else if (e.code == "EM90") {
          this.msgEM90 =e.message
        }
        else if (e.code == "EM91") {
          this.msgEM91 =e.message
        }

        else if (e.code == "EM92") {
          this.msgEM92 =e.message
        }

        else if (e.code == "EM151") {
          this.msgEM151 =e.message
        }

        else if (e.code == "EM152") {
          this.msgEM152 =e.message
        }

          })
      } else {
        this.messagesDataList = [];
      }
    })
  }

    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
  
    }

}
