import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AttendanceService } from '../../attendance.service';
import { UserData } from '../../models/EmployeeData';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-regularaization-approval',
  templateUrl: './regularaization-approval.component.html',
  styleUrls: ['./regularaization-approval.component.scss']
})
export class RegularaizationApprovalComponent implements OnInit {

  regularizationApprovalForm: any = UntypedFormGroup;
  displayedColumns: string[] = ['select', 'sno', 'empoyeeid', 'empname', 'shift', 'intime', 'outtime', 'action'];
  dataSource: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  arrayList: UserData[] = [];
  userSession: any;
  titleName: string = '';
  reason: any;
  requestData: any;
  iseditouttime: boolean = false;
  enable: any = null;
  constructor(private router: Router, public dialog: MatDialog,
    private attendanceService: AttendanceService, private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,) {

  }
  selection = new SelectionModel<any>(true, []);
  selectedEmps: any = [];
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getPendingAttendanceRegularizationsForManager();
    this.regularizationApprovalForm = this.formBuilder.group({
      intime: [''],
      outtime: ['']
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  changeTab(elment: UserData) {
    this.router.navigate(["/Attendance/Approval"], { state: { userData: elment, url: 'ApprovedHistory' } });
  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  edit(data: any) {
    this.enable = data.id;
  }

  canceledit() {
    this.enable = null;
    this.iseditouttime = false;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/regularizationApproval"]));

  }

  saveRegularization(data: any) {
    let IHH = Number(this.regularizationApprovalForm.controls.intime.value[0] + this.regularizationApprovalForm.controls.intime.value[1])
    let IMM = Number(this.regularizationApprovalForm.controls.intime.value[2] + this.regularizationApprovalForm.controls.intime.value[3])
    let ISS = Number(this.regularizationApprovalForm.controls.intime.value[4] + this.regularizationApprovalForm.controls.intime.value[5])
    let OHH = Number(this.regularizationApprovalForm.controls.outtime.value[0] + this.regularizationApprovalForm.controls.outtime.value[1])
    let OMM = Number(this.regularizationApprovalForm.controls.outtime.value[2] + this.regularizationApprovalForm.controls.outtime.value[3])
    let OSS = Number(this.regularizationApprovalForm.controls.outtime.value[4] + this.regularizationApprovalForm.controls.outtime.value[5])
    let setdata = {
      empid: data.empid,
      shiftid: data.shiftid,
      fromdate: data.fromdate,
      todate: data.fromdate,
      logintime: data.logintime,
      logouttime: OHH + ':' + OMM + ':' + OSS,
      worktype: 1,
      raisedby: this.userSession.id,
      approvercomments: '',
      actionby: this.userSession.id,
      status: 'Approved',
    }
    this.attendanceService.setAttendanceRegularization(setdata).subscribe((result: any) => {
      if (result.status) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Attendance/regularizationApproval"]));
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Attendance Regularization Approved successfully"
        });

      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to Approve Attendance Regularization"
        });

      }
    })

  }


  getPendingAttendanceRegularizationsForManager() {
    let data = {
      empid: this.userSession.id
    }
    this.spinner.show();
    this.attendanceService.getPendingAttendanceRegularizationsForManager(data).subscribe((result: any) => {
      if (result.status) {
        this.dataSource = new MatTableDataSource(result.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        this.spinner.hide();
      }
    })

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  approveRegularization() {

    if (this.selection.selected.length > 0) {
      this.selectedEmps = [];
      this.selection.selected.forEach((e: any) => {
        let setdata = {
          emp_id: e.empid,
          shiftid: e.shiftid,
          fromdate: e.fromdate,
          todate: e.fromdate,
          logintime: e.shift_start_time,
          logouttime: e.shift_end_time,
          worktype: e.worktype_id,
          halfday:  e.halfdayattendance == null ? 0 : e.halfdayattendance,
          raisedby: e.empid,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Approved',
          locationid: e.locationid,
        }
        this.selectedEmps.push(setdata)
      });

      let data = {
        input_data: this.selectedEmps,
      }
      this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
        if (result.status && result.data[0].validity_status == 1) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Attendance/regularizationApproval"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Attendance Regularization Approved successfully"
          });
          this.selection.clear();
        } else if(result.status && result.data[0].validity_status== 0){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist',
          });
          this.selection.clear();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to Approve Attendance Regularization"
          });
          this.selection.clear();
        }

      })
    } else {
      this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Select atleast one employee.',
      });
      return;
    }
  }

  rejectRegularization() {

    if (this.selection.selected.length > 0) {
      this.selectedEmps = [];
      this.selection.selected.forEach((e: any) => {
        let setdata = {
          emp_id: e.empid,
          shiftid: e.shiftid,
          fromdate: e.fromdate,
          todate: e.fromdate,
          logintime: e.shift_start_time,
          logouttime: e.shift_end_time,
          worktype: e.worktype_id,
          halfday: e.halfdayattendance == null ? 0 : e.halfdayattendance,
          raisedby: e.empid,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Rejected',
          locationid: e.locationid,
        }
        this.selectedEmps.push(setdata)
      });

      let data = {
        input_data: this.selectedEmps,

      }
      this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
        if (result.status && result.data[0].validity_status == 1) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Attendance/regularizationApproval"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Attendance Regularization Rejected successfully"
          });
          this.selection.clear();

        } else if(result.status && result.data[0].validity_status== 0){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist',
          });
          this.selection.clear();

        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to Reject Attendance Regularization"
          });
          this.selection.clear();
        }
      })

    } else {
      this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Select atleast one employee.',
      });
      return;
    }
  }

}

