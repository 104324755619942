
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { AttendanceService } from '../../attendance.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-approval-attendance',
  templateUrl: './approval-attendance.component.html',
  styleUrls: ['./approval-attendance.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class ApprovalAttendanceComponent implements OnInit {
  requestform: any = UntypedFormGroup;
  fromDate: any;
  toDate: any;
  today: Date = new Date();
  minDate = new Date('1950/01/01'); maxDate = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  public userData: any;
  reason: string = '';
  titleName: string = '';
  userSession: any;
  isEdit = true;
  messagesDataList: any = [];
  reqField: any;
  reqOption: any;
  reqSave: any;
  reqNotSave: any;
  reqReject: any;
  reqNotReject: any;
  employeeEmailData: any = [];
  employeeId: any;
  constructor(private formBuilder: UntypedFormBuilder, private activeroute: ActivatedRoute,
    private location: Location, public dialog: MatDialog, private router: Router,
    private attendanceService: AttendanceService, private adminService: AdminService,
    private emsService: EmsService, private LM: LeavesService,) { }
  isStatus = false;
  fileURL: any;
  file: any;
  isFile: boolean = true;
  isedit: boolean = false;
  editFileName: any;
  documentId: any = null;
  documentInfo: any = null;
  pdfName: any = null;
  document: boolean = false;
  attendanceid: any;
  requestEmpId: any;
  isFirstHalf: boolean = false;
  isSecondHalf: boolean = false;
  ngOnInit(): void {
    // if (this.activeroute.snapshot.params.userData !=null){
    //   this.userData=this.activeroute.snapshot.params.userData;
    // }
    this.userData = this.location.getState();
    if (this.userData.userData == null && this.userData.userData == undefined) {
      this.router.navigate(["/Attendance/ApprovalList"],);
    }
    this.attendanceid = this.userData.userData.id;
    this.requestEmpId = this.userData.userData.raisedbyid;
    this.getMessagesList();
    this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
    this.requestform = this.formBuilder.group(
      {
        appliedDate: [this.userData.userData.applieddate, Validators.required],
        shift: [this.userData.userData.shift, Validators.required],
        empName: [this.userData.userData.empname, Validators.required],
        fromDate: [this.userData.userData.fromdate, Validators.required],
        toDate: [this.userData.userData.todate, Validators.required],
        workType: [this.userData.userData.worktype, Validators.required],
        reason: [this.userData.userData.reason, Validators.required],
        comment: [this.userData.userData.comment],
        firstHalf: [''],
        secondHalf: [''],
      });
    if (this.userData.userData.status != "Submitted") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
    if (this.userData.userData.status == 'Approved') {
      this.isStatus = true;
    } else {
      this.isStatus = false;
    }

    this.halfday();
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeeEmailData();
    this.getUploadDocument();

  }
      /** first half & second half attendance */
  halfday(){
    if ( this.userData.userData.halfdayattendance === 1) {
      this.requestform.controls.firstHalf.setValue(true)

    } else if (this.userData.userData.halfdayattendance === 2) {
          this.requestform.controls.secondHalf.setValue(true)

    } else if(this.userData.userData.halfdayattendance === 0) {
          this.isFirstHalf = false;
          this.isSecondHalf = false;
      }
  }
  acceptApproval() {
    this.titleName = "Approve"
    this.openDialog();
  }
  rejectApproval() {
    this.titleName = "Reject"
    this.openDialog();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px', position: { top: `100px` },
      data: { name: this.titleName, reason: this.reason, }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        if (result !== true) {
          this.reason = result.reason;
          this.saveApproval();
        }
      }
    });
  }
  saveApproval() {
    let obj = {
      "id": this.userData.userData.id,
      "approvercomments": this.reason,
      "actionby": this.userSession.id,
      "approvelstatus": this.titleName == "Reject" ? 'Rejected' : 'Approved',
      ///email data
      "empData": this.userData.userData,
      "emailData": this.employeeEmailData

    };
    this.attendanceService.updateAttendanceRequest(obj).subscribe((res) => {
      if (res.status) {
        let resMessage: any;
        if (res.message == "ApprovalRequest") {
          if (this.titleName == "Reject") {
            resMessage = this.reqReject
          } else {
            resMessage = this.reqSave
          }
        } else if (res.message == "UnableToApprove") {
          resMessage = this.reqNotSave
        }
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: resMessage
          //data: this.titleName=="Reject"?'Attendance request rejected successfully.':'Attendance request approved successfully.'
        });
        this.router.navigate(["/Attendance/ApprovalList"],);

      }
    })
  }
  backClick() {
    this.router.navigate(["/Attendance/" + this.userData.url],);
  }

  getMessagesList() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 100
    }
    this.adminService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == "ATT13") {
            this.reqSave = e.message
          } else if (e.code == "ATT14") {
            this.reqNotSave = e.message
          } else if (e.code == "ATT15") {
            this.reqReject = e.message
          }
        })
      }
      else {
        this.messagesDataList = [];
      }

    })
  }
  getEmployeeEmailData() {
    this.employeeEmailData = [];
    this.emsService.getEmployeeEmailDataByEmpid(this.userData.userData.raisedbyid)
      .subscribe((res: any) => {
        this.employeeEmailData = JSON.parse(res.data[0].jsonvalu)[0];
      })
  }
  getUploadDocument() {
    let info = {
      'employeeId': this.requestEmpId,
      'candidateId': null,
      'filecategory': 'ATTENDANCE',
      'moduleId': 4,
      'requestId': this.attendanceid,
      'status': 'Submitted'
    }
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length > 0) {
        this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0])
        this.pdfName = result.data[0].fname

        result.data[0].employeeId = this.userSession.id;
        let info = result.data[0]
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName = this.pdfName.split('.');

            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        })
      }
      else {
      }
    })
  }

  fileView() {
    window.open(this.fileURL);
  }

}


