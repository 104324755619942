
<div fxLayout="column" style="background-color: #f4f8fa;">
<mat-card>
    <mat-card-header class="headerbox">
        <div fxLayout="row" fxFlex="50">
            <mat-card-title class="title"> Audit Log </mat-card-title>
        </div>
        </mat-card-header>
        <form [formGroup]="auditLogForm">
            <br>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date</mat-label>
                    <input matInput readonly required [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                      placeholder="DD-MM-YYYY" formControlName="fromDate" (click)="fromDate.open()">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="auditLogForm.controls.fromDate.errors?.required">
                     Please select from date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date</mat-label>
                    <input matInput readonly required [matDatepicker]="toDate" [min]="minDate" [max]="maxDate"
                      placeholder="DD-MM-YYYY" formControlName="toDate" (click)="toDate.open()">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                    <mat-error class="con-error" *ngIf="auditLogForm.controls.toDate.errors?.required">
                        Please select to date
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                    <mat-label>Screens</mat-label>
                    <mat-select formControlName="screenName" required>
                      <ng-container>
                        <span *ngFor="let list of screensList">
                          <mat-option [value]="list.id">{{list.screen}}</mat-option>
                        </span>
                      </ng-container>
                    </mat-select>
                    <!-- <mat-error class="con-error" *ngIf="auditLogForm.controls.screenName.errors?.required">
                      {{requiredOption}}
                    </mat-error> -->
                  </mat-form-field>
                  <div fxlayout="row" style="padding-top: 1%;">
                    <button class="btn btn-primary mr-2 mb-2" (click)="searchForm()" type="submit">
                      <span>Submit</span>
                    </button>
                   
                    <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                      <span>Cancel</span>
                    </button>
                  </div>
            </div>
         </form>
         <mat-divider></mat-divider>
               <div style="padding-right: 1%;">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end">
                                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="30" class="mx-2 my-2" appearance="outline" style="padding-top: 1%;">
                     <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                     <mat-label>Search Employee</mat-label>
                     <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                   </mat-form-field>
                 
                     <span style="margin-right: 2%;padding-top: 2%;">
                       <button mat-icon-button matTooltip="Download">
                         <mat-icon style="font-size: 40px;color: #28acaf;">download</mat-icon>
                       </button>
                     </span>
       
                 </div>
                 <div fxLayout="column" class="tableP">
                   <div class="mat-elevation-z1">
                     <table mat-table [dataSource]="dataSource" matSort>
                       <ng-container matColumnDef="sno">
                         <th mat-header-cell *matHeaderCellDef>S.No</th>
                         <td mat-cell *matCellDef="let element; let i=index;">
                           {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                       </ng-container>
                       <ng-container matColumnDef="empid">
                         <th mat-header-cell *matHeaderCellDef mat-sort-header> Emp ID </th>
                         <td mat-cell *matCellDef="let row" class="truncate-cell">
                           <span>{{row.firstname}}{{' '}}{{row.middlename}}{{' '}}{{row.lastname}} </span>
                                      </td>
                       </ng-container>
             
                       <ng-container matColumnDef="name">
                         <th mat-header-cell *matHeaderCellDef >Name </th>
                         <td mat-cell *matCellDef="let row">
                           <span>{{row.personal_email}} </span>
                         </td>
                       </ng-container>
                       <ng-container matColumnDef="desig">
                         <th mat-header-cell *matHeaderCellDef >Designation</th>
                         <td mat-cell *matCellDef="let row">
                           <span>{{row.hired_date | date:'dd-MM-yyyy'}} </span>
                         </td>
                       </ng-container>
             
                       <ng-container matColumnDef="date">
                         <th mat-header-cell *matHeaderCellDef >Date&Time</th>
                         <td mat-cell *matCellDef="let row">
                           <span>{{row.dateofjoin | date:'dd-MM-yyyy'}} </span>
                         </td>
                       </ng-container>
             
                       <ng-container matColumnDef="screen">
                         <th mat-header-cell *matHeaderCellDef>Screen</th>
                         <td mat-cell *matCellDef="let row">
                           <span>{{row.contact_number}} </span>
             
                         </td>
                       </ng-container>
             
                       <ng-container matColumnDef="type">
                         <th mat-header-cell *matHeaderCellDef> Modification Type</th>
                         <td mat-cell *matCellDef="let row">
                           <span>{{row.status}} </span>
                         </td>
                       </ng-container>
             
                       <ng-container matColumnDef="action">
                         <th mat-header-cell *matHeaderCellDef></th>
                         <td mat-cell *matCellDef="let row">
                           <button mat-icon-button matTooltip="Details">
                             <mat-icon>visibility</mat-icon>
                           </button>
                         </td>
                       </ng-container>
             
                       <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                       <tr class="mat-row" *matNoDataRow>
                         <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                       </tr>
                     </table>
                     <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                   </div>
                 </div>
               </div>
       
</mat-card>
</div>