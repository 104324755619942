<div class="frame col-md-12" style="padding:12px">
    <h3 style="text-align: center;color: black;">Add</h3>
    <form #roleTypeForm="ngForm">
        <div class="form-group row">
            <label class="col-sm-4 col-form-label">Role Name<span  style="color: red">*</span></label>
            <div class="col-sm-8">
                <input type="text" class="form-control" maxlength="30" name="roleName" required [(ngModel)]="role.roleName" placeholder="" #roleName="ngModel" (keyup)="checkLeaveTypes()">
                <div class="custom-text-error" *ngIf="(isCustomRoleSubmitted && !roleName.valid) || (isCustomRoleSubmitted && roleName.touched && !roleName.valid)">
                    {{ msgEM1 }}
                </div>
                <div class="custom-text-error" *ngIf="(isCustomRoleSubmitted && isRoleAlreadyExists && roleName.touched)">
                    {{ msgEM107 }}
                </div>

            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12" style="text-align: right">
                <button type="button" (click)="addRole(roleTypeForm)" class="btn btn-primary mr-3">Save</button>
                <button type="button" mat-dialog-close class="btn btn-primary">Cancel</button>
            </div>
        </div>
    </form>
</div>
