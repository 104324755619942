 <div  [formGroup]="searchForm">
<mat-card>
   <div fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" style="font: small-caption;" *ngIf="is_super_admin">
      <div fxLayout="row" fxflex="100" fxLayout.xs="row" fxLayout.lt-lg="row" >
        <mat-form-field fxFlex="50" fxFlex.xs="100"   class="mx-2 my-2" appearance="outline" >
          <mat-label>City-Branch </mat-label>
          <mat-select placeholder="" name="companylocation" formControlName="companylocation" required  multiple #locationSelect>
              <button mat-raised-button class="mat-primary fill text-sm" (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                  *ngIf="!locationIshide">Select All
              </button>
              <button mat-raised-button class="mat-accent fill text-sm" (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                  Deselect All
              </button>
              <mat-option *ngFor="let res of worklocationDetails" [value]="res" (click)="onchangeLocation(res)">
                  {{res.cityname}}-{{res.location}}
              </mat-option>
          </mat-select>
          <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
              Please select option
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50" fxFlex.xs="100"    class="mx-2 my-2" appearance="outline"  >
          <mat-label>Manager</mat-label>
          <mat-select placeholder="" name="managers" formControlName="managers" required  #managerSelect>
              <!-- <button mat-raised-button class="mat-primary fill text-sm"
                  (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                  *ngIf="!mgrIshide">Select All
              </button>
              <button mat-raised-button class="mat-accent fill text-sm"
                  (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                  Deselect All
              </button> -->
              <mat-option *ngFor="let res of managersDetails" [value]="res" (click)="onchangeManager(res)">
                  {{res.manager_name}}
              </mat-option>
          </mat-select>
          <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
              Please select option
          </mat-error>
        </mat-form-field>
      </div>
        
   </div>
   <div fxLayout="row" >
          <div fxLayout="column"  fxFlex="25" >
            <div fxLayout="row" fxLayoutAlign.xs="center" >
                <div style="width:100%;"  >             
                  <div fxLayout="column" style="font: small-caption;">
                    <mat-form-field  class="mx-2 my-2" appearance="outline">
                        <mat-label>Date </mat-label>
                        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="selectedDate"  [formControl]="selectedDateControl"   (keypress)="false" 
                          placeholder="DD-MM-YYYY"  required  (click)="selectedDate.open()" />
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="selectedDate"> </mat-datepicker-toggle>
                        <mat-datepicker #selectedDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                  
                  <div fxLayout="column" style="font: small-caption;">
                      <mat-form-field  class="mx-2 my-2" appearance="outline" >
                          <mat-label>Employee</mat-label>
                          <mat-select [(value)]="selectedEmployee"  [formControl]="selectedEmployeeControl"> 
                              <!-- <mat-option value="0" > {{employeesAllCaption}}</mat-option> -->
                              <mat-option *ngFor="let emp of attendanceData" [value]="emp.empid" [ngStyle]="{'color':emp.punchcolor }" (click)="getCrmselectedEmployeeDetails(emp)">{{ emp.empname }}</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-divider></mat-divider>   
                        <!-- <input matInput  [matDatepicker]="selectedDate"  [min]="minDate" [max]="maxDate"  [formControl]="selectedDateControl" placeholder="select date" class="form-control" (keypress)="false" required>
                        <mat-datepicker-toggle matSuffix [for]="selectedDate"></mat-datepicker-toggle><mat-datepicker #selectedDate></mat-datepicker>   -->
                  </div>
                </div>   
            </div>   
            <div fxLayout="row" *ngIf="!selectedEmployee" fxFlex="100"  fxLayoutAlign.xs="center" style="padding-top: 1%;">
              <div class="tableP" fxLayout="row wrap" style="width: -webkit-fill-available;">
                  <mat-card style="width:100%; margin:5px;padding: 2%;background-color: ghostwhite;" class="outline" >
                    <div fxLayout="row" fxLayout.xs="row"  class="card"  style="background-color: lightgreen;width: -webkit-fill-available;" >
                      <div fxLayoutAlign="space-evenly center"  fxLayout="row"    style="color:black;padding: 2%;font-size: initial;font-weight: 300;">
                        <div  >
                          <span style="font-size: larger;font-weight: 400;" class="">Punched In: {{punchInEmployees.length}} </span>
                        </div>
                        <!-- <div [fxFlex]="'10%'"  >
                          <span   fxLayoutAlign="end">
                            <button mat-icon-button matTooltip="" style="color:cadetblue;">
                              <mat-icon class="icon" >keyboard_arrow_right</mat-icon>
                            </button>
                          </span>
                        </div> -->
                      </div>
                    </div>
                    <mat-divider></mat-divider>   
                    <div  fxLayout="row" fxLayout.xs="row" class="card"  style="background-color: lightsalmon;width: -webkit-fill-available;" >
                      <div fxLayoutAlign="space-evenly center"  fxLayout="row"    style="color:black;padding: 2%;font-size: initial;font-weight: 300;">
                        <div >
                          <span style="font-size: larger;font-weight: 400;" class="">Punched Out: {{punchOutEmployees.length}} </span>
                        </div>
                        <!-- <div [fxFlex]="'10%'"  >
                          <span   fxLayoutAlign="end">
                            <button mat-icon-button matTooltip="" style="color:cadetblue;">
                              <mat-icon class="icon" >keyboard_arrow_right</mat-icon>
                            </button>
                          </span>
                        </div> -->
                      </div>
                    </div>              
                  </mat-card>
                  <!-- <div style="width:100%;">                  
                        <div class="scrollable-div"  > 
                          <ng-container *ngFor="let a of as;index as i;"> </ng-container>
                        </div>
                  </div> -->
              </div>
            </div>   
            <div fxLayout="row" *ngIf="selectedEmployee" fxFlex="25" style="height: fit-content;">
              <div fxLayout="row"  fxFlex="100"  fxLayoutAlign.xs="center" style="padding-top: 1%;">
                <div class="tableP" fxLayout="row wrap" style="width: -webkit-fill-available;">
                  <mat-card style="width:100%; margin:5px;padding: 1%;background-color: ghostwhite;" class="outline" >
                    <div fxLayout="row" fxLayoutAlign="space-between"  *ngIf="employeeData.empname"  style="padding:5px;font-weight: 600;text-align: center;" >
                      <mat-label>{{employeeData.empname}}  </mat-label>
                     
                      <mat-label>
                        <!-- <div fxLayout="row" fxFlex="25"  ><span style="font-weight: 500;">Location:</span></div> -->
                        <div fxLayout="row" fxFlex="100" style="text-align: end;" *ngIf="today === formattedDate && show_emp_trace && employeeData.empname">
                          
                          <mat-icon class="mat-icon-size"  [ngStyle]="{'color':emp_batterylevel >= 20? 'lightgreen':'pink' }" style="position:relative; -webkit-writing-mode: vertical-lr; margin-top: -15px; ">{{ emp_batterylevel >= 20 ?  'battery_full':'battery_alert' }}</mat-icon>
                          <span  [ngStyle]="{'color':emp_batterylevel >= 20? 'green':'red' }"  style=" font-size: x-small;position:absolute;display:inline-block; color: blue;margin-left: 10px;" *ngIf="emp_batterylevel">
                            {{emp_batterylevel}}%
                          </span>
                          
                        </div>
                      </mat-label>
                      
                    </div>
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="employeeData.contactnumber" style="padding:5px;font-weight: 600;text-align: center;" >
                      <mat-label>{{employeeData.contactnumber}} </mat-label>
                      <mat-label>
                        <div fxLayout="row" fxFlex="100" style="text-align: end;" fxLayoutAlign="end" *ngIf="today === formattedDate && show_emp_trace && employeeData.contactnumber">
                          <button class="btn" style="background-color: unset;margin-right: -25px; margin-bottom: -10px; margin-top: -10px;" type="button" (click)="fetchLocationOnMap()">
                            <i style="position:relative;" class="material-icons" matTooltip="{{lastTrackData && lastTrackData.trackTime?lastTrackData.trackTime:''}}"><span  [ngStyle]="{'color':location_icon_color}">{{location_icon_val}} </span> </i> 
                          </button>
                      </div>
                      </mat-label>
                    </div>
                    <div fxLayout="column" *ngIf="attd_trackdata && attd_trackdata[0]" >
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center"  class="clock-attendance" >
                              <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                                <p style="align-self: end; margin-top: 5px;"> Punch In </p>
                            </div>
                          
                            <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                                <p style="align-self: end; margin-top: 5px;">Punch Out </p>
                            </div>
                      </div>
                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="space-between" class="time-attendance">
                          <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                              <p *ngIf="attd_trackdata[0].firstlogintime" style="align-self: end;"> {{getFormattedTime(attd_trackdata[0].firstlogintime)}} </p>
                          </div>
                        
                              <!-- <img *ngIf="imageDataUrl && imageDataUrl.imageData" [src]="imageDataUrl.imageData" alt="Captured Image" style=" width: 50px; height: auto;">    -->
                          <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                              <p *ngIf="attd_trackdata[0].lastlogouttime" style="align-self: end;"> {{getFormattedTime(attd_trackdata[0].lastlogouttime)}} </p>
                          </div>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start" style="padding-top: 10px;font-variant-caps: all-small-caps;margin-left: 5%;text-align:center;">
                      <span  fxLayoutAlign.lt-lg="end">
                        <mat-radio-group >
                          <mat-radio-button class="small-radio"  tabIndex="4" class="mx2 my-2 mr-2" value = "POINT" [checked]='liveTrackingStatus == "POINT"' (click)='getTrackData("POINT")'>
                            <span class="radioBtn">POINT TO POINT</span>
                          </mat-radio-button>
                          <mat-radio-button class="small-radio"  tabIndex="3"  class="mx2 my-2 mr-2" value = "LIVE" [checked]='liveTrackingStatus == "LIVE"' (click)='getTrackData("LIVE")' >
                            <span class="radioBtn">LIVE TRACKING</span>
                          </mat-radio-button>
                        </mat-radio-group>
                      </span>
                    </div>
                </mat-card>
                <div style="width:100%;">
                    <div style=" margin:5px;padding: 1px;" class="outline">
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center"  class="clock-attendance" >
                            <div fxLayout="row" fxLayoutAlign="space-evenly left" >
                              <p style="align-self: end; margin-top: 5px;"> Activities </p>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="space-evenly right" >
                              <p style="align-self: end; margin-top: 5px;">Total: {{activitiesData.length}}</p>
                          </div>
                      </div>
                    </div>
                    <div class="scrollable-div"  > 
                  <ng-container *ngFor="let activity of activitiesData;index as i;">
                    <mat-card style=" margin:5px;padding: 1px;background-color: ghostwhite;" class="outline" >
                          
                        <div fxLayout="row" fxLayoutAlign="space-between" [ngStyle]="{'color': activity.display?'Orange':'black', 'font-weight': activity.display?'500':'300'}" style="padding:2px;text-align: left;" (click)="checkActivityDetails(activity)">
                            <mat-label >{{getFormattedTime(activity.created_on)}}{{activity.client_name.toString().length?' | '+activity.client_name:''}}</mat-label>
                                <mat-icon  class="hand-symbol" (click)="fetchActivityLocationOnMap(activity)" *ngIf="activity.activity_type === 'VISIT'">
                                    <i style="position:relative; margin-bottom:-5px;" [ngStyle]="{'color': getLocationIconColorForActivity(activity)}" class="material-icons"> 
                                      {{activity.location_icon_val}} 
                                  </i>  
                            </mat-icon>
                        </div>
                        <div fxLayout="row" *ngIf="activity.display">
                          <div fxLayout="column" fxFlex="100"  style="padding:5px;text-align: left;">  
                            <mat-divider ></mat-divider>
                            <div  fxLayout="column" fxFlex="100" *ngIf="activity && activity.imageData && activity.imageData.image">
                              <img class="img-fluid custom-image"   [src]="activity.imageData.image">
                            </div> 
                            <mat-divider ></mat-divider>
                              <mat-label>
                                <div fxLayout="row" fxFlex="100" *ngIf="activity.activity_status && activity.activity_status.toString().length ">
                                  <span style="font-size: smaller;"> <b  style="font-weight: 500;"> {{activity.activity_type.toString().length?activity.activity_type+' | ':''}}Client {{ activity.client_status}} {{ activity.activity_status?' | '+activity.activity_status:'' }} </b> </span>
                                </div>
                              </mat-label>
                            <mat-divider ></mat-divider>
                              <mat-label>
                                <div fxLayout="row" fxFlex="100" *ngIf="activity.activity_status && activity.activity_status.toString().length ">
                                  <span style="font-size: smaller;"> <b  style="font-weight: 500;">Follow-up On </b> on {{ getFormattedDateTime(activity.next_follow_up_on) }}</span>
                                </div>
                              </mat-label>
                            <mat-divider ></mat-divider>
                              <mat-label>
                                <div fxLayout="row" fxFlex="100" *ngIf="activity.notes && activity.notes.toString().length ">
                                  <span style="font-size: smaller;"> <b  style="font-weight: 500;">Notes: </b>{{ activity.notes }}</span>
                                </div>
                              </mat-label>
                              <mat-divider></mat-divider>
                              <mat-label >
                                <div fxLayout="row" fxFlex="100" *ngIf="activity.location && activity.location.toString().length ">
                                  <span style="font-size: smaller;"> <b  style="font-weight: 500;">Location: </b>{{ activity.location }}</span>
                                </div>
                              </mat-label>
                          </div>
                        </div>
                      </mat-card>
                    </ng-container>
                      </div>
                  </div>
                </div>
              </div>
              
            </div>         
          </div>
         
          <div fxLayout="column" fxFlex="75" style="display: none;">
            <div style="height:500px ; position: relative; overflow: hidden;" *ngIf="mapInitialized">
              <google-map [center]="center" height="100%" width="100%"  [options]="mapOptions" >
                <!-- <map-traffic-layer [autoRefresh]="true"></map-traffic-layer> -->
                <map-directions-renderer  #directionsRender *ngFor="let directionResult of directionsResults"  [options]="directionsRenderOption" [directions]="directionResult"></map-directions-renderer>
                  <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions"  [position]="markerPosition"  
                   [label]="markerPosition.label" [icon]="markerPosition.icon" (mapMouseover)="openInfoWindow(marker,markerPosition)"
                   (mapMousedown)="closeMouseOver(markerPosition)">
                    <map-info-window  fxLayout="row" style="text-align: center;">

                      <div *ngIf="markerSelected && markerSelected.markerInfo && markerSelected.markerInfo.length "  >
                            
                          <div *ngFor="let mi of markerSelected.markerInfo"  fxLayout="column">
                              <div fxLayout="column" style="width: 190px;" > 
                                 <span  (click)="changeDisplayStatus(mi)"  style="width: 190px;"  [ngStyle]="{'color':mi.markerColor}" [ngClass]="getMarkerClass(mi)" >  {{selectedEmployee?'':mi.empname}} {{!selectedEmployee && mi.mapActivity?' | ':''}}  {{mi.mapActivity?mi.mapActivity:''}}{{mi.mapActivity && mi.time?' | ':''}}  {{mi.time }}  </span>
                                 <mat-divider ></mat-divider>
                              </div>
                                <div *ngIf="mi.display"  fxLayout="column">
                                  <div fxLayout="column"  [ngStyle]="{'color':mi.markerColor}" style="width: 190px;"> 
                                      <div fxLayout="column"   *ngIf="mi.client && mi.client.toString().length" style="width: 190px;" > 
                                        <span  style="width: 190px;"> Client:<b>{{mi.client}}</b> </span>
                                    </div>
                                    <div fxLayout="column"  *ngIf="mi.checkImage && mi.imageDisplayOnMap" ><img  [src]="mi.displayImage" style="width: 100px;margin: auto;" >
                                    </div>
                                    <div fxLayout="column" style="width: 190px;" *ngIf="mi.infoContent && mi.infoContent.toString().length"> 
                                      <span  style="width: 190px;font-weight: bold;font-size: x-small">Notes: {{mi.infoContent}}</span>
                                    </div>
                                    <mat-divider ></mat-divider>
                                </div>
                              </div>
                            </div>
                            <div fxLayout="column"   *ngIf="markerSelected.markerInfo[0].location && markerSelected.markerInfo[0].location.toString().length" style="width: 190px;" > 
                              <span  style="width: 190px;font-size: x-small;"> {{markerSelected.markerInfo[0].location}}</span>
                            </div>
                            <div fxLayout="column"  >
                              <span><a [routerLink]="[]" (click)="displayMarkerInGoogle(markerSelected)" style="font-size: 0.8em;color:#00A9E0 !important">Display in Google</a></span>
                            </div>
                        </div>
                    </map-info-window>
                  </map-marker>
              </google-map>
            </div>
          </div>
    </div>
    <div class="important-note">
      <ul >
        <li >  <span class="icon">  Tracking will start only when the employee is logged into the CRM application on their mobile device, with location permissions granted and an active internet connection. </span> </li>
        <li >  <span class="icon">  <b>Point to point:&nbsp;</b> The route map will be displayed only when the employee punches in, punches out, or performs visit activities, such as navigating to client locations. This ensures accurate logging of visits and minimizes unnecessary travel routes. </span> </li>
        <li >  <span class="icon">  <b>Live Tracking:&nbsp;</b> The route map will capture the employee's movements from punch-in to punch-out, including all visit activities, ensuring comprehensive live tracking data. </span> </li>
      </ul>
    </div>
</mat-card>
</div>
 