import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PayrollService } from '../../payroll.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as _moment from 'moment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as XLSX from "xlsx";
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
const moment = _moment;
@Component({
  selector: 'app-bank-wise-salary-dialog',
  templateUrl: './bank-wise-salary-dialog.component.html',
  styleUrls: ['./bank-wise-salary-dialog.component.scss']
})
export class BankWiseSalaryDialogComponent implements OnInit {
  @ViewChild('table') table!: ElementRef;
  date = new UntypedFormControl(moment());
  paygroupDisplayedColumns: string[] = ['sno', 'empid', 'empname', 'amount', 'accountnumber', 'bank', 'ifsc'];
  esiDisplayedColumns: string[] = ['ESI', 'Employee_Name', 'paid_days', 'Gross_Salary', 'employee_esi_value', 'employer_esi_value', 'total_contribution'];
  ptreportDisplayedColumns: string[] = ['Employee_Id', 'Employee_Name', 'Gross_Salary', 'Professionam_Tax'];
  epfDisplayedColumns: string[] = ['sno', 'uan', 'empname', 'gross', 'epf', 'eps', 'edil', 'epsvalue', 'ee', 'er', 'ncp', 'refunds'];

  paygroupDataSource: any = [];
  esiDataSource: any = [];
  epfDataSource: any = [];
  ptDataSource: any = [];

  stateForm: any = UntypedFormGroup;
  month: any;
  year: any;
  category: any;
  payGroupList: any = [];
  isEsiView: boolean = false;
  isEpfView: boolean = false;
  isPtView: boolean = false;
  isPaygroupView: boolean = false;
  constructor(public dialogRef: MatDialogRef<BankWiseSalaryDialogComponent>,
    private payrollService: PayrollService, private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,) {
    this.month = data.month_value;
    this.year = data.year_value;
    this.payGroupList = data.paygroup_value;
    this.category = data.category

  }
  employeesList: any = [];
  ptStateslist: any = [];
  esiStateslist: any = [];
  statewiseList: any = [];
  selectedStateId: any;
  datadata: any;
  months = [{ id: 0, month: 'Jan' }, { id: 1, month: 'Feb' }, { id: 2, month: 'Mar' }, { id: 3, month: 'Apr' }, { id: 4, month: 'May' }, { id: 5, month: 'Jun' }, { id: 6, month: 'Jul' }, { id: 7, month: 'Aug' }, { id: 8, month: 'Sep' }, { id: 9, month: 'Oct' }, { id: 10, month: 'Nov' }, { id: 11, month: 'Dec' }]
  monthdata: any;
  datadatas: any = [];
  ngOnInit(): void {
    this.stateForm = this.formBuilder.group({
      state: ['']
    })

    if (this.category == 'ESI') {
      this.getEsiStatesList();
      this.isEsiView =true ;

    } else if (this.category == 'EPF') {
      this.epfData();

    } else if (this.category == 'SALARY') {
      this.paygroupData();

    } else if (this.category == 'PT') {
      this.isPtView = true;
      this.getStatesForProfessionalTax();
    }

    this.stateForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.selectedStateId = selectedValue;
      if(this.category == 'ESI'){
        this.esiData();
      } else if(this.category == 'PT'){
        this.getProfessionalTaxDetailReport();
      }

    })
  }

  ptStateChange() {
    this.selectedStateId = this.stateForm.controls.state.value;
    this.getProfessionalTaxDetailReport();
  }

  esiStateChange() {
    this.esiData();
  }

  epfData() {
    this.isEpfView = true;
    let data = {
      month: this.month,
      year: this.year,
    }
    this.payrollService.getEpfValuesForChallan(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.epfDataSource = res.data;
        this.datadata = res.data;
        for (let i = 0; i < this.datadata.length; i++) {
          this.datadatas[i] = this.datadata[i].UAN + '#~#' + this.datadata[i].Employee_Name + '#~#' + this.datadata[i].gross_salary + '#~#' + this.datadata[i].employee_epf_value + '#~#' + this.datadata[i].eps_wage + '#~#' + this.datadata[i].edli_wage + '#~#' + this.datadata[i].employer_eps_value + '#~#' + this.datadata[i].epf_eps_difference + '#~#' + (this.datadata[i].ncp_days == null ? 0 : this.datadata[i].ncp_days) + '#~#' + (this.datadata[i].refund_of_advance == null ? 0 : this.datadata[i].refund_of_advance) + '\n'
        }
      }
    });
  }
  esiData() {
    this.isEsiView = true;
    let data = {
      month: this.month,
      year: this.year,
      state: Number(this.selectedStateId)
    }
    this.payrollService.getESIValuesForChallan(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.esiDataSource = res.data;
      }
    });
  }

  paygroupData() {
    this.isPaygroupView = true;
    let data = {
      month_value: this.month,
      year_value: this.year,
      paygroup_value: this.payGroupList
    }
    this.payrollService.getEmployeeSalariesByPayGroups(data).subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.paygroupDataSource = result.data;
      }
    });
  }

  getStatesForProfessionalTax() {
    let data = {
      year: this.year,
      month: this.month,
    }
    this.payrollService.getStatesForProfessionalTax(data).subscribe((result: any) => {
      if (result.status) {
        this.ptStateslist = result.data;
      }
    })
  }

  getProfessionalTaxDetailReport() {
    this.statewiseList = [];
    let data = {
      year: this.year,
      month: this.month,
      state: Number(this.selectedStateId)
    }
    this.ptDataSource = new MatTableDataSource();
    this.payrollService.getProfessionalTaxDetailReport(data).subscribe((result: any) => {
      if (result.status) {
        this.statewiseList = result.data;
        this.statewiseList[0].Gross_Salary != null ? this.ptDataSource = this.statewiseList : this.ptDataSource = new MatTableDataSource();
      }
    })
  }


  public paygroupExportPDF(): void {
    let year_value = this.date.value._d.getFullYear();
    let month_value = this.date.value._d.getMonth() + 1;
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: month_value + '-' + year_value + '-' + "Paygroup Employee List ",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage: any, pageCount: any) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: month_value + '-' + year_value + '-' + "Paygroup List Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html
      ],
      pageOrientation: 'landscape',//'portrait'
      pageSize: 'A4'
    }).download("Paygroup List Report.pdf");

  }

  public esiExportPDF(): void {
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: "ESI Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: "Payroll Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html
      ],
      pageOrientation: 'landscape'//'portrait'
    }).download("ESI Report.pdf");

  }

  esiExportAsXLSX() {

    // var ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet('Payroll_report_for_financeteam_');
    var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ESI_report_for_financeteam_');
    /* save to file */
    // XLSX.writeFile('Payroll_report_for_financeteam_'+this.monthdata,'Payroll_report_for_financeteam_'+this.monthdata+'_'+this.year+'.xlsx')
    XLSX.writeFile(wb, 'ESI_report_for_financeteam_' + this.month + '_' + this.year + '.xlsx');


  }

  public ptReportExportPDF(): void {
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: "Professional Tax Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: "Professional Tax\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html
      ],
      pageOrientation: 'landscape'//'portrait'
    }).download("Professional Tax.pdf");

  }

  public epfReportExportText(): void {
    const data = this.datadatas.toString().replace(/\,/g, '');
    const blob = new Blob([data], { type: 'application/octet-stream' });
    let urls: any = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'EPF.txt');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getEsiStatesList() {
    this.payrollService.getCompanyEsiValues().subscribe((result:any)=>{
      if(result.status && result.data.length>0){
        this.esiStateslist = result.data[0]
      }else {
        this.esiStateslist = [];
      }
    })
  }
}
