

<div fxLayoutAlign="space-between center" style="width: auto;"> 
    <div style="padding-left: 41%;">
        <h3 style="margin: 0px;"> <b>INVOICE</b></h3>
    </div>
    <div fxLayout="row wrap">
       <span style="color:#28acaf">
        <button mat-icon-button (click)="print()" >
        <mat-icon matTooltip="Download">download</mat-icon>
    </button>
    </span>
        <span>
            <button mat-icon-button>
        <mat-icon class="close-icon" color="warn" matTooltip="Close"  [mat-dialog-close]="data.NO" >cancel</mat-icon>
    </button> </span>
    </div>
   
    
</div>
<div id="invoice" #invoice  style="width: auto;padding-top: 20px;padding-left: 40px;">
<div >
<div fxLayout="row wrap" fxLayout.xs="column">
    <div style="width: 45%;">
        <p><b>Invoice Date: </b> {{invoiceDate | date:'dd-MM-yyyy'}}</p>
        <p><b>Renewal Date: </b> {{dueDate | date:'dd-MM-yyyy'}}</p>
        <p><b>Invoice Number: </b> {{invoiceNum}}</p>

    </div>
    <div style="width: 45%;">
        <p><b>Company Name: </b> {{cname}}</p>
        <p><b>Billing Address: </b> {{address1}},</p>
        <span *ngIf="address2 !=null">
            <p>{{address2}}{{', '}}{{location}},</p>
        </span>
        <span *ngIf="address2 ==null || '' ">
            <p>{{location}},</p>
        </span>
        <p>{{state}},{{country}}-{{pincode}}</p>
    </div>
    
    
</div>
<div style="height: auto;">
    <div class="mat-elevation-z1">
 
    <table mat-table  [dataSource]="dataSource" matSort  #table>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date</th>
            <td mat-cell *matCellDef="let element" > {{element.payment_date | date:'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef >Plan </th>
            <td mat-cell *matCellDef="let element" > {{element.plan_name}} </td>
        </ng-container>
        <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef >Users </th>
            <td mat-cell *matCellDef="let element" > {{element.user_count}} </td>
        </ng-container>
        <ng-container matColumnDef="costperuser">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"><img src="./assets/img/currency-white.png">Cost Per  User </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.cost_per_user_yearly_bill}} </td>
        </ng-container>
        <ng-container matColumnDef="subtotal">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"><img src="./assets/img/currency-white.png"> Total </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.amount_paid}} </td>
        </ng-container>
       
        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data found</td>
        </tr> 
    </table>
   </div><br>
   
</div>
</div>
</div>

