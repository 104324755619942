<div fxLayout="column" fxFlex fxLayoutAlign="start" style="padding: 0%;margin: auto;">

   
    <div  fxLayout="row"   style="margin-bottom: 5px;color: white;align-items: center;" class="clock-attendance">  
        <div   fxFlex="5" fxLayoutAlign="start" >
            <button class="btn" (click)="backArrow()" style="background-color: unset;color: white;text-align-last: start;" type="button">
                <mat-icon style="font-weight: bold" title="Back">arrow_back</mat-icon>
            </button>
        </div>
        <div   fxFlex="95" fxLayoutAlign="center" style="font-weight: bold;">
                <mat-label> {{pageTitle}}</mat-label>
        </div>

    </div>
    <form [formGroup]="companyForm" autocomplete="off">
        <div>
            <div  fxLayout="row" fxFlex>     
            
                <div fxLayout="column"   fxFlex>
                 
                    <div>
                        <div fxFlex="100%" fxLayout="column" >
                            <div fxLayout="row">
                                <mat-form-field fxFlex="95%"  style="padding-right: 1%">
                                    <mat-label>Organization</mat-label>
                                    <input  formControlName="companyname" matInput placeholder="" required noLeadingWhitespace   >
                                        <mat-error *ngIf="companyForm.controls.companyname.hasError('required')">Please enter organization</mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="20%"  style="padding-right: 1%">
                                    <mat-label>Expected Price </mat-label>
                                    <input  formControlName="targetPrice" matInput placeholder=""  onlyNumber  >
                                        <!-- <mat-error *ngIf="companyForm.controls.targetPrice.hasError('whitespace') || companyForm.controls.companyname.hasError('required')">Please enter organization</mat-error> -->
                                </mat-form-field>
                                <button fxFlex="5%"  class="btn" style="background-color: unset;" type="button">
                                    <i  (click)="openWebcamModal()"  class="material-icons" [ngStyle]="{'color': imageDataUrl && imageDataUrl.imageData?'green':'red'}" >
                                          camera_alt 
                                    </i>  
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div fxFlex="75%" fxLayout="column" [hidden]="mode !== 'map'" >
                            <div fxLayout="row" >
                                <div fxLayout="row"  fxFlex="85%"   [hidden]="!searchClientOnMap">
                                   <input  type="text"  matInput  placeholder="Enter your address"  [formControl]="myControl" [matAutocomplete]="auto"  />
                                        <!-- <input type="text" matInput  [(ngModel)]="inputValue" id="autocompleteService" placeholder="Enter your address" (input)="onInputChange($event)" /> -->
                                        <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option *ngFor="let option of displayOptions" [value]="option"  (onSelectionChange)="selectValue(option)" style="font-size: smaller;font-stretch: semi-condensed;color: blueviolet;" >
                                            {{option.name + ", " + option.formatted_address }}
                                        </mat-option>
                                        </mat-autocomplete>
                                     
                                </div>
                                <div fxLayout="row"  fxFlex="85%" [hidden]="searchClientOnMap">
                                        <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" [hidden]="mode !== 'map'" >    
                                            <button  class="btn btn-success mr-2 mb-2"   (click)="getClientLocationFromMap()" type="button" style="min-width: max-content;">
                                                <span>Current Location</span>
                                            </button>
                                        </div>
                                        <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" [hidden]="mode !== 'map'" >    
                                            <button  class="btn btn-success mr-2 mb-2"   (click)="searchlocationOnMap()" type="button" style="min-width: max-content;">
                                                <span>Search Location</span>
                                            </button>
                                        </div>
                                </div>
                                <div fxLayout="row" fxFlex="10%" > 
                                    <mat-icon   (click)="refreshMap()" class="icon"  style="padding-top: 0.2rem;color:cadetblue;cursor: pointer;">refresh</mat-icon> 
                                </div>
                            </div>
                        </div>
                        <div fxFlex="75%" fxLayout="column" *ngIf="mode === 'details'" >
                            <div fxLayout="row" fxFlex="100%"  >
                                <mat-form-field  fxFlex="25%" style="padding-right: 1%;">
                                    <mat-label>Contact Person</mat-label>
                                    <input  formControlName="contact_person_name" noLeadingWhitespace matInput placeholder="" required   
                                    onlyAlphabets >
                                        <mat-error *ngIf=" companyForm.controls.contact_person_name.hasError('required')">Please enter person name</mat-error>
                                </mat-form-field>
                                <mat-form-field  fxFlex="25%"       style="padding-right: 1%">
                                    <mat-label>Contact Number</mat-label>
                                    <input  formControlName="contact" matInput placeholder=""  required  minlength="10" maxlength="10" onlyNumber >
                                            <mat-error class="con-error" *ngIf=" companyForm.controls.contact.errors?.required ">
                                                Please enter contact number
                                                </mat-error>
                                                <mat-error class="con-error" *ngIf="companyForm.controls.contact.errors?.minlength">
                                                    Enter valid contact number
                                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field  fxFlex="50%" style="padding-right: 1%;">
                                    <mat-label>Email</mat-label>
                                    <input type="email"  formControlName="email" matInput placeholder=""   >
                                    <mat-error class="reg-error" *ngIf="companyForm.controls.email.errors?.pattern">
                                        Please enter valid email address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="25%" fxLayout="column" *ngIf="mode === 'details' || mode === 'map' " >
                            <div fxLayout="row">
                                <mat-form-field   fxFlex="80%"   style="padding-right: 1%;">
                                    <mat-label>Country </mat-label>
                                        <mat-select    formControlName="country" required  >
                                            <ng-container >
                                                <div *ngFor="let b of CountryDetails">
                                                    <mat-option    [value]="b.id">{{b.country}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error" *ngIf="  companyForm.controls.country.errors?.required">
                                            Please select country 
                                        </mat-error>
                                </mat-form-field>
                                <button fxFlex="10%" class="btn" style="background-color: unset;"  type="button">
                                    <i (click)="setLocationInMap()"  [ngStyle]="{'color': getLocationIconColor()}" class="material-icons" > {{location_icon_val}} </i> 
                                </button>
                            </div>
                        </div>  
                    </div>
                    <div >
                        <div fxFlex="75%" fxLayout="column"  [hidden]="mode !== 'map'" >
                            <div  [hidden]="!mapInitialized"  style="padding-right: 1%;">
                                <div id="map" style="height: 190px; position: relative; overflow: hidden;"></div> 
                            </div> 
                        </div>
                        <div fxFlex="75%" fxLayout="column" *ngIf="mode === 'details'" >
                            <div fxLayout="row" fxFlex="100%"  >
                                <mat-form-field   fxFlex="100%"  style="padding-right: 1%;">
                                    <mat-label>Address1</mat-label>
                                    <input  formControlName="address1" noLeadingWhitespace matInput placeholder="" required>
                                        <mat-error *ngIf="companyForm.controls.address1.hasError('required')">Please enter address</mat-error>
                                    </mat-form-field>
                            </div>
                            <div fxLayout="row" fxFlex="100%" >
                                <mat-form-field  fxFlex="100%"  style="padding-right: 1%;">
                                    <mat-label>Address2</mat-label>
                                    <input  formControlName="address2" matInput placeholder=""  >
                                    </mat-form-field>
                                    
                            </div>
                            <div fxLayout="row"  fxFlex="100%" >
                                <mat-form-field  fxFlex="100%"  style="padding-right: 1%">
                                    <mat-label>Description</mat-label>
                                    <input  formControlName="notes" noLeadingWhitespace matInput placeholder="" required   maxlength="100"  >
                                        <mat-error *ngIf=" companyForm.controls.notes.hasError('required')">Please enter description</mat-error>
                                        <mat-error *ngIf="companyForm.controls.notes.hasError('maxLength') " >Description allowed for 100 characters length only</mat-error>
                                        
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="25%" fxLayout="column" *ngIf="mode === 'details' || mode === 'map'" >
                            <div fxLayout="row"   >
                                <mat-form-field style="padding-right: 1%;">
                                    <mat-label>State</mat-label>
                                    <mat-select   required formControlName="state"  >
                                        <ng-container >
                                            <div *ngFor="let b of stateDetails">
                                                <mat-option   [value]="b.id">{{b.state}}</mat-option>
                                            </div>
        
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="con-error" *ngIf="  companyForm.controls.state.errors?.required">
                                        Please select state
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row"   >
                                <mat-form-field   style="padding-right: 1%;">
                                    <mat-label>City</mat-label>
                                    <mat-select required  formControlName="city" >
                                        <ng-container >
                                            <div *ngFor="let b of cityDetails">
                                                <mat-option [value]="b.id">{{b.location}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="con-error" *ngIf="  companyForm.controls.city.errors?.required">
                                         Please select city</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-form-field  style="padding-right: 1%;">
                                    <mat-label>Pincode</mat-label>
                                    <input type="text" onlyNumber formControlName="pincode" matInput  placeholder="" minlength="6" maxlength="6" required>
                                    <mat-error class="con-error" *ngIf="  companyForm.controls.pincode.errors?.required">
                                     Please enter pincode
                                    </mat-error>
                                    <mat-error class="con-error" *ngIf="  companyForm.controls.pincode.errors?.maxlength || companyForm.controls.pincode.errors?.minlength">
                                        This field length should be 6 digits
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" *ngIf="mode === 'map'" >
                                <mat-form-field  fxFlex="45%" style="padding-right: 1%;">
                                    <mat-label>Latitude</mat-label>
                                    <input  formControlName="latitude" matInput placeholder="" onlyNumber style="font-size: x-small;">
                                        <mat-error class="con-error" *ngIf="  companyForm.controls.latitude.errors?.required">
                                            Please enter latitude
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field  fxFlex="45%" style="padding-right: 1%;">
                                        <mat-label>Longitude</mat-label>
                                        <input   formControlName="longitude" matInput placeholder="" onlyNumber style="font-size: x-small;" >
                                        <mat-error class="reg-error" *ngIf="companyForm.controls.longitude.errors?.pattern">
                                            Please enter longitude
                                        </mat-error>
                                    </mat-form-field>
                            </div>

                        </div>  
                    </div>
                    
                    <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" *ngIf="mode === 'details'" >    
                        <button [tabIndex]="7" class="btn btn-primary mr-2 mb-2" [disabled]="isWebRestricted"  (click)="saveNewClient()" type="submit">
                            <span>Submit</span>
                        </button>
                        <button [tabIndex]="7" class="btn btn-danger mr-2 mb-2" [disabled]="isWebRestricted"  (click)="clearNewClient()" type="reset">
                            <span>Clear</span>
                        </button>
                        <!-- <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="Cancel()" type="button">
                            <span>Cancel</span>
                        </button>  -->
                    </div>
                   
                    <div fxLayout="row" *ngIf="mode === 'image'">
                        <div fxFlex="20%"   style="padding-right: 1%;min-width:fit-content ;">
                            <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" *ngIf="mode === 'image'" >    
                                <button   class="btn btn-success btn-file  mr-2 mb-2" [disabled]="isWebRestricted"   type="button" style="min-width: max-content;">
                                    <span>Select From System</span>
                                    <input type="file" class="form-control" (change)="onSelectFile($event)" accept="image/*" [disabled]="isVedioOpen" />
                                </button>
                            </div>
                            <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" *ngIf="mode === 'image'" >    
                                <button  class="btn btn-success mr-2 mb-2" [disabled]="isWebRestricted"  (click)="initCamera()" type="button" style="min-width: max-content;">
                                    <span>Capture from Webcam</span>
                                </button>
                            </div>
                            
                        </div>
                         
                        <div fxFlex="20%"   style="padding-right: 1%;"></div>
                        <div  fxFlex="40%" style="padding-right: 1%;">
                            <div *ngIf="isCameraAvailable && !dispayImage" style="width: fit-content;">
                              <video #videoElement  autoplay style="width: 250px;height: auto;" ></video>
                                <div fxLayout="row"   appearance="outline" style="margin-top: 10%; min-width:fit-content; justify-content: center;align-items: center;" >
                                  <button type="submit"  class="btn btn-outline-primary custom mr-2 mb-2" [disabled]="isWebRestricted"  (click)="closeCamera()"  placeholder="Close" style=" min-width: fit-content;"> 
                                    <mat-label>Close</mat-label>
                                  </button>
                                  <button type="submit" *ngIf="isVedioOpen" [disabled]="isWebRestricted"  class="btn btn-outline-primary custom mr-2 mb-2" (click)="capturePhoto()"  placeholder="Capture Photo" style=" min-width: fit-content;"> 
                                    <mat-label>Take Photo</mat-label>
                                  </button>
                                </div>
                            </div>
                              <div  *ngIf="!isCameraAvailable"> 
                                <mat-label >Camera not available</mat-label>
                                <div fxLayout="row" appearance="outline" style="margin-top: 10%; min-width:fit-content;align-self: center;">
                                  <button type="submit" class="btn btn-outline-primary custom mr-2 mb-2" (click)="closeCamera()"  placeholder="Close" style=" min-width: fit-content;"> 
                                    <mat-label>Close</mat-label>
                                  </button>
                                </div>
                              </div>
                              <img *ngIf="dispayImage && imageDataUrl && imageDataUrl.imageData" [src]="imageDataUrl.imageData" alt="Captured Image" style="width: 250px; height: auto;">   
                            
                        </div>
                        <div fxFlex="20%"   style="padding-right: 1%;">
                            <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" *ngIf="mode === 'image'" >    
                                <button  class="btn btn-success mr-2 mb-2"  (click)="cancelImage()" type="button" style="min-width: max-content;">
                                    <span>Cancel</span>
                                </button>
                            </div>
                            <div  fxLayout="row" flexLayoutAlign="center" style="align-self: center;" *ngIf="mode === 'image' && (imageDataUrl && imageDataUrl.imageData)" >    
                                <button  class="btn btn-success mr-2 mb-2" [disabled]="isWebRestricted"  (click)="confirmImage()" type="button" style="min-width: max-content;">
                                    <span>Confirm</span>
                                </button>
                            </div>
                        </div>
                    </div>  
                    
                </div> 
            </div>
        </div>
    </form>
</div>
 