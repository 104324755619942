<body class="authentication-bg" data-layout-config='{"darkMode":false}'>
<div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-xxl-4 col-lg-5">
        <h1 class="text-dark-50 text-center mt-0 fw-bold" *ngIf="URL" style="color: red">Selected URL is expired </h1>

        <div class="card" *ngIf="!URL">

					<!-- Logo -->
					<!-- <div  class="card-header pt-4 pb-4 text-center bg-secondary" style="background-color: blue;">
						<span class="text-white"><img class="logo-img mr-2" src="assets/img/logo.png" alt="" />Sreeb Technologies</span>
					</div> -->

					<div class="card-body p-4">
						<!-- <form [formGroup]="formGroup" > -->

						<div class="text-center w-75 m-auto">
							<h4> Reset Password </h4>

              <!-- <p class="text-muted mb-4">Enter your email address and password to access main dashboard.</p> -->
							<!-- <p class="text-muted mb-4">Enter your email address and password to access admin panel.</p> -->
						</div>

						<form [formGroup]="formGroup"  >

							<div >
								<mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
									<mat-label>New Password</mat-label>
									<input matInput  [type]="hide1 ? 'password' : 'text'"formControlName="newpassword"  pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#\$&*~])(?=.*[A-Z]).{8,20}"
									  class="form-control" name="password" placeholder="Enter your password" maxlength="30"
									  />
									<mat-icon matSuffix (click)="hide1 = !hide1">{{
									  hide1 ? "visibility_off" : "visibility"
									  }}</mat-icon>
									<mat-error class="con-error" *ngIf="formGroup.controls['newpassword'].invalid">
										One lower case letter.One UPPER case letter. <br>
										One Number.
										One special character (! @ ^ $ % or a space).<br>
										Minimum 8 characters in length (20 characters max).
									</mat-error>
								  </mat-form-field>


								<!-- <label for="password" class="form-label">New Password</label>

								<div class="input-group input-group-merge">
									<input id="password-field"[type]="hide1 ? 'password' : 'text'" formControlName="newpassword"  pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#\$&*~])(?=.*[A-Z]).{8,20}"  class="form-control" name="password" placeholder="Enter your password">
									<div class="input-group-text" data-password="false">

										<mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
									</div>

								</div>
								<div class="extra" style="color: red;"  *ngIf=" formGroup.controls['newpassword'].touched && formGroup.controls['newpassword'].invalid" >
									One lower case letter.One UPPER case letter. <br>
									One Number.
									One special character (! @ ^ $ % or a space).<br>
									Minimum 8 characters in length (20 characters max).
								  </div> -->

							</div>


							<div class="mb-3" style="padding-top: 20px;">
								<mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
									<mat-label>Confirm Password</mat-label>
									<input matInput  [type]="hide2 ? 'password' : 'text'" formControlName="confirmpassword"  
									  class="form-control"  placeholder="" maxlength="30"
									  />
									<mat-icon matSuffix (click)="hide2 = !hide2">{{
									  hide2 ? "visibility_off" : "visibility"
									  }}</mat-icon>
									<mat-error class="con-error" *ngIf="formGroup.controls.confirmpassword.errors?.required">
										This field is required
									</mat-error>
								  </mat-form-field>


								<!-- <label for="password" class="form-label">Confirm Password</label>

								<div class="input-group input-group-merge">
									<input id="password-field"[type]="hide2 ? 'password' : 'text'" formControlName="confirmpassword"  class="form-control" name="password" placeholder="Enter your password">
									<div class="input-group-text" data-password="false">
										<mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
									</div>

								</div>
								<div class="extra" style="color: red;"  *ngIf=" issubmit && formGroup.controls['confirmpassword'].invalid" >
									{{msgEM1}}
								  </div> -->

							</div>



							<div class="mb-3 mb-0 text-center">
								<button class="btn btn-primary mx-2 my-2" type="submit" (click)="submit()">Submit  </button>
								<button class="btn btn-danger mx-2 my-2" type="submit" (click)="cancel()"> Cancel </button>
							</div>

						<!-- </form> -->
					</form>
				</div> <!-- end card-body -->
				</div>
				<!-- end card -->

			</div> <!-- end col -->
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>
<!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" >
<div class="container" fxFlex="40">
	<h2 style="text-align: center;">Reset Password</h2>
	<form
	  [formGroup]="formGroup"
	  class="form"
	>
	  <mat-form-field floatLabel="never" class="form-element">
		<input
         [type]="hide1 ? 'password' : 'text'"
		  matInput
          pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#\$&*~])(?=.*[A-Z]).{8,20}"
		  placeholder="New Password"
		  formControlName="newpassword"
		/>
        <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
		<mat-error *ngIf="formGroup.controls['newpassword'].invalid">
			One lower case letter.One UPPER case letter. <br>
			One Number.
			One special character (! @ ^ $ % or a space).<br>
			Minimum 8 characters in length (20 characters max).
		</mat-error>


	  </mat-form-field>

	  <mat-form-field floatLabel="never" class="form-element">
		<input
        [type]="hide2 ? 'password' : 'text'"
		  matInput
		  placeholder="Confirm Password"
		  formControlName="confirmpassword"
		/>
        <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
		<mat-error *ngIf="formGroup.controls['confirmpassword'].invalid">
		  This field is required
		</mat-error>
	  </mat-form-field>

	  <div class="form-element">
		<button
		  mat-raised-button
		  color="primary"
		  type="submit"
		  class="button"
		  [disabled]="formGroup.invalid"
		  (click)="submit()"
		>
		  Submit
		</button>
        <button
		  mat-raised-button
		  color="primary"
		  type="Clear"
		  class="button"
		  (click)="cancel()"
		>
		  Cancel
		</button>
	  </div>
	</form>
  </div>
  </div> -->
