import { Component, OnInit ,Inject, LOCALE_ID } from '@angular/core';
import { ChangeDetectorRef, ViewChild,ElementRef,Renderer2 } from '@angular/core';
import { CalendarOptions, EventInput, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
//import { WebcamImage, WebcamInitError, WebcamComponent as NgxWebcamComponent } from 'ngx-webcam';
import { MediaMatcher } from '@angular/cdk/layout';
import { NgxSpinnerService } from 'ngx-spinner';
//import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
//import { RequestData } from 'src/app/modules/attendance/models/Request';
import { MapInfoWindow, MapMarker,GoogleMap } from '@angular/google-maps';
import { AbstractControl,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {CrmService} from "../../crm.service";
import { Subscription, interval,of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {WebcamModalComponent} from "../../../../pages/webcam-modal/webcam-modal.component";
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { environment } from 'src/environments/environment';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { LeavesService } from '../../../leaves/leaves.service';
import {CrmAddClientComponent } from "../crm-add-client/crm-add-client.component";
import CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import {MapService} from "src/app/modules/crm/map.service"; 
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-crm-user-dashboard',
  templateUrl: './crm-user-dashboard.component.html',
  styleUrls: ['./crm-user-dashboard.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmUserDashboardComponent implements OnInit {
  mapUpdate!: Subscription;
  isUpdateMap:any = true;
 @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
 @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap | undefined;
 @ViewChild('videoElement')
  videoElement!: ElementRef;
 @ViewChild('canvasElement')
  canvasElement!: ElementRef;
  cols:any = [];
  encriptPipe= new EncryptPipe();
  imageDataUrl:any = {imageData:null,notes:null};
  displayVideo:any = true;
  downloadCapture:any = true;
  stopCamera:any = true;
  CountryDetails:any=[];
  stateDetails:any=[];
  cityDetails:any=[];
  attd_detail_id:any;
 infoContent :any;
 leaveApplied:any =false;
 attendanceid: any;
  markerPositions: any=[]; 
  employeemarkers:any=[];
  locationTracked:any= false;
  activitiesData:any = [];
  attd_trackdata:any = [] ;
  selectedEmployee:any =0;
  show_callender:any = false;
 //  center: google.maps.LatLngLiteral | undefined;
 //center:any={lat :17.457183152970547,lng: 78.37056237270387};
 center:any={lat :0,lng: 0};
 selected_location:any= {lat : 0,lng: 0};
  mapOptions: google.maps.MapOptions = {
    zoom: 12,
  };
  locationButton:any = document.createElement("button");
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  map:any;
   
  TODAY_STR = new Date().toISOString().replace(/T.*$/, '');
  todayDate: any;
  location:any = ''; 
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    }, customButtons: {
      next: {
        click: this.nextMonth.bind(this),
      },
      prev: {
        click: this.prevMonth.bind(this),
      },

      // today: {

      //     click: this.currentMonth.bind(this),
      // },
    },
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height:'auto'
  };
  isview:boolean=true;
  pipe:any;
  userSession:any = JSON.parse(sessionStorage.getItem('user') ?? '');;
  calendarApi: any;
  notificationsData: any = [];
  tobevisited:any = [];
  sampleElement: any;
  companyinfo:any=[];
  shiftData: any;
  isNewclient: boolean = false;
  clientForm: any;
  companyForm:any;
  companyDBName: any = environment.dbName;
  markerPosition:any ={};
  loginToken: any;
  clientStatusVal:any =  {};
  demoStatusVal:any =  {};
  employeeEmailData:any;
  attendanceData:any;
  trackdata:any;
  file:any;
  logoId:any=null;
  imageInfo:any=null;
  formData: FormData = new FormData();
  followups:any =[];
  punchIn: any = null;
  punchOut: any = null;
  initialEvents:any = [];
  currentDate = new Date();
  minDate = new Date('2020/01/01'); maxDate = new Date();
  workTypeData:any = [];
  workType:any=[];
  shiftId:any;
  formattedDate:any;
  formattedTime:any;
  timeStampVal:any;
  isRemoveImage:boolean=true;
  fetchedAddress:any = '';
  markerSelected:any = null;
  selectedDate:any;
  stype:any = 'followup';
  showFollowUps:any = true;
  showActivites:any = true;
  geocoder: any;
  mobileQuery!: MediaQueryList;
  private _mobileQueryListener: () => void;
  deviceInfo:any;
  mapInitialized:any=false;
  isWebRestricted:any = 1;
  constructor(@Inject(LOCALE_ID) private locale: string,private mapService:MapService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,private FormBuilder:UntypedFormBuilder,
    private router: Router, private element: ElementRef,private crm:CrmService,public  dialog: MatDialog,
    private renderer: Renderer2,private spinner: NgxSpinnerService,private company:CompanySettingService,
    private attendanceService:AttendanceService,private emsService:EmsService, private LMS:LeavesService,
    public sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef,private httpClient:HttpClient,
     ) {
      this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
      this.shiftData = this.userSession.shift_json && this.userSession.shift_json[0]?this.userSession.shift_json[0]:null;
      if (this.userSession.isCrmWebDisabled !== undefined) {
        this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
      }
     if(!this.mapInitialized){
        this.mapService.initMap().then(() => {
          // Code to execute after the map has been initialized
          this.mapInitialized = true;
          this.geocoder = new google.maps.Geocoder();
          this.getCrmSelectedEmployeeTrackLocations(this.userSession.id);
        }).catch((error: any) => {
          // Handle any errors that occurred during map initialization
          console.error('Error initializing map:', error);
        });
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      this.geocoder = new google.maps.Geocoder();
      this.center ={lat : position.coords.latitude,lng: position.coords.longitude};
    },(error) => console.log(error));
    if(this.userSession.is_sales_manager){
      this.crm.setCrmEmployeeLocationRoutes({rm_id:this.userSession.id}).subscribe(async (info: any) => {
        this.spinner.hide();
        if(info && info.status){
        }
      })
    }
    this.pipe = new DatePipe(this.locale);
    this.getTrakckingDetails();
 
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
   this._mobileQueryListener = () => changeDetectorRef.detectChanges();
   this.mobileQuery.addListener(this._mobileQueryListener);
   this.deviceInfo = this.crm.getDeviceInfo();
  }

  ngOnInit(): void {    
    this.selectedDate = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.isCrmWebDisabled !== undefined) {
      this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
    }
    this.selectedEmployee = this.userSession.id;
    this.getWorkypeList();
    this.getEmployeeActivities();
   // this.getEmployeeShiftDetails();
    this.trackdata = null;
   
    this.getCountry();
    this.todayDate = this.pipe.transform(Date.now(), 'dd-MM-yyyy');
    this.getemployeeattendancedashboard();
     
    //this.getclientStatusVal();
    //this.getDemoStatusVal();
    this.loginToken = JSON.parse(JSON.stringify(sessionStorage.getItem('token') || ''));
    this.formattedDate = this.pipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.formattedTime = this.currentDate.toLocaleTimeString(this.locale, {hour12: false, hour: '2-digit',minute: '2-digit', second: '2-digit' });
    this.timeStampVal = this.currentDate.getTime();
    this.crm.getCrmFollowUps({empid:this.userSession.id,selected_date:(this.formattedDate)}).subscribe((results)=>{
      this.followups= results.data;
    })
    this.companyForm=this.FormBuilder.group({
        todayDate:["",Validators.required],
        companyname:["",Validators.required],
        contact_person_name: ["",Validators.required],
        contact:["",[Validators.required]],
        email:["",[Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        address1:["",Validators.required],
        address2:[""],
        city: ["",Validators.required],
        state: ["",Validators.required],
        pincode: ["",Validators.required],
        country: ["",Validators.required],
       // client_status:["",[Validators.required]],   
       // demo_status:["",[Validators.required]]
      });

      this.companyForm.get('country')?.valueChanges.subscribe((selectedValue: any) => {
        this.stateDetails= [];
        this.company.getStatesc(selectedValue).subscribe((data)=>{
          this.stateDetails=data.data;
          if(this.companyinfo != null) {
            this.companyForm.controls.state.setValue(this.companyinfo.stateid);
          }
        })
      })
    this.companyForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.companyForm.controls.city.setValue("");
      this.cityDetails = [];
       this.company.getCities(selectedValue).subscribe((data)=>{
          this.cityDetails=data.data
          if(this.companyinfo != null && selectedValue == this.companyinfo.stateid )
          {
            this.companyForm.controls.city.setValue(this.companyinfo.locationid);
          }
      // this.availablecities=data
        })
      })
      // this.mapUpdate = interval(10000).subscribe((p:any) =>{  
      //     this.setTracking();
      // });
  }
  setTracking(){     
    navigator.geolocation.getCurrentPosition( (position) => {             
      let info = {
        created_by: this.userSession.id,
        lat:position.coords.latitude,
        lng: position.coords.longitude,
        device: this.deviceInfo.deviceType+'-'+this.deviceInfo.os_version,
        device_type: JSON.stringify({Type:this.deviceInfo.deviceType,Name:this.deviceInfo.os_version,isMobile:false}),        
        created_on: this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }
      this.crm.setCrmTracking(info).subscribe(async (res: any) => {   
      });       
    },(error) => console.log(error));
  }
  
  ngOnDestroy(){
    this.isUpdateMap = false;
  }
 
  async  getTrakckingDetails() {
    this.spinner.show();
    this.markerPositions = [];
    let marks :any= [];
    
    let obj = {
      rmid:this.userSession.roles[0].rmid ?? '',      
      empid:this.userSession.id,
      tracking_type:'ALL',
      date_selected:this.pipe.transform(this.currentDate, 'yyyy-MM-dd')
    }
     
    this.crm.getCrmAttendanceTrackingDetails(obj).subscribe(async (info: any) => {
      this.spinner.hide();
      if (info.status && info.data.length != 0) {
        this.trackdata = info.data;
        this.trackdata.forEach(async (e: any) => {
          
          //let address = await this.crm.reverseGeocode(Number(e.lat), Number(e.lng));
        })
        }
    })
     
  }

  
  async openWebcamModal() {
    this.imageDataUrl = {imageData:null,notes:null};
    const dialogRef = this.dialog.open(WebcamModalComponent, {
       width: '350px',// width: '400px', // adjust the width as needed
       data:{notesRequired:true,location:this.location},
       disableClose: true
    });
    await dialogRef.afterClosed().subscribe(result => {
     // 'result' will contain the captured photo data (data URL)
      this.imageDataUrl = result;
    });
  }


  takePicture(){
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
                    this.videoElement.nativeElement.srcObject = stream;
          setTimeout( () => { 
            const video = this.videoElement.nativeElement;
            const canvas = this.canvasElement.nativeElement;
            const context = canvas.getContext('2d');
            canvas.width = video.videoWidth;   // Set the canvas size to match the video feed
            canvas.height = video.videoHeight;  // Set the canvas size to match the video feed
            context.drawImage(video, 0, 0, canvas.width, canvas.height);  // Draw the current frame from the video onto the canvas
            this.imageDataUrl = canvas.toDataURL('image/png');  // Get the image data from the canvas as a data URL

            if(this.downloadCapture){
                const link = document.createElement('a');  // Create a temporary link element
                link.href = this.imageDataUrl;
                link.download = 'captured_photo-user@'+this.userSession.id+'-'+this.currentDate+'.png';
                document.body.appendChild(link);   // Append the link to the document
                link.click(); // Trigger a click on the link to initiate the download
                document.body.removeChild(link); // Remove the link from the document
            }
            if(this.stopCamera){
                const stream = video.srcObject as MediaStream;
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());   // Stop the webcam stream
                video.srcObject = null;
            }
           }, 1250);
        })
        .catch((error) => {
          console.error('Error accessing webcam:', error);
        });
  }
 
 
 openInfo(marker: MapMarker, content: any) {  
  this.infoContent = content;  
  this.infoWindow? this.infoWindow.open(marker):null; 
}
  async selectAddClient() {
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied.' });
      return;
    }
    this.imageDataUrl = {imageData:null,notes:null};
    //this.isNewclient = true;
    const dialogRef = this.dialog.open(CrmAddClientComponent, { width: '1250px',height:'450px',disableClose: true });
    await dialogRef.afterClosed().subscribe(result => {
      this.imageDataUrl = result;
    });
  }

  Cancel(){
    this.imageDataUrl = {imageData:null,notes:null};
    this.isNewclient = false;
  }
   
  submit(){
  this.saveNewClient();
}
  saveNewClient(){
    this.getClientLocationFromMap();
    let companyinformation ={
      client_id:0,
      companyname:this.companyForm.controls.companyname.value,
      contact_person_name:this.companyForm.controls.contact_person_name.value,
      contact:this.companyForm.controls.contact.value,
       email:this.companyForm.controls.email.value,
      address1:this.companyForm.controls.address1.value,
      address2:this.companyForm.controls.address2.value?this.companyForm.controls.address2.value:'',
      country:this.companyForm.controls.country.value,
      state:this.companyForm.controls.state.value,
      city:this.companyForm.controls.city.value,
      pincode:this.companyForm.controls.pincode.value,
      createdby:this.userSession.id,
      latitude:this.selected_location.lat,   
      longitude:this.selected_location.lng,
      
      notes: this.imageDataUrl.notes??'',
      created_date:this.formattedDate+' '+this.formattedTime,
    }
    
     let resMessage = 'Data not saved successfully';
      this.crm.setCrmClientInformation(companyinformation).subscribe((data:any) => {
        if (data && data.status) {
          let client_id = data.data[0].client_id;
          resMessage = 'Data saved successfully';
          if(this.imageDataUrl.imageData ){
            this.crm.getFilepathsMaster(12).subscribe((result) => {
              if(result && result.status){
                let obj = {
                  'id':null,
                  'employeeId':this.userSession.id,
                  'filecategory': 'CRM_CLIENT',
                  'moduleId':12,
                  'documentnumber':'',
                  'fileName':companyinformation.companyname.toString().replace(' ','')+this.formattedDate+'-'+this.timeStampVal+'.png',
                  'modulecode':result.data[0].module_code,
                  'requestId': client_id??0,
                  'status':'Submitted'
                }
                this.crm.setCrmFilesMaster(obj).subscribe((data) => {
                   
                  if(data && data.status) {
                   // this.formData.append("info",JSON.stringify(data.data[0]))
                  //  this.file = {
                  //   filename: data.data[0].filename,
                  //   size:blob.size.toString(),
                  //   encoding:  'base64'; // Encoding used in the data URL
                  //   mimeType:'image/png'; // MIME type of the image  
                  //   md5:  this.calculateMD5(this.imageDataUrl.imageData); // Calculate MD5 hash (you may need a library for this)
                  // }
                  const formDt: FormData = new FormData();   // Create FormData to send as a payload // const formData = new FormData();
                 
                  const blob = this.dataURLtoBlob(this.imageDataUrl.imageData);   
                  formDt.append('info', JSON.stringify(data.data[0]));
                  formDt.append('file', blob, data.data[0].filename);
                    this.crm.setCapturedImageForTracking(formDt).subscribe((result) => {
                            this.spinner.hide();
                            if(result && result.status)
                             resMessage  = resMessage+'- uploaded.'
                            else  resMessage  = resMessage+'- not uploaded.'
                        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        //this.router.navigate(["/Admin/CompanyLogo"]));
                    });
                  }else  resMessage  = resMessage+'- file settings not performed.'
               })
            }else  resMessage  = resMessage+'- file path not found.'      
            })

          }
        }  
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position:{top:`70px`}, disableClose: true,data:resMessage
        });
      })
  }

  getCountry(){
    this.crm.getCountry(null,1,10).subscribe((results)=>{
      this.CountryDetails=results.data;
    })
  }

  getDemoStatusVal(){
    this.crm.getCrmActivityStatusValues().subscribe((res:any)=>{
      if(res.status) {
        this.demoStatusVal =  res.data;
      }
      else {
        this.clientStatusVal = [];
      }
    })
  }
  
  getclientStatusVal(){
    this.crm.getCrmClientStatusValues().subscribe((res:any)=>{
      if(res.status) {
        this.clientStatusVal =  res.data;
      }
      else {
        this.clientStatusVal = [];
      }
    })
  }
  addClient() {
      this.isNewclient = true;
  }


getWorkypeList() {
  this.workTypeData = [];
  this.attendanceService.getWorkypeList('attendancetypesmaster', 'active', 1, 100, this.companyDBName).subscribe((info: any) => {
    if (info.status && info.data.length != 0) {
      this.workTypeData = info.data;
      this.workTypeData.forEach((e: any) => {
        if (e.type.toLowerCase().toString() === 'on-duty') {
          this.workType= e;
        }
      })
    }
  })
}
 

onMapClick(event: google.maps.MapMouseEvent) {
  // Handle map clicks if needed
}
 
  removeData(){

  }
  async getemployeeattendancedashboard() {
    this.spinner.show();
   let data = {
      'manager_id': null,
      'employee_id': this.userSession.id,
      'date':this.selectedDate
      //'date': this.pipe.transform(new Date(), 'yyyy-MM-dd')
    }
      this.attendanceService.getemployeeattendancedashboard(data).subscribe((res: any) => {
      if (res.status) {
        this.attendanceData = res.data;
                this.initialEvents=[];
        this.attendanceData.forEach((e: any) => {          
          let currentDate = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
          let selectDate = this.pipe.transform(e.attendancedate, 'yyyy-MM-dd');
          if (currentDate == selectDate) {
            if(e.present_or_absent=='L'){
              this.leaveApplied = true;
            }
            else {
              this.punchIn = this.pipe.transform(e.firstlogintime, 'shortTime');
              this.punchOut = this.pipe.transform(e.lastlogouttime, 'shortTime');
            }
            
          }
          let color;
          if(e.present_or_absent=='P'){
            color='#32cd32';
          }else if(e.present_or_absent=='W'){
            color='#2e0cf3';
          }else if(e.present_or_absent=='A'){
            color='#FF3131';
          } else if(e.present_or_absent=='H'){
            color='#800000'
          }else if(e.present_or_absent=='L'){
            color='#FF8C00';
          }else if(e.present_or_absent=='HD'){
            color='#CE06E4';
          } else if(e.present_or_absent=='WP'){
            color='#06c3e4';
          } else if(e.present_or_absent=='HP'){
            color='#06e471';
          }
          let item =
          {
            title: e.present_or_absent,
            start: e.attendancedate,
            color:color,      //e.isweekoff == null ? e.present_or_absent == 'P' ? '#32cd32' : '#FF3131' : '#2e0cf3',
            icon: e.present_or_absent == 'P' ? 'fa-check-circle' : 'fa-times-circle'
          }
          this.initialEvents.push(item);
        });
 this.calendarOptions.events = this.initialEvents;
 //  this.calendarCountViewNoMore();
 this.spinner.hide();
      }
    })
    await this.getClientLocationFromMap();
  }
  
  async getClientLocationFromMap(){
    setTimeout(async() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      this.selected_location ={lat : position.coords.latitude,lng: position.coords.longitude};
      let lat =  Number(this.selected_location.lat);
      let lng =  Number(this.selected_location.lng);
      await this.geocoder.geocode({ location: { lat,lng} }, (results:any, status:any) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          this.location = results[0].formatted_address;
        }  
      });
      // this.crm.fetchMapLocationDetails(this.selected_location ).subscribe((data) => {
      //   this.fetchedAddress = data.display_name;       
      // });
     // this.markerPositions.push({ lat: position.coords.latitude, lng: position.coords.longitude,description:'current place' });
    },(error) => console.log(error));
  }, 250);
  }
 
refreshPage(){
  this.getemployeeattendancedashboard();
  this.getTrakckingDetails();
  this.getCrmSelectedEmployeeTrackLocations(this.userSession.id);
  this.fetchedAddress = '';
}

async setPunchInandOut(type:any){
    if(!type){
      return;
    }
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied.' });
      return;
    }
    await this.getClientLocationFromMap();
  const dialogRef = this.dialog.open(WebcamModalComponent, {width: '350px',  disableClose: true , data:{notesRequired:true,location:this.location}});
  
  dialogRef.afterClosed().subscribe(async result => {  
    if(this.selected_location.lat && this.selected_location.lng ){
      this.locationTracked = true;
    }
    else {
      this.locationTracked = false;
      return;
    }
    let obj= {
      'rmid':this.userSession.roles[0].rmid ?? '',
      'empid':  this.userSession.id ?? '',
      'attendancetype' : this.workType.type,
      'attendancedate' : this.pipe.transform(new Date(), 'yyyy-MM-dd'),
      'punchtime' : this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      'punchcategory': type.toLowerCase(),
      'shift' :this.shiftData && this.shiftData.shiftname ? this.shiftData.shiftname:null,
      "lat":this.selected_location.lat,
      "lng":this.selected_location.lng,
      "notes": '',
      'battery_percent':'',
      "address": '',
      "device": this.deviceInfo.deviceType+'-'+this.deviceInfo.os_version,
      "device_type": JSON.stringify({Type:this.deviceInfo.deviceType,Name:this.deviceInfo.os_version,isMobile:false,location:this.location})
    }   

    if(result && result.imageData && result.notes && result.notes.toString().length ){
      this.imageDataUrl = result; 
      obj.notes = this.imageDataUrl.notes;
       
      const blob = this.dataURLtoBlob(this.imageDataUrl.imageData);   
      // const encoding = 'base64'; // Encoding used in the data URL
      // const mimeType = 'image/png'; // MIME type of the image  
      // const md5 = this.calculateMD5(this.imageDataUrl.imageData); // Calculate MD5 hash (you may need a library for this)
      let resMessage: any ='Punched ' + type.toLowerCase() + 'failed';
      this.crm.setCrmEmployeeAttendance(obj).subscribe((res: any) => {
            if(res && res.data && res.data[0] && res.data[0].successstate && res.data[0].successstate == 1){
              resMessage = res.data[0].resultmsg;
            }
            else if (res && res.status && res.data && res.data[0] && res.data[0].successstate == 0){
              this.refreshPage();
              this.attendanceid = res.data[0].attendance_id ?? 0;
              this.attd_detail_id = res.data[0].attd_detail_id ?? 0;
              resMessage = res.data[0].resultmsg;
              //resMessage ='Punched ' + type.toLowerCase() + ' successfully.'
              this.crm.getFilepathsMaster(12).subscribe((result) => {
                if(result && result.status){
                  let obj = {
                    'id':null,
                    'employeeId':this.userSession.id,
                    'filecategory': 'CRM_ATTENDANCE',
                    'moduleId':12,
                    'documentnumber':'',
                    'fileName':'punch'+type.toLowerCase()+'-'+this.formattedDate+'-'+this.formattedTime.replace(':','-').replace(':','-')+'.png',
                    'modulecode':result.data[0].module_code,
                    'requestId': this.attd_detail_id??0,
                    'status':'Submitted'
                  }
                  this.crm.setCrmFilesMaster(obj).subscribe((data) => {
                    
                    if(data && data.status) {
                      const formDt: FormData = new FormData();   // Create FormData to send as a payload // const formData = new FormData();
                      formDt.append('info', JSON.stringify(data.data[0]));
                      formDt.append('file', blob, data.data[0].filename);
                      
                      this.crm.setCapturedImageForTracking(formDt).subscribe((result) => {
                             this.refreshPage();
                              if(result && result.status)
                              resMessage  = resMessage+'- uploaded.'
                              else  resMessage  = resMessage+'- not uploaded.'
                          //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                          //this.router.navigate(["/Admin/CompanyLogo"]));
                      });
                    }else {
                        this.refreshPage();
                      resMessage  = resMessage+'- file settings not performed.'
                    }                     
                })
              }else {
                 this.refreshPage();
                resMessage  = resMessage+'- file path not found.'     
              }  
              })
              
            }   else {
	     this.refreshPage();
	     }
       this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose:true, data: resMessage });
      })
    }
  });
}

getEmployeeEmailData() {
  this.employeeEmailData = [];
  this.emsService.getEmployeeEmailDataByEmpid(this.userSession.id)
    .subscribe((res: any) => {
      this.employeeEmailData = JSON.parse(res.data[0].jsonvalu)[0];
    })
}

private calculateMD5(dataURL: string): string {
  const base64 = dataURL.split(',')[1]; // Extract base64 part
  const wordArray = CryptoJS.enc.Base64.parse(base64);
  const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
  return md5;
}

private dataURLtoBlob(dataURL: string): Blob {
  const arr = dataURL.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (mimeMatch) {
    const mimeType = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mimeType });
  }
  return new Blob();
}
selectClient(event:any,client:any){
  let clientId=this.encriptPipe.transform(client.client_id.toString());
  this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}])
}
checkClientDetails(client:any){ 
  this.followups.forEach((c:any)=>{ c.display = false; });
  client.display = !client.display;
}

getbackgroundColor(client:any) {
  const currentDate = new Date();
  if(client.next_follow_up_on)
    return (new Date(client.next_follow_up_on) > currentDate) ?  'aliceblue':'mistyrose' ;
  else 
    return (new Date(client.created_on) > currentDate) ?  'aliceblue':'mistyrose' ;
}
getFormattedDateTime(date:any = null){
  if(!date) 
  date = new Date();
  else date = new Date(date);
  return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
}


getMIcon(pinColor:any,svgtype:any = null){
  var hexcolors =[
     {Red: '#FF0000'},{ Green: '#00FF00'},{ Blue: '#0000FF'},{Yellow: '#FFFF00'}, {Orange: '#FFA500'}, {Purple: '#800080'}, {Pink: '#FFC0CB'},
     {Brown: '#A52A2A'}, {Gray: '#808080'},  {Black: '#000000'},{ White: '#FFFFFF'},{ Cyan: '#00FFFF'},{Magenta: '#FF00FF'},{Lime: '#00FF00'},
     {Salmon : '#FA8072'},  {Teal: '#008080'}];
 
   var hexColor ='#FF0000';
   for(var i=0;i<hexcolors.length;i++){
         var val =  JSON.parse(JSON.stringify(hexcolors[i]));
         for (const key in val) {
           if(key === pinColor)
               hexColor = val[key]
         }
   }
   svgtype =svgtype?svgtype: 'svghole';
   var svg =  "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z";
   var svghole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
   var originhole = new google.maps.Point(12,15);
   var svgfilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
   var labelOriginFilled =  new google.maps.Point(12,9);
   var symbolRectangle= "M -2,0 0,-2 2,0 0,2 z";
   var symbolCross=  "M -2,-2 2,2 M 2,-2 -2,2";
   var pinImage='http://chart.apis.google.com/chart?chst=d_map_pin_shadow';
   var PinImageColor= 'https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|'+'FE7569';
   var svgtypes = [{svghole:svghole}, {originhole:originhole}, {svgfilled:svgfilled},{svg:svg}, {Rectangle:symbolRectangle},{Cross:symbolCross}];
   var svg_selected = svghole;
 
   for(var i=0;i<svgtypes.length;i++){
       for (const [key, value] of Object.entries( svgtypes[i])) {
         if(key === svgtype) svg_selected = value;
       }
     }
 
   var markerImage = {   
       path: svg_selected,
       anchor: new google.maps.Point(12,17),
       fillOpacity: 0.6,
       fillColor:hexColor,
       strokeWeight: 2,
       strokeColor: "white",
       scale: 2,
       labelOrigin: labelOriginFilled,
       rotation: 0    
   };
 
   return markerImage;
  }
  getMLabel(pinLabel:any,pinColor:any= null){
    return{ text: pinLabel,color: pinColor?pinColor:'white',fontSize: "12px"};
  };

  getCrmSelectedEmployeeTrackLocations(selectedEmployee:any){
      this.markerPositions = [];
      let obj = {
        empid:selectedEmployee,  
        manager_id:this.userSession.roles[0].rmid??0,       
        date_selected:this.pipe.transform(this.currentDate, 'yyyy-MM-dd'),
        is_employee_selected:selectedEmployee?1:0      
      }
      this.spinner.show();
        this.crm.getCrmSelectedEmployeeTrackLocations(obj).subscribe(async (info: any) => {
           
          this.spinner.hide();
          if (info.status && info.data.length != 0) {
              this.trackdata = info.data;
              this.trackdata.sort((a:any, b:any) => a.id - b.id);
              for(var i=0;i<this.trackdata.length;i++){
                this.setMarkerInfoWindow(this.trackdata[i]);
                // if(selectedEmployee && this.trackdata[i+1]){
                //   // if(this.trackdata[i+1].trace_type != 'out'){
                //   //   this.calculateAndDisplayRoute({ lat: Number(this.trackdata[i].lat), lng: Number(this.trackdata[i].lng) },
                //   //         { lat: Number(this.trackdata[i+1].lat), lng: Number(this.trackdata[i+1].lng) });
                //   // }                 
                // }
                if(i == (this.trackdata.length - 1)){
                  this.center ={lat:Number(this.trackdata[i].lat), lng: Number(this.trackdata[i].lng)}; 
                }
              }
                       
            }
              
        })
  }
  
  setMarkerInfoWindow(e:any) {   
    let markerinfoSets = [];
    var infoCount=(e.lat && Number(e.lat) && e.lng && Number(e.lng) )?1:0;
    if(infoCount)
      infoCount = infoCount + ((e.activities && e.activities.length)?e.activities.length:0) + ((e.attendance && e.attendance.length)?e.attendance.length:0);
    if(infoCount === 1){
      markerinfoSets.push({
          empname:e.empname,
          time: this.getFormattedTime(e.created_on),
          mapActivity:'',
          checkImage:false,
          infoContent:'',
          displayImage:null,
          client:null,
          location:e.location,
          markerColor:e.markerColor,
          imageDisplayOnMap:Number(e.imageDisplayOnMap),
          display: true            
          //client:e.client_name
      });
    }
    else{
      if(e.activities && e.activities.length){
            e.activities.forEach((a:any)=>{
              if(a.activity_type === 'VISIT'){
                  a.markerPosition  = {
                    empname:a.empname?a.empname:e.empname,
                    time: this.getFormattedTime(a.created_on),
                    mapActivity:a.activity_type,
                    checkImage:!!(a.imageData && a.imageData.success),
                    infoContent:a.notes||'',
                    displayImage:(a.imageData && a.imageData.success) ? a.imageData.image: null,// (a.imageData && a.imageData.image && a.imageData.image.data) ? this.getImageBase64Format(a.imageData) : null,
                    client:a.client_name||'',
                    lat:a.lat?a.lat:e.lat,
                    lng:a.lng?a.lng:e.lng,
                    markerColor:a.markerColor,
                    imageDisplayOnMap:Number(e.imageDisplayOnMap),
                    location:e.location,
                    display: false
                }
                markerinfoSets.push(a.markerPosition);
              }
              else{
                a.markerPosition  = {
                  empname:a.empname?a.empname:e.empname,
                  time: this.getFormattedTime(a.created_on),
                 // mapActivity:a.activity_type,
                //  checkImage:!!(a.imageData && a.imageData.success),
                //  infoContent:a.notes||'',
                //  displayImage:(a.imageData && a.imageData.success) ? a.imageData.image: null,// (a.imageData && a.imageData.image && a.imageData.image.data) ? this.getImageBase64Format(a.imageData) : null,
                //  client:a.client_name||'',
                  lat:a.lat?a.lat:e.lat,
                  lng:a.lng?a.lng:e.lng,
                //  markerColor:a.markerColor,
                //  imageDisplayOnMap:Number(e.imageDisplayOnMap),
                  location:e.location,
                  display: false
              }
              markerinfoSets.push(a.markerPosition);
              }
              

            //this.activitiesData.push(a);
            });
      }
      if(e.attendance && e.attendance.length){        
        e.attendance.forEach((b:any)=>{
            b.markerPosition ={
                empname:b.empname?b.empname:e.empname,
                time: this.getFormattedTime(b.punchtime),
                mapActivity:b.attendance_type,
                checkImage:!!(b.imageData && b.imageData.success),
                infoContent:b.notes||'',
                displayImage:(b.imageData && b.imageData.success) ? b.imageData.image: null,
                client:null,
                location:e.location,
                markerColor:b.markerColor,
                imageDisplayOnMap:Number(e.imageDisplayOnMap),
                display: false
              }
            markerinfoSets.push(b.markerPosition);
            this.attd_trackdata.push(b);
        });
      }
      
    }

    this.markerPositions.push({ 
      lat:Number(e.lat), 
      lng: Number(e.lng),
      label:this.getMLabel(e.empname.charAt(0)),
      icon:this.getMIcon(e.markerColor,e.markerType),
    //  activityType:e.trace_type,
      empname:e.empname,
      location:e.location,
      description:'',
      id:e.id,
      empid:e.empid,
      type:e.trace_type,
      imageData:null,
      infoCount:infoCount,
      markerInfo:markerinfoSets,
      mouseOver:false
    });
  
  //this.infoWindow? this.infoWindow.open(marker):null; 
}

getFormattedTime(date:any = null){
  if(!date) 
  return ''; //date = new Date();
  else date = new Date(date);
  return date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
}

openInfoWindow(marker: MapMarker, markerPosition: any) {  
  this.markerSelected = markerPosition;
  if(this.markerSelected){
    this.markerSelected.mouseOver = true;
  } 
  this.infoWindow? this.infoWindow.open(marker):null; 
}


getMarkerClass(markerInfo:any){
  if(markerInfo.display){
    return 'mark-class-display';
  }
  else{
    return 'mark-class-normal';
  }
}

changeDisplayStatus(markerInfo:any){
  this.markerSelected.markerInfo.forEach((e:any)=>{e.display = false;})
  markerInfo.display = !markerInfo.display;
}

closeMouseOver(markerPosition:any){
  markerPosition.mouseOver = false;
  this.infoContent = null;
  this.markerSelected = null;
  this.infoWindow?.close();
}

enableCalender(){
  this.show_callender = !this.show_callender;
}
getLocationIconColorForActivity(activity:any){
  if(activity.lat && activity.lng){
    activity.location_icon_val = 'location_on';
  return 'green';
  }
  else{
    activity.location_icon_val = 'location_off';
    return  'red';
  }
}

fetchActivityLocationOnMap(activity:any){
  if(activity.lat && activity.lng){
    this.center ={lat : Number(activity.lat),lng: Number(activity.lng)};
  }
}
checkActivityDetails(activity:any){
  this.activitiesData.forEach((a:any)=>{
    if(a.requestid !== activity.requestid){
      a.display = false;
    }   
  });
    if(activity && activity.display){
      activity.display = false;
    }
    else  activity.display = true;
}


nextMonth(): void {
  this.calendarApi = this.calendarComponent.getApi();
  this.calendarApi.next();
  const selectDate = this.calendarApi.getDate();

  if (selectDate.getTime() <= this.currentDate.getTime()) {
    this.selectedDate = this.pipe.transform(selectDate, 'yyyy-MM-dd');
    this.getemployeeattendancedashboard();
   // this.getEmployeeAttendanceNotifications();
  } else {
    this.attendanceData = [];
    this.notificationsData = [];
    this.initialEvents = [];
    this.calendarOptions.events = this.initialEvents;
  }

}
prevMonth(): void {
  this.calendarApi = this.calendarComponent.getApi();
  this.calendarApi.prev();
  const selectDate = this.calendarApi.getDate();

  if (selectDate.getTime() <= this.currentDate.getTime()) {
    this.selectedDate = this.pipe.transform(selectDate, 'yyyy-MM-dd');
    this.getemployeeattendancedashboard();
   // this.getEmployeeAttendanceNotifications();
  } else {
    this.attendanceData = [];
    this.notificationsData = [];
    this.initialEvents = [];
    this.calendarOptions.events = this.initialEvents;
  }
}
currentMonth(): void {
  this.calendarApi = this.calendarComponent.getApi();
  this.calendarApi.today();
  const currentDate = this.calendarApi.getDate();
  this.selectedDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
  this.getemployeeattendancedashboard();
 // this.getEmployeeAttendanceNotifications();
}

changestype(st:any){
  this.stype = st;
 

}

showSpinner(){
  this.spinner.show();
  setTimeout(() => {
    this.spinner.hide();
  }, 5000);
}

getEmployeeActivities(){
  this.activitiesData = [];
 
  let params = {
    manager_id: this.userSession.roles[0].rmid??0,     
    empid: this.userSession.id, 
    client_id:0,
    date_selected:this.pipe.transform(new Date(), 'yyyy-MM-dd')
  }
  this.spinner.show();
  this.crm.getSprypleData(params,'get_crm_activities').subscribe(async (info: any) => {
    this.spinner.hide();
    if(info && info.status && info.data && info.data.length){
      this.activitiesData = info.data;
    }
  })
}
async geocodeAddress(coords:any) {
  if(!coords){
    await this.getClientLocationFromMap();
    coords = this.selected_location;
  }
    let lat =  Number(coords.lat);
    let lng =  Number(coords.lng);
    this.geocoder.geocode({ location: {lat,lng} }, (results:any, status:any) => {
      if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
        this.location = results[0].formatted_address;
      }  
    });
  }

}




