import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';


@Component({
  selector: 'app-department-designation-mapping',
  templateUrl: './department-designation-mapping.component.html',
  styleUrls: ['./department-designation-mapping.component.scss']
})
export class DepartmentDesignationMappingComponent implements OnInit {
  dataSource: MatTableDataSource<any> = <any>[];
  mappingForm: any = UntypedFormGroup;
  searchControl = new FormControl('');
  filteredDepartments: any = [];
  availableDepartments: any = [];
  companyDBName: any = environment.dbName;
  availableDesignations: any = [];
  filteredDesignations: any = [];
  searchControlfordesig = new FormControl('');
  displayedColumns = ['sno', 'department', 'designations'];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageLoading = true;
  userSession: any;
  designationIshide: boolean = false;
  designationIschecked: boolean = false;
  selectedDesignations: any = [];

  constructor(
    private companyService: CompanySettingService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.mappingForm = this.formBuilder.group({
      department: ['', Validators.required],
      designation: ['', Validators.required]
    });
    this.getDepartmentsMaster();
    this.getDesignationsMaster();
    this.getDepartDesigMapping();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterDepartments(searchText)
    });
    this.searchControlfordesig.valueChanges.subscribe(searchText => {
      this.filterDesignations(searchText)
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
  }
  getDepartmentsMaster() {
    this.companyService.getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDepartments = data.data;
        this.filteredDepartments = this.availableDepartments;
      }
    })
  }
  filterDepartments(searchText: any) {
    this.filteredDepartments = this.availableDepartments.filter((val: any) =>
      val.deptname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDepartments.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  getDesignationsMaster() {
    this.companyService.getMastertable('designationsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDesignations = data.data;
        this.filteredDesignations = this.availableDesignations;
      }
    })

  }
  filterDesignations(searchText: any) {
    this.filteredDesignations = this.availableDesignations.filter((val: any) =>
      val.designation.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDesignations.length <= 0) {
      this.searchControlfordesig.setValue('');
    }
  }
  resetDesignations() {
    this.mappingForm.controls.designation.setValue('')
  }
  setMapping() {
    if (this.mappingForm.valid) {
      let data = {
        department_id: this.mappingForm.controls.department.value,
        designation_ids: JSON.stringify(this.selectedDesignations),
      }

      this.adminService.setDepartDesignMapping(data).subscribe((result: any) => {
        if (result.status) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: result.message
          })

        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: result.message
          })
        }
      })
    }
  }
  cancel() {
    this.mappingForm.controls.designation.setValue('');
    this.mappingForm.controls.department.setValue('');
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getDepartDesigMapping() {
    this.adminService.getDepartDesigMapping(this.userSession).subscribe((info: any) => {
      if (info.status && info.data.length != 0) {
        this.dataSource = new MatTableDataSource(info.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })
  }
  departmentSelectAll(select: MatSelect, values: any, array: any) {

    this.designationIshide = true;
    this.designationIschecked = true;
    select.value = values;
    array = values;
    this.mappingForm.controls.designation.setValue(array)
    let designations = this.mappingForm.controls.designation.value;
    designations.forEach((e: any) => {
      this.selectedDesignations.push(e.id);
    });
  }
  departmentDeselectAll(select: MatSelect) {
     this.designationIshide = false;
    this.designationIschecked = false
    select.value = [];
    this.mappingForm.controls.designation.setValue('');
    this.selectedDesignations = [];
  }
  onchangedepartment(res: any) {
    this.selectedDesignations = [];
    let designation = this.mappingForm.controls.designation.value;
    designation.forEach((e: any) => {
      this.selectedDesignations.push(e.id);
    });
  }
}
