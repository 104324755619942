import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import * as _moment from 'moment';

import { ReportsService } from '../../reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { MatDialog } from '@angular/material/dialog';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-admin-attendance-summary-report',
  templateUrl: './admin-attendance-summary-report.component.html',
  styleUrls: ['./admin-attendance-summary-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class AdminAttendanceSummaryReportComponent implements OnInit {

  SummaryReportForm: any = UntypedFormGroup;
  locationIshide: boolean = false;
  worklocationDetails: any[] = [];
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedManagers: any = [];
  managersDetails: any[] = [];
  userSession: any;
  employeelist: any;
  date = new Date();
  selectedLocations: any = [];
  searchControlforLoc = new FormControl('');
  searchControlforRM = new FormControl('');
  filteredLocations: any = [];
  filteredManagers: any = [];


  companyName: any;
  filter = new UntypedFormControl();
  obj: any;
  dateDayArray: any = [];
  List: any[] = [
  ];
  headersList: any = [];
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'empname', 'attendancedate', 'firstlogintime',
    'lastlogouttime', 'totalhours', 'productivehours', 'ot', 'ph', 'action'];
  isLoading = false;
  pageLoading = true;
  minDate = new Date('1950/01/01');
  maxDate = new Date();
  minToDate: any;



  @ViewChild('table') table!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  @ViewChild(MatSort)
  sort!: MatSort;


  constructor(
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    public reportsService: ReportsService,
    private companyService: CompanySettingService,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
  ) {
    this.companyName = sessionStorage.getItem("companyName");
  }

  ngOnInit(): void {

    this.SummaryReportForm = this.formBuilder.group(
      {
        companylocation: [[], Validators.required],
        managers: ["", Validators.required],
        fromDate: [new Date()],
        toDate: [new Date()],
      });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getWorkLocation();
    this.searchControlforLoc.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });
    this.searchControlforRM.valueChanges.subscribe(searchText => {
      this.filterRM(searchText);
    });
  }


  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.SummaryReportForm.controls.companylocation.setValue(array)
    let locations = this.SummaryReportForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.SummaryReportForm.controls.companylocation.setValue('')
    this.getManagersList()
  }

  onchangeLocation(value: any) {
    // this.getManagersList();
    // this.locationIshide = this.SummaryReportForm.controls.companylocation.value.length === this.worklocationDetails.length
    this.selectedLocations = [];
    let location = this.SummaryReportForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.SummaryReportForm.controls.managers.setValue(array)
    let managerdata = this.SummaryReportForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
    select.value = [];
    this.SummaryReportForm.controls.managers.setValue('')
    this.selectedManagers = [];
  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.SummaryReportForm.controls.managers.value;

    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
  }

  Searchform() {

    if (this.SummaryReportForm.valid) {
      this.List = [];
      this.dataSource = new MatTableDataSource(this.List);
      let fromDate = this.datePipe.transform(this.SummaryReportForm.controls.fromDate.value, "y-MM-d");
      let toDate = this.datePipe.transform(this.SummaryReportForm.controls.toDate.value, "y-MM-d");

      let data = {
        "manager_empid": JSON.stringify(this.selectedManagers),
        'fromdate': fromDate,
        'toDate': toDate,
        'location_id': JSON.stringify(this.selectedLocations),
      }

      this.isLoading = true;
      this.reportsService.getLocationWiseAttendanceSummaryReport(data).subscribe((res: any) => {
        this.List = res.data;
        this.List.forEach((e: any) => {
          if (e.breaks != null) {
            e.breaks = e.breaks.split(',')
          }
        })
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }, error => {
        this.isLoading = false;
        error.error.text
      });

    }

  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/admin-monthly-attendance-summary"]));
  }

  getWorkLocation() {
    this.managersDetails = [];
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }

  getManagersList() {
    this.managersDetails = [];
    let locationIds = this.SummaryReportForm.controls.companylocation.value.map((value: any) => {
      return value.id
    })
    let data = {
      location_id: JSON.stringify(locationIds),
      companyName: this.companyName
    }
    this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.managersDetails = res.data;
        this.filteredManagers = this.managersDetails;
      }
    })

  }

  public exportPDF(): void {
    let date = (this.datePipe.transform(this.SummaryReportForm.controls.fromDate.value, "MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: date + "-" + "Monthly Summary Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: date + " - " + "Monthly Summary Report \n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html,

      ], styles: {
        'html-table': {
          background: 'white' // it will add a yellow background to all <STRONG> elements
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A3'
      //'portrait'
    }).download("Monthly Summary Report.pdf");

  }
  exportAsXLSX() {
    let date = (this.datePipe.transform(this.SummaryReportForm.controls.fromDate.value, "MM-YYYY"))
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Monthly_Detail_Report');
    XLSX.writeFile(wb, date + '-' + 'Monthly_Detail_Report_for_superadmin.xlsx');

  }
  getColor(i: string): String {
    let color = ''
    if (i == "P") {
      return color = 'green'
    } else if (i == "H") {
      return color = '#800000';
    } else if (i == "W") {
      return color = 'blue';
    } else if (i == "L") {
      return color = 'orange';
    }
    else {
      return color = 'red';
    }
  }
  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      width: '1200px', position: { top: `70px` },
      data: { attendanceid: item.attendanceid }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLoc.setValue('');
    }
  }
  filterRM(searchText: any) {
    this.filteredManagers = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredManagers.length <= 0) {
      this.searchControlforRM.setValue('');
    }
  }

  downloadPDF() {
    let fromdate = (this.datePipe.transform(this.SummaryReportForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.SummaryReportForm.controls.toDate.value, "dd-MM-YYYY"))
    let data = {
      'list': this.List,
      'report_date': fromdate +' '+'_'+' '+todate
    }
    this.reportsService.getAttendanceSummaryReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'attendance_summary_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
