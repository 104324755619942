<div fxLayout="column" class="first-col">
    <mat-card fxFill style="outline: none;">
        <mat-card-header class="headerbox" style="padding: 1%;" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Future Shift Report</mat-card-title>
        </mat-card-header>

        <div fxLayout="row" style="margin: 1%;" fxLayout.lt-lg="column">

            <form [formGroup]="requestForm" fxFlex="60" fxLayoutAlign="start">
                <div fxLayout="row">
                    <div fxLayout="row" fxLayout.lt-lg="column">
                        <mat-form-field fxFlex fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>From Date</mat-label>
                            <input matInput readonly [min]="minFromDate" [max]="maxFromDate" [matDatepicker]="fromDate"
                                (dateInput)="fromDateChange('input', $event)" placeholder="DD-MM-YYYY"
                                formControlName="fromDate" (click)="fromDate.open()">
                            <mat-datepicker-toggle class="datepicker" matSuffix
                                [for]="fromDate"></mat-datepicker-toggle>
                            <mat-datepicker #fromDate></mat-datepicker>
                            <mat-error class="con-error" *ngIf="requestForm.controls.fromDate.errors?.required">
                                {{ requiredOption }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>To Date</mat-label>
                            <input matInput readonly [min]="minToDate" [max]="maxToDate" [matDatepicker]="toDate"
                                placeholder="DD-MM-YYYY" (dateInput)="toDateChange('input', $event)"
                                formControlName="toDate" (click)="toDate.open()">
                            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                            <mat-datepicker #toDate></mat-datepicker>
                            <mat-error class="con-error" *ngIf="requestForm.controls.toDate.errors?.required">
                                {{ requiredOption }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center"
                        style="margin: 10px 0px 0px 20px;">
                        <span>
                            <button class="btn btn-primary mr-2 mb-2" (click)="searchForm()" tabindex="3">
                                Search
                            </button>
                        </span>
                        <span>
                            <button class="btn btn-danger mr-2 mb-2" (click)="resetForm()" tabindex="4">
                                <span>Clear</span>
                            </button>
                        </span>
                    </div>
                </div>
            </form>


        </div>

        <div class="tablePadding" style="margin-top:10px;">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100" />
                    </mat-form-field>
                </span>
            </div>

            <div class="mat-elevation-z1 example-container">
                <table mat-table [dataSource]="dataSource" matSort class="table-container">
                    <!-- S.No Column -->
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let row; let i = index;">
                            <div style="margin: 5% 0;">{{ (i + 1) + (paginator.pageIndex * paginator.pageSize) }}</div>
                        </td>
                    </ng-container>

                    <!-- Employee Name Column -->
                    <ng-container matColumnDef="employee_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Name</th>
                        <td mat-cell *matCellDef="let row">
                            <div style="margin: 2% 0;">{{ row.employee_name }}</div>
                        </td>
                    </ng-container>

                    <!-- Shift Column -->
                    <ng-container matColumnDef="shift">
                        <th mat-header-cell *matHeaderCellDef>Shift</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngFor="let shift of element.shifts" style="margin: 5% 0;">
                                {{ shift.shift }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- From Date Column -->
                    <ng-container matColumnDef="from_date">
                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngFor="let shift of element.shifts" style="margin: 5% 0;">
                                {{ datePipe.transform(shift.from_date, 'dd-MM-yyyy') }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- To Date Column -->
                    <ng-container matColumnDef="to_date">
                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngFor="let shift of element.shifts" style="margin: 5% 0;">
                                {{ datePipe.transform(shift.to_date, 'dd-MM-yyyy') }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Week off Day Column -->
                    <ng-container matColumnDef="week_off">
                        <th mat-header-cell *matHeaderCellDef>Week off</th>
                        <td mat-cell *matCellDef="let row">
                            <div style="margin: 5% 0;">{{ row.week_off }}</div>
                        </td>
                    </ng-container>

                    <!-- Header and Row Definitions -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>
    </mat-card>
</div>