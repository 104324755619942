import { Component, OnInit,LOCALE_ID,Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ViewChild,ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from 'src/app/modules/reports/excel-service.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
// import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CrmService } from '../../crm.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
import { MatSelect } from '@angular/material/select';
import * as XLSX from 'xlsx';
import { CompanySettingService } from 'src/app/services/companysetting.service';
// import {default as _rollupMoment} from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { DataTransferService } from  "../../data-transfer.service";
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-summary-tracking-report',
  templateUrl: './summary-tracking-report.component.html',
  styleUrls: ['./summary-tracking-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class SummaryTrackingReportComponent implements OnInit {
  List: any[] = [

  ];
  @ViewChild('table') table!: ElementRef;
  pipe:any;
  employeelist: any;
  Users: any;
  minDate = new Date('1950/01/01');
  maxDate = new Date();
  minToDate: any;
  pageLoading=true;
  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  companyName:any;
  is_super_admin:any = false;
  //routeDistances:any = [];
  //travelModes:any =[];
  constructor(@Inject(LOCALE_ID) private locale: string,public reportsService: ReportsService, public datePipe: DatePipe, 
    public formBuilder: UntypedFormBuilder, private crm:CrmService,private spinner: NgxSpinnerService,private companyService: CompanySettingService,
    private dataTransfer:DataTransferService,private router: Router,// private mapService:MapService, 
    public dialog: MatDialog, private excelService: ExcelServiceService) {
      this.companyName = sessionStorage.getItem("companyName");
     }
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  userSession: any;
  monthdata:any;
  year:any;
  searchForm:  any = FormGroup;
  months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
  //searchForm = this.formBuilder.group({ fromDate: [new Date()], toDate: [new Date()], Users: ['0'] });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns:string[]=['sno','emp_name','date_selected','firtlogin','lastlogout','activity_total','route_type','distance_km','routes'];
  mapInitialized:any=false;
  directionsRenderer:any; //new google.maps.DirectionsRenderer();
  directionsService:any; // new google.maps.DirectionsService();
  directionsRenderOption:any ={};
  isLoading = false;
  ngOnInit(): void {
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      fromDate: [new Date()],
      toDate: [new Date()],
      Users: ['0']
      });
      this.pipe = new DatePipe(this.locale);
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.is_super_admin =="1"){
      this.is_super_admin = true;
      this.displayedColumns=['sno','emp_name','date_selected','firtlogin','lastlogout','activity_total','route_type','distance_km','recompute','routes'];
      this.getWorkLocation();
    }
    else {
      this.getEmployeelist();
    }
   this.searchForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
     if (selectedValue != null) {
       this.minToDate = selectedValue._d;    
     }
   });
   
 }
 getEmployeelist() {
  let obj={
   "rm_id":this.userSession.id,
  };
this.reportsService.getTotalEmployeslistByManagerId(obj).subscribe((res: any) => {
if (res.status) {
 this.employeelist = [];
 this.employeelist = res.data;
 this.searchForm.controls.Users.setValue('0');
}
});
}

getEmployeelistForSuperAdmin() {
  let obj={
   "rm_id":JSON.stringify(this.selectedManagers),
   "location_id" :JSON.stringify(this.selectedLocations),
  };
this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
if (res.status) {
 this.employeelist = [];
 this.employeelist = res.data;
 this.searchForm.controls.Users.setValue('0');
}

});
}

Searchform() {
  this.List = [];
  this.dataSource = new MatTableDataSource(this.List);
  let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
  let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-d");
  let userId = this.searchForm.controls.Users.value;
  if (userId == '0') {
    userId=null;
  }
  if(!this.is_super_admin){
    this.selectedManagers = [];
    this.selectedManagers.push(Number(this.userSession.id));
  }
  if(this.selectedManagers && this.selectedManagers.length){
    this.getCrmEmployeeTracesSummeryForSupeerAdmin({
      empid:userId,
      manager_id:JSON.stringify(this.selectedManagers),
      from_date: fromDate, 
      to_date :toDate
    });
  }
}

Searchform_old() {
  this.List = [];
  this.dataSource = new MatTableDataSource(this.List);
  let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-d");
  let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-d");
  let userId = this.searchForm.controls.Users.value;
  if (userId == '0') {
    userId=null;
  }
  if(this.is_super_admin){
    if(this.selectedManagers && this.selectedManagers.length){
      this.getCrmEmployeeTracesSummeryForSupeerAdmin({
        empid:userId,
        manager_id:JSON.stringify(this.selectedManagers),
        from_date: fromDate, 
        to_date :toDate
      });
    }
  }
  else {    
    this.getCrmEmployeeTracesSummery({
      empid:userId,
      manager_id:this.userSession.id,
      from_date: fromDate, 
      to_date :toDate
    });
  }
}

getCrmEmployeeTracesSummery(data:any){
  this.isLoading = true;
     this.spinner.show();
    this.crm.getCrmEmployeeTracesSummery(data).subscribe((res: any) => {
      this.spinner.hide();
      this.List=res.data;
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.List);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
    }, error => {
      this.isLoading = false;
      error.error.text
    });
}

getCrmEmployeeTracesSummeryForSupeerAdmin(data:any){
  this.isLoading = true;
  this.spinner.show();
 this.crm.getCrmEmployeeTracesSummeryForSupeerAdmin(data).subscribe((res: any) => {
   this.spinner.hide();
   this.List=res.data;
   this.isLoading = false;
   this.dataSource = new MatTableDataSource(this.List);
   this.dataSource.paginator = this.paginator;
   this.dataSource.sort = this.sort;
   this.pageLoading=false;
 }, error => {
   this.isLoading = false;
   error.error.text
 });
}


resetform() {
  this.dataSource.data = [];
  this.searchForm.reset();
  this.searchForm.controls.fromDate.setValue(new Date());
  this.searchForm.controls.toDate.setValue(new Date());
  this.searchForm.controls.Users.setValue('0');
  if(this.is_super_admin){
    this.DeselectAll();
  }
 
  this.Searchform();



}
openDialog(item:any): void {
  const dialogRef = this.dialog.open(DialogDetailComponent, {
    width: '1000px',position:{top:`70px`},
    data: {attendanceid:item.attendanceid ,}
  });

  dialogRef.afterClosed().subscribe(() => {  });
}

exportAsXLSX() {
  let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
  let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'employee activity Report');
  /* save to file */
  XLSX.writeFile(wb,fromdate+" - "+todate+" - "+ 'employee-activity-Report.xlsx');

}
getPageSizes(): number[] {
    
var customPageSizeArray = [];
if (this.dataSource.data.length > 5) {
  customPageSizeArray.push(5);
}
if (this.dataSource.data.length > 10) {
  customPageSizeArray.push(10);
}
if (this.dataSource.data.length > 20) {
  customPageSizeArray.push(20);
}
customPageSizeArray.push(this.dataSource.data.length);
return customPageSizeArray;
}

public exportPDF(): void {
  let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
  let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
  const pdfTable = this.table.nativeElement;
  var html = htmlToPdfmake(pdfTable.innerHTML);
  pdfMake.createPdf({
    info: {
      title:fromdate+" - "+todate+" - "+ "employee activity Report",
      author:'Sreeb tech',
      subject:'Theme',
          keywords:'Report'
    },
    footer: function (currentPage:any, pageCount:any) {
      return {
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              }
            ],
            alignment: 'center'
          }
        ]
      };
    },
    content: [ 
      {
        text: fromdate+" - "+ todate +" - "+"employee activity Report\n\n",
        style: 'header',
        alignment: 'center',
        fontSize: 14
      },
      // {
      //   text:
      //     "Designation :  " + this.designationForPdf +"\n" +
      //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
      //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
      //   fontSize: 10,
      //   margin: [0, 0, 0, 20],
      //   alignment: 'left'
      // },
      html,
      
    ],
    pageOrientation: 'landscape'//'portrait'
  }).download("employee activity Report.pdf");

}

getFormattedDateTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}
getFormattedTime(date:any = null){
  if(date && date.toString().length) {
    date = new Date(date);
    return  date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  else return '';
}

locationDeselectAll(select: MatSelect) { 
  select.value = [];
  this.DeselectAll();
}

DeselectAll(){
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  this.searchForm.controls.companylocation.setValue('');   
  this.mgrDeselectAll();
}

onchangeManager(value:any) {
  this.selectedManagers=[];
    let mgr = this.searchForm.controls.managers.value;
    mgr.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeelistForSuperAdmin();
}

managerDeselectAll(select: MatSelect) {
  select.value = [];
  this.mgrDeselectAll();
}

mgrDeselectAll(){
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];  
  //this.searchForm.controls.managers.setValue('')
  this.employeelist = [];
  //this.managersDetails =[];
  this.dataSource.data = [];
}

managerSelectAll(select: MatSelect, values: any, array: any) {
  this.mgrIshide = true;
this.mgrIschecked = true;
select.value = values;
array = values;
this.searchForm.controls.managers.setValue(array)
let managerdata = this.searchForm.controls.managers.value;
managerdata.forEach((e: any) => {
 this.selectedManagers.push(Number(e.manager_emp_id));
});
this.getEmployeelistForSuperAdmin();
}

onchangeLocation(value:any) {
  this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
 this.getManagersList();
}
locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getManagersList() {
  this.managersDetails =[];
  let data={
    location_id :JSON.stringify(this.selectedLocations),
    companyName : this.companyName
  }
  this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
      this.managersDetails = res.data;
     }
  })
}
getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
    this.worklocationDetails = result.data;
  })
}

 /**Search functionality */
 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

getDistance_km(distance:any){
  if(distance)
    return  isNaN(distance) ? 0 : parseFloat(distance);
  else return 0;
}

recalculate(row:any){
  let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
    position: { top: `70px` },  disableClose: true,
    data: { message: "Are you sure you want to recompute ?", YES: 'YES', NO: 'No' }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result == 'YES') {
      let data ={
        empid:row.empid,
        date_selected:row.date_selected,
        re_calculate:0,
        route_type:''
      }
      this.spinner.show();
      this.crm.setCrmSelectedEmployeeLocationRoutes(data).subscribe((res: any) => {
        this.spinner.hide();
          if (res.status) {
            this.Searchform();
          }
      });
    }
  });
}

// exportAsXLSX() {
//   let edata: any = [];
//   let i = 1;
//   this.dataSource.data.map(a => {
//     let e: any = {};
//     e['Sno'] = i++;
//     e['Employee Name'] = a.empname;
//     e['Attendance Date'] = a.attendancedate;
//     e['First In'] = a.firstlogintime;
//     e['Last Out'] = a.lastlogouttime;
//     e['Total Hours'] = a.totalhours;
//     e['breaks'] = a.breaks;
//     e['Break Time'] = a.breaktime;
//     e['Production Hours'] = a.productivehours;
//     edata.push(e);
//   })
//   this.excelService.exportAsExcelFile(edata, '');
// }
 
  transform(value: string){
      return value.toLowerCase() // Convert to lowercase to ensure consistent capitalization
        .split('_')    // Split by underscore
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
        .join(' '); 
  }

  displayRoutes(row:any){
     this.dataTransfer.setData({user:[{empid:row.empid,empname:row.emp_name}],  date_selected:row.date_selected, is_super_admin: this.is_super_admin});
     this.router.navigate(["/Crm/CrmDetailedTrackingReport"])
  }
}


  
  


