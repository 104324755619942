import { Component, OnInit,ViewChild,Inject,ElementRef, LOCALE_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {CrmService} from "../../crm.service";
import {UntypedFormGroup,UntypedFormBuilder,  Validators,UntypedFormControl  } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { DecryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MapInfoWindow, MapMarker,GoogleMap } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import {WebcamModalComponent} from "../../../../pages/webcam-modal/webcam-modal.component";
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeavesService } from '../../../leaves/leaves.service';
import {CrmAddClientComponent } from "../crm-add-client/crm-add-client.component";
import {CrmAddTransactionComponent } from "../crm-add-transaction/crm-add-transaction.component";
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { DataTransferService } from  "../../data-transfer.service";
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-crm-client-edit-and-update',
  templateUrl: './crm-client-edit-and-update.component.html',
  styleUrls: ['./crm-client-edit-and-update.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmClientEditAndUpdateComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap | undefined;
  @ViewChild('videoElement')
  videoElement!: ElementRef;
  @ViewChild('canvasElement')
  canvasElement!: ElementRef;
  currentTime:any ;
  stopCamera:any = true;
  DateFilter:any;
  minDate:any;
  maxDate:any;
  MaxDate:any;
  isUpdate: boolean = false;
  hireForm: any;
  imageurls:any =[];
  editClientForm:any;
  addPayment:any;
  userSession: any;
  stateDetails:any=[];
  cityDetails:any=[];
  clientId:any;
  CountryDetails:any =[];
  localdata:any;
  formattedDate:any;
  formattedTime:any;
  timeStampVal:any;
  isEditClientEnabled:boolean  = false;
  addActivity: any = UntypedFormGroup;
  addActivityScreen: any = false;
  decryptPipe = new DecryptPipe();
  activityTypeList:any = [];
  activityStatusList:any = [];
  clientStatusList:any = [];
  selected_location:any= {lat : 0,lng: 0};
  imageDataUrl = {imageData:null,notes:null};
  activityImageDataUrl = {imageData:null,notes:null};
  selectedActivityType:any = '';
  activitiesData:any =[];
  activitiesTotals:any =[];
  imageInfo:any;
  isWebRestricted:any = 1;
  deviceInfo:any;
  firstActivityCoords:any = null;
  showFinalPrice:any = false;
  showActivitesData:any = true;
  showPayments:any = true;
  paymentsData:any = [];
  paymentModes:any =[];
  collectedTotal:any = 0;
  addPaymentScreen:any = false;
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading=true;
  @ViewChild(MatTable) tableOne: MatTable<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  filter = new UntypedFormControl();
  encriptPipe= new EncryptPipe();
  paymentInProcess:any = false;
  displayedColumns:string[]=['sno','created_on','mode','collected_amount','pending_amount','created_by'];
  totalVisits:any =0;
  constructor(@Inject(LOCALE_ID) private locale: string,private LMS:LeavesService, private router: Router,private dataTransfer:DataTransferService,
    private activeRoute:ActivatedRoute, private company:CompanySettingService,private pipe: DatePipe, private FormBuilder:UntypedFormBuilder, 
     private spinner: NgxSpinnerService, private dialog: MatDialog, private crm:CrmService
   ) {
      this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
      if (this.userSession.isCrmWebDisabled !== undefined) {
        this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
      }
       this.editClientForm= this.FormBuilder.group({
            client_id:[{value:''},Validators.required],
           
            companyname:[{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            contactpersonname: [{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            contact:[{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            email:[{value:'',disabled: !this.isEditClientEnabled},[Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
            address1:[{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            address2:[{value:'',disabled: !this.isEditClientEnabled}],
            city: [{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            state: [{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            pincode: [{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            country: [{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            notes:[{value:'',disabled: !this.isEditClientEnabled},Validators.required],
            latitude:[{value:'',disabled: !this.isEditClientEnabled}],
            longitude:[{value:'',disabled:!this.isEditClientEnabled}],
            //client_status:[this.localdata.client_status],   
            //demo_status:["",[Validators.required]]
            });
    }

  ngOnInit(): void {
    
    this.collectedTotal = 0;
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.isCrmWebDisabled !== undefined) {
      this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
    }
    this.deviceInfo = this.crm.getDeviceInfo();
    this.clientId = this.activeRoute.snapshot.params && this.activeRoute.snapshot.params.clientId?this.decryptPipe.transform(this.activeRoute.snapshot.params.clientId):0;
    // let dataSubscription = this.dataTransfer.data$.subscribe(data => {
    //   this.clientId = data && data.clientId? data.clientId:0;
    // });
    this.minDate = new Date();
    this.getActivityTypeList();
    this.getPaymentModes();
    
    this.getActivityStatusList();
    this.getClientStatusList();
    //this.getClientLocationFromMap();
    //this.getActivitiesWithTrackingDetails();
    this.formattedDate = this.pipe.transform(this.minDate, 'yyyy-MM-dd');
    this.formattedTime = this.minDate.toLocaleTimeString(undefined, {hour12: false, hour: '2-digit',minute: '2-digit', second: '2-digit' });
    //this.formattedTime = this.minDate.toLocaleTimeString(undefined, {hour12: false, hour: '2-digit',minute: '2-digit', second: '2-digit' });    
    this.timeStampVal = this.minDate.getTime();
    this.pipe = new DatePipe(this.locale);
     
    
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.currentTime = this.formatTime(new Date());
   

    this.addPaymentInitilization();

    if(this.clientId && this.userSession.id){
      this.getClientData();
    }

    this.editClientForm.get('country')?.valueChanges.subscribe((selectedValue: any) => {
      this.stateDetails= [];
      this.company.getStatesc(selectedValue).subscribe((data)=>{
        this.stateDetails = data.data;
      })
    });
    this.editClientForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.cityDetails = [];
       this.company.getCities(selectedValue).subscribe((data)=>{
            this.cityDetails=data.data
        })
      })

      if(this.activeRoute.snapshot.params && this.activeRoute.snapshot.params.type && this.activeRoute.snapshot.params.type == 'pay'){
        this.addScreen('pay');
      }
  }

  addPaymentInitilization(){
    this.addPayment = this.FormBuilder.group({
      collectedDate: [{value:this.formattedDate,disabled:true},Validators.required],
      collectedAmount:[{value:'',disabled:false},[Validators.required, Validators.min(0.01)]],
      modeId:[{value:null,disabled:false},Validators.required],
      paymentNotes:[{value:'',disabled:false},Validators.required],
    });
  }
  enableEditClient(){
    this.isEditClientEnabled = true;

    if(this.isEditClientEnabled){
      this.editClientForm.get('companyname')?.enable();
      this.editClientForm.get('contactpersonname')?.enable();
      this.editClientForm.get('contact')?.enable();
      this.editClientForm.get('email')?.enable();
      this.editClientForm.get('address1')?.enable();
      this.editClientForm.get('address2')?.enable();
      this.editClientForm.get('city')?.enable();
      this.editClientForm.get('state')?.enable();
      this.editClientForm.get('country')?.enable();
      this.editClientForm.get('notes')?.enable();
      this.editClientForm.get('pincode')?.enable();
      this.editClientForm.get('latitude')?.enable();
      this.editClientForm.get('longitude')?.enable();
    }

  }

  getActivitiesWithTrackingDetails(){
    this.activitiesData =[];
      this.crm.getCrmActivitiesWithTrackingDetails({manager_id:this.userSession.id,empid:this.userSession.id,client_id:this.clientId}).subscribe((results:any)=>{
        this.activitiesData = results.data;
        this.activitiesTotals = results.activity_counts && results.activity_counts.length ? results.activity_counts:[];
        if(this.activitiesData && this.activitiesData.length){
          this.firstActivityCoords = {
            lat: this.activitiesData[this.activitiesData.length-1].lat,
            lng: this.activitiesData[this.activitiesData.length-1].lng,
          };
        }
        
        this.activitiesData.forEach((e:any)=>{
          e.location_icon_val = 'location_off';
          e.location_icon_color = 'lightcoral';
          if(e.lat && e.lng){
            e.location_icon_val = 'location_on';
            e.location_icon_color = 'red';
          }
          if(e.activity_type == 'VISIT'){
            this.totalVisits = this.totalVisits+1;
          }
          this.getLocationIconColor(e);
          //this.getImageData(e.id,e.created_by);
        });
       
    })
  }

  getPaymentsData(){
     this.paymentsData =[];
      let params ={
        'trans_id':0,
        'client_id': this.clientId,
        'product_id': 0,
        'mode_id': 0,
        'from_date': null,
        'to_date': null,
        'created_by': 0
      }
       
      this.crm.getCrmClientsPaymentsData(params).subscribe((results:any)=>{
        if(results.status){         
          this.paymentsData = results.data;
          let collected_total =  this.collectedTotal;
          this.paymentsData.forEach((p:any)=>{
            p.pending_amount = this.localdata.final_price - collected_total;
            collected_total = collected_total - p.collected_amount;
        });

          this.dataSource = new MatTableDataSource(this.paymentsData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading=false;
        }
       
      else{
        this.paymentsData = [];
      } 
        // this.activitiesData.forEach((e:any)=>{
        // });
    })
  }

  setPayment(){
   
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }
    if(!this.addPayment.valid){
      return;
    }
    else if(this.addPayment.controls.collectedAmount.value == 0){
      return;
    }
    else{
       
          let created_on = (this.pipe.transform(this.minDate, 'yyyy-MM-dd'))+' '+this.formatTime(this.minDate);
          let paymentInfo={
            'trans_id':0,
            'client_id': this.clientId,
            'product_id':0,
            'amount': this.addPayment.controls.collectedAmount.value,
            'mode_id': this.addPayment.controls.modeId.value,
            'description' :this.addPayment.controls.paymentNotes.value,
            'created_on':created_on,
            'created_by':this.userSession.id 
            }
          let resMessage = 'Payment not saved';
          this.paymentInProcess = true;
            this.spinner.show();
            this.crm.setCrmClientTransaction(paymentInfo).subscribe((res:any) => {
              this.spinner.hide();
              this.paymentInProcess = false;
              if (res && res.status) {
                this.addPayment.reset();
                this.addPaymentInitilization();
                if(res.data && res.data[0]){
                  resMessage =  res.data[0].resultmsg ? res.data[0].resultmsg: resMessage;
                  if(res.data[0].successState === 0){
                    this.getClientData();
                    resMessage = 'Payment saved successfully';
                  }
                }
              }    
              let dialogRef = this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:resMessage });              
                  dialogRef.afterClosed().subscribe(result => { this.closePayment(); });
            })
        }
  }
  
  getClientData() {
    this.collectedTotal = 0;
    this.spinner.show();
    this.crm.getCrmClientDataBySelection({clientId:this.clientId ,empId: this.userSession.id}).subscribe(async (results)=>{
      this.spinner.hide();
      if(results && results.data && results.data[0]){
          this.localdata = results.data[0];
          this.getCountry(this.localdata.country);
          this.collectedTotal = this.localdata.collected_total && isNaN(this.localdata.collected_total) ? 0 : this.localdata.collected_total;
          // this.getStatesc(this.localdata.country,this.localdata.state);
          // this.getCities(this.localdata.state,this.localdata.city);
          
          this.editClientForm.patchValue({
            client_id:this.localdata.client_id,
            
            companyname: this.localdata.client_name,
            contactpersonname: this.localdata.contact_person_name,
            contact:this.localdata.contact,
            email: this.localdata.email?.toLowerCase(),
            address1:this.localdata.address1,
            address2:this.localdata.address2,
            city: this.localdata.city,
            state: this.localdata.state,
            pincode:this.localdata.pincode,
            country: this.localdata.country,
            notes:this.localdata.notes,
            latitude:this.localdata.lat,
            longitude:this.localdata.lng,
          });

          this.getActivitiesWithTrackingDetails();
          this.getPaymentsData();
          this.addActivityFormInitilization();
        }
     
    })
  }
  addActivityFormInitilization(){
    this.addActivity = this.FormBuilder.group({
      //client_id:[{value:this.clientId,disabled: this.isEditClientEnabled},Validators.required],
     // productId:[{value:0,disabled: this.isEditClientEnabled},Validators.required],
      activityTypeId:[{value:'',disabled: this.isEditClientEnabled},Validators.required],
      activityStatusId: [{value:'',disabled: this.isEditClientEnabled},Validators.required],
      activityNotes:[{value:'',disabled: this.isEditClientEnabled},Validators.required],
      nextFollowUpDate: [{value:this.formattedDate,disabled: this.isEditClientEnabled},Validators.required],
      nextFollowUpTime:[{value:'',disabled: this.isEditClientEnabled},Validators.required],
      clientStatusId: [{value:'',disabled: this.isEditClientEnabled},Validators.required],
      //file: [{value:'',disabled: this.isEditClientEnabled},Validators.required],
      lat: [{value:'',disabled: this.isEditClientEnabled},Validators.required],
      lng:[{value:'',disabled: this.isEditClientEnabled},Validators.required],
      finalPrice:[{value:(isNaN(this.localdata.final_price)?0:this.localdata.final_price),disabled: this.localdata.final_price}]      
      //createdby:[{value:this.userSession.id,disabled: this.isEditClientEnabled},Validators.required],
    });
    this.addActivity.get('activityTypeId').valueChanges.subscribe((selectedValue:any) => {
      this.activityTypeList.forEach((e:any) => {
        if(selectedValue=== e.id)
        this.selectedActivityType = e.code
        });
       this.getClientLocationFromMap();
    });
    this.addActivity.get('clientStatusId').valueChanges.subscribe((selectedValue:any) => {
      this.showFinalPrice = false;
        this.clientStatusList.forEach((s:any)=>{
           if(s.id === selectedValue && s.code === 'WON') {
            this.showFinalPrice = true;
           }
        });   
    });
  }

 getActivityTypeList(){
  this.crm.getCrmActivityTypeValues().subscribe((res:any)=>{
    if(res.status) {
      this.activityTypeList =  res.data;
    }
    else 
      this.activityTypeList = [];
  })
 }

 getPaymentModes(){
  this.crm.getCrmPaymentModes().subscribe((res:any)=>{
    if(res.status) 
      this.paymentModes =  res.data;
    else 
      this.paymentModes = [];
  })
 }

 

 getActivityStatusList(){
  this.crm.getCrmActivityStatusValues().subscribe((res:any)=>{
    if(res.status) 
      this.activityStatusList =  res.data;
    else 
      this.activityStatusList = [];
    
  })
 }

getClientStatusList(){
  this.crm.getCrmClientStatusValues().subscribe((res:any)=>{
    if(res.status) 
      this.clientStatusList =  res.data;
    else 
      this.clientStatusList = [];
    
  })
};  

addActivityFormBuild(){
  this.addActivity.reset();
  this.addActivity.get('activityTypeId').reset();
  this.addActivity.get('activityTypeId').setErrors(null);
  this.addActivity.get('activityStatusId').reset();
  this.addActivity.get('activityStatusId').setErrors(null);
  this.addActivity.get('activityNotes').reset();
  this.addActivity.get('activityNotes').setErrors(null);
  this.addActivity.get('nextFollowUpDate').reset();
  this.addActivity.get('nextFollowUpDate').setErrors(null);
  this.addActivity.get('nextFollowUpTime').reset();
  this.addActivity.get('nextFollowUpTime').setErrors(null);
  this.addActivity.get('clientStatusId').reset();
  this.addActivity.get('clientStatusId').setErrors(null);
  this.addActivity.get('lat').reset();
  this.addActivity.get('lat').setErrors(null);
  this.addActivity.get('lng').reset();
  this.addActivity.get('lng').setErrors(null);
  this.addActivity.get('finalPrice').reset();
  this.addActivity.get('finalPrice').setErrors(null);
};

 async validateAcitivty(){
  let msg = null;
    if(this.selectedActivityType === 'VISIT'){
      if(!(this.activityImageDataUrl && this.activityImageDataUrl.imageData)){
        msg = 'Please capture photo';
      }  
    }
    if(this.showFinalPrice){
      if(!(this.addActivity.controls.finalPrice.value && Number(this.addActivity.controls.finalPrice.value)>0 ))
        msg = 'Please enter valid final amount';
    }
  return msg;
 }

  async addCrmActivity(){ 
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }    
      this.addActivity.controls.lat.setValue(this.selected_location.lat);
      this.addActivity.controls.lng.setValue(this.selected_location.lng);
     
      if(!this.addActivity.valid){
        return;
      } 
      else
       {       
        let valid_msg = await this.validateAcitivty();
          if(valid_msg){
            this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:valid_msg });
            return;
          }           
        let follow_up = (this.pipe.transform(this.addActivity.controls.nextFollowUpDate.value, 'yyyy-MM-dd'))+' '+this.formatTime(this.addActivity.controls.nextFollowUpTime.value);
        let activityInfo ={
          clientId:this.clientId ,
          productId:0,
          activityTypeId:this.addActivity.controls.activityTypeId.value,
          activityStatusId:this.addActivity.controls.activityStatusId.value,
          activityNotes:this.addActivity.controls.activityNotes.value,
          nextFollowUpDate:follow_up,
          clientStatusId:this.addActivity.controls.clientStatusId.value,
          createdBy:this.userSession.id,
          lat:this.addActivity.controls.lat.value,
          lng:this.addActivity.controls.lng.value,
          createdOn:this.pipe.transform(this.minDate, 'yyyy-MM-dd HH:mm:ss'),
          device: this.deviceInfo.deviceType+'-'+this.deviceInfo.os_version,
          device_type: JSON.stringify({Type:this.deviceInfo.deviceType,Name:this.deviceInfo.os_version,isMobile:false}),
          finalPrice:this.showFinalPrice?this.addActivity.controls.finalPrice.value:null          
          // location:this.fetchedAddress
        }
        let resMessage = 'Data not saved successfully';
          this.crm.setCrmActivityDataWithTracking(activityInfo).subscribe((data:any) => {
            if (data && data.status) {
              //this.addActivityFormBuild();
              let activity_id = data.data[0].activity_id;
              
              resMessage = 'Data saved successfully';
              if(this.activityImageDataUrl.imageData ){
                this.crm.getFilepathsMaster(12).subscribe((result) => {
                  if(result && result.status){
                    let obj = {
                      'id':null,
                      'employeeId':this.userSession.id,
                      'filecategory': 'CRM_VISITS',
                      'moduleId':12,
                      'documentnumber':'',
                      'fileName':'VISIT'+this.formattedDate+'-'+this.timeStampVal+'.png',
                      'modulecode':result.data[0].module_code,
                      'requestId': activity_id??0,
                      'status':'Submitted'
                    }
                    this.crm.setCrmFilesMaster(obj).subscribe((data) => {
                      if(data && data.status) {
                       
                        const formDt: FormData = new FormData();   // Create FormData to send as a payload // const formData = new FormData();
                        const blob = this.activityImageDataUrl.imageData?this.dataURLtoBlob(this.activityImageDataUrl.imageData):null;   
                        if(blob){ 
                        formDt.append('info', JSON.stringify(data.data[0]));
                        formDt.append('file', blob, data.data[0].filename);
                          this.crm.setCapturedImageForTracking(formDt).subscribe((result) => {
                                  this.spinner.hide();
                                  if(result && result.status){
                                    this.getActivitiesWithTrackingDetails();
                                    resMessage  = resMessage+'- uploaded';
                                    this.activityImageDataUrl = {imageData:null,notes:null};
                                  }
                                  else  {
                                    resMessage  = resMessage+'- not uploaded'
                                    this.addActivity.reset();
                                  
                                  }
                          });
                        }else {
                          resMessage  = resMessage+'- image capture failed'
                         // this.addActivityFormBuild();
                          //this.getActivitiesWithTrackingDetails();
                        } 
                      } else  {
                        resMessage  = resMessage+'- image capture failed'
                       // this.getActivitiesWithTrackingDetails();
                       // this.addActivityFormBuild();
                      }
                  })
                  }else  resMessage  = resMessage+'- image capture failed'    
                 // this.addActivityFormBuild();
                //  this.getActivitiesWithTrackingDetails();
                })
              }
              else{
              //  this.addActivityFormBuild();
              //  this.getActivitiesWithTrackingDetails();
              }              
             // this.getClientData();
            }  
            //this.fetchedAddress = '';
          
            let dialogRef = this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:resMessage });             
            dialogRef.afterClosed().subscribe(result => { this.closeCrmActivity(); });
          })
      }

  }



  closeCrmActivity(){
    this.activityImageDataUrl = {imageData:null,notes:null};
    let clientId=this.encriptPipe.transform(this.clientId.toString());
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}]));
     
    
  }
  closePayment(){
    let clientId=this.encriptPipe.transform(this.clientId.toString());
    let type = 'pay';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId,type}]));
    this.addScreen('pay');
  }
  getClientImageDisplay(data:any){

  }

  getCountry(countryId:any=null){
    this.crm.getCountry(null,1,10).subscribe((results)=>{
      this.CountryDetails=results.data;
      if(results.data && results.data.length){
        results.data.forEach((c:any)=>{
          if(countryId === c.id){
            this.localdata.countryname = c.country;
          }
        });
        this.getStatesc(countryId,this.localdata.state);
      }
      if(countryId){
        this.editClientForm.patchValue({ country: countryId});
      }
    });
    
  }

  getStatesc(countryId:any,stateId:any=null){
      this.company.getStatesc(countryId).subscribe((data)=>{
        this.stateDetails=data.data;
        if(data.data && data.data.length){
          data.data.forEach((s:any)=>{
            if(stateId === s.id){
              this.localdata.statename = s.state;
            }
          });
          this.getCities(stateId,this.localdata.city);
        }
        if(stateId){
          this.editClientForm.patchValue({ state: stateId});
        }
      })
      
  }

  getCities(stateId:any,cityId:any=null){
    this.company.getCities(stateId).subscribe((data)=>{
      this.cityDetails=data.data;
      if(data.data && data.data.length){
        data.data.forEach((ct:any)=>{
          if(cityId === ct.id){
            this.localdata.cityname = ct.location;
          }
        });
      }
      if(cityId){
        this.editClientForm.patchValue({ city: cityId});
      }
    })
    
  }


  getClientLocationFromMap(){
    navigator.geolocation.getCurrentPosition( (position) => {
      this.selected_location ={lat : position.coords.latitude,lng: position.coords.longitude};
      // this.crm.fetchMapLocationDetails(this.selected_location ).subscribe((data) => {
      //   this.fetchedAddress = data.display_name;
         
      // });
      // this.markerPositions.push({ lat: position.coords.latitude, lng: position.coords.longitude,description:'current place' });
    },(error) => console.log(error));
    
  }

  closeEditClient(){
    this.isEditClientEnabled = false;
    if(!this.isEditClientEnabled){
      this.editClientForm.get('companyname')?.disable();
      this.editClientForm.get('contactpersonname')?.disable();
      this.editClientForm.get('contact')?.disable();
      this.editClientForm.get('email')?.disable();
      this.editClientForm.get('address1')?.disable();
      this.editClientForm.get('address2')?.disable();
      this.editClientForm.get('city')?.disable();
      this.editClientForm.get('state')?.disable();
      this.editClientForm.get('country')?.disable();
      this.editClientForm.get('notes')?.disable();
      this.editClientForm.get('pincode')?.disable();
      this.editClientForm.get('latitude')?.disable();
      this.editClientForm.get('longitude')?.disable();
    }
    this.getClientData();
  }

  editClient(){
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }

    if(!this.editClientForm.valid){
      return;
    }
    let companyinformation ={
      client_id:this.clientId ,
      companyname:this.editClientForm.controls.companyname.value,
      contact_person_name:this.editClientForm.controls.contactpersonname.value,
      contact:this.editClientForm.controls.contact.value,
       email:this.editClientForm.controls.email.value?.toLowerCase(),
      address1:this.editClientForm.controls.address1.value,
      address2:this.editClientForm.controls.address2.value?this.editClientForm.controls.address2.value:'',
      country:this.editClientForm.controls.country.value,
      state:this.editClientForm.controls.state.value,
      city:this.editClientForm.controls.city.value,
      pincode:this.editClientForm.controls.pincode.value,
      createdby:this.userSession.id,
      latitude:this.editClientForm.controls.latitude.value,
      longitude:this.editClientForm.controls.longitude.value,
      notes: this.editClientForm.controls.notes.value,
      created_date:this.pipe.transform(this.minDate, 'yyyy-MM-dd HH:mm:ss'),
     // created_date:this.formattedDate+' '+this.timeStampVal,
      is_captured:(this.imageDataUrl && this.imageDataUrl.imageData) ? 1 : 0
    }
    
     let resMessage = 'Data not saved successfully';
      this.crm.setCrmClientInformation(companyinformation).subscribe((data:any) => {
        if (data && data.status) {
           
          let client_id = data.data[0].client_id;
          resMessage = data.data[0].resultMsg;
          if(this.imageDataUrl.imageData ){
            this.crm.getFilepathsMaster(12).subscribe((result) => {
              if(result && result.status){
                let obj = {
                  'id':null,
                  'employeeId':this.userSession.id,
                  'filecategory': 'CRM_CLIENT',
                  'moduleId':12,
                  'documentnumber':'',
                  'fileName':companyinformation.companyname.toString().replace(' ','')+this.formattedDate+'-'+this.timeStampVal+'.png',
                  'modulecode':result.data[0].module_code,
                  'requestId': client_id??0,
                  'status':'Submitted'
                }
                this.crm.setCrmFilesMaster(obj).subscribe((data) => {
                   
                  if(data && data.status) {
                   // this.formData.append("info",JSON.stringify(data.data[0]))
                  //  this.file = {
                  //   filename: data.data[0].filename,
                  //   size:blob.size.toString(),
                  //   encoding:  'base64'; // Encoding used in the data URL
                  //   mimeType:'image/png'; // MIME type of the image  
                  //   md5:  this.calculateMD5(this.imageDataUrl.imageData); // Calculate MD5 hash (you may need a library for this)
                  // }
                  const formDt: FormData = new FormData();   // Create FormData to send as a payload // const formData = new FormData();
                 
                  const blob = this.imageDataUrl.imageData?this.dataURLtoBlob(this.imageDataUrl.imageData):null;   
                  if(blob){ 
                  formDt.append('info', JSON.stringify(data.data[0]));
                  formDt.append('file', blob, data.data[0].filename);
                    this.crm.setCapturedImageForTracking(formDt).subscribe((result) => {
                            this.spinner.hide();
                            if(result && result.status){
                              this.editClientFormReset();
                              resMessage  = resMessage+'- uploaded'
                              this.imageDataUrl = {imageData:null,notes:null};
                            }
                             
                            else { resMessage  = resMessage+'- not uploaded'
                            this.editClientFormReset();
                          }
                        //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        //this.router.navigate(["/Admin/CompanyLogo"]));
                    });
                  }else {
                     resMessage  = resMessage+'- image capture failed'
                     this.editClientFormReset();
                  }
                  }else  {
                    resMessage  = resMessage+'- image capture failed'
                    this.editClientFormReset();
                  }
               })
            }else  {
              resMessage  = resMessage+'- image capture failed'      
              this.editClientFormReset();
            }
            })

          }else {
            this.editClientFormReset();
          }
        }  
        this.dialog.open(ReusableDialogComponent, {
          position:{top:`70px`}, disableClose: true,data:resMessage
        });
      })
  }

 
    editClientFormReset(){
      this.editClientForm.reset();
      this.editClientForm.get('companyname').reset();
      this.editClientForm.get('companyname').setErrors(null);
      this.editClientForm.get('contactpersonname').reset();
      this.editClientForm.get('contactpersonname').setErrors(null);
      this.editClientForm.get('contact').reset();
      this.editClientForm.get('contact').setErrors(null);
      this.editClientForm.get('email').reset();
      this.editClientForm.get('email').setErrors(null);
      this.editClientForm.get('address1').reset();
      this.editClientForm.get('address1').setErrors(null);
      this.editClientForm.get('address2').reset();
      this.editClientForm.get('address2').setErrors(null);
      this.editClientForm.get('country').reset();
      this.editClientForm.get('country').setErrors(null);
      this.editClientForm.get('state').reset();
      this.editClientForm.get('state').setErrors(null);
      this.editClientForm.get('city').reset();
      this.editClientForm.get('city').setErrors(null);
      this.editClientForm.get('pincode').reset();
      this.editClientForm.get('pincode').setErrors(null);
      this.editClientForm.get('latitude').reset();
      this.editClientForm.get('latitude').setErrors(null);
      this.editClientForm.get('longitude').reset();
      this.editClientForm.get('longitude').setErrors(null);
      this.editClientForm.get('notes').reset();
      this.editClientForm.get('notes').setErrors(null);
      this.closeEditClient();
  }

  async openWebcamModalVisit() {
    this.imageDataUrl = {imageData:null,notes:null};
    const dialogRef = this.dialog.open(WebcamModalComponent,{width: '350px', disableClose: false });
    await dialogRef.afterClosed().subscribe(result => {
      this.activityImageDataUrl= result;
    });
  }

  async openWebcamModalClient() {
    this.imageDataUrl = {imageData:null,notes:null};
    const dialogRef = this.dialog.open(WebcamModalComponent,{width: '350px', disableClose: false });
    await dialogRef.afterClosed().subscribe(result => {
      this.imageDataUrl = result;
    });
  }

  showSpinner(){
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
  }

  private dataURLtoBlob(dataURL: string): Blob {
    const arr = dataURL.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (mimeMatch) {
      const mimeType = mimeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mimeType });
    }
    return new Blob();
  }

  formatTime(date:any): string {
    // Extract hours, minutes, and seconds
    //let date = new Date();
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    // Format the time as HH:mm:ss
    return `${hours}:${minutes}`;
    
  }
  padZero(value: number): string {     
    return value < 10 ? `0${value}` : `${value}`;
  }
  openMapDialog(){

  }

  getImageDisplay(requestId:any){
    var data = ``;
    this.imageurls.forEach((e:any)=>{
      if(e.requestId === requestId){         
        data = `${e.img}`;
      }
    })
    return data;
  }

  getFormattedDateTime(date:any = null){
    if(!date) 
    date = new Date();
    else date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  getImageData(requestId:any,empid:any){
      let info = {
        'employeeId': empid,
        "candidateId": null,
        "moduleId": 12,
        "filecategory": 'CRM_VISITS',
        "requestId": requestId,
        'status': 'Submitted'
      }
      this.crm.getCrmFilesMaster(info).subscribe((result) => {
          if(result && result.status && result.data[0]){
            //this.logoId=result.data[0].id;
          this.imageInfo =JSON.stringify(result.data[0]);
          result.data[0].employeeId=0;
          let info = result.data[0]
          this.LMS.getProfileImage(info).subscribe((imageData) => {
            if(imageData.success){
              let TYPED_ARRAY = new Uint8Array(imageData.image.data);
              const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
                return data + String.fromCharCode(byte);
              }, '');
              let base64String= btoa(STRING_CHAR)
              this.imageurls.push({requestId:requestId,img:'data:image/png;base64,'+base64String});
            }
          })
      }
    })
  }

  async selectAddClient() {
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }
    this.imageDataUrl = {imageData:null,notes:null};
    //this.isNewclient = true;
    const dialogRef = this.dialog.open(CrmAddClientComponent, 
      { width: '1250px', 
      //padding: '0.25em',
      height:'450px',
      data:{client_id:this.clientId},
      disableClose: true
     });
    await dialogRef.afterClosed().subscribe(result => {
      this.imageDataUrl = result;
      this.getClientData();
    });
  }
  async selectAddClientPayment() {
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }
    const dialogRef = this.dialog.open(CrmAddTransactionComponent, 
      { width: '850px', 
      //padding: '0.25em',
      height:'430px',
      data:{
        client_id:this.clientId,
        client_name:this.localdata.client_name,
        trans_id:0,
        final_price:this.localdata.final_price,
        collected_total: this.collectedTotal
      },
      disableClose: true
     });
    await dialogRef.afterClosed().subscribe(result => {
      this.imageDataUrl = result;
      this.getClientData();
    });
  }
  getLocationColor(activity:any) {
   // return 'mistyrose';


  }

  async getLocationIconColor(activity:any){
    var dist = null;
    if(activity.lat && activity.lng){
      if(this.localdata && this.localdata.lat && this.localdata.lng && Number(this.localdata.lat) && Number(this.localdata.lng)){
         dist = await this.crmHaversineDistanceCalculation({lat:this.localdata.lat,lng:this.localdata.lng},{lat:activity.lat,lng:activity.lng});
         dist = Number(dist);
         if(dist <= 100){
          activity.location_icon_color = 'green';
        }
        }else if(this.firstActivityCoords && this.firstActivityCoords.lat && this.firstActivityCoords.lng &&  Number(this.firstActivityCoords.lat) && Number(this.firstActivityCoords.lng)){
        dist = await this.crmHaversineDistanceCalculation({lat:this.firstActivityCoords.lat,lng:this.firstActivityCoords.lng},{lat:activity.lat,lng:activity.lng});
        dist = Number(dist);
        if(dist <= 100){
          activity.location_icon_color = 'green';
        }
      }
    }
  }

  
async  crmHaversineDistanceCalculation(location1:any,location2:any) {
  // distance between latitudes
  // and longitudes
    let lat1 =location1.lat; 
    let lon1 =location1.lng;
    let lat2 =location2.lat;
    let lon2 =location2.lng;
    
    let dLat = (lat2 - lat1) * Math.PI / 180.0;
    let dLon = (lon2 - lon1) * Math.PI / 180.0;           
    // convert to radiansa
    lat1 = (lat1) * Math.PI / 180.0;
    lat2 = (lat2) * Math.PI / 180.0;         
    // apply formulae
    let a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371;
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;         
  }

  addScreen(type:any){
    if(type == 'activity'){
       this.addActivityScreen = !this.addActivityScreen;
       this.addPaymentScreen = false;
    }
    if(type == 'pay'){
      this.addActivityScreen = false;
      this.addPaymentScreen = !this.addPaymentScreen;
   }
  }
  getPageSizes(): number[] {
    
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
    }

    changeFinalPrice(){
      
    }
    getPendingAmout(){
      let pending_amount = Number(this.localdata.final_price) - Number(this.collectedTotal) - Number(isNaN(this.addPayment.controls.collectedAmount.value)?0:this.addPayment.controls.collectedAmount.value);
      if(isNaN(pending_amount)){
        return 0;
      }
      else if(pending_amount < 0){
        return 0;
      }
      else {
        return pending_amount;
      }
      
    }
}
