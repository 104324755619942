<div fxLayout="column" class="first-col">
    <form [formGroup]="requestform" autocomplete="off">
        <mat-card class="mat-elevation-z0">
            <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-card-title class="title"> Assign Rotation Shift </mat-card-title>
                <div style="margin-right: 2%;" *ngIf="isAdd">
                    <button mat-icon-button style="padding-right: 40px;" (click)="newAdd()" matTooltip="Add New">
                        <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                    </button>
                </div>
            </mat-card-header>

            <div *ngIf="!isAdd" fxLayout="column" class="dev-Pad">
                <form [formGroup]="searchform" autocomplete="off">
                    <div fxLayout="row" fxLayoutAlign.xs="center center">
                        <mat-form-field fxFlex.xs="100" fxFlex="28" class="mx-2 my-2" appearance="outline">
                            <mat-label>Location</mat-label>
                            <mat-select required formControlName="location">
                                <input matInput class="search-align" [formControl]="searchControl" type="text"
                                    placeholder="Search">
                                <ng-container>
                                    <div *ngFor="let b of filteredAvaliableLocations">
                                        <mat-option [value]="b.locationid">{{ b.location }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="
                    searchform.controls.location.errors?.required">
                                {{ requiredOption }}
                            </mat-error>
                        </mat-form-field>
                        <!-- <div style="padding-top: 10px;">
                    <button class="btn btn-primary mr-2 mb-2" (click)="getShiftSequenceList()">
                        <span>Search</span>
                    </button>
    
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div> -->
                    </div>
                </form>

                <div *ngIf="isShow">
                    <form [formGroup]="requestform">
                        <div fxLayout="row" fxLayoutAlign.xs="center center">
                            <mat-form-field fxFlex="28" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                                <mat-label>Rotation Shift</mat-label>
                                <mat-select formControlName="shift" required>
                                    <input matInput [formControl]="searchControlforShift" type="text"
                                        placeholder="Search" class="search-align">
                                    <ng-container *ngFor="let v of filteredShiftSequenceList">
                                        <mat-option [value]="v.id"
                                            (onSelectionChange)="shiftChange(v.id)">{{v.shift_sequence_name}}</mat-option>
                                    </ng-container>
                                </mat-select>

                                <mat-error class="con-error" *ngIf="requestform.controls.shift.errors?.required">
                                    {{requiredOption}}
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <mat-divider></mat-divider>
                        <div fxLayout="column">
                            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"  style="padding-top: 5px;height: 70px;">
                    <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                        <mat-label>Search </mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder=""
                            maxlength="100" />
                    </mat-form-field>
                </div> -->
                            <div class="table-pad">
                                <div class="example-container mat-elevation-z1">
                                    <table mat-table [dataSource]="dataSource" matSort>
                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(row) : null"
                                                    [checked]="selection.isSelected(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> S. No. </th>
                                            <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) +
                                                (paginator.pageIndex * paginator.pageSize) }}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="empid">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                                                ID </th>
                                            <td mat-cell *matCellDef="let row"> {{row.employee_code}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="empname">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                                                Name </th>
                                            <td mat-cell *matCellDef="let row"> {{row.employee_name}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="desig">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                                                Designation</th>
                                            <td mat-cell *matCellDef="let row"> {{row.designation}}</td>
                                        </ng-container>

                                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-paginator
                                        [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                                </div>
                            </div>
                        </div>
                        <br>
                        <div fxLayoutAlign="center" *ngIf="arrayList.length > 0 ">
                            <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
                                <span>Submit</span>
                            </button>

                            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                                <span>Cancel</span>
                            </button>
                            <br />
                        </div>
                    </form>
                </div>


            </div>

            <div fxLayout="column" *ngIf="isAdd">
                <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                    <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                        <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                            appearance="outline">
                            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                        </mat-form-field>
                    </span>
                </div>

                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1  example-container">
                        <table mat-table [dataSource]="dataSource2" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator2.pageIndex * paginator2.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="empcode">
                                <th mat-header-cell *matHeaderCellDef>Emp. ID</th>
                                <td mat-cell *matCellDef="let row">{{row.empcode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="empname">
                                <th mat-header-cell *matHeaderCellDef>Emp. Name</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{row.empname}} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef>Location</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{row.location}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="shift">
                                <th mat-header-cell *matHeaderCellDef>Current Shift</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{row.shiftname}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rotationShift">
                                <th mat-header-cell *matHeaderCellDef>Rotation Shift</th>
                                <td mat-cell *matCellDef="let row">
                                    <span *ngIf="row.isAssigned == 0" style="color: red;">{{row.rotationShift}} </span>
                                    <span *ngIf="row.isAssigned != 0">{{row.rotationShift}} </span>
                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                            </tr>
                        </table>

                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                        <mat-paginator
                            [pageSizeOptions]="!pageLoading2 ? getPageSizes2() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>

            </div>
        </mat-card>
    </form>






</div>