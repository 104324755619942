import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router, RouterModule } from '@angular/router';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as _moment from 'moment';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;
import jsPDF from "jspdf";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
/**test */
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin-payroll-report',
  templateUrl: './admin-payroll-report.component.html',
  styleUrls: ['./admin-payroll-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminPayrollReportComponent implements OnInit {
  viewdata: any
  date = new UntypedFormControl(moment());
  searchForm: any = UntypedFormGroup;
  displayedColumns: string[] = ['sno', 'location', 'empid', 'empname', 'totalgsalary', 'days', 'totalsalary', 'regularization'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageLoading = true;
  searchControlforManager = new FormControl('');
  searchControlForLocation = new FormControl('');
  filteredLocations: any = [];
  filteredManagerList: any = [];

  constructor(private LM: LeavesService, private router: Router, public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService, private reportsService: ReportsService, private companyService: CompanySettingService) { }
  @ViewChild('table') table!: ElementRef;

  minDate: any;
  pipe = new DatePipe('en-US');
  userSession: any;
  employeePayrollData: any;
  managersDetails: any[] = [];
  employeeListByManager: any[] = [];
  year: any;
  max = new Date()
  maxDate: any = new Date();
  worklocationDetails: any[] = [];
  companyName: any;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;

  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;


  selectedLocations: any = [];
  selectedManagers: any = [];
  selectedEmployeeList: any = [];
  isNorecords = false;
  ngOnInit(): void {
    this.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.searchForm = this.formBuilder.group({
      companylocation: ['', Validators.required],
      managers: ["", Validators.required],
      fromDate: [new Date()],
    });

    this.getWorkLocation();
    this.getManagersList();
    this.searchControlforManager.valueChanges.subscribe(searchText => {
      this.filterManagerList(searchText)
    });

    this.searchControlForLocation.valueChanges.subscribe(searchText => {
      this.filterLocation(searchText)
    });




  }
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.viewdata = ctrlValue._d;
    this.searchForm.controls.fromDate.setValue(ctrlValue);
  }

  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName
    }
    this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.managersDetails = res.data;
        this.filteredManagerList = this.managersDetails;
      }
    })

  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    this.employeeListByManager = [];

    let location = this.searchForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeeList();
  }

  getEmployeeList() {
    let data = {
      rm_id: JSON.stringify(this.selectedManagers),
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName
    }
    this.reportsService.getLocationWiseEmployeesForManagers(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.employeeListByManager = res.data;
        this.selectedEmployeeList = [];
        for (let i = 0; i < res.data.length; i++) {
          this.selectedEmployeeList.push(Number(res.data[i].empid));
        }
      }
    })
  }
  getEmployeePayrollData() {
    let syear = new Date(this.searchForm.controls.fromDate.value).getFullYear();
    let smonth = new Date(this.searchForm.controls.fromDate.value).getMonth() + 1;
    let info = {
      empids_json: JSON.stringify(this.selectedEmployeeList),
      year_value: syear,
      month_value: smonth,
      companyName: this.companyName
    }

    this.reportsService.getGrossSalaryDetails(info).subscribe((result: any) => {
      this.employeePayrollData = result.data;
      this.dataSource = this.employeePayrollData;
      this.employeePayrollData[0].empid != null ? this.dataSource = this.employeePayrollData : this.dataSource = new MatTableDataSource();
      // this.employeePayrollData.forEach((e: any) => {
      //   if(e.empid==null && e.empcode ==null && e.total_gross_salary){
      //       this.isNorecords =true;
      //   }
      // });
    })
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.companylocation.setValue(array)
    let locations = this.searchForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.searchForm.controls.companylocation.setValue('')
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.managers.setValue(array);
    let managerdata = this.searchForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeeList();
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
    select.value = [];
    this.searchForm.controls.managers.setValue('');
    this.selectedManagers = []
  }

  Searchform() {
    if (this.searchForm.valid) {
      this.getEmployeePayrollData();
    }

  }

  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/adminPayrollReport"]));
  }


  public exportPDF(): void {
    this.year = this.viewdata != undefined ? this.viewdata.getFullYear() : new Date().getFullYear();
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: "Payroll Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: "Payroll Report for from " + this.pipe.transform(this.searchForm.controls.fromDate.value, 'longDate') + ' to ' + this.pipe.transform(this.searchForm.controls.toDate.value, 'longDate') + '\n\n',
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html
      ],
      pageOrientation: 'landscape',//'portrait'
      pageSize: 'A4'
    }).download("Payroll Report from " + this.pipe.transform(this.searchForm.controls.fromDate.value, 'longDate') + " to " + this.pipe.transform(this.searchForm.controls.toDate.value, 'longDate') + ".pdf");

  }
  exportAsXLSX() {

    this.year = this.viewdata != undefined ? this.viewdata.getFullYear() : new Date().getFullYear();
    var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payroll_report');
    XLSX.writeFile(wb, 'Payroll_report_for_financeteam_ from ' + this.pipe.transform(this.searchForm.controls.fromDate.value, 'longDate') + ' to ' + this.pipe.transform(this.searchForm.controls.toDate.value, 'longDate') + '.xlsx');

  }

  filterManagerList(searchText: any) {
    this.filteredManagerList = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredManagerList.length <= 0) {
      this.searchControlforManager.setValue('');
    }
  }

  filterLocation(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLocations.length <= 0) {
      this.searchControlForLocation.setValue('');
    }
  }

}
