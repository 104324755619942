<div fxLayout="column" class="first-col">
    <div fxLayout="row" fxLayoutAlign.xs="center" [formGroup]="searchForm" style="padding-left: 1%"
        fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%">

            <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                <mat-label>Employee Name</mat-label>
                <mat-select formControlName="Users" required tabindex="1">
                    <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                        class="search-align">
                    <mat-option value="0"> All Employees </mat-option>
                    <ng-container *ngFor="let e of filteredEmployeeList">
                        <mat-option [value]="e.empid">{{ e.empname }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                <mat-label>Date</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                    placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()" tabindex="2" />
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>


            <div fxLayout="row" fxLayoutAlign.xs="center center">
                <span ngStyle.gt-md="margin-top: 5%" ngStyle.lt-lg="margin-top: 5%" ngStyle.xs="margin-top: 0px">
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
                        Search
                    </button>
                    <span class="line" style="color: #1898d5; font-size: 16px"></span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
                        <span>Clear</span>
                    </button>
                </span>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end" *ngIf="List.length > 0" style="margin-right: 3%; margin-top: 10px">
            <div>
                <span class="exlprint"> | </span>
                <button mat-icon-button>
                    <a title="PDF" [tabIndex]="4" (keyup.enter)="exportPDF()">
                        <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
                </button>
                <span class="exlprint"> | </span>
                <button mat-icon-button>
                    <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()">
                        <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png" />
                    </a>
                </button>
            </div>
        </div>
    </div>
    <table *ngIf="List.length > 0" class="rTable">
        <thead class="rHeader">
            <tr>
                <th class="red-icon">A - Absent</th>
                <th class="green-icon">P - Present</th>
                <th class="blue-icon">W - Week-Off</th>
                <th class="orange-icon">L -Leave</th>
                <th class="yellow-icon">H - Holiday</th>
                <th class="light-icon">HD - Half Day</th>
                <th class="wp-icon">WP - Week-off Present</th>
                <th class="hp-icon">HP - Holiday Present</th>
            </tr>
        </thead>
    </table>

    <div class="p-2">
        <div class="example-container" style="padding: 10px" #table>
            <table style="width: 100%" *ngIf="List.length > 0" id="table">
                <thead class="theader">
                    <tr>
                        <th><span>S. No. </span></th>
                        <th><span>Employee </span></th>
                        <ng-container *ngFor="let a of headersList[0]; let i = index">
                            <th>
                                <u>{{ a | date : "d" }} </u><br />
                                {{ headersList[1][i] | limit : 2 }}
                            </th>
                        </ng-container>
                        <th><span>Absents</span></th>
                        <th><span>Presents</span></th>
                        <th><span>Late Count</span></th>
                        <th><span>Total</span></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of List">
                        <tr style="width: auto">
                            <ng-container *ngFor="let e of item">
                                <td [ngStyle]="{'background-color': e == 'H' || e == 'W'   ? '#E2E5DE' : '#ffffff'  }">
                                    <!-- <span [hidden]="e == 'H' || e == 'W'" [ngStyle]="{ color: getColor(e) }">
                                {{ e }}
                            </span> -->
                                    <span [ngStyle]="{ color: getColor(e) }">
                                        {{e}}
                                    </span>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>

            <div style="text-align: center; padding-top: 20px" *ngIf="List.length === 0">
                No data found.
            </div>
        </div>
    </div>
</div>