import { Component, OnInit, ViewChild,ElementRef,Inject, LOCALE_ID } from '@angular/core';
import { UntypedFormGroup,UntypedFormControl,Validators, UntypedFormBuilder,FormGroup,ValidatorFn, AbstractControl, UntypedFormArray} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CrmService } from '../../crm.service';
import * as XLSX from "xlsx";
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
//import {CrmRptcolumnsPopupComponent} from '../crm-rptcolumns-popup/crm-rptcolumns-popup.component';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
import { tr } from 'date-fns/locale';
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
interface DynamicObject {
  [key: string]: any;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
function minSelectedCheckboxes(min = 1) {
  const validator: any = (formArray: UntypedFormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map((control:any) => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);
    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };
  return validator;
}
@Component({
  selector: 'app-crm-client-report',
  templateUrl: './crm-client-report.component.html',
  styleUrls: ['./crm-client-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmClientReportComponent implements OnInit {
  reportForm:any= UntypedFormGroup;
  userSession:any;
  ishide:boolean=true;
 isview:boolean=false;
  ischecked:boolean=false;
  ishidestatus:boolean=false;
  addflagcheckboxes:boolean=false;
  ishiding=[1,1,1,1,1,1,1,1,1,1,1,1,1];
  ids=[0,1,2,3,4,5,6,7,8,9,10,11,12];
  arrlist:any=[1,1,1,1,1,1,1,1,1,1,1,1,1];
  allfilterdata: any = [];
  displayedColumns2: string[] = [];
  displayedColumns3:string[] = [];
  filterlist:any=[];
  filterlist2:any=[];
  created_by:any=[];
  empids:any=[];
  isColumns:any = false; 
  valid:boolean=true;
  encriptPipe= new EncryptPipe();
   get created_by_FormArray() {
    return this.reportForm.controls.created_by as UntypedFormArray;
  }
 
  
  arr: string[] = ['sno','Organisation'];
  dataSource : MatTableDataSource<any> = <any>[];
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  columns:any = [];
  companyName:any = null;
  searchForm:  any = FormGroup;
  
 worklocationDetails: any[] = [];
 managersDetails: any[] = [];
 locationIshide: boolean = false;
 locationIschecked: boolean = false;
 mgrIshide: boolean = false;
 mgrIschecked: boolean = false;
 selectedLocations: any = [];
 selectedManagers: any = [];
 
 is_super_admin:any = false;

 dynamicArr: number[] = [];
 route_name:any;
 //report_name:any;

 get earningsFormArray() {
   return this.reportpopupForm.controls.earnings as UntypedFormArray;
 }
 reportpopupForm:any= UntypedFormGroup;
 status!:boolean;
 data:any=[];
 isindeterminate:boolean=false;

  constructor(@Inject(LOCALE_ID) private locale: string,private formBuilder: UntypedFormBuilder, private router: Router, public dialog: MatDialog,private pipe: DatePipe,
    private companyService: CompanySettingService,public reportsService: ReportsService,
    private crm: CrmService, private spinner: NgxSpinnerService,) {
      this.companyName = sessionStorage.getItem("companyName");
      this.columns = [    
        { id: 0, name: 'Created By',keyname:'created_by' },
        { id: 1, name: 'Contact Person',keyname:'contact_person' },
        { id: 2, name: 'Mobile',keyname:'mobile_number' },
        { id: 3, name: 'Address',keyname:'address' },
        { id: 4, name: 'Country',keyname:'country' },
        { id: 5, name: 'State',keyname:'state' },
        { id: 6, name: 'City',keyname:'city' },
        { id: 7, name: 'Created Time' ,keyname:'created_on'},
        { id: 8, name:'Updated Time',keyname:'updated_on' },
        { id: 9, name:'Expected Price',keyname:'target_price' },
        { id: 10, name:'Final Price',keyname:'final_price' },
        { id: 11, name:'Description',keyname:'notes' },        
        { id: 12, name:'Collected Amount',keyname:'collected_amount' },
    ]; 
       
     // this.route_name = this.injectedData?(this.injectedData.route??''):'';
     // this.report_name = this.injectedData?(this.injectedData.report_name??''):'';
      this.reportpopupForm=this.formBuilder.group({
        check:[],
        earnings: new UntypedFormArray([]),
      });
      this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
      this.addCheckboxes();
      }
   showScroll: boolean | undefined;
  ngOnInit(): void {
    this.reportForming();
     this.getCrmconfigurations();
    //this.getClientReportColumns();
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      });
    
     this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
     if (this.userSession.is_super_admin =="1"){
      this.is_super_admin = true;
      this.getWorkLocation();
    }
    else {
    }
    // this.getCrmClientReportColumnConfigures();
  }
  reportForming(){
    if(this.reportForm){
      this.reportForm=this.formBuilder.group({    
          created_by:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[0]))),
          contact_person:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[1]))),
          mobile_number:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[2]))),
          address:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[3]))),
          country:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[4]))),
          state:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[5]))),
          city:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[6]))),
          created_on:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[7]))),
          updated_on:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[8]))),
          target_price:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[9]))),
          final_price:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[10]))),
          notes:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[11]))),
          collected_amount:new UntypedFormArray([],minSelectedCheckboxes(Number(this.arrlist[12]))),

      });
    }
  }

  private addEmployees() {
    this.created_by_FormArray.clear();
    this.created_by.forEach(() => this.created_by_FormArray.push(new UntypedFormControl(false)));
    if( this.arrlist[0]=='1'){
      this.created_by_FormArray.setValue(this.empids);
    }
  }

  empstatus(event:any,id:any){
    if(event.checked){
      for(let i=0;i<this.ids.length;i++){
        if(id == this.ids[i] && event.checked)
            this.ishiding[i]  = 1;
        else this.ishiding[i] = 0;
      }
    }
    else  this.ishiding[id]=0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  filter(){
    this.isColumns = false;
    this.ishide = false;
    this.isview = true;
    this.dataSource = new MatTableDataSource(<any>[]);
    if(!this.addflagcheckboxes){
      this.addEmployees();
      this.search();
    }
  }

  clearreport(){
    this.ishide = true;
    this.isview = false;
    this.isColumns = false;
  }

  async popup(){
    this.isColumns = true;
    this.isview = false;
    this.ishide = false;
    this.getClientReportColumns();
  }

  cancel(){
    this.ishide = true;
    this.isview = false;
    this.isColumns= false;
  }
  exportAsXLSX() {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Client_Report');
    /* save to file */
    XLSX.writeFile(wb, 'Client_Report.xlsx');

  }

  getEmsEmployeeColumnFilterData(){   
    this.created_by = [];
    this.empids = [];
    if(this.is_super_admin){
      this.getEmployeelistForSuperAdmin();       
    }else{
      this.getEmployeesForManagers(this.userSession.id);
    }    
  }

  getEmployeesForManagers(rmid:any){
    this.created_by = [];
    this.empids = [];
    this.crm.getCrmEmployeesForManagers({rm_id:rmid,selected_date:null}).subscribe((result:any)=>{       
      if(result && result.data && result.data.length){
        for(let i=0;i<result.data.length;i++){       
          this.created_by.push(result.data[i]);
         this.empids.push(result.data[i].empid)
       }
      }
      this.filter()
    })
  }

  search() {   
    this.addflagcheckboxes = true;
    const created_by_val = this.reportForm.value.created_by
    .map((checked:any, i:any) => checked ? this.empids[i] : null)
    .filter((v:any) => v !== null);
     let data2 ={
      employee_id:this.userSession.id,
      created_by_val:created_by_val,
      search_string:null
     }
     this.allfilterdata = [];        
     this.dataSource = new MatTableDataSource(<any>[]);
        this.spinner.show();
        this.crm.getCrmClientSelectedColumnsReport(data2).subscribe((res: any) => {
          this.spinner.hide();        
          if (res.status && res.data && res.data.length) {
            this.allfilterdata = res.data;
            this.dataSource = new MatTableDataSource(this.allfilterdata);
            }
            this.ishide = true;
            this.isview = false;
            this.isColumns = false;
        });
  }
  getCrmconfigurations(){
    this.crm.getCrmClientReportColumnConfigures({empid:this.userSession.id}).subscribe((result:any)=>{      
    this.arrlist = JSON.parse(JSON.stringify(result.data[0].configurations)).split(',');
    this.getEmsEmployeeColumnFilterData();
    this.getCrmClientReportColumnConfigures();
  });
}
  getCrmClientReportColumnConfigures(){
    this.displayedColumns2 = [];
   // this.getEmsEmployeeColumnFilterData();
    if(this.arrlist){
      for (let i=0;i<this.arrlist.length;i++){
        if((this.arrlist[i]) == '1')
          this.displayedColumns2.push(this.columns[i].keyname);
      }
      this.displayedColumns3=this.arr.concat(this.displayedColumns2);
      if(this.is_super_admin){
        this.getEmsEmployeeColumnFilterData();
      }
    }
  };



  clear(){
    this.ishide = true;
    this.isview = false;
    this.isColumns = false;
  }
  getPageSizes(): number[] {    
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) 
      customPageSizeArray.push(5);    
    if (this.dataSource.data.length > 10) 
      customPageSizeArray.push(10);    
    if (this.dataSource.data.length > 20) 
      customPageSizeArray.push(20);    
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getEmployeelistForSuperAdmin() {
    this.created_by = [];
    this.empids = [];
      let obj={
      "rm_id":JSON.stringify(this.selectedManagers),
      "location_id" :JSON.stringify(this.selectedLocations),
      };
      this.reportsService.getLocationWiseEmployeesForManagers(obj).subscribe((res: any) => {
          if (res && res.data && res.data.length) {
            if( res.data && res.data.length){
              for(let i=0;i<res.data.length;i++){
                this.created_by.push(res.data[i]);
                this.empids.push(res.data[i].empid);
              }
            }            
          }
          this.filter();
      });
  }

onchangeManager(value:any) {
    this.selectedManagers = [];
    let mgrs = this.searchForm.controls.managers.value;

    mgrs.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.addflagcheckboxes = false;
    this.getCrmClientReportColumnConfigures();
}
managerDeselectAll(select: MatSelect) {
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];
  select.value = [];
  this.searchForm.controls.managers.setValue('');
  this.addflagcheckboxes = false;
  this.getCrmClientReportColumnConfigures();
}
onchangeLocation(value:any) {
  this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
 this.getManagersList();
}
getManagersList() {
    this.managersDetails =[];
    this.reportsService.getManagersListByLocation({location_id :JSON.stringify(this.selectedLocations), companyName : this.companyName}).subscribe((res: any) => {
      this.managersDetails =  (res.status &&  res.data && res.data.length > 0) ?res.data:[];
      });
      
}

locationDeselectAll(select: MatSelect) {
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('');
  this.managersDetails =[];
  this.selectedManagers = [];
  this.addflagcheckboxes = false;
  this.getCrmClientReportColumnConfigures();
}

locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
    this.worklocationDetails = result.data;
  })
}


managerSelectAll(select: MatSelect, values: any, array: any) {
  this.mgrIshide = true;
this.mgrIschecked = true;
select.value = values;
array = values;
this.selectedManagers = [];
this.searchForm.controls.managers.setValue(array);
let managerdata = this.searchForm.controls.managers.value;
managerdata.forEach((e: any) => {
 this.selectedManagers.push(Number(e.manager_emp_id));
});
this.addflagcheckboxes = false;
this.getCrmClientReportColumnConfigures();
}

 getClientReportColumns(){
  this.ischecked = true;
  this.isindeterminate = false;
    this.crm.getCrmClientReportColumnConfigures({empid:this.userSession.id}).subscribe((result:any)=>{
    this.data = result.data;
    if(result.data && result.data[0] && result.data[0].configurations){
      this.arrlist = JSON.parse(JSON.stringify(result.data[0].configurations)).split(',');
     
          // let arr = JSON.parse(JSON.stringify(result.data[0].configurations)).split(',');
        this.dynamicArr = [];
        var count = 0,setval='';
        this.earningsFormArray.reset();
        this.arrlist.forEach((val:number)=>{
          count = Number(count) + Number(val);
          this.dynamicArr.push(Number(val));
          setval = setval +(setval.toString().length?',':'')+val.toString();
        });

        this.earningsFormArray.setValue(this.dynamicArr);
        this.ischecked = (count === this.arrlist.length)?true:false;
        this.isindeterminate = (this.arrlist.length-count>0)?true:false;
        this.reportpopupForm.controls.check.setValue(this.ischecked);
      }
  })
 }

 

  save(){    
    const earningselectedIds = this.reportpopupForm.value.earnings.map((checked:any, i:any) => checked ? 1 : 0).filter((v:any) => v !== null);
    var dynamicArray: DynamicObject[] = [{ empid:this.userSession.id }];
    this.columns.forEach((col:any)=>{
      dynamicArray[0][col.keyname.toString()]= (earningselectedIds[col.id]);      
    });
     
    this.setClientReportColumns(dynamicArray[0]);
  }

  setClientReportColumns(data:any){
    this.crm.setCrmClientReportColumnConfiguration(data).subscribe((res:any)=>{
      if(res.status){
        this.arrlist = JSON.parse(JSON.stringify(res.data[0].configurations)).split(',');
        this.isColumns = false;
        this.isview = false;
        this.ishide = true;      
      }
      this.addflagcheckboxes = false;
      this.getCrmClientReportColumnConfigures();
    })
  }
private addCheckboxes() {
  this.earningsFormArray.reset();
  this.columns.forEach(() => this.earningsFormArray.push(new UntypedFormControl(false)));
}

onChange($event:any){
  this.dynamicArr = [];
  if($event.checked){
    this.columns.forEach(() =>{
      this.dynamicArr.push(Number(1));
    });
    this.earningsFormArray.setValue(this.dynamicArr);
    this.isindeterminate = false;
    this.ischecked =true
  }
  else{
    this.columns.forEach(() => {
      this.dynamicArr.push(Number(0));
    });
    this.earningsFormArray.setValue(this.dynamicArr);
    this.isindeterminate = false;
    this.ischecked =false
  }
}
onNoClick(): void {
  this.isColumns = false;
  this.isview = false;
  this.ishide = true;
  }

  getFormattedNumber(n:any){
    return (n?(isNaN(n)?0:n):0).toFixed(2);
  }

  getFormattedDateTime(date:any = null){
    if(!date) 
      return '';
    else if(date == '')
      return '';
    else date = new Date(date);
      return this.pipe.transform(date, 'yyyy-MM-dd')?.replace(' ','') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' })?.replace(' ','');
  }

  selectClient(event:any,client:any){
    let clientId=this.encriptPipe.transform(client.id.toString());
    this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}])
    //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/crm/client-edit-and-update"]));
  }
}
