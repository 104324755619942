<div fxLayoutAlign="center center" fxFlexFill class="bg-img main-dev">
  <mat-card fxFlex="30" fxFlex.xs="90" fxFlex.lt-lg="60" style="border-radius: 30px;">

    <form [formGroup]="formGroup" fxLayoutAlign="stretch" fxLayout="column" class="login-form">
      <!-- Logo -->

      <div class="card-body p-3">

        <div fxLayout="column" class=" pt-4 pb-4 text-center">
          <span class="text-white">
            <img class="logo-img mr-2" style="height: 45px" src="../../../assets/img/title_logo.png" alt="" />
          </span>
        </div>
        <div>

          <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Company Code</mat-label>
            <input matInput class="form-control" formControlName="comapnyname" placeholder="Company Code"
              noLeadingWhitespace maxlength="15" />
            <mat-error class="con-error" *ngIf="formGroup.controls.comapnyname.errors?.required">
              This field is required.
            </mat-error>
          </mat-form-field>

        </div>

        <div>
          <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
            <mat-label>User Name/Email</mat-label>
            <input matInput placeholder="User Name/Email" formControlName="username" noLeadingWhitespace
              maxlength="50" />
            <mat-error class="con-error" *ngIf="formGroup.controls.username.errors?.required">
              This field is required.
            </mat-error>
          </mat-form-field>

        </div>

        <div>
          <div fxLayout="row">
            <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" id="password-field" [type]="hide ? 'password' : 'text'"
                formControlName="password" name="password" noLeadingWhitespace maxlength="30" (keyup.enter)="login()" />
              <button mat-icon-button matSuffix>
                <mat-icon (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error class="con-error" *ngIf="formGroup.controls.password.errors?.required">
                This field is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
            style="padding-left: 8px;padding-right: 8px">
            <div fxLayout="row" fxFlex="50">
              <mat-checkbox formControlName="rememberme" style="color:#00A9E0 !important">
                Remember Me
              </mat-checkbox>
            </div>

            <div fxLayout="row" fxLayoutAlign="end">
              <span><a [routerLink]="[]" (click)="ForgotPassword()" style="color:#00A9E0 !important">Forgot
                  password?</a>
              </span>
            </div>
          </div>


        </div>
      </div>
      <!-- end card-body -->
      <div class="mb-3 mb-0 text-center">
        <button class="btn btn-primary" style="background-color: #00A9E0 !important;border-color:#00A9E0 !important ;"
          type="submit" (click)="login()">
          Log In
        </button>
      </div>
    </form>


  </mat-card>
</div>