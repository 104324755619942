import { Injectable,LOCALE_ID,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable,of } from "rxjs/index";
import {environment} from "../../../environments/environment";
import { DeviceDetectorService } from 'ngx-device-detector';
import { DatePipe } from '@angular/common';
const addressService = 'geocode';
@Injectable({
    providedIn: 'root'
  })
  
  export class CrmService {
    mainUrl = environment.apiUrl;
    userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    httpOptions:any;
    companyName:any;
    constructor(@Inject(LOCALE_ID) private locale: string,private http: HttpClient,private deviceDetectorService: DeviceDetectorService,private pipe: DatePipe, ) {
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      this.httpOptions = {
        headers: new HttpHeaders({
          'content-Type': 'application/json',
          "Authorization": JSON.parse(JSON.stringify(sessionStorage.getItem('token') || '')), 
        })
      };
    }
    
    getCompany(){
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.companyName;
    }

    getMastertable(tableName:any,status:any,page:any,size:any,companyName:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
        return this.http.get(this.mainUrl+'api/getMastertable/'+tableName+'/'+status+'/'+page+'/'+size+'/'+this.companyName, this.httpOptions);
    }

    getCrmActivityStatusValues():Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
         return this.http.get(this.mainUrl+'api/getCrmActivityStatusValues/'+this.companyName, this.httpOptions);
    }  

    getCrmActivityStatusValuesMaster(pagenumber:any,pagesize:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
         return this.http.get(this.mainUrl+'api/getCrmActivityStatusValuesMaster/'+pagenumber+'/'+pagesize+'/'+this.companyName, this.httpOptions);
    } 

    getCrmClientStatusValuesMaster(pagenumber:any,pagesize:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
         return this.http.get(this.mainUrl+'api/getCrmClientStatusValuesMaster/'+pagenumber+'/'+pagesize+'/'+this.companyName, this.httpOptions);
    } 

    setCrmActivityStatusValues(info:any){
      info.companyName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.post(this.mainUrl + 'api/setCrmActivityStatusValues',(info) , this.httpOptions);
    }
    
    deleteCrmActivityStatusValue(params:any):Observable<any>{
      return this.http.delete(this.mainUrl+'api/deleteCrmActivityStatusValue/'+params.demoStatusId+'/'+params.empid+'/'+this.companyName, this.httpOptions);
    }
    
    getCrmClientStatusValues():Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.get(this.mainUrl+'api/getCrmClientStatusValues/'+this.companyName, this.httpOptions);
    }  

    setCrmClientStatusValues(info:any){
      info.companyName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.post(this.mainUrl + 'api/setCrmClientStatusValues',(info) , this.httpOptions);
    }  

    setCrmClientReportColumnConfiguration(info:any){
      info.companyName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.post(this.mainUrl + 'api/setCrmClientReportColumnConfiguration',(info) , this.httpOptions);
    }

    deleteCrmClientStatusValues(params:any):Observable<any>{
      return this.http.delete(this.mainUrl+'api/deleteCrmClientStatusValues/'+params.statusId+'/'+params.empid+'/'+this.companyName, this.httpOptions);
    }

    getCountry(  status: any, page: any, size: any): Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
        return this.http.get(this.mainUrl + 'api/getMastertable/' + 'countrymaster' + '/' + status + '/' + page + '/' + size + '/' + this.companyName, this.httpOptions);
    }
    setCrmClientInformation(info:any){
      info.companyName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.post(this.mainUrl + 'api/setCrmClientInformation',(info) , this.httpOptions);
    }

    setCrmActivityDataWithTracking(info:any){
      info.companyName = sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.post(this.mainUrl + 'api/setCrmActivityDataWithTracking',(info) , this.httpOptions);
    }

    setCrmEmployeeAttendanceRegularization(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.post(this.mainUrl+'api/setCrmEmployeeAttendanceRegularization',JSON.stringify(data),this.httpOptions);
    }
    getCrmAttendanceTrackingDetails(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmAttendanceTrackingDetails/'+JSON.stringify(data),this.httpOptions);
    }
    getCrmClientFilteredList(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmClientFilteredList/'+JSON.stringify(data),this.httpOptions);
    }
    getClientFilteredListLatest(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getClientFilteredListLatest/'+JSON.stringify(data),this.httpOptions);
    }

    getCrmClientsDashboardSelected(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmClientsDashboardSelected/'+JSON.stringify(data),this.httpOptions);
    }

    getCrmClientDataBySelection(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmClientDataBySelection/'+JSON.stringify(data),this.httpOptions);
    }
    getCrmAllEmployeesCurrentLocations(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmAllEmployeesCurrentLocations/'+JSON.stringify(data),this.httpOptions);
    }
    getCrmSelectedEmployeeTrackLocations(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmSelectedEmployeeTrackLocations/'+JSON.stringify(data),this.httpOptions);
    }

    getCrmSelectedEmployeeTrackLocations_live(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.get(this.mainUrl+'api/getCrmSelectedEmployeeTrackLocations_live/'+JSON.stringify(data),this.httpOptions);
    }
    

    setCrmEmployeeAttendance(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.post(this.mainUrl + 'api/setCrmEmployeeAttendance',JSON.stringify(data), this.httpOptions);
    }
    setCrmDeviceApprovalStatusForEmployee(data:any):Observable<any>{
      data.companyName = this.companyName;
      return this.http.post(this.mainUrl + 'api/setCrmDeviceApprovalStatusForEmployee',JSON.stringify(data), this.httpOptions);
    }

    fetchMapLocationDetails(data:any):Observable<any>{
      let address = null;
        try{
          address =  this.http.get(`https://geocode.maps.co/reverse?lat=${data.lat}&lon=${data.lng}&api_key=658a778d9ddf1122840694manecee77`);
          }
        catch{
          //error logs
          address = this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lng}&key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4`);
        }            
      return address;
    }

    getCrmActivityTypeValues():Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.get(this.mainUrl+'api/getCrmActivityTypeValues/'+this.companyName, this.httpOptions);
    }  
  
    getCrmClientListTypes(empId:any,userRole:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.get(this.mainUrl+'api/getCrmClientListTypes/'+ empId +'/'+userRole + '/'+this.companyName, this.httpOptions);
    }  

    getCrmClientDashboardTypes(empId:any,userRole:any,dashboard_id:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.get(this.mainUrl+'api/getCrmClientDashboardTypes/'+ empId +'/'+userRole  + '/'+dashboard_id  + '/'+this.companyName, this.httpOptions);
    }  

    getCrmClientDashboardNames(empId:any,userRole:any):Observable<any>{
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return this.http.get(this.mainUrl+'api/getCrmClientDashboardNames/'+ empId +'/'+userRole + '/'+this.companyName, this.httpOptions);
    }  

  setDemoStatus(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmActivityStatusValues',JSON.stringify(data), this.httpOptions);
  }

  getCrmFollowUps(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmFollowUps/'+JSON.stringify(data),this.httpOptions);
  }
  getCrmEmployeesForManagers(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmEmployeesForManagers/'+JSON.stringify(data),this.httpOptions);
  }
  
  getCrmActivitiesWithTrackingDetails(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmActivitiesWithTrackingDetails/'+JSON.stringify(data),this.httpOptions);
  }

  getCrmClientReportColumnConfigures(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientReportColumnConfigures/'+JSON.stringify(data),this.httpOptions);
  }
  getCrmClientSelectedColumnsReport(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl+'api/getCrmClientSelectedColumnsReport',data,this.httpOptions);
  }

   
  setCrmFilesMaster(info:any): Observable<any> {
    info.companyName= this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmFilesMaster/', info, this.httpOptions);
  }

  getFilepathsMaster(moduleId:any):Observable<any>{
    return this.http.get(this.mainUrl + 'api/getFilepathsMaster/'+moduleId+'/'+this.companyName, this.httpOptions);
  }

  setCapturedImageForTracking(data: FormData): Observable<any> {
    return this.http.post(this.mainUrl + 'api/setCapturedImageForTracking/', data);
  }

  getCrmFilesMaster(info: any): Observable<any>{
    info.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/getCrmFilesMaster/', info,this.httpOptions);
  }

  getCrmEmployeeTracesSummery(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmEmployeeTracesSummery/'+JSON.stringify(data),this.httpOptions);
  }

  getCrmEmployeeTracesSummeryForSupeerAdmin(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmEmployeeTracesSummeryForSupeerAdmin/'+JSON.stringify(data),this.httpOptions);
  }
  getCrmDeviceApprovals(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmDeviceApprovals/'+JSON.stringify(data),this.httpOptions);
  }
  
  getCrmDeviceApprovalsForSelectedManagers(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmDeviceApprovalsForSelectedManagers/'+JSON.stringify(data),this.httpOptions);
  }

  setCrmEmployeeLocationRoutes(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmEmployeeLocationRoutes',JSON.stringify(data), this.httpOptions);
  }

  getCrmSelectedEmployeeRoutes(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmSelectedEmployeeRoutes/'+JSON.stringify(data),this.httpOptions);
  }  
  getDeviceInfo() {
    return this.deviceDetectorService.getDeviceInfo();
  }

  getFormattedDateTime(date:any = null){
    if(!date) 
    date = new Date();
    else date = new Date(date);
    return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }

  setCrmClientAssociateType(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmClientAssociateType',JSON.stringify(data), this.httpOptions);
  }

  setCrmClientAssociateQualificationType(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmClientAssociateQualificationType',JSON.stringify(data), this.httpOptions);
  }

  setCrmClientAssociateSpecializationType(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmClientAssociateSpecializationType',JSON.stringify(data), this.httpOptions);
  }

  
  getCrmClientAssociateTypesMaster(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateTypesMaster/'+JSON.stringify(data),this.httpOptions);
  } 

  getCrmClientAssociateTypes(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateTypes/'+JSON.stringify(data),this.httpOptions);
  } 
  getCrmClientAssociateQualificationTypesMaster(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateQualificationTypesMaster/'+JSON.stringify(data),this.httpOptions);
  } 

  getCrmClientAssociateQualificationTypes(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateQualificationTypes/'+JSON.stringify(data),this.httpOptions);
  }

  getCrmClientAssociateSpecializationTypesMaster(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateSpecializationTypesMaster/'+JSON.stringify(data),this.httpOptions);
  } 

  getCrmClientAssociateSpecializationTypes(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociateSpecializationTypes/'+JSON.stringify(data),this.httpOptions);
  } 

  
  getCrmClientAssociatesReport(data:any):Observable<any>{
    data.companyName = this.companyName;
    return this.http.get(this.mainUrl+'api/getCrmClientAssociatesReport/'+JSON.stringify(data),this.httpOptions);
  } 
  getStates(countryid: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getStates/' + countryid + '/' + this.companyName, this.httpOptions);
  }
  getCities(id: any): Observable<any> {
    return this.http.get(this.mainUrl + 'api/getCities/' + id + '/' + this.companyName,  this.httpOptions);
  }

  getCrmPaymentModes():Observable<any>{
    return this.http.get(this.mainUrl + 'crm/getCrmPaymentModes/' + this.getCompany(), this.httpOptions);
  } 
  getCrmClientsPaymentsData(data:any):Observable<any>{
    data.companyName = this.getCompany();
    return this.http.get(this.mainUrl+'crm/getCrmClientsPaymentsData/'+JSON.stringify(data),this.httpOptions);
  }
  setCrmClientTransaction(data:any):Observable<any>{
    data.companyName = this.getCompany();
    return this.http.post(this.mainUrl + 'crm/setCrmClientTransaction',JSON.stringify(data), this.httpOptions);
  }

  setCrmTracking(info:any): Observable<any> {
    info.companyName= this.companyName;
    return this.http.post(this.mainUrl + 'crm/setEmployeeCurrentTracking/', info, this.httpOptions);
  }

  getCrmImageCaptured(data:any):Observable<any>{   
    data.companyName = this.getCompany();
    return this.http.post(this.mainUrl+'crm/getCrmImageCaptured/',data ,this.httpOptions);
  }
  getCrmEmpLiveStatus(data: any): Observable<any> {
    return this.http.get(this.mainUrl + 'crm/getCrmEmpLiveStatus/' + data.empid + '/' + this.getCompany(), this.httpOptions);
  }

  getSprypleData(data: any,procedure:any): Observable<any> {
    if(data && typeof data == 'object'){
        data = JSON.stringify(data);
    }
    if(procedure)
    return this.http.get(this.mainUrl + 'api/getSprypleData/' + data + '/'+ procedure + '/' + this.getCompany(), this.httpOptions);
    else 
    return of({ status: false, Message: 'Please provide procedure' });
  }

  setCrmSelectedEmployeeLocationRoutes(info:any): Observable<any> {
    info.companyName= this.companyName;
    return this.http.post(this.mainUrl + 'api/setCrmSelectedEmployeeLocationRoutes/', info, this.httpOptions);
  }  
}
