<div mat-dialog-content>
    <h2 style="text-align: center;color: red;">Alert !</h2>
    <p>{{data.content}}</p>
    <p style="text-align: center;">{{data.message0}}</p>
    <p style="text-align: center;">{{data.message1}}</p>
    <p style="text-align: center;">{{data.message2}}</p>
    <p style="text-align: center;">{{data.query}}</p>

</div>
<div mat-dialog-actions
   fxLayout="row" style="justify-content: space-between; margin-top: 5px;">
   <button class="btn btn-primary mr-2 mb-2"  [mat-dialog-close]="data.YES" >Yes</button>
   <button class="btn btn-danger mr-2 mb-2"  [mat-dialog-close]="data.NO" >No</button>
</div>