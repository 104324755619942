 
  <div mat-dialog-content  [innerHTML]="message">  </div>
  
   <div mat-dialog-actions 
   fxLayout="row" fxLayoutAlign="center">
    <button [mat-dialog-close] class="m-1 mat-stroked-button button-save" type="submit" color="primary" >
      <span class="mat-button-wrapper">Ok</span>
      <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false"
        ng-reflect-trigger="[object HTMLButtonElement]"></div>
      <div class="mat-button-focus-overlay"></div>
    </button>
   </div>
  
  
   