import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoSpaceDirective } from '../custom-directive/no-space.directive';
import { NoConsecutiveSpecialCharsDirective } from '../custom-directive/no-consecutive-special-chars.directive';
import { NoLeadingWhitespaceDirectiveDirective } from '../custom-directive/no-leading-whitespace-directive.directive';
import { OnlyNumberDirective } from '../custom-directive/only-number.directive';
import { OnlyCharactorDirective } from '../custom-directive/only-charactors.directive';
import { NumbersWithAlphabetsDirective } from '../custom-directive/numbers-with-alphabets/numbers-with-alphabets.directive';
import { PreventLeadingZeroDirective } from '../custom-directive/prevent-leading-zero/prevent-leading-zero.directive';
import { AlphabetNumberWhitespaceDirective } from '../custom-directive/alphabets-numbers-emptyspace/alphabet-number-whitespace.directive';
import { NumberDotOnlyDirective } from '../custom-directive/number-with-dot/number-dot-only.directive';

@NgModule({
  declarations: [
    NoSpaceDirective,
    NoConsecutiveSpecialCharsDirective,
    NoLeadingWhitespaceDirectiveDirective,
    OnlyNumberDirective,
    OnlyCharactorDirective,
    NumbersWithAlphabetsDirective,
    PreventLeadingZeroDirective,
    AlphabetNumberWhitespaceDirective,
    NumberDotOnlyDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NoSpaceDirective,
    NoConsecutiveSpecialCharsDirective,
    NoLeadingWhitespaceDirectiveDirective,
    OnlyNumberDirective,
    OnlyCharactorDirective,
    NumbersWithAlphabetsDirective,
    PreventLeadingZeroDirective,
    AlphabetNumberWhitespaceDirective,
    NumberDotOnlyDirective
  ]
})
export class SharedModule { }
