<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="assignAssetForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>Assign Asset</mat-card-title>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
       
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee ID/Name</mat-label>
                    <mat-select formControlName="empName" required>
                        <ng-container>
                            <div *ngFor="let b of employeeList">
                             <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="assignAssetForm.controls.empName.hasError('required')">
                        Please select employee
                     </mat-error> 
                </mat-form-field>
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Applied Date</mat-label>                                
                    <input matInput [matDatepicker]="picker" formControlName="appliedDate">                                
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> 
                </mat-form-field>
                
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Category</mat-label>
                    <mat-select required formControlName="assetCategory">
                        <ng-container>
                            <div *ngFor="let b of assetCategoryList">
                                  <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="assignAssetForm.controls.assetCategory.hasError('required')">
                        Please select asset category
                     </mat-error>
                </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Type</mat-label>
                    <mat-select formControlName="assetType" required>
                        <ng-container>
                            <div *ngFor="let b of assetTypeList">
                              <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="assignAssetForm.controls.assetType.hasError('required')">
                        Please select asset type
                     </mat-error>
                </mat-form-field>
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset name</mat-label>
                    <mat-select formControlName="assetName" required>
                        <ng-container>
                            <div *ngFor="let b of assetNameList">
                                  <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="assignAssetForm.controls.assetName.hasError('required')">
                        Please select asset name
                     </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" required>
                        <ng-container>
                            <div *ngFor="let b of statusList">
                                <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="assignAssetForm.controls.status.hasError('required')">
                        Please select status
                     </mat-error>
                </mat-form-field>
                
             </div>
            <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </div>
        </mat-card>
    </form>

    <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%"  fxLayoutAlign="space-between center" *ngIf="isdata">
        <span style="padding-top: 5px; padding-left: 10px;">
            <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
             </span>
             <span style="padding-right: 7px;">
               <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="submit">
                   <span>Add</span>
               </button>
             </span>
    </div>
  

        <div fxLayout="column" class="tableP" *ngIf="isdata">
        <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="cname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned Date</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Emp Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Type</th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Return Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>


</div>


</div>