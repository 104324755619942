import { Injectable,LOCALE_ID,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable,of } from "rxjs/index";
import {environment} from "../../../environments/environment";
import { DatePipe } from '@angular/common';
@Injectable({
    providedIn: 'root'
  })

  export class ClaimsService {
    mainUrl = environment.apiUrl;
    userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    httpOptions:any;
    companyName:any;
    constructor(@Inject(LOCALE_ID) private locale: string,private http: HttpClient,private pipe: DatePipe,
       //private deviceDetectorService: DeviceDetectorService
        ) {

      this.httpOptions = {
        headers: new HttpHeaders({
          'content-Type': 'application/json',
          "Authorization": JSON.parse(JSON.stringify(sessionStorage.getItem('token') || '')),
        })
      };
    }

    getCompany(){
      this.companyName=sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
      return sessionStorage.getItem("companyName")?sessionStorage.getItem("companyName"):null;
    }

    getMastertable(tableName:any,status:any,page:any,size:any,companyName:any):Observable<any>{
        return this.http.get(this.mainUrl+'api/getMastertable/'+tableName+'/'+status+'/'+page+'/'+size+'/'+this.getCompany(), this.httpOptions);
    }

    getCountry(  status: any, page: any, size: any): Observable<any>{
        return this.http.get(this.mainUrl + 'api/getMastertable/' + 'countrymaster' + '/' + status + '/' + page + '/' + size + '/' + this.getCompany(), this.httpOptions);
    }

    getClaimsReimbursementTypes(pagenumber:any,pagesize:any):Observable<any>{
           return this.http.get(this.mainUrl+'claims/getClaimsReimbursementTypes/'+pagenumber+'/'+pagesize+'/'+this.getCompany(), this.httpOptions);
    }
    getClaimsActiveReimbursementTypes():Observable<any>{
      let emp_id=0;
      return this.http.get(this.mainUrl+'claims/getClaimsActiveReimbursementTypes/'+emp_id+'/'+this.getCompany(), this.httpOptions);
   }

    getClaimsReimbursementRequests(data:any):Observable<any>{
      data.companyName=this.getCompany();
      return this.http.get(this.mainUrl+'claims/getClaimsReimbursementRequests/'+JSON.stringify(data),this.httpOptions);
    }

    setClaimsReimbursementTypes(info:any){
      info.companyName=this.getCompany();
      return this.http.post(this.mainUrl + 'claims/setClaimsReimbursementTypes',(info) , this.httpOptions);
    }

    setClaimsEmployeeReimbursementRequest(info:any){
      info.companyName=this.getCompany();
      return this.http.post(this.mainUrl + 'claims/setClaimsEmployeeReimbursementRequest',(info) , this.httpOptions);
    }

    getFormattedDateTime(date:any = null){
      if(!date)
      date = new Date();
      else date = new Date(date);
      return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
    }

    getFormattedDate(date:any = null) {
      if(!date)
      date = new Date();
      else date = new Date(date);
      return this.pipe.transform(date, 'yyyy-MM-dd');
    }

    getClaimsEmployeeWiseDetailedReport(data:any):Observable<any>{
      data.companyName=this.getCompany();
      return this.http.get(this.mainUrl+'claims/getClaimsEmployeeWiseDetailedReport/'+JSON.stringify(data),this.httpOptions);
    }


}
