<div >
<div *ngIf="data && !data.flag" mat-dialog-content text-right >
  {{data.Message}}
</div>
<div *ngIf="data && data.flag" mat-dialog-content >
  <ul *ngFor="let error of data.Message">
    <li style="color: red">{{error.message}}</li>
  </ul>
</div>
<div mat-dialog-actions
     fxLayout="row" fxLayoutAlign="center" >
  <button [mat-dialog-close] class="m-1 mat-stroked-button button-save" (click)="close()" type="submit" color="primary" >
    <span class="mat-button-wrapper">Ok</span>
    <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false"
         ng-reflect-trigger="[object HTMLButtonElement]"></div>
    <div class="mat-button-focus-overlay"></div>
  </button>
</div>
</div>

