<div fxLayout="column">
    <mat-card>
        <!-- <div style="padding: 10px;">
            <mat-card style="background-color: #e7eaf3">
                <div fxLayout="row" style="padding: 15px">
                    <div fxFlex="30" fxFlex.xs="50" fxFlex.sm="50" fxLayoutAlign="center">
                        <div class="avatar-upload" *ngFor="let url of imageurls; let i = index">
                            <img class="avatar-preview" [src]="url.base64String" />
                          </div>
                     </div>

                    <div fxFlex="70" fxFlex.xs="50" fxFlex.sm="50" fxLayoutAlign="start">
                        <span>
                            <p class="subh"><span class="heading">{{employeeNameh}}</span></p>
                            <p class="subh">Designation: <span class="heading1">{{employeeDesignation}}</span> </p>
                            <p class="subh">Joining Date: <span class="heading1"> {{employeeJoinDate | date:
                                    'dd-MM-YYYY'}}</span> </p>
                            <p class="subh">Mobile: <span class="heading1">{{employeeMobile}} </span></p>
                        </span>
                    </div>
                </div>
            </mat-card>
        </div> -->
        <div>
            <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedtab.value"
                (selectedTabChange)="tabClick($event)" (selectedIndexChange)="selectedtab.setValue($event)"
                mat-stretch-tabs>
                <!-- personal info tab -->
                <mat-tab label="Personal Info">
                    <div fxLayout="column">
                        <form [formGroup]="personalInfoForm">
                            <mat-card style="padding: 1%;">
                                <div class="section"><b>Personal Information</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input type="text" formControlName="firstname" #firstname onlyAlphabets matInput
                                            placeholder="" maxlength="100" required tabindex="1" />
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.firstname.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Middle Name</mat-label>
                                        <input type="text" onlyAlphabets formControlName="middlename" matInput
                                            tabindex="2" maxlength="70" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input formControlName="lastname" onlyAlphabets matInput placeholder=""
                                            maxlength="70" required tabindex="3" />
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.lastname.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Date of Birth</mat-label>
                                        <input required matInput [matDatepicker]="dateofbirth" [max]="maxBirthDate"
                                            placeholder="" formControlName="dateofbirth" (click)="dateofbirth.open()"
                                            tabindex="4" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofbirth">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dateofbirth></mat-datepicker>
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.dateofbirth.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Blood Groups</mat-label>
                                        <mat-select formControlName="bloodgroup" tabindex="5">
                                            <ng-container>
                                                <div *ngFor="let b of bloodGroupdetails">
                                                    <mat-option value={{b.id}}>{{b.bloodgroup}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Gender</mat-label>
                                        <mat-select formControlName="gender" required tabindex="6">
                                            <ng-container>
                                                <div *ngFor="let b of genderDetails">
                                                    <mat-option [value]="b.id">{{b.gender}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.gender.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Marital Status</mat-label>
                                        <mat-select formControlName="maritalstatus" required tabindex="7">
                                            <ng-container>
                                                <div *ngFor="let b of maritalStatusDetails">
                                                    <mat-option [value]="b.id">{{
                                                        b.maritalstatus
                                                        }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.maritalstatus.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Aadhar Number</mat-label>
                                        <input type="text" onlyNumber formControlName="aadharNumber" matInput
                                            maxlength="12" tabindex="8" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Personal Email</mat-label>
                                        <input type="text" formControlName="personalemail" matInput tabindex="9"
                                            maxlength="50" />
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" fxFlex.sm="50" class="mx-2 my-2"
                                        appearance="outline">
                                        <mat-label>Languages Spoken</mat-label>
                                        <input type="text" formControlName="spokenLanguages" matInput tabindex="10"
                                            onlyAlphabets maxlength="100" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Mobile Number</mat-label>
                                        <input type="text" onlyNumber formControlName="mobileNo" matInput tabindex="11"
                                            maxlength="10" onlyNumber required />
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.mobileNo.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                        <mat-error class="reg-error" *ngIf="
                                    personalInfoForm.controls.mobileNo.errors
                                      ?.minlength ||
                                    personalInfoForm.controls.mobileNo.errors
                                      ?.maxlength ||
                                    personalInfoForm.controls.mobileNo.errors
                                      ?.pattern"> Please enter valid number
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Emergency Contact Number</mat-label>
                                        <input type="text" onlyNumber formControlName="alternateMobileNo" matInput
                                            tabindex="12" maxlength="10" minlength="10" onlyNumber required />
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.alternateMobileNo.errors?.required">
                                            {{ EM1 }}
                                        </mat-error>
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.alternateMobileNo.errors?.minlength ||
                                    personalInfoForm.controls.alternateMobileNo.errors?.maxlength || 
                                    personalInfoForm.controls.alternateMobileNo.errors?.pattern">
                                            Please enter valid number
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section"><b>Work Information</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Employee ID</mat-label>
                                        <input formControlName="empid" matInput tabindex="13" required />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Office Email</mat-label>
                                        <input type="text" maxlength="100" formControlName="officeemail" matInput
                                            tabindex="14" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Employment Type</mat-label>
                                        <mat-select formControlName="employmentType" tabindex="15" required>
                                            <ng-container>
                                                <div *ngFor="let b of EmploymentTypeDetails">
                                                    <mat-option [value]="b.id">{{
                                                        b.employmenttype
                                                        }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf=" personalInfoForm.controls.employmentType.errors?.required ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Employee Status</mat-label>
                                    <mat-select formControlName="empStatus" [disabled]="true">
                                        <ng-container>
                                            <div *ngFor="let b of statusList">
                                                <mat-option [value]="b.id">{{b.name}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field> -->
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">


                                    <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Role</mat-label>
                                        <mat-select [disabled]="true" formControlName="usertype" tabindex="16">
                                            <ng-container>
                                                <div *ngFor="let b of availableRole">
                                                    <mat-option [value]="b.id">{{ b.name }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field> -->
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Role</mat-label>
                                        <textarea type="text" formControlName="usertype" matInput placeholder=""
                                            maxlength="250" required>
                                         </textarea>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Company Location</mat-label>
                                        <mat-select formControlName="companylocation" tabindex="17" required>
                                            <ng-container>
                                                <div *ngFor="let b of worklocationDetails">
                                                    <mat-option [value]="b.id">{{ b.location }} - {{ b.cityname
                                                        }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error" *ngIf="
                                        personalInfoForm.controls.companylocation.errors?.required ">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Designation</mat-label>
                                        <mat-select formControlName="designation" tabindex="18" required>
                                            <ng-container>
                                                <div *ngFor="let b of availableDesignations">
                                                    <mat-option [value]="b.id">{{b.designation}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.designation.errors?.required">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">


                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Department</mat-label>
                                        <mat-select formControlName="department" tabindex="19" required>
                                            <ng-container>
                                                <div *ngFor="let b of availableDepartments">
                                                    <mat-option [value]="b.id">{{ b.deptname }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.department.errors?.required">
                                            {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Finance Manager</mat-label>
                                        <mat-select [disabled]="true" formControlName="reportingmanager" tabindex="20">
                                            <ng-container *ngFor="let b of availablereportingmanagers">
                                                <mat-option [value]="b.id">{{b.employee_name}}</mat-option>
                                            </ng-container>
                                            <mat-option value="Self" *ngIf="isself">Self</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>HR Manager</mat-label>
                                        <mat-select [disabled]="true" formControlName="reportingmanager" tabindex="20">
                                            <ng-container *ngFor="let b of availablereportingmanagers">
                                                <mat-option [value]="b.id">{{b.employee_name}}</mat-option>
                                            </ng-container>
                                            <mat-option value="Self" *ngIf="isself">Self</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Reporting Manager Department</mat-label>
                                        <mat-select formControlName="rdepartment" tabindex="19">
                                            <ng-container>
                                                <div *ngFor="let b of availableDepartments">
                                                    <mat-option [value]="b.id">{{ b.deptname }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Reporting Manager</mat-label>
                                        <mat-select formControlName="reportingmanager" tabindex="20" required>
                                            <ng-container *ngFor="let b of availablereportingmanagers">
                                                <mat-option [value]="b.id">{{b.employee_name}}</mat-option>
                                            </ng-container>
                                            <mat-option value="Self" *ngIf="isself">Self</mat-option>
                                        </mat-select>
                                        <mat-error class="con-error" *ngIf="
                                        personalInfoForm.controls.reportingmanager.errors?.required "> {{ EM2 }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">



                                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Notice Period</mat-label>
                                        <input type="text" formControlName="noticePeriod" matInput tabindex="21"
                                            maxlength="3" required (keypress)="numbersOnly($event)" />
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.noticePeriod.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="section"><b>Personal Contact Information</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end"
                                    style="padding-top:1% ;padding-bottom:1% ;">

                                    <mat-slide-toggle style="padding-right: 11px;" formControlName="checked"
                                        tabindex="22" color="primary"><b>Same as residence
                                            address</b></mat-slide-toggle>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Residence Address</mat-label>
                                        <input type="text" formControlName="raddress" matInput placeholder=""
                                            maxlength="100" required tabindex="23">
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.raddress.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Permanent Address</mat-label>
                                        <input type="text" formControlName="paddress" matInput placeholder=""
                                            maxlength="100" tabindex="24">
                                        <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                                    </mat-form-field>

                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <mat-select required formControlName="rcountry" required tabindex="25">
                                            <input matInput [formControl]="searchControl" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredrCountryDetails">
                                                    <mat-option [value]="b.id">{{b.country}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.rcountry.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <mat-select formControlName="pcountry" tabindex="26">
                                            <input matInput [formControl]="searchControlforPCountryd" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredpCountryDetails">
                                                    <mat-option [value]="b.id">{{b.country}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="rstate" required tabindex="27">
                                            <input matInput [formControl]="searchControlforrstate" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredrstateDetails">
                                                    <mat-option [value]="b.id">{{b.state}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.rstate.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="pstate" tabindex="28">
                                            <input matInput [formControl]="searchControlpstate" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredpstateDetails">
                                                    <mat-option [value]="b.id">{{b.state}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>City</mat-label>
                                        <mat-select formControlName="rcity" required tabindex="29">
                                            <input matInput [formControl]="searchControlforrcity" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredCityDetails">
                                                    <mat-option [value]="b.id">{{b.location}}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.rcity.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>City</mat-label>
                                        <mat-select formControlName="pcity" tabindex="30">
                                            <input matInput [formControl]="searchControlforpcity" type="text"
                                                placeholder="  Search" class="search-align">
                                            <ng-container>
                                                <div *ngFor="let b of filteredpCityDetails">
                                                    <mat-option [value]="b.id">{{b.location}}</mat-option>
                                                </div>

                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Pincode</mat-label>
                                        <input type="text" onlyNumber formControlName="rpincode" matInput placeholder=""
                                            maxlength="6" minlength="6" required tabindex="31" />
                                        <mat-error class="con-error"
                                            *ngIf="personalInfoForm.controls.rpincode.errors?.required">
                                            {{ EM1 }}
                                        </mat-error>
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.rpincode.errors?.minlength">
                                            Pincode should be 6 digits
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Pincode</mat-label>
                                        <input type="text" onlyNumber formControlName="ppincode" matInput placeholder=""
                                            maxlength="6" minlength="6" tabindex="32" />
                                        <mat-error class="reg-error"
                                            *ngIf="personalInfoForm.controls.ppincode.errors?.minlength">
                                            Pincode should be 6 digits
                                        </mat-error>
                                    </mat-form-field>

                                </div>

                                <form [formGroup]="candidateFamilyForm">
                                    <div class="section" fxLayout="row">
                                        <span style="padding-top: 3px;"><b>Family Details : </b></span>
                                        <span style="padding-left: 1%;">
                                            <mat-icon title="Add"
                                                style="color:#28acaf;font-size: 35px;overflow: visible"
                                                (click)="addingFamilyView()" *ngIf="editable">add_box</mat-icon>
                                        </span>
                                    </div>


                                    <div *ngIf="addFamilyView">
                                        <mat-divider></mat-divider>
                                        <br>
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                                appearance="outline">
                                                <mat-label>Name</mat-label>
                                                <input type="text" formControlName="familyfirstname" matInput
                                                    placeholder="" maxlength="100" required tabindex="33" />
                                                <mat-error class="con-error"
                                                    *ngIf="candidateFamilyForm.controls.familyfirstname.errors?.required">
                                                    {{EM1}}
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                                appearance="outline">
                                                <mat-label>Contact Number</mat-label>
                                                <input type="text" formControlName="familycontact" matInput
                                                    placeholder="" maxlength="10" minlength="10" onlyNumber
                                                    tabindex="34" />
                                                <mat-error class="reg-error" *ngIf="candidateFamilyForm.controls.familycontact.errors?.minlength ||
                                        candidateFamilyForm.controls.familycontact.errors?.maxlength || 
                                        candidateFamilyForm.controls.familycontact.errors?.pattern">
                                                    Please enter valid number
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                                appearance="outline">
                                                <mat-label>Gender</mat-label>
                                                <mat-select formControlName="familygender" required tabindex="35">
                                                    <ng-container>
                                                        <div *ngFor="let b of genderDetails">
                                                            <mat-option [value]="b">{{ b.gender }}</mat-option>
                                                        </div>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error class="con-error" *ngIf="
                          candidateFamilyForm.controls.familygender.errors
                            ?.required
                        ">
                                                    {{ EM2 }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
                                            fxLayoutAlign.xs="center">
                                            <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                                appearance="outline">
                                                <mat-label>Relationship</mat-label>
                                                <mat-select required formControlName="relation" tabindex="36">
                                                    <ng-container>
                                                        <div *ngFor="let b of employeeRelationship">
                                                            <mat-option [value]="b">{{
                                                                b.relationship
                                                                }}</mat-option>
                                                        </div>
                                                    </ng-container>


                                                </mat-select>
                                                <mat-error class="con-error" *ngIf="
                                                candidateFamilyForm.controls.relation.errors?.required
                                              ">
                                                    {{ EM2 }}
                                                </mat-error>
                                            </mat-form-field>

                                            <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                                appearance="outline">
                                                <mat-label>Status</mat-label>
                                                <mat-select required formControlName="familystatus" tabindex="37">
                                                    <ng-container>
                                                        <div>
                                                            <mat-option value="Alive">Alive</mat-option>
                                                        </div>
                                                    </ng-container>
                                                </mat-select>
                                            </mat-form-field>
                                            <div fxLayout="row">
                                                <span style="padding-top:5%">
                                                    <button *ngIf="!isfamilyedit" tabindex="38"
                                                        (keyup.enter)="addfamily()" class="btn btn-primary mr-2 mb-2"
                                                        (click)="addfamily()">
                                                        <span> Add </span>
                                                    </button>
                                                    <button *ngIf="isfamilyedit" tabindex="38"
                                                        (keyup.enter)="addfamily()" class="btn btn-primary mr-2 mb-2"
                                                        (click)="addfamily()">
                                                        <span> Update </span>
                                                    </button>
                                                    <button class="btn btn-danger mr-2 mb-2" (click)="clearfamily()"
                                                        tabindex="39" (keyup.enter)="clearfamily()">
                                                        Clear
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mat-elevation-z1" *ngIf="familyDetails.length > 0">
                                        <table mat-table [dataSource]="familyDataSource">
                                            <ng-container matColumnDef="position">
                                                <th mat-header-cell *matHeaderCellDef> S No </th>
                                                <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                                <td mat-cell *matCellDef="let data">
                                                    {{ data.firstname }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="relation">
                                                <th mat-header-cell *matHeaderCellDef>Relationship</th>
                                                <td mat-cell *matCellDef="let data">
                                                    {{ data.relationshipname }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="gender">
                                                <th mat-header-cell *matHeaderCellDef>Gender</th>
                                                <td mat-cell *matCellDef="let data">
                                                    {{ data.gendername }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="contact">
                                                <th mat-header-cell *matHeaderCellDef>Contact Number</th>
                                                <td mat-cell *matCellDef="let data">
                                                    <span *ngIf="data.contactnumber != 'null'">{{ data.contactnumber
                                                        }}</span>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                                <td mat-cell *matCellDef="let data">{{ data.status }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef></th>
                                                <td mat-cell *matCellDef="let element; let i = index">
                                                    <button mat-icon-button (keyup.enter)="editfamily(i)"
                                                        *ngIf="!isviewemployee && element.status == 'Alive' && editable">
                                                        <mat-icon title="Edit" (click)="editfamily(i)">edit
                                                        </mat-icon>
                                                    </button>
                                                    <button mat-icon-button (keyup.enter)="deleteFamilyPopup(i)"
                                                        *ngIf="!isviewemployee && element.status == 'Alive'  && editable ">
                                                        <mat-icon title="Delete" (click)="deleteFamilyPopup(i)">
                                                            delete</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="familyTableColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: familyTableColumns"></tr>
                                        </table>
                                    </div>
                                </form>
                            </mat-card>
                            <mat-divider></mat-divider>
                            <br>
                            <div fxLayoutAlign="center">
                                <button class="btn btn-primary custom mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                    (click)=" savePersonalInfo()" type="submit" [tabIndex]="40">
                                    <span>Save & Continue</span>
                                </button>
                                <br />
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <!-- job tab -->
                <mat-tab label="Contract/Promotions">
                    <div fxLayout="column">
                        <mat-card style="padding-left: 1%;">
                            <div *ngIf="isContractData">
                                <form [formGroup]="employeeJobForm">
                                    <div class="section"><b>Employee Contract</b></div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                        style="padding-top: 2%">
                                        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Contract Name</mat-label>
                                            <input type="text" readonly formControlName="contractName" matInput
                                                placeholder="" maxlength="100" />
                                        </mat-form-field>

                                        <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Start Date</mat-label>
                                            <input matInput readonly [matDatepicker]="contractStartDate" [min]="minDate"
                                                [max]="maxDate" placeholder="" [disabled]="true"
                                                formControlName="contractStartDate">
                                            <mat-datepicker-toggle class="datepicker" matSuffix
                                                [for]="contractStartDate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #contractStartDate></mat-datepicker>
                                        </mat-form-field>

                                        <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>End Date</mat-label>
                                            <input matInput readonly [matDatepicker]="contractEndDate" [min]="minDate"
                                                [max]="maxDate" placeholder="" [disabled]="true"
                                                formControlName="contractEndDate" />
                                            <mat-datepicker-toggle class="datepicker" matSuffix [for]="contractEndDate">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #contractEndDate></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                        <!-- <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Attach File</mat-label>
                                    <input type="text" readonly formControlName="contractFile" matInput placeholder=""
                                        />
                                </mat-form-field> -->
                                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2"
                                            appearance="outline">
                                            <mat-label>Contract Notes</mat-label>
                                            <textarea readonly formControlName="contractNotes" matInput placeholder=""
                                                maxlength="400"></textarea>
                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                            <div class="section"><b>Promotions</b></div>
                            <form [formGroup]="promotionsForm">
                                <div class="mat-elevation-z1">
                                    <table mat-table [dataSource]="promotionsDataSource">
                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef>S No</th>
                                            <td mat-cell *matCellDef="let data; let i = index">
                                                {{ i + 1 }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="salary">
                                            <th mat-header-cell *matHeaderCellDef>Annual Salary</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.annualsalary }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="fromDate">
                                            <th mat-header-cell *matHeaderCellDef>Effective Date</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.effectivedate | date: "dd-MM-yyyy"}}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="promotionsTableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: promotionsTableColumns"></tr>
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </form>
                        </mat-card>
                    </div>

                </mat-tab>
                <!-- employement tab -->
                <mat-tab label="Work Experience">
                    <div fxLayout="column">
                        <mat-card style="padding-left: 1%;">
                            <form [formGroup]="experienceForm">
                                <div class="section"><b>Work Experience</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Company Name</mat-label>
                                        <input type="text" formControlName="companyName" onlyAlphabets matInput
                                            placeholder="" maxlength="100" required noLeadingWhitespace />
                                        <mat-error class="con-error"
                                            *ngIf="experienceForm.controls.companyName.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Designation</mat-label>
                                        <input type="text" required formControlName="designation" onlyAlphabets matInput
                                            placeholder="" maxlength="30" noLeadingWhitespace />
                                        <mat-error class="reg-error"
                                            *ngIf="experienceForm.controls.designation.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>From Date</mat-label>
                                        <input matInput required readonly [matDatepicker]="expFromDate" [min]="minDate"
                                            [max]="maxDate" placeholder="" formControlName="expFromDate"
                                            (click)="expFromDate.open()" (dateChange)="onExpDateChange()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="expFromDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #expFromDate></mat-datepicker>
                                        <mat-error class="con-error"
                                            *ngIf="experienceForm.controls.expFromDate.errors?.required">
                                            Please select date
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100%" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                        <mat-label>To Date</mat-label>
                                        <input required matInput readonly [matDatepicker]="expToDate"
                                            [min]="minExperienceDate" [max]="maxDate" placeholder=""
                                            formControlName="expToDate" (click)="expToDate.open()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="expToDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #expToDate></mat-datepicker>
                                        <mat-error class="con-error"
                                            *ngIf="experienceForm.controls.expToDate.errors?.required">
                                            Please select date
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column">
                                    <div fxFlex="50" fxFlex.xs="100">
                                        <mat-form-field fxFlex="100" class="mx-2 my-2" appearance="outline">
                                            <mat-label>Job Description</mat-label>
                                            <textarea formControlName="jobDescription" matInput noLeadingWhitespace
                                                maxlength="400"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                                    <span>
                                        <button class="btn btn-primary mr-2 mb-2"
                                            [attr.disabled]="!editable ? '' : null" (click)="addWorkExperience()">
                                            <span *ngIf="!isExperienceEdit"> Save </span>
                                            <span *ngIf="isExperienceEdit"> Update </span>
                                        </button>
                                        <button *ngIf="!isExperienceEdit" class="btn btn-primary custom1 mr-2 mb-2"
                                            [attr.disabled]="!editable ? '' : null" (click)="submitAdd()">
                                            <span> Save & Add </span>
                                        </button>
                                        <button class="btn btn-danger mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                            (click)="clearWorkExperience()">
                                            Clear
                                        </button>
                                    </span>
                                </div>
                            </form>
                            <div class="mat-elevation-z1" *ngIf="workExperienceDetails.length > 0">
                                <table mat-table [dataSource]="workExperienceDataSource">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.companyname }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="desig">
                                        <th mat-header-cell *matHeaderCellDef>Designation</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.designation }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.fromdate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="toDate">
                                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                                        <td mat-cell *matCellDef="let data" class="no-break">
                                            {{ data.todate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <button mat-icon-button (keyup.enter)="editExperience(i)">
                                                <mat-icon title="Edit" (click)="editExperience(i)"
                                                    *ngIf="!isviewemployee && editable">edit
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button (keyup.enter)="deleteExperiencePopup(i)">
                                                <mat-icon title="Delete" (click)="deleteExperiencePopup(i)"
                                                    *ngIf="!isviewemployee && editable">
                                                    delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="workTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: workTableColumns"></tr>
                                </table>
                            </div>
                            <br>

                        </mat-card>
                    </div>
                </mat-tab>
                <!-- bank details tab -->
                <mat-tab label="Bank Details">
                    <div fxLayout="column">
                        <mat-card style="padding-left: 1%;">
                            <form [formGroup]="employementForm">
                                <div class="section"><b>Bank Details</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>Bank Name</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="bankName" matInput />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>Name as per bank account</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="bankAccountName" matInput placeholder="" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>Account Number</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="bankAccountNumber" matInput placeholder="" />
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>IFSC Code</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="ifscCode" matInput placeholder="" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>Branch Name</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="branchName" matInput placeholder="" />
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                                        <mat-label>UAN Number</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="uanNumber" matInput placeholder="" max="12" />
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                        <mat-label>PAN Number</mat-label>
                                        <input type="text" readonly [ngClass]="{'disable': true}"
                                            formControlName="panNumber" matInput placeholder="" />
                                    </mat-form-field>


                                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                        <mat-label>ESI Number</mat-label>
                                        <input matInput mask="00-00-000000-000-0000" formControlName="esiNumber"
                                            placeholder="" readonly [ngClass]="{'disable': true}" />
                                    </mat-form-field>

                                </div>
                            </form>
                        </mat-card>
                    </div>
                </mat-tab>

                <!-- education tab -->
                <mat-tab label="Education">
                    <div fxLayout="column">
                        <mat-card style="padding-left: 1%;">
                            <form [formGroup]="educationForm">
                                <div class="section"><b>Education</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Course</mat-label>
                                        <input required type="text" maxlength="100" formControlName="course" matInput
                                            placeholder="" noLeadingWhitespace onlyAlphabets />
                                        <mat-error class="con-error"
                                            *ngIf="educationForm.controls.course.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Institute Name</mat-label>
                                        <input required type="text" maxlength="100" onlyAlphabets
                                            formControlName="instituteName" matInput noLeadingWhitespace
                                            onlyAlphabets />
                                        <mat-error class="con-error"
                                            *ngIf="educationForm.controls.instituteName.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>From Date</mat-label>
                                        <input required matInput readonly [matDatepicker]="eduFromDate"
                                            [max]="edmaxDate" placeholder="" formControlName="eduFromDate"
                                            (click)="eduFromDate.open()" (dateChange)="onEduDateChange()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduFromDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #eduFromDate></mat-datepicker>
                                        <mat-error class="con-error"
                                            *ngIf="educationForm.controls.eduFromDate.errors?.required">
                                            Please select date
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                        <mat-label>To Date</mat-label>
                                        <input required matInput readonly [matDatepicker]="eduToDate"
                                            [min]="minEducationDate" [max]="edmaxDate" placeholder=""
                                            formControlName="eduToDate" (click)="eduToDate.open()" />
                                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduToDate">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #eduToDate></mat-datepicker>
                                        <mat-error class="con-error"
                                            *ngIf="educationForm.controls.eduToDate.errors?.required">
                                            Please select date
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="center">
                                    <button class="btn btn-primary mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                        (click)="addEducation()">
                                        <span *ngIf="!isEducationEdit"> Save </span>
                                        <span *ngIf="isEducationEdit"> Update </span>
                                    </button>
                                    <button *ngIf="!isEducationEdit" class="btn btn-primary custom1 mr-2 mb-2"
                                        [attr.disabled]="!editable ? '' : null" (click)="educationSaveAdd()">
                                        <span> Save & Add </span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                        (click)="clearEducation()">
                                        Clear
                                    </button>
                                </div>
                                <mat-divider></mat-divider>
                                <br>
                                <div class="mat-elevation-z1" *ngIf="educationDetails.length > 0">
                                    <table mat-table [dataSource]="educationDataSource">
                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef>S No</th>
                                            <td mat-cell *matCellDef="let data; let i = index">
                                                {{ i + 1 }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="course">
                                            <th mat-header-cell *matHeaderCellDef>Course</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.course }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="college">
                                            <th mat-header-cell *matHeaderCellDef class="no-break">Institute Name</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.institutename }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fromDate">
                                            <th mat-header-cell *matHeaderCellDef>From Date</th>
                                            <td mat-cell *matCellDef="let data" class="no-break">
                                                {{ data.fromdate | date:'dd-MM-yyyy' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="toDate">
                                            <th mat-header-cell *matHeaderCellDef>To Date</th>
                                            <td mat-cell *matCellDef="let data" class="no-break">
                                                {{ data.todate | date:'dd-MM-yyyy' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element; let i = index" class="no-break">
                                                <button mat-icon-button (keyup.enter)="editEduction(i)">
                                                    <mat-icon title="Edit" (click)="editEduction(i)"
                                                        *ngIf="!isviewemployee">edit
                                                    </mat-icon>
                                                </button>
                                                <button mat-icon-button (keyup.enter)="deleteEducationPopup(i)">
                                                    <mat-icon title="Delete" (click)="deleteEducationPopup(i)"
                                                        *ngIf="!isviewemployee">
                                                        delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="educationTableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: educationTableColumns"></tr>
                                    </table>
                                </div>
                                <mat-divider></mat-divider>
                                <br>
                                <!-- <div fxLayoutAlign="center">
                                <button class="btn btn-primary custom mr-2 mb-2"  (click)="saveEducation()" type="submit">
                                    <span>Save & Continue</span>
                                </button>
                                <br />
                            </div> -->
                            </form>
                        </mat-card>
                    </div>
                </mat-tab>

                <!-- document tab -->
                <mat-tab label="Documents">
                    <div fxLayout="column">
                        <mat-card style="padding: 10px">
                            <form [formGroup]="documentsForm">
                                <div class="section"><b>Add Documents</b></div>
                                <mat-divider></mat-divider>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center"
                                    style="padding-top: 1%;">
                                    <mat-form-field fxFlex.xs="100" fxFlex="33" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Document Name</mat-label>
                                        <mat-select required formControlName="documentName">
                                            <ng-container>
                                                <div *ngFor="let b of documentTypeList">
                                                    <mat-option value="{{ b.category }}">{{ b.description
                                                        }}</mat-option>
                                                </div>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="reg-error"
                                            *ngIf="documentsForm.controls.documentName.errors?.required">
                                            {{EM2}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field fxFlex.xs="100" fxFlex="33" class="mx-2 my-2" appearance="outline">
                                        <mat-label>Document Number</mat-label>
                                        <input type="text" minlength="6" maxlength="14" formControlName="documentNumber"
                                            matInput noLeadingWhitespace required />
                                        <mat-error class="reg-error"
                                            *ngIf="documentsForm.controls.documentNumber.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                        <mat-error class="reg-error"
                                            *ngIf="documentsForm.controls.documentNumber.errors?.minlength">
                                            {{EM22}}
                                        </mat-error>
                                    </mat-form-field>

                                    <div fxLayout="row" style="align-items: center">
                                        <input title="" style="margin-left: 10px" type="file" *ngIf='!isedit'
                                            formControlName="attachedFile" (change)="onSelectFile($event)" single />

                                        <button class="link" *ngIf='isedit'>{{ editFileName }}</button>

                                        &nbsp;
                                        <mat-icon title="Delete" *ngIf='documentsForm.controls.attachedFile.value'
                                            (click)="deleteIcon()">delete</mat-icon>
                                        <mat-icon title="Edit" *ngIf='isedit' (click)="delete()">edit</mat-icon>
                                        &nbsp;
                                    </div>
                                </div>
                                <br>
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <button class="btn btn-primary mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                        (click)=" validateDocument()">
                                        <span *ngIf="!isedit"> Save </span>
                                        <span *ngIf="isedit"> Update </span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" [attr.disabled]="!editable ? '' : null"
                                        (click)="clearDock()">
                                        Clear
                                    </button>
                                </div>
                                <div class="mat-elevation-z1" *ngIf="documentDetails.length > 0">
                                    <table mat-table [dataSource]="documentDataSource">
                                        <ng-container matColumnDef="position">
                                            <th mat-header-cell *matHeaderCellDef>S No</th>
                                            <td mat-cell *matCellDef="let data; let i = index">
                                                {{ i + 1 }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.description}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="number">
                                            <th mat-header-cell *matHeaderCellDef>Document number</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.document_number }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>Status</th>
                                            <td mat-cell *matCellDef="let data">
                                                {{ data.status }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>Document</th>
                                            <td mat-cell *matCellDef="let data">
                                                <button class="link" (click)="fileView(data)">
                                                    {{ data.fname }}
                                                </button>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let data" class="no-break">
                                                <button *ngIf="data.status == 'Submitted' && editable" mat-icon-button
                                                    (keyup.enter)="editDock(data)">
                                                    <mat-icon (click)="editDock(data)" title="Edit">edit</mat-icon>
                                                </button>
                                                <button mat-icon-button (keyup.enter)="deleteDock(data)"
                                                    *ngIf="data.status == 'Submitted' && editable">
                                                    <mat-icon (click)="deleteDock(data)" title="Delete">
                                                        delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="documentTableColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: documentTableColumns"></tr>
                                    </table>
                                </div>
                            </form>
                        </mat-card>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </mat-card>
</div>