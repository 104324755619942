<div fxLayout="column" class="first-col">
    <form [formGroup]="reportForm" autocomplete="off">
      <mat-card>
        <mat-card-header class="headerbox">
             <mat-card-title class="title"> Employee Reports </mat-card-title>
            </mat-card-header>
        <div *ngIf="ishide" class="div-pad" fxLayout="column">
            
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign.gt-md="space-between center" style="height: 70px;">
              <span  style="padding-top: 1%;" fxFlex="50">
                <button mat-icon-button (keyup.enter)="popup()" [tabIndex]="1">
                    <mat-icon class="styles" title='Select' (click)="popup()">blur_linear</mat-icon>
                </button>
                    <button mat-icon-button (keyup.enter)="filter()" [tabIndex]="2">
                    <mat-icon class="styles" title='Filter' (click)="filter()">filter_alt</mat-icon>
                </button>
                    <button mat-icon-button  (keyup.enter)="exportAsXLSX()" [tabIndex]="3">
                    <mat-icon class="styles" title='Download' (click)='exportAsXLSX()'>download</mat-icon>
                   </button>
                    <!-- <mat-icon class="styles" >download</mat-icon> -->
                </span>
                <span *ngIf="allfilterdata.length>0" style="padding-top: 1%;" fxFlex="50" fxLayoutAlign="end">
                    <mat-form-field fxFlex="56" fxFlex.lt-lg="100" fxFlex.xs="100%" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input [tabIndex]="4">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                    </mat-form-field>
                     </span>
            </div>
              <div id="table" class="example-container mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > S.No </th>
                            <td mat-cell *matCellDef="let row;let i=index" >
                                <span>{{(i+1)}}</span> 
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ecode">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Emp ID </th>
                            <td mat-cell *matCellDef="let row" >
                                <!-- <div  class="truncate" 
                                [matTooltip]="row.employee_name.length > 20 ? 
                                row.employee_name: '' ">
                                {{row.employee_name}}
                            </div> -->
                            <span> {{row.employee_code}}</span>
                            </td>
                          </ng-container>
 
                        <ng-container matColumnDef="name">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef >Emp Name </th>
                            <td mat-cell *matCellDef="let row" >
                                <!-- <div  class="truncate" 
                                [matTooltip]="row.employee_name.length > 20 ? 
                                row.employee_name: '' ">
                                {{row.employee_name}}
                            </div> -->
                            <span> {{row.employee_name}}</span>
                            </td>
                          </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Office Email </th>
                            <td mat-cell *matCellDef="let row" >
                                <!-- <div  class="truncate" 
                                [matTooltip]="row.office_email.length > 25 ? 
                                row.office_email: '' ">
                                {{row.office_email}}
                            </div> -->
                            <span>{{row.office_email}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="mobile">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef >Mobile</th>
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.contact_number}} </span>
    
                            </td>
                        </ng-container>
  
  
                        <!-- <ng-container matColumnDef="education">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Education </th>
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.education}} </span>
    
                            </td>
                               
                        </ng-container>
  
                      
                        <ng-container matColumnDef="exp">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Experience </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.location}} </span>   
                            </td>
                        </ng-container> -->
                        <ng-container matColumnDef="empstatus">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Emp Status </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.status}} </span>   
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="emptype">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Emp Type </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.employment_type}} </span>   
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="dept">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Department </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.department_name}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="desg">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Designation </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.designation}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="location">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Location </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.work_location}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="gender">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Gender </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.gender}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="blood">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Blood Group </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.blood_group}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="marital">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Marital Status </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.marital_status}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="shift">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Shift </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.shift_name}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
  
                        </ng-container>
                        <ng-container matColumnDef="manager">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Manager </th>                        
                            <td mat-cell *matCellDef="let row" >
                                <span >{{row.reporting_manager}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
  
  
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns3;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
               
               </div>
               <!-- <div *ngIf="allfilterdata.length == 0" fxLayoutAlign="center">
                <p>No data found</p>
              </div> -->
                
                
        </div>

        <div  *ngIf="isview">
            <mat-card>
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between" >
                    
                    <span fxLayoutAlign="end" class="mx-2 my-2">
                        <mat-icon class="styles" (click)="filter()">filter_alt</mat-icon>
    
                    </span>
                </div>
               
               
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[0])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[0]==1" [indeterminate]="ishiding[0]==0"  (change)="empstatus($event,0)" color="primary"></mat-checkbox>Employee Status</div>
                    <ul *ngIf="ishiding[0] && (arrlist[0])=='1'" >
                        <p formArrayName="empstatus" *ngFor="let order of employeestatusFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{employeestatus[i].name}}
                        </p>
                            <!-- <div *ngFor="let b of employeestatus">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                            </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[1])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[1]==1" [indeterminate]="ishiding[1]==0" (change)="empstatus($event,1)" color="primary" ></mat-checkbox>Employee Type </div>
                    <ul *ngIf="ishiding[1] && (arrlist[1])=='1'" >
                        <p formArrayName="emptype" *ngFor="let order of emptypeFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{emptype[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of employeestatus">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[2])=='1'">  <mat-checkbox class="example-margin" [checked]="ishiding[2]==1" [indeterminate]="ishiding[2]==0" (change)="empstatus($event,2)" color="primary"></mat-checkbox>Department </div>
                    <ul *ngIf="ishiding[2] && (arrlist[2])=='1'" >
                        <p formArrayName="department" *ngFor="let order of deptFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{department[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of emptype">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[3])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[3]==1" [indeterminate]="ishiding[3]==0" (change)="empstatus($event,3)" color="primary"></mat-checkbox>Designation </div>
                    <ul *ngIf="ishiding[3] && (arrlist[3])=='1'" >
                        <p formArrayName="designation" *ngFor="let order of desgFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{designation[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of department">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[4])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[4]==1" [indeterminate]="ishiding[4]==0" (change)="empstatus($event,4)" color="primary"></mat-checkbox>Office Location </div>
                    <ul *ngIf="ishiding[4] && (arrlist[4])=='1'" >
                        <p formArrayName="location" *ngFor="let order of locationFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{location[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of designation">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[5])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[5]==1" [indeterminate]="ishiding[5]==0" (change)="empstatus($event,5)" color="primary"></mat-checkbox>Gender  </div>
                    <ul *ngIf="ishiding[5] && (arrlist[5])=='1'" >
                        <p formArrayName="gender" *ngFor="let order of genderFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{gender[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of location">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[6])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[6]==1" [indeterminate]="ishiding[6]==0"  (change)="empstatus($event,6)" color="primary"></mat-checkbox>Blood Group </div>
                    <ul *ngIf="ishiding[6] && (arrlist[6])=='1'" >
                        <p formArrayName="bloodgroup" *ngFor="let order of bloodgroupFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{bloodgroup[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of bloodgroup">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[7])=='1'"> <mat-checkbox class="example-margin"  [checked]="ishiding[7]==1" [indeterminate]="ishiding[7]==0" (change)="empstatus($event,7)" color="primary"></mat-checkbox>Marital Status</div>
                    <ul *ngIf="ishiding[7] && (arrlist[7])=='1' " >
                        <p formArrayName="maritalstatus" *ngFor="let order of maritalstatusFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{maritalstatus[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of gender">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[8])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[8]==1" [indeterminate]="ishiding[8]==0"  (change)="empstatus($event,8)" color="primary"></mat-checkbox>Shifts</div>
                    <ul *ngIf="ishiding[8] && (arrlist[8])=='1'" >
                        <p formArrayName="shift" *ngFor="let order of shiftFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{shift[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of shift">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[9])=='1'"> <mat-checkbox class="example-margin"  [checked]="ishiding[9]==1" [indeterminate]="ishiding[9]==0" (change)="empstatus($event,9)"  color="primary" ></mat-checkbox>Reporting Manager</div>
                    <ul *ngIf="ishiding[9] &&  (arrlist[9])=='1'" >
                        <p formArrayName="manager" *ngFor="let order of managerFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{manager[i].name}}
                        </p>
                        <!-- <div *ngFor="let b of manager">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary" formControlName="manager" value="2"></mat-checkbox> {{b}}</div>
                        </div> -->
                    </ul>
                    <h3 style="text-align:center;color: red;" *ngIf="arrlist.length>0">Note: Please select at least one option while filtering a field.</h3>
                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary mr-2 mb-2" (click)="search()">Search</button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="clearreport()">Clear</button>
                    </div>
                <!-- </div> -->
                
                

                
            </mat-card>
            

        </div>
        <div *ngIf="false">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
  
                <mat-form-field fxFlex.xs="100" fxFlex="30"  class="mx-2 my-2" appearance="outline">
                  <mat-label>Employee Name</mat-label>
                  <input formControlName="empname"  matInput placeholder=""readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                  <mat-label>Contact Number</mat-label>
                  <input formControlName="contact"  matInput placeholder=""readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="30"  class="mx-2 my-2" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input formControlName="email" required matInput placeholder="" >
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
  
                <mat-form-field fxFlex.xs="100" fxFlex="30"  class="mx-2 my-2" appearance="outline">
                  <mat-label>Designation</mat-label>
                  <input formControlName="empname"  matInput placeholder=""readonly>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                  <mat-label>Education</mat-label>
                  <input formControlName="education"  matInput placeholder=""readonly>
                </mat-form-field>
               
              </div>
              <div class="col-sm-6 mr2 text-right" fxLayoutAlign="end">
                <!-- <button class="col-md-2 mr-2 btn btn-primary" >Submit</button> -->
                <button class="col-md-2  btn btn-danger" (click)="clear()" >Close</button>
            </div>
        </div>
     
      </mat-card>
    </form>
</div>