import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-employee-tax-regime-selection',
  templateUrl: './employee-tax-regime-selection.component.html',
  styleUrls: ['./employee-tax-regime-selection.component.scss']
})
export class EmployeeTaxRegimeSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = [
    'select',
    'id',
    'empid',
    'empname',
    'regime',
  ];
  pageLoading = true;
  regimes: any;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  masterToggle() {

    if (this.isSomeSelected()) {
      this.selection.clear();
    } else {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  submit() {

  }
  resetform() {

  }
}
