import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { DatePipe } from '@angular/common';
import { PayrollService } from '../../payroll.service';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';

@Component({
  selector: 'app-tax-exemption-behalf-employee',
  templateUrl: './tax-exemption-behalf-employee.component.html',
  styleUrls: ['./tax-exemption-behalf-employee.component.scss']
})
export class TaxExemptionBehalfEmployeeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns = ['id', 'date', 'empid', 'empname', 'status'];
  employeeExemptionForm: any = UntypedFormGroup;
  id: any;
  pipe = new DatePipe('en-US');
  file: any;
  empId: any;
  fileURL: any;
  mappingId: any;
  categoryList: any;
  sectionsList: any;
  userSession: any;
  maximumLimit: any;
  pendingAmount: any;
  employeelist: any;
  displayMaxLimit: any;
  descriptionList: any;
  descriptionForDisplay: any = '';
  pdfName: any = null;
  pageLoading = true;
  isFile: boolean = true;
  ispdf: boolean = true;
  displayTable: boolean = true;
  displayForm: boolean = false;
  addNewField: boolean = true;
  displayAddForm: boolean = false;
  iseditDoc: boolean = true;
  sectionId: any;
  requestId: any;
  documentId: any = null;
  documentInfo: any = null;
  document: boolean = false;
  isApproved: boolean = false;
  formData: FormData = new FormData();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private payrollService: PayrollService,
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private LM: LeavesService
  ) { }

  ngOnInit(): void {

    this.employeeExemptionForm = this.formBuilder.group({
      employeeName: ['', Validators.required],
      section: ['', Validators.required],
      category: [''],
      declaredAmount: [''],
      receiptNumber: ['', Validators.required],
      submitAmount: ['', Validators.required],
      description: ['', Validators.required],
      document: [''],
      pendingAmount: [''],
      appliedDate: [''],
    });

    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    // this.getEmployeeTaxSavings();
    // this.getIncomeTaxCategory();
    this.getEmployeelist();
    this.employeeExemptionForm.get('employeeName')?.valueChanges.subscribe((selectedValue: any) => {
      this.empId = selectedValue;
    });
    this.employeeExemptionForm.controls.appliedDate.setValue(this.pipe.transform(new Date, 'dd-MM-YYYY'));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openApprovalDetailsForm(value: any) {
    this.displayTable = false;
    this.displayForm = true;
    this.sectionId = value.sectionId;
    this.requestId = value.id;
    this.employeeExemptionForm.controls.employeeName.setValue(value.employeeId);
    this.employeeExemptionForm.controls.section.setValue(value.sectionId);
    this.employeeExemptionForm.controls.category.setValue(value.categoryId);
    this.employeeExemptionForm.controls.declaredAmount.setValue(value.maxExcemptedAmount);
    this.employeeExemptionForm.controls.receiptNumber.setValue(value.receiptNumber);
    this.employeeExemptionForm.controls.submitAmount.setValue(value.receiptNumber);
    this.employeeExemptionForm.controls.pendingAmount.setValue(
      value.maxExcemptedAmount - value.sumSavingAmount
    );
    this.employeeExemptionForm.controls.description.setValue(value.description);
    if (value.approvedAmount != 0) {
      this.employeeExemptionForm.controls.approvedAmount.setValue(value.approvedAmount);
      this.employeeExemptionForm.controls.approvedAmount.disable();
    }
    value.statusName == 'Approved' ? this.isApproved = true : this.isApproved = false;
    this.id = value.id;
    this.mappingId = value.mappingId;
    this.empId = value.employeeId;
    this.getUploadDocument();
    this.employeeExemptionForm.controls.appliedDate.setValue(this.pipe.transform(value.createdOn, 'dd-MM-YYYY'));
    this.employeeExemptionForm.controls.category.setValue(value.categoryName);
  }

  submitSection() {
    let data = {
      id_value: this.id,
      employee_id_value: this.empId,
      section_id_value: this.employeeExemptionForm.controls.section.value,
      mapping_id_value: this.mappingId,
      amount_value: this.employeeExemptionForm.controls.submitAmount.value,
      file_path_value: null,
      created_by_value: this.userSession.id,
      receipt_number_value: this.employeeExemptionForm.controls.receiptNumber.value,
      status_value: 2, // 1-submitted , 2- approved , 3-rejected
      description_value: this.employeeExemptionForm.controls.description.value,
      approved_amount_value: this.employeeExemptionForm.controls.submitAmount.value,
    };

    this.payrollService.setPayrollEmployeeTaxSavings(data).subscribe((result: any) => {
      let resMessage = result.data[0].statuscode === 0 ? 'Submitted and Approved successfully.' : result.data[0].statuscode === 2 ? 'Data updated successfully.' : result.data[0].statuscode === 1 ? 'Already excemption limit reached' : 'Unable to save data'
      this.requestId = result.data[0].lastInsertId;
      if (result.status) {
        if (this.isFile = true) {
          this.LM.getFilepathsMaster(5).subscribe((result: any) => {
            if (result && result.status) {
              let obj = {
                id: null,
                employeeId: this.empId,
                filecategory: 'INVESTMENTS',
                moduleId: 5,
                documentnumber: '',
                fileName: this.file.name,
                modulecode: result.data[0].module_code,
                requestId: this.requestId,
                status: 'Submitted',
              };
              this.LM.setFilesMaster(obj).subscribe((data) => {
                if (data && data.status) {
                  let info = JSON.stringify(data.data[0]);
                  this.formData.append('info', info);
                  this.formData.append('file', this.file);
                  this.LM.setProfileImage(this.formData).subscribe(
                    (data) => {
                    }
                  );
                }
              });
            } else {

              this.dialog.open(ConfirmationComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: {
                  Message: 'Please check uploaded file.',
                },
              });
            }
          });

        }

        /****--------------------- */
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: resMessage,
        });
        this.navigationToITexcemption();

      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Unable to save data.',
        });
      }
    });
  }

  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/ITexcemptionBehalfEmployee']));
  }

  getEmployeeTaxSavings() {
    let data = {
      employee_id_value: null,
      manager_id_value: this.userSession.id,
    };
    this.payrollService.getEmployeeTaxSavings(data).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
    });
  }
  navigationToITexcemption() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/ITexcemptionBehalfEmployee']));
  }
  addNewSection() {
    this.addNewField = false;
    this.displayTable = false;
    this.displayAddForm = true;
  }

  getIncomeTaxSections() {
    let data = {
      category_id_value: this.employeeExemptionForm.controls.category.value,
    };
    this.payrollService.getIncomeTaxSections(data).subscribe((result: any) => {
      this.sectionsList = result.data;
    });
  }

  insertMaxExemAmount(data: any, mapId: any, description: any) {
    this.descriptionForDisplay = '';
    this.maximumLimit = '';
    this.employeeExemptionForm.controls.declaredAmount.setValue(data);
    this.mappingId = mapId;
    this.getEmployeeSectionWiseSavings();
    for (let i = 0; i < description.length; i++) {
      if (description.charCodeAt(i) == 40 || description.charCodeAt(i) == 41) {
      } else {
        this.descriptionForDisplay += description[i];
      }
    }
    this.descriptionList = this.descriptionForDisplay.split(",")
    this.maximumLimit = data;
    this.displayMaxLimit = true;
  }

  getEmployeeSectionWiseSavings() {
    this.employeeExemptionForm.controls.pendingAmount.setValue('');
    let data = {
      mapping_id_value: this.mappingId,
      employee_id_value: this.employeeExemptionForm.controls.employeeName.value,
    };

    this.payrollService.getEmployeeSectionWiseSavings(data).subscribe((result: any) => {

      let list = result.data;

      let sectionIdfromForm =
        this.employeeExemptionForm.controls.section.value;
      let maxAmount =
        this.maximumLimit;

      if (list != undefined) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].sectionId == sectionIdfromForm) {
            this.pendingAmount = maxAmount - list[i].savingAmount;
          }
        }
      } else {
        this.pendingAmount = maxAmount;
      }
      this.employeeExemptionForm.controls.pendingAmount.setValue(
        this.pendingAmount
      );

    });
  }

  fileView() {
    window.open(this.fileURL);
  }
  onSelectFile(event: any) {
    this.iseditDoc = false;

    if (event.target.files[0].size <= 1242880) {
      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');

      if (pdf[pdf.length - 1] == 'pdf') {
        this.isFile = true;
      } else {
        this.ispdf = true;
        this.isFile = false;
      }
    } else {
      this.ispdf = false;
      this.isFile = false;

      // Swal.fire({title: '', text: 'File size is must be less than 15MB', color: "red", position: 'top'});
    }
  }
  delete() {
    this.employeeExemptionForm.controls.document.setValue('');
  }
  getIncomeTaxCategory() {
    this.payrollService.getIncomeTaxCategory().subscribe((result: any) => {
      this.categoryList = result.data;
    });
  }
  getEmployeelist() {
    let obj = {
      rm_id: null,
    };
    this.reportsService.getTotalEmployeslistByManagerId(obj).subscribe((res: any) => {
      if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.employeeExemptionForm.controls.employeeName.setValue('');
      }
    });
  }

  getUploadDocument() {
    let info = {
      'employeeId': this.empId,
      'candidateId': null,
      'filecategory': 'INVESTMENTS',
      'moduleId': 5,
      'requestId': this.requestId,
      'status': 'Submitted'
    }
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length > 0) {
        this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0])
        this.pdfName = result.data[0].fname

        result.data[0].employeeId = this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            this.employeeExemptionForm.controls.document.value = true;
            this.employeeExemptionForm.controls.document.clearValidators();
            this.employeeExemptionForm.controls.document.updateValueAndValidity();
            this.iseditDoc = false;


            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName = this.pdfName.split('.');
            // const file = new Blob([TYPED_ARRAY], { type: "application/pdf" });
            // this.fileURL = URL.createObjectURL(file);
            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        })
      }
      else {
      }
    })
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  clearForm() {
    this.employeeExemptionForm.controls.section.setValue('');
    this.employeeExemptionForm.controls.receiptNumber.setValue('');
    this.employeeExemptionForm.controls.pendingAmount.setValue('');
    this.employeeExemptionForm.controls.submitAmount.setValue('');
    this.employeeExemptionForm.controls.description.setValue('');
    this.employeeExemptionForm.controls.category.setValue('');
    this.displayMaxLimit = false;
    this.getEmployeeTaxSavings();
    this.getIncomeTaxCategory();
  }
}
