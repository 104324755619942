<div>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Chapter Questioner</mat-card-title>
        </mat-card-header>
        <form [formGroup]="addQuestions">
            <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center"
                style="margin-top: 10px;">
                <mat-form-field fxFlex="40" fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline">
                    <mat-label>Course Name</mat-label>
                    <mat-select formControlName="courseName" required>
                        <ng-container *ngFor="let v of courseName">
                            <mat-option (click)="displayChapterList()" [value]="v">{{v}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="40" fxFlex.xs="100" fxFlex.lt-lg="47" class="mx-2 my-2" appearance="outline">
                    <mat-label>Chapter Name</mat-label>
                    <mat-select formControlName="chapterName" required>
                        <ng-container *ngFor="let v of chapterNames">
                            <mat-option [value]="v">{{v}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-left: 10px;">
                <mat-checkbox value="1" (change)="setDuration($event)">Would you like to set a specific duration
                    for the exam?</mat-checkbox>
                <div fxLayout="row" *ngIf="displayDurationForm">
                    <div style="margin-top: 18px;" fxFlex="7">Duration:</div>
                    <div fxFlex="20">
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <input matInput width="20px" formControlName="duration" placeholder="00:00:00"
                                (keypress)="TimeFormat($event)" maxlength="8" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="secondTitle" fxLayoutAlign="space-between center">
                <div> Add Question</div>
                <div>
                    <button mat-icon-button matTooltip="Add New Question">
                        <mat-icon *ngIf="addNewquestion" style="font-size: 30px;overflow: visible;"
                            (click)="addNewQuestion()">add_circle</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="addNewquestion">
                <div *ngFor="let a of questions" style="margin-top: 10px;">
                    <div fxLayout="row" style="margin-bottom: 10px;">
                        <div style="margin-top: 10px;">
                            <b> {{a.sno}}. {{ a.question}}</b>
                        </div>
                        <div style="margin-left: 100px;">
                            <button mat-icon-button>
                                <mat-icon class="mr-2" title="Edit">edit</mat-icon>
                            </button>&nbsp;
                            <button mat-icon-button>
                                <mat-icon class="mr-1" title="Delete" (click)="deleteQuestion()">delete</mat-icon>
                            </button>
                        </div>

                    </div>
                    <div style="margin-left: 30px;">
                        <div style="margin-bottom:10px">A. {{ a.A}}</div>
                        <div style="margin-bottom:10px">B. {{ a.B}}</div>
                        <div style="margin-bottom:10px">C. {{ a.C}}</div>
                        <div style="margin-bottom:10px">D. {{ a.D}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="!addNewquestion" style="margin: 10px 0px 0px 10px; ">
                <div>
                    <mat-radio-group>
                        <div style="margin-top: 20px;"><mat-radio-button value="1" [checked]="true"
                                (click)="singleAnsQues()"><b>Single Answer
                                    Questions </b></mat-radio-button></div>
                        <div style="margin-top: 10px; margin-bottom: 10px;"><mat-radio-button value="2"
                                (click)="mutliAnsQues()"><b>Multiple
                                    Answer
                                    Questions</b></mat-radio-button></div>
                    </mat-radio-group>
                </div>
                <div fxLayout="column" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <div style="color: #28acaf;">
                        <h2>Question/Title</h2>
                    </div>
                    <div>
                        <mat-form-field fxFlex="40" appearance="outline">
                            <mat-label></mat-label>
                            <input matInput formControlName="question" />
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center">
                    <div style="color: #28acaf;">
                        <h2>Add Option</h2>
                    </div>
                    <div>
                        <mat-form-field fxFlex="40" appearance="outline">
                            <mat-label></mat-label>
                            <input matInput formControlName="answer" />
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <div><b>Select the correct answer configuration</b></div>
                    <div *ngIf="displaySingleAnsQuesOptions" style="margin-top: 20px;">
                        <b>
                            <mat-radio-group>
                                <mat-radio-button style="margin-right: 50px;" value="1">A</mat-radio-button>
                                <mat-radio-button style="margin-right: 50px;" value="2">B</mat-radio-button>
                                <mat-radio-button style="margin-right: 50px;" value="3">C</mat-radio-button>
                                <mat-radio-button value="4">D</mat-radio-button>
                            </mat-radio-group>
                        </b>
                    </div>
                    <div *ngIf="displayMutliAnsQuesOptions" style="margin-top: 20px;">
                        <mat-checkbox value="1" style="margin-right: 50px;">A</mat-checkbox>
                        <mat-checkbox value="2" style="margin-right: 50px;">B</mat-checkbox>
                        <mat-checkbox value="3" style="margin-right: 50px;">C</mat-checkbox>
                        <mat-checkbox value="4">D</mat-checkbox>
                    </div>
                </div>
                <div fxLayoutAlign="center" style="margin-top: 30px;">
                    <button class="btn btn-primary mr-2 mb-2" type="submit">
                        <span>Submit</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </div>
        </form>
    </mat-card>
</div>