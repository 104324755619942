import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { EmsService } from '../../ems.service';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
type AOA = any[][];
@Component({
  selector: 'app-employee-excel-upload',
  templateUrl: './employee-excel-upload.component.html',
  styleUrls: ['./employee-excel-upload.component.scss']
})
export class EmployeeExcelUploadComponent implements OnInit {
  data: AOA = [[], []];
  viewdata: AOA = [[], []];
  isview: boolean = false;
  isadd: boolean = true;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  @ViewChild('inputFile') inputFile!: ElementRef;
  convertedJson!: string;
  viewconvertedJson!: string;
  messagesDataList: any = [];
  reqField: any;
  reqOption: any;
  reqSave: any;
  reqNotSave: any;
  isLoading = false;
  companyName: any;
  userSession: any;
  fileURL: any;
  constructor(private emsService: EmsService, public dialog: MatDialog, private router: Router,
    private mainService: MainService, private sanitizer: DomSanitizer) {
    this.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    // this.agreement();
  }

  ngOnInit(): void {
  }
  onChange(event: any) {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event: any) => {
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: "binary", cellText: false, cellDates: true });

      /* grab first sheet */
      const wsname: string = workbook.SheetNames[0];
      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' }));
      let data = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: 'yyyy-mm-dd' });
      this.viewdata = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'd-m-yy' }));
      let viewdata = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: 'd-m-yy' });
      // this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 ,raw:false}));
      // let data=XLSX.utils.sheet_to_json(ws,{ raw:true});
      this.convertedJson = JSON.stringify(data, undefined, 4);
      this.isview = true;
      this.isadd = false;

    }
  }

  SaveUploadedData() {
    this.isLoading = true;
    let data = {
      'emplist': JSON.parse(this.convertedJson),
      'createdby': this.userSession.id
    }
    this.emsService.setEmployeeExcelData(data).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.status) {
          if (res.data == 0) {
            this.removeData();
            this.isview = false;
            this.isadd = true;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/ems/employee-excel-upload"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Data saved successfully.'
            });
          } else if (res.data == 1) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Entered Email is already exist.'
            });
            this.removeData();
            this.isview = false;
            this.isadd = true;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/ems/employee-excel-upload"]));
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to save data.'
            });
            this.removeData();
            this.isview = false;
            this.isadd = true;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/ems/employee-excel-upload"]));
          }
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to save data.'
          });
          this.removeData();
          this.isview = false;
          this.isadd = true;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/ems/employee-excel-upload"]));
        }
      },
      error => {
        error.error.text
      })
  }
  removeData() {
    this.isview = false;
    this.isadd = true;
    this.data = [[], []];
    this.convertedJson = '';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/ems/employee-excel-upload"]));
  }

  getExampleFileUrl(): SafeResourceUrl {
    const filePath = 'assets/images/Employee-Excel.xlsx';
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(filePath);
    return url;
  }
}
