<div fxLayout="column">
    <form [formGroup]="checklistForm" autocomplete="off">
        <mat-card>
          <mat-card-header>
            <mat-card-title > Checklist </mat-card-title>
          </mat-card-header>
          <div *ngIf="ishide">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <span style="padding-top: 20px;padding-left: 20px;">
                    <mat-radio-group >
                        <mat-radio-button  class="mx2 my-2 mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;"   [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                      </mat-radio-group>
                 </span>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                <mat-form-field fxFlex.xs="100" fxFlex="30"  class="mx-2 my-2" appearance="outline">
                  <mat-label>Department</mat-label>
                  <input formControlName="department"  matInput readonly required>
                </mat-form-field>
                <mat-icon style="padding-top: 25px;" title="Add" fxLayoutAlign="end"  (click)="addtext()" >add</mat-icon>


              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <div formArrayName="text">
                    <div  *ngFor="let employee of text().controls; let empIndex=index">
                        <div [formGroupName]="empIndex">
                            <mat-form-field fxFlex.xs="100" fxFlex="30" class="mx-2 my-2" appearance="outline">
                                <mat-label>Text Input</mat-label>
                                <input type="text" formControlName="textinput"  matInput placeholder="">

                               </mat-form-field>
                               <mat-icon style="padding-top: 25px;" title="Delete" (click)="removetext(empIndex)">delete</mat-icon>
                               <!-- <mat-icon style="padding-top: 25px;" title="Add" fxLayoutAlign="end"  (click)="addtext()" >add</mat-icon> -->
                        </div>
                    </div>
                </div>

          </div>


              <div class="col-sm-6 mr2 text-right" fxLayoutAlign="end">
                <button class="col-md-2 mr-2 btn btn-primary" >Submit</button>
                <button class="col-md-2  btn btn-danger" (click)="close()" >Cancel</button>
            </div>
            <br>
          </div>
          <div fxLayout="column" *ngIf="isview">
            <br>
            <div fxLayout="row" fxLayoutAlign="end">
                <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="button">
                    <span>Add</span>
                </button>
            </div><br>
            <div fxLayout="column" class="tableP">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef > S.No </th>
                        <td mat-cell *matCellDef="let row;let i=index" >
                            <span>{{(i+1)}}</span>


                        </td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > Checklist </th>
                        <td mat-cell *matCellDef="let row" >
                            <span>{{row.department}} </span>

                        </td>
                    </ng-container>


                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef >  </th>
                        <td mat-cell *matCellDef="let row" >
                            <mat-icon title="Edit"  (click)="edit($event,row)" >edit</mat-icon>
                         </td>
                    </ng-container>
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                        </tr>
                </table>

            </div>
        </div>

        </div>


        </mat-card>
    </form>
</div>
