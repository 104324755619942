<!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" >
<div class="container" fxFlex="40" >
	<h2 style="text-align: center;">Forgot Password</h2>
	<form
	  [formGroup]="formGroup"
	  class="form"
	>
	  <mat-form-field floatLabel="never" class="form-element">
		<input
		  type="text"
		  matInput
		  placeholder="Email"
		  formControlName="email"
		/>
		<mat-error *ngIf="formGroup.controls['email'].invalid">
		 This field is required
		</mat-error>
	  </mat-form-field>



	  <div class="form-element">
		<button
		  mat-raised-button
		  color="primary"
		  type="submit"
		  class="button"
		  [disabled]="!formGroup.valid"
		  (click)="submit()"
		>
		  Submit
		</button>
        <button
		  mat-raised-button
		  color="primary"
		  type="cancel"
		  class="button"

		  (click)="cancel()"
		>
		  Cancel
		</button>
	  </div>
	</form>
  </div>
</div> -->

<body class="authentication-bg" data-layout-config='{"darkMode":false}'>
  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5">
          <div class="card">
            <!-- Logo -->
            <!-- <div class="card-header pt-4 pb-4 text-center bg-secondary" style="background-color: blue;">
                                <span class="text-white"><img class="logo-img mr-2" src="assets/img/logo.png" alt="" />Sreeb Technologies</span>
                            </div> -->

            <div class="card-body p-4">
              <!-- <form [formGroup]="formGroup" > -->

              <div class="text-center w-75 m-auto">
                <h4 style="color: #28acaf">
                  <strong>Forgot Password</strong>
                </h4>
                <p class="text-muted mb-4">
                  Enter your company code & email address to get reset password
                  link
                </p>
              </div>

              <form [formGroup]="formGroup">
                <div fxLayout="column">
                  <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company Code</mat-label>
                    <input matInput class="form-control" type="text" formControlName="comapnyname" id="company" required
                      placeholder="" maxlength="15" />
                    <mat-error class="con-error" *ngIf="formGroup.controls.comapnyname.errors?.required">
                      This field is required.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex.xs="100" fxFlex="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput class="form-control" type="email" formControlName="email" placeholder=""
                      maxlength="50" required />
                    <mat-error class="con-error" *ngIf="formGroup.controls.email.errors?.required">
                      This field is required.
                    </mat-error>
                    <mat-error class="con-error" *ngIf="
                        formGroup.controls.email.errors?.email ||
                        formGroup.controls.email.errors?.pattern
                      ">
                      Please enter valid email
                    </mat-error>
                  </mat-form-field>

                  <!-- <label for="emailaddress" class="form-label">Email address</label>
                                        <input class="form-control" type="email" formControlName="email" id="emailaddress" formc required="" placeholder="Enter your email">
                                        <div class="extra" style="color: red;"  *ngIf="issubmit && formGroup.controls['email'].invalid " >
                                             This field is required
                                          </div> -->
                </div>

                <div class="mb-3 mb-0 text-center">
                  <button class="btn btn-primary mx-2 my-2" type="submit" (click)="submit()">
                    Submit
                  </button>
                  <button class="btn btn-danger mx-2 my-2" type="submit" (click)="cancel()">
                    Cancel
                  </button>
                </div>

                <!-- </form> -->
              </form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end page -->

  <!-- <footer class="footer footer-alt">
            2018 - <script>document.write(new Date().getFullYear())</script> © sreebtech.com
        </footer> -->
</body>