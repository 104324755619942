import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import * as XLSX from 'xlsx';


import * as _moment from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-employee-summary-report',
  templateUrl: './employee-summary-report.component.html',
  styleUrls: ['./employee-summary-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EmployeeSummaryReportComponent implements OnInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table') table!: ElementRef;

  fromDate: any;
  minDate = new Date('1950/01/01');
  today = new Date();
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
  minToDate: any;
  List: any[] = [

  ];
  displayedColumns: string[] = ['sno', 'attendancedate', 'firstlogintime',
    'lastlogouttime', 'totalhours', 'productivehours', 'ot', 'ph', 'action'];
  // 'empname'
  userSession: any;
  isLoading = false;
  pageLoading = true;
  dataSource: MatTableDataSource<any> = <any>[];
  searchForm = this.formBuilder.group({ fromDate: [this.maxDate], toDate: [this.maxDate], Users: ['0'] });

  constructor(
    private formBuilder: FormBuilder,
    public datePipe: DatePipe,
    private reportsService: ReportsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.searchForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue != null) {
        this.minToDate = selectedValue;
      }
    })
  }
  Searchform() {
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, 'y-MM-d');
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, 'y-MM-d');
    let data = {
      "manager_empid": null,
      'employee': this.userSession.id,
      'fromdate': fromDate,
      'todate': toDate
    }
    this.isLoading = true;
    this.reportsService.getAttendanceSummaryReport(data).subscribe((res: any) => {
      this.List = res.data;
      this.List.forEach((e: any) => {
        if (e.breaks != null) {
          e.breaks = e.breaks.split(',')
        }
      })
      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.List);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
    }, error => {
      this.isLoading = false;
      error.error.text
    });
  }

  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(this.maxDate);
    this.searchForm.controls.toDate.setValue(this.maxDate);
    this.Searchform();
  }

  public exportPDF(): void {
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: fromdate + " - " + todate + " - " + "Attendance Summary Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage: any, pageCount: any) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          width: '100%',
          text: fromdate + " - " + todate + " - " + "Attendance Summary Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html,

      ],
      pageOrientation: 'landscape',//'portrait'
      pageSize: "EXECUTIVE"
    }).download("Attendance Summary Report.pdf");

  }
  
  exportAsXLSX() {

    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance_Summary_Report');
    /* save to file */
    XLSX.writeFile(wb, fromdate + " - " + todate + " - " + 'Attendance_Summary_Report.xlsx');
  }

  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  openDialog(item: any): void {
    let type;
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      width: '1200px', position: { top: `70px` },
      data: { attendanceid: item.attendanceid }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  downloadPDF() {
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
    let data = {
      'list': this.List,
      'report_date': fromdate +' '+'_'+' '+todate
    }
    this.reportsService.getAttendanceSummaryReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'attendance_summary_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
