<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="assetListForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>Assets List</mat-card-title>
            </mat-card-header>
           <!-- <div fxLayout="row">
    <label style="padding-top: 5px;" class="mx-1 my-1 normal">Asset Type:</label>
    <mat-radio-group  class="mx-2 my-2" formControlName="typeOfList" (change)="radioChange($event)">
      <mat-radio-button style="margin-left: 5px;"  [value]="false">Software</mat-radio-button>
      <span style="font-size: 23px;margin-left: 10px;margin-right: 10px;"> |   </span>
      <mat-radio-button [value]="true">Hardware</mat-radio-button>
    </mat-radio-group>
</div> -->


      <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
        <br>
        <mat-label class="mx-2" *ngIf="dataView"><b>Add New Software Asset</b></mat-label>
        <mat-label class="mx-2" *ngIf="!dataView"><b>Add New Hardware Asset</b></mat-label>
           
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Category</mat-label>
                    <mat-select formControlName="assetCategory" required>
                        <ng-container *ngFor="let value of assetCategoryList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Type</mat-label>
                    <mat-select formControlName="assetType" required>
                        <ng-container *ngFor="let value of assetTypeList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Name</mat-label>
                    <mat-select formControlName="assetName" required>
                        <ng-container *ngFor="let value of assetNameList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
              
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Vendor Id</mat-label>
                    <mat-select formControlName="vendorId" required>
                        <ng-container *ngFor="let value of assetCategoryList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Purchase Date </mat-label>
                    <input matInput readonly required [matDatepicker]="purchaseDate"
                        placeholder="Purchase Date" formControlName="purchaseDate">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="purchaseDate"></mat-datepicker-toggle>
                    <mat-datepicker #purchaseDate></mat-datepicker>
                    <!-- <mat-error *ngIf="notificationsForm.controls.expDate.errors?.required">
                   Please select expiry date
                    </mat-error> -->
                </mat-form-field>

                <mat-form-field *ngIf="!dataView" fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Serial No</mat-label>
                    <input type="text" formControlName="serialNo"  matInput placeholder="Serial No" required>
                    <!-- <mat-error *ngIf="notificationsForm.controls.name.hasError('required')">
                        Please Enter Name
                       </mat-error> -->
                </mat-form-field>
                <mat-form-field *ngIf="dataView" fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Software Version</mat-label>
                    <input type="text" formControlName="softVersion"  matInput placeholder="Software Version" required>
                </mat-form-field>
              </div>
             <div *ngIf="dataView" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
              
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Total Purchased</mat-label>
                    <input type="text" formControlName="serialNo"  matInput placeholder="Total Purchased">
                 </mat-form-field>

                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Product Key</mat-label>
                    <input type="text" formControlName="productKey"  matInput placeholder="Product Key">
                 </mat-form-field>
                
                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>License key</mat-label>
                    <input type="text" formControlName="licenseKey"  matInput placeholder="License Key" required>
                </mat-form-field>
                     
             </div> 
             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
            
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Warranty</mat-label>
                    <mat-select formControlName="warranty" required>
                        <ng-container *ngFor="let value of isWarranty">
                            <mat-option [value]="value.Value">{{value.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Type of asset</mat-label>
                    <mat-select formControlName="typeOfAsset" required>
                        <ng-container *ngFor="let value of typeOfAssetList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Assignable</mat-label>
                    <mat-select formControlName="assignable" required>
                        <ng-container *ngFor="let value of assignableList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
             </div>
             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
              
                <mat-form-field *ngIf="!dataView" fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Life Cycle Exp.Date </mat-label>
                    <input matInput readonly required [matDatepicker]="lifeCycleExpDate"
                        placeholder="Life Cycle Exp.Date" formControlName="lifeCycleExpDate">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="lifeCycleExpDate"></mat-datepicker-toggle>
                    <mat-datepicker #lifeCycleExpDate></mat-datepicker>
                    <!-- <mat-error *ngIf="notificationsForm.controls.expDate.errors?.required">
                   Please select expiry date
                    </mat-error> -->
                </mat-form-field>
            <mat-form-field *ngIf="expWarranty" fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Warranty Exp.Date </mat-label>
                    <input matInput readonly required [matDatepicker]="warExpDate"
                        placeholder="Life Cycle Exp.Date" formControlName="warExpDate">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="warExpDate"></mat-datepicker-toggle>
                    <mat-datepicker #warExpDate></mat-datepicker>
                    <!-- <mat-error *ngIf="notificationsForm.controls.expDate.errors?.required">
                   Please select expiry date
                    </mat-error> -->
                </mat-form-field>
                     
             </div>
            
            

            <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
       </div>
        </mat-card>


    <br>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="isdata">
        <div>
            <label style="padding-top: 5px;" class="mx-1 my-1 normal">Asset Type:</label>
            <mat-radio-group  class="mx-2 my-2" formControlName="typeOfList" (change)="radioChange($event)">
              <mat-radio-button style="margin-left: 5px;"  [value]="false">Hardware</mat-radio-button>
               <span style="font-size: 23px;margin-left: 10px;margin-right: 10px;"> |   </span>
                <mat-radio-button [value]="true">Software</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxLayoutAlign.xs="space-between center">
            <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="submit">
                <span>Add</span>
            </button>
            <span style="padding-top: 5px; padding-left: 10px;">
                <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
                 </span>
        </div>
      
    </div>
    <br/>
    <mat-divider></mat-divider>
</form>
        <div fxLayout="column" class="tableP" *ngIf="isdata">
           
             <div class="mat-elevation-z8">
          
            <table *ngIf="!dataView" mat-table  [dataSource]="dataSource" matSort>
               <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="cname"> 
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Type</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial No </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Purchase Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="tyofasset">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type of Asset </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                           <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="hardWaredisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: hardWaredisplayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>

            <table *ngIf="dataView" mat-table  [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                     <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                 </ng-container>
             
                 <ng-container matColumnDef="cname"> 
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
                     <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                 </ng-container>
                 <ng-container matColumnDef="mobile">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Type</th>
                     <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                 </ng-container>
                 <ng-container matColumnDef="email">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
                     <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                 </ng-container>
                 <ng-container matColumnDef="type">
                     <th mat-header-cell *matHeaderCellDef mat-sort-header>Software Version </th>
                     <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                 </ng-container>
                 <ng-container matColumnDef="purchased">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Purchased</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="tyofasset">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type of Asset </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                            <ng-container matColumnDef="action">
                     <th mat-header-cell *matHeaderCellDef> Action </th>
                     <td mat-cell *matCellDef="let row">
                         <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                             visibility</mat-icon>
                         <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                             edit</mat-icon>
                         <mat-icon class="mr-2" title="Delete"
                             (click)="deleteRequest(row)">delete</mat-icon>
                     </td>
                 </ng-container>
 
                 <tr class="headerbox title" mat-header-row *matHeaderRowDef="softwaredisplayedColumns"></tr>
                 <tr mat-row *matRowDef="let row; columns: softwaredisplayedColumns;"></tr>
 
                 <tr class="mat-row" *matNoDataRow>
                     <td class="mat-cell" colspan="6">No data available</td>
                 </tr>
             </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>


</div>


</div>