<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Subscription Plan</mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isAddBtn">
                <button mat-icon-button style="padding-right: 40px;" (click)="addNew()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <form [formGroup]="subscriptionForm" autocomplete="off">
            <div fxFlex="column">

                <div class="dev-Pad" *ngIf="isData">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Plan Name</mat-label>
                            <input formControlName="planName" matInput required maxlength="50"
                            onlyAlphabets>
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.planName.errors?.required">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field fxFlex fxFlex.xs="100" fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                            <mat-label>Modules</mat-label>
                            <mat-select  [compareWith]="compareFn"   placeholder="Select" required formControlName="modules" multiple >
                                <mat-select-trigger>
                                  <span *ngFor="let item of subscriptionForm.controls.modules.value">
                                       {{item.modulename}} ,
                                  </span>
                                 </mat-select-trigger>
                                <span class="select-container" *ngIf="editflag">
                                    <mat-option  (onSelectionChange)="selectedModulesChange($event)"
                                    *ngFor="let modules of modulesList" [value]="modules">{{modules.modulename}}
                                </mat-option>
                              </span>
                              <span class="select-container" *ngIf="!editflag">
                                <mat-option  (onSelectionChange)="selectedModulesChange($event)"
                                *ngFor="let modules of modulesList" [value]="modules">{{modules.modulename}}
                            </mat-option>
                                </span>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="subscriptionForm.controls.modules.errors?.required">
                                Please select an option
                            </mat-error>
                        </mat-form-field>
                      
                     

                       

                    </div>

                   

                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary mr-2 mb-2" (click)="saved()" *ngIf="isadd">
                            Submit
                        </button>
                        <button class="btn btn-primary mr-2 mb-2" *ngIf="editing" (click)="saved()">
                            Update
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            Cancel
                        </button>
                    </div>
                </div>

                <div *ngIf="!isData" fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                    <span style="height: 70px;" fxFlex="50" fxLayoutAlign="end">
                      <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                      </mat-form-field>
                    </span>
                  </div>
                <div class="table-pad" *ngIf="!isData">
                    <div class="example-container mat-elevation-z1">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno" >
                                <th mat-header-cell *matHeaderCellDef>S.No</th>
                                <td mat-cell *matCellDef="let element; let i=index;" >
                                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                              </ng-container>
                            <ng-container matColumnDef="plan">
                                <th mat-header-cell *matHeaderCellDef>
                                    Plan Name
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.plan_name}} </span>
                                </td>
                            </ng-container>
                            

                           

                            <ng-container matColumnDef="module">
                                <th mat-header-cell *matHeaderCellDef>Modules</th>
                                <td mat-cell *matCellDef="let row">
                                    <span>{{ row.module_list
                                         }} </span>
                                </td>
                            </ng-container>
                          
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                                <td mat-cell *matCellDef="let row">
                                    Active
                                    <!-- <mat-radio-group  >
                                        <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;"  [checked]="halfs.id == row.plan_status" [disabled]="true" [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                                      </mat-radio-group> -->
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                     <!-- <mat-icon (click)="edit($event, row)" *ngIf="row.status == 'Submitted'" 
                                        title="Edit">edit</mat-icon> -->
                                    <!-- <mat-icon (click)="view($event, row)" title="view">visibility </mat-icon> -->
                                    <!-- <mat-icon (click)="edit($event, row)" title="Edit">edit </mat-icon> -->
                                </td>
                            </ng-container>
                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
       
                    </div>
                </div>

            </div>
        </form>
    </mat-card>
</div>