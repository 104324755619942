import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { ReportsService } from '../../reports.service';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-report-users-configuration',
  templateUrl: './report-users-configuration.component.html',
  styleUrls: ['./report-users-configuration.component.scss'],
})
export class ReportUsersConfigurationComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  searchControl = new FormControl('');
  searchControlforLocations = new FormControl('');

  ReportUserForm: any = UntypedFormGroup;
  employeeDetails: any = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  employeeIshide: boolean = false;
  employeeIschecked: boolean = false;
  selectedLocations: any = [];
  employees: any;
  worklocationDetails: any;
  companyName: any;
  pageLoading = true;
  displayedColumns: string[] = ['id', 'employeeName', 'Locations'];
  dataSource: MatTableDataSource<UserData> = <any>[];
  disableDates: any = [];
  getData: any = [];
  filteredEmpList: any = [];
  filteredLocations: any = [];

  constructor(
    public formBuilder: UntypedFormBuilder,
    private companyService: CompanySettingService,
    private reportService: ReportsService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.companyName = sessionStorage.getItem('companyName');
  }

  ngOnInit(): void {
    this.ReportUserForm = this.formBuilder.group({
      reportUser: ['', Validators.required],
      companylocation: [[], Validators.required],
    });

    this.getWorkLocation();
    this.getEmployeeDetails();
    this.getReportsuserLocationsMapping();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmp(searchText)
    });
    this.searchControlforLocations.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText)
    })
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.ReportUserForm.controls.companylocation.setValue(array);
    let locations = this.ReportUserForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false;
    this.selectedLocations = [];
    select.value = [];
    this.ReportUserForm.controls.companylocation.setValue('');
  }

  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.ReportUserForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
  }

  getWorkLocation() {
    this.companyService
      .getactiveWorkLocation({ id: null, companyName: this.companyName })
      .subscribe((result) => {
        this.worklocationDetails = result.data;
        this.filteredLocations = this.worklocationDetails;
      });
  }
  getEmployeeDetails() {
    this.reportService.getEmployeesForReportsRoleAssignment().subscribe((result) => {
      this.employeeDetails = result.data;
      this.filteredEmpList = this.employeeDetails;
    });
  }
  Submit() {
    if (this.ReportUserForm.valid) {
      let data = {
        employee_id: this.ReportUserForm.controls.reportUser.value,
        location_ids: this.selectedLocations,
      };
      this.reportService
        .setReportsUserLocationsMapping(data)
        .subscribe((res) => {
          if (res.status) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Saved Successfully.',
            });
            this.navigationToreportUsersConfiguration();
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Data is not saved',
            });
            this.navigationToreportUsersConfiguration();
          }
        });
    }
  }

  cancel() {
    this.navigationToreportUsersConfiguration();
  }

  navigationToreportUsersConfiguration() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate(['/Attendance/reportUsersConfiguration'])
      );
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getReportsuserLocationsMapping() {
    let data = {
      id: null,
      companyName: this.companyName,
    };

    this.reportService
      .getReportsuserLocationsMapping(data)
      .subscribe((res: any) => {
        if (res.status) {
          this.getData = res.data;

          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < JSON.parse(res.data[i].locations).length; j++) {
              this.getData[i].locationsdata =
                this.getData[i].locationsdata == undefined
                  ? JSON.parse(res.data[i].locations)[j]
                  : this.getData[i].locationsdata +
                  ',' +
                  JSON.parse(res.data[i].locations)[j];
            }
          }
          this.dataSource = new MatTableDataSource(this.getData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        }
      });
  }

  filterEmp(searchText: any) {
    this.filteredEmpList = this.employeeDetails.filter((val: any) =>
      val.employee.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredEmpList.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLocations.setValue('');
    }
  }
}
