import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { PayrollService } from '../../payroll.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-leave-balance-report-manager',
  templateUrl: './leave-balance-report-manager.component.html',
  styleUrls: ['./leave-balance-report-manager.component.scss']
})
export class LeaveBalanceReportManagerComponent implements OnInit {

  dataMessageResults:any=[];
  displayedColumns: string[] = ['sno','empid','empname','advance','casual','sick','earned','maternity','paternity','marriage','bereavement','compoff'];
  dataSource: MatTableDataSource<any>=<any>[];
  @ViewChild(MatPaginator)  paginator!: MatPaginator;
  @ViewChild(MatSort)  sort!: MatSort;
  pageLoading = true;
  pipe = new DatePipe('en-US');
  year:any;
  max = new Date()
leaveBalanceReportList:any=[];
leavesList: any[] = [];
headersList: any = [];
  constructor(private payrollService:PayrollService,public datePipe: DatePipe,
    public formBuilder: UntypedFormBuilder,public spinner :NgxSpinnerService,
    private RS:ReportsService) { }
  @ViewChild('table') table!: ElementRef;

  ngOnInit(): void {
this.getEmployeesLeaveBalanceReport();
  }

  getEmployeesLeaveBalanceReport() {
    this.payrollService.getEmployeesLeaveBalanceReport().subscribe((info:any) => {
      this.dataMessageResults = info.data;
//       if (info.status && info.data.length != 0) {
//         this.headersList = [];
//         this.leavesList = [];
//           let i = 0;
//           info.data.forEach((e: any) => {
//             if (i < 1) {
//               let header = (e.result);
//               this.headersList.push(header);
//             } else {
//               let header = (e.result);
//               this.leavesList.push(header);
//             }
//             i++;
  
//           });


//         this.leaveBalanceReportList = info.data;
//         this.dataSource = new MatTableDataSource(this.leavesList);
//         this.dataSource.paginator = this.paginator;
//         this.dataSource.sort = this.sort;
//         this.pageLoading = false;
//       }
    })
  }

  getHeaders() {
    let headers: string[] = [];
    if(this.dataMessageResults) {
      this.dataMessageResults.forEach((value:any) => {
        Object.keys(value).forEach((key) => {
          if(!headers.find((header) => header == key)){
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataMessageResults.filter = filterValue.trim().toLowerCase();
    
    }
  getPageSizes(): number[] {
     
  var customPageSizeArray = [];
  if (this.dataSource.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource.data.length);
  return customPageSizeArray;
  }

  exportAsXLSX() {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Leave_Balance_Report');
    /* save to file */
    XLSX.writeFile(wb,'Leave_Balance_Report.xlsx');
  }

  public exportPDF(): void {
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title:"Leave Balance Report",
        author:'Sreeb tech',
        subject:'Theme',
            keywords:'Report'
      },
      footer: function (currentPage:any, pageCount:any) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [ 
        {
          text: "Leave Balance Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html,
        
      ],
      pageOrientation: 'landscape'//'portrait'
    }).download("Leave Balance Report.pdf");

  }
}

