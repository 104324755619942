<div fxLayout="column">

  <div class="card">
    <div class="card-header ">
      <h4 class="card-title">Admin Dashboard</h4>

    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h4>Welcome to Attendance Module...... </h4>
    </div>
    <!-- <canvas baseChart [type]="chartType" [data]="data" [options]="options">
    </canvas> -->
  </div>
</div>
