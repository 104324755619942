<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Holidays </mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isview">
                <button mat-icon-button style="padding-right: 40px;" [tabIndex]="1" (keyup.enter)="add()"
                    (click)="add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <div *ngIf="isadd">
            <form [formGroup]="HolidayForm" autocomplete="off" class="tableP">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Holiday Name</mat-label>
                        <input formControlName="holiday" matInput noLeadingWhitespace required maxlength="100">
                        <mat-error class="con-error" *ngIf="HolidayForm.controls.holiday.errors?.required  ">
                            {{msgEM1}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Date </mat-label>
                        <input matInput readonly [matDatepicker]="date" placeholder="DD-MM-YYYY" formControlName="date"
                            required (click)="date.open()">
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                        </mat-datepicker-toggle>
                        <mat-datepicker #date></mat-datepicker>
                        <mat-error class="con-error" *ngIf="HolidayForm.controls.date.errors?.required">
                            {{msgEM2}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                        <mat-label>Branch Location </mat-label>
                        <mat-select placeholder="" name="branchs" formControlName="branch"
                            (selectionChange)="onSelectionChange($event)" [ngModel]="selectedOptions" required multiple
                            #branchSelect>

                            <!-- <mat-option disabled="disabled" class="filter-option"> -->
                            <button mat-raised-button class="mat-primary fill text-sm"
                                (click)="selectAll(branchSelect, worklocationDetails, HolidayForm.branch)"
                                *ngIf="!ishide">Select All
                            </button>
                            <button mat-raised-button class="mat-accent fill text-sm"
                                (click)="deselectAll(branchSelect)" *ngIf="ishide">
                                Deselect All
                            </button>
                            <!-- <input type="checkbox"(click)="selectAll(branchSelect, worklocationDetails, HolidayForm.branch)"/> -->
                            <!-- <span
                            *ngIf="!ishide"
                            (click)="selectAll(branchSelect, worklocationDetails, HolidayForm.branch)">Select All
                          </span>
                            <span
                            *ngIf="ishide"
                            (click)="deselectAll(branchSelect)">
                            Deselect All
                           </span>  -->
                            <!-- </mat-option>  -->
                            <mat-option *ngFor="let location of worklocationDetails" [value]="location">
                                {{location.cityname}}
                            </mat-option>
                        </mat-select>
                        <mat-error class="con-error" *ngIf="HolidayForm.controls.branch.errors?.required">
                            {{msgEM2}}
                        </mat-error>
                    </mat-form-field>
                </div><br>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>

            </form>
        </div>
        <div *ngIf="isview" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </span>
            </div>
            <form [formGroup]="HolidayForm" autocomplete="off" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>
                        <ng-container matColumnDef="holiday">
                            <th mat-header-cell *matHeaderCellDef> Holiday</th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="enable != row.id">{{row.description}} </span>
                                <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                    <input formControlName="holiday" matInput placeholder="{{row.description}}"
                                        maxlength="100">
                                    <mat-error class="con-error" *ngIf="HolidayForm.controls.holiday.errors?.required ||
                                HolidayForm.controls.holiday.errors?.['whitespace'] ">
                                        {{msgEM1}}
                                    </mat-error>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <span *ngIf="enable != row.id">{{row.date | date:'dd-MM-yyyy'}} </span>

                                <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                    <input matInput readonly [matDatepicker]="date" placeholder="DD-MM-YYYY"
                                        formControlName="date" (click)="date.open()">
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #date></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="day">
                            <th mat-header-cell *matHeaderCellDef style="padding-right: 100px"> Day </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{row.day}} </span>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef style="padding-right: 100px"> Location </th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="enable != row.id">{{row.cityname}}</span>
                                <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                    <mat-select formControlName="branch" placeholder="Select Branch">
                                        <ng-container>
                                            <div *ngFor="let b of worklocationDetails">

                                                <mat-option [value]="b.city">{{b.cityname}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row,let i = index;" style="white-space: nowrap;">
                                <button class="mr-2" mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="edit($event,row)"
                                    *ngIf="enable != row.id">
                                    <mat-icon title="Edit" (click)="edit($event,row)" *ngIf="enable != row.id">edit
                                    </mat-icon>
                                </button>
                                <button class="mr-2" mat-icon-button [tabIndex]="[i+3]"
                                    (keyup.enter)="deleteHolidayPopup($event,row.id)" *ngIf="enable != row.id">

                                    <mat-icon title="Delete" (click)="deleteHolidayPopup($event,row.id)"
                                        *ngIf="enable != row.id">delete</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]"
                                    (keyup.enter)="update($event,row.id,this.HolidayForm.controls.holiday.value,this.HolidayForm.controls.branch.value,this.HolidayForm.controls.date.value)"
                                    *ngIf="enable == row.id">

                                    <mat-icon title="Save"
                                        (click)="update($event,row.id,this.HolidayForm.controls.holiday.value,this.HolidayForm.controls.branch.value,this.HolidayForm.controls.date.value)"
                                        *ngIf="enable == row.id">save</mat-icon>
                                </button>
                                <button mat-icon-button [tabIndex]="[i+3]" (keyup.enter)="canceledit($event,row.id)"
                                    *ngIf="enable == row.id">

                                    <mat-icon (click)="canceledit($event,row.id)" title="Cancel"
                                        *ngIf="enable == row.id">cancel
                                    </mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                </div>
            </form>
        </div>
    </mat-card>
</div>