import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-ems-policies',
  templateUrl: './ems-policies.component.html',
  styleUrls: ['./ems-policies.component.scss'],
})
export class EmsPoliciesComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private adminService: AdminService
  ) { }
  emsPoliciesForm!: FormGroup;

  displayedColumns: string[] = ['sno', 'configurationrules', 'data'];
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  companyDBName: any = environment.dbName;
  defaultRuleInfo: any = [];
  isEditDefaultRules: boolean = false;
  defaultDropdownList: any = [
    { value: '1', name: 'Yes' },
    { value: '0', name: 'No' },
  ];
  shiftDurationDropdownList: any = [
    { value: '1 week', name: '1 week' },
    { value: '2 weeks', name: '2 weeks' },
    { value: '1 month', name: '1 month' },
    { value: '3 months', name: '3 months' },
  ];
  rotationShiftChecklist: any = [
    { value: 1, name: 'Continue with the existing shift' },
    { value: 2, name: 'Implement Rotation Shift' },
  ];
  weekList = [
    { value: 1, name: 'Sunday' },
    { value: 2, name: 'Monday' },
    { value: 3, name: 'Tuesday' },
    { value: 4, name: 'Wednesday' },
    { value: 5, name: 'Thursday' },
    { value: 6, name: 'Friday' },
    { value: 7, name: 'Saturday' },
  ];

  msgLM1: any;
  msgLM2: any;
  msgLM3: any;
  msgLM21: any;
  msgLM110: any;
  msgLM111: any;
  msgLM133: any;
  msgLM134: any;
  msgLM135: any;
  msgLM136: any;
  shiftDataList: any = [];
  leaveTypeList: any = [];
  autoShiftDisable: boolean = true;
  autoDisable: boolean = true;
  ngOnInit(): void {
    this.emsPoliciesForm = this.formBuilder.group({
      pwdMinLength: [{ value: '', disabled: true }],
      pwdMaxLength: [{ value: '', disabled: true }],
      pwdLastMatchCount: [{ value: '', disabled: true }],
      pwdStartCapital: [{ value: '', disabled: true }],
      pwdMinCapitalCount: [{ value: '', disabled: true }],
      pwdIsAllowSpecialChar: [{ value: '', disabled: true }],
      pwdMinCountSpecialChar: [{ value: '', disabled: true }],
      pwdMinSmallLeterCount: [{ value: '', disabled: true }],
      pwdMinNumberCount: [{ value: '', disabled: true }],
      sendAutoRemindOnboard: [{ value: '', disabled: true }],
      probationPeriod: [{ value: '', disabled: true }],
      probationPeriodDays: [{ value: '', disabled: true }],
      sendAutoRemindOffboard: [{ value: '', disabled: true }],
      noticePeriod: [{ value: '', disabled: true }],
      noticePeriodDays: [{ value: '', disabled: true }],
      defaultWeekoff1: [''],
      defaultWeekoff2: [''],
      defaultWeekoff3: [''],
      autoShiftAssign: [''],
      automaticShift: [''],
      automaticShiftDuration: [''],
      howManyLeavesDeduction: ['', [Validators.pattern(/^\d+(\.\d*)?$/)]],
      leaveTypeDeduction: [''],
      howManyMaxLateAttendance: [''],
      defaultShift: [''],
      defaultShiftDuration: [''],
      minWorkHoursForHalfday: [''],
      minWorkHoursForFullday: [''],
      permissionPaidOrNot: [''],
      flexibleShiftEnabled: [''],
      flexibleShiftDuration: [''],
      weekoff_option: [''],
      regularzation_Duration: [''],
      edit_profile: ['']
    });
    this.autoDisable = true;
    this.getActiveShiftIds();
    this.getLeaveTypesForDeductionList();
    this.getEmsRules();
    this.emsPoliciesForm.get('autoShiftAssign')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue == 0) {
        this.autoShiftDisable = true;
        this.emsPoliciesForm.controls.automaticShift.reset();
        this.emsPoliciesForm.controls.automaticShiftDuration.reset();
      } else {
        this.autoShiftDisable = false;
      }
    });
    this.emsPoliciesForm.get('flexibleShiftEnabled')?.valueChanges.subscribe((selectedValue: any) => {
      if (selectedValue == 0) {
        this.emsPoliciesForm.controls.flexibleShiftDuration.reset();
        this.emsPoliciesForm.controls.flexibleShiftDuration.disable();
      } else {
        this.emsPoliciesForm.controls.flexibleShiftDuration.enable();
      }
    })
  }
  /**Common ems rules */
  getEmsRules() {
    this.adminService.getEmsPolicies().subscribe((result) => {
      this.defaultRuleInfo = result.data;
      this.dataSource = new MatTableDataSource(this.defaultRuleInfo);
      for (let i = 0; i < this.defaultRuleInfo.length; i++) {
        if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_LENGTH') {
          this.emsPoliciesForm.controls.pwdMinLength.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MAX_LENGTH') {
          this.emsPoliciesForm.controls.pwdMaxLength.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_LAST_PASSWORD_MATCH_COUNT'
        ) {
          this.emsPoliciesForm.controls.pwdLastMatchCount.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_IS_START_WITH_CAPITAL_LETTER'
        ) {
          this.emsPoliciesForm.controls.pwdStartCapital.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_MIN_CAPITAL_LETTERS_COUNT'
        ) {
          this.emsPoliciesForm.controls.pwdMinCapitalCount.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_IS_SPECIAL_CHARACTERS_ALLOWED'
        ) {
          this.emsPoliciesForm.controls.pwdIsAllowSpecialChar.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_MIN_SPECIAL_CHARACTERS_COUNT'
        ) {
          this.emsPoliciesForm.controls.pwdMinCountSpecialChar.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_SMALL_LETTERS_COUNT'
        ) {
          this.emsPoliciesForm.controls.pwdMinSmallLeterCount.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_NUMBERS_COUNT'
        ) {
          this.emsPoliciesForm.controls.pwdMinNumberCount.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'SEND_AUTOMATIC_REMAINDERS_ONBOARD'
        ) {
          this.emsPoliciesForm.controls.sendAutoRemindOnboard.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD') {
          this.emsPoliciesForm.controls.probationPeriod.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD_DAYS'
        ) {
          this.emsPoliciesForm.controls.probationPeriodDays.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'SEND_AUTOMATIC_REMAINDERS_OFFBOARD'
        ) {
          this.emsPoliciesForm.controls.sendAutoRemindOffboard.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD') {
          this.emsPoliciesForm.controls.noticePeriod.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD_DAYS') {
          this.emsPoliciesForm.controls.noticePeriodDays.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_1') {
          this.emsPoliciesForm.controls.defaultWeekoff1.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_2') {
          this.defaultRuleInfo[i].value != null ? this.emsPoliciesForm.controls.defaultWeekoff2.setValue(
            this.defaultRuleInfo[i].value) : null;

        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_3') {
          this.defaultRuleInfo[i].value != null ? this.emsPoliciesForm.controls.defaultWeekoff3.setValue(
            this.defaultRuleInfo[i].value) : null;
        } else if (
          this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_ASSIGNMENT'
        ) {
          this.emsPoliciesForm.controls.autoShiftAssign.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT') {
          this.emsPoliciesForm.controls.automaticShift.setValue(
            Number(this.defaultRuleInfo[i].value)
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_DURATION'
        ) {
          this.emsPoliciesForm.controls.automaticShiftDuration.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'ALLOWED_LATE_ATTENDANCES_PER_MONTH'
        ) {
          this.emsPoliciesForm.controls.howManyMaxLateAttendance.setValue(
            this.defaultRuleInfo[i].value
          );
          this.emsPoliciesForm.controls.howManyMaxLateAttendance.disable();
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'NUMBER_LEAVES_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
        ) {
          this.emsPoliciesForm.controls.howManyLeavesDeduction.setValue(
            this.defaultRuleInfo[i].value
          );
          this.emsPoliciesForm.controls.howManyLeavesDeduction.disable();
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'LEAVETYPE_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
        ) {
          this.emsPoliciesForm.controls.leaveTypeDeduction.setValue(
            Number(this.defaultRuleInfo[i].value)
          );
        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT') {
          this.emsPoliciesForm.controls.defaultShift.setValue(
            Number(this.defaultRuleInfo[i].value)
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT_DURATION'
        ) {
          this.emsPoliciesForm.controls.defaultShiftDuration.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          /**new */
          this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_HALFDAY'
        ) {
          this.emsPoliciesForm.controls.minWorkHoursForHalfday.setValue(
            this.defaultRuleInfo[i].value
          );
          this.emsPoliciesForm.controls.minWorkHoursForHalfday.disable();
        } else if (
          this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_FULLDAY'
        ) {
          this.emsPoliciesForm.controls.minWorkHoursForFullday.setValue(
            this.defaultRuleInfo[i].value
          );
          this.emsPoliciesForm.controls.minWorkHoursForFullday.disable();
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'CONSIDER_A_PERMISSION_AS_PAID_OR_NOT'
        ) {
          this.emsPoliciesForm.controls.permissionPaidOrNot.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'IS_FLEXI_SHIFT_ENABLED'
        ) {
          this.emsPoliciesForm.controls.flexibleShiftEnabled.setValue(
            this.defaultRuleInfo[i].value
          );
        } else if (
          this.defaultRuleInfo[i].rulename == 'FLEXI_SHIFT_DURATION_HOURS'
        ) {
          this.emsPoliciesForm.controls.flexibleShiftDuration.setValue(this.defaultRuleInfo[i].value);
          this.emsPoliciesForm.controls.flexibleShiftDuration.disable();
        }
        else if (this.defaultRuleInfo[i].rulename == 'IS_WEEKOFFS_OPTION_NEEDED_IN_REGULARIZATION') {
          this.emsPoliciesForm.controls.weekoff_option.setValue(this.defaultRuleInfo[i].value);

        } else if (this.defaultRuleInfo[i].rulename == 'DURATION_FOR_REGULARIZATION') {
          this.emsPoliciesForm.controls.regularzation_Duration.setValue(this.defaultRuleInfo[i].value);
          this.emsPoliciesForm.controls.regularzation_Duration.disable();
        } else if (this.defaultRuleInfo[i].rulename == 'IS_EMPLOYEE_DATA_EDITABLE') {
          this.emsPoliciesForm.controls.edit_profile.setValue(this.defaultRuleInfo[i].value);
          this.emsPoliciesForm.controls.edit_profile.disable();
        }

      }
    });
  }

  saveDefaultrules() {
    let halfdayHours = this.emsPoliciesForm.controls.minWorkHoursForHalfday.value;
    let fulldayHours = this.emsPoliciesForm.controls.minWorkHoursForFullday.value;
    if (
      this.emsPoliciesForm.controls.autoShiftAssign.value == 1 &&
      this.emsPoliciesForm.controls.automaticShift.value == null &&
      this.emsPoliciesForm.controls.automaticShiftDuration.value == null
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please select Shift Type and Duration',
      });
    } else if (
      this.emsPoliciesForm.controls.howManyMaxLateAttendance.value != '' &&
      this.emsPoliciesForm.controls.howManyLeavesDeduction.value == ''
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please enter leave deduction count',
      });
    } else if (this.emsPoliciesForm.controls.minWorkHoursForHalfday.value == '' ||
      this.emsPoliciesForm.controls.minWorkHoursForHalfday.value == null ||
      this.emsPoliciesForm.controls.minWorkHoursForHalfday.value < 1
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please enter minimum 1 hour for half day attendance',
      });

    }
    else if (this.emsPoliciesForm.controls.minWorkHoursForFullday.value == '' ||
      this.emsPoliciesForm.controls.minWorkHoursForFullday.value == null
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please enter minimum hours for full day attendance',
      });

    }
    else if (parseInt(halfdayHours) > parseInt(fulldayHours)) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Full day hours should not be less than Half day hours',
      });
      this.emsPoliciesForm.controls.minWorkHoursForFullday.setValue('');
    }
    else if (
      this.emsPoliciesForm.controls.flexibleShiftEnabled.value == 1 &&
      this.emsPoliciesForm.controls.flexibleShiftDuration.value == null
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please enter minimum hours for flexible shit',
      });

    } else if (
      this.emsPoliciesForm.controls.regularzation_Duration.value < 30 ||
      this.emsPoliciesForm.controls.regularzation_Duration.value > 45
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Attendance Regularization duration value must be in between 30 to 45',
      });
      this.emsPoliciesForm.controls.regularzation_Duration.reset();
    }

    else if (this.emsPoliciesForm.valid) {
      for (let i = 0; i < this.defaultRuleInfo.length; i++) {
        if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_LENGTH') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMinLength.value;
        } else if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MAX_LENGTH') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMaxLength.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_LAST_PASSWORD_MATCH_COUNT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdLastMatchCount.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_IS_START_WITH_CAPITAL_LETTER'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdStartCapital.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_MIN_CAPITAL_LETTERS_COUNT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMinCapitalCount.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_IS_SPECIAL_CHARACTERS_ALLOWED'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdIsAllowSpecialChar.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'PASSWORD_MIN_SPECIAL_CHARACTERS_COUNT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMinCountSpecialChar.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_SMALL_LETTERS_COUNT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMinSmallLeterCount.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_NUMBERS_COUNT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.pwdMinNumberCount.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'SEND_AUTOMATIC_REMAINDERS_ONBOARD'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.sendAutoRemindOnboard.value;
        } else if (this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.probationPeriod.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD_DAYS'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.probationPeriodDays.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'SEND_AUTOMATIC_REMAINDERS_OFFBOARD'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.sendAutoRemindOffboard.value;
        } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.noticePeriod.value;
        } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD_DAYS') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.noticePeriodDays.value;
        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_1') {

          if (this.emsPoliciesForm.controls.defaultWeekoff1.value != '' || null) {
            this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.defaultWeekoff1.value;
          } else {
            this.defaultRuleInfo[i].value = null
          }

        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_2') {

          if (this.emsPoliciesForm.controls.defaultWeekoff2.value != '' || null) {
            this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.defaultWeekoff2.value;
          } else {
            this.defaultRuleInfo[i].value = null
          }

        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_3') {

          if (this.emsPoliciesForm.controls.defaultWeekoff3.value != '' || null) {
            this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.defaultWeekoff3.value;
          } else {
            this.defaultRuleInfo[i].value = null
          }
        } else if (
          this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_ASSIGNMENT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.autoShiftAssign.value;
        } else if (this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.automaticShift.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_DURATION'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.automaticShiftDuration.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'ALLOWED_LATE_ATTENDANCES_PER_MONTH'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.howManyMaxLateAttendance.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'NUMBER_LEAVES_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.howManyLeavesDeduction.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'LEAVETYPE_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.leaveTypeDeduction.value;
        } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT') {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.defaultShift.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT_DURATION'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.defaultShiftDuration.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_HALFDAY'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.minWorkHoursForHalfday.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_FULLDAY'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.minWorkHoursForFullday.value;
        } else if (
          this.defaultRuleInfo[i].rulename ==
          'CONSIDER_A_PERMISSION_AS_PAID_OR_NOT'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.permissionPaidOrNot.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'IS_FLEXI_SHIFT_ENABLED'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.flexibleShiftEnabled.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'FLEXI_SHIFT_DURATION_HOURS'
        ) {
          this.defaultRuleInfo[i].value =
            this.emsPoliciesForm.controls.flexibleShiftDuration.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'IS_WEEKOFFS_OPTION_NEEDED_IN_REGULARIZATION'
        ) {
          this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.weekoff_option.value;
        } else if (
          this.defaultRuleInfo[i].rulename == 'DURATION_FOR_REGULARIZATION'
        ) {
          this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.regularzation_Duration.value;
        } else if (this.defaultRuleInfo[i].rulename == 'IS_EMPLOYEE_DATA_EDITABLE') {
          this.defaultRuleInfo[i].value = this.emsPoliciesForm.controls.edit_profile.value;
        }
      }

      var info = {
        ruleData: this.defaultRuleInfo,
      };
      this.adminService.setEmsRuleValues(info).subscribe((result) => {
        if (result.status && result.data.successstate == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'HR Policies updated successfully',
          });
          this.isEditDefaultRules = false;
          this.autoShiftDisable = true;
          this.autoDisable = true;
          this.getEmsRules();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to update HR policies. Please try again.',
          });
        }
      });
    }
  }

  cancelDefaultRules() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/ems-policies']));
  }
  editDefaultRules() {
    this.isEditDefaultRules = true;
    this.autoDisable = false;
    this.emsPoliciesForm.controls.howManyMaxLateAttendance.enable();
    this.emsPoliciesForm.controls.howManyLeavesDeduction.enable();
    this.emsPoliciesForm.controls.minWorkHoursForHalfday.enable();
    this.emsPoliciesForm.controls.minWorkHoursForFullday.enable();
    this.emsPoliciesForm.controls.regularzation_Duration.enable();
    for (let i = 0; i < this.defaultRuleInfo.length; i++) {
      if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_LENGTH') {
        this.emsPoliciesForm.controls.pwdMinLength.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'PASSWORD_MAX_LENGTH') {
        this.emsPoliciesForm.controls.pwdMaxLength.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'PASSWORD_LAST_PASSWORD_MATCH_COUNT'
      ) {
        this.emsPoliciesForm.controls.pwdLastMatchCount.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'PASSWORD_IS_START_WITH_CAPITAL_LETTER'
      ) {
        this.emsPoliciesForm.controls.pwdStartCapital.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_CAPITAL_LETTERS_COUNT'
      ) {
        this.emsPoliciesForm.controls.pwdMinCapitalCount.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'PASSWORD_IS_SPECIAL_CHARACTERS_ALLOWED'
      ) {
        this.emsPoliciesForm.controls.pwdIsAllowSpecialChar.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'PASSWORD_MIN_SPECIAL_CHARACTERS_COUNT'
      ) {
        this.emsPoliciesForm.controls.pwdMinCountSpecialChar.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_SMALL_LETTERS_COUNT'
      ) {
        this.emsPoliciesForm.controls.pwdMinSmallLeterCount.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'PASSWORD_MIN_NUMBERS_COUNT'
      ) {
        this.emsPoliciesForm.controls.pwdMinNumberCount.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'SEND_AUTOMATIC_REMAINDERS_ONBOARD'
      ) {
        this.emsPoliciesForm.controls.sendAutoRemindOnboard.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD') {
        this.emsPoliciesForm.controls.probationPeriod.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'PROBATION_PERIOD_DAYS') {
        this.emsPoliciesForm.controls.probationPeriodDays.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'SEND_AUTOMATIC_REMAINDERS_OFFBOARD'
      ) {
        this.emsPoliciesForm.controls.sendAutoRemindOffboard.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD') {
        this.emsPoliciesForm.controls.noticePeriod.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'NOTICE_PERIOD_DAYS') {
        this.emsPoliciesForm.controls.noticePeriodDays.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_1') {
        this.emsPoliciesForm.controls.defaultWeekoff1.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_2') {
        this.emsPoliciesForm.controls.defaultWeekoff2.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_WEEKOFF_3') {
        this.defaultRuleInfo[i].value != null
          ? this.emsPoliciesForm.controls.defaultWeekoff3.setValue(
            this.defaultRuleInfo[i].value
          )
          : null;
      } else if (
        this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_ASSIGNMENT'
      ) {
        this.emsPoliciesForm.controls.autoShiftAssign.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT') {
        this.emsPoliciesForm.controls.automaticShift.setValue(
          Number(this.defaultRuleInfo[i].value)
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'AUTOMATIC_SHIFT_DURATION'
      ) {
        this.emsPoliciesForm.controls.automaticShiftDuration.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'ALLOWED_LATE_ATTENDANCES_PER_MONTH'
      ) {
        this.emsPoliciesForm.controls.howManyMaxLateAttendance.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'NUMBER_LEAVES_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
      ) {
        this.emsPoliciesForm.controls.howManyLeavesDeduction.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'LEAVETYPE_TO_DEDUCT_FOR_MAX_LATE_ATTENDANCES'
      ) {
        this.emsPoliciesForm.controls.leaveTypeDeduction.setValue(
          Number(this.defaultRuleInfo[i].value)
        );
      } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT') {
        this.emsPoliciesForm.controls.defaultShift.setValue(
          Number(this.defaultRuleInfo[i].value)
        );
      } else if (this.defaultRuleInfo[i].rulename == 'DEFAULT_SHIFT_DURATION') {
        this.emsPoliciesForm.controls.defaultShiftDuration.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_HALFDAY'
      ) {
        this.emsPoliciesForm.controls.minWorkHoursForHalfday.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'MIN_WORKING_HOURS_FOR_FULLDAY'
      ) {
        this.emsPoliciesForm.controls.minWorkHoursForFullday.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename ==
        'CONSIDER_A_PERMISSION_AS_PAID_OR_NOT'
      ) {
        this.emsPoliciesForm.controls.permissionPaidOrNot.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (this.defaultRuleInfo[i].rulename == 'IS_FLEXI_SHIFT_ENABLED') {
        this.emsPoliciesForm.controls.flexibleShiftEnabled.setValue(
          this.defaultRuleInfo[i].value
        );
      } else if (
        this.defaultRuleInfo[i].rulename == 'FLEXI_SHIFT_DURATION_HOURS'
      ) {
        this.emsPoliciesForm.controls.flexibleShiftDuration.setValue(this.defaultRuleInfo[i].value);

      } else if (this.defaultRuleInfo[i].rulename == 'IS_WEEKOFFS_OPTION_NEEDED_IN_REGULARIZATION') {
        this.emsPoliciesForm.controls.weekoff_option.setValue(this.defaultRuleInfo[i].value);

      } else if (this.defaultRuleInfo[i].rulename == 'DURATION_FOR_REGULARIZATION') {
        this.emsPoliciesForm.controls.regularzation_Duration.setValue(this.defaultRuleInfo[i].value);
      }
    }
  }
  getActiveShiftIds() {
    this.adminService.getActiveShiftValuesForPolicies().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
      }
    });
  }

  getLeaveTypesForDeductionList() {
    this.adminService.getLeavesForDeductionConfiguration().subscribe((res) => {
      if (res.status) {
        this.leaveTypeList = res.data;
      }
    });
  }

  onChange() {
    let minNumber = this.emsPoliciesForm.controls.howManyLeavesDeduction.value;
    if (minNumber > 5) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Leave deduction count should not be greater than 5 ',
      });
      this.emsPoliciesForm.controls.howManyLeavesDeduction.setValue('');
    }
  }
}
