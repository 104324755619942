<div fxLayout="column"  style="height: 100%;padding: 10px ; padding-left: 10%;padding-right: 10%;overflow-y: auto;"  *ngIf="flag">
    <mat-card style="background-color: #ffffff;border: 1px solid #cccbcb;">
        <div>
            <mat-card style="background-color: #ffffff">
                <div fxLayout="row">
                    <!-- <div fxFlex="30" fxFlex.xs="50" fxFlex.sm="50" fxLayoutAlign="center">
                        <img src="assets/img/profile.jpg" />
                    </div> -->

                    <div fxFlex="100" fxLayoutAlign="center">
                        <div fxLayout="row" fxLayout.xs="column" style="width: 100%">
                            <div fxLayout="column" fxLayoutAlign="center" style="width: 45%">
                                <p class="subh">Name
                                    <span class="heading" style="padding-left: 55px;">{{ employeeNameh | titlecase }}</span>
                                </p>
                                <p class="subh">
                                    Designation
                                    <span class="heading1" style="padding-left: 3%;">{{
                                        employeeDesignation
                                        }}</span>
                                </p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="center" style="width: 55%">
                                <p class="subh">
                                    Mobile
                                    <span class="heading1" style="padding-left:53px">{{ employeeMobile }}
                                    </span>
                                </p>
                                <p class="subh">
                                    Joining Date
                                    <span class="heading1" style="padding-left: 3%;">
                                        {{ employeeJoinDate | date: "dd-MM-YYYY" }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div>
            <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedtab.value" (selectedTabChange)="tabClick($event)"
            (selectedIndexChange)="selectedtab.setValue($event)" mat-stretch-tabs>
                <!-- personal info tab -->
                <mat-tab label="Personal Info">
                    <div fxLayout="column">
                      
                        <form [formGroup]="personalInfoForm">
                            <mat-card style="padding-left: 1%;" class="outline">
                            <div class="section"><b>Personal Information</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%;">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input type="text" formControlName="firstname" #firstname
                                    onlyAlphabets noLeadingWhitespace matInput placeholder="First Name"
                                        maxlength="60" required />
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.firstname.errors?.required">
                                        {{EM1}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Middle Name</mat-label>
                                    <input type="text" onlyAlphabets formControlName="middlename" matInput placeholder=""
                                        maxlength="50" />
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input formControlName="lastname" noLeadingWhitespace onlyAlphabets matInput placeholder=""
                                        maxlength="50" required />
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.lastname.errors?.required">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Date of Birth</mat-label>
                                    <input required matInput readonly [matDatepicker]="dateofbirth" [min]="minDate"
                                        [max]="maxBirthDate" placeholder="DD-MM-YYYY" formControlName="dateofbirth"
                                        (click)="dateofbirth.open()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="dateofbirth">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dateofbirth></mat-datepicker>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.dateofbirth.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Blood Group</mat-label>
                                    <mat-select formControlName="bloodgroup">
                                        <ng-container>
                                            <div *ngFor="let b of bloodGroupdetails">
                                                <mat-option [value]="b.id">{{
                                                    b.bloodgroup
                                                    }}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Gender</mat-label>
                                    <mat-select formControlName="gender" required>
                                        <ng-container>
                                            <div *ngFor="let b of genderDetails">
                                                <mat-option [value]="b.id">{{b.gender}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.gender.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Marital Status</mat-label>
                                    <mat-select formControlName="maritalstatus" required>
                                        <ng-container>
                                            <div *ngFor="let b of maritalStatusDetails">
                                                <mat-option [value]="b.id">{{
                                                    b.maritalstatus
                                                    }}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.maritalstatus.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Aadhar Number</mat-label>
                                    <input #aadharnumber type="text" onlyNumber formControlName="aadharNumber" matInput maxlength="12"
                                        placeholder="Aadhar Number" />
                                </mat-form-field>

                                <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>PAN Number</mat-label>
                                    <input type="text" alphaNumericOnly formControlName="panNumber" matInput placeholder=""
                                        maxlength="10" />
                                </mat-form-field> -->
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Personal Email</mat-label>
                                    <input type="text" formControlName="personalemail" matInput placeholder=""
                                        required />
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.personalemail.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                                <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>UAN Number</mat-label>
                                    <input type="number" onlyNumber formControlName="uanNumber" matInput placeholder=""
                                        max="12" />
                                </mat-form-field> -->
                                <mat-form-field fxFlex.xs="100" fxFlex="50" fxFlex.sm="50" class="mx-2 my-2"
                                appearance="outline">
                                <mat-label>Languages Spoken</mat-label>
                                <input type="text" formControlName="spokenLanguages" matInput placeholder=""/>
                            </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Mobile Number</mat-label>
                                    <input type="text" onlyNumber formControlName="mobileNo" matInput placeholder=""
                                        maxlength="10" minlength="10" required />
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.mobileNo.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                 </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Emergency Contact Number</mat-label>
                                    <input type="text" onlyNumber formControlName="alternateMobileNo" matInput
                                        placeholder="" maxlength="10" minlength="10" />
                                </mat-form-field>

                               
                            </div>

                            <div class="section"><b>Personal Contact Information</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end"
                            style="padding-top:1% ;padding-bottom:1% ;">

                            <mat-slide-toggle formControlName="checked" color="primary"><b>Same as residence
                                    address</b></mat-slide-toggle>
                        </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Residence Address</mat-label>
                                    <input type="text" formControlName="raddress" matInput noLeadingWhitespace placeholder="" maxlength="100"
                                        required>
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.raddress.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                   </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Permanent Address</mat-label>
                                    <input type="text" formControlName="paddress" matInput placeholder="" maxlength="100">
                                    <!-- <input *ngIf="isview" formControlName="lastname" matInput placeholder="" readonly> -->
                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="rcountry" required>
                                        <ng-container>
                                            <div *ngFor="let b of countryDetails">
                                                <mat-option [value]="b.id">{{b.country}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.rcountry.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                 </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="pcountry">
                                        <ng-container>
                                            <div *ngFor="let b of permanentCountryDetails">
                                                <mat-option [value]="b.id">{{b.country}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="rstate" required>
                                        <ng-container>
                                            <div *ngFor="let b of stateDetails">
                                               <mat-option [value]="b.id">{{b.state}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.rstate.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                 </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="pstate">
                                        <ng-container>
                                            <div *ngFor="let b of permanentStateDetails">
                                                <mat-option [value]="b.id">{{b.state}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="rcity" required>
                                        <ng-container>
                                            <div *ngFor="let b of cityDetails">
                                                <mat-option [value]="b.id">{{b.location}}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="personalInfoForm.controls.rcity.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                 </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="pcity">
                                        <ng-container>
                                            <div *ngFor="let b of permanentCityDetails">
                                                <mat-option [value]="b.id">{{b.location}}</mat-option>
                                            </div>

                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Pincode</mat-label>
                                    <input type="text" onlyNumber formControlName="rpincode" matInput placeholder=""
                                        maxlength="6" minlength="6" required>
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.rpincode.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.rpincode.errors?.minlength">
                                           Pincode should be 6 digits
                                        </mat-error>
                                 </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Pincode</mat-label>
                                    <input type="text" onlyNumber formControlName="ppincode" matInput placeholder=""
                                        maxlength="6" minlength="6">
                                        <mat-error class="reg-error" *ngIf="personalInfoForm.controls.ppincode.errors?.minlength">
                                            Pincode should be 6 digits
                                         </mat-error>
                                </mat-form-field>

                            </div>

                    <form [formGroup]="CandidateFamilyForm">
                            <div class="section"><b>Family Details</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%;">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input type="text" onlyAlphabets formControlName="familyfirstname" matInput placeholder=""
                                        maxlength="100" required />
                                        <mat-error class="con-error"
                                  *ngIf="CandidateFamilyForm.controls.familyfirstname.errors?.required">
                                  {{EM1}}
                              </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Contact Number</mat-label>
                                    <input type="text" onlyNumber formControlName="familycontact" matInput
                                        placeholder="" maxlength="10" minlength="10"/>
                                        <mat-error class="con-error" *ngIf="CandidateFamilyForm.get('familycontact').hasError('pattern')">
                                            Please enter valid mobile number
                                         </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Gender</mat-label>
                                    <mat-select formControlName="familygender" required>
                                        <ng-container>
                                            <div *ngFor="let b of genderDetails">
                                                <mat-option [value]="b">{{ b.gender }}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="CandidateFamilyForm.controls.familygender.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign.xs="center">
                                <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                    appearance="outline">
                                    <mat-label>Relationship</mat-label>
                                    <mat-select required formControlName="relation">
                                        <ng-container>
                                            <div *ngFor="let b of employeeRelationship">
                                                <mat-option [value]="b">{{
                                                    b.relationship
                                                    }}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error" *ngIf="CandidateFamilyForm.controls.relation.hasError('required')">
                                        {{EM2}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex.sm="50" fxFlex="32" class="mx-2 my-2"
                                    appearance="outline">
                                    <mat-label>Status</mat-label>
                                    <mat-select required formControlName="familystatus">
                                        <ng-container>
                                            <div>
                                                <mat-option value="Alive">Alive</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <div fxLayout="row" fxLayoutAlign="center">
                                    <span style="padding-top: 5%;">
                                        <button class="btn btn-primary mr-2 mb-2" (click)="addfamily()">
                                            <span> Add </span>
                                        </button>
                                          <button class="btn btn-danger mr-2 mb-2" (click)="clearfamily()">
                                            Clear
                                        </button>
                                    </span>
                                   
                                </div>
                             </div>

                            <div class="mat-elevation-z1" *ngIf="familyDetails.length > 0">
                                <table mat-table [dataSource]="familyDataSource">
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef> S No </th>
                                        <td mat-cell *matCellDef="let data; let i = index;"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.firstname }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="relation">
                                        <th mat-header-cell *matHeaderCellDef>Relationship</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.relationshipname }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="gender">
                                        <th mat-header-cell *matHeaderCellDef>Gender</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.gendername }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="contact">
                                        <th mat-header-cell *matHeaderCellDef>Contact Number</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.contactnumber !='null' ? data.contactnumber:'' }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef>Status</th>
                                        <td mat-cell *matCellDef="let data">{{ data.status }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <!-- <mat-icon title="Edit" (click)="editfamily(i)" *ngIf="!isviewemployee">edit
                                            </mat-icon> -->
                                            <mat-icon title="Delete" (click)="deleteFamilyPopup(i)" *ngIf="!isviewemployee">
                                                delete</mat-icon>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="familyTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: familyTableColumns"></tr>
                                </table>
                            
                            </div>
                        </form>
                    </mat-card>
                        <br>
                        <div fxLayoutAlign="center">
                            <button class="btn btn-primary custom2 mr-2 mb-2"   (click)="savePersonalInfo()"
                                type="submit">
                                <span>Save & Continue</span>
                            </button>
                            <br>
                        </div>    
                    </form>
               
                    </div>
                </mat-tab>
                 <!-- employement tab -->
                <mat-tab label="Work Experience">
                    <div fxLayout="column">
                        <form [formGroup]="employementForm">
                            <mat-card style="padding-left: 1%;" class="outline">
                            <div class="section"><b>Work Experience</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%;">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Company Name</mat-label>
                                    <input type="text" formControlName="companyName" onlyAlphabets matInput
                                        placeholder="" maxlength="150" required />
                                        <mat-error class="reg-error" *ngIf="employementForm.controls.companyName.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Designation</mat-label>
                                    <input type="text" required formControlName="designation" onlyAlphabets matInput
                                        placeholder="" maxlength="30" />
                                        <mat-error class="reg-error" *ngIf="employementForm.controls.designation.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100%" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>From Date</mat-label>
                                    <input required matInput readonly [matDatepicker]="expFromDate" [min]="minDate"
                                        [max]="maxDate" placeholder="DD-MM-YYYY" formControlName="expFromDate" (click)="expFromDate.open()" 
                                        (dateChange)="onExpDateChange()"/>
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="expFromDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expFromDate></mat-datepicker>
                                    <mat-error class="reg-error" *ngIf="employementForm.controls.expFromDate.hasError('required')">
                                        {{EM1}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100%" fxFlex="32" class="mx-2 my-2" appearance="outline">
                                    <mat-label>To Date</mat-label>
                                    <input required matInput readonly [matDatepicker]="expToDate" [min]="minExperienceDate" [max]="maxDate"
                                    placeholder="DD-MM-YYYY" formControlName="expToDate" (click)="expToDate.open()"/>
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="expToDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #expToDate></mat-datepicker>
                                    <mat-error class="reg-error" *ngIf="employementForm.controls.expToDate.hasError('required')">
                                        {{EM1}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="50" fxFlex.xs="100">
                                <mat-form-field fxFlex="100" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Job Description</mat-label>
                                    <textarea formControlName="jobDescription" matInput placeholder=""
                                        maxlength="1500"></textarea>
                                </mat-form-field>
                                </div>
<!-- 
                              <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="start center">
                                <span>
                                    <button class="btn btn-primary mr-2 mb-2" (click)="addWorkExperience()">
                                        <span> Submit </span>
                                    </button>
                                    <button class="btn btn-primary custom1 mr-2 mb-2" (click)="workExperienceSubmitAdd()">
                                        <span> Submit & Add</span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" (click)="clearWork()">
                                        Clear
                                    </button>
                                </span>
                              </div> -->

                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                               <span>
                                    <button class="btn btn-primary mr-2 mb-2" (click)="addWorkExperience()">
                                        <span> Save </span>
                                    </button>
                                    <button class="btn btn-primary custom1 mr-2 mb-2" (click)="workExperienceSubmitAdd()">
                                        <span> Save & Add</span>
                                    </button>
                                    <button class="btn btn-danger mr-2 mb-2" (click)="clearWork()">
                                        Clear
                                    </button>
                                </span>
                            
                            </div>

                            <div class="mat-elevation-z1" *ngIf="workExperienceDetails.length > 0">
                                <table mat-table [dataSource]="workExperienceDataSource">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.companyname }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="desig">
                                        <th mat-header-cell *matHeaderCellDef>Designation</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.designation }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.fromdate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="toDate">
                                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.todate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <!-- <mat-icon title="Edit" (click)="editfamily(i)" *ngIf="!isviewemployee">edit
                                            </mat-icon> -->
                                            <mat-icon title="Delete" (click)="deleteExperiencePopup(i)" *ngIf="!isviewemployee">
                                                delete</mat-icon>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="workTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: workTableColumns"></tr>
                                </table>
                            </div>
                        </mat-card>
                        <br>
                            <!-- <div fxLayoutAlign="center" *ngIf="workExperienceDetails.length > 0">
                                <button class="btn btn-primary custom mr-2 mb-2"   (click)="saveWorkExperience()"
                                    type="submit">
                                    <span>Save & Continue</span>
                                </button>
                              
                                <br />
                            </div> -->
                        </form>  
                    </div>

                </mat-tab>

                <!-- education tab -->
                <mat-tab label="Education">
                    <div fxLayout="column">
                        <form [formGroup]="educationForm">
                            <mat-card style="padding-left: 1%;" class="outline">
                            <div class="section"><b>Education</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%;">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Course</mat-label>
                                    <input type="text" maxlength="100" formControlName="course" matInput placeholder=""
                                        appRemoveSpace required onlyAlphabets/>
                                        <mat-error class="reg-error" *ngIf="educationForm.controls.course.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Institute Name</mat-label>
                                    <input type="text" maxlength="100" onlyAlphabets formControlName="instituteName" matInput
                                        placeholder="" appRemoveSpace required/>
                                        <mat-error class="reg-error" *ngIf="educationForm.controls.instituteName.hasError('required')">
                                            {{EM1}}
                                        </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>From Date</mat-label>
                                    <input matInput readonly [matDatepicker]="eduFromDate" [max]="edmaxDate"
                                        placeholder="DD-MM-YYYY" formControlName="eduFromDate"
                                        (click)="eduFromDate.open()" required (dateChange)="onEduDateChange()" />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduFromDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #eduFromDate></mat-datepicker>
                                    <mat-error class="reg-error" *ngIf="educationForm.controls.eduFromDate.hasError('required')">
                                       Please select date
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>To Date</mat-label>
                                    <input matInput readonly [matDatepicker]="eduToDate" [min]="minEducationDate"
                                        [max]="edmaxDate" placeholder="DD-MM-YYYY" formControlName="eduToDate"
                                        (click)="eduToDate.open()" required />
                                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="eduToDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #eduToDate></mat-datepicker>
                                    <mat-error class="reg-error" *ngIf="educationForm.controls.eduToDate.hasError('required')">
                                        Please select date
                                     </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button class="btn btn-primary mr-2 mb-2" (click)="addEducation()">
                                    <span> Save </span>
                                </button>
                                <button class="btn btn-primary custom1 mr-2 mb-2" (click)="educationSaveAdd()">
                                    <span> Save & Add </span>
                                </button>
                                <button class="btn btn-danger mr-2 mb-2" (click)="clearEducation()">
                                    Clear
                                </button>
                            </div>
                            <div class="mat-elevation-z1" *ngIf="educationDetails.length > 0">
                                <table mat-table [dataSource]="educationDataSource">
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="course">
                                        <th mat-header-cell *matHeaderCellDef>Course</th>
                                        <td mat-cell *matCellDef="let data">
                                           {{ data.course }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="college">
                                        <th mat-header-cell *matHeaderCellDef>Institute Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.institutename }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fromDate">
                                        <th mat-header-cell *matHeaderCellDef>From Date</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.fromdate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="toDate">
                                        <th mat-header-cell *matHeaderCellDef>To Date</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.todate | date:'dd-MM-yyyy'}}
                                        </td>
                                    </ng-container>
                                      <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element; let i = index">
                                            <!-- <mat-icon title="Edit" (click)="editfamily(i)" *ngIf="!isviewemployee">edit
                                            </mat-icon> -->
                                            <mat-icon title="Delete" (click)="deleteEducationPopup(i)" *ngIf="!isviewemployee">
                                                delete</mat-icon>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="educationTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: educationTableColumns"></tr>
                                </table>
                            </div>
                        </mat-card>
                            <br>

                         <!-- <div fxLayoutAlign="center" *ngIf="educationDetails.length > 0">
                            <button class="btn btn-primary custom mr-2 mb-2"    (click)="saveEducation()"
                                type="submit">
                                <span>Save & Continue</span>
                            </button>
                         </div> -->
                        </form>
                    </div>
                </mat-tab>

                <!-- document tab -->
                <mat-tab label="Documents">
                    <div fxLayout="column">
                        <mat-card style="padding-left: 1%;" class="outline">
                        <form [formGroup]="documentsForm">
                            <div class="section"><b>Add Documents</b></div>
                            <mat-divider></mat-divider>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" style="padding-top: 1%;">
                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Document Name</mat-label>
                                    <mat-select required formControlName="documentName">
                                        <ng-container>
                                            <div *ngFor="let b of documentTypeList">
                                                <mat-option value="{{ b.category }}">{{ b.description }}</mat-option>
                                            </div>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="reg-error"
                                    *ngIf="documentsForm.controls.documentName.errors?.required">
                                    {{EM2}}
                                </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                                    <mat-label>Document Number</mat-label>
                                    <input type="text" maxlength="30" minlength="6"  formControlName="documentNumber" matInput
                                        placeholder="" required/>
                                        <mat-error class="reg-error"
                                        *ngIf="documentsForm.controls.documentNumber.errors?.required">
                                        {{EM1}}
                                    </mat-error>
                                    <mat-error class="reg-error"
                                    *ngIf="documentsForm.controls.documentNumber.errors?.minlength">
                                    {{EM22}}
                                </mat-error>
                                </mat-form-field>

                                <div fxLayout="row" style="align-items: center">
                                    <input type="file" *ngIf='!isedit' title="" style="margin-left: 10px"
                                    formControlName="attachedFile"  (change)="onSelectFile($event)"  single />
                                     <button class="link" *ngIf='isedit' >{{ editFileName }}</button>

                                    &nbsp;
                                    <mat-icon title="Delete"  *ngIf=' documentsForm.controls.attachedFile.value' 
                                    (click)="deleteIcon()">delete</mat-icon>
                                    <mat-icon title="edit"  *ngIf='isedit'
                                        (click)="delete()">edit</mat-icon>
                                    &nbsp;
                                </div>
                            </div>
                            <br>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button class="btn btn-primary mr-2 mb-2"  (click)="validateDocument()">
                                    <span> Save </span>
                                </button>
                                <button class="btn btn-danger mr-2 mb-2" (click)="clearDock()">
                                    Clear
                                </button>
                            </div>
                            <div class="mat-elevation-z1" *ngIf="documentDetails.length > 0">
                                <table mat-table [dataSource]="documentDataSource">
                                    <ng-container matColumnDef="position">
                                        <th mat-header-cell *matHeaderCellDef>S No</th>
                                        <td mat-cell *matCellDef="let data; let i = index">
                                            {{ i + 1 }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.description}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="number">
                                        <th mat-header-cell *matHeaderCellDef>Document Number</th>
                                        <td mat-cell *matCellDef="let data">
                                            {{ data.document_number }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Document</th>
                                        <td mat-cell *matCellDef="let data">

                                            <button class="link" (click)="fileView(data)" >{{ data.fname }}</button>

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let data">
                                        <!-- <mat-icon (click)="editDock(data)" title="Edit" *ngIf="data.status == 'Submitted'">edit</mat-icon> -->
                                        <mat-icon (click)="deleteDock(data)" title="Delete" *ngIf="data.status == 'Submitted'"> delete</mat-icon>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="documentTableColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: documentTableColumns"></tr>
                                </table>
                            </div>
                            <!-- <div fxLayoutAlign="center">
                                <button class="m-1 mat-stroked-button button-save" (click)="saveDocument()"
                                    type="submit">
                                    <span>Submit</span>
                                </button>
                                <button class="m-1 mat-stroked-button button-clear" (click)="personalInfoClear()">
                                    <span>Cancel</span>
                                </button>
                                <br />
                            </div> -->
                        </form>
                    </mat-card>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </mat-card>
</div>
<ngx-spinner size="medium" type='ball-fussion'></ngx-spinner>

<div fxLayout="column" fxLayoutAlign="center center" *ngIf="!flag" style="height: 100%;">
    <mat-card >
    <span>
        <h1 class="text-dark-50 text-center mt-0 fw-bold" style="color: red">Selected URL is expired </h1>
    </span>
</mat-card>
</div>
