<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title">
               Shifts Wise Report
            </mat-card-title>
        </mat-card-header>
        <br />
        <div fxLayout fxLayout="row" fxLayoutAlign.xs="center center"
         [formGroup]="shiftReportForm" fxLayoutAlign="space-between">
          <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column"> 
            <div fxLayout="row" fxLayout.xs="column">
                <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>From Date</mat-label>
                    <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                        placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()" tabindex="2"/>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>To Date</mat-label>
                    <input matInput readonly [matDatepicker]="toDate" [min]="minDate" [max]="maxDate"
                        placeholder="From Date" formControlName="toDate" (click)="toDate.open()" tabindex="2"/>
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center">
                <span ngStyle.gt-md="margin-top: 5%">
                    <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="3">
                        Search
                    </button>
                    <span class="line" style="color: #1898d5; font-size: 16px"></span>
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                        <span>Clear</span>
                    </button>
                </span>
            </div>
        </div>
            <div fxLayout="row xs-column" fxLayoutAlign="end" *ngIf="shiftDataList.length > 0"
                style="margin-top: 10px; margin-right: 3%">
                <div>
                  <button mat-icon-button tabindex="5" (keyup.enter)="exportPDF()">
                    <a title="PDF">
                        <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
                   </button>
                   
                </div>
            </div>
        </div>
      

        <div class="p-2">
            <div class="example-container" style="padding: 10px" #table>
                <table style="width: 100%" *ngIf="shiftDataList.length > 0">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let key of getObjectKeys(headersList[0])">
                                <th>
                                    <span class="header-cell">
                                        {{ key }}
                                            </span>
                                 </th>
                        
                            </ng-container>
                   <th class="header-cell">Details   </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of shiftDataList">
                            <tr style="width: auto">
                                <ng-container *ngFor="let key of getObjectKeys(item) ">
                                    <td>
                                        <span>
                                            {{ item[key] }}
                                        </span>
                                    </td>
                                </ng-container>
                                <td>
                                    <button mat-icon-button (click)="view(item.Date)" >
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div style="text-align: center; padding-top: 20px" *ngIf="shiftDataList.length === 0">
                    No data found.
                </div>
            </div>
        </div>
    </mat-card>
</div>