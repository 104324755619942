import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ClaimsRoutingModule } from './claims-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'; 
 import dayGridPlugin from '@fullcalendar/daygrid'; 
 import interactionPlugin from '@fullcalendar/interaction'; 
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ClaimsReimbursementTypesComponent } from './pages/claims-reimbursement-types/claims-reimbursement-types.component';
import { ClaimsUserRequestComponent } from './pages/claims-user-request/claims-user-request.component';
import { ClaimsEmployeeWiseDetailedComponent } from './pages/claims-employee-wise-detailed/claims-employee-wise-detailed.component';
import { ClaimsApprovalsComponent } from './pages/claims-approvals/claims-approvals.component';
import { ClaimsDetailedReportComponent } from './pages/claims-detailed-report/claims-detailed-report.component';
import { SharedModule } from 'src/app/shared/shared.module';


// module-name.module.ts

@NgModule({
  declarations: [/* list of components, directives, and pipes in this module */
    ClaimsReimbursementTypesComponent, 
    ClaimsUserRequestComponent, 
    ClaimsEmployeeWiseDetailedComponent,
    ClaimsApprovalsComponent,
    ClaimsDetailedReportComponent
  ],
  imports: [ 
    ClaimsRoutingModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FlexLayoutModule,
    GoogleMapsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    SharedModule
    ],
  exports: [/* list of components, directives, and pipes to be used outside this module */],
  providers: [/* list of services available within this module */],
})

export class ClaimsModule { }
