<div fxLayout="Column" class="dashboard-content">
  <mat-card class="sidePad">
    <form [formGroup]="dashBoardForm" fxLayout="Column">
      <!-- f1st row -->
      <div fxLayout="row"  fxLayout.xs="column" fxLayoutGap="10px" ngStyle.xs="padding-top: 5%;">
         <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 50vh;">
          <span class="title" fxLayoutAlign="start">
                <span>Employee Count by Location</span>
              </span>
              <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="allLocationsTotalCount.length == 0 || allLocationsTotalCount == null">
                <span>No data found</span>
                </div>

            <div fxLayoutAlign="center" *ngIf="allLocationsTotalCount.length > 0 || allLocationsTotalCount.isNotEmpty" style="padding-top: 55px;" >
              <chart #chart style="display: block;
              width: 245px;
              height: 220px;"
              [type]="locPieChartType"
              [data]="locPieChartdata"
              [options]="locPieChartoptions"
              ></chart>
         </div>

          </mat-card>

          <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 50vh;">
           <span class="title" fxLayoutAlign="start">
                <span>Employees by Department by Location</span>
              </span>
              <div fxLayout="column">
                <span fxLayoutAlign="end">
                  <mat-form-field class="mx-2 my-2">
                    <mat-select formControlName="location">
                        <ng-container>
                            <div *ngFor="let b of workLocationList">
                                <mat-option [value]="b.id">{{ b.location }}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                </span>
                <div fxLayoutAlign="center" *ngIf="locationByDepartmentCountLis.length > 0">
                  <chart #chart style="display: block;
                  width: 245px;
                  height: 220px;"
                  [type]="locByDeptEmpsdoughChartType"
                  [data]="locByDeptEmpsdoughnutChartData"
                  [options]="locByDeptEmpsdoughnutChartOptions"
                  ></chart>
            </div>
              </div>

          <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="locationByDepartmentCountLis.length == 0 || locationByDepartmentCountLis ==null">
          <span>No data found</span>
          </div>
          </mat-card>

          <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 50vh;">
            <span class="title" fxLayoutAlign="start">
                 <span>Employee Count</span>
               </span>
               <div fxLayout="row" fxLayoutAlign="center" style="padding-left: 10px;padding-top: 20px;">
                <span class="textStyle">Total Active Employees :</span>&nbsp;
                <span class="textStyle2">{{totalActiveEmployeeCount}}</span>
              </div>
          <hr>
               <span fxLayoutAlign="end">
                 <mat-form-field>
                  <input matInput readonly [matDatepicker]="employeeStatusMonthDate" [min]="minDate" [max]="maxDate"
                    placeholder="" (click)="employeeStatusMonthDate.open()" formControlName="employeeStatusMonthDate" />
                  <mat-datepicker-toggle class="datepicker" matSuffix [for]="employeeStatusMonthDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #employeeStatusMonthDate></mat-datepicker>
                </mat-form-field>
               </span>
              <div fxLayout="column" fxLayoutAlign="center center">
                <span fxLayoutAlign="center">
                   <mat-icon>person_add</mat-icon>
                 </span>
                 <div fxLayout="row"><span class="textStyle">New Employees :</span>&nbsp;<span class="textStyle2">{{newEmployeeStatusCount}}</span></div>
                 <br>
                 <span fxLayoutAlign="center">
                   <mat-icon>exit_to_app</mat-icon>
                 </span>
                 <div fxLayout="row"><span class="textStyle">Exit Employees :</span>&nbsp;<span class="textStyle2">{{exitEmployeeStatusCount}}</span></div>
                 <br>
                 <!-- <span fxLayoutAlign="center">
                   <mat-icon>group</mat-icon>
                 </span> -->

                </div>

           </mat-card>

      </div>

      <!-- 2nd row -->

      <div fxLayout="row" fxFlex="100" fxLayout.xs="column" fxLayoutGap="10px">

          <mat-card class="card outline" fxFlex="100" ngStyle.gt-md="height: 50vh;">
             <span class="title" fxLayoutAlign="start">
                <span>Attendance by Work Type</span>
              </span>
              <span fxLayoutAlign="end">
                <mat-form-field>
                  <input matInput readonly [matDatepicker]="attendanceWiseDate" [min]="minDate" [max]="maxDate"
                    placeholder="" (click)="attendanceWiseDate.open()" formControlName="attendanceWiseDate" />
                  <mat-datepicker-toggle class="datepicker" matSuffix [for]="attendanceWiseDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #attendanceWiseDate></mat-datepicker>
                  <!-- <mat-datepicker #attendanceWiseDate
                  startView="multi-year"
                         (monthSelected)="attendanceMonthAndYear($event, attendanceWiseDate)"></mat-datepicker> -->
                </mat-form-field>
              </span>
              <div fxLayoutAlign="center">
                <chart #chart style="display: block;
                width: 260px;
                height: 220px;"
                [type]="attDonutChartType"
                [data]="attDonutChartdata"
                [options]="attDonutChartoptions"
                ></chart>
            </div>
          </mat-card>

        <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 50vh;">
          <span class="title" fxLayoutAlign="start">
               <span>Employees on Leave by Department</span>
             </span>
             <div fxLayout="column">
             <span fxLayoutAlign="end">
               <mat-form-field>
                 <input matInput readonly [matDatepicker]="deptWiseleavesDate" [min]="minDate" [max]="maxDate"
                   placeholder="" (click)="deptWiseleavesDate.open()" formControlName="deptWiseleavesDate" />
                 <mat-datepicker-toggle class="datepicker" matSuffix [for]="deptWiseleavesDate">
                 </mat-datepicker-toggle>
                 <mat-datepicker #deptWiseleavesDate></mat-datepicker>
               </mat-form-field>
             </span>
             <div fxLayoutAlign="center" *ngIf="departmentWiseLeaveCountList.length > 0">
              <chart #chart style="display: block;
              width: 245px;
              height: 220px;"
              [type]="deptWiseLeavepieChartType"
              [data]="deptWiseLeavepieChartData"
              [options]="deptWiseLeavepieoptions"
              >
            </chart>
           </div>
          </div>
           <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="departmentWiseLeaveCountList.length == 0 || departmentWiseLeaveCountList ==null">
            <span>No data found</span>
            </div>
         </mat-card>

         <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 50vh;">
          <span class="title" fxLayoutAlign="start">
               <span>Leaves</span>
             </span>
             <span fxLayoutAlign="end">
               <mat-form-field>
                 <input matInput readonly [matDatepicker]="leaveStatusDate" [min]="minDate" [max]="maxDate"
                   placeholder="" (click)="leaveStatusDate.open()" formControlName="leaveStatusDate" />
                 <mat-datepicker-toggle class="datepicker" matSuffix [for]="leaveStatusDate">
                 </mat-datepicker-toggle>
                 <mat-datepicker #leaveStatusDate></mat-datepicker>
                </mat-form-field>
             </span>

             <div fxLayout="column" fxLayoutAlign="center center">
             
               <div fxLayout="row"><span class="textStyle">On Leave :</span>&nbsp;<span class="textStyle2">{{totalLeaveCount}}</span></div>
               <br>
            
               <!-- <div fxLayout="row"><span class="textStyle">Pending Leaves :</span>&nbsp;<span class="textStyle2">{{pendingLeaveCount}}</span></div>
               <br>

               <div fxLayout="row"><span class="textStyle">Rejected Leaves :</span>&nbsp;<span class="textStyle2">{{rejectedLeaveCount}}</span></div>
               <br> -->


            </div>

            <!-- <div fxLayout="column" fxFlex  fxLayoutAlign="center" style="padding-left:10%">
                    <div fxLayout="row">
                       <span class="textStyle2" style="padding-top:20px"> On leave : {{totalLeaveCount}}</span>
                        </div>
                        <div style="padding-bottom: 10px;">
                          <div fxLayout="row"><span class="textStyle" style="padding-top: 10px;">Count by Month </span></div>

                        <div fxLayout="row"><span class="textStyle" style="padding-top: 10px;">Pending Leaves :</span>&nbsp;<span class="textStyle2" style="padding-top: 10px;">{{pendingLeaveCount}}</span></div>
                         <br>
                       <div fxLayout="row"><span class="textStyle">Rejected Leaves :</span>&nbsp;
                     <span class="textStyle2">{{rejectedLeaveCount}}</span></div>
                    </div>
           </div> -->



         </mat-card>
      </div>

      <!-- 3rd row -->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-card class="card outline" fxFlex="100" fxLayout="column" ngStyle.gt-md="height: 100%;">
          <span class="title" fxLayoutAlign="start">
             <span>Shift Based Headcount by Department</span>
           </span>
          <div fxLayout="column">
            <span fxLayoutAlign="end">
           <mat-form-field class="mx-2 my-2">
            <mat-select formControlName="shiftName">
                <ng-container *ngFor="let v of shiftDataList">
                    <mat-option [value]="v.shiftid">{{v.shiftname}}</mat-option>
                  
                </ng-container>
                <mat-option [value]="0">Unassigned Shift</mat-option>
            </mat-select>
       </mat-form-field>
       </span>

       <div *ngIf="shiftByDepartmentCountList.length > 0">
        <chart #chart
        [type]="vbarChartType"
        [data]="vbarChartData"
        [options]="vbaroptions"
        >
       </chart>
       </div>
       </div>
       <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="shiftByDepartmentCountList.length == 0 || shiftByDepartmentCountList ==null">
        <span>No data found</span>
        </div>

       </mat-card>

        <mat-card class="card outline" fxFlex="100" ngStyle.gt-lg="height:100%">
          <div fxLayout="row" fxLayoutAlign="start">
            <span class="title">
              <span>Payroll Amount by Department by Month</span>
            </span>
          </div>
          <chart #chart
          [type]="deptWisePayrollBarChartType"
          [data]="deptWisePayrollBarChartData"
          [options]="deptWisePayrollBarChartOptions"

          ></chart>
        </mat-card>
      </div>

    </form>
  </mat-card>
</div>
