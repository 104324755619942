import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import * as _moment from 'moment';
import moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import * as XLSX from 'xlsx';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-employee-payment-report',
  templateUrl: './employee-payment-report.component.html',
  styleUrls: ['./employee-payment-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EmployeePaymentReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: ElementRef;

  employeePaygroupForm: any = UntypedFormGroup;
  viewdata: any;
  date = new UntypedFormControl(moment());
  displayedColumns: string[] = [
    'sno',
    'location',
    'empid',
    'empname',
    'totalgsalary',
    'days',
    'totalsalary',
    'regularization',
  ];
  dataSource: MatTableDataSource<any> = <any>[];
  pageLoading = true;
  minDate: any;
  pipe = new DatePipe('en-US');
  userSession: any;
  employeePayrollData: any;
  managersDetails: any[] = [];
  employeeListByManager: any[] = [];
  year: any;
  max = new Date();
  maxDate: any = new Date();
  worklocationDetails: any[] = [];
  companyName: any;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;

  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;

  selectedLocations: any = [];
  selectedManagers: any = [];
  selectedEmployeeList: any = [];
  isNorecords = false;

  constructor(
    private LM: LeavesService,
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    private companyService: CompanySettingService
  ) {}

  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName')
      ? sessionStorage.getItem('companyName')
      : null;
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');

    this.employeePaygroupForm = this.formBuilder.group({
      companylocation: ['', Validators.required],
      managers: ['', Validators.required],
      fromDate: [new Date()],
    });
    this.getWorkLocation();
    this.getManagersList();
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.viewdata = ctrlValue._d;
    this.employeePaygroupForm.controls.fromDate.setValue(ctrlValue);
  }

  getWorkLocation() {
    this.companyService
      .getactiveWorkLocationForGeneralManager({
        id: this.userSession.id,
        companyName: this.companyName,
      })
      .subscribe((result) => {
        this.worklocationDetails = result.data;
      });
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.employeePaygroupForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName,
    };
    this.reportsService
      .getManagersListByLocation(data)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.managersDetails = res.data;
        }
      });
  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    this.employeeListByManager = [];

    let location = this.employeePaygroupForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeeList();
  }

  getEmployeeList() {
    let data = {
      rm_id: JSON.stringify(this.selectedManagers),
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName,
    };
    this.reportsService
      .getLocationWiseEmployeesForManagers(data)
      .subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
          this.employeeListByManager = res.data;
          this.selectedEmployeeList = [];
          for (let i = 0; i < res.data.length; i++) {
            this.selectedEmployeeList.push(Number(res.data[i].empid));
          }
        }
      });
  }
  getEmployeePayrollData() {
    let syear = new Date(
      this.employeePaygroupForm.controls.fromDate.value
    ).getFullYear();
    let smonth =
      new Date(this.employeePaygroupForm.controls.fromDate.value).getMonth() +
      1;
    let info = {
      empids_json: JSON.stringify(this.selectedEmployeeList),
      year_value: syear,
      month_value: smonth,
      companyName: this.companyName,
    };

    this.reportsService.getGrossSalaryDetails(info).subscribe((result: any) => {
      this.employeePayrollData = result.data;
      this.dataSource = this.employeePayrollData;
      this.employeePayrollData[0].empid != null
        ? (this.dataSource = this.employeePayrollData)
        : (this.dataSource = new MatTableDataSource());
      // this.employeePayrollData.forEach((e: any) => {
      //   if(e.empid==null && e.empcode ==null && e.total_gross_salary){
      //       this.isNorecords =true;
      //   }
      // });
    });
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.employeePaygroupForm.controls.companylocation.setValue(array);
    let locations = this.employeePaygroupForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false;
    this.selectedLocations = [];
    select.value = [];
    this.employeePaygroupForm.controls.companylocation.setValue('');
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.employeePaygroupForm.controls.managers.setValue(array);
    let managerdata = this.employeePaygroupForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.getEmployeeList();
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false;
    this.selectedManagers = [];
    select.value = [];
    this.employeePaygroupForm.controls.managers.setValue('');
    this.selectedManagers = [];
  }

  Searchform() {
    if (this.employeePaygroupForm.valid) {
      this.getEmployeePayrollData();
    }
  }

  resetform() {
    this.locationIshide = false;
    this.locationIschecked = false;
    this.mgrIshide = false;
    this.mgrIschecked = false;

    this.employeePaygroupForm.controls.companylocation.setValue('');
    this.employeePaygroupForm.controls.managers.setValue('');
    this.employeePaygroupForm.controls.fromDate.setValue(new Date());
  }

  public exportPDF(): void {
    this.year =
      this.viewdata != undefined
        ? this.viewdata.getFullYear()
        : new Date().getFullYear();
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: 'Payroll Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text:
              'Payroll Report for from ' +
              this.pipe.transform(
                this.employeePaygroupForm.controls.fromDate.value,
                'longDate'
              ) +
              ' to ' +
              this.pipe.transform(
                this.employeePaygroupForm.controls.toDate.value,
                'longDate'
              ) +
              '\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          html,
        ],
        pageOrientation: 'landscape', //'portrait'
        pageSize: 'A4',
      })
      .download(
        'Payroll Report from ' +
          this.pipe.transform(
            this.employeePaygroupForm.controls.fromDate.value,
            'longDate'
          ) +
          ' to ' +
          this.pipe.transform(
            this.employeePaygroupForm.controls.toDate.value,
            'longDate'
          ) +
          '.pdf'
      );
  }
  exportAsXLSX() {
    this.year =
      this.viewdata != undefined
        ? this.viewdata.getFullYear()
        : new Date().getFullYear();
    var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payroll_report');
    XLSX.writeFile(
      wb,
      'Payroll_report_for_financeteam_ from ' +
        this.pipe.transform(
          this.employeePaygroupForm.controls.fromDate.value,
          'longDate'
        ) +
        ' to ' +
        this.pipe.transform(
          this.employeePaygroupForm.controls.toDate.value,
          'longDate'
        ) +
        '.xlsx'
    );
  }
}
