<div fxLayout="column" fxFlex fxLayoutAlign="start stretch" >
  <!-- <div class="place-autocomplete-card" id="place-autocomplete-card"> -->
    <mat-form-field>
      <input type="text" matInput id="autocomplete" placeholder="Enter your address" #addressInput />
      <!-- <input  type="text"  matInput  placeholder="Choose an option"  [formControl]="myControl" [matAutocomplete]="auto"/> -->
      <!-- <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of displayOptions" [value]="option"  (onSelectionChange)="selectValue(option)">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete> -->
    </mat-form-field>
  <!-- </div> -->
    
    <!-- <div *ngIf="selectedValue">Selected Value: {{ selectedValue }}</div> -->
    <div   [hidden]="!mapInitialized">
      <div id="map" style="height:500px ; width: 560px; position: relative; overflow: hidden;">
        
      </div>  
        <!-- <google-map [center]="center" [height]="500" [width]="560"  [options]="mapOptions" >
            <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions"  [position]="markerPosition"
            (mapClick)="openInfo(marker,markerPosition.description)">
            <map-info-window>{{infoContent}}</map-info-window>
            </map-marker>
        </google-map> -->
      </div>  
    
    
   <!-- <script>(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
        ({key: "AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4", v: "alpha"});</script> -->
  
</div>