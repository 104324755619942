

<div fxLayout="column" class="first-col">

    <mat-card class="mat-elevation-z0">
      <mat-card-header class="headerbox">
         <mat-card-title class="title"> Leave Balance Report </mat-card-title>
       </mat-card-header>

       <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end" style="padding-top: 1%;">
        <!-- <div fxFlex="50" fxLayoutAlign="start" style="height: 70px;">
            <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100"  class="mx-2 my-2" appearance="outline">
              <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
              <mat-label>Search</mat-label>
              <input (keyup)="applyFilter($event)" [tabIndex]="1" matInput placeholder="" maxlength="100">
            </mat-form-field>
          </div> -->

          <div style="padding-right: 2%;" fxLayout="row" fxLayoutAlign="end" >
             <span> | </span>&nbsp;
              <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px"></a>&nbsp;
      
              <span> | </span>&nbsp;
              <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">&nbsp;
              </a>
           </div >
       
      </div>
    
  
  <!-- test -->
  <div class="tableP">
   
        <div class="example-container" style="padding: 10px" #table>
            <table style="width: 100%" *ngIf="dataMessageResults.length > 0"  id="table">
                <thead class="theader">
                     <tr>          
                       <th *ngFor="let header of getHeaders()">{{header}}</th>
                     </tr>
                </thead>
                <tbody>
                     <tr *ngFor="let item of dataMessageResults">          
                       <td *ngFor="let key of getHeaders()">{{item[key]}}</td>
                     </tr>
                </tbody>
           </table>
            <!-- <table style="width: 100%" *ngIf="leavesList.length > 0">
                <thead>
                    <tr>
                        <th><span style="font-size: 16px">S.No </span></th>
                        <th><span style="font-size: 16px">Employee </span></th>
                        <ng-container *ngFor="let a of headersList[0]">
                            <th>
                               {{a}}
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of leavesList">
                        <tr style="width: auto">
                            <ng-container *ngFor="let e of item">
                                <td>
                                    <span>
                                        {{ e }}
                                    </span>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table> -->

            <div style="text-align: center; padding-top: 20px" *ngIf="dataMessageResults.length === 0">
                No data found.
            </div>
        </div>

  </div>
   
  </mat-card>
  </div>
  