<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" style="padding: 1%;" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Current Day Punch Details ({{ date | date: 'dd-MM-yyyy' }})</mat-card-title>
        </mat-card-header>
        <form [formGroup]="searchForm">
            <div fxLayout="column" class="table-pad">
                <div fxLayout="row" style="margin-top: 1%;" fxLayoutGap="30px" fxLayoutAlign="space-between center"
                    fxFlex="100%">
                    <div fxLayout="row" fxFlex="50" fxLayout.xs="column">

                        <!-- <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>City-Branch</mat-label>
                            <mat-select placeholder="" name="companylocation" formControlName="location" required
                                multiple #locationSelect>
                                <button mat-raised-button class="mat-primary fill text-sm"
                                    (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.location)"
                                    *ngIf="!locationIshide">Select All
                                </button>
                                <button mat-raised-button class="mat-accent fill text-sm"
                                    (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                                    Deselect All
                                </button>
                                <input matInput [formControl]="searchControl" type="text" placeholder="  Search"
                                    class="search-align">
                                <mat-option *ngFor="let res of filteredLocations" [value]="res"
                                    (click)="onchangeLocation(res)">
                                    {{res.cityname}}-{{res.location}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="searchForm.controls.location.errors?.required">
                                Please select option
                            </mat-error>
                        </mat-form-field> -->
                        <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>Location</mat-label>
                            <mat-select formControlName="location" required>
                                <input class="search-align" type="text" placeholder="Search"
                                    [formControl]="searchControl" matInput>
                                <mat-option *ngFor="let list of filteredLocations"
                                    [value]="list.id">{{list.location}}&nbsp;-&nbsp;{{list.cityname}}</mat-option>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="searchForm.controls.location.errors?.required">
                                This Field is required.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                            <mat-label>Employee Name</mat-label>
                            <mat-select formControlName="employee" tabindex="1">
                                <input matInput [formControl]="searchControlforEmp" type="text" placeholder="  Search"
                                    class="search-align">
                                <mat-option value="0"> All Employees </mat-option>
                                <ng-container *ngFor="let e of filteredEmployeelist">
                                    <mat-option [value]="e.id">{{ e.ename }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="searchForm.controls.employee.errors?.required">
                                Please select option
                            </mat-error>
                        </mat-form-field>

                        <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center">
                            <span ngStyle.gt-md="margin-top: 5%">
                                <button class="btn btn-primary mr-2 mb-2" (click)="getDetails()" tabindex="3">
                                    Search
                                </button>
                            </span>
                            <span ngStyle.gt-md="margin-top: 5%" class="line" style="color: #1898d5; font-size: 16px">
                                <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                                    <span>Clear</span>
                                </button>
                            </span>

                        </div>
                    </div>

                </div>

            </div>
            <div fxLayoutAlign="end">
                <mat-form-field appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="search" #input>
                </mat-form-field>
            </div>
            <div fxLayout="column" class="table-pad">
                <div class="mat-elevation-z1 example-container">

                    <table mat-table [dataSource]="dataSource" matSort>
                        <!-- S.No Column -->
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ getSerialNumber(i) }}
                            </td>
                        </ng-container>

                        <!-- Employee Name Column -->
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                            <td mat-cell *matCellDef="let row;">{{ row.empname }}</td>
                        </ng-container>

                        <!-- Employee ID Column -->
                        <ng-container matColumnDef="empcode">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. ID</th>
                            <td mat-cell *matCellDef="let row;">{{ row.empcode }}</td>
                        </ng-container>

                        <!-- Punch Time Column -->
                        <ng-container matColumnDef="punchtimes">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Punch Time</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <ng-container>{{ row.punch_time
                                    }}<br></ng-container>
                            </td>
                        </ng-container>

                        <!-- Category Column -->
                        <ng-container matColumnDef="categories">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Category</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <ng-container>{{ row.category
                                    }}<br></ng-container>
                            </td>
                        </ng-container>

                        <!-- Attendance Source Column -->
                        <ng-container matColumnDef="attendanceSource">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Attendance Source
                            </th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <ng-container>{{ row.attendancesource
                                    }}<br></ng-container>
                            </td>
                        </ng-container>

                        <!-- Location Column -->
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Location</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                <ng-container>{{ row.location
                                    }}<br></ng-container>
                            </td>
                        </ng-container>


                        <!-- Details Button Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button (click)="openDialog(row)">
                                    <a style="color: blue">
                                        <u>Details</u>
                                    </a>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                </div>
            </div>
        </form>
    </mat-card>
</div>