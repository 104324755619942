import { Component, OnInit,Inject,LOCALE_ID, ElementRef, ViewChild, COMPILER_OPTIONS } from '@angular/core';
import { DatePipe } from '@angular/common';
import {CrmService} from "../../crm.service";
import {MatIconModule} from '@angular/material/icon';
import {CrmAddClientComponent } from "../crm-add-client/crm-add-client.component";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { ActivatedRoute } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { FormControl  } from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DataTransferService } from  "../../data-transfer.service";
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-crm-client-list',
  templateUrl: './crm-client-list.component.html',
  styleUrls: ['./crm-client-list.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmClientListComponent implements OnInit {
  clientFilteredList: any = [];
  isNewclient:any = false;
  currentDate = new DatePipe('');
  formattedDate:any;
  formattedFromDate:any;
  formattedToDate:any;
  formattedTime:any;
  rows:any=[];
  cols:any = [];
  encriptPipe= new EncryptPipe();
  userSession: any;
  columnwidth:any;
  sortedItems:any;
  userRole:any;
  totalClients:any=0;
  clientList:any =[];
  listTypeAddClient:any = [];
  isWebRestricted:any = 1;
  selectedEmployee:any=0;
  selectedEmployeeControl:any = new FormControl(0); 
  selectedDashboardControl:any = new FormControl(0); 
  selectedDateControl:any = new FormControl(0); 
  fromDateControl:any = new FormControl(0); 
  toDateControl:any = new FormControl(0); 
  attendanceData:any;
  selectedDate:any = new Date();
  fromDate:any = new Date();
  toDate:any = new Date();
  selectedAllControl = new FormControl(0); 
  selectedAll:any =false;
  maxDate:any = new Date();
  minDate:any = new Date();
  pipe = new DatePipe('en-US');
  dashboard_id:any ;
  dashboards:any =[];
  dashboard_selected :any;
  activeChild:any;
  constructor(private crm:CrmService,private datePipe:DatePipe,private route: ActivatedRoute,@Inject(LOCALE_ID) private locale: string,
              public  dialog: MatDialog,private router: Router,private dataTransfer:DataTransferService,private spinner: NgxSpinnerService) { 
                this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
                if (this.userSession.isCrmWebDisabled !== undefined) {
                  this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
                }
            }

  ngOnInit(): void {
    this.getCrmEmployeesForManagers();
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.activeChild = JSON.parse(sessionStorage.getItem('activeChild') || '');
    if (this.userSession.isCrmWebDisabled !== undefined) {
      this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
    }
    this.totalClients = 0;
    this.route.url.subscribe(segments => {
      this.userRole = segments[0]?.path;  
    });
   
    this.formattedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.formattedFromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.formattedToDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.formattedTime = (new Date()).toLocaleTimeString(undefined, {hour12: false, hour: '2-digit',minute: '2-digit', second: '2-digit' });
    this.selectedDateControl.setValue(this.selectedDate );
    this.fromDateControl.setValue(this.fromDate );
    this.toDateControl.setValue(this.toDate );
    this.selectedAllControl.setValue(this.selectedAll );
    //this.getClientListTypes(null,null);
    this.getDashboards();
    this.selectedEmployeeControl.valueChanges.subscribe((newValue: any) => {
      this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
    });
    this.selectedDashboardControl.valueChanges.subscribe((newValue: any) => {
      this.dashboard_id = newValue;
      this.dashboards.forEach((d:any)=>{
        if(d.id == newValue){
          this.dashboard_selected = d;
          if(this.dashboard_selected && this.dashboard_selected.back_color_json && typeof this.dashboard_selected.back_color_json == 'string')
            this.dashboard_selected.back_color_json = JSON.parse(this.dashboard_selected.back_color_json );
        }
      });
      this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
    });
    this.selectedDateControl.valueChanges.subscribe((newValue: any) => {      
      this.formattedDate = this.pipe.transform(new Date(newValue), 'yyyy-MM-dd');
      this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
    });
    this.fromDateControl.valueChanges.subscribe((newValue: any) => {      
      this.formattedFromDate = this.pipe.transform(new Date(newValue), 'yyyy-MM-dd');
      this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
    });
    this.toDateControl.valueChanges.subscribe((newValue: any) => {      
      this.formattedToDate = this.pipe.transform(new Date(newValue), 'yyyy-MM-dd');
      this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
    });
  }
  
  getCrmEmployeesForManagers(){
    this.attendanceData = [];
    this.crm.getCrmEmployeesForManagers({rm_id:this.userSession.id,selected_date:this.formattedDate}).subscribe((results:any)=>{
       
      this.attendanceData= (results && results.data)? results.data:[];
      // if(this.attendanceData && this.attendanceData.length){
      //   this.attendanceData.forEach((a:any)=>{
      //     this.totalEmployees.push(a);
      //     if(this.selectedEmployee === a.empid){
      //       this.employeeData = {
      //         empname:a.empname,
      //         contactnumber:a.contactnumber
      //       } ;
      //     }
      //     if(a.punchouttime)
      //       this.punchOutEmployees.push(a); //= this.punchOutEmployees + 1;
      //     else if(a.punchintime)
      //       this.punchInEmployees.push(a); // = this.punchInEmployees + 1;
      //     else if(!a.punchintime && !a.punchouttime) 
      //       this.absentEmployees.push(a); // = this.absentEmployees + 1;
      //   });
      // }
      
    })
  }

  async selectAddClient() {
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied.' });
      return;
    }
    const dialogRef = this.dialog.open(CrmAddClientComponent, { width: '1050px',height:'425px',disableClose: true });
    await dialogRef.afterClosed().subscribe((result: any) => {
      if(this.listTypeAddClient && this.listTypeAddClient.id)
      this.getClientFilteredListLatest({manager_id:(this.userRole === 'Mgr'? this.userSession.id:null),empid:(this.userRole === 'Emp'? this.userSession.id:null),typeid:this.listTypeAddClient.id,selected_date:null,type_name:this.listTypeAddClient.type_name});
     });
  }

  select_emp(){
      if(this.userRole === 'Mgr' ) 
        return true;
      else return false; 
  }

  changeSelectAll(){
    this.selectedAll = !this.selectedAll;
    this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientFilteredList =  _.cloneDeep(this.clientList);
    this.clientFilteredList.filter((m:any)=>{
      var filterdata = m.data.filter((client:any)=>
        client.organization_name.toString().toLowerCase().includes(filterValue.trim().toLowerCase()));   
      m.data = filterdata;
    });
 }

  getClientFilteredList(params:any){
    this.crm.getCrmClientFilteredList(params).subscribe((res: any) => {
      if (res.status) {
        this.clientFilteredList.push({data:res.data,type_id:params.typeid,type_name:params.type_name,count:res.data.length});
      } else {
        //this.clientFilteredList =[];
      }
    })
  }
  getClientFilteredListLatest(params:any){
    this.clientFilteredList = [];
    this.clientList = [];
    this.spinner.show();
    this.crm.getCrmClientsDashboardSelected(params).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.totalClients = 0;
        this.clientFilteredList = res.data;   
        this.clientList = _.cloneDeep(this.clientFilteredList);    
        if(this.clientFilteredList.length){         
          this.clientFilteredList.forEach((e:any)=>{
            this.totalClients = this.totalClients + ((e && e.data && e.data.length) ? e.data.length : 0 );
          });
        }         
        
      } else {
        if(!this.clientFilteredList.length){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`}, disableClose: true,data:res.message?res.message:'No Records Found'
          });
        }
      }
    })
  }
  getClientFilteredListLatest_old(params:any){
    if(!(params && params.dashboard_id)){
      params.dashboard_id = this.dashboard_id;
    } 
    this.crm.getCrmClientsDashboardSelected(params).subscribe((res: any) => {
      if (res.status) {
        
        let arrayNotFound = true;
        if(this.clientFilteredList.length){
          this.totalClients = 0;
          this.clientFilteredList.forEach((e:any)=>{
            if(e.type_id === params.typeid){
              e.data = res.data;
              e.count = res.data.length;
              this.totalClients = this.totalClients + ((e && e.data && e.data.length) ? e.data.length : 0 );
              arrayNotFound = false;
            }
            else{
              this.totalClients = this.totalClients + ((e && e.data && e.data.length) ? e.data.length : 0 );
            }

          });
        }
        if(arrayNotFound){
        this.clientFilteredList.push({data:res.data,type_id:params.typeid,type_name:params.type_name,count:res.data.length});
        this.totalClients = this.totalClients + ((res && res.data && res.data.length) ? res.data.length : 0 );
        }
        this.sortById();
      } else {
        if(!this.clientFilteredList.length){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`}, disableClose: true,data:res.message?res.message:'No Records Found'
          });
        }
        
       // this.clientFilteredList = [];
      }
    })
  }

  sortById() {
    this.clientFilteredList.sort((a:any, b:any) => a.display_order - b.display_order);
    this.clientList = _.cloneDeep(this.clientFilteredList);
  }
 
  getClientListTypes(selectedEmployee:any,from_date:any,to_date:any){
    this.clientFilteredList = [];
    this.columnwidth = 100/Number(this.cols.length);
    let params = {
      manager_id :(this.userRole === 'Mgr'? this.userSession.id:null),
      empid:((this.userRole === 'Emp')? this.userSession.id: (this.selectedAll ? null:selectedEmployee)),
      typeid:null,
      //selected_date:this.selectedAll? null:selected_date,
      from_date:this.selectedAll? null:from_date,
      to_date:this.selectedAll? null:to_date,
      dashboard_id: this.dashboard_id
    }
    this.getClientFilteredListLatest(params);
  }
  getClientListTypes_old(selectedEmployee:any,from_date:any,to_date:any){
    this.cols = [];
    this.crm.getCrmClientDashboardTypes(this.userSession.id,this.userRole,this.dashboard_id).subscribe((res: any) => {
      if (res.status) {
        this.cols = res.data;
         
        this.clientFilteredList = [];
        this.columnwidth = 100/Number(this.cols.length);
        this.cols.forEach((e:any) => {
          if(e.status_val === 0){
            this.listTypeAddClient = e;
          }
          let params = {
            manager_id :(this.userRole === 'Mgr'? this.userSession.id:null),
            empid:((this.userRole === 'Emp')? this.userSession.id: (this.selectedAll ? null:selectedEmployee)),
            typeid:e.id,
            //selected_date:this.selectedAll? null:selected_date,
            from_date:this.selectedAll? null:from_date,
            to_date:this.selectedAll? null:to_date,
            type_name:e.type_name
          }
            this.getClientFilteredListLatest(params);
          });
      } else {
        this.cols =[];
      }
    })
  }

  getDashboards(){
    this.cols = [];
    this.crm.getCrmClientDashboardNames(this.userSession.id,this.userRole).subscribe((res: any) => {
      if (res.status) {
        this.dashboards = res.data;
        if(res.data[0] && res.data[0].id){
          this.dashboard_id = res.data[0].id;
          this.dashboard_selected = res.data[0];
          if(this.dashboard_selected && this.dashboard_selected.back_color_json && typeof this.dashboard_selected.back_color_json == 'string' )
            this.dashboard_selected.back_color_json = JSON.parse(this.dashboard_selected.back_color_json );
          this.getClientListTypes(this.selectedEmployee,this.formattedFromDate,this.formattedToDate);
        }
       
      } else {
        this.dashboards =[];
      }
    })
  }

  checkClientDetails(client:any){
    if(client && client.display){
      client.display = false;
    }
    else  client.display = true;
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.clientFilteredList.filter = filterValue.trim().toLowerCase();
  //   // if (this.clientFilteredList.paginator) {
  //   //   this.clientFilteredList.paginator.firstPage();
  //   // }
  // }

  selectClient(event:any,client:any){
    // this.dataTransfer.setData({ clientId:client.client_id });
    let clientId=this.encriptPipe.transform(client.client_id.toString());
    this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}])
   //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/Crm/CrmClientEditAndUpdate"]));
  }

  getActivityTImeLapseColor(client:any){
    let color = 'black';
    let currentDate = new Date();
    if(client.nextFollowUpDate){
        return (new Date(client.nextFollowUpDate) > currentDate) ?  '#5DADEC':'#800020' ;
    }
    return color;
  }
  getActivityTImeLapseIcon(client:any){
    let icon = 'timelapse';
    let currentDate = new Date();
    if(client.nextFollowUpDate){
        return (new Date(client.nextFollowUpDate) > currentDate) ?  'schedule':'history' ;
    }
    return icon;
  }
  getbackgroundColor(client:any) {
      if(client.back_color && client.back_color.toString().length){
        return client.back_color;
      }
      if(client.nextFollowUpDate){
        if(this.selectedAll){
          let currentDate = new Date();
          return (new Date(client.nextFollowUpDate) > currentDate) ?  'aliceblue':'mistyrose' ;
        }
        else {
          let selectedDate = new Date(this.formattedDate);
          return (new Date(client.nextFollowUpDate) > selectedDate) ?  'aliceblue':'mistyrose' ;
        }
      }
        
      else if(client.created_on){
        let created_on =this.datePipe.transform( new Date(client.created_on), 'yyyy-MM-dd');
        if(this.selectedAll){
          let currentDateFormat =this.datePipe.transform( new Date(), 'yyyy-MM-dd');
          return  (created_on == currentDateFormat) ?  'aliceblue':'mistyrose' ;
        }
        else 
          return  (created_on == this.formattedDate) ?  'aliceblue':'mistyrose' ;
      }
      return 'mistyrose';
  }

  getFormattedDateTime(date:any = null){
    if(!date) 
    date = new Date();
    else date = new Date(date);
    return this.datePipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
  }
  
  getBackDaysRange(c:any){
      return (c.min.toString()+' - '+((c.max == -1)?'Past':c.max.toString())+' Days');
  }
}
