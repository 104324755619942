import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { PopupComponent, PopupConfig } from '../../../../pages/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { startTransition } from 'preact/compat';
import { environment } from 'src/environments/environment';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { PayrollService } from '../../payroll.service';
export interface UserData {
  deptname: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-other-deduction',
  templateUrl: './other-deduction.component.html',
  styleUrls: ['./other-deduction.component.scss']
})
export class OtherDeductionComponent implements OnInit {
  deductionForm!: UntypedFormGroup;
  deduction: any;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  valid: boolean = false;
  msgEM1:any;
  msgEM84:any;
  msgEM85:any;
  msgEM88:any;
  msgEM89:any;
  msgEM90:any
  msgEM91:any;
  msgEM92:any;
  msgEM151:any;
  msgEM152:any;
  todayDate:any=new Date();
  displayedColumns: string[] = ['sno','deductionname','deductionamount', 'Action'];

  // displayedColumns: string[] = ['sno','deductionname','deductionamount', 'status', 'Action'];
  deductionData: any = [];
  arrayValue: any;
  dataSource: MatTableDataSource<UserData>;
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)  sort!: MatSort;
  companyDBName:any = environment.dbName;
    messagesDataList: any = [];
  constructor(private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog, private LM: CompanySettingService,private ts:LoginService,
    private emsService:EmsService,private PR:PayrollService) {
      this.getOtherDeductions();
      this.dataSource = new MatTableDataSource(this.deductionData);
  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getstatuslist();
    this.getMessages();
    this.deductionForm = this.formBuilder.group(
      {
        deduction: ["",[Validators.required ]],
        deductionAmount:[]

      },
    );
  }
  validatedepartments(data: any) {
    if (this.deductionData.length ==0) {
      this.valid = true;
    }
    else {
      if (this.deductionData.length > 0) {

        for (let i = 0; i < this.deductionData.length; i++) {
          if (data.replace(/\s{1,}/g, '').trim().toLowerCase() === this.deductionData[i].deduction_name.replace(/\s{1,}/g, '').trim().toLowerCase() ) {
            this.valid = false;
            break;
          }
          else {
            this.valid = true;
          }
        }
      }
    }

  }
  validateupdatedepartments(id:any,data: any) {
    if (this.deductionData.length == 0) {
      this.valid = true;
    }
    else {
      if (this.deductionData.length > 0) {
        for (let i = 0; i < this.deductionData.length; i++) {
          if (id !=this.deductionData[i].id && data.replace(/\s{1,}/g, '').trim().toLowerCase() === this.deductionData[i].deptname.replace(/\s{1,}/g, '').trim().toLowerCase()) {
            this.valid = false;
            break;
          }
          else {
            this.valid = true;
          }
        }
      }
    }
 }
 setdeduction() {
    this.validatedepartments(this.deductionForm.controls.deduction.value)
    this.deduction = this.deductionForm.controls.deduction.value;
    var data = {
      deductionName: this.deductionForm.controls.deduction.value,
      deductionAmount:this.deductionForm.controls.deductionAmount.value,
      created_by:this.userSession.id,
      status:'Active',
      created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd')+' '+this.pipe.transform(new Date(), 'HH:mm:ss'),
    }
       if (this.deductionForm.valid) {
      if (this.valid) {
        this.PR.setOtherDeduction(data).subscribe((data) => {
          this.valid = false;
         if (data.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Payroll/Other-Deductions"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Other deduction added Successfully.'
            });


          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable add other deduction,Please try again.'
            });
          }
        })
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "This deduction name already exists."
        });


      }

    }

  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    this.deductionForm.controls.deductionAmount.setValue('');
    this.deductionForm.controls.deduction.setValue('')
  }
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/Other-Deductions"]));
  }
  status(status: any, id: any, deptname: any) {
    let data = {
      deptname: deptname,
      tableName: 'employee_departments',
      columnName: 'departmentid',
      id: id,
      status: status
    }
    // this.LM.updateStatus(data).subscribe((result) => {
    //   if (result.status) {
    //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //       position: { top: `70px` },
    //       disableClose: true,
    //       data: status == 1 ? this.msgEM151:this.msgEM152
    //     });
    //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //     this.router.navigate(["/Admin/Department"]));
    //   } else {
    //     this.ngOnInit();
    //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //       position: { top: `70px` },
    //       disableClose: true,
    //       data: this.msgEM89
    //     });
    //   }
    // })
  }
  edit(w: any, i: any) {
    this.deductionForm.controls.deductionAmount.setValue(i.deduction_amount);
    this.enable = i.id;
    this.isEdit = false;
    this.isSave = true;
    // VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);

  }
  save(event: any, id: any, deductionamount: any, datas: any) {

    if (true) {
      // this.validatedepartments(deptname)
      // this.validateupdatedepartments(id,datas.deduction_name)
      this.enable = null;
      this.isEdit = true;
      this.isSave = false;
        let data={

        id:id,
        deductionName: datas.deduction_name,
        deductionAmount:this.deductionForm.controls.deductionAmount.value,
        created_by:this.userSession.id,
        status:'Active',
        created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd')+' '+this.pipe.transform(new Date(), 'HH:mm:ss'),
        } 
          this.PR.setOtherDeduction(data).subscribe((data) => {
            this.valid = false;
           if (data.status) {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(["/Payroll/Other-Deductions"]));
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Other deduction updated successfully'
              });
  
  
            } else {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Unable update other deduction,Please try again.'
              });
            }
          })
        
        
  
  
        
        
      
      
    }
  }
  canceledit(event: any, id: any) {
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.ngOnInit();

  }
  getOtherDeductions() {
    let data ={
      id:null
    }
    this.PR.getOtherDeductions(data).subscribe((info:any) => {
      if (info.status && info.data.length != 0) {
      this.deductionData = info.data;
      this.dataSource = new MatTableDataSource(this.deductionData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
      }

    })

  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
     
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getstatuslist(){
    this.LM.getstatuslists().subscribe((result:any) => {
      if(result.status){
        this.arrayValue = result.data;
      }

    })
  }
  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    this.emsService.getMessagesListApi(data).subscribe((result: any) => {
      if(result.status) {
        this.messagesDataList = result.data;
        this.messagesDataList.forEach((e: any) => {
          
         if (e.code == "EM1") {
          this.msgEM1 = e.message
         } 
         else if (e.code == "EM84") {
           this.msgEM84 = e.message;
          }
          else if (e.code == "EM85") {
           this.msgEM85 =e.message
         }
         else if (e.code == "EM88") {
           this.msgEM88 =e.message
         } 
         else if (e.code == "EM89") {
          this.msgEM89 =e.message
        } 
        else if (e.code == "EM90") {
          this.msgEM90 =e.message
        }
        else if (e.code == "EM91") {
          this.msgEM91 =e.message
        }

        else if (e.code == "EM92") {
          this.msgEM92 =e.message
        }

        else if (e.code == "EM151") {
          this.msgEM151 =e.message
        }

        else if (e.code == "EM152") {
          this.msgEM152 =e.message
        }

          })
      } else {
        this.messagesDataList = [];
      }
    })
  }


    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
  
    }

}
