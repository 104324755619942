import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { EmsService } from '../../ems.service';
import { MainService } from '../../../../services/main.service';
import { ComfirmationDialogComponent } from '../../../../pages/comfirmation-dialog/comfirmation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { DecryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { MainComponent } from '../../../../pages/main/main.component';
import { AddNewDialogComponent } from '../add-new-dialog/add-new-dialog.component';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EmployeeInfoComponent implements OnInit {
  minEducationDate: any;
  minExperienceDate: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanySettingService,
    private dialog: MatDialog,
    private mainService: MainService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private LM: LeavesService,
    private activatedRoute: ActivatedRoute,
    private emsService: EmsService,
    private MainComponent: MainComponent
  ) {
    this.formData = new FormData();
    this.companyDBName = sessionStorage.getItem('companyName')
      ? sessionStorage.getItem('companyName')
      : null;
  }
  personalInfoForm: any = UntypedFormGroup;
  candidateFamilyForm: any = UntypedFormGroup;
  employeeJobForm: any = UntypedFormGroup;
  promotionsForm: any = UntypedFormGroup;
  employementForm!: UntypedFormGroup;
  experienceForm: any = UntypedFormGroup;
  educationForm: any = UntypedFormGroup;
  documentsForm: any = UntypedFormGroup;
  documentTypeList: any;

  displayedColumns = [
    'position',
    'name',
    'relation',
    'gender',
    'contact',
    'status',
    'action',
  ];
  familyTableColumns = [
    'position',
    'name',
    'relation',
    'gender',
    'contact',
    'status',
    'action',
  ];
  documentTableColumns = ['position', 'category', 'number', 'name', 'action'];
  promotionsTableColumns = ['sno', 'salary', 'fromDate'];
  workTableColumns = [
    'sno',
    'company',
    'desig',
    'fromDate',
    'toDate',
    'action',
  ];
  educationTableColumns = [
    'sno',
    'course',
    'college',
    'fromDate',
    'toDate',
    'action',
  ];
  familyDataSource: MatTableDataSource<any> = <any>[];
  promotionsDataSource: MatTableDataSource<any> = <any>[];
  documentDataSource: MatTableDataSource<any> = <any>[];
  workExperienceDataSource: MatTableDataSource<any> = <any>[];
  educationDataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchControl = new FormControl('');
  filteredEmpTypes: any = [];
  searchControlforRole = new FormControl('');
  filteredRoles: any = [];
  searchControlforloc = new FormControl('');
  filteredLocations: any = [];
  searchControlforDesignation = new FormControl('');
  filteredDesignations: any = [];
  searchControlforRMDep = new FormControl('');
  filteredRMDep: any = [];
  searchControlforRCoun = new FormControl('');
  filteredRCountryList: any = [];
  filteredPCountryList: any = [];
  searchControlforPCoun = new FormControl('');
  searchControlforRState = new FormControl('');
  filteredRStates: any = [];
  filteredPStates: any = [];
  searchControlforPState = new FormControl('');
  searchControlforRCity = new FormControl('');
  filteredRcity: any = [];
  searchControlforPCity = new FormControl('');
  filteredPcity: any = [];

  isedit: boolean = false;
  minDate = new Date('1950/01/01');
  minJoinDate: any;
  bloodGroupdetails: any[] = [];
  genderDetails: any[] = [];
  departmentsForReportingManagers: any = [];
  employeeRelationship: any = [];
  maritalStatusDetails: any[] = [];
  countryDetails: any = [];
  stateDetails: any = [];
  cityDetails: any = [];
  permanentCountryDetails: any = [];
  permanentStateDetails: any = [];
  permanentCityDetails: any = [];
  isfamilyedit: boolean = false;
  isEducationEdit: boolean = false;
  familyDetails: any = [];
  isviewemployee: boolean = false;
  isview: boolean = true;
  availableDesignations: any = [];
  availableDepartments: any = [];
  availableRole: any = [];
  availablereportingmanagers: any[] = [];
  worklocationDetails: any[] = [];
  loginCandidateId: any;
  loginData: any = [];
  employeeInformationData: any = [];
  employeeJobData: any = [];
  employeeEmployementData: any = [];
  employeeEducationData: any = [];
  documentDetails: any = [];
  expFromDate: any;
  expToDate: any;
  maxDate: any;
  minetodate: any;
  availableFinanceManagers: any = [];
  availableHRManagers: any = [];
  edmaxDate = new Date();
  expmaxDate = new Date();
  joiningmaxDate = new Date();
  // documentTypeList: any = ['Aadhar', 'PAN Card', 'Passport ID'];
  isFile: boolean = true;
  formData: any;
  selectedtab = new UntypedFormControl(0);
  userSession: any;
  pipe = new DatePipe('en-US');
  messagesDataList: any = [];
  requiredField: any;
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  workExperienceDetails: any = [];
  educationDetails: any = [];
  designationId: any;
  preOnboardId!: number;
  candidateId: any;
  employeeId: any;
  employeeCode: any;

  editemployee: boolean = false;
  EmploymentTypeDetails: any = [];
  contractStartDate: any;
  contractEndDate: any;
  addFamilyView: boolean = false;
  isself: boolean = false;
  employeeNameh: any;
  employeeDesignation: any;
  employeeJoinDate: any;
  employeeMobile: any;
  statusList: any;
  promotionsGetList: any = [];
  promotionList: any = [];
  params: any;
  editFileName: any;
  editDockinfo: any;
  EM11: any;
  EM12: any;
  EM13: any;
  EM14: any;
  EM15: any;
  EM16: any;
  EM17: any;
  EM18: any;
  EM19: any;
  EM20: any;
  EM21: any;
  EM22: any;
  EM1: any;
  EM2: any;
  EM61: any;
  EM42: any;
  EM43: any;
  EM62: any;
  EM63: any;
  fileURL: any;
  file: any;
  familyindex: any;
  educationIndex: any;
  experienceIndex: any;
  isExperienceEdit: boolean = false;
  isUpdate: boolean = false;
  isDelete: boolean = false;
  mincontarctDate: any;
  isContractData: boolean = false;
  profileId: any = null;
  profileInfo: any = null;
  imageurls = [
    {
      base64String: 'assets/img/profile.jpg',
    },
  ];
  base64String: any;
  name: any;
  imagePath: any;
  isFileImage: boolean = false;
  progressInfos: any = [];
  selectedFiles: any;
  previews: any = [];
  isRemoveImage: boolean = true;
  isPromotionsOnly: boolean = true;
  // companyDBName: any = environment.dbName;
  companyDBName: any;
  issubmit: boolean = false;
  submitsavepersonal: boolean = false;
  isNewEmployee: boolean = true;
  decryptPipe = new DecryptPipe();
  joinDateDisable: boolean = false;
  isSubmitAdd: boolean = false;
  maxBirthDate: Date | undefined;
  editRoles: boolean = false;
  searchTextboxControl = new UntypedFormControl();
  selectedRoles: any = [];
  selectedUserTypeRoles: any = [];
  isEnable = false;
  flag: boolean = true;
  roleArray: any = [];
  isNewire: boolean = false;
  isDirectory: boolean = false;
  isSuperAdmin: boolean = false;
  checkedsame: boolean = false;
  ishrmanager: boolean = false;
  isfinancemanager: boolean = false;
  selectedEmployeeRole: any = [];
  loginEmpId: any;
  existingRmID: any;
  newRmID: any;
  isNewReportingManager: boolean = false;
  currentDate = new Date();
  reportingManagerName: any;
  empImageurls: any = null;
  validpic: boolean = true;
  LM117: any = '';
  empFile: any;
  filteredBloodGroups: any[] = [];
  searchTerm: string = '';
  selectedBloodGroup: string = '';
  searchReportingManager = new FormControl('');
  filteredReportingManagers: any = [];
  searchControlforDep = new FormControl('');
  filteredDep: any = [];
  displayAdd: boolean = true;
  ismappingEnable: boolean = false;

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    if (this.userSession.is_super_admin == '1') {
      this.isSuperAdmin = true;
    } else {
      this.isSuperAdmin = false;
    }
    this.maxBirthDate = new Date();
    this.maxBirthDate.setMonth(this.maxBirthDate.getMonth() - 12 * 18);
    this.getDesignationsMaster();
    this.getDepartmentsForReportingManagers();
    this.getGender();
    this.getMaritalStatusMaster();
    this.getRelationshipMaster();
    this.getRoles();
    this.getFinanceManagersList(false);
    this.getHRManagersList(false);
    this.params = this.activatedRoute.snapshot.params;
    if (this.params.candId != undefined && this.params.empId == undefined) {
      this.editRoles = false;
    } else {
      this.editRoles = true;
    }
    /** through new hired list */
    if (
      this.activeroute.snapshot.params.candId != 0 &&
      this.activeroute.snapshot.params.candId != null
    ) {
      this.isNewire = true;
      this.candidateId = this.decryptPipe.transform(
        this.activeroute.snapshot.params.candId
      );
      this.getLoginCandidateData();
    }
    /** through employee directory */
    if (
      this.activeroute.snapshot.params.empId != 0 &&
      this.activeroute.snapshot.params.empId != null
    ) {
      this.isNewire = false;
      this.employeeId = this.decryptPipe.transform(
        this.activeroute.snapshot.params.empId
      );
      this.getEmployeeInformationList();
      this.getEmployeeJobList();
      this.getEmployeeEmploymentList();
      this.getEmployeeEducationList();
      this.getUploadImage();
    }

    this.getDocumentsEMS();
    this.getFilecategoryMasterForEMS();
    this.createPersonalInfoForm();
    this.createFamilyForm();
    this.createEmployeeJobForm();
    this.createPromotionsForm();
    this.createEmployementForm();
    this.createExperienceForm();
    this.createEducationForm();
    this.createDocumentsForm();
    this.getMessagesList();
    this.getBloodgroups();
    this.getGender();
    this.getMaritalStatusMaster();
    this.getDepartmentsForReportingManagers();
    this.getRelationshipMaster();

    this.getDepartmentsMaster();
    this.getWorkLocation();
    this.getCountry();
    this.getEmploymentTypeMaster();
    this.getstatuslist();
    this.getnoticeperiods();

    /**get state details for residance address */
    this.personalInfoForm
      .get('rcountry')
      ?.valueChanges.subscribe((selectedResidenceStateValue: any) => {
        this.stateDetails = [];
        this.spinner.show();
        if (selectedResidenceStateValue != '') {
          this.companyService
            .getStatesc(selectedResidenceStateValue)
            .subscribe((data) => {
              this.stateDetails = data.data;
              this.permanentStateDetails = data.data;
              this.filteredRStates = this.stateDetails;
              this.filteredPStates = this.permanentStateDetails;
              if (this.employeeCode != null || this.employeeCode != undefined) {
                this.personalInfoForm.controls.rstate.setValue(
                  this.employeeInformationData.state
                );
              } else {
                this.personalInfoForm.controls.rstate.setValue(
                  this.loginData.state
                );
              }
            });
        }
        this.spinner.hide();
      });
    /**get city details for residance address */
    this.personalInfoForm
      .get('rstate')
      ?.valueChanges.subscribe((selectedResidenceCityValue: any) => {
        this.spinner.show();
        this.cityDetails = [];
        this.personalInfoForm.controls.rcity.setValue('');
        if (selectedResidenceCityValue != '') {
          this.companyService
            .getCities(selectedResidenceCityValue)
            .subscribe((data) => {
              this.cityDetails = data.data;
              this.permanentCityDetails = data.data;
              this.filteredPcity = this.cityDetails;
              this.filteredRcity = this.permanentCityDetails;
              if (
                selectedResidenceCityValue ==
                  this.employeeInformationData.state &&
                (this.employeeCode != null || this.employeeCode != undefined)
              ) {
                this.personalInfoForm.controls.rcity.setValue(
                  this.employeeInformationData.city
                );
              } else if (
                selectedResidenceCityValue == this.employeeInformationData.state
              ) {
                this.personalInfoForm.controls.rcity.setValue(
                  this.loginData.city
                );
              }
            });
        }
        this.spinner.hide();
      });

    /**get state details for present address*/
    this.personalInfoForm
      .get('pcountry')
      ?.valueChanges.subscribe((selectedPresentStateValue: any) => {
        this.spinner.show();

        if (selectedPresentStateValue != '' && !this.checkedsame) {
          this.permanentStateDetails = [];
          this.companyService
            .getStatesc(selectedPresentStateValue)
            .subscribe((data) => {
              this.permanentStateDetails = data.data;
              if (this.employeeCode != null || this.employeeCode != undefined) {
                this.personalInfoForm.controls.pstate.setValue(
                  this.employeeInformationData.pstate
                );
              } else {
                this.personalInfoForm.controls.pstate.setValue(
                  this.loginData.pstate
                );
              }
            });
        }
        this.spinner.hide();
      });

    /**get city details for present address */
    this.personalInfoForm
      .get('pstate')
      ?.valueChanges.subscribe((selectedPresentCityValue: any) => {
        this.spinner.show();

        if (selectedPresentCityValue != '' && !this.checkedsame) {
          this.permanentCityDetails = [];
          this.companyService
            .getCities(selectedPresentCityValue)
            .subscribe((data) => {
              this.permanentCityDetails = data.data;
              if (
                selectedPresentCityValue ==
                  this.employeeInformationData.pstate &&
                (this.employeeCode != null || this.employeeCode != undefined)
              ) {
                this.personalInfoForm.controls.pcity.setValue(
                  this.employeeInformationData.pcity
                );
              } else if (
                selectedPresentCityValue == this.employeeInformationData.pstate
              ) {
                this.personalInfoForm.controls.pcity.setValue(
                  this.loginData.pcity
                );
              }
            });
        }
        this.spinner.hide();
      });
    /**same as present address checkbox */
    this.personalInfoForm
      .get('checked')
      ?.valueChanges.subscribe((selectedValue: any) => {
        if (selectedValue != '') {
          this.checkedsame = true;
          this.permanentStateDetails = this.stateDetails;
          this.permanentCityDetails = this.cityDetails;
          this.personalInfoForm.controls.pstate.setValue(
            this.personalInfoForm.controls.rstate.value
          );
          this.personalInfoForm.controls.pcity.setValue(
            this.personalInfoForm.controls.rcity.value
          );
          this.personalInfoForm.controls.paddress.setValue(
            this.personalInfoForm.controls.raddress.value
          ),
            this.personalInfoForm.controls.pcountry.setValue(
              this.personalInfoForm.controls.rcountry.value
            ),
            this.personalInfoForm.controls.ppincode.setValue(
              this.personalInfoForm.controls.rpincode.value
            );
          this.personalInfoForm.controls.paddress.disable();
          this.personalInfoForm.controls.pcountry.disable();
          this.personalInfoForm.controls.pstate.disable();
          this.personalInfoForm.controls.pstate.disable();
          this.personalInfoForm.controls.pcity.disable();
          this.personalInfoForm.controls.ppincode.disable();
          this.spinner.hide();
        } else {
          this.checkedsame = false;
          this.personalInfoForm.controls.paddress.setValue('');
          this.personalInfoForm.controls.pcountry.setValue('');
          this.personalInfoForm.controls.pstate.setValue('');
          this.personalInfoForm.controls.pstate.setValue('');
          this.personalInfoForm.controls.pcity.setValue('');
          this.personalInfoForm.controls.ppincode.setValue('');
        }
      });

    this.employeeJobForm
      .get('contractStartDate')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.mincontarctDate = selectedValue._d;
      });
    this.experienceForm
      .get('expFromDate')
      ?.valueChanges.subscribe((selectedValue: any) => {
        if (selectedValue != null) {
          this.minExperienceDate = selectedValue._d;
        }
      });
    this.educationForm
      .get('eduFromDate')
      ?.valueChanges.subscribe((selectedValue: any) => {
        if (selectedValue != null) {
          this.minEducationDate = selectedValue._d;
        }
      });
    /////////

    this.personalInfoForm
      .get('usertype')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.ishrmanager = false;
        this.isfinancemanager = false;

        // for(let i =0 ; i<selectedValue.length;i++){
        //   if(selectedValue[i].name == 'HR Manager'){
        //     this.ishrmanager = true;
        //   }
        //   else if (selectedValue[i].name == 'Finance Manager'){
        //     this.isfinancemanager = true;
        //   }
        //   if(i+1 == selectedValue.length){
        //     this.getFinanceManagersList( this.isfinancemanager);
        //     this.getHRManagersList(this.ishrmanager);
        //   }
        // }
      });

    this.personalInfoForm
      .get('employmentType')
      ?.valueChanges.subscribe((selectedValue: number) => {
        if (selectedValue == 3) {
          this.isContractData = true;
          this.isPromotionsOnly = false;
        } else {
          this.isContractData = false;
          this.isPromotionsOnly = true;
        }
      });
    this.personalInfoForm
      .get('department')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.personalInfoForm.controls.rdepartment.setValue('');
        this.personalInfoForm.controls.reportingmanager.setValue('');
      });

    this.personalInfoForm
      .get('rdepartment')
      ?.valueChanges.subscribe((selectedValue: any) => {
        this.availablereportingmanagers = [];
        this.personalInfoForm.controls.reportingmanager.setValue('');
        if (
          this.personalInfoForm.controls.rdepartment.value != null &&
          this.personalInfoForm.controls.rdepartment.value != ''
        ) {
          let data = {
            id: selectedValue,
          };
          this.companyService.getReportingManagers(data).subscribe((data) => {
            for (let i = 0; i < data[0].length; i++) {
              if (data[0][i].id != this.employeeId) {
                this.availablereportingmanagers.push(data[0][i]);
              }
            }
          });
        }
        this.filteredReportingManagers = this.availablereportingmanagers;
      });

    this.getEmployeeImage();
    // if(this.personalInfoForm.controls.usertype.value == null)
    // this.personalInfoForm.controls.usertype.setValue(this.selectedEmployeeRole);

    this.searchReportingManager.valueChanges.subscribe((searchText) => {
      this.filterReportingManagers(searchText);
    });
  }

  onExpDateChange() {
    this.experienceForm.controls.expToDate.setValue('');
  }

  onEduDateChange() {
    this.educationForm.controls.eduToDate.setValue('');
  }

  getnoticeperiods() {
    this.emsService.getnoticeperiods().subscribe((res: any) => {
      if (res.status) {
        this.personalInfoForm.controls.noticePeriod.setValue(res.data[0].value);
      }
    });
  }
  //////////
  getLoginCandidateData() {
    this.spinner.show();
    this.loginData = [];
    this.emsService
      .getPreonboardCandidateData(this.candidateId)
      .subscribe((res: any) => {
        this.loginData = JSON.parse(res.data[0].json)[0];
        this.isNewEmployee = false;
        if (this.loginData.id != null) {
          this.preOnboardId = this.loginData.id;
        }
        let a = this.loginData;
        if (a.rcountry == null) {
          this.personalInfoForm.controls.checked.setValue(false);
        } else if (
          a.rcountry == a.pcountry &&
          a.rstate == a.pstate &&
          a.rcity == a.pcity &&
          a.raddress == a.paddress &&
          a.rpincode == a.ppincode
        ) {
          this.personalInfoForm.controls.checked.setValue(true);
        }
        this.loginCandidateId = this.loginData.candidateid;
        let firname =
          this.loginData.firstname != null ? this.loginData.firstname : '';
        let midname =
          this.loginData.middlename != null ? this.loginData.middlename : '';
        let lasname =
          this.loginData.lastname != null ? this.loginData.lastname : '';
        this.employeeNameh = firname + ' ' + midname + ' ' + lasname;
        this.employeeCode = this.loginData.empid;
        this.availableDesignations.forEach((e: any) => {
          if (e.id == this.loginData.designation) {
            this.employeeDesignation = e.designation;
          }
        });
        this.employeeJoinDate = this.loginData.dateofjoin;
        this.employeeMobile = this.loginData.contact_number;
        this.designationId = this.loginData.designation;
        ////////////
        let fname = this.loginData.firstname;
        fname = fname
          ? fname.charAt(0).toUpperCase() + fname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.firstname.setValue(fname);

        let mname = this.loginData.middlename;
        mname = mname
          ? mname.charAt(0).toUpperCase() + mname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.middlename.setValue(mname);

        let lname = this.loginData.lastname;
        lname = lname
          ? lname.charAt(0).toUpperCase() + lname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.lastname.setValue(lname);

        // this.maxBirthDate = new Date(this.employeeJoinDate);
        if (this.loginData.dateofbirth != null)
          this.personalInfoForm.controls.dateofbirth.setValue(
            new Date(this.loginData.dateofbirth)
          );
        this.personalInfoForm.controls.bloodgroup.setValue(
          this.loginData.bloodgroup
        );
        this.personalInfoForm.controls.designation.setValue(
          this.loginData.designation
        );
        this.personalInfoForm.controls.gender.setValue(this.loginData.gender);
        this.personalInfoForm.controls.maritalstatus.setValue(
          this.loginData.maritalstatus
        );
        this.personalInfoForm.controls.usertype.setValue(
          this.selectedEmployeeRole
        );
        this.loginData.aadharnumber != 'null'
          ? this.personalInfoForm.controls.aadharNumber.setValue(
              this.loginData.aadharnumber
            )
          : this.personalInfoForm.controls.aadharNumber.setValue(''),
          this.personalInfoForm.controls.raddress.setValue(
            this.loginData.address
          );
        this.personalInfoForm.controls.rcountry.setValue(
          this.loginData.country
        );
        this.personalInfoForm.controls.rpincode.setValue(
          this.loginData.pincode
        );
        this.personalInfoForm.controls.personalemail.setValue(
          this.loginData.personal_email
        );
        this.personalInfoForm.controls.spokenLanguages.setValue(
          this.loginData.languages_spoken == 'null' || null
            ? ''
            : this.loginData.languages_spoken
        );
        this.personalInfoForm.controls.paddress.setValue(
          this.loginData.paddress == 'null' || null
            ? ''
            : this.loginData.paddress
        );
        this.personalInfoForm.controls.pcountry.setValue(
          this.loginData.pcountry
        );
        if (this.loginData.ppincode != 'null')
          this.personalInfoForm.controls.ppincode.setValue(
            this.loginData.ppincode
          );
        this.personalInfoForm.controls.mobileNo.setValue(
          this.loginData.contact_number
        );
        if (this.loginData.emergencycontact_number != 'null')
          this.personalInfoForm.controls.alternateMobileNo.setValue(
            this.loginData.emergencycontact_number
          );
        this.personalInfoForm.controls.hireDate.setValue(
          new Date(this.loginData.hired_date)
        );
        this.personalInfoForm.controls.joinDate.setValue(
          new Date(this.loginData.dateofjoin)
        );
        if (this.isSuperAdmin == false) {
          this.personalInfoForm.controls.joinDate.disable();
        }

        if (this.loginData.relations != null) {
          let familydata = JSON.parse(this.loginData.relations);
          if (familydata != null) {
            for (let i = 0; i < familydata.length; i++) {
              let relationship;
              let relationshipname;
              this.employeeRelationship.forEach((e: any) => {
                if (e.id == familydata[i].relationship) {
                  relationship = e.id;
                  relationshipname = e.relationship;
                }
              });

              let gender;
              let gendername;
              this.genderDetails.forEach((e: any) => {
                if (e.id == familydata[i].gender) {
                  gender = e.id;
                  gendername = e.gender;
                }
              });

              this.familyDetails.push({
                firstname: familydata[i].firstname,
                lastname: familydata[i].lastname,
                gender: gender != null || 'null' ? gender : null,
                gendername: gendername,
                contactnumber: familydata[i].contactnumber,
                status: familydata[i].status,
                relationship:
                  relationship != null || 'null' ? relationship : null,
                relationshipname: relationshipname,
                dateofbirth:
                  familydata[i].dateofbirth != 'null'
                    ? this.pipe.transform(
                        familydata[i].dateofbirth,
                        'yyyy-MM-dd'
                      )
                    : '',
              });
            }
            this.familyDataSource = new MatTableDataSource(this.familyDetails);
          }
        }
        ///////
        if (this.loginData.experience != null) {
          let workExperiencedata = JSON.parse(this.loginData.experience);
          for (let i = 0; i < workExperiencedata.length; i++) {
            this.workExperienceDetails.push({
              companyname: workExperiencedata[i].companyname,
              designation: workExperiencedata[i].designation,
              skills: workExperiencedata[i].skills,
              fromdate:
                workExperiencedata[i].fromdate != 'null'
                  ? this.pipe.transform(
                      workExperiencedata[i].fromdate,
                      'yyyy-MM-dd'
                    )
                  : '',
              todate:
                workExperiencedata[i].todate != 'null'
                  ? this.pipe.transform(
                      workExperiencedata[i].todate,
                      'yyyy-MM-dd'
                    )
                  : '',
            });
          }
          this.workExperienceDataSource = new MatTableDataSource(
            this.workExperienceDetails
          );
        }
        ////////
        if (this.loginData.education != null) {
          let educationdata = JSON.parse(this.loginData.education);
          for (let i = 0; i < educationdata.length; i++) {
            this.educationDetails.push({
              course: educationdata[i].course,
              institutename: educationdata[i].institutename,
              fromdate:
                educationdata[i].fromdate != 'null'
                  ? this.pipe.transform(educationdata[i].fromdate, 'yyyy-MM-dd')
                  : '',
              todate:
                educationdata[i].todate != 'null'
                  ? this.pipe.transform(educationdata[i].todate, 'yyyy-MM-dd')
                  : '',
            });
          }
          this.educationDataSource = new MatTableDataSource(
            this.educationDetails
          );
        }
      });
    this.spinner.hide();
  }

  /** through employee directory login data  */
  getEmployeeInformationList() {
    this.spinner.show();
    this.employeeInformationData = [];
    this.familyDetails = [];
    this.selectedRoles = [];
    this.roleArray = [];
    this.flag = false;
    this.emsService
      .getEmployeeInformationData(this.employeeId)
      .subscribe((res: any) => {
        this.employeeInformationData = JSON.parse(res.data[0].json)[0];
        this.isNewEmployee = false;
        this.joinDateDisable = true;
        this.existingRmID = this.employeeInformationData.reportingmanager;
        if (this.employeeInformationData.id != null) {
          this.preOnboardId = this.employeeInformationData.id;
        }
        let a = this.employeeInformationData;
        if (
          a.country == a.pcountry &&
          a.state == a.pstate &&
          a.city == a.pcity &&
          a.address == a.paddress &&
          a.pincode == a.ppincode
        ) {
          this.personalInfoForm.controls.checked.setValue(true);
        }
        let midname =
          this.employeeInformationData.middlename != null
            ? this.employeeInformationData.middlename
            : '';
        let lasname =
          this.employeeInformationData.lastname != null
            ? this.employeeInformationData.lastname
            : '';
        this.employeeNameh =
          this.employeeInformationData.firstname +
          ' ' +
          midname +
          ' ' +
          lasname;
        // this.employeeNameh = this.employeeInformationData.firstname + ' ' + this.employeeInformationData.middlename  + ' ' + this.employeeInformationData.lastname;
        this.loginEmpId = this.employeeInformationData.id;
        this.employeeCode = this.employeeInformationData.empid;
        this.availableDesignations.forEach((e: any) => {
          if (e.id == this.employeeInformationData.designation) {
            this.employeeDesignation = e.designation;
          }
        });
        this.employeeJoinDate = this.employeeInformationData.dateofjoin;
        this.employeeMobile = this.employeeInformationData.contactnumber;
        this.designationId = this.employeeInformationData.designation;
        if (this.employeeInformationData.employmenttype == 3) {
          this.isContractData = true;
          this.isPromotionsOnly = false;
        }

        let fname = this.employeeInformationData.firstname;
        fname = fname
          ? fname.charAt(0).toUpperCase() + fname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.firstname.setValue(fname);

        let mname = this.employeeInformationData.middlename;
        mname = mname
          ? mname.charAt(0).toUpperCase() + mname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.middlename.setValue(mname);

        let lname = this.employeeInformationData.lastname;
        lname = lname
          ? lname.charAt(0).toUpperCase() + lname.substr(1).toLowerCase()
          : '';
        this.personalInfoForm.controls.lastname.setValue(lname);
        if (this.employeeInformationData.dateofbirth != null)
          this.personalInfoForm.controls.dateofbirth.setValue(
            new Date(this.employeeInformationData.dateofbirth)
          );
        this.personalInfoForm.controls.bloodgroup.setValue(
          this.employeeInformationData.bloodgroup
        );
        this.personalInfoForm.controls.gender.setValue(
          this.employeeInformationData.gender
        );
        this.personalInfoForm.controls.maritalstatus.setValue(
          this.employeeInformationData.maritalstatus
        );
        this.employeeInformationData.aadharnumber != 'null'
          ? this.personalInfoForm.controls.aadharNumber.setValue(
              this.employeeInformationData.aadharnumber
            )
          : this.personalInfoForm.controls.aadharNumber.setValue(''),
          this.personalInfoForm.controls.raddress.setValue(
            this.employeeInformationData.address
          );
        this.personalInfoForm.controls.rcountry.setValue(
          this.employeeInformationData.country
        );
        // this.personalInfoForm.controls.rstate.setValue(this.employeeInformationData.state);
        // this.personalInfoForm.controls.rcity.setValue(this.employeeInformationData.city);
        this.personalInfoForm.controls.rpincode.setValue(
          this.employeeInformationData.pincode
        );
        this.personalInfoForm.controls.personalemail.setValue(
          this.employeeInformationData.personalemail == 'null' || null
            ? ''
            : this.employeeInformationData.personalemail
        );
        if (
          this.employeeInformationData.languages_spoken != 'null' ||
          this.employeeInformationData.languages_spoken != 'null'
        )
          this.personalInfoForm.controls.spokenLanguages.setValue(
            this.employeeInformationData.languages_spoken
          );
        this.personalInfoForm.controls.paddress.setValue(
          this.employeeInformationData.paddress == 'null' || null
            ? ''
            : this.employeeInformationData.paddress
        );
        this.personalInfoForm.controls.pcountry.setValue(
          this.employeeInformationData.pcountry
        );
        this.personalInfoForm.controls.pstate.setValue(
          this.employeeInformationData.pstate
        );
        this.personalInfoForm.controls.pcity.setValue(
          this.employeeInformationData.pcity
        );
        if (this.employeeInformationData.ppincode != 'null')
          this.personalInfoForm.controls.ppincode.setValue(
            this.employeeInformationData.ppincode
          );
        this.personalInfoForm.controls.mobileNo.setValue(
          this.employeeInformationData.contactnumber
        );
        this.employeeInformationData.emergencycontactnumber != 'null'
          ? this.personalInfoForm.controls.alternateMobileNo.setValue(
              this.employeeInformationData.emergencycontactnumber
            )
          : this.personalInfoForm.controls.alternateMobileNo.setValue(''),
          this.personalInfoForm.controls.hireDate.setValue(
            new Date(this.employeeInformationData.hired_date)
          );
        this.personalInfoForm.controls.joinDate.setValue(
          new Date(this.employeeInformationData.dateofjoin)
        );
        if (this.isSuperAdmin == false) {
          this.personalInfoForm.controls.joinDate.disable();
        }
        // /**work information */
        this.personalInfoForm.controls.empid.setValue(
          this.employeeInformationData.empid
        );
        this.personalInfoForm.controls.officeemail.setValue(
          this.employeeInformationData.officeemail
        );
        this.personalInfoForm.controls.empStatus.setValue(
          this.employeeInformationData.status
        );
        this.personalInfoForm.controls.employmentType.setValue(
          this.employeeInformationData.employmenttype
        );
        if (this.employeeInformationData.usertype != null) {
          let data = JSON.parse(this.employeeInformationData.usertype);
          for (let i = 0; i < data.length; i++) {
            this.roleArray.push(data[i]);
          }
        }
        this.personalInfoForm.controls.usertype.setValue(this.roleArray);
        this.personalInfoForm.controls.companylocation.setValue(
          this.employeeInformationData.worklocation
        );
        this.personalInfoForm.controls.designation.setValue(
          this.employeeInformationData.designation
        );
        this.personalInfoForm.controls.department.setValue(
          this.employeeInformationData.department
        );
        this.personalInfoForm.controls.rdepartment.setValue(
          this.employeeInformationData.reportingmanager_deptid
        );
        this.personalInfoForm.controls.reportingmanager.setValue(
          this.employeeId == this.employeeInformationData.reportingmanager
            ? 'Self'
            : this.employeeInformationData.reportingmanager
        );
        // this.personalInfoForm.controls.fmanager.setValue(this.empId == this.employeeInformationData.financemanager?"Self":this.employeeInformationData.financemanager);
        // this.personalInfoForm.controls.hrmanager.setValue(this.empId == this.employeeInformationData.hrmanager?"Self":this.employeeInformationData.hrmanager);
        if (this.employeeInformationData.relations != null) {
          let familydata = JSON.parse(this.employeeInformationData.relations);
          if (familydata != null) {
            for (let i = 0; i < familydata.length; i++) {
              let relationship;
              let relationshipname;
              this.employeeRelationship.forEach((e: any) => {
                if (e.id == familydata[i].relationship) {
                  relationship = e.id;
                  relationshipname = e.relationship;
                }
              });

              let gender;
              let gendername;
              this.genderDetails.forEach((e: any) => {
                if (e.id == familydata[i].gender) {
                  gender = e.id;
                  gendername = e.gender;
                }
              });

              this.familyDetails.push({
                firstname: familydata[i].firstname,
                lastname: familydata[i].lastname,
                gender: gender,
                gendername: gendername,
                contactnumber: familydata[i].contactnumber,
                status: familydata[i].status,
                relationship: relationship,
                relationshipname: relationshipname,
                dateofbirth:
                  familydata[i].dateofbirth != 'null'
                    ? this.pipe.transform(
                        familydata[i].dateofbirth,
                        'yyyy-MM-dd'
                      )
                    : '',
              });
            }
            this.familyDataSource = new MatTableDataSource(this.familyDetails);
          }
        }
      });
    this.spinner.hide();
  }

  /** through employee directory login data  */
  getEmployeeJobList() {
    this.employeeJobData = [];
    this.promotionsGetList = [];
    this.emsService
      .getEmployeeJobData(this.employeeId)
      .subscribe((res: any) => {
        this.employeeJobData = JSON.parse(res.data[0].json)[0];
        if (this.employeeJobData.contractname != 'null')
          this.employeeJobForm.controls.contractName.setValue(
            this.employeeJobData.contractname
          );
        this.employeeJobForm.controls.contractFile.setValue(
          this.employeeJobData.fileid
        );
        if (this.employeeJobData.notes != 'null')
          this.employeeJobForm.controls.contractNotes.setValue(
            this.employeeJobData.notes
          );
        if (this.employeeJobData.startdate != null)
          this.employeeJobForm.controls.contractStartDate.setValue(
            new Date(this.employeeJobData.startdate)
          );
        if (this.employeeJobData.enddate != null)
          this.employeeJobForm.controls.contractEndDate.setValue(
            new Date(this.employeeJobData.enddate)
          );

        if (this.employeeJobData.promotions != null) {
          let promotionsdata = JSON.parse(this.employeeJobData.promotions);
          if (promotionsdata != null) {
            for (let i = 0; i < promotionsdata.length; i++) {
              this.promotionsGetList.push({
                newsalary: promotionsdata[i].salary,
                newdescription: promotionsdata[i].description,
                effectivedate:
                  promotionsdata[i].effectivedate != 'null'
                    ? this.pipe.transform(
                        promotionsdata[i].effectivedate,
                        'yyyy-MM-dd'
                      )
                    : '',
                annualsalary: promotionsdata[i].annualsalary,
              });
            }
            this.promotionsDataSource = new MatTableDataSource(
              this.promotionsGetList
            );
          }
        }
      });
  }
  /** through employee directory login data  */
  getEmployeeEmploymentList() {
    this.employeeEmployementData = [];
    this.workExperienceDetails = [];
    this.emsService
      .getEmployeeEmployement(this.employeeId)
      .subscribe((res: any) => {
        this.employeeEmployementData = JSON.parse(res.data[0].json)[0];
        if (this.employeeEmployementData.bankname != 'null')
          this.employementForm.controls.bankName.setValue(
            this.employeeEmployementData.bankname
          );
        if (this.employeeEmployementData.nameasperbankaccount != 'null')
          this.employementForm.controls.bankAccountName.setValue(
            this.employeeEmployementData.nameasperbankaccount
          );
        if (this.employeeEmployementData.bankaccountnumber != 'null')
          this.employementForm.controls.bankAccountNumber.setValue(
            this.employeeEmployementData.bankaccountnumber
          );
        this.employeeEmployementData.ifsccode != null
          ? this.employementForm.controls.ifscCode.setValue(
              this.employeeEmployementData.ifsccode
            )
          : '';

        if (this.employeeEmployementData.branchname != 'null')
          this.employementForm.controls.branchName.setValue(
            this.employeeEmployementData.branchname
          );
        if (this.employeeEmployementData.uanumber != 'null')
          this.employementForm.controls.uanNumber.setValue(
            this.employeeEmployementData.uanumber
          );
        this.employeeEmployementData.pan == 'null' || null
          ? null
          : this.employementForm.controls.panNumber.setValue(
              this.employeeEmployementData.pan
            );
        if (this.employeeEmployementData.esi != 'null')
          this.employementForm.controls.esiNumber.setValue(
            this.employeeEmployementData.esi
          );

        if (this.employeeEmployementData.experience != null) {
          let employementdata = JSON.parse(
            this.employeeEmployementData.experience
          );
          if (employementdata != null) {
            for (let i = 0; i < employementdata.length; i++) {
              this.workExperienceDetails.push({
                companyname: employementdata[i].companyname,
                designation: employementdata[i].designation,
                fromdate:
                  employementdata[i].fromdate != 'null'
                    ? this.pipe.transform(
                        employementdata[i].fromdate,
                        'yyyy-MM-dd'
                      )
                    : '',
                todate:
                  employementdata[i].todate != 'null'
                    ? this.pipe.transform(
                        employementdata[i].todate,
                        'yyyy-MM-dd'
                      )
                    : '',
                skills: employementdata[i].skills,
              });
            }
            this.workExperienceDataSource = new MatTableDataSource(
              this.workExperienceDetails
            );
          }
        }
      });
  }

  /** through employee directory login data  */
  getEmployeeEducationList() {
    this.employeeEducationData = [];
    this.educationDetails = [];
    this.emsService
      .getEmployeeEducationData(this.employeeId)
      .subscribe((res: any) => {
        this.employeeEducationData = JSON.parse(res.data[0].json)[0];

        if (this.employeeEducationData.education != null) {
          let educationdata = JSON.parse(this.employeeEducationData.education);
          if (educationdata != null) {
            for (let i = 0; i < educationdata.length; i++) {
              this.educationDetails.push({
                course: educationdata[i].course,
                fromdate:
                  educationdata[i].fromdate != 'null'
                    ? this.pipe.transform(
                        educationdata[i].fromdate,
                        'yyyy-MM-dd'
                      )
                    : '',
                todate:
                  educationdata[i].todate != 'null'
                    ? this.pipe.transform(educationdata[i].todate, 'yyyy-MM-dd')
                    : '',
                institutename: educationdata[i].institutename,
              });
            }
            this.educationDataSource = new MatTableDataSource(
              this.educationDetails
            );
          }
        }
      });
  }

  getCountry() {
    this.countryDetails = [];
    this.companyService
      .getCountry('countrymaster', null, 1, 10, this.companyDBName)
      .subscribe((result) => {
        this.countryDetails = result.data;
        this.permanentCountryDetails = result.data;
        this.filteredRCountryList = this.countryDetails;
        this.filteredPCountryList = this.permanentCountryDetails;
      });
  }

  createPersonalInfoForm() {
    this.personalInfoForm = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      middlename: [''],
      dateofbirth: [''],
      bloodgroup: [''],
      gender: [''],
      maritalstatus: [''],
      aadharNumber: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      panNumber: [''],
      esiNumber: [''],
      uanNumber: ['', Validators.maxLength(12)],
      /// address controls
      raddress: [''],
      rcountry: [''],
      rstate: [''],
      rcity: [''],
      rpincode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
      personalemail: [
        '',
        [
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      spokenLanguages: [''],
      checked: [false],
      paddress: [''],
      pcountry: [''],
      pstate: [''],
      pcity: [''],
      ppincode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
      mobileNo: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^(91)?[4-9][0-9]{9}'),
        ],
      ],
      alternateMobileNo: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^(91)?[4-9][0-9]{9}'),
        ],
      ],
      officeemail: [
        '',
        [
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      usertype: [],
      designation: [''],
      department: [''],
      employmentType: [''],
      dateofjoin: [''],
      companylocation: [''],
      reportingmanager: [''],
      rdepartment: [''],
      // fmanager:[""],
      // hrmanager:[""],
      empid: [''],
      empStatus: ['Active'],
      noticePeriod: [''],
      hireDate: [''],
      joinDate: ['', Validators.required],
      // familyfirstname: ["", Validators.required],
      // familycontact: ["", [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^(91)?[4-9][0-9]{9}'), Validators.required]],
      // familygender: ["", Validators.required],
      // relation: ["", Validators.required],
      // familystatus: ["Alive", Validators.required],
    });
  }
  createFamilyForm() {
    this.candidateFamilyForm = this.formBuilder.group({
      familyfirstname: ['', Validators.required],
      familycontact: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^(91)?[4-9][0-9]{9}'),
          Validators.required,
        ],
      ],
      familygender: ['', Validators.required],
      relation: ['', Validators.required],
      familystatus: ['Alive', Validators.required],
    });
  }

  //** */

  //** */

  createEmployeeJobForm() {
    this.employeeJobForm = this.formBuilder.group({
      contractName: [''],
      contractStartDate: [''],
      contractEndDate: [''],
      contractFile: [''],
      contractNotes: [''],
    });
  }

  createPromotionsForm() {
    this.promotionsForm = this.formBuilder.group({
      newSalary: [''],
      newDescription: [''],
      effectiveDate: [''],
      annualSalary: [''],
    });
  }

  createEmployementForm() {
    const regexx = new RegExp(
      /^(\d{2})[-–\s]?(\d{2})[-–\s]?(\d{6})[-–\s]?(\d{3})[-–\s]?(\d{4})$/
    );
    this.employementForm = this.formBuilder.group({
      bankName: [''],
      bankAccountName: [''],
      bankAccountNumber: [''],
      ifscCode: [''],
      branchName: [''],
      uanNumber: [''],
      panNumber: [
        '',
        [Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)],
      ],
      esiNumber: [''],
    });
  }
  createExperienceForm() {
    this.experienceForm = this.formBuilder.group({
      companyName: [''],
      designation: [''],
      expFromDate: [''],
      expToDate: [''],
      jobDescription: [''],
    });
  }

  createEducationForm() {
    this.educationForm = this.formBuilder.group({
      course: [''],
      instituteName: [''],
      eduFromDate: [''],
      eduToDate: [''],
    });
  }
  createDocumentsForm() {
    this.documentsForm = this.formBuilder.group({
      documentId: [''],
      documentName: ['', Validators.required],
      documentNumber: ['', Validators.required],
      attachedFile: [''],
    });

    this.searchControl.valueChanges.subscribe((searchText) => {
      this.filterEmpTypes(searchText);
    });

    this.searchControlforRole.valueChanges.subscribe((searchText) => {
      this.filterRoles(searchText);
    });
    this.searchControlforloc.valueChanges.subscribe((searchText) => {
      this.filterLocations(searchText);
    });
    this.searchControlforDesignation.valueChanges.subscribe((searchText) => {
      this.filterDesignations(searchText);
    });
    this.searchControlforDep.valueChanges.subscribe((searchText) => {
      this.filterDep(searchText);
    });
    this.searchControlforRMDep.valueChanges.subscribe((searchText) => {
      this.filterRMDep(searchText);
    });
    this.searchControlforRCoun.valueChanges.subscribe((searchText) => {
      this.filterRcountry(searchText);
    });
    this.searchControlforPCoun.valueChanges.subscribe((searchText) => {
      this.filterPcountry(searchText);
    });
    this.searchControlforRState.valueChanges.subscribe((searchText) => {
      this.filterRStates(searchText);
    });
    this.searchControlforPState.valueChanges.subscribe((searchText) => {
      this.filterPStates(searchText);
    });
    this.searchControlforRCity.valueChanges.subscribe((searchText) => {
      this.filterRCity(searchText);
    });
    this.searchControlforPCity.valueChanges.subscribe((searchText) => {
      this.filterPCity(searchText);
    });
  }

  getBloodgroups() {
    this.companyService
      .getMastertable('bloodgroupmaster', '1', 1, 10, this.companyDBName)
      .subscribe((data) => {
        this.bloodGroupdetails = data.data;
        this.filteredBloodGroups = data.data;
      });
  }
  getGender() {
    this.companyService
      .getMastertable('gendermaster', null, 1, 40, this.companyDBName)
      .subscribe((data) => {
        this.genderDetails = data.data;
      });
  }
  getMaritalStatusMaster() {
    this.companyService
      .getMastertable('maritalstatusmaster', null, 1, 10, this.companyDBName)
      .subscribe((data) => {
        this.maritalStatusDetails = data.data;
      });
  }
  getDepartmentsForReportingManagers() {
    this.companyService
      .getDepartmentsForReportingManagers()
      .subscribe((data: any) => {
        this.departmentsForReportingManagers = data.data;
        this.filteredRMDep = this.departmentsForReportingManagers;
      });
  }
  getRelationshipMaster() {
    this.companyService
      .getMastertable('relationshipmaster', 'Active', 1, 30, this.companyDBName)
      .subscribe((data) => {
        this.employeeRelationship = data.data;
      });
  }
  getEmploymentTypeMaster() {
    this.companyService
      .getMastertable('employmenttypemaster', null, 1, 1000, this.companyDBName)
      .subscribe((data) => {
        this.EmploymentTypeDetails = data.data;
        this.filteredEmpTypes = this.EmploymentTypeDetails;
      });
  }
  getDesignationsMaster() {
    this.companyService
      .getMastertable('designationsmaster', 1, 1, 1000, this.companyDBName)
      .subscribe((data) => {
        if (data.status) {
          this.availableDesignations = data.data;
          this.filteredDesignations = this.availableDesignations;
        }
      });
  }
  getDepartmentsMaster() {
    this.companyService
      .getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName)
      .subscribe((data) => {
        if (data.status) {
          this.availableDepartments = data.data;
          this.filteredDep = this.availableDepartments;
        }
      });
  }
  getWorkLocation() {
    this.companyService
      .getactiveWorkLocation({ id: null, companyName: this.companyDBName })
      .subscribe((result) => {
        this.worklocationDetails = result.data;
        this.filteredLocations = this.worklocationDetails;
      });
  }
  getRoles() {
    this.companyService
      .getMastertable('rolesmaster', null, 1, 1000, this.companyDBName)
      .subscribe((data) => {
        let roledata = data.data;
        this.availableRole = [];
        for (let i = 0; i < roledata.length; i++) {
          if (roledata[i].isEditable == 0) {
            this.availableRole.push(roledata[i]);
          }
        }
        this.selectedEmployeeRole.push(this.availableRole[0]);
        this.filteredRoles = this.availableRole;
      });
  }
  getReportingManagers(id: any) {
    let data = {
      id: id,
    };
    this.companyService.getReportingManagers(data).subscribe((data) => {
      this.availablereportingmanagers = data[0];
    });
  }
  // get personalForm(): { [key: string]: AbstractControl } {
  //   return this.personalInfoForm.controls;
  // }
  savePersonalInfo() {
    this.submitsavepersonal = true;
    let hiredDate;
    let joinDate;
    if (
      this.personalInfoForm.controls.hireDate.value == undefined ||
      this.personalInfoForm.controls.hireDate.value == ''
    ) {
      hiredDate = this.pipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    } else {
      hiredDate = this.pipe.transform(
        this.personalInfoForm.controls.hireDate.value,
        'yyyy-MM-dd hh:mm:ss'
      );
    }

    if (
      this.personalInfoForm.controls.joinDate.value == undefined ||
      this.personalInfoForm.controls.joinDate.value == ''
    ) {
      joinDate = this.pipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    } else if (this.isNewEmployee == false) {
      joinDate = this.pipe.transform(
        this.personalInfoForm.controls.joinDate.value,
        'yyyy-MM-dd hh:mm:ss'
      );
    }
    this.newRmID = this.personalInfoForm.controls.reportingmanager.value;
    if (
      (this.existingRmID != null || this.existingRmID != undefined) &&
      this.newRmID !== 'Self'
    ) {
      this.existingRmID === this.newRmID
        ? (this.isNewReportingManager = false)
        : (this.isNewReportingManager = true);
    }

    if (this.personalInfoForm.valid) {
      this.spinner.show();
      let data = {
        condidateid:
          this.loginCandidateId != undefined || this.loginCandidateId != null
            ? this.loginCandidateId
            : null,
        // empid: this.employeeCode != undefined || this.employeeCode != null ? this.employeeCode : null,
        id: this.loginEmpId,
        empid:
          this.employeeCode != undefined || this.employeeCode != null
            ? this.personalInfoForm.controls.empid.value
            : null,
        firstname: this.personalInfoForm.controls.firstname.value,
        middlename: this.personalInfoForm.controls.middlename.value,
        lastname: this.personalInfoForm.controls.lastname.value,
        dateofbirth: this.pipe.transform(
          this.personalInfoForm.controls.dateofbirth.value,
          'yyyy-MM-dd hh:mm:ss'
        ),
        bloodgroup: parseInt(this.personalInfoForm.controls.bloodgroup.value),
        gender: parseInt(this.personalInfoForm.controls.gender.value),
        maritalstatus: parseInt(
          this.personalInfoForm.controls.maritalstatus.value
        ),
        aadharnumber: this.personalInfoForm.controls.aadharNumber.value,
        address: this.personalInfoForm.controls.raddress.value,
        city: parseInt(this.personalInfoForm.controls.rcity.value),
        state: parseInt(this.personalInfoForm.controls.rstate.value),
        pincode: this.personalInfoForm.controls.rpincode.value,
        country: parseInt(this.personalInfoForm.controls.rcountry.value),
        paddress: this.personalInfoForm.controls.paddress.value,
        pcity: parseInt(this.personalInfoForm.controls.pcity.value),
        pstate: parseInt(this.personalInfoForm.controls.pstate.value),
        ppincode: this.personalInfoForm.controls.ppincode.value,
        pcountry: parseInt(this.personalInfoForm.controls.pcountry.value),
        passport: null,
        personalemail: this.personalInfoForm.controls.personalemail.value,
        languages_spoken: this.personalInfoForm.controls.spokenLanguages.value,
        contactnumber: this.personalInfoForm.controls.mobileNo.value,
        hiredon: hiredDate,
        dateofjoin: this.pipe.transform(
          this.personalInfoForm.controls.joinDate.value,
          'yyyy-MM-dd hh:mm:ss'
        ),
        noticeperiod: this.personalInfoForm.controls.noticePeriod.value,
        designation: parseInt(this.personalInfoForm.controls.designation.value),
        emergencycontactnumber:
          this.personalInfoForm.controls.alternateMobileNo.value,
        emergencycontactrelation: null,
        emergencycontactname: null,
        relations: this.familyDetails,
        education:
          this.educationDetails.length > 0 ? this.educationDetails : null,
        experience:
          this.workExperienceDetails.length > 0
            ? this.workExperienceDetails
            : null,
        status: 1,
        actionby: parseInt(this.userSession.id),
        ///////
        officeemail: this.personalInfoForm.controls.officeemail.value,
        usertype: this.selectedUserTypeRoles,
        department: this.personalInfoForm.controls.department.value,
        employmenttype: this.personalInfoForm.controls.employmentType.value,
        companylocation: this.personalInfoForm.controls.companylocation.value,
        reportingmanager: this.personalInfoForm.controls.reportingmanager.value,
        isNewReportingManager: this.isNewReportingManager,
        reportingManagerName: this.reportingManagerName,
        currentDate: this.pipe.transform(this.currentDate, 'dd-MM-yyyy'),
      };
      this.emsService
        .saveEmployeeInformationData(data)
        .subscribe((res: any) => {
          if (res.status) {
            if (res.data.email == null) {
              this.employeeId = res.data.empid;
              this.getEmployeeInformationList();
              this.getEmployeeJobList();
              this.getEmployeeEmploymentList();
              this.getEmployeeEducationList();
              this.getEmployeeImage();
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: this.EM42,
              });
              this.isNewEmployee != true
                ? this.selectedtab.setValue(1)
                : this.selectedtab.setValue(0);
              this.spinner.hide();

              /** employee photo upload */
              if (this.empFile) {
                this.LM.getFilepathsMaster(1).subscribe((result) => {
                  if (result && result.status) {
                    let obj = {
                      id: this.profileId ? this.profileId : null,
                      employeeId: this.employeeId,
                      filecategory: 'PROFILE',
                      moduleId: 1,
                      documentnumber: '',
                      fileName: this.empFile.name,
                      modulecode: result.data[0].module_code,
                      requestId: null,
                      status: 'Submitted',
                    };

                    this.LM.setFilesMaster(obj).subscribe((data) => {
                      if (data && data.status) {
                        let info = JSON.stringify(data.data[0]);
                        this.formData.append('info', info);
                        this.formData.append('file', this.empFile);
                        this.LM.setProfileImage(this.formData).subscribe(
                          (data) => {
                            this.formData.delete('file');
                            this.formData.delete('info');
                            this.spinner.hide();
                            if (data && data.status) {
                              if (this.employeeId == this.userSession.id) {
                                this.MainComponent.getUploadImage();
                              }
                            } else {
                              this.dialog.open(ConfirmationComponent, {
                                position: { top: `70px` },
                                disableClose: true,
                                data: '',
                              });
                            }
                            this.empFile = null;
                            this.fileImageToggler();
                            this.getUploadImage();
                            this.isRemoveImage = true;
                          }
                        );
                      } else {
                        this.spinner.hide();
                        this.LM.deleteFilesMaster(result.data[0].id).subscribe(
                          (data) => {}
                        );
                        this.getUploadImage();
                        this.dialog.open(ConfirmationComponent, {
                          position: { top: `70px` },
                          disableClose: true,
                          data: '',
                        });
                      }
                    });
                  } else {
                    this.getUploadImage();
                    this.spinner.hide();
                    this.dialog.open(ConfirmationComponent, {
                      position: { top: `70px` },
                      disableClose: true,
                      data: '',
                    });
                  }
                });
              }
            } else {
              this.spinner.hide();
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: res.data.email,
              });
            }
          } else {
            this.spinner.hide();
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM43,
            });
          }
        });
    }
  }

  addingFamilyView() {
    this.addFamilyView = true;
  }
  addfamily() {
    this.addValidators();
    if (this.isfamilyedit) {
      this.isfamilyedit = false;
      this.familyDetails[this.familyindex].firstname =
        this.candidateFamilyForm.controls.familyfirstname.value;
      //this.familyDetails[this.familyindex].lastname = this.candidateFamilyForm.controls.familylastname.value;
      this.familyDetails[this.familyindex].gender =
        this.candidateFamilyForm.controls.familygender.value.id;
      this.familyDetails[this.familyindex].gendername =
        this.candidateFamilyForm.controls.familygender.value.gender;
      this.familyDetails[this.familyindex].contactnumber =
        this.candidateFamilyForm.controls.familycontact.value;
      this.familyDetails[this.familyindex].status =
        this.candidateFamilyForm.controls.familystatus.value;
      this.familyDetails[this.familyindex].relationship =
        this.candidateFamilyForm.controls.relation.value.id;
      this.familyDetails[this.familyindex].relationshipname =
        this.candidateFamilyForm.controls.relation.value.relationship;
      //this.familyDetails[this.familyindex].dateofbirth = this.candidateFamilyForm.controls.familydateofbirth.value != "" ? this.pipe.transform(this.candidateFamilyForm.controls.familydateofbirth.value, 'yyyy-MM-dd') : ''
      this.clearValidators();
      this.clearfamily();
    } else {
      if (this.candidateFamilyForm.valid) {
        this.familyDetails.push({
          firstname: this.candidateFamilyForm.controls.familyfirstname.value,
          lastname: null,
          gender: this.candidateFamilyForm.controls.familygender.value.id,
          gendername:
            this.candidateFamilyForm.controls.familygender.value.gender,
          contactnumber: this.candidateFamilyForm.controls.familycontact.value,
          status: this.candidateFamilyForm.controls.familystatus.value,
          relationship: this.candidateFamilyForm.controls.relation.value.id,
          relationshipname:
            this.candidateFamilyForm.controls.relation.value.relationship,
          dateofbirth: null,
        });
        this.familyDataSource = new MatTableDataSource(this.familyDetails);
        this.clearValidators();
        this.clearfamily();
      }
    }
  }
  clearValidators() {
    this.candidateFamilyForm.get('familyfirstname').clearValidators();
    this.candidateFamilyForm.get('familyfirstname').updateValueAndValidity();

    this.candidateFamilyForm.get('relation').clearValidators();
    this.candidateFamilyForm.get('relation').updateValueAndValidity();

    this.candidateFamilyForm.get('familycontact').clearValidators();
    this.candidateFamilyForm.get('familycontact').updateValueAndValidity();

    this.candidateFamilyForm.get('familygender').clearValidators();
    this.candidateFamilyForm.get('familygender').updateValueAndValidity();
  }

  addValidators() {
    this.candidateFamilyForm
      .get('familyfirstname')
      .setValidators(Validators.required);
    this.candidateFamilyForm.get('familyfirstname').updateValueAndValidity();

    this.candidateFamilyForm.get('relation').setValidators(Validators.required);
    this.candidateFamilyForm.get('relation').updateValueAndValidity();

    this.candidateFamilyForm
      .get('familygender')
      .setValidators(Validators.required);
    this.candidateFamilyForm.get('familygender').updateValueAndValidity();
  }
  clearfamily() {
    this.candidateFamilyForm.controls.familyfirstname.setValue('');
    this.candidateFamilyForm.controls.relation.setValue('');
    this.candidateFamilyForm.controls.familycontact.setValue('');
    this.candidateFamilyForm.controls.familygender.setValue('');
    this.isfamilyedit = false;
    //this.candidateFamilyForm.valid = true;
  }

  editfamily(i: any) {
    this.familyindex = i;
    this.isfamilyedit = true;
    this.addFamilyView = true;
    this.candidateFamilyForm.controls.familyfirstname.setValue(
      this.familyDetails[i].firstname
    );
    //this.candidateFamilyForm.controls.familylastname.setValue(this.familyDetails[i].lastname);
    //this.candidateFamilyForm.controls.familydateofbirth.setValue(new Date(this.familyDetails[i].dateofbirth));
    this.candidateFamilyForm.controls.familystatus.setValue(
      this.familyDetails[i].status
    );
    if (this.familyDetails[i].contactnumber != 'null')
      this.candidateFamilyForm.controls.familycontact.setValue(
        this.familyDetails[i].contactnumber
      );
    this.employeeRelationship.forEach((e: any) => {
      if (e.id == this.familyDetails[i].relationship) {
        this.candidateFamilyForm.controls.relation.setValue(e);
      }
    });
    this.genderDetails.forEach((e: any) => {
      if (e.id == this.familyDetails[i].gender) {
        this.candidateFamilyForm.controls.familygender.setValue(e);
      }
    });
  }
  deleteFamilyPopup(event: any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: this.EM61, YES: 'YES', NO: 'NO' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.deletefamily(event);
      }
    });
  }
  deletefamily(index: any) {
    this.familyDetails.splice(index, 1);
    this.familyDataSource = new MatTableDataSource(this.familyDetails);
    this.isfamilyedit = false;
  }
  //////////////
  clearContract() {
    this.clearContractValidators();
    this.employeeJobForm.controls.contractName.reset();
    this.employeeJobForm.controls.contractStartDate.reset();
    this.employeeJobForm.controls.contractEndDate.reset();
    this.employeeJobForm.controls.contractNotes.reset();
    this.clearPromotions();
  }
  clearContractValidators() {
    this.employeeJobForm.get('contractName').clearValidators();
    this.employeeJobForm.get('contractName').updateValueAndValidity();

    this.employeeJobForm.get('contractStartDate').clearValidators();
    this.employeeJobForm.get('contractStartDate').updateValueAndValidity();

    this.employeeJobForm.get('contractEndDate').clearValidators();
    this.employeeJobForm.get('contractEndDate').updateValueAndValidity();

    this.employeeJobForm.get('contractNotes').clearValidators();
    this.employeeJobForm.get('contractNotes').updateValueAndValidity();
  }
  addPromotions() {
    if (this.promotionsForm.valid) {
      this.promotionsGetList.push({
        newsalary: this.promotionsForm.controls.newSalary.value,
        newdescription: this.promotionsForm.controls.newDescription.value,
        effectivedate: this.pipe.transform(
          this.promotionsForm.controls.effectiveDate.value,
          'yyyy-MM-dd'
        ),
        annualsalary: this.promotionsForm.controls.annualSalary.value,
      });
      this.promotionsDataSource = new MatTableDataSource(
        this.promotionsGetList
      );
      this.clearPromotions();
    } else {
    }
  }
  clearPromotions() {
    this.clearPromotionValidators();
    this.promotionsForm.controls.newSalary.reset();
    this.promotionsForm.controls.newDescription.reset();
    this.promotionsForm.controls.effectiveDate.reset();
    this.promotionsForm.controls.annualSalary.reset();
  }
  addPromotionValidators() {
    this.promotionsForm.get('newSalary').setValidators(Validators.required);
    this.promotionsForm.get('newSalary').updateValueAndValidity();

    this.promotionsForm
      .get('newDescription')
      .setValidators(Validators.required);
    this.promotionsForm.get('newDescription').updateValueAndValidity();

    this.promotionsForm.get('effectiveDate').setValidators(Validators.required);
    this.promotionsForm.get('effectiveDate').updateValueAndValidity();

    this.promotionsForm.get('annualSalary').setValidators(Validators.required);
    this.promotionsForm.get('annualSalary').updateValueAndValidity();
  }
  clearPromotionValidators() {
    this.promotionsForm.get('newSalary').clearValidators();
    this.promotionsForm.get('newSalary').updateValueAndValidity();

    this.promotionsForm.get('newDescription').clearValidators();
    this.promotionsForm.get('newDescription').updateValueAndValidity();

    this.promotionsForm.get('effectiveDate').clearValidators();
    this.promotionsForm.get('effectiveDate').updateValueAndValidity();

    this.promotionsForm.get('annualSalary').clearValidators();
    this.promotionsForm.get('annualSalary').updateValueAndValidity();
  }
  deletePromotions(index: any) {
    this.promotionsGetList.splice(index, 1);
    this.promotionsDataSource = new MatTableDataSource(this.promotionsGetList);
  }
  //** */
  saveJobDetails() {
    this.issubmit = true;
    if (this.employeeCode != undefined || this.employeeCode != null) {
      if (this.isPromotionsOnly == true) {
        let isValid = false;
        this.addPromotionValidators();
        if (this.promotionsForm.valid) {
          this.promotionList.push({
            newsalary: this.promotionsForm.controls.newSalary.value,
            newdescription: this.promotionsForm.controls.newDescription.value,
            effectivedate: this.pipe.transform(
              this.promotionsForm.controls.effectiveDate.value,
              'yyyy-MM-dd'
            ),
            annualsalary: this.promotionsForm.controls.annualSalary.value,
          });
          this.addPromotionValidators();
          this.clearPromotions();
          isValid = true;
        }
        if (isValid == true) {
          this.spinner.show();
          let data = {
            empid: this.employeeCode,
            contractname: this.employeeJobForm.controls.contractName.value,
            notes: this.employeeJobForm.controls.contractNotes.value,
            //fileid: this.employeeJobForm.controls.contractFile.value,
            fileid: null,
            startdate: this.pipe.transform(
              this.employeeJobForm.controls.contractStartDate.value,
              'yyyy-MM-dd'
            ),
            enddate: this.pipe.transform(
              this.employeeJobForm.controls.contractEndDate.value,
              'yyyy-MM-dd'
            ),
            promotions: this.promotionList,
          };
          this.emsService
            .saveEmployeeJobDetailsData(data)
            .subscribe((res: any) => {
              if (res.status && res.data[0].statuscode == 0) {
                this.spinner.hide();
                this.promotionList = [];
                this.getEmployeeJobList();
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.EM42,
                });
                this.selectedtab.setValue(2);
                this.issubmit = false;
              } else {
                this.spinner.hide();
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.EM43,
                });
              }
            });
        }
      } else {
        let isValid = false;
        if (this.employeeJobForm.valid) {
          this.promotionList = [];
          if (
            this.promotionsForm.controls.newSalary.value != '' &&
            this.promotionsForm.controls.newDescription.value != '' &&
            this.promotionsForm.controls.effectiveDate.value != '' &&
            this.promotionsForm.controls.annualSalary.value != ''
          ) {
            this.promotionList.push({
              newsalary: this.promotionsForm.controls.newSalary.value,
              newdescription: this.promotionsForm.controls.newDescription.value,
              effectivedate: this.pipe.transform(
                this.promotionsForm.controls.effectiveDate.value,
                'yyyy-MM-dd'
              ),
              annualsalary: this.promotionsForm.controls.annualSalary.value,
            });
          }

          isValid = true;
        }
        if (isValid == true) {
          this.spinner.show();
          let data = {
            empid: this.employeeCode,
            contractname: this.employeeJobForm.controls.contractName.value,
            notes: this.employeeJobForm.controls.contractNotes.value,
            //fileid: this.employeeJobForm.controls.contractFile.value,
            fileid: null,
            startdate: this.pipe.transform(
              this.employeeJobForm.controls.contractStartDate.value,
              'yyyy-MM-dd'
            ),
            enddate: this.pipe.transform(
              this.employeeJobForm.controls.contractEndDate.value,
              'yyyy-MM-dd'
            ),
            promotions: this.promotionList,
          };
          this.emsService
            .saveEmployeeJobDetailsData(data)
            .subscribe((res: any) => {
              if (res.status && res.data[0].statuscode == 0) {
                this.spinner.hide();
                this.promotionList = [];
                this.clearContract();
                this.getEmployeeJobList();
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.EM42,
                });
                this.selectedtab.setValue(2);
              } else {
                this.spinner.hide();
                let dialogRef = this.dialog.open(ReusableDialogComponent, {
                  position: { top: `70px` },
                  disableClose: true,
                  data: this.EM43,
                });
              }
            });
        }
      }
    } else {
      this.spinner.hide();
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please complete personal details first',
      });
    }
  }
  workExperienceSubmitAdd() {
    this.isSubmitAdd = true;
    this.addWorkExperience();
  }
  //** */
  addWorkExperience() {
    this.addExperienceValidators();
    if (this.experienceForm.valid) {
      if (this.isExperienceEdit) {
        this.isExperienceEdit = false;
        this.workExperienceDetails[this.experienceIndex].companyname =
          this.experienceForm.controls.companyName.value;
        this.workExperienceDetails[this.experienceIndex].designation =
          this.experienceForm.controls.designation.value;
        this.workExperienceDetails[this.experienceIndex].skills =
          this.experienceForm.controls.jobDescription.value;
        (this.workExperienceDetails[this.experienceIndex].fromdate =
          this.pipe.transform(
            this.experienceForm.controls.expFromDate.value,
            'yyyy-MM-dd'
          )),
          (this.workExperienceDetails[this.experienceIndex].todate =
            this.pipe.transform(
              this.experienceForm.controls.expToDate.value,
              'yyyy-MM-dd'
            )),
          this.saveWorkExperience();
      } else {
        if (this.experienceForm.valid) {
          this.workExperienceDetails.push({
            companyname: this.experienceForm.controls.companyName.value,
            fromdate: this.pipe.transform(
              this.experienceForm.controls.expFromDate.value,
              'yyyy-MM-dd'
            ),
            todate: this.pipe.transform(
              this.experienceForm.controls.expToDate.value,
              'yyyy-MM-dd'
            ),
            skills: this.experienceForm.controls.jobDescription.value,
            designation: this.experienceForm.controls.designation.value,
          });
          this.clearExperienceValidators();
          this.clearWorkExperience();
          this.saveWorkExperience();
          //this.workExperienceDataSource = new MatTableDataSource(this.workExperienceDetails);
        } else {
        }
      }
    }
  }

  editExperience(i: any) {
    this.experienceIndex = i;
    this.isExperienceEdit = true;
    this.isUpdate = true;
    this.experienceForm.controls.companyName.setValue(
      this.workExperienceDetails[i].companyname
    );
    this.experienceForm.controls.designation.setValue(
      this.workExperienceDetails[i].designation
    );
    this.experienceForm.controls.jobDescription.setValue(
      this.workExperienceDetails[i].skills
    );
    this.experienceForm.controls.expFromDate.setValue(
      this.workExperienceDetails[i].fromdate
    );
    this.experienceForm.controls.expToDate.setValue(
      this.workExperienceDetails[i].todate
    );
  }
  clearExperienceValidators() {
    this.experienceForm.get('companyName').clearValidators();
    this.experienceForm.get('companyName').updateValueAndValidity();

    this.experienceForm.get('expFromDate').clearValidators();
    this.experienceForm.get('expFromDate').updateValueAndValidity();

    this.experienceForm.get('expToDate').clearValidators();
    this.experienceForm.get('expToDate').updateValueAndValidity();

    this.experienceForm.get('designation').clearValidators();
    this.experienceForm.get('designation').updateValueAndValidity();

    this.experienceForm.get('jobDescription').clearValidators();
    this.experienceForm.get('jobDescription').updateValueAndValidity();
  }

  addExperienceValidators() {
    this.experienceForm.get('companyName').setValidators(Validators.required);
    this.experienceForm.get('companyName').updateValueAndValidity();

    this.experienceForm.get('expFromDate').setValidators(Validators.required);
    this.experienceForm.get('expFromDate').updateValueAndValidity();

    this.experienceForm.get('expToDate').setValidators(Validators.required);
    this.experienceForm.get('expToDate').updateValueAndValidity();

    this.experienceForm.get('designation').setValidators(Validators.required);
    this.experienceForm.get('designation').updateValueAndValidity();
  }

  clearWorkExperience() {
    this.isExperienceEdit = false;
    this.isUpdate = false;
    this.isDelete = false;
    // this.experienceForm.controls.companyName.reset();
    // this.experienceForm.controls.expFromDate.reset();
    // this.experienceForm.controls.expToDate.reset();
    // this.experienceForm.controls.designation.reset();
    // this.experienceForm.controls.jobDescription.reset();
    this.experienceForm.reset();
    this.experienceForm.get('companyName').clearValidators();
    this.experienceForm.get('companyName').updateValueAndValidity();
    this.experienceForm.get('expFromDate').clearValidators();
    this.experienceForm.get('expFromDate').updateValueAndValidity();
    this.experienceForm.get('expToDate').clearValidators();
    this.experienceForm.get('expToDate').updateValueAndValidity();
    this.experienceForm.get('designation').clearValidators();
    this.experienceForm.get('designation').updateValueAndValidity();
  }
  deleteExperiencePopup(event: any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: this.EM61, YES: 'YES', NO: 'NO' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.isDelete = true;
        this.isSubmitAdd = true;
        this.deleteExperience(event);
      }
    });
  }
  deleteExperience(index: any) {
    this.workExperienceDetails.splice(index, 1);
    this.workExperienceDataSource = new MatTableDataSource(
      this.workExperienceDetails
    );
    this.saveWorkExperience();
  }

  /**getFinanceManagersList */
  getFinanceManagersList(flag: any) {
    let data = {
      isfinance: flag,
    };
    this.availableFinanceManagers = [];
    this.emsService.getFinanceManagersList(data).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.availableFinanceManagers = res.data;
      }
    });
  }
  /**getHRManagersList */
  getHRManagersList(flag: any) {
    let data = {
      ishrmanager: flag,
    };
    this.availableHRManagers = [];
    this.emsService.getHRManagersList(data).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.availableHRManagers = res.data;
      }
    });
  }
  saveWorkExperience() {
    if (this.employeeCode != undefined || this.employeeCode != null) {
      if (this.isDelete == true) {
        this.submitExperience();
      } else if (this.workExperienceDetails.length > 0) {
        this.submitExperience();
      }
    } else {
      this.spinner.hide();
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please complete personal details first',
      });
    }
  }

  submitExperience() {
    this.spinner.show();
    let data = {
      empid: this.employeeCode,
      experience: this.workExperienceDetails,
      bankname:
        this.employementForm.controls.bankName.value != null
          ? this.employementForm.controls.bankName.value
          : null,
      ifsccode:
        this.employementForm.controls.ifscCode.value != null
          ? this.employementForm.controls.ifscCode.value
          : null,
      nameasperbankaccount:
        this.employementForm.controls.bankAccountName.value != null
          ? this.employementForm.controls.bankAccountName.value
          : null,
      branchname:
        this.employementForm.controls.branchName.value != null
          ? this.employementForm.controls.branchName.value
          : null,
      bankaccountnumber:
        this.employementForm.controls.bankAccountNumber.value != null
          ? this.employementForm.controls.bankAccountNumber.value
          : null,
      uanumber:
        this.employementForm.controls.uanNumber.value != null
          ? this.employementForm.controls.uanNumber.value
          : null,
      pan:
        this.employementForm.controls.panNumber.value != null
          ? this.employementForm.controls.panNumber.value
          : null,
      esi:
        this.employementForm.controls.esiNumber.value != null
          ? this.employementForm.controls.esiNumber.value
          : null,
    };
    this.emsService.saveEmployeeEmployementData(data).subscribe((res: any) => {
      if (res.status && res.data[0].statuscode == 0) {
        this.spinner.hide();
        this.getEmployeeEmploymentList();
        if (this.isUpdate == false && this.isDelete == false) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EM42,
          });
        } else if (this.isUpdate == true) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Data updated successfully.',
          });
        } else if (this.isDelete == true) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Data deleted successfully.',
          });
        }
        if (this.isSubmitAdd == false) {
          this.selectedtab.setValue(3);
        }
        this.clearExperienceValidators();
        this.clearWorkExperience();
        this.isSubmitAdd == false;
      } else {
        this.spinner.hide();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM43,
        });
      }
    });
  }

  saveBankDetails() {
    if (this.employeeCode != undefined || this.employeeCode != null) {
      if (this.employementForm.valid) {
        this.spinner.show();
        let data = {
          empid: this.employeeCode,
          bankname: this.employementForm.controls.bankName.value,
          ifsccode: this.employementForm.controls.ifscCode.value.toUpperCase(),
          nameasperbankaccount:
            this.employementForm.controls.bankAccountName.value,
          branchname: this.employementForm.controls.branchName.value,
          bankaccountnumber:
            this.employementForm.controls.bankAccountNumber.value,
          uanumber: this.employementForm.controls.uanNumber.value,
          //pfaccountnumber: this.employementForm.controls.contractFile.value,
          pan:
            this.employementForm.controls.panNumber.value != null
              ? this.employementForm.controls.panNumber.value.toUpperCase()
              : null,
          esi: this.employementForm.controls.esiNumber.value,
          experience:
            this.workExperienceDetails.length > 0
              ? this.workExperienceDetails
              : null,
        };
        this.emsService
          .saveEmployeeEmployementData(data)
          .subscribe((res: any) => {
            if (res.status && res.data[0].statuscode == 0) {
              this.spinner.hide();
              this.getEmployeeEmploymentList();
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: this.EM42,
              });
              this.selectedtab.setValue(4);
            } else {
              this.spinner.hide();
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: this.EM43,
              });
            }
          });
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please complete personal details first',
      });
    }
  }

  //** */
  saveEducation() {
    if (this.employeeCode != undefined || this.employeeCode != null) {
      if (this.isDelete == true) {
        this.submitEducation();
      } else if (this.educationForm.valid) {
        this.submitEducation();
      }
    } else {
      this.spinner.hide();
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Please complete personal details first',
      });
    }
  }

  submitEducation() {
    this.spinner.show();
    let data = {
      empid: this.employeeCode,
      education: this.educationDetails,
    };

    this.emsService.saveEmployeeEducationData(data).subscribe((res: any) => {
      if (res.status && res.data[0].statuscode == 0) {
        this.getEmployeeEducationList();
        if (this.isUpdate == false && this.isDelete == false) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EM42,
          });
        } else if (this.isUpdate == true) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Data updated successfully.',
          });
        } else if (this.isDelete == true) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Data deleted successfully.',
          });
        }
        this.spinner.hide();
        if (this.isSubmitAdd == false) {
          this.selectedtab.setValue(5);
        }
        this.clearEducationValidators();
        this.clearEducation();
        this.isSubmitAdd = false;
      } else {
        this.spinner.hide();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM43,
        });
      }
    });
  }

  educationSaveAdd() {
    this.isSubmitAdd = true;
    this.addEducation();
  }

  addEducation() {
    this.addEducationValidators();
    if (this.educationForm.valid) {
      if (this.isEducationEdit) {
        this.isEducationEdit = false;
        this.educationDetails[this.educationIndex].course =
          this.educationForm.controls.course.value;
        this.educationDetails[this.educationIndex].institutename =
          this.educationForm.controls.instituteName.value;
        (this.educationDetails[this.educationIndex].fromdate =
          this.pipe.transform(
            this.educationForm.controls.eduFromDate.value,
            'yyyy-MM-dd'
          )),
          (this.educationDetails[this.educationIndex].todate =
            this.pipe.transform(
              this.educationForm.controls.eduToDate.value,
              'yyyy-MM-dd'
            )),
          this.saveEducation();
      } else {
        if (this.educationForm.valid) {
          this.educationDetails.push({
            course: this.educationForm.controls.course.value,
            institutename: this.educationForm.controls.instituteName.value,
            fromdate: this.pipe.transform(
              this.educationForm.controls.eduFromDate.value,
              'yyyy-MM-dd'
            ),
            todate: this.pipe.transform(
              this.educationForm.controls.eduToDate.value,
              'yyyy-MM-dd'
            ),
          });
          // this.educationDataSource = new MatTableDataSource(this.educationDetails);
          this.clearEducationValidators();
          this.clearEducation();
          this.saveEducation();
        }
      }
    }
  }
  editEduction(i: any) {
    this.educationIndex = i;
    this.isEducationEdit = true;
    this.isUpdate = true;
    this.educationForm.controls.course.setValue(
      this.educationDetails[i].course
    );
    this.educationForm.controls.instituteName.setValue(
      this.educationDetails[i].institutename
    );
    this.educationForm.controls.eduFromDate.setValue(
      this.educationDetails[i].fromdate
    );
    this.educationForm.controls.eduToDate.setValue(
      this.educationDetails[i].todate
    );
  }
  clearEducationValidators() {
    this.educationForm.get('course').clearValidators();
    this.educationForm.get('course').updateValueAndValidity();

    this.educationForm.get('instituteName').clearValidators();
    this.educationForm.get('instituteName').updateValueAndValidity();

    this.educationForm.get('eduFromDate').clearValidators();
    this.educationForm.get('eduFromDate').updateValueAndValidity();

    this.educationForm.get('eduToDate').clearValidators();
    this.educationForm.get('eduToDate').updateValueAndValidity();
  }

  addEducationValidators() {
    this.educationForm.get('course').setValidators(Validators.required);
    this.educationForm.get('course').updateValueAndValidity();

    this.educationForm.get('instituteName').setValidators(Validators.required);
    this.educationForm.get('instituteName').updateValueAndValidity();

    this.educationForm.get('eduFromDate').setValidators(Validators.required);
    this.educationForm.get('eduFromDate').updateValueAndValidity();

    this.educationForm.get('eduToDate').setValidators(Validators.required);
    this.educationForm.get('eduToDate').updateValueAndValidity();
  }
  clearEducation() {
    this.isEducationEdit = false;
    this.isUpdate = false;
    this.isDelete = false;
    // this.educationForm.controls.course.reset();
    // this.educationForm.controls.instituteName.reset();
    // this.educationForm.controls.eduFromDate.reset();
    // this.educationForm.controls.eduToDate.reset();
    this.educationForm.reset();
    this.educationForm.get('course').clearValidators();
    this.educationForm.get('course').updateValueAndValidity();
    this.educationForm.get('instituteName').clearValidators();
    this.educationForm.get('instituteName').updateValueAndValidity();
    this.educationForm.get('eduFromDate').clearValidators();
    this.educationForm.get('eduFromDate').updateValueAndValidity();
    this.educationForm.get('eduToDate').clearValidators();
    this.educationForm.get('eduToDate').updateValueAndValidity();
  }
  deleteEducationPopup(event: any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: this.EM61, YES: 'YES', NO: 'NO' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.isDelete = true;
        this.isSubmitAdd = true;
        this.deleteEducation(event);
      }
    });
  }
  deleteEducation(index: any) {
    this.educationDetails.splice(index, 1);
    this.educationDataSource = new MatTableDataSource(this.educationDetails);
    this.saveEducation();
  }

  open(
    errormessages: any,
    top: any,
    width: any,
    height: any,
    flag: any,
    url: any
  ) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      position: { top: `70px` },
      data: { Message: errormessages, flag: flag, url: url },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  tabClick(event: any) {
    if (event.index == 0) {
      this.selectedtab.setValue(0);
    } else if (event.index == 1) {
      this.selectedtab.setValue(1);
    } else if (event.index == 2) {
      this.selectedtab.setValue(2);
    } else if (event.index == 3) {
      this.selectedtab.setValue(3);
    } else if (event.index == 4) {
      this.selectedtab.setValue(4);
    }
  }

  getMessagesList() {
    let data = {
      code: null,
      pagenumber: 1,
      pagesize: 100,
    };
    this.emsService.getMessagesListApi(data).subscribe((res: any) => {
      if (res.status) {
        this.messagesDataList = res.data;
        this.messagesDataList.forEach((e: any) => {
          if (e.code == 'EM15') {
            this.EM15 = e.message;
          } else if (e.code == 'EM11') {
            this.EM11 = e.message;
          } else if (e.code == 'EM12') {
            this.EM12 = e.message;
          } else if (e.code == 'EM13') {
            this.EM13 = e.message;
          } else if (e.code == 'EM14') {
            this.EM14 = e.message;
          } else if (e.code == 'EM16') {
            this.EM16 = e.message;
          } else if (e.code == 'EM17') {
            this.EM17 = e.message;
          } else if (e.code == 'EM18') {
            this.EM18 = e.message;
          } else if (e.code == 'EM19') {
            this.EM19 = e.message;
          } else if (e.code == 'EM20') {
            this.EM20 = e.message;
          } else if (e.code == 'EM21') {
            this.EM21 = e.message;
          } else if (e.code == 'EM1') {
            this.EM1 = e.message;
          } else if (e.code == 'EM22') {
            this.EM22 = e.message;
          } else if (e.code == 'EM2') {
            this.EM2 = e.message;
          } else if (e.code == 'EM61') {
            this.EM61 = e.message;
          } else if (e.code == 'EM42') {
            this.EM42 = e.message;
          } else if (e.code == 'EM43') {
            this.EM43 = e.message;
          } else if (e.code == 'EM62') {
            this.EM62 = e.message;
          } else if (e.code == 'EM63') {
            this.EM63 = e.message;
          }
        });
      } else {
        this.messagesDataList = [];
      }
    });
  }
  getstatuslist() {
    this.companyService.getstatuslists().subscribe((result: any) => {
      if (result.status) {
        this.statusList = result.data;
      }
    });
  }

  getDocumentsEMS() {
    let input = {
      employeeId: this.employeeId == '' ? null : this.employeeId,
      candidateId: this.candidateId ?? null,
      moduleId: 1,
      filecategory: null,
      requestId: null,
      status: null,
    };
    this.mainService.getDocumentsFiles(input).subscribe((result: any) => {
      this.documentDetails = [];
      if (result && result.status) {
        this.documentDetails = result.data;
        this.documentDataSource = new MatTableDataSource(this.documentDetails);
      }
    });
  }

  editDock(data: any) {
    this.isedit = true;
    this.editFileName = data.fname;
    this.editDockinfo = JSON.stringify(data);
    this.documentsForm.controls.documentId.setValue(data.id, {
      emitEvent: false,
    });
    this.documentsForm.controls.documentName.setValue(data.file_category, {
      emitEvent: false,
    });
    this.documentsForm.controls.documentNumber.setValue(data.document_number, {
      emitEvent: false,
    });
  }

  deleteDock(data: any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: this.EM16, YES: 'YES', NO: 'NO' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'YES') {
        this.mainService.deleteFilesMaster(data.id).subscribe((res: any) => {
          if (res && res.status) {
            var info = JSON.stringify(data);
            this.mainService
              .removeDocumentOrImagesForEMS(info)
              .subscribe((result: any) => {});
            // this.mainService.removeDocumentOrImagesForEMS(data).subscribe(result => {})
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM15,
            });
            this.getDocumentsEMS();
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM17,
            });
          }
        });
      }
    });
  }

  fileView(data: any) {
    let info = data;
    this.spinner.show();
    this.mainService.getDocumentOrImagesForEMS(info).subscribe((imageData) => {
      if (imageData.success) {
        this.spinner.hide();

        let TYPED_ARRAY = new Uint8Array(imageData.image.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        var documentName = data.fname.split('.');

        if (documentName[documentName.length - 1] == 'pdf') {
          const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
          this.fileURL = URL.createObjectURL(file);
          window.open(this.fileURL);
        } else {
          this.fileURL = new Blob([TYPED_ARRAY], { type: 'image/png' });
          let url = URL.createObjectURL(this.fileURL);
          window.open(url, '_blank');
        }
      }
    });
    this.createValidatorForDocument();
    this.clearDock();
  }

  validateDocument() {
    this.createValidatorForDocument();
    if (this.documentsForm.valid) {
      if (this.documentsForm.controls.attachedFile.value || this.editDockinfo) {
        if (this.isFile) {
          var valid = true;
          var ReplaceDocument: any;
          if (this.documentDetails.length != 0 && !this.editDockinfo) {
            for (let i = 0; i < this.documentDetails.length; i++) {
              if (
                this.documentsForm.controls.documentName.value ==
                this.documentDetails[i].file_category
              ) {
                valid = false;
                ReplaceDocument = this.documentDetails[i];
                break;
              }
            }
          }
          if (!valid) {
            let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: {
                message: ReplaceDocument.description + ' ' + this.EM21,
                YES: 'YES',
                NO: 'NO',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result == 'YES') {
                this.documentsForm.controls.documentId.setValue(
                  ReplaceDocument.id,
                  { emitEvent: false }
                );
                this.editDockinfo = JSON.stringify(ReplaceDocument);
                this.saveDocument();
              }
            });
          } else {
            this.saveDocument();
          }
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EM13,
          });
        }
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM18,
        });
      }
    }
  }

  saveDocument() {
    // if(this.documentsForm.controls.attachedFile.value || this.editDockinfo){
    // if(this.isFile){
    //   if(this.validateDocument()){
    this.spinner.show();
    this.mainService.getFilepathsMasterForEMS(1).subscribe((resultData) => {
      if (resultData && resultData.status) {
        let obj = {
          id: this.documentsForm.controls.documentId.value
            ? this.documentsForm.controls.documentId.value
            : null,
          employeeId: this.employeeId,
          candidateId: 0,
          filecategory: this.documentsForm.controls.documentName.value,
          moduleId: 1,
          documentnumber: this.documentsForm.controls.documentNumber.value,
          fileName: this.file ? this.file.name : this.editFileName,
          modulecode: resultData.data[0].module_code,
          requestId: null,
          status: 'Approved',
        };
        this.mainService.setFilesMasterForEMS(obj).subscribe((data) => {
          if (data && data.status) {
            this.formData = new FormData();
            if (obj.fileName != this.editFileName) {
              let info = JSON.stringify(data.data[0]);
              this.formData.append('file', this.file, this.file.name);
              this.formData.append('info', info);
              this.formData.append('data', obj.status);
              this.mainService
                .setDocumentOrImageForEMS(this.formData)
                .subscribe((data) => {
                  // this.spinner.hide()
                  this.formData.delete('file');
                  this.formData.delete('info');
                  if (data && data.status) {
                    if (this.editDockinfo) {
                      this.mainService
                        .removeDocumentOrImagesForEMS(this.editDockinfo)
                        .subscribe((data) => {});
                    }
                    let dialogRef = this.dialog.open(ReusableDialogComponent, {
                      position: { top: `70px` },
                      disableClose: true,
                      data: this.EM11,
                    });
                    this.spinner.hide();
                    this.getDocumentsEMS();
                    this.clearDock();
                    this.selectedtab.setValue(0);
                  } else {
                    this.spinner.hide();
                    let dialogRef = this.dialog.open(ReusableDialogComponent, {
                      position: { top: `70px` },
                      disableClose: true,
                      data: this.EM12,
                    });
                    // this.open(result.isLeaveUpdated ? this.msgLM76 : this.msgLM79,'8%','500px','250px',false,"/LeaveManagement/UserDashboard")
                  }
                  this.file = null;

                  this.editDockinfo = null;
                  this.editFileName = null;
                });
            } else {
              this.spinner.hide();
              this.getDocumentsEMS();
              this.clearDock();
              this.editDockinfo = null;
              this.editFileName = null;
              this.file = null;
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: this.EM19,
              });
            }
          } else {
            this.spinner.hide();
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: this.EM17,
            });
          }
        });
        this.spinner.hide();
      }
    });
    //   }
    //   }else{
    //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //       position: {top: `70px`},
    //       disableClose: true,
    //       data: this.EM13
    //     });
    //   }
    // }
    // else{
    //   let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //     position: {top: `70px`},
    //     disableClose: true,
    //     data: this.EM18
    //   });
    // }
  }

  onSelectFile(event: any) {
    if (event.target.files.length != 0) {
      if (event.target.files[0].size <= 2097152) {
        this.file = event.target.files[0];
        var pdf = this.file.name.split('.');
        if (
          pdf[pdf.length - 1] == 'pdf' ||
          pdf[pdf.length - 1] == 'jpg' ||
          pdf[pdf.length - 1] == 'png'
        ) {
          this.isFile = true;
        } else {
          this.isFile = false;
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: this.EM13,
          });
          // this.open(this.msgLM141,'8%','500px','250px',false,"/LeaveManagement/LeaveRequest")
        }
      } else {
        this.isFile = false;
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: this.EM14,
        });
      }
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.EM18,
      });
      // th
    }
  }
  getFilecategoryMasterForEMS() {
    let input = {
      id: null,
      moduleId: 1,
    };
    this.mainService
      .getFilecategoryMasterForEMS(input)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.documentTypeList = [];
          for (let i = 0; i < result.data.length; i++) {
            if (result.data[i].category != 'PROFILE') {
              this.documentTypeList.push(result.data[i]);
            }
          }
        }
      });
  }

  clearDock() {
    // this.documentsForm.resetForm({resetType:ResetFormType.ControlsOnly})
    this.editFileName = '';
    this.isedit = false;
    this.documentsForm.reset();
    this.documentsForm.get('documentName').clearValidators();
    this.documentsForm.get('documentName').updateValueAndValidity();
    this.documentsForm.get('documentNumber').clearValidators();
    this.documentsForm.get('documentNumber').updateValueAndValidity();
    this.documentsForm.get('documentId').clearValidators();
    this.documentsForm.get('documentId').updateValueAndValidity();
    this.documentsForm.get('attachedFile').clearValidators();
    this.documentsForm.get('attachedFile').updateValueAndValidity();
  }
  deleteIcon() {
    this.isedit = false;
    this.documentsForm.controls.attachedFile.setValue('');
  }
  delete() {
    this.isedit = false;
  }
  createValidatorForDocument() {
    this.documentsForm.controls.documentNumber.setValidators([
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(30),
    ]);
    this.documentsForm.controls.documentName.setValidators([
      Validators.required,
    ]);
    this.documentsForm.get('documentNumber').updateValueAndValidity();
    this.documentsForm.get('documentName').updateValueAndValidity();
  }
  educationClear() {}
  workClear() {}
  jobClear() {}

  getEmployeeImage() {
    let input = {
      employeeId: this.employeeId,
      candidateId: null,
      moduleId: 1,
      filecategory: 'PROFILE',
      requestId: null,
      status: 'Submitted',
    };
    this.mainService.getDocumentsForEMS(input).subscribe((result: any) => {
      if (result && result.status && result.data.length > 0) {
        this.profileId = result.data[0].id;
        this.profileInfo = JSON.stringify(result.data[0]);
        this.mainService
          .getDocumentOrImagesForEMS(result.data[0])
          .subscribe((imageData) => {
            if (imageData.success) {
              let TYPED_ARRAY = new Uint8Array(imageData.image.data);
              const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
                return data + String.fromCharCode(byte);
              }, '');
              let base64String = btoa(STRING_CHAR);
              this.imageurls[0].base64String =
                'data:image/png;base64,' + base64String;
            } else {
              this.isRemoveImage = false;
              this.imageurls = [{ base64String: 'assets/img/profile.jpg' }];
            }
          });
      }
    });
  }

  addPersonalInfoValidators() {
    this.personalInfoForm.get('firstname').setValidators(Validators.required);
    this.personalInfoForm.get('firstname').updateValueAndValidity();

    this.personalInfoForm.get('lastname').setValidators(Validators.required);
    this.personalInfoForm.get('lastname').updateValueAndValidity();

    this.personalInfoForm.get('dateofbirth').setValidators(Validators.required);
    this.personalInfoForm.get('dateofbirth').updateValueAndValidity();

    this.personalInfoForm.get('gender').setValidators(Validators.required);
    this.personalInfoForm.get('gender').updateValueAndValidity();

    this.personalInfoForm
      .get('maritalstatus')
      .setValidators(Validators.required);
    this.personalInfoForm.get('maritalstatus').updateValueAndValidity();

    this.personalInfoForm.get('mobileNo').setValidators(Validators.required);
    this.personalInfoForm.get('mobileNo').updateValueAndValidity();

    this.personalInfoForm
      .get('alternateMobileNo')
      .setValidators(Validators.required);
    this.personalInfoForm.get('alternateMobileNo').updateValueAndValidity();

    this.personalInfoForm
      .get('employmentType')
      .setValidators(Validators.required);
    this.personalInfoForm.get('employmentType').updateValueAndValidity();

    this.personalInfoForm.get('usertype').setValidators(Validators.required);
    this.personalInfoForm.get('usertype').updateValueAndValidity();

    this.personalInfoForm
      .get('companylocation')
      .setValidators(Validators.required);
    this.personalInfoForm.get('companylocation').updateValueAndValidity();

    this.personalInfoForm.get('designation').setValidators(Validators.required);
    this.personalInfoForm.get('designation').updateValueAndValidity();

    this.personalInfoForm.get('department').setValidators(Validators.required);
    this.personalInfoForm.get('department').updateValueAndValidity();

    this.personalInfoForm
      .get('reportingmanager')
      .setValidators(Validators.required);
    this.personalInfoForm.get('reportingmanager').updateValueAndValidity();

    // this.personalInfoForm.get("fmanager").setValidators(Validators.required);
    // this.personalInfoForm.get("fmanager").updateValueAndValidity();

    // this.personalInfoForm.get("hrmanager").setValidators(Validators.required);
    // this.personalInfoForm.get("hrmanager").updateValueAndValidity();

    this.personalInfoForm.get('raddress').setValidators(Validators.required);
    this.personalInfoForm.get('raddress').updateValueAndValidity();

    this.personalInfoForm.get('rcountry').setValidators(Validators.required);
    this.personalInfoForm.get('rcountry').updateValueAndValidity();

    this.personalInfoForm.get('rstate').setValidators(Validators.required);
    this.personalInfoForm.get('rstate').updateValueAndValidity();

    this.personalInfoForm.get('rcity').setValidators(Validators.required);
    this.personalInfoForm.get('rcity').updateValueAndValidity();

    this.personalInfoForm.get('rpincode').setValidators(Validators.required);
    this.personalInfoForm.get('rpincode').updateValueAndValidity();
  }

  backArrow() {
    if (this.isNewire == true) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/ems/new-hired-list']));
    } else {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate(['/ems/employeeDirectory']));
    }
  }
  getCompanyInformation() {
    this.companyService
      .getCompanyInformation(
        'companyinformation',
        null,
        1,
        10,
        this.companyDBName
      )
      .subscribe((data: any) => {
        if (data.status && data.data.length != 0) {
          this.minJoinDate = new Date(data.data[0].established_date);
        }
      });
  }
  openedSearch(e: any) {
    this.searchTextboxControl.patchValue('');
  }

  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }
  compareFn(option1: any, option2: any) {
    return option1.id === option2.id;
  }

  selectedRolesChange(event: any) {
    if (event.isUserInput && event.source.selected == false) {
      if (
        event.source.value.id == 2 ||
        event.source.value.id == 3 ||
        event.source.value.id == 4 ||
        event.source.value.id == 6 ||
        event.source.value.id == 7 ||
        event.source.value.id == 9 ||
        event.source.value.id == 10
      ) {
        this.isself = false;
      } else if (this.selectedRoles.length == 0) {
        this.isself = false;
      } else {
        this.isself = true;
      }
      this.selectedRoles.forEach((value: { id: any }, index: any) => {
        if (value.id == event.source.value.id)
          this.selectedRoles.splice(index, 1);
      });
    } else {
      if (this.selectedRoles.length == 0) {
        this.isself = false;
      }
      const toSelect = this.selectedRoles.find(
        (e: any) => e.id == event.source.value.id
      );
      if (toSelect == undefined) {
        this.selectedRoles.push({ id: event.source.value.id });
        if (
          event.source.value.id == 2 ||
          event.source.value.id == 3 ||
          event.source.value.id == 4 ||
          event.source.value.id == 6 ||
          event.source.value.id == 7 ||
          event.source.value.id == 9 ||
          event.source.value.id == 10
        ) {
          this.isself = true;
        }
      }
    }
    let arr = this.selectedRoles.filter((v: any) => v.id !== 1);
    if (arr.length > 0) {
      this.selectedUserTypeRoles = arr;
    } else {
      this.selectedUserTypeRoles = [];
      this.selectedUserTypeRoles.push({ id: 1 });
    }
  }

  onChangeRM(event: any) {
    const selectedManagerId = event.value;
    const selectedManager = this.availablereportingmanagers.find(
      (manager) => manager.id === selectedManagerId
    );
    if (selectedManager) {
      this.reportingManagerName = selectedManager.employee_name;
    }
  }

  onSelectFile2(event: any) {
    this.isRemoveImage = false;
    this.empImageurls = null;
    this.empFile = null;
    this.empFile = event.target.files[0];
    let uploadeddata = this.empFile.type.split('/');
    if (this.empFile && uploadeddata[0] == 'image') {
      if (this.empFile.size <= 1024000) {
        this.fileImageToggler();
        if (event.target.files && event.target.files[0]) {
          // this.empImageurls = null;
          this.validpic = true;
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.empImageurls = event.target.result;
            };
            reader.readAsDataURL(event.target.files[i]);
          }
        }
      } else {
        this.dialog.open(ConfirmationComponent, {
          position: { top: `70px` },
          disableClose: true,
          // data:{Message:this.LM117,}
        });
        this.getUploadImage();
      }
    } else {
      this.getUploadImage();
      this.validpic = false;
      this.dialog.open(ConfirmationComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: {
          Message: 'Please upload a valid Image.',
          url: '/LeaveManagement/EditProfile',
        },
      });
    }
  }
  getUploadImage() {
    let info = {
      employeeId: this.employeeId,
      candidateId: null,
      filecategory: 'PROFILE',
      moduleId: 1,
      requestId: null,
      status: 'Submitted',
    };
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data[0]) {
        this.profileId = result.data[0].id;
        this.profileInfo = JSON.stringify(result.data[0]);
        result.data[0].employeeId = this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');

            let base64String = btoa(STRING_CHAR);
            this.empImageurls = 'data:image/png;base64,' + base64String;
          } else {
            this.isRemoveImage = false;
            this.empImageurls = null;
          }
        });
      } else {
        this.isRemoveImage = false;
        this.empImageurls = null;
      }
    });
  }
  fileImageToggler() {
    this.isFileImage = !this.isFileImage;
  }

  filterOptions(): void {
    // this.filteredBloodGroups = this.bloodGroups.filter(group =>
    //   group.bloodgroup.toLowerCase().includes(this.searchTerm.toLowerCase())
    // );
  }
  filterReportingManagers(searchText: any) {
    this.filteredReportingManagers = this.availablereportingmanagers.filter(
      (val: any) =>
        val.employee_name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredReportingManagers.length <= 0) {
      this.searchReportingManager.setValue('');
    }
  }
  filterEmpTypes(searchText: any) {
    this.filteredEmpTypes = this.EmploymentTypeDetails.filter((val: any) =>
      val.employmenttype.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmpTypes.length <= 0) {
      this.searchControl.setValue('');
    }
  }
  filterRoles(searchText: any) {
    this.filteredRoles = this.availableRole.filter((val: any) =>
      val.name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredRoles.length <= 0) {
      this.searchControlforRole.setValue('');
    }
  }
  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter(
      (val: any) =>
        val.location.toLowerCase().includes(searchText.toLowerCase()) ||
        val.cityname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforloc.setValue('');
    }
  }
  filterDesignations(searchText: any) {
    this.filteredDesignations = this.availableDesignations.filter((val: any) =>
      val.designation.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDesignations.length <= 0) {
      this.searchControlforDesignation.setValue('');
    }
  }
  filterDep(searchText: any) {
    this.filteredDep = this.availableDepartments.filter((val: any) =>
      val.deptname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredDep.length <= 0) {
      this.searchControlforDep.setValue('');
    }
  }
  filterRMDep(searchText: any) {
    this.filteredRMDep = this.departmentsForReportingManagers.filter(
      (val: any) =>
        val.deptname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredRMDep.length <= 0) {
      this.searchControlforRMDep.setValue('');
    }
  }
  filterRcountry(searchText: any) {
    this.filteredRCountryList = this.countryDetails.filter((val: any) =>
      val.country.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredRCountryList.length <= 0) {
      this.searchControlforRCoun.setValue('');
    }
  }
  filterPcountry(searchText: any) {
    this.filteredPCountryList = this.permanentCountryDetails.filter(
      (val: any) => val.country.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredPCountryList.length <= 0) {
      this.searchControlforPCoun.setValue('');
    }
  }
  filterRStates(searchText: any) {
    this.filteredRStates = this.stateDetails.filter((val: any) =>
      val.state.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredRStates.length <= 0) {
      this.searchControlforRState.setValue('');
    }
  }
  filterPStates(searchText: any) {
    this.filteredPStates = this.stateDetails.filter((val: any) =>
      val.state.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredPStates.length <= 0) {
      this.searchControlforPState.setValue('');
    }
  }
  filterRCity(searchText: any) {
    this.filteredRcity = this.cityDetails.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredRcity.length <= 0) {
      this.searchControlforRCity.setValue('');
    }
  }
  filterPCity(searchText: any) {
    this.filteredPcity = this.cityDetails.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredPcity.length <= 0) {
      this.searchControlforPCity.setValue('');
    }
  }
  addNew(item: any) {
    let department = this.personalInfoForm.controls.department.value;
    const dialogRef = this.dialog.open(AddNewDialogComponent, {
      width: '800px',
      position: { top: `70px` },
      data: { addTo: item, ismappingEnable: this.ismappingEnable },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Designation') {
        this.getDesignationsMaster();
      } else if (result === 'Department') {
        this.getDepartmentsMaster();
      }
    });
  }
}
