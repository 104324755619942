import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReportsService } from '../../reports.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
// import {default as _rollupMoment} from 'moment';
const moment = _moment;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
import { AdminShiftEmployeeDialogComponent } from '../admin-shift-employee-dialog/admin-shift-employee-dialog.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin-shiftwise-emp-report',
  templateUrl: './admin-shiftwise-emp-report.component.html',
  styleUrls: ['./admin-shiftwise-emp-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminShiftwiseEmpReportComponent implements OnInit {
  companyName: any;
  constructor(public reportsService: ReportsService, public datePipe: DatePipe,
    public formBuilder: UntypedFormBuilder, public dialog: MatDialog, private router: Router,
    private companyService: CompanySettingService, private reportService: ReportsService, private spinner: NgxSpinnerService) {
    this.companyName = sessionStorage.getItem("companyName");
  }

  List: any[] = [
  ];
  employeelist: any;
  Users: any;
  minDate = new Date('2020/01/01'); maxDate = new Date();
  userSession: any;
  headersList: any = [];
  @ViewChild('table') table!: ElementRef;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  filter = new UntypedFormControl();
  shiftReportForm: any = UntypedFormGroup;

  shiftDataList: any = [];
  employeesList: any = [];
  headerlist: any = [];
  rmId: any;

  worklocationDetails: any[] = [];
  managersDetails: any[] = [];
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIshide: boolean = false;
  mgrIschecked: boolean = false;
  selectedLocations: any = [];
  selectedManagers: any = [];
  searchControlforLoc = new FormControl('');
  searchControlforRM = new FormControl('');
  filteredLocations: any = [];
  filteredManagers: any = [];

  ngOnInit() {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.rmId = this.userSession.id;
    this.shiftReportForm = this.formBuilder.group(
      {
        companylocation: ["", Validators.required],
        managers: ["", Validators.required],
        fromDate: [new Date()],
        toDate: [new Date()],

      });
    this.getWorkLocation();
    this.searchControlforLoc.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });
    this.searchControlforRM.valueChanges.subscribe(searchText => {
      this.filterRM(searchText);
    });
  }
  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.shiftReportForm.controls.companylocation.setValue(array)
    let locations = this.shiftReportForm.controls.companylocation.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.shiftReportForm.controls.companylocation.setValue('');
    this.selectedLocations = [];
    this.managersDetails = [];
  }

  managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.shiftReportForm.controls.managers.setValue(array)
    let managerdata = this.shiftReportForm.controls.managers.value;
    managerdata.forEach((e: any) => {
      this.selectedManagers.push(Number(e.manager_emp_id));
    });
  }

  managerDeselectAll(select: MatSelect) {
    this.mgrIshide = false;
    this.mgrIschecked = false
    this.selectedManagers = [];
    select.value = [];
    this.shiftReportForm.controls.managers.setValue('');
    this.selectedManagers = []
  }

  getWorkLocation() {
    this.managersDetails = [];
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails;
    })
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.shiftReportForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getManagersList();
  }

  getManagersList() {
    this.managersDetails = [];
    let data = {
      location_id: JSON.stringify(this.selectedLocations),
      companyName: this.companyName
    }
    this.reportService.getManagersListByLocation(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        this.managersDetails = res.data;
        this.filteredManagers = this.managersDetails;
      }
    })

  }

  onchangeManager(value: any) {
    this.selectedManagers = [];
    let location = this.shiftReportForm.controls.managers.value;
    location.forEach((e: any) => {
      this.selectedManagers.push(e.manager_emp_id);
    });
  }

  Searchform() {
    if (this.shiftReportForm.valid) {
      this.getShiftWiseReportList();
    }
  }

  getShiftWiseReportList() {
    this.shiftDataList = [];
    let fromDate = this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "y-MM-d");
    let toDate = this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "y-MM-d");
    let data = {
      "rm_id_value": JSON.stringify(this.selectedManagers),
      "from_date_value": fromDate,
      "to_date_value": toDate,
      "location_id": JSON.stringify(this.selectedLocations),
    }
    this.spinner.show();
    this.reportService.getBranchWiseShiftsWiseEmployeesCountsByDates(data).subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.headersList.push(this.shiftDataList[0]);
        this.headersList.slice(1);
        this.spinner.hide();
      } else {
        this.shiftDataList = [];
      }
    })
  }

  getObjectKeys(obj: any): string[] { return Object.keys(obj); }

  view(data: any) {
    let givenData = {
      // "rm_id_value":this.rmId,
      "rm_id_value": JSON.stringify(this.selectedManagers),
      'location_id': JSON.stringify(this.selectedLocations),
      "date_value": data,
    }
    let dialogRef = this.dialog.open(AdminShiftEmployeeDialogComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });

  }


  resetform() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Attendance/AdminShiftsWiseReport"]));
  }

  removeLastColumn(table: HTMLTableElement): void {
    const rows = table.querySelectorAll('tr');
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td, th');
      if (cells.length > 0) {
        const lastCell = cells[cells.length - 1];
        row.removeChild(lastCell);
      }
    });
  }


  public exportPDF(): void {
    let fdate = (this.datePipe.transform(this.shiftReportForm.controls.fromDate.value, "dd-MM-YYYY"))
    let tdate = (this.datePipe.transform(this.shiftReportForm.controls.toDate.value, "dd-MM-YYYY"))
    const pdfTable = this.table.nativeElement;
    this.removeLastColumn(pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: fdate + " " + " " + "To" + " " + tdate + "-" + "Shift Report",
        author: 'Sreeb tech',
        subject: 'Theme',
        keywords: 'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: fdate + " " + " " + "To" + " " + tdate + " - " + "Shift Wise Report \n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 14
        },
        html,

      ], styles: {
        'html-table': {
          background: 'white' // it will add a yellow background to all <STRONG> elements
        }
      },
      pageOrientation: 'landscape',
      pageSize: 'A4'
      //'portrait'
    }).download("Shift Wise Report.pdf");
    this.Searchform();
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.cityname.toLowerCase().includes(searchText.toLowerCase()) || val.location.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControlforLoc.setValue('');
    }
  }
  filterRM(searchText: any) {
    this.filteredManagers = this.managersDetails.filter((val: any) =>
      val.manager_name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredManagers.length <= 0) {
      this.searchControlforRM.setValue('');
    }
  }
}
