<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <div fxLayout="row" fxFlex="50">
                <mat-card-title class="title"> Users Login </mat-card-title>
            </div>
        </mat-card-header>

        <div class="tableP">
            <form [formGroup]="usersloginForm" autocomplete="off">
                <div fxLayout="row" fxLayout.lt-lg="row wrap" fxLayout.xs="column">
                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <input formControlName="empname" readonly matInput placeholder="">
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Official Email ID</mat-label>
                        <input formControlName="email" readonly matInput placeholder="">
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
                        <mat-label>User ID</mat-label>
                        <input formControlName="userid" appNoSpace appNoConsecutiveSpecialChars matInput placeholder=""
                            required minlength="3" maxlength="50">
                        <mat-error class="con-error" *ngIf="usersloginForm.controls.userid.errors?.required">
                            This field is required.
                        </mat-error>

                        <mat-error class="reg-error" *ngIf="
                     usersloginForm.controls.userid.errors?.minlength ||
                     usersloginForm.controls.userid.errors?.maxlength "> Minimum 3 characters and max 50 characters
                        </mat-error>
                    </mat-form-field>




                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Password</mat-label>
                        <input formControlName="password" required
                            pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#\$&*~])(?=.*[A-Z]).{8,20}" matInput
                            placeholder="">
                        <mat-error class="con-error" *ngIf="usersloginForm.controls['password'].invalid">
                            One lower case letter.One UPPER case letter. <br>
                            One Number.
                            One special character (! "&#64;" ^ $ % or a space).<br>
                            Minimum 8 characters in length (20 characters max).
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="48" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Status</mat-label>
                        <input formControlName="status" readonly matInput placeholder="">
                        <!-- <mat-select  formControlName="status" >
                    <ng-container>
                        <mat-option value="Active">Active</mat-option>
                        <mat-option value="Inactive">Inactive</mat-option>

                    </ng-container>
                  </mat-select> -->

                    </mat-form-field>

                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" type="submit" (click)="submit()">
                        <span>Submit</span>
                    </button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="close()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </form>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column">
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end"
                style="height: 50px;padding-right: 2%;padding-top: 1%;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100%" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <div class="tablePadding">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>
                        <ng-container matColumnDef="empname">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{row.empname}} </span>

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Official Email </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{row.officeemail}} </span>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="userid">
                            <th mat-header-cell *matHeaderCellDef> User ID </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{row.login}} </span>

                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
                        <td mat-cell *matCellDef="let row" >
                            <span>{{row.status}} </span>

                        </td>
                    </ng-container> -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button title="Edit" (click)="edit($event,row)"
                                    *ngIf="row.status!='Inactive' && row.login == null || '' ">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>