import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
  private mapInitialized: boolean = false;
  initMap(): Promise<void> {
    if (!this.mapInitialized) {
      return new Promise<void>((resolve, reject) => {
        // Define the callback function
        (window as any)['initMap'] = () => {
          this.mapInitialized = true;
          resolve();
        };
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.userSession.google_map_apikey}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          // The API script has loaded, but the callback may not have been called yet
          if (!this.mapInitialized) {
            reject('Failed to initialize the map');
          }
        };
        script.onerror = (error: Event | string) => reject(error);
        document.head.appendChild(script);
      });
    } else {
      return Promise.resolve();
    }
  }
  constructor() { }
}
