<div fxLayout="column" fxFlex fxLayoutAlign="start" style="padding: 0%;margin: auto;">
    <div  fxLayout="row"   style="margin-bottom: 5px;color: white;align-items: center;" class="clock-attendance">  
        <div   fxFlex="5" fxLayoutAlign="start" >
            <button class="btn" (click)="backArrow()" style="background-color: unset;color: white;text-align-last: start;" type="button">
                <mat-icon style="font-weight: bold" title="Back">arrow_back</mat-icon>
            </button>
        </div>
        <div   fxFlex="95" fxLayoutAlign="center" style="font-weight: bold;">
                <mat-label> {{pageTitle}}</mat-label>
        </div>
    </div>
     <form [formGroup]="addPayment" autocomplete="off" style="padding-top: 1%;" *ngIf="!isWebRestricted">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex="70"   class="mx-2 my-2" appearance="outline">
                <mat-label>Client Name</mat-label><input  formControlName="clientName" matInput></mat-form-field> 
          <mat-form-field fxFlex="30"   class="mx-2 my-2" appearance="outline">
            <mat-label>Final Amount</mat-label><input  formControlName="finalPrice" matInput ></mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex="30"  class="mx-2 my-2" appearance="outline">
                <mat-label>Collected Date</mat-label>
                <input matInput [matDatepicker]="collectedDate" [max]="currentDate" placeholder=""  formControlName="collectedDate"  (keypress)="false" required>
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="collectedDate"></mat-datepicker-toggle>
                <mat-datepicker #collectedDate></mat-datepicker>
              </mat-form-field>
          <mat-form-field   fxFlex="40"  class="mx-2 my-2" appearance="outline" >
            <mat-label>Payment Method</mat-label>
                <mat-select    formControlName="modeId" required >
                    <ng-container >
                        <div *ngFor="let p of paymentModes">
                            <mat-option [value]="p.mode_id">{{p.mode}}</mat-option>
                        </div>
                    </ng-container>
                </mat-select>
                <mat-error class="con-error" *ngIf="addPayment.controls.modeId.errors?.required">
                        Please select payment method
                </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="30"   class="mx-2 my-2" appearance="outline">
            <mat-label>Collected Amount</mat-label>
            <input  formControlName="collectedAmount" matInput placeholder="" onlyNumber >
            <mat-error class="con-error" *ngIf="addPayment.controls.collectedAmount.errors?.required">
                Please enter collected amount
          </mat-error>
          </mat-form-field>
        </div>
        
           
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex="70" class="mx-2 my-2" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea matInput  formControlName="paymentNotes"  required matInput placeholder="" maxlength="250" style="height: fit-content;"></textarea>
              <mat-error class="con-error" *ngIf="addPayment.controls.paymentNotes.errors?.required">
                  Please enter description
            </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="30"   class="mx-2 my-2" appearance="outline">
                <mat-label>Penidng Amount</mat-label><input  formControlName="pendingAmount" matInput onlyNumber >
            </mat-form-field>
          </div>
        <div style="align-self: center;    text-align: center;" *ngIf="!isWebRestricted">    
          <button [tabIndex]="7" class="btn btn-primary mr-2 mb-2" aria-disabled="checkValues()"   (click)="setPayment()" type="submit" >
              <span>Submit</span>
          </button>
          <button [tabIndex]="8" class="btn btn-danger mr-2 mb-2" (click)="closePayment()"  >
              <span>Cancel</span>
          </button> 
      </div>
    </form>
</div>
 