import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserData } from '../../models/EmployeeData';
import { AttendanceService } from '../../attendance.service';
import { UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'app-permission-history',
  templateUrl: './permission-history.component.html',
  styleUrls: ['./permission-history.component.scss']
})
export class PermissionHistoryComponent implements OnInit {

  dataSource: MatTableDataSource<UserData> = <any>[];
  displayedColumns: string[] = ['id', 'employee_code', 'employee_name', 'designation', 'permission_duration', 'usage_date', 'submission_status'];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  userSession: any;
  isEdit: boolean = false;


  constructor(
    public router: Router,
    private AS: AttendanceService,
    private formBuilder: UntypedFormBuilder,


  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getPermissionsHistory(this.userSession.id)

  }


  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }


  getPageSizes(): number[] {

    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  getPermissionsHistory(id: any) {
    let data = {
      manager_id: id,
    }
    this.AS.getPermissionsHistory(data).subscribe((res: any) => {
      if (res.status) {
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    }
    )

  }
}
