import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PayrollService } from '../../../payroll.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-it-exemption-approvals',
  templateUrl: './it-exemption-approvals.component.html',
  styleUrls: ['./it-exemption-approvals.component.scss'],
})
export class ITExemptionApprovalsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns = ['id', 'date','empid', 'empname', 'status', 'view'];
  ITExcemptionForm: any = UntypedFormGroup;
  employeeExemptionForm: any = UntypedFormGroup;
  id: any;
  pipe = new DatePipe('en-US');
  file: any;
  empId: any;
  fileURL: any;
  mappingId: any;
  categoryList: any;
  sectionsList: any;
  userSession: any;
  maximumLimit: any;
  pendingAmount: any;
  employeelist: any;
  displayMaxLimit: any;
  descriptionForDisplay: any = '';
  pdfName: any = null;
  pageLoading = true;
  isFile: boolean = true;
  ispdf: boolean = true;
  displayTable: boolean = true;
  displayForm: boolean = false;
  addNewField: boolean = true;
  displayAddForm: boolean = false;
  iseditDoc: boolean = true;
sectionId:any;
requestId:any ;
documentId:any=null;
documentInfo: any = null;
document: boolean = false;
isApproved: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private payrollService: PayrollService,
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private LM: LeavesService
  ) {}

  ngOnInit(): void {
    this.ITExcemptionForm = this.formBuilder.group({
      appliedDate: [''],
      empCode: [''],
      empName: [''],
      section: [''],
      declaredAmount: [''],
      submitAmount: [''],
      receiptNumber: [''],
      pendingAmount: [''],
      approvedAmount: ['', Validators.required],
      description: [''],
      category: [''],
      
    });

    this.employeeExemptionForm = this.formBuilder.group({
      employeeName: ['', Validators.required],
      section: ['', Validators.required],
      category: [''],
      declaredAmount: [''],
      receiptNumber: ['', Validators.required],
      submitAmount: ['', Validators.required],
      description: ['', Validators.required],
      document: [''],
      pendingAmount: [''],
    });

    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeeTaxSavings();
    this.getIncomeTaxCategory();
    this.getEmployeelist();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  openApprovalDetailsForm(value: any) {

    this.displayTable = false;
    this.displayForm = true;
    this.sectionId = value.sectionId;
    this.requestId = value.id;
    this.ITExcemptionForm.controls.empCode.setValue(value.employeeCode);
    this.ITExcemptionForm.controls.empName.setValue(value.empName);
    this.ITExcemptionForm.controls.section.setValue(value.sectionName);
    this.ITExcemptionForm.controls.declaredAmount.setValue( value.maxExcemptedAmount  );
    this.ITExcemptionForm.controls.submitAmount.setValue(value.savingAmount);
    this.ITExcemptionForm.controls.receiptNumber.setValue(value.receiptNumber);
    this.ITExcemptionForm.controls.pendingAmount.setValue(
      value.maxExcemptedAmount - value.sumSavingAmount
    );
    this.ITExcemptionForm.controls.description.setValue(value.description);
    if(value.approvedAmount !=0){
      this.ITExcemptionForm.controls.approvedAmount.setValue(value.approvedAmount);
      this.ITExcemptionForm.controls.approvedAmount.disable();
    }
    value.statusName =='Approved' ? this.isApproved =true : this.isApproved =false;
    this.id = value.id;
    this.mappingId = value.mappingId;
    this.empId = value.employeeId;
    this.getUploadDocument();
    this.ITExcemptionForm.controls.appliedDate.setValue(this.pipe.transform(value.createdOn,'dd-MM-YYYY'));
    this.ITExcemptionForm.controls.category.setValue(value.categoryName);
  }

  approveSection() {

    if(this.ITExcemptionForm.valid){

    let submitAmt = this.ITExcemptionForm.controls.submitAmount.value ;
    let aprovedAmt = this.ITExcemptionForm.controls.approvedAmount.value ;
  if(submitAmt >= aprovedAmt){

    let data = {
      id_value: this.id,
      employee_id_value: this.empId,
      section_id_value: this.sectionId,
      mapping_id_value: this.mappingId,
      amount_value: this.ITExcemptionForm.controls.submitAmount.value,
      file_path_value: null,
      created_by_value: this.userSession.id,
      receipt_number_value: this.ITExcemptionForm.controls.receiptNumber.value,
      status_value: 2, // 1-submitted , 2- approved , 3-rejected
      description_value: this.ITExcemptionForm.controls.description.value,
      approved_amount_value: this.ITExcemptionForm.controls.approvedAmount.value,
      approved_by_value: this.userSession.id,
    };
    this.payrollService.setPayrollEmployeeTaxSavings(data).subscribe((result: any) => {
      let resMessage = result.data[0].statuscode === 0 ? 'Submitted successfully.' : result.data[0].statuscode === 2 ? 'Approved successfully.' : result.data[0].statuscode === 1 ? 'Already excemption limit reached' : 'Unable to save data'
    
        if (result.status) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: resMessage,
          });
          this.navigationToITexcemption();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Cannot submit.',
          });
        }
      });
    }else{
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Approve amount should not be greater than submit amount.',
      });
    }

  }
  }


  submitSection() {
    let data = {
      id_value: this.id,
      employee_id_value: this.empId,
      section_id_value: this.employeeExemptionForm.controls.section.value,
      mapping_id_value: this.mappingId,
      amount_value: this.employeeExemptionForm.controls.submitAmount.value,
      file_path_value: this.employeeExemptionForm.controls.document.value,
      created_by_value: this.userSession.id,
      receipt_number_value: this.employeeExemptionForm.controls.receiptNumber.value,
      status_value: 2, // 1-submitted , 2- approved , 3-rejected
      description_value: this.employeeExemptionForm.controls.description.value,
      approved_amount_value: this.employeeExemptionForm.controls.submitAmount.value,
    };

    this.payrollService.setPayrollEmployeeTaxSavings(data).subscribe((result: any) => {
        if (result.status && result.data[0].statuscode === 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Submitted successfully.',
          });
          this.navigationToITexcemption();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Cannot submit.',
          });
        }
      });
  }
  cancel() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/ITexcemptionApprovals']));
  }

  getEmployeeTaxSavings() {
    let data = {
      employee_id_value: null,
      manager_id_value: this.userSession.id,
    };
    this.payrollService.getEmployeeTaxSavings(data).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
    });
  }
  navigationToITexcemption() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/ITexcemptionApprovals']));
  }
  addNewSection() {
    this.addNewField = false;
    this.displayTable = false;
    this.displayAddForm = true;
  }

  getIncomeTaxSections() {
    let data = {
      category_id_value: this.employeeExemptionForm.controls.category.value,
    };

    this.payrollService.getIncomeTaxSections(data).subscribe((result: any) => {
      this.sectionsList = result.data;
    });
  }

  insertMaxExemAmount(data: any, mapId: any, description: any) {
    this.descriptionForDisplay = '';
    this.maximumLimit = '';
    this.employeeExemptionForm.controls.declaredAmount.setValue(data);
    this.mappingId = mapId;
    this.getEmployeeSectionWiseSavings();
    for (let i = 0; i < description.length; i++) {
      if (description.charCodeAt(i) == 40 || description.charCodeAt(i) == 41) {
      } else {
        this.descriptionForDisplay += description[i];
      }
    }
    this.maximumLimit = data;
    this.displayMaxLimit = true;
  }
  getEmployeeSectionWiseSavings() {
    this.employeeExemptionForm.controls.pendingAmount.setValue('');
    let data = {
      mapping_id_value: this.mappingId,
      employee_id_value: this.userSession.id,
    };

    this.payrollService
      .getEmployeeSectionWiseSavings(data)
      .subscribe((result: any) => {
        let list = result.data;
        let sectionIdfromForm =
          this.employeeExemptionForm.controls.section.value;
        let maxAmount =
          this.employeeExemptionForm.controls.declaredAmount.value;
        for (let i = 0; i < list.length; i++) {
          if (list[i].sectionId == sectionIdfromForm) {
            this.pendingAmount = maxAmount - list[i].savingAmount;
          }
        }
        this.employeeExemptionForm.controls.pendingAmount.setValue(
          this.pendingAmount
        );
      });
  }
  fileView() {
    window.open(this.fileURL);
  }
  onSelectFile(event: any) {
    this.iseditDoc = false;

    if (event.target.files[0].size <= 1242880) {
      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');

      if (pdf[pdf.length - 1] == 'pdf') {
        this.isFile = true;
      } else {
        this.ispdf = true;
        this.isFile = false;
      }
    } else {
      this.ispdf = false;
      this.isFile = false;

      // Swal.fire({title: '', text: 'File size is must be less than 15MB', color: "red", position: 'top'});
    }
  }
  delete() {
    this.employeeExemptionForm.controls.document.setValue('');
  }
  getIncomeTaxCategory() {
    this.payrollService.getIncomeTaxCategory().subscribe((result: any) => {
      this.categoryList = result.data;
    });
  }
  getEmployeelist() {
    let obj = {
      rm_id: this.userSession.id,
    };
    this.reportsService
      .getTotalEmployeslistByManagerId(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.employeeExemptionForm.controls.employeeName.setValue('0');
        }
      });
  }

  getUploadDocument() {
    let info = {
      'employeeId':this.empId,
      'candidateId':null,
      'filecategory': 'INVESTMENTS',
      'moduleId':5,
      'requestId':this.requestId,
      'status':'Submitted'
    }
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length >0) {
       this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0])
        this.pdfName = result.data[0].fname

        result.data[0].employeeId=this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            this.employeeExemptionForm.controls.document.value=true;
            this.employeeExemptionForm.controls.document.clearValidators();
            this.employeeExemptionForm.controls.document.updateValueAndValidity();
            this.iseditDoc = false;
            

            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName =  this.pdfName.split('.');
            // const file = new Blob([TYPED_ARRAY], { type: "application/pdf" });
            // this.fileURL = URL.createObjectURL(file);
            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        })
      }
      else{
     }
    })
  }
}
