
<div fxLayout="column" class="first-col">

    <mat-card>
      <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title class="title"> Employee Directory </mat-card-title>
          <div style="margin-right: 2%;">
             <button mat-icon-button style="padding-right: 40px;" (click)="newHire()" matTooltip="Add New">
                  <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
             </div>
          </mat-card-header>
      <form [formGroup]="employeeDirectoryForm" style="padding: 1%;">

         <div  fxFlex.xs="100" fxFlex class="mx-2 my-2 custom-payroll-div" >
          <mat-accordion>
            <mat-expansion-panel #matExpansionPanel *ngFor="let res of worklocationDetails;index as i;" style="margin: 1px;">
              <mat-expansion-panel-header style="color: #fff !important; background-color: #f8f9fc !important; border-bottom: 1px solid #e3e6f0 !important;" 
              (click)="validateExpansion(res,i)" (keyup.emter)="validateExpansion(res,i+1)">
                <mat-panel-title style="margin-top:5px;color: #4e73df!important;font-weight: 500;">
                  <p style="margin-bottom: 0px;font-size: large;">{{res.cityname}} - {{res.location}}</p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="end">

                <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline" style="padding-top: 1%;padding-right: 1%;">
                  <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                  <mat-label>Search Employee</mat-label>
                    <input (keyup)="applyFilter($event)"  matInput placeholder="" maxlength="100" >
                  </mat-form-field>
          
        </div>

        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" >

              <div class="card" *ngFor="let emp of employeeFilteredList,let i=index">

                <div fxLayoutAlign="end">
                     <span (click)="editEmployee($event,emp)">
                      <button mat-icon-button matTooltip="Edit">
                        <mat-icon class="icon">edit</mat-icon>
                               </button>

                        </span>
                     </div>
                <div fxLayoutAlign="center" style="height: 90px;"> <img mat-card-sm-image src="assets/img/new-profile.png" ></div>
                <div fxLayout="row" style="padding: 3px;" fxLayoutAlign="space-around center">
                    <span class="fontSize textLength ">{{emp.ename | titlecase}}</span>
                   </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <span class="textLength">{{emp.designation}}</span>
                  <span ss="textLength">{{emp.location}}</span>

                </div>

              </div>
              <div *ngIf="employeeFilteredList == 0">
                <span>No data found</span>

              </div>
            </div>
            </mat-expansion-panel>
          </mat-accordion>
           </div>
        </form>
    </mat-card>

</div>
