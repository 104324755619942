import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EmsService } from 'src/app/modules/ems/ems.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import * as _moment from 'moment';
import { AdminService } from '../../admin.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-variable-da-configuration-master',
  templateUrl: './variable-da-configuration-master.component.html',
  styleUrls: ['./variable-da-configuration-master.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VariableDaConfigurationMasterComponent implements OnInit {
  variableDaForm!: UntypedFormGroup;
  pipe = new DatePipe('en-US');
  displayedColumns: string[] = [
    'id',
    'notifyDate',
    'notification',
    'publishedname',
    'cpipoints',
    'action',
  ];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  userSession: any;
  pageLoading = true;
  requiredField: any;
  requiredOption: any;

  isdata: boolean = true;
  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private router: Router,
    public reportsService: ReportsService,
    public adminService: AdminService,
    private LM: LeavesService
  ) {}
  // --------------------
  maxDate = new Date();
  smaxDate: any;
  emaxDate: any;
  minDate: any;
  sminDate: any;
  eminDate: any;
  variableDaId: any;
  variableDaList: any = [];
  effectiveDateList:any =  [
    {effectiveDateId:1, effectiveDateValue:'January - June'},
    {effectiveDateId:2, effectiveDateValue:'July - December'}
  ]
  fileURL: any;
  file: any;
  array: any;
  iseditDoc: boolean = true;
  isFile: boolean = false;
  ispdf: boolean = true;
  pdfName: any = null;
  document: boolean = false;
  formData: FormData = new FormData();
  requestId:any ;
  documentId:any=null;
  documentInfo: any = null;
  ngOnInit(): void {
    this.variableDaForm = this.formBuilder.group({
      notification_name: [''],
      published_name: [''],
      wages_linked_cpi_points: [''],
      cpi_points_at_notified_date: [''],
      notification_date: [''],
      vda_rate_per_point: [''],
      effective_start_date: [''],
      effective_end_date: [''],
      document: [''],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getVariableDAList();
    this.variableDaForm.get('effective_start_date')?.valueChanges.subscribe((selectedValue: any) => {
        if (selectedValue != null) {
          this.eminDate = selectedValue._d;
        }
      });
  }
  Add() {
    this.isAdd = true;
    this.isdata = false;
  }

  getVariableDAList() {
    this.adminService.getVariableDAConfiguration().subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.variableDaList = result.data;
        this.dataSource = new MatTableDataSource(this.variableDaList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      } 
    });
  }

  submitData() {
    let wages_cpi_points =  this.variableDaForm.controls.wages_linked_cpi_points.value;
    let notified_cpi_points =  this.variableDaForm.controls.cpi_points_at_notified_date.value;
    if (this.variableDaForm.valid) {
    
      if( wages_cpi_points > notified_cpi_points){

      let data = {
        id_value: this.variableDaId == null ? null : this.variableDaId,
        notification_name_value:  this.variableDaForm.controls.notification_name.value,
        published_name_value: this.variableDaForm.controls.published_name.value,
        wages_linked_cpi_points:   this.variableDaForm.controls.wages_linked_cpi_points.value,
        cpi_points_at_notified_date_value:      this.variableDaForm.controls.cpi_points_at_notified_date.value,
        notification_date_value: this.pipe.transform( this.variableDaForm.controls.notification_date.value, 'yyyy-MM-dd' ),
        vda_rate_per_point_value: this.variableDaForm.controls.vda_rate_per_point.value,
        effectiveDateId: this.variableDaForm.controls.effective_start_date.value,
        // effective_end_date_value: this.pipe.transform( this.variableDaForm.controls.effective_end_date.value,'yyyy-MM-dd' ),
        user_id_value: this.userSession.id,
      };
      this.adminService.setVariableDaConfiguration(data).subscribe((result: any) => {
        let resMessage = result.data[0].statuscode === 0 ? 'Variable DA configured successfully.' : result.data[0].statuscode === 1 ? 'Record already exist.' : result.data[0].statuscode === 2 ? 'Variable DA configuration updated successfully.' : 'Unable to save data'
        this.requestId = result.data[0].lastInsertId;
          if (result.status) {
            if (this.isFile = true) {
              this.LM.getFilepathsMaster(5).subscribe((result: any) => {
                if (result && result.status) {
                  let obj = {
                    id: null,
                    employeeId: this.userSession.id,
                    filecategory: 'VARIABLE_DA',
                    moduleId: 5,
                    documentnumber: '',
                    fileName: this.file.name,
                    modulecode: result.data[0].module_code,
                    requestId: this.requestId,
                    status: 'Submitted',
                  };
                  this.LM.setFilesMaster(obj).subscribe((data) => {
                    if (data && data.status) {
                      let info = JSON.stringify(data.data[0]);
                      this.formData.append('info', info);
                      this.formData.append('file', this.file);
                      this.LM.setProfileImage(this.formData).subscribe(
                        (data) => {
                        }
                      );
                    }
                  });
                } else {
  
                  this.dialog.open(ConfirmationComponent, {
                    position: { top: `70px` },
                    disableClose: true,
                    data: {
                      Message: 'Please check uploaded file.',
                    },
                  });
                }
              });
  
            }
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage,
            });
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/Payroll/variableDaConfiguration'])
              );
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to save data.',
            });
          }
        });
      }else{
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'CPI points at notified date value must be greater than Wages Linked CPI Points.',
        });
      }
      }
  }

  edit(data: any) {
    this.isAdd = true;
    this.isdata = false;
    this.isEdit = true;
    this.variableDaId = data.id;
    this.variableDaForm.controls.notification_name.setValue(data.notification);
    this.variableDaForm.controls.published_name.setValue(data.publishedname);
    this.variableDaForm.controls.wages_linked_cpi_points.setValue(
      data.cpipoints
    );
    this.variableDaForm.controls.cpi_points_at_notified_date.setValue(
      data.cpi_points_at_notified_date
    );
    this.variableDaForm.controls.vda_rate_per_point.setValue(data.vdarate);
    this.variableDaForm.controls.notification_date.setValue(
      data.notificationdate
    );
    this.variableDaForm.controls.effective_start_date.setValue(data.effectiveDateId);
    this.getUploadDocument();
  }

  view(data: any) {}
  resetform() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/variableDaConfiguration']));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  fileView() {
    window.open(this.fileURL);
  }

  onSelectFile(event: any) {
    this.iseditDoc = false;

    if (event.target.files[0].size <= 1242880) {
      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');
      if (pdf[pdf.length - 1] == 'pdf' || pdf[pdf.length - 1] == 'jpg' || pdf[pdf.length - 1] == 'png') {
        this.isFile = true;
      } else {
        this.ispdf = true;
        this.isFile = false;
      }
    } else {
      this.ispdf = false;
      this.isFile = false;
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'File size is must be less than 2MB.',
      });
    }
  }

  editdoc() {
    this.pdfName = null;
  }

  deleteIcon() {
    this.pdfName = null;
    this.isFile = false;
    this.file='';
    this.variableDaForm.controls.document.reset();
  }

  getUploadDocument() {
    let info = {
      'employeeId':this.userSession.id,
      'candidateId':null,
      'filecategory': 'VARIABLE_DA',
      'moduleId':5,
      'requestId':this.variableDaId,
      'status':'Submitted'
    }
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length >0) {
       this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0])
        this.pdfName = result.data[0].fname

        result.data[0].employeeId=this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            // this.variableDaForm.controls.document.value=true;
            this.variableDaForm.controls.document.clearValidators();
            this.variableDaForm.controls.document.updateValueAndValidity();
            this.iseditDoc = false;
            

            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName =  this.pdfName.split('.');
            // const file = new Blob([TYPED_ARRAY], { type: "application/pdf" });
            // this.fileURL = URL.createObjectURL(file);
            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        })
      }
      else{
     }
    })
  }
}
