import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class MainService {
  mainBeUrl = environment.apiUrl;
  userSession: any;
  httpOptions: any;
  companyName: any;
  constructor(private http: HttpClient) {
    // this.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    // this.httpOptions = {
    //   headers: new HttpHeaders({
    //     'content-Type': 'application/json',
    //     "Authorization": JSON.parse(JSON.stringify(sessionStorage.getItem('token') || '')),
    //   })
    // };
    this.refreshSession();
  }
  refreshSession(){
    // this.userSession =  JSON.parse(sessionStorage.getItem('user') ?? '');
    this.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        "Authorization": JSON.parse(JSON.stringify(sessionStorage.getItem('token') || '')),
      })
    };
  }
  /*Get Role Screen Functionalities*/
  getRoleScreenFunctionalities(data: any) {
    data.companyName =sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;

    return this.http.post(this.mainBeUrl + 'attendance/api/getrolescreenfunctionalities', data, this.httpOptions);
  }
  /*Get Role Screen Functionalities Based on RoleId*/
  getrolescreenfunctionalitiesforrole(data: any) {
    data.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;

    return this.http.post(this.mainBeUrl + 'attendance/api/getrolescreenfunctionalitiesforrole', data, this.httpOptions);

  }

  getProfileImage(info: any): Observable<any> {
    this.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'api/getProfileImage/' + this.companyName + '/', info, this.httpOptions);
  }
  getCompoffleavestatus(): Observable<any> {
    return this.http.get(this.mainBeUrl + 'api/getcompoffleavestatus/' + this.companyName, this.httpOptions);
  }
  getFilesMaster(info: any): Observable<any> {
    this.refreshSession();
    info.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'api/getFilesMaster/', info, this.httpOptions);
  }

  /** save candidate information */
  savePreOnboardingCandidateInfo(data: any) {
    data.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/setPreonboardCandidateInformation', JSON.stringify(data), this.httpOptions);
  }

  getPreonboardCandidateData(id: any, cname: any): Observable<any> {
    this.companyName = cname;
    return this.http.get(this.mainBeUrl + 'ems/api/getCandidateDetails/' + id + '/' + this.companyName, this.httpOptions);
  }

  /** save candidate information */
  savePreOnboardingCandidateExperience(data: any) {
    data.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;

    return this.http.post(this.mainBeUrl + 'ems/api/setCandidateExperience', JSON.stringify(data), this.httpOptions);
  }

  /** save candidate education */
  savePreOnboardingCandidateEducation(data: any) {
    data.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;

    return this.http.post(this.mainBeUrl + 'ems/api/setCandidateEducation', JSON.stringify(data), this.httpOptions);
  }

  getFileMasterForEMS(input: any) {
    return this.http.post(this.mainBeUrl + 'ems/api/getFileMasterForEMS', JSON.stringify(input), this.httpOptions);

  }

  getFilecategoryMasterForEMS(input: any) {
    input.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/getFilecategoryMasterForEMS', JSON.stringify(input), this.httpOptions);

  }
  getFilepathsMasterForEMS(moduleId: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'ems/api/getFilepathsMasterForEMS/' + moduleId + '/' + sessionStorage.getItem("companyName"), this.httpOptions);
  }

  setFilesMasterForEMS(info: any): Observable<any> {
    info.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;

    return this.http.post(this.mainBeUrl + 'ems/api/setFilesMasterForEMS/', info, this.httpOptions);
  }
  setDocumentOrImageForEMS(data: FormData): Observable<any> {
    return this.http.post(this.mainBeUrl + 'ems/api/setDocumentOrImageForEMS/' +sessionStorage.getItem("companyName"), data);
  }
  getDocumentsForEMS(input: any) {
    input.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/getDocumentsForEMS', JSON.stringify(input), this.httpOptions);
  }
  getDocumentOrImagesForEMS(info: any): Observable<any> {
    info.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/getDocumentOrImagesForEMS/', info, this.httpOptions)
  }
  // removeDocumentOrImagesForEMS(info:any): Observable<any> {
  //   return this.http.delete(this.mainBeUrl + 'ems/api/removeDocumentOrImagesForEMS/'+this.companyName +'/'+encodeURI(info),this.httpOptions);
  // }

  /** For AWS*/
  removeDocumentOrImagesForEMS(info: any): Observable<any> {
    return this.http.post(this.mainBeUrl + 'ems/api/removeDocumentOrImagesForEMS/', info, this.httpOptions);
  }
  removeDocumentOrImagesForEMSpreonboarding(info: any): Observable<any> {
    return this.http.post(this.mainBeUrl + 'ems/api/removeDocumentOrImagesForEMSpreonboarding/', info, this.httpOptions);
  }

  deleteFilesMaster(id: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'ems/api/deleteFilesMaster/' + id + '/' + sessionStorage.getItem("companyName"), this.httpOptions);
  }
  deleteFilesMasterpreonboarding(id: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'ems/api/deleteFilesMasterpreonboarding/' + id + '/' + sessionStorage.getItem("companyName"), this.httpOptions);
  }

  /** */
  getEmployeeAttendanceCounts(mid: any, empid: any, date: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'ems/api/getAttendanceCountsForDate/' + mid + '/' + empid + '/' + date + '/' + sessionStorage.getItem("companyName"), this.httpOptions);
  }

  getSideNavigation(data: any) {
     
    return this.http.post(this.mainBeUrl + 'api/getCommonSideNavigation', data, this.httpOptions);
    //return this.http.post(this.mainBeUrl + 'attendance/api/getSideNavigation',data, this.httpOptions);
  }
  getScreensForSuperAdmin(data: any) {
    data.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'api/getScreensForSuperAdmin', data, this.httpOptions);
    //return this.http.post(this.mainBeUrl + 'attendance/api/getSideNavigation',data, this.httpOptions);
  }
  
  /**get document files */
  getDocumentsFiles(input: any) {
    input.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/getDocumentsFiles', JSON.stringify(input), this.httpOptions);
  }

  /** get employee schedule program alerts in maindashboard */
  getEmpScheduleProgramAlerts(eid: any) {
    return this.http.get(this.mainBeUrl + 'ems/api/getEmployeeProgramAlerts/' + eid + '/' + sessionStorage.getItem("companyName"), this.httpOptions);
  }
  /**get document files */
  getPreonboardingDocumentsFiles(input: any) {
    return this.http.post(this.mainBeUrl + 'ems/api/getDocumentsFilesPreonboarding', JSON.stringify(input), this.httpOptions);
  }
  getPreonboardingFilecategoryMasterForEMS(input: any) {
    return this.http.post(this.mainBeUrl + 'ems/api/getFilecategoryMasterForEMSPreonboarding', JSON.stringify(input), this.httpOptions);
  }
  getPreonboardingDocumentOrImagesForEMS(info: any): Observable<any> {
    info.companyName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    return this.http.post(this.mainBeUrl + 'ems/api/getDocumentOrImagesForEMSPreonboarding/', info, this.httpOptions)
  }
  setPreonboardingDocumentOrImageForEMS(data: FormData, cname: any): Observable<any> {
    return this.http.post(this.mainBeUrl + 'ems/api/preonboardingSetDocumentOrImageForEMS/' + cname, data);
  }
  removePreonboardingDocumentOrImagesForEMS(info: any, cname: any): Observable<any> {
    return this.http.delete(this.mainBeUrl + 'ems/api/removeDocumentOrImagesForEMS/' + cname + '/' + encodeURI(info), this.httpOptions);
  }
  getPreonboardingFilepathsMasterForEMS(moduleId: any, cname: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'ems/api/getFilepathsMasterForEMSPreonboarding/' + moduleId + '/' + cname, this.httpOptions);
  }
  setPreonboaringFilesMasterForEMS(info: any): Observable<any> {
    return this.http.post(this.mainBeUrl + 'ems/api/setFilesMasterForEMSPreonboarding/', info, this.httpOptions);
  }

  /** */
  getIsManagerOrNot(eid: any) {
    return this.http.get(this.mainBeUrl + 'ems/api/validateReportingManager/' + eid + '/' +sessionStorage.getItem("companyName"), this.httpOptions);
  }
  /** get industry type list */
  getIndustryTypes(tableName: any, status: any, page: any, size: any, companyName: any): Observable<any> {
    return this.http.get(this.mainBeUrl + 'api/getMastertableSignup/' + tableName + '/' + status + '/' + page + '/' + size + '/' + companyName);
  }

  setSprypleClient(data: any) {
    return this.http.post(this.mainBeUrl + 'subscription/api/setSprypleClient', data)
  }
  agreementFile(data:any) {
    return this.http.get(this.mainBeUrl + 'subscription/agreement');
  }
  getUnverifiedSprypleClient(data: any) {
    return this.http.post(this.mainBeUrl + 'subscription/getUnverifiedSprypleClient', data);
  }
  /**get plan details by plan id and client id */
  getPlanDetailsByPlanIdAndClientId(data: any) {
    return this.http.post(this.mainBeUrl + 'subscription/api/getSpryplePlanDetailsById', data);
  }
  /** get spryple activations by Month count  */
  getSprypleActivationsCountByMonth(data: any) {
    return this.http.get(this.mainBeUrl + 'subscription/api/getSprypleActivationsCountByMonth/' + data + '/' +sessionStorage.getItem("companyName"), this.httpOptions)
  }

  /** get spryple activations by year count */
  getSprypleActivationsCountByYear(data: any) {
    return this.http.get(this.mainBeUrl + 'subscription/api/getSprypleActivationsCountByYear/' + data + '/' + sessionStorage.getItem("companyName"), this.httpOptions)
  }

  /** get spryple clients status wise count */
  getSprypleClientsStatusWiseCount() {
    return this.http.get(this.mainBeUrl + 'subscription/api/getSprypleClientsStatusWiseCount/' + sessionStorage.getItem("companyName"), this.httpOptions)
  }

  /**get spryple revenue By month */
  getSprypleRevenueByMonth(data: any) {
    return this.http.get(this.mainBeUrl + 'subscription/api/getRevenueByMonth/' + data + '/' +sessionStorage.getItem("companyName"), this.httpOptions)
  }

  setSprypleClientPlanPayment(data: any) {
   return this.http.post(this.mainBeUrl + 'subscription/api/setSprypleClientPlanPayment', data, this.httpOptions);
  }

  /** chart-get spryple clients year wise count */
  getYearWiseClientsCount() {
    return this.http.get(this.mainBeUrl + 'subscription/api/getYearWiseClientsCount/' + sessionStorage.getItem("companyName"), this.httpOptions)
  }

  /**chart - get spryple clients By month */
  getMonthWiseClientsCountByYear(data: any) {
    return this.http.get(this.mainBeUrl + 'subscription/api/getMonthWiseClientsCountByYear/' + data + '/' + sessionStorage.getItem("companyName"), this.httpOptions)
  }
  paymentFailedMail(data:any){
    return this.http.post(this.mainBeUrl + 'subscription/api/paymentFailedMail', data, this.httpOptions);
  }
  employeeDataExcelUpload() {
    return this.http.get(this.mainBeUrl + 'subscription/employeeExcelUpload');
  }
  getListOfYears() {
    return this.http.get(this.mainBeUrl + 'subscription/api/getListOfYears',this.httpOptions);
  }

  /**set employee ticket */
  setHelpTicketCreation(data: any) {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/setHelpTicketCreation', data, this.httpOptions);
   }

     /**get employee ticket */
  getEmployeeTicket(data:any) {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/getHelpTicketCreations', data, this.httpOptions);
   }

   /**get Active Modules */
    getActiveModules() {
    return this.http.get(this.mainBeUrl + 'api/getActiveModules/'+ sessionStorage.getItem("companyName"), this.httpOptions);
   }

   getScreenMaster(module_id:any){
      return this.http.get(this.mainBeUrl + 'api/getscreensmaster/'+ module_id +'/'+this.companyName, this.httpOptions);
  }
    /**get admin help ticket */
    getSprypleHelpTickets(data: any) {
      data.companyName = this.companyName;
      return this.http.post(this.mainBeUrl + 'api/getSprypleHelpTickets', data, this.httpOptions);
     }

/** get Ticket Details By Id */
     getTicketDetailsById(ticketId:any){
      return this.http.get(this.mainBeUrl + 'api/getTicketDetailsById/'+ ticketId +'/'+this.companyName, this.httpOptions);
  }
  
/** get Help Ticket Status */
     getHelpTicketStatus() {
      return this.http.get(this.mainBeUrl + 'api/getHelpTicketStatus', this.httpOptions);
     }

    /**set Help Ticket Assignment */
   setHelpTicketAssignment(data: any) {
    data.companyName = this.companyName;
    return this.http.post(this.mainBeUrl + 'api/setHelpTicketAssignment', data, this.httpOptions);
   }

/**get Help Tickets For Employee */
      getHelpTicketsForEmployee(data: any) {
        data.companyName = this.companyName;
        return this.http.post(this.mainBeUrl + 'api/getHelpTicketsForEmployee', data, this.httpOptions);
       }

/**set Help Ticket Status Save */
    setHelpTicketStatusSave(data: any) {
      data.companyName = this.companyName;
      return this.http.post(this.mainBeUrl + 'api/setHelpTicketStatusSave', data, this.httpOptions);
     }  

/** get Ticket history By Id */
getTicketHistory(ticketId:any){
  return this.http.get(this.mainBeUrl + 'api/getTicketHistory/'+ ticketId +'/'+this.companyName, this.httpOptions);
}

/** get Help Priorities */
getHelpPriorities(){
  return this.http.get(this.mainBeUrl + 'api/getHelpPriorities', this.httpOptions);
}
}
