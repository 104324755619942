<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header>
            <mat-card-title> HR Regularization Approval </mat-card-title>
        </mat-card-header>
        <div fxLayout="column">
            <!-- <mat-divider></mat-divider> -->
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end"
                style="height: 70px;padding-top: 1%;">
                <mat-form-field fxFlex="28" fxFlex.lt-lg="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)" [tabIndex]="1" placeholder="Search" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <form [formGroup]="regularizationApprovalForm" autocomplete="off">
               <div class="tableP">
                    <div class="mat-elevation-z1 example-container">
                  
                        <table mat-table [dataSource]="dataSource" matSort>
                            <!-- multi select  -->
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef> S.No</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                {{i+1}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="empoyeeid">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Date </th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.fromdate |
                                    date:'dd-MM-yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="empname">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name </th>
                                <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                            </ng-container>
                            <ng-container matColumnDef="shift">
                                <th mat-header-cell *matHeaderCellDef> Shift </th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.shift}}</td>
                            </ng-container>
               
                            <ng-container matColumnDef="intime">
                                <th mat-header-cell *matHeaderCellDef> In Time</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <span *ngIf="enable != row.id">{{row.logintime}} </span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <input formControlName="intime" matInput mask="00:00:00"
                                                placeholder="{{ row.logintime }}" maxlength="100" />
                                        </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>

                             <ng-container matColumnDef="outtime">
                                <th mat-header-cell *matHeaderCellDef> Out Time</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <span *ngIf="enable != row.id">{{row.logouttime}} </span>
                                    <span *ngIf="enable === row.id">
                                        <mat-form-field appearance="outline">
                                            <input formControlName="outtime" matInput mask="00:00:00"
                                                placeholder="{{ row.logouttime }}" maxlength="100" />
                                       </mat-form-field>
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                  
                                    <!-- <button mat-icon-button [tabIndex]="2" (keyup.enter)="setApproveRegularization(row)"
                                        *ngIf=" enable != row.id">
                                        <i class="material-icons mr-1" (click)="setApproveRegularization(row)"
                                            title="Approve">check_box</i>
                                    </button>

                                    <button mat-icon-button [tabIndex]="2" (keyup.enter)="setRejectRegularization(row)"
                                        *ngIf="enable != row.id">
                                        <i class="material-icons mr-1" (click)="setRejectRegularization(row)"
                                            title="Reject">cancel_presentation</i>
                                    </button> -->

                                <!--  record edit -->

                                    <!-- <button mat-icon-button [tabIndex]="2" (keyup.enter)="edit(row)"
                                        *ngIf=" enable != row.id">
                                        <mat-icon class="mr-1" title="Edit" (click)="edit(row)">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [tabIndex]="2" (keyup.enter)="saveRegularization(row)"
                                        *ngIf="enable === row.id">
                                        <mat-icon class="mr-1" title="save"
                                            (click)="saveRegularization(row)">save</mat-icon>
                                    </button>

                                    <button mat-icon-button [tabIndex]="2" (keyup.enter)="canceledit()"
                                        *ngIf="enable === row.id">
                                        <mat-icon class="mr-1" title="cancel" (click)="canceledit()">cancel</mat-icon>
                                    </button>  -->
                                </td>

                            </ng-container>
                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                      </div>

                      <br>
                      <div fxLayoutAlign="center">
                        <button class="btn btn-primary custom mr-2 mb-2" (click)="submit()" type="submit">
                            <span>Approve</span>
                        </button>
                       <button class="btn btn-danger custom mr-2 mb-2" (click)="rejectRegularization()">
                            <span>Reject</span>
                        </button>
                        <br/>
                    </div>
               </div>
            </form>
        </div>
    </mat-card>
</div>