<div fxLayout="column">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="title">Employee Password Reset</mat-card-title>
        </mat-card-header>
        <div [formGroup]="passwordResetForm" autocomplete="off" fxLayout="column" fxLayoutAlign.xs="center center"
            style="margin-top: 20px;">
            <div fxLayout="row" fxLayout.xs="column" style="margin-bottom: 30px;">
                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select formControlName="location">
                        <ng-container *ngFor="let a of worklocationDetails">
                            <mat-option (click)="enableDepartments()" [value]="a.id">
                                {{a.location}}-{{a.cityname}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.empname.errors?.required">
                        This Field is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Department</mat-label>
                    <mat-select formControlName="department">
                        <ng-container *ngFor="let a of availableDepartments">
                            <mat-option [value]="a.id" (click)="getEmployeeListBasedonlocationIdandDepartmentId()">
                                {{a.deptname}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.empname.errors?.required">
                        This Field is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Employee Name</mat-label>
                    <mat-select formControlName="empname">
                        <ng-container *ngFor="let a of employeeList">
                            <mat-option [value]="a.empid"> {{a.empname}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.empname.errors?.required">
                        This Field is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" ngStyle.xs="margin-top: 20px;">
                <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Existing User name</mat-label>
                    <input matInput readonly formControlName="existingUsername">
                </mat-form-field>

                <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Existing Password</mat-label>
                    <input [type]="hide1 ? 'password' : 'text'" formControlName="oldPassword" matInput
                        placeholder="Existing Password" readonly >
                    <mat-icon matSuffix>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>New Username</mat-label>
                    <input matInput formControlName="username">
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.username.errors?.required">
                        This field is required.
                    </mat-error>
                </mat-form-field>


                <!-- <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label> New Password</mat-label>
                    <input [type]="hide2 ? 'password' : 'text'" formControlName="newPassword" matInput
                        placeholder="Confirm Password">
                    <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.newPassword.errors?.required">
                        This Field is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="32" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label> Confirm Password</mat-label>
                    <input [type]="hide3 ? 'password' : 'text'" formControlName="confirmPassword" matInput
                        placeholder="Confirm Password">
                    <mat-icon matSuffix (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls.newPassword.errors?.required">
                        This Field is required.
                    </mat-error>
                </mat-form-field> -->
             
                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input [type]="hide2 ? 'password' : 'text'" formControlName="newPassword"
                      pattern="(?=.*?[0-9])(?=.*[a-z])(?=.*?[!@#%\$&*~])(?=.*[A-Z]).{8,20}" matInput placeholder="New Password">
                    <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls['newPassword'].invalid">
                      One lower case letter.One UPPER case letter. <br>
                      One Number.
                      One special character (! @ ^ $ or %).<br>
                      Minimum 8 characters in length (20 characters max).
                    </mat-error>
                  </mat-form-field>

                  <br *ngIf="passwordResetForm.controls['newPassword'].touched && !passwordResetForm.controls['newPassword'].valid">
                  <br *ngIf="passwordResetForm.controls['newPassword'].touched && !passwordResetForm.controls['newPassword'].valid">
        
                  <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input [type]="hide3 ? 'password' : 'text'" formControlName="confirmPassword" matInput
                      placeholder="Confirm Password">
                    <mat-icon matSuffix (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
        
                    <mat-error class="con-error" *ngIf="passwordResetForm.controls['confirmPassword'].invalid">
                      The confirm password you entered does not match with new password. Please re-enter your password
                    </mat-error>
                     </mat-form-field>
            </div>
<br>
<div fxLayoutAlign="center">
          
    <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
      <span>Submit</span>
    </button>

    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
      <span>Cancel</span>
    </button>
    <br/>

  </div>

        </div>
    </mat-card>

</div>