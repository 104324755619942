import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoConsecutiveSpecialChars]'
})
export class NoConsecutiveSpecialCharsDirective {

  // Define a regex to match specific special characters
  private specialCharsRegex = /[\/\*\-\+\.\,]/;
  private specialCharsRegex2 = /[!@#$%^&*(),.?":{}|<>]/;
  private consecutiveSpecialCharsRegex = /([!@#$%^&*(),.?":{}|<>])(?=[!@#$%^&*(),.?":{}|<>])/g;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;

    // Remove consecutive special characters
    value = value.replace(this.consecutiveSpecialCharsRegex, '');

    // Remove special characters(/,*,-,+) if it's the first character
    if (this.specialCharsRegex.test(value.charAt(0))) {
      value = value.substring(1);
    }

        // Remove special character(@#$^&*) if it's the first character
        if (this.specialCharsRegex2.test(value.charAt(0))) {
          value = value.substring(1);
        }

    if (input.value !== value) {
      input.value = value;
      event.stopImmediatePropagation();
    }
  }
}
