<div fxLayout="column" fxFill class="custom-investment-of first-col">
    <mat-card fxFill>
        <mat-card-header class="headerbox">
            <div fxLayout="row" fxFlex="50">
                <mat-card-title class="title">Pay Schedule</mat-card-title>
            </div>
        </mat-card-header>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card">
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="5">
            </div>
            <div fxLayout="column" fxFlex="60" fxFlex.lt-lg="90" class="custom-payroll-div">
                <div class="card card-stats card-warning cmn-leave">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-12">
                                <div class="numbers text-center">
                                    <!-- <div style="display: -webkit-box">
                                        <p class="card-category" style="text-align:left; margin-right: auto;font-family: 'Roboto';">The organization payroll runs on this schedule</p>
                                      <p>
                                        <button mat-icon-button title="Edit" [tabindex]="[1]" (keyup.enter)="onRequestClick()">
                                        <mat-icon title="Edit" style="color: black" (click)="onRequestClick()">edit
                                      </mat-icon>
                                    </button></p>
                                      </div> -->
                                    <div fxLayout="row" fxLayoutAlign="space-between">
                                        <span style="padding-top: 8px;">
                                            <span style="color: black;font-size: large;font-weight: 500;">The
                                                organization payroll runs on this schedule</span>
                                        </span>
                                        <span>
                                            <button mat-icon-button title="Edit" [tabindex]="[1]"
                                                (keyup.enter)="onRequestClick(true)" *ngIf="isadd">
                                                <mat-icon title="Edit" style="color: black"
                                                    (click)="onRequestClick(true)">edit
                                                </mat-icon>
                                            </button>

                                            <button mat-icon-button title="Edit" [tabindex]="[1]"
                                                (keyup.enter)="onRequestClick(isadd)" *ngIf="!isadd">
                                                <mat-icon title="View" style="color: black"
                                                    (click)="onRequestClick(isadd)">visibility
                                                </mat-icon>
                                            </button>
                                        </span>
                                    </div>

                                    <table class="table no-border mt-3">
                                        <tbody style="text-align:left;">
                                            <!--<tr>-->
                                            <!--<td>Pay Frequency</td>-->
                                            <!--<td>Monthly</td>-->
                                            <!--</tr>-->

                                            <!-- <tr>
                                                    <td>Pay Day</td>
                                                  <td *ngIf="payDay !='LAST_WORKING_DAY'">{{payDay}}</td>
                                                </tr> -->

                                            <tr>
                                                <td>Company Payroll Cycle</td>
                                                <!-- <td>April - March</td> -->
                                                <td>{{companyPayrollCycle}}</td>
                                            </tr>
                                            <tr>
                                                <td>Leave Window</td>
                                                <td *ngIf="leaveWindowStartDate==1"> {{"Present Month
                                                    "+leaveWindowStartDate}} - {{"Present Month "+leaveWindowEndDate}}
                                                </td>
                                                <td *ngIf="leaveWindowStartDate!=1"> {{"Previous Month
                                                    "+leaveWindowStartDate}} - {{"Present Month "+leaveWindowEndDate}}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card card-stats card-warning cmn-leave">

                        <div class="card-body ">

                            <div class="row">
                                <div class="col-12">

                                    <div class="numbers text-center">
                                        <table class="table no-border mt-3">
                                            <tbody style="text-align:left;">
                                                <tr>
                                                    <td style="width:47%;">First Pay Period</td>
                                                    <td>Status</td>
                                                </tr>
                                                <tr style="text-decoration: underline;"></tr>
                                                <tr>
                                                    <td style="width:47%;">April - 2022</td>
                                                    <td>Inactive</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
            </div>
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="5">
            </div>
        </div>
    </mat-card>
</div>