<div fxLayout="Column">
  <mat-card class="sidePad">

    <div fxLayout="row" fxFlex="100" fxLayout.xs="column" fxLayoutGap="10px" ngStyle.xs="padding-top:6%">

      <!-- attendance card -->
      <mat-card *ngIf="isAttendanceModule" fxFlex="50" fxFlex class="card outline">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start">
          <span class="title">
            <span>Attendance</span>
          </span>
          <span *ngIf="isManager" fxLayoutAlign.lt-lg="end">
            <mat-radio-group>
              <mat-radio-button tabIndex="1" (keyup.enter)="getSelfAttendanceCount()" class="mx2 my-2 mr-2" value="self"
                [checked]='true' (click)="getSelfAttendanceCount()">
                <span class="radioBtn">Self</span>
              </mat-radio-button>
              <mat-radio-button tabIndex="2" (keyup.enter)="getTeamAttendanceCount()" class="mx2 my-2 mr-2" value="team"
                (click)="getTeamAttendanceCount()">
                <span class="radioBtn">Team</span>
              </mat-radio-button>
            </mat-radio-group>
          </span>
        </div>
        <div class="scrollbox">
          <div class="scrollbox-content">
            <div style="padding-bottom: 1%;min-height: 20vh;">
              <!-- self attendance -->
              <div fxLayout="column" *ngIf="!teamAttendanceCountData" flex="100" style="padding-top: 6%">

                <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" fxLayoutAlign="space-around center">
                  <!-- WFO -->
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">

                      <button *ngIf="employeeAttendanceCountData.wfo_count > 0" mat-icon-button (click)="selfOffice()"
                        [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.wfo_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.wfo_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.wfo_count}}
                      </span>
                    </div>
                    <span style="font-size: 16px;font-weight: 500;text-align: center;">From office</span>
                  </div>
                  <!-- WFM -->
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">

                      <button *ngIf="employeeAttendanceCountData.wfh_count > 0" mat-icon-button (click)="selfhome()"
                        [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.wfh_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.wfh_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.wfh_count}}
                      </span>
                    </div>
                    <span style="font-size: 16px;font-weight: 500;text-align: center;">From home</span>
                  </div>
                  <!-- On duty -->
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">

                      <button *ngIf="employeeAttendanceCountData.onduty_count > 0" mat-icon-button (click)="selfduty()"
                        [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.onduty_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.onduty_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.onduty_count}}
                      </span>
                    </div>
                    <span style="font-size: 16px;font-weight: 500;text-align: center;">On duty</span>
                  </div>
                  <!-- Absents -->
                  <div fxLayout="column">
                    <div fxLayoutAlign="center center" class="center">
                      <button *ngIf="employeeAttendanceCountData.absents_count > 0" mat-icon-button
                        (click)="selfAbsents()" [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.absents_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.absents_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.absents_count}}
                      </span>
                    </div>
                    <span style="font-size: 16px;font-weight: 500;text-align: center;">Absent</span>
                  </div>

                  <!-- Onduty -->
                  <!-- <div fxLayout="column" fxLayoutAlign="center center">
                            <div fxLayoutAlign="center center" class="center">

                              <button *ngIf="employeeAttendanceCountData.onduty_count > 0" mat-icon-button (click)="selfonduty()" [matMenuTriggerFor]="menu">
                                <span style="color: white; font-size: 25px;">
                                  {{employeeAttendanceCountData.onduty_count}}</span>
                              </button>
                              <span *ngIf="employeeAttendanceCountData.onduty_count==0" style="color: white; font-size: 25px;">
                                {{employeeAttendanceCountData.onduty_count}}
                               </span>
                            </div>
                            <span style="font-size: 16px;font-weight: 500;text-align: center;">Onduty</span>
                          </div> -->

                  <!-- self attendance detailed list -->
                  <mat-menu #menu="matMenu">
                    <span *ngIf="selfwfo">
                      <button mat-menu-item *ngFor="let v of workFromOfficeData ">
                        <span>{{v.attendance_date | date:'dd-MM-yyyy'}}</span>
                      </button>
                    </span>
                    <span *ngIf="selfwfh">
                      <button mat-menu-item *ngFor="let v of workFromHometData">
                        <span>{{v.attendance_date | date:'dd-MM-yyyy'}}</span>
                      </button>
                    </span>

                    <span *ngIf="selfAbsent">
                      <button mat-menu-item *ngFor="let v of absentEmployeesData">
                        <span>{{v.attendance_date | date:'dd-MM-yyyy'}}</span>
                      </button>
                    </span>

                    <span *ngIf="selfOnduty">
                      <button mat-menu-item *ngFor="let v of ondutyEmployeesData">
                        <span>{{v.attendance_date | date:'dd-MM-yyyy'}}</span>
                      </button>
                    </span>
                  </mat-menu>
                </div>
              </div>


              <!-- team attendance -->
              <div fxLayout="column" *ngIf="teamAttendanceCountData" flex="100">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayout.xs="column" fxLayoutAlign="space-between center"
                  flex="100">

                  <form [formGroup]="attendanceForm" style="padding-left: 2%;">

                    <span fxLayout="row">
                      <mat-form-field>
                        <input matInput readonly [matDatepicker]="currentDate" [min]="minFromDate" [max]="maxDate"
                          placeholder="" (click)="currentDate.open()" formControlName="currentDate" />
                        <mat-datepicker-toggle class="datepicker" matSuffix [for]="currentDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #currentDate></mat-datepicker>
                      </mat-form-field>
                    </span>
                  </form>
                  <div style="font-size: 16px;font-weight: 500;padding-right: 2%;">
                    Total Employees: <span>{{totalEmpCount}}</span></div>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayout.xs="column" fxLayoutAlign="space-around center">
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">
                      <button *ngIf="employeeAttendanceCountData.wfo_count > 0" mat-icon-button (click)="teamOffice()"
                        [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.wfo_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.wfo_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.wfo_count}}
                      </span>
                    </div>

                    <span style="font-size: 16px;font-weight: 500;text-align: center;">From office</span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">
                      <button *ngIf="employeeAttendanceCountData.wfh_count > 0" mat-icon-button (click)="teamhome()"
                        [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.wfh_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.wfh_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.wfh_count}}
                      </span>
                    </div>

                    <span style="font-size: 16px;font-weight: 500;text-align: center;">From home</span>
                  </div>
                  <!-- onduty -->
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="center">

                      <button *ngIf="employeeAttendanceCountData.onduty_count > 0" mat-icon-button
                        (click)="teamonduty()" [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.onduty_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.onduty_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.onduty_count}}
                      </span>
                    </div>
                    <span style="font-size: 16px;font-weight: 500;text-align: center;">On duty</span>
                  </div>

                  <div fxLayout="column">
                    <div fxLayoutAlign="center center" class="center">
                      <button *ngIf="employeeAttendanceCountData.absents_count > 0" mat-icon-button
                        (click)="teamAbsent()" [matMenuTriggerFor]="menu">
                        <span style="color: white; font-size: 25px;">
                          {{employeeAttendanceCountData.absents_count}}</span>
                      </button>
                      <span *ngIf="employeeAttendanceCountData.absents_count==0" style="color: white; font-size: 25px;">
                        {{employeeAttendanceCountData.absents_count}}
                      </span>
                    </div>
                    <span style="font-size: 14px;font-weight: 500;text-align: center;">Absent</span>
                  </div>


                  <!-- team attendance detailed list -->
                  <mat-menu #menu="matMenu">
                    <span *ngIf="selfwfo">
                      <button mat-menu-item *ngFor="let v of workFromOfficeData ">
                        <span>{{v.empname}}</span>
                      </button>
                    </span>
                    <span *ngIf="selfwfh">
                      <button mat-menu-item *ngFor="let v of workFromHometData">
                        <span>{{v.empname}}</span>
                      </button>
                    </span>

                    <span *ngIf="selfAbsent">
                      <button mat-menu-item *ngFor="let v of absentEmployeesData">
                        <span>{{v.empname}}</span>
                      </button>
                    </span>
                    <span *ngIf="selfOnduty">
                      <button mat-menu-item *ngFor="let v of ondutyEmployeesData">
                        <span>{{v.empname}}</span>
                      </button>
                    </span>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <!-- leave card -->
      <mat-card *ngIf="isLeaveModule" class="card outline" fxFlex="50">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxLayoutAlign.lt-lg="start">
          <span class="title">
            <span>Leaves</span>
          </span>
          <span *ngIf="isManager" fxLayoutAlign.lt-lg="end">
            <mat-radio-group>
              <mat-radio-button tabIndex="3" (keyup.enter)="getLeaveBalance()" class="mx2 my-2 mr-2" value="self"
                [checked]='true' (click)="getLeaveBalance()">
                <span class="radioBtn">Self</span>
              </mat-radio-button>
              <mat-radio-button tabIndex="4" (keyup.enter)="getCurrentLeaveEmployees()" class="mx2 my-2 mr-2"
                value="team" (click)="getCurrentLeaveEmployees()">
                <span class="radioBtn">Team</span>
              </mat-radio-button>
            </mat-radio-group>
          </span>
        </div>
        <div class="scrollbox">
          <div class="scrollbox-content">
            <div style="padding-top: 1%;padding-left: 1%;max-height: 30vh;min-height: 15vh;">
              <div *ngIf="!teamLeavesData">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="padding-left: 8px;">Leave Type</th>
                      <th style="text-align: center;">Used</th>
                      <th style="text-align: center;">Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of leavebalance">
                      <tr>
                        <td style="text-align: start;">{{item.display_name}}</td>
                        <td style="text-align: center;">{{ item.used }}</td>
                        <td style="text-align: center;">{{ item.leave_balance }}</td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="leavebalance && leavebalance.length === 0">
                      <td colspan="10" style="text-align: center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                </table>


              </div>
              <div *ngIf="teamLeavesData">
                <div *ngIf="employeesLeaveList.length > 0">
                  <mat-list class="time-attendance">
                    <ng-container *ngFor="let item of employeesLeaveList">
                      <mat-list-item>
                        <h3 matLine> {{ item.emp_name}} </h3>
                        <p>
                          {{ item.fromdate | date: "dd/MM/yyyy" }} - {{ item.todate | date: "dd/MM/yyyy" }}
                        </p>
                      </mat-list-item>
                    </ng-container>
                  </mat-list>
                </div>
                <div style="padding: 5%" *ngIf="employeesLeaveList.length == 0">
                  <div fxLayoutAlign="center">
                    <p><b>No data found</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </mat-card>
</div>