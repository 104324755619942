<div fxLayout="column" class="first-col">
    <mat-card class="mat-elevation-z0">
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> IT Declarations</mat-card-title>
            <div style="margin-right: 2%;" *ngIf="addNewField">
                <button mat-icon-button style="padding-right: 40px;" (click)="addNewSection()"
                    matTooltip="Add New Section">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>

        <form [formGroup]="employeeExemptionForm" autocomplete="off" *ngIf="displayForm">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                <mat-card fxFlex="60" style="padding-left: 1%">

                    <div fxLayout="row" fxLayout.xs="column" style="padding-top:1%">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Request Date</mat-label>
                            <input matInput formControlName="appliedDate" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Category</mat-label>
                            <mat-select formControlName="category" [tabIndex]="1">
                                <ng-container>
                                    <div *ngFor="let b of categoryList">
                                        <mat-option [value]="b.categoryId"
                                            (click)="getIncomeTaxSections()">{{b.categoryName}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.category.errors?.required">
                                Please select an option.
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.xs="column" style="padding-top:1%">

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Select a Section</mat-label>
                            <mat-select formControlName="section" [tabIndex]="1">
                                <ng-container>
                                    <div *ngFor="let b of sectionsList">
                                        <mat-option [value]="b.sectionId"
                                            (click)="insertMaxExemAmount(b.maxAmount,b.mappingId,b.description)">{{b.sectionName}}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.section.errors?.required">
                                Please select an option.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Receipt Number</mat-label>
                            <input matInput formControlName="receiptNumber" maxlength="30" required>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.receiptNumber.errors?.required">
                                This field is required.
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.xs="column" style="padding-top:1%">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Submit Amount</mat-label>
                            <input matInput formControlName="submitAmount" minlength="2" maxlength="6" required
                                onlyNumber (change)="onchangeAmount()">
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.submitAmount.errors?.required">
                                This field is required.
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Pending Amount</mat-label>
                            <input matInput formControlName="pendingAmount" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" fxLayout.xs="column" class="dev-Pad" style="padding-top: 5px;height: 70px;">
                        <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description" required maxlength="250"></textarea>
                            <mat-error class="con-error"
                                *ngIf="employeeExemptionForm.controls.description.errors?.required">
                                This field is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <br>
                    <br>
                    <div fxLayout="row" style="align-items: center;">
                        <span *ngIf="pdfName" class="no-break" style="margin-left: 7px;">Upload File:</span>&nbsp;
                        <button class="toolbar-button" ngClass.lt-md="mob_file" mat-button (click)="fileView();"
                            class="link" style="font-size: 16px;text-align: start;" *ngIf="pdfName">
                            {{ pdfName }}
                        </button>
                        &nbsp;
                        <input *ngIf="!pdfName" style="margin-left: 0px" type="file" formControlName="document"
                            (change)="onSelectFile($event)" single />
                        &nbsp;
                        <mat-icon class="toolbar-button" mat-button title="Edit" *ngIf="pdfName && !isApprove"
                            (click)="editdoc()">edit</mat-icon>
                        &nbsp;
                        <span>
                            <mat-icon title="Delete" *ngIf="employeeExemptionForm.controls.document.value && iseditDoc"
                                (click)="deleteIcon()">delete</mat-icon>
                        </span>
                        &nbsp;
                    </div>
                    <div fxLayoutAlign="center">
                        <button *ngIf="!isApprove" class="btn btn-primary mr-2 mb-2" style="padding: 0 10px"
                            (click)="submit()">
                            <span>Submit</span>
                        </button>
                        <button *ngIf="isUpdate" class="btn btn-primary mr-2 mb-2" style="padding: 0 10px"
                            (click)="submit()">
                            <span>Update</span>
                        </button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                            <span>Cancel</span>
                        </button>
                        <br />
                    </div>
                </mat-card>
                <mat-card fxFlex="40" style="padding-left: 1%; padding-top: 30px;">
                    <div *ngIf="displayMaxLimit">
                        <h2> Deduction towards payment mode to</h2>

                        <div>
                            <div fxFlex="30" style="margin-top: 40px; margin-left: 10px;">
                                <h2> section</h2>
                            </div>
                            <div fxFlex="70">
                                <div *ngFor=" let a of descriptionList"> {{a}} </div>
                            </div>
                        </div>
                    </div>
                    <div style="padding-top: 20px;" *ngIf="displayMaxLimit"> <b>Max. Limit:</b> Combined deduction limit
                        of <b>Rs {{maximumLimit}}/-</b>
                    </div>
                </mat-card>
            </div>

        </form>
        <div fxLayoutAlign="end" style="margin-top: 5px;" *ngIf="displayTable">
            <span style="padding-top: 5px;height: 70px;" fxFlex="50" fxLayoutAlign="end">
                <mat-form-field fxFlex="56" fxFlex.xs="100" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
                    <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                    <mat-label>Search</mat-label>
                    <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput placeholder="" maxlength="100">
                </mat-form-field>
            </span>
        </div>
        <div fxLayout="column" fxLayout.xs="column">
            <div class="example-container mat-elevation-z1" *ngIf="displayTable">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> S. No.</th>
                        <td mat-cell *matCellDef="let element; let i=index;">
                            {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                    </ng-container>


                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-break"> Date </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdOn | date:'dd-MM-yyyy' }}</td>
                    </ng-container>


                    <ng-container matColumnDef="section">
                        <th mat-header-cell *matHeaderCellDef class="no-break"> Section</th>
                        <td mat-cell *matCellDef="let row" class="no-break"> {{row.sectionName}} </td>
                    </ng-container>


                    <ng-container matColumnDef="submittedAmount">
                        <th mat-header-cell *matHeaderCellDef class="no-break">Submit Amount</th>
                        <td mat-cell *matCellDef="let row" class="no-break"> {{row.savingAmount }} </td>
                    </ng-container>

                    <ng-container matColumnDef="approvedAmount">
                        <th mat-header-cell *matHeaderCellDef> Approved Amount </th>
                        <td mat-cell *matCellDef="let row"
                            style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;">
                            {{row.approvedAmount }} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.description}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.statusName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" class="no-break">

                            <button *ngIf="row.statusName !='Approved' " mat-icon-button [tabIndex]="10"
                                (keyup.enter)="Edit(row)">
                                <mat-icon class="mr-2" title="Edit" (click)="Edit(row)">
                                    edit</mat-icon>
                            </button>

                            <button *ngIf="row.statusName ==='Approved' " mat-icon-button [tabIndex]="10"
                                (keyup.enter)="Edit(row)">
                                <mat-icon class="mr-2" title="View" (click)="viewData(row)">
                                    visibility</mat-icon>
                            </button>

                        </td>
                    </ng-container>
                    <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
            </div>
        </div>
    </mat-card>
</div>