import { Component, OnInit,Inject,LOCALE_ID } from '@angular/core';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import {UntypedFormGroup,UntypedFormBuilder,  Validators,UntypedFormControl  } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import {CrmService} from "../../crm.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { visitLexicalEnvironment } from 'typescript';
import { tr } from 'date-fns/locale';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-crm-add-transaction',
  templateUrl: './crm-add-transaction.component.html',
  styleUrls: ['./crm-add-transaction.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmAddTransactionComponent implements OnInit {
  paymentsData:any = [];
  paymentModes:any =[];
  collectedTotal:any = 0;
  isWebRestricted:any = 1;
  userSession:any;
  addPayment:any;
  formattedDate:any;
  pageTitle:any = 'Add Payment';
  trans_id:any = 0;
  editMode:any = false;
  currentDate:any;
  pending_amount:any = 0;
  constructor( @Inject(LOCALE_ID) private locale: string,
  private FormBuilder:UntypedFormBuilder, private pipe: DatePipe, private dialog: MatDialog,private crm:CrmService,
    public dialogRef: MatDialogRef<CrmAddTransactionComponent>,private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.pipe = new DatePipe(this.locale);
    this.getPaymentModes();
    if(this.data && this.data.trans_id){
      this.trans_id = this.data.trans_id;
      this.pageTitle = 'Edit Payment';
    } else {
      this.editMode = true;
    }
    if(this.data && this.data.final_price){
      if(this.data.collected_total){
        this.pending_amount = this.data.final_price - this.data.collected_total;
      }
      else {
        this.pending_amount = this.data.final_price;
      }
    }
    this.formattedDate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.isCrmWebDisabled !== undefined) {
      this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
    }
    this.addPaymentFormBuild();

    this.addPayment.get('collectedAmount')?.valueChanges.subscribe((selectedValue: any) => {
      this.addPayment.controls.pendingAmount.setValue(Number(this.pending_amount - selectedValue).toFixed(2) );
    });
  }

  addPaymentFormBuild(){
    this.addPayment = this.FormBuilder.group({
      clientName:[{value:this.data.client_name,disabled:true},Validators.required],
      finalPrice:[{value:this.data.final_price,disabled:true},Validators.required],
      collectedDate:[{value:this.formattedDate,disabled:true},Validators.required],
      collectedAmount:[{value:'',disabled:false},Validators.required],
      pendingAmount:[{value:Number(this.pending_amount).toFixed(2),disabled:true},Validators.required],
      modeId:[{value:'',disabled:false},Validators.required],
      paymentNotes:[{value:'',disabled:false},Validators.required],
    });
  }
  getPaymentModes(){
    this.crm.getCrmPaymentModes().subscribe((res:any)=>{
      if(res.status) 
        this.paymentModes =  res.data;
      else 
        this.paymentModes = [];
    })
   }
  backArrow(){
    this.dialogRef.close();
  }
  formatTime(date:any): string {
    // Extract hours, minutes, and seconds
    //let date = new Date();
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    // Format the time as HH:mm:ss
    return `${hours}:${minutes}`;
    
  }
  padZero(value: number): string {     
    return value < 10 ? `0${value}` : `${value}`;
  }
  checkValues(){
    let status = false;
    if(isNaN(this.addPayment.controls.collectedAmount.value) || this.addPayment.controls.collectedAmount.value == 0){
      this.addPayment.get('collectedAmount').setErrors('Please enter collected amount');
      this.addPayment.get('collectedAmount').reset();
      status = true;
    }
    if(this.addPayment.controls.modeId.value == 0){
      this.addPayment.get('modeId').setErrors('Please select payment method');
      this.addPayment.get('modeId').reset();
      status = true;
    }
    if(this.addPayment.controls.paymentNotes.value && this.addPayment.controls.paymentNotes.value.toString().length == 0){
      this.addPayment.get('paymentNotes').setErrors('Please enter description');
      this.addPayment.get('paymentNotes').reset(); 
      status = true;
    }
    return status;
  }
  setPayment(){
    if(this.isWebRestricted){
      this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:'Permission denied' });
      return;
    }
    //this.addPayment.controls.paymentNotes.setErrors('Please enter description');
    //this.addPayment.get('collectedAmount').setErrors('Please enter collected amount');
    //this.addPayment.get('paymentNotes').setErrors('Please enter description');
    //this.addPayment.get('modeId').setErrors('Please select payment method');
      
    if(!this.addPayment.valid){
      return;
    }
      this.spinner.show();
          let created_on = (this.pipe.transform(new Date(), 'yyyy-MM-dd'))+' '+this.formatTime(new Date());
          let paymentInfo={
            'trans_id':this.data.trans_id,
            'client_id': this.data.client_id,
            'product_id':0,
            'amount': this.addPayment.controls.collectedAmount.value,
            'mode_id': this.addPayment.controls.modeId.value,
            'description' :this.addPayment.controls.paymentNotes.value,
            'created_on':created_on,
            'created_by':this.userSession.id 
            }

          let resMessage = 'Payment not saved successfully';
            this.crm.setCrmClientTransaction(paymentInfo).subscribe((res:any) => {
              this.spinner.hide();
              if (res && res.status) {
                if(res.data && res.data[0]){
                  resMessage =  res.data[0].resultmsg ? res.data[0].resultmsg: resMessage;
                  if(res.data[0].successState === 0){
                    this.dialogRef.close();
                  }
                }
              }  
              this.dialog.open(ReusableDialogComponent, {position:{top:`70px`}, disableClose: true,data:resMessage });
            })
        
  }

  addPaymentFormReset(){
    this.addPayment.reset();
  }
  closePayment(){
    // this.addPayment.reset();
     this.addPayment.controls.collectedDate.patchValue(this.formattedDate);
     this.addPayment.controls.pendingAmount.patchValue(Number(this.pending_amount).toFixed(2));
     this.addPayment.controls.clientName.patchValue(this.data.client_name);
     this.addPayment.controls.finalPrice.patchValue(Number(this.data.final_price).toFixed(2));
     //this.addPayment.controls.modeId.patchValue(0);
     this.addPayment.get('modeId').reset(null);
     //this.addPayment.get('modeId').setErrors('');
     
     //this.addPayment.controls.paymentNotes.patchValue('');
     this.addPayment.get('paymentNotes').reset(null);
     //this.addPayment.get('paymentNotes').setErrors('');
   
     //this.addPayment.controls.collectedAmount.patchValue('');
     this.addPayment.get('collectedAmount').reset(null);
     //this.addPayment.get('collectedAmount').setErrors('');

     //this.addPaymentFormBuild();
  
  }
}
