<div fxLayout="column">
    <form [formGroup]="documentTypeForm" autocomplete="off">
          <mat-card-header class="">
                <mat-card-title> Document Category </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
                <div fxLayout="row">
                    <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                        <mat-label>Document Category</mat-label>
                        <input formControlName="document" matInput placeholder="" noLeadingWhitespace required
                            maxlength="100">
                        <mat-error class="con-error"
                         *ngIf="documentTypeForm.controls.document.errors?.required">
      This field is required
    </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayoutAlign="center">

                    <button class="col-md-1 btn btn-primary mx-2 my-2" [disabled]="!documentTypeForm.valid"
                        (click)="saveDocument()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="col-md-1 btn btn-danger mx-2 my-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>


            </div>
            <br>

            <div fxLayout="column" class="tableP" *ngIf="isdata">
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">
                    <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue"
                        (click)="Add()" type="button">
                        <span>Add</span>
                    </button>
                </div><br>
                <div class="mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                        </ng-container>

                        <ng-container matColumnDef="termination">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Category </th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="enable != row.id">{{row.category}}&nbsp;</span>
                                <mat-form-field appearance="outline" *ngIf="enable === row.id">
                                    <input formControlName="document" value="row.category" matInput placeholder="">
                                    <mat-error class="con-error" *ngIf="documentTypeForm.controls.document.errors?.required">
                                        Please enter name
                                    </mat-error>
                                </mat-form-field>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-radio-group>
                                    <mat-radio-button class="mr-2" color="primary"
                                        *ngFor="let list of statusList;index as i;"
                                        (change)="statusUpdate(list.id,row)"
                                        [checked]="list.id == row.status" [value]="list.id">{{list.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button matTooltip="Edit" *ngIf="enable != row.id">
                                    <mat-icon (click)="edit($event,row)">edit</mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Save" *ngIf="enable == row.id">
                                    <mat-icon
                                        (click)="updateData(documentTypeForm.controls.document.value,row)">
                                        save</mat-icon>
                                </button>

                                <button mat-icon-button *ngIf="enable == row.id" matTooltip="Cancel">
                                    <mat-icon (click)="canceledit($event,row.id)" *ngIf="enable == row.id">cancel
                                    </mat-icon>
                                </button>

                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="6">No data found</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                </div>


            </div>
 </form>
</div>
