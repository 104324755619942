import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from 'src/app/services/main.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from '../../reusable-dialog/reusable-dialog.component';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-customer-tickets',
  templateUrl: './customer-tickets.component.html',
  styleUrls: ['./customer-tickets.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CustomerTicketsComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private moduleService: LoginService, private mainService: MainService,
    private dialog: MatDialog, private router: Router, public datePipe: DatePipe) { }
  displayedColumns: string[] = ['sno', 'ticketno', 'issuedate', 'module', 'screen', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  raiseTicketForm: any = UntypedFormGroup;
  ticketHistoryForm: any = UntypedFormGroup;
  allModuleDetails: any = [];
  ticketsList: any = [

  ];
  companyName: any;
  selectedModule: any;
  fileURL: any;
  pdfName: any = null;
  document: boolean = false;
  iseditDoc: boolean = true;
  isFile: boolean = true;
  pipe = new DatePipe('en-US');
  allScreensList: any = [];
  displayStatus: boolean = false;
  minDate = new Date('2020/01/01'); maxDate = new Date();
  selectedtab = new FormControl(0);
  userSession: any;
  companyId: any;
  ticketId: any;
  isNew: boolean = false;
  saveBtnView: boolean = true;
  isEdit: boolean = false;
  ticketHistory: any = [];
  firstDayOfMonth = new Date();
  ticketCode: any;
  lengthOfTicketHistory: any;


  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName');
    this.companyId = sessionStorage.getItem('client_Id');
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.raiseTicketForm = this.formBuilder.group({
      appliedDate: [{ value: new Date(), disabled: true }],
      modules: [''],
      screens: [''],
      description: [''],
      document: [''],
      issueStatus: [''],
      remarks: [''],
    });
    const today = new Date();
    this.firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.ticketHistoryForm = this.formBuilder.group({
      fromDate: [this.firstDayOfMonth],
      toDate: [new Date()],
    })
    this.getModules();
    this.getEmployeeTicketList();
    this.raiseTicketForm.get('modules')?.valueChanges.subscribe((selectedValue: any) => {
      this.selectedModule = selectedValue;
      this.getScreensList();
    })

  }
  ispdf: boolean = false;
  file: any;

  onSelectFile(event: any) {

    this.iseditDoc = true;

    if (event.target.files[0].size <= 1242880) {
      //  var pdfArray =[];

      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');

      if (pdf[pdf.length - 1] == 'pdf' || pdf[pdf.length - 1] == 'jpg' || pdf[pdf.length - 1] == 'png') {
        this.isFile = true;

      } else {
        this.ispdf = true;
        this.isFile = false;

        this.open("Only JPG,PNG and PDF files supported", '8%', '500px', '250px', false, null)


      }

    } else {
      this.ispdf = false;
      this.isFile = false;

      this.open("File size must be less than 1MB", '8%', '500px', '250px', false, null)
    }
  }

  delete() {
    this.raiseTicketForm.controls.document.setValue("");
  }
  newTicket() {
    this.isNew = true;

  }
  tabClick(data: any) {
    if (data.index === 0) {

    } else {
      this.searchform();
    }
  }
  getModules() {
    this.mainService.getActiveModules().subscribe((result: any) => {
      if (result && result.status) {
        this.allModuleDetails = result.data;
      }
    });
  }

  getScreensList() {
    this.mainService.getScreenMaster(this.selectedModule).subscribe((result: any) => {
      if (result && result.status) {
        this.allScreensList = result.data[0];
      }
    });
  }

  fileView() {

    window.open(this.fileURL);

  }
  editdoc() {
    this.pdfName = null;
  }

  open(errormessages: any, top: any, width: any, height: any, flag: any, url: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, { position: { top: `70px` }, data: { "Message": errormessages, flag: flag } });
    dialogRef.afterClosed().subscribe(result => { });
  }

  submit() {

    if (this.raiseTicketForm.valid) {
      let data = {
        'id_value': this.ticketId == null || '' ? null : this.ticketId,
        'company_id_value': parseInt(this.companyId),
        'created_by_value': this.userSession.id,
        'screen_id_value': this.raiseTicketForm.controls.screens.value,
        'issue_description_value': this.raiseTicketForm.controls.description.value,
      }
      this.mainService.setHelpTicketCreation(data).subscribe((res: any) => {
        if (res.data.statuscode == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Ticket raised successfully."
          });
          this.clear();
        } else if (res.data.statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Ticket data modified successfully."
          });
          this.clear();
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to raise ticket."
          });
        }
      })
    }
  }

  searchform() {
    this.getEmployeeTicketList();
  }

  getEmployeeTicketList() {
    let data = {
      'company_id_value': this.companyId,
      'created_by_value': this.userSession.id,
      'from_date_value': this.datePipe.transform(this.ticketHistoryForm.controls.fromDate.value, "y-MM-d"),
      'to_date_value': this.datePipe.transform(this.ticketHistoryForm.controls.toDate.value, "y-MM-d")
    }
    this.mainService.getEmployeeTicket(data).subscribe((res: any) => {
      if (res.status) {
        this.ticketsList = res.data
        this.pageLoading = false;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  clear() {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/main/raise_ticket"]));
  }

  edit(data: any) {
    this.isNew = true;
    this.saveBtnView = true;
    this.isEdit = true;
    this.ticketId = data.ticketId;
    this.raiseTicketForm.controls.appliedDate.setValue(data.issueDate);
    this.raiseTicketForm.controls.modules.setValue(data.moduleId);
    this.raiseTicketForm.controls.screens.setValue(data.screenId);
    this.raiseTicketForm.controls.description.setValue(data.description);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  viewStatus(details: any) {
    this.isNew = true;
    this.saveBtnView = false;
    this.ticketId = details.ticketId;
    this.raiseTicketForm.controls.appliedDate.setValue(details.issueDate);
    this.raiseTicketForm.controls.modules.setValue(details.moduleId);
    this.raiseTicketForm.controls.modules.disable();
    this.raiseTicketForm.controls.screens.setValue(details.screenId);
    this.raiseTicketForm.controls.screens.disable();
    this.raiseTicketForm.controls.description.setValue(details.description);
    this.raiseTicketForm.controls.description.disable();
    this.raiseTicketForm.controls.issueStatus.setValue(details.ticketStatus);
    this.raiseTicketForm.controls.issueStatus.disable();
    this.raiseTicketForm.controls.remarks.setValue(details.trackingDescription);
    this.raiseTicketForm.controls.remarks.disable();
    this.getTicketHistory()
  }

  deleteRecord(data: any) {

  }
  getTicketHistory() {
    this.mainService.getTicketHistory(this.ticketId).subscribe((res: any) => {
      if (res.status) {
        this.ticketHistory = res.data;
        this.ticketCode = this.ticketHistory[0].ticketCode;
        this.lengthOfTicketHistory = this.ticketHistory.length
      }
    })
  }
  // trackByFn(index: number, item: any) {
  //   return index;
  // }
}
