<div fxLayout="column" fxFill class="custom-investment-of first-col">
    <mat-card fxFill>
      <mat-card-header class="headerbox" fxLayoutAlign="space-between center">
    <mat-card-title class="title"> Pay Group </mat-card-title>
    <span *ngIf="isadd">
      <button mat-icon-button style="padding-right: 40px;" [tabIndex]="[1]" (keyup.enter)="onRequestClick()" (click)="onRequestClick()" matTooltip="Add">
        <mat-icon style="font-size: 30px;overflow: visible;color: #ffffff">add_circle</mat-icon>
      </button>
    </span>
   
  </mat-card-header>

      <div fxLayout="row" fxLayout.xs="column" class="custom-card">  
                 
                <div fxLayout="column" fxFlex="100" class="custom-payroll-div div-pad">
                  <div class="mat-elevation-z1 example-container">
                  <table mat-table [dataSource]="dataSource"   multiTemplateDataRows  style="width:100%;display:table;">
                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                          <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Name</th>
                          <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.group_name}} </td>
                        </ng-container>                                            
                        <!-- Start Range Column -->
                        <ng-container matColumnDef="Start_Range">
                          <th mat-header-cell *matHeaderCellDef class="custom-investment-th">Start Range</th>
                          <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.income_from_value}} </td>
                        </ng-container>

                        <!-- End Range Column -->
                        <ng-container matColumnDef="End_Range">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th">End Range</th>
                            <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.income_to_value}} </td>
                        </ng-container>

                        <!-- Earnings Column -->
                        <ng-container matColumnDef="Earnings">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th">Earnings</th>
                            <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.Earnings}} </td>
                          </ng-container>
                        
                          <!-- Deductions Column -->
                          <ng-container matColumnDef="Deductions">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Deductions</th>
                            <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.Deductions}} </td>
                          </ng-container>
  
                          <!-- Status Column -->
                          <ng-container matColumnDef="Status">
                              <th mat-header-cell *matHeaderCellDef class="custom-investment-th">Status</th>
                              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.status}} </td>
                          </ng-container>
                          
                          <!-- Action Column -->
                          <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th"></th>
                            <td mat-cell *matCellDef="let element;let i = index;" class="custom-investment-td"> 
                         <span *ngIf="!isview">
                              <button mat-icon-button matTooltip="Configure"  [tabindex]="[i+1]" (keyup.enter)="navigate(element,true)"  *ngIf="element.status != 'Active' && true">
                              <mat-icon style="cursor: pointer;" title="Configure" (click)="navigate(element,true)" *ngIf="element.status != 'Active'">settings</mat-icon>
                              </button>
                              <button mat-icon-button matTooltip="Edit"  [tabindex]="[i+1]" (keyup.enter)="navigate(element,true)" *ngIf="element.status == 'Active' && true">

                              <mat-icon style="cursor: pointer;" title="Edit" (click)="navigate(element,true)" *ngIf="element.status == 'Active'">edit</mat-icon>
                              </button>
                            </span>
                              <button mat-icon-button matTooltip="View"  [tabindex]="[i+1]" (keyup.enter)="navigate(element,false)" *ngIf="isview">

                                <mat-icon style="cursor: pointer;" title="View" (click)="navigate(element,false)" >visibility</mat-icon>
                                </button>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                              <!-- <div class="example-element-detail" [@detailExpand]="
                                  element == expandedElement ? 'expanded' : 'collapsed'
                                "> -->
                                <div class="example-element-detail" [@detailExpand]="
                                  element == expandedElement ? 'collapsed' : 'collapsed'
                                ">
                                <div class="table-expanded-description" style="width:100%">
                                  <div class="table-responsive custom-investment-of">
                                    <form [formGroup]="payGroupRequestForm" autocomplete="off" style="width:100%">  
                                      <div> 
                                     <div fxLayout="column" fxFlex="5">
                                     </div>       
                                     <div fxLayout="column" fxFlex="40" class="custom-payroll-div">
                                                         
                                           <div>
                                           
                                                 <p>                        
                                                 <mat-form-field appearance="outline" style="width: 100%;">
                                                     <mat-label>Name of the Pay Group</mat-label>
                                                     <input formControlName="payNameGroup" matInput placeholder="Name of the Pay Group" >
                                                 </mat-form-field>
                                                 </p>
                                                 <p>
                                                     <label style="font-size: 18px !important;">Annual CTC</label>    
                                                 </p>    
                                                 <p>
                                                 
                                                 <mat-form-field appearance="outline" style="width: 100%;">
                                                     <mat-label>Start Range</mat-label>
                                                     <input formControlName="start_range" matInput placeholder="Start Range" >
                                                 </mat-form-field>
                                                 </p>
                                                
                                                
                                                 <p>
                                                     <mat-expansion-panel hideToggle style="box-shadow: none !important;">
                                                         <mat-expansion-panel-header style="padding: 0 !important;" (click)="validateExpansion('earnings')">
                                                           <mat-panel-title>
                                                             <span class="material-icons" *ngIf="isEarnings" style="margin-right: 5px;color:#28acaf;">add_box</span>
                                                             <span class="material-icons" *ngIf="!isEarnings" style="margin-right: 5px;color:#28acaf;">indeterminate_check_box</span>
                                                             
                                                             <p style="text-decoration: underline;color:#28acaf;">Earnings</p>
                                                           </mat-panel-title>
                                                         </mat-expansion-panel-header>
                                                         <div>
                                                          <p formArrayName="earnings" *ngFor="let order of earningsFormArray.controls; let i = index">
                                                            <input type="checkbox" [formControlName]="i">
                                                            {{earningData[i].component}}
                                                        </p>
                                                              <p><mat-checkbox formControlName="basic">Basic</mat-checkbox></p>
                                                             <p><mat-checkbox formControlName="hra">HRA</mat-checkbox></p>
                                                             <p><mat-checkbox formControlName="conveyance">Conveyance</mat-checkbox></p>
                                                             <p><mat-checkbox formControlName="fixedAllowance">Fixed Allowance</mat-checkbox></p> 
                                                         </div>
                                             
                                                     </mat-expansion-panel>
                                                 </p>
                                                
                         
                                                 <p>                           
                                                     <mat-form-field appearance="outline" style="width: 100%;">
                                                         <mat-label>Status</mat-label>
                                                         <mat-select formControlName="status">
                                                             <mat-option value="active">Active</mat-option>
                                                             <mat-option value="inactive">Inactive</mat-option>
                                                         </mat-select>
                                                     </mat-form-field>
                                                 </p>
                                            
                                                   
                                                 
                                           </div> 
                         
                                         </div>
                                      <div fxLayout="column" fxFlex="5">
                                     </div>
                                     <div fxLayout="column" fxFlex="45">
                                         <p style="margin-bottom:159px"></p>
                                         <p>                        
                                             <mat-form-field appearance="outline" style="width: 100%;">
                                                 <mat-label>End Range</mat-label>
                                                 <input formControlName="end_range" matInput placeholder="End Range" >
                                             </mat-form-field>
                                         </p>
                                        
                                         <p>
                                             <mat-expansion-panel hideToggle style="box-shadow: none !important;">
                                                 <mat-expansion-panel-header style="padding: 0 !important;" (click)="validateExpansion('earnings')">
                                                   <mat-panel-title>
                                                     <span class="material-icons" *ngIf="isEarnings" style="margin-right: 5px;color:#28acaf;">add_box</span>
                                                     <span class="material-icons" *ngIf="!isEarnings" style="margin-right: 5px;color:#28acaf;">indeterminate_check_box</span>
                                                     
                                                     <p style="text-decoration: underline;color:#28acaf;">Deductions</p>
                                                   </mat-panel-title>
                                                 </mat-expansion-panel-header>
                                                 <div>
                                                  <p formArrayName="deducts" *ngFor="let order of deductionFormArray.controls; let i = index">
                                                    <input type="checkbox" [formControlName]="i">
                                                    {{deductionData[i].component}}
                                                </p>
                                                     <!-- <p><mat-checkbox formControlName="esi">ESI</mat-checkbox></p>
                                                     <p><mat-checkbox formControlName="tds">TDS</mat-checkbox></p>
                                                     <p><mat-checkbox formControlName="loanDeductions">Loan Deductions</mat-checkbox></p>
                                                     <p><mat-checkbox formControlName="insurance">Insurance</mat-checkbox></p> -->
                                                 </div>
                                     
                                             </mat-expansion-panel>
                                         </p>
                                         <p>                          
                                             <mat-form-field appearance="outline" style="width: 100%;">
                                                 <mat-label>Descriptions</mat-label>
                                                 <textarea formControlName="descriptions" matInput placeholder="Descriptions" ></textarea>
                                             </mat-form-field>
                                         </p>
                                     </div>
                                     </div>
                                     <div class="form-group row" style="margin-bottom: 15px !important;">
                                         <div class="col-sm-6 text-right" style="padding:0px">
                                         <button class="mx-1 mat-stroked-button button-save"  style="background-color:#3f51b5;color:aliceblue" (click)="setPayGroup()"   type="submit">
                                             <span>Save</span>
                                         </button>
                                         </div>
                                         <div class="col-sm-6">
                                         <button class="mx-1  mat-stroked-button button-clear" style="background-color:#550534;color:aliceblue" (click)="cancel()">
                                             <span >Cancel</span>
                                         </button>
                                         </div>
                                     </div>
                                 </form>
                         
                                  </div>
                                  </div>
                              </div>
                            </td>
                          </ng-container>
                        <tr mat-header-row  class="dashboard-1 custom-investment-header" *matHeaderRowDef="displayedColumns;"></tr>
                        <tr mat-row 
                        *matRowDef="let row; columns: displayedColumns;"   [class.example-expanded-row]="expandedElement == row" (click)="
                        expandedElement = expandedElement == row ? null : row
                      " class="example-element-row"></tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7"style="text-align: center;">No data found</td>
                      </tr>
                 </table>
                 <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                </div>

                </div>
           
        </div>
    </mat-card>
</div>                    
