<div [formGroup]="ProfessionalTaxForm" style="padding-top: 1%;">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" style="margin-left: 10px;">
        <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Month and year</mat-label>
                <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
                    [min]="minDate" [max]="max" (click)="fromDate.open()">
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)"
                    panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex class="mx-2 my-2" *ngIf="statehide">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                    <ng-container>
                        <div *ngFor="let b of stateslist">
                            <mat-option value="{{ b.id }}">{{
                                b.state
                                }}</mat-option>
                        </div>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
    <div fxLayout="row" fxLayout.xs="column" style="padding-left: 22px;">
        <mat-radio-group formControlName="changePaygroup" style="display: grid;">
            <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;"
                [value]="halfs.Value" [checked]="halfs.checked">{{halfs.name}}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div style="text-align: center;">
        <span>
            <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabindex="3">
                Search
            </button>
            <span class="line" style="color: #1898d5; font-size: 16px"></span>
            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabindex="4">
                <span>Clear</span>
            </button>
        </span>
    </div>
    <div fxLayout="row xs-column" fxLayoutAlign="end" style="margin-right: 3%;padding-bottom: 10px;"
        *ngIf="reporthide || detailreporthide">
        <div>
            <span class="exlprint"> | </span>
            <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png" width="20px"
                    height="20px"></a>

            <span class="exlprint"> | </span>
            <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
            </a>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div style="margin-top:5px;padding-right: 2%;" fxLayout="row xs-column" fxLayoutAlign="end  center"
    *ngIf="reporthide || detailreporthide">
    <div>
        <span class="exlprint">
            <mat-form-field appearance="outline">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
            </mat-form-field>
        </span>
        <!-- <span class="exlprint"> | </span>
        <a title="PDF"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px"></a>

        <span class="exlprint"> | </span>
        <a title="Excel"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
        </a> -->
    </div>
</div>


<div class="tablePadding">

    <div class="mat-elevation-z1 example-container" *ngIf="reporthide">
        <div id="table" #table=''>
            <table id="table" mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> S. No.</th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                        {{i+1}}
                        <!-- {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }}  -->
                    </td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> State</th>
                    <td mat-cell *matCellDef="let row"><span>{{row.state}} </span></td>
                </ng-container>
                <ng-container matColumnDef="payrange">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Pay Range </th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.salary_from_value}}-{{row.salary_to_value==null?'above':row.salary_to_value}}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Tax Rate (%)
                    </th>
                    <td mat-cell *matCellDef="let row"><span>{{row.tax_value}} </span></td>
                </ng-container>
                <ng-container matColumnDef="employees">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">No. of Employees </th>
                    <td mat-cell *matCellDef="let row"><span>{{row.total}} </span></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                </tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>



    </div>

    <div class="mat-elevation-z1 example-container" *ngIf="detailreporthide">
        <div id="table" #table=''>
            <table id="table" mat-table [dataSource]="dataSource2" matSort>

                <ng-container matColumnDef="Employee_Id">
                    <th mat-header-cell *matHeaderCellDef> Emp. ID</th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Employee_Id}}</b> </span>
                        <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Employee_Id}} </span>

                    </td>
                </ng-container>
                <ng-container matColumnDef="Employee_Name">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp. Name</th>
                    <td mat-cell *matCellDef="let row">

                        <span *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Employee_Name}}</b> </span>
                        <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Employee_Name}} </span>

                    </td>
                </ng-container>
                <ng-container matColumnDef="Gross_Salary">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Gross Salary
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Gross_Salary}}</b> </span>
                        <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Gross_Salary}} </span>

                    </td>
                </ng-container>
                <ng-container matColumnDef="Professionam_Tax">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Professional Tax </th>
                    <td mat-cell *matCellDef="let row">

                        <span *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Professionam_Tax}}</b> </span>
                        <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Professionam_Tax}} </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                </tr>
            </table>
        </div>
        <!-- <mat-paginator [pageSizeOptions]="!pageLoading2 ? getPageSizes2() : [5, 10, 20]"></mat-paginator> -->



    </div>


</div>