
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AttendanceService } from '../../attendance.service';
import { UserData } from '../../models/EmployeeData';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { HalfdayDialogboxComponent } from '../../models/halfday-dialogbox/halfday-dialogbox.component';
import { MatRadioChange } from '@angular/material/radio';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';
@Component({
  selector: 'app-manager-attendance-regularization',
  templateUrl: './manager-attendance-regularization.component.html',
  styleUrls: ['./manager-attendance-regularization.component.scss'],
})
export class ManagerAttendanceRegularizationComponent implements OnInit {
  attendanceRequestForm: any = UntypedFormGroup;
  displayedColumns: string[] = [
    'select',
    'sno',
    'empoyeeid',
    'empname',
    'shift',
    'intime',
    'outtime',
    'action',
  ];
  displayedColumns2: string[] = [
    'sno',
    'empoyeeid',
    'empname',
    'shift',
    'intime',
    'outtime',
    'action',
  ];
  dataSource: MatTableDataSource<UserData> = <any>[];
  dataSource2: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  arrayList: UserData[] = [];
  userSession: any;
  titleName: string = '';
  reason: any;
  requestData: any;
  leavetypeid: any;
  iseditouttime: boolean = false;
  enable: any = null;
  originalleavecount: any;
  leavecontvalue: any;
  isedited: boolean = false;
  leavedeductionpolicydata: any;
  leavedeductionpolicydata2: any;
  fullDayList: any = [];
  halfDayList: any = [];
  halfdayData: any;
  L1: any;
  L2: any;
  isHalfday:any;
  selection = new SelectionModel<any>(true, []);
  selectedEmps: any = [];
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private LM: LeavesService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    for (let i = 0; i < this.userSession.roles.length; i++) {
      if (this.userSession.roles[i].role_name == 'Manager') {
        this.getEmployeesForRegularization();
      }
    }
  }
  isFullday: boolean = true;
  isHalfdayView:boolean = false;
  isLoading = true;
  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.attendanceRequestForm = this.formBuilder.group({
      intime: [''],
      outtime: [''],
    });
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.getEmployeesForRegularization();
      this.isFullday=true;
      this.isHalfdayView=false;

    } else if (event.value == 2) {
      this.getHalfdayEmployeesForRegularization();
      this.isFullday = false;
      this.isHalfdayView = true;

    }
  }
      /** Selects all rows if they are not all selected; otherwise clear selection. */
masterToggle() {
  // if there is a selection then clear that selection
  if (this.isSomeSelected()) {
    this.selection.clear();
  } else {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
}
isSomeSelected() {
  return this.selection.selected.length > 0;
}
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}

  edit(data: any) {
    let checkin = data.logintime != null ? data.logintime.split(':') : '';
    let checkout = data.logouttime != null ? data.logouttime.split(':') : '';
    let checkindata =
      data.logintime != null ? checkin[0] + checkin[1] + checkin[2] : '';
    let checkoutdata =
      data.logouttime != null ? checkout[0] + checkout[1] + checkout[2] : '';
    this.enable = data.id;
    this.isedited = true;
    this.attendanceRequestForm.controls.intime.setValue(checkindata);
    this.attendanceRequestForm.controls.outtime.setValue(checkoutdata);
  }
  canceledit() {
    this.enable = null;
    this.isedited = false;
    this.iseditouttime = false;
  }
  saveRegularization(data: any) {

    let outTimeValid = this.attendanceRequestForm.controls.outtime.value;
    if (outTimeValid != null && outTimeValid != '' && outTimeValid != '000000') {
      let IHH = Number(
        this.attendanceRequestForm.controls.intime.value[0] +
        this.attendanceRequestForm.controls.intime.value[1]
      );
      let IMM = Number(
        this.attendanceRequestForm.controls.intime.value[2] +
        this.attendanceRequestForm.controls.intime.value[3]
      );
      let ISS = Number(
        this.attendanceRequestForm.controls.intime.value[4] +
        this.attendanceRequestForm.controls.intime.value[5]
      );
      let OHH = Number(
        this.attendanceRequestForm.controls.outtime.value[0] +
        this.attendanceRequestForm.controls.outtime.value[1]
      );
      let OMM = Number(
        this.attendanceRequestForm.controls.outtime.value[2] +
        this.attendanceRequestForm.controls.outtime.value[3]
      );
      let OSS = Number(
        this.attendanceRequestForm.controls.outtime.value[4] +
        this.attendanceRequestForm.controls.outtime.value[5]
      );
      let str1 = data.shift_start_time.split(':');
      let str2 = data.shift_end_time.split(':');

      let stime = Number(str1[0]);
      // if (stime >= IHH) {

      if (Number(str2[0] >= OHH)) {
        this.selectedEmps = [];

        let setdata = {
          emp_id: data.empid,
          shiftid: data.shiftid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          logintime: IHH + ':' + IMM + ':' + ISS,
          logouttime: OHH + ':' + OMM + ':' + OSS,
          worktype: data.worktypeid,
          halfday: data.halfday == null ? 0 : data.halfday,
          raisedby: data.empid,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Approved',
          locationid: data.locationid,
        };
        
        this.selectedEmps.push(setdata);
    
        let obj = {
          input_data: this.selectedEmps,
        }

        this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {
          if (result.status && result.data[0].validity_status == 1) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Attendance regularization saved successfully"
            });
            this.getEmployeesForRegularization();

          } else if(result.status && result.data[0].validity_status== 0){
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Record already exist',
            });
       
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Unable to save attendance regularization"
            });
         }
        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Selected Time must less than shift time.',
        });
      }

      // } else {
      //   let dialogRef = this.dialog.open(ReusableDialogComponent, {
      //     position: { top: `70px` },
      //     disableClose: true,
      //     data: 'In time and Out time between 24 hours',
      //   });
      // }

    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Please enter out time."
      });
    }

  }

  applyFilter(event: Event) {
    if(this.isHalfdayView ==false){
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource2.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource2.paginator) {
        this.dataSource2.paginator.firstPage();
      }
    }

  }

  changeTab(elment: UserData) {
    this.router.navigate(['/Attendance/Approval'], {
      state: { userData: elment, url: 'ApprovedHistory' },
    });
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length < 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  setAttendanceRegularization() {
    if (this.selection.selected.length > 0) {
      this.selectedEmps = [];
      this.selection.selected.forEach((e: any) => {
        let setdata = {
          emp_id: e.empid,
          shiftid: e.shiftid,
          fromdate: e.attendancedate,
          todate: e.attendancedate,
          logintime: e.shift_start_time,
          logouttime: e.shift_end_time,
          worktype: e.worktypeid,
          halfday: e.halfday == null ? 0 : e.halfday,
          raisedby: e.empid,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Approved',
          locationid: e.locationid,
        }
        this.selectedEmps.push(setdata)
      });
  
      let data = {
        input_data: this.selectedEmps,
      }
      this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
        if (result.status && result.data[0].validity_status == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Attendance regularization saved successfully"
          });
          this.selection.clear();
          this.getEmployeesForRegularization();
        } else if(result.status && result.data[0].validity_status== 0){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist',
          });
          this.selection.clear();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to save attendance regularization"
          });
          this.selection.clear();
        }
  
      })
    } else {
      this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Select atleast one employee.',
      });
      return;
    }


  }

  setLeaveRegularization(data: any) {
    this.getLeavetypeIdForRegularization(data);
  }

  /**For getting employees list */
  getEmployeesForRegularization() {
    // this.spinner.show();
    this.isLoading = true;
    this.fullDayList = [];
    this.halfDayList = [];
    let data = {
      empid: this.userSession.id,
      roleid: 2,
      location_id: null
    };
    this.attendanceService.getEmployeesForRegularization(data).subscribe((result: any) => {
      if (result.status) {
        let empList = result.data;
        empList.forEach((e: any) => {
          if (e.halfday == null) {
            this.fullDayList.push(e);
          }
        })
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.fullDayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        // this.spinner.hide();
      } else{
        // this.spinner.hide();
        this.isLoading = false
      }
      
    });
   
  }

  /**get_leavetype_id_for_regularization */
  getLeavetypeIdForRegularization(event: any) {
    let data = {
      // employee_id:event.empid
      employee_id: event.empid,
    };
    this.attendanceService
      .getLeavetypeIdForRegularization(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.leavetypeid = result.data[0].leavetypeid;
          this.setValidateLeave(event);
        }
      });
  }

  setValidateLeave(data: any) {
    var errorMessage = [];
    let obj = {
      empid: data.empid,
      fromDate: data.attendancedate,
      toDate: data.attendancedate,
      fromDateHalf: 0,
      toDateHalf: 0,
      leaveTypeId: this.leavetypeid,
      document: false,
      leaveId: null,
    };
    this.originalleavecount = '';
    this.LM.setValidateLeave(obj).subscribe(async (result: any) => {
      if (result && result.status) {
        var validLeave = JSON.parse(result.data[0].count_json);
        this.leavecontvalue = JSON.parse(  result.data[0].count_json )[0].leavecount;
        this.isedited = false;
        this.leavedeductionpolicydata = {};
        this.leavedeductionpolicydata2 = {};

        /**
         * This condition is used for sandwich leave
         */

        let datas = {
          employee_id: data.empid,
          leavetype: this.leavetypeid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          fromhalfdayleave: 0,
          tohalfdayleave: 0,
          leavecount: this.leavecontvalue,
          leavestatus: 'Approved',
          contactnumber: '',
          regularization_date: data.attendancedate,
        };

        this.attendanceService.setLeaveRegularization(datas).subscribe((result: any) => {
          if (result.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(['/Attendance/managerAttendanceRegularization',])
            );
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Leave Regularization updated successfully',
            });
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to applied regularization leave',
            });
          }
        });
      }
    });
  }

  halfDayRegularization(data: any) {
    this.halfdayData = data;
    let givenData = {
      "halfDaytype": data.halfday,
      "attendancetype": true,
    }
    let dialogRef = this.dialog.open(HalfdayDialogboxComponent, {
      width: '500px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });
    dialogRef.afterClosed().subscribe(result => {

      if (result != undefined) {
        if (result == 'attendance') {
          this.halfDayAttendance();

        } else {
          this.halfDayLeave();

        }
      }
    });
  }

  halfDayAttendance() {
    this.setHalfdayAttendanceRegularization(this.halfdayData);
  }

  halfDayLeave() {
    this.setHalfdayLeaveRegularization(this.halfdayData);
  }

  /**For getting halfday employees list */
  getHalfdayEmployeesForRegularization() {
    // this.attendanceRequestForm.controls.search.setValue('');
    this.fullDayList = [];
    this.halfDayList = [];
    let empList = [];
    // this.spinner.show();
    this.isLoading = true;
    let data = {
      empid: this.userSession.id,
      roleid: 2,
      location_id: null
    };
    this.attendanceService.getHalfdayEmployeesForRegularization(data).subscribe((result: any) => {
      if (result.status) {
        empList = result.data;
        empList.forEach((e: any) => {
          if (e.halfday != null) {
            this.halfDayList.push(e);
          }
        })
        this.pageLoading = false;
        this.dataSource2 = new MatTableDataSource(this.halfDayList);
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
        this.isLoading = false;
     }
      // this.spinner.hide();
      this.isLoading = false;
    });
  }

  /*** halfday attendance save */
  setHalfdayAttendanceRegularization(data: any) {
    if(data.halfday =="A1" || data.halfday =="L1" ){
      this.isHalfday = 2;

    } else if(data.halfday =="A2" || data.halfday =="L2"){
      this.isHalfday = 1;
    }

    this.selectedEmps = [];
    let setdata = {
      emp_id: data.empid,
      shiftid: data.shiftid,
      fromdate: data.attendancedate,
      todate: data.attendancedate,
      logintime: data.halfday_starttime,
      logouttime: data.halfday_endtime,
      worktype: data.worktypeid,
      raisedby: this.userSession.id,
      approvercomments: '',
      actionby: this.userSession.id,
      status: 'Approved',
      halfday:this.isHalfday,
      locationid: data.locationid,
    };
    this.selectedEmps.push(setdata);

    let obj = {
      input_data: this.selectedEmps,
    }
    this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {
      if (result.status && result.data[0].validity_status == 1) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Attendance Regularization updated successfully',
        });
        this.getHalfdayEmployeesForRegularization();
      } else if(result.status && result.data[0].validity_status== 0){
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Record already exist',
        });
      }  else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Unable to save data',
        });
      }
    });
  }

  /*** halfday leave save */
  setHalfdayLeaveRegularization(data: any) {
    this.getHalfLeavetypeIdForRegularization(data);
  }

  /**get_leavetype_id_for_regularization */
  getHalfLeavetypeIdForRegularization(event: any) {
    let data = {
      employee_id: event.empid,
    };
    this.attendanceService.getLeavetypeIdForRegularization(data).subscribe((result: any) => {
      if (result.status) {
        this.leavetypeid = result.data[0].leavetypeid;
        this.setHalfValidateLeave(event);
      }
    });
  }
  /*** halfday leave validate */
  setHalfValidateLeave(data: any) {
    var errorMessage = [];
    if (data.halfday == "A1" || data.halfday == "L1") {
      this.L1 = 0;
      this.L2 = 1;
    } else if (data.halfday == "A2" || data.halfday == "L2") {
      this.L1 = 1;
      this.L2 = 0;
    }

    let obj = {
      empid: data.empid,
      fromDate: data.attendancedate,
      toDate: data.attendancedate,
      fromDateHalf: this.L2,
      toDateHalf: this.L1,
      leaveTypeId: this.leavetypeid,
      document: false,
      leaveId: null,
    };
    this.originalleavecount = '';
    this.LM.setValidateLeave(obj).subscribe(async (result: any) => {
      if (result && result.status) {
        var validLeave = JSON.parse(result.data[0].count_json);
        this.leavecontvalue = JSON.parse(result.data[0].count_json)[0].leavecount;
        this.isedited = false;
        let value = result.data[0];
        let fhl = value.fhday;
        let shl = value.thday;
        /**
         * This condition is used for sandwich leave
         */

        let datas = {
          employee_id: data.empid,
          leavetype: this.leavetypeid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          fromhalfdayleave: fhl,
          tohalfdayleave: shl,
          leavecount: this.leavecontvalue,
          leavestatus: 'Approved',
          contactnumber: '',
          regularization_date: data.attendancedate,
        };
        this.attendanceService.setLeaveRegularization(datas).subscribe((result: any) => {
          if (result.status) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Half day leave regularization updated successfully',
            });
            this.getHalfdayEmployeesForRegularization();
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to applied leave',
            });
          }
        });
      }
    });
  }

  clear(){

      this.getEmployeesForRegularization();
  }
  getPageSizes2(): number[] {
    let customPageSizeArray = [];
    if (this.dataSource2 && this.dataSource2.data) {
      if (this.dataSource2.data.length < 5) {
        customPageSizeArray.push(5);
      }
      if (this.dataSource2.data.length > 5) {
        customPageSizeArray.push(5);
      }
      if (this.dataSource2.data.length > 10) {
        customPageSizeArray.push(10);
      }
      if (this.dataSource2.data.length > 20) {
        customPageSizeArray.push(20);
      }
      customPageSizeArray.push(this.dataSource2.data.length);
    } else {
      customPageSizeArray = [5, 10, 20]; // default options if dataSource2 is not defined
    }
    return customPageSizeArray;
  }
  
}
