<div fxLayout="column" class="first-col">
  <mat-card class="mat-elevation-z0">
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Rotational Shift Configuration </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isAdd">
        <button mat-icon-button style="padding-right: 40px;" (click)="newAdd()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <div *ngIf="!isAdd" fxLayout="column" fxLayout.xs="column" class="dev-Pad">
      <form [formGroup]="shiftRotationForm" autocomplete="off">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Location Name</mat-label>
            <input type="text" formControlName="location" matInput readonly />
          </mat-form-field>


          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Rotation Name</mat-label>
            <input type="text" formControlName="rotationName" matInput placeholder="" maxlength="100" required />
            <mat-error class="reg-error" *ngIf="shiftRotationForm.controls.rotationName.errors?.required ||
                shiftRotationForm.controls.rotationName.errors?.['whitespace']">
              This field is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex class="mx-2 my-2">
            <mat-label>Please select shift</mat-label>
            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedShift($event)"
              [displayWith]="getOptionText">
              <mat-option *ngFor="let option of shiftDataList; let i = index" [value]="option">
                {{ option.shiftname }}
              </mat-option>
            </mat-autocomplete>
            <!-- <mat-error class="reg-error" *ngIf="!isAlert">
                    This field is required.
                  </mat-error> -->
          </mat-form-field>


          <!-- <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex class="mx-2 my-2">
                <mat-label>Select Shift</mat-label>
                <input matInput placeholder="" formControlName="shift" autocompleteOff
                    [matAutocomplete]="shiftName">
                <mat-autocomplete role="combobox" [displayWith]="ADSL"
                    #shiftName="matAutocomplete">
                    <ng-container *ngFor="let s of shiftDataList  ">
                            <mat-option  [value]="s" (onSelectionChange)="onChange(s)">{{s.shiftname }}
                        </mat-option>
                   </ng-container>
                </mat-autocomplete>
        </mat-form-field> -->
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
          <p><b>Shifts Order</b></p>
          <mat-list *ngIf="addChecklistData.length > 0">
            <mat-list-item class="list" *ngFor="let list of addChecklistData; let i = index">
              <mat-icon style="color:#28acaf;">label_important</mat-icon>
              <span style="font-size: initial;">{{list.shiftname}}</span>
              <a (click)="removeText(i)"><mat-icon style="color: rgb(247, 115, 115);
                        font-size: 23px;" title="Delete">delete_forever</mat-icon></a>
            </mat-list-item>
          </mat-list>
        </div>

        <div fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
            <span>Submit</span>
          </button>

          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </div>
    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="column" *ngIf="isAdd">
      <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
        <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
          <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
          </mat-form-field>
        </span>
      </div>

      <div fxLayout="column" class="table-pad">
        <div class="mat-elevation-z1  example-container">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>

            <ng-container matColumnDef="sequence">
              <th mat-header-cell *matHeaderCellDef> Sequence Name </th>
              <td mat-cell *matCellDef="let row">{{row.shift_sequence_name}} </td>
            </ng-container>

            <ng-container matColumnDef="sequenceList">
              <th mat-header-cell *matHeaderCellDef>Rotation Order</th>
              <td mat-cell *matCellDef="let row">
                <span>{{row.seq_names}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row;let i = index;" style="white-space: nowrap;">
                <button mat-icon-button matTooltip="Edit" [tabindex]="[i+3]" (keyup.enter)="editEmployee($event,row)"
                  *ngIf="row.status == 'Hired' ">
                  <mat-icon (click)="editEmployee($event,row)">edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
            </tr>
          </table>

          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
          <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div>

    </div>
  </mat-card>
</div>