import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { environment } from 'src/environments/environment';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { ReportsService } from 'src/app/modules/reports/reports.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require('html-to-pdfmake');

@Component({
  selector: 'app-leave-report',
  templateUrl: './leave-report.component.html',
  styleUrls: ['./leave-report.component.scss'],
})
export class LeaveReportComponent implements OnInit {
  dataSource: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table') table!: ElementRef;
  detailedLeaveReports: any = UntypedFormGroup;
  employeeDetails: any = [];
  ishide: boolean = true;
  isCustom: boolean = false;
  minFromDate: any;
  leaveTypes: any = [];
  designations: any = [];
  arrayList: any = [];
  displayedColumns: string[] = [
    'sno',
    'employeeName',
    'employeeId',
    'leaveType',
    'designation',
    'appliedDate',
    'startDate',
    'toDate',
    'noOfDays',
    'status',
    'approvedBy',
  ];

  pageLoading = true;
  companyDBName: any = environment.dbName;
  userSession: any;
  pipe = new DatePipe('en-US');
  searchControlforDesignation = new FormControl('');
  searchControlforLeaveType = new FormControl('');
  searchControlforEmpname = new FormControl('');
  filteredLeaveTypes: any = [];
  filteredDesignations: any = [];
  filteredEmpname: any = [];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    public router: Router,
    private payrollService: PayrollService,
    private LM: LeavesService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public reportsService: ReportsService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.searchControlforDesignation.valueChanges.subscribe(searchText => {
      this.filterDesignations(searchText)
    });
    this.searchControlforLeaveType.valueChanges.subscribe(searchText => {
      this.filterLeaveTypes(searchText)
    });
    this.searchControlforEmpname.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText)
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');

    this.detailedLeaveReports = this.formBuilder.group({
      leaveType: ['All'],
      leaveStatus: ['All'],
      designation: ['All'],
      fromDate: [new Date()],
      toDate: [new Date()],
      DateFormate: ['currentWeek'],
      employeeId: ['All'],
    });

    this.isCustom = false;

    const today = new Date();
    let fromdate = new Date(today.setDate(today.getDate() - today.getDay()));
    let toDate = new Date(today.setDate(today.getDate() - today.getDay()));
    this.detailedLeaveReports.controls.fromDate.setValue(fromdate);
    this.detailedLeaveReports.controls.toDate.setValue(
      new Date(toDate.setDate(toDate.getDate() + 6))
    );
    this.detailedLeaveReports.get('DateFormate')?.valueChanges.subscribe((selectedValue: any) => {
      switch (selectedValue) {
        case 'currentWeek':
          var fromdate = new Date(today.setDate(today.getDate() - today.getDay()));
          this.detailedLeaveReports.controls.fromDate.setValue(new Date(today.setDate(today.getDate() - today.getDay())));
          this.detailedLeaveReports.controls.toDate.setValue(new Date(fromdate.setDate(fromdate.getDate() + 6)));
          this.isCustom = false;
          break;
        case 'lastWeek':
          var fromdate = new Date(
            today.setDate(today.getDate() - today.getDay())
          );
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date(fromdate.setDate(fromdate.getDate() - 7))
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date(fromdate.setDate(fromdate.getDate() + 6))
          );
          this.isCustom = false;
          break;
        case 'currentMonth':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date(today.getFullYear(), today.getMonth(), 1)
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date(today.getFullYear(), today.getMonth() + 1, 0)
          );
          this.isCustom = false;
          break;
        case 'lastMonth':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          this.isCustom = false;
          break;
        case 'quaterOne':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date('01-01-' + today.getFullYear().toString())
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date('03-31-' + today.getFullYear().toString())
          );
          this.isCustom = false;
          break;
        case 'quaterTwo':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date('04-01-' + today.getFullYear().toString())
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date('06-30-' + today.getFullYear().toString())
          );
          this.isCustom = false;
          break;
        case 'quaterThree':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date('07-01-' + today.getFullYear().toString())
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date('09-30-' + today.getFullYear().toString())
          );
          this.isCustom = false;
          break;
        case 'quaterFour':
          this.detailedLeaveReports.controls.fromDate.setValue(
            new Date('10-01-' + today.getFullYear().toString())
          );
          this.detailedLeaveReports.controls.toDate.setValue(
            new Date('12-31-' + today.getFullYear().toString())
          );
          this.isCustom = false;
          break;
        case 'custom':
          this.detailedLeaveReports.controls.fromDate.setValue('');
          this.detailedLeaveReports.controls.toDate.setValue('');
          this.isCustom = true;
          break;
      }

      // if(selectedValue == 'currentWeek'){
      //   var fromdate =new Date(today.setDate(today.getDate() - today.getDay()))
      //   this.detailedLeaveReports.controls.fromDate.setValue(fromdate)
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date(fromdate.setDate(fromdate.getDate()+6)))
      // }else if (selectedValue == 'lastWeek'){
      //   var fromdate =new Date(today.setDate(today.getDate() - today.getDay()))
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date(fromdate.setDate(fromdate.getDate()-7)));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date(fromdate.setDate(fromdate.getDate()+6)));
      //
      // } else if (selectedValue == 'currentMonth'){
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date(today.getFullYear(), today.getMonth(), 1));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date(today.getFullYear(), today.getMonth() + 1, 0));
      // }else if (selectedValue == 'lastMonth'){
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date(today.getFullYear(), today.getMonth() - 1, 1));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date(today.getFullYear(), today.getMonth(), 0));
      //
      //
      // }else if (selectedValue == 'quaterOne'){
      //
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date('01-01-'+today.getFullYear().toString()));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date('03-31-'+today.getFullYear().toString()));
      //
      // }else if (selectedValue == 'quaterTwo'){
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date('04-01-'+today.getFullYear().toString()));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date('06-30-'+today.getFullYear().toString()));
      //
      //
      // }else if (selectedValue == 'quaterThree'){
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date('07-01-'+today.getFullYear().toString()));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date('09-30-'+today.getFullYear().toString()));
      //
      //
      // }else if (selectedValue == 'quaterFour'){
      //   this.detailedLeaveReports.controls.fromDate.setValue(new Date('10-01-'+today.getFullYear().toString()));
      //   this.detailedLeaveReports.controls.toDate.setValue(new Date('12-31-'+today.getFullYear().toString()));
      //
      //
      // }
    });
    this.detailedLeaveReports.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.minFromDate = selectedValue._d;
      this.detailedLeaveReports.controls.toDate.setValue(
        new Date(this.minFromDate)
      );
    });
    this.detailedLeaveReports.get('designation')?.valueChanges.subscribe((selectedValue: any) => {
      this.detailedLeaveReports.controls.employeeId.setValue('All');
      this.getEmployeesForReportingManager();
    });

    this.getLeaveTypes(); /**for Detailed leave report */
    this.getEmployeesForReportingManager(); /**for leave detailed and summary report */
    this.getDesignation();
    this.SearchLeaveDetailsForm();
  }

  getLeaveTypes() {
    let obj = {
      tableName: 'lm_leavesmaster',
      status: 'Active',
      pageNumber: 1,
      pageSize: 1000,
      databaseName: this.companyDBName,
    };
    this.LM.getMastertable(obj).subscribe((result) => {
      if (result && result.status) {
        this.leaveTypes = result.data;
        this.filteredLeaveTypes = this.leaveTypes;
      }
    });
  }
  getEmployeesForReportingManager() {
    // let input = {
    //   managerId: this.userSession.id,
    //   departmentId: this.detailedLeaveReports.controls.designation.value,
    // };
    // this.LM.getEmployeesForReportingManager(input).subscribe((result) => {
    //   if (result && result.status) {
    //     this.employeeDetails = result.data;
    //     if (this.employeeDetails.length > 0) {
    //       this.ishide = true;
    //     } else {
    //       this.ishide = false;
    //     }
    //   }
    // });
    let obj = {
      remployee_id: this.userSession.id,
    };
    this.reportsService
      .getReportsuserEmployeesList(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeeDetails = [];
          this.employeeDetails = res.data;
          this.filteredEmpname = this.employeeDetails;
          // this.detailedLeaveReports.controls.employeeId.setValue('All');
        }
      });
  }

  getDesignation() {
    let obj = {
      tableName: 'designationsmaster',
      status: 1,
      pageNumber: 1,
      pageSize: 1000,
      databaseName: this.companyDBName,
    };
    this.LM.getMastertable(obj).subscribe((result) => {
      if (result && result.status) {
        this.designations = result.data;
        this.filteredDesignations = this.designations;
      }
    });
  }
  SearchLeaveDetailsForm() {
    this.arrayList = [];
    this.dataSource = new MatTableDataSource(this.arrayList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pageLoading = true;

    this.getPageSizes();
    this.spinner.show();
    let obj = {
      employee_id: this.detailedLeaveReports.controls.employeeId.value,
      reportsuser_id: this.userSession.id,
      leavetype_id: this.detailedLeaveReports.controls.leaveType.value,
      leave_status: this.detailedLeaveReports.controls.leaveStatus.value,
      designation_id: this.detailedLeaveReports.controls.designation.value,
      from_date: this.pipe.transform(this.detailedLeaveReports.controls.fromDate.value, 'yyyy-MM-dd'),
      to_date: this.pipe.transform(this.detailedLeaveReports.controls.toDate.value, 'yyyy-MM-dd'),
      pagenumber: 1,
      pagesize: 1000,
    };

    this.LM.getEmployeeLeaveDetailedReportForReportsuser(obj).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.arrayList = result.data;
          // this.count = this.arrayList[0].total;
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSize = 5;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        } else {
          this.arrayList = [];
          this.dataSource = new MatTableDataSource(this.arrayList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSize = 5;
          this.dataSource.sort = this.sort;
        }
      }
    );
  }
  clearLeaveDetailsForm() {
    this.ngOnInit();
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  public exportPDFleaveDetailedReport(): void {
    let fromDate = this.pipe.transform(
      this.detailedLeaveReports.controls.fromDate.value,
      'dd-MM-yyyy'
    );
    let toDate = this.pipe.transform(
      this.detailedLeaveReports.controls.toDate.value,
      'dd-MM-yyyy'
    );
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: fromDate + '-' + toDate + '-' + 'Detailed Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage: any, pageCount: any) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: fromDate + '-' + toDate + '-' + 'Detailed Report\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },

          html,
        ],
        pageOrientation: 'landscape', //'portrait'
        pageSize: 'A4',
      })
      .download(fromDate + '-' + toDate + '-' + 'Detailed Report.pdf');
  }

  exportAsXLSXleaveDetailedReport() {
    let fromDate = this.pipe.transform(
      this.detailedLeaveReports.controls.fromDate.value,
      'dd-MM-yyyy'
    );
    let toDate = this.pipe.transform(
      this.detailedLeaveReports.controls.toDate.value,
      'dd-MM-yyyy'
    );
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Detailed_Report');

    /* save to file */
    XLSX.writeFile(wb, fromDate + '-' + toDate + '-' + 'Detailed_Report.xlsx');
  }
  filterDesignations(searchText: any) {
    this.filteredDesignations = this.designations.filter((val: any) =>
      val.designation.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredDesignations.length <= 0) {
      this.searchControlforDesignation.setValue('');
    }
  }
  filterLeaveTypes(searchText: any) {
    this.filteredLeaveTypes = this.leaveTypes.filter((val: any) =>
      val.leavename.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredLeaveTypes.length <= 0) {
      this.searchControlforLeaveType.setValue('');
    }
  }
  filterEmployees(searchText: any) {
    this.filteredEmpname = this.employeeDetails.filter((val: any) =>

      val.empname.toLowerCase().includes(searchText.toLowerCase()) || val.empcode.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredEmpname.length <= 0) {
      this.searchControlforEmpname.setValue('');
    }
  }
  downloadPDF() {

    let fromdate = (this.datePipe.transform(this.detailedLeaveReports.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.detailedLeaveReports.controls.toDate.value, "dd-MM-YYYY"))
    let data = {
      'list': this.arrayList,
      'report_date': fromdate + ' ' + '_' + ' ' + todate
    }
    this.reportsService.getAttendanceDetailedLeaveReportPdf(data).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'detailed_leave_report.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
