<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="cabinForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>New Cabin</mat-card-title>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
       
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
               
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Cabin ID/Name</mat-label>
                    <input type="text" formControlName="cabinId"  matInput placeholder="Cabin ID/Name" required>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.cabinId.hasError('required')">
                        Please enter cabin name
                     </mat-error> 
                </mat-form-field>

                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Location</mat-label>
                    <mat-select formControlName="location" required>
                        <ng-container *ngFor="let value of locationsList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.location.hasError('required')">
                        Please select location
                     </mat-error> 
                 </mat-form-field>
                
                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Sub-Location</mat-label>
                    <mat-select formControlName="subLocation" required>
                        <ng-container *ngFor="let value of locationsList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.subLocation.hasError('required')">
                        Please select sub location
                     </mat-error> 
                 </mat-form-field>
             </div>

             <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>IP Address</mat-label>
                    <input type="text" formControlName="ipAddress"  matInput placeholder="IP Address" required>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.ipAddress.hasError('required')">
                        Please enter IP address
                     </mat-error> 
                </mat-form-field>
                
                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Data Port</mat-label>
                    <input type="text" formControlName="dataPort"  matInput placeholder="Data Port" required>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.dataPort.hasError('required')">
                        Please enter data port
                     </mat-error> 
                </mat-form-field>

                  <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Voice Port</mat-label>
                    <input type="text" formControlName="voicePort"  matInput placeholder="Voice Port" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="cabinForm.controls.voicePort.hasError('required')">
                        Please enter voice port
                     </mat-error> 
                </mat-form-field>
             </div>
          <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </div>
        </mat-card>
       </form>
      <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%"  fxLayoutAlign="space-between center" *ngIf="isdata">
        <span style="padding-top: 5px; padding-left: 10px;">
         <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
          </span>
          <span style="padding-right: 7px;">
            <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="submit">
                <span>Add</span>
            </button>
          </span>
        
     </div>
     <div fxLayout="column" class="tableP" *ngIf="isdata">
       <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="cid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cabin ID</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="subLocation">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Location</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>