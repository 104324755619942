<div fxLayout="column" fxFill>
  <mat-card fxFill>
    <mat-card-header class="">
        <mat-card-title class="title custom-payRoll-header"> IT Declaration </mat-card-title>
    </mat-card-header>
    
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card"> 
      <div fxLayout="column" fxFlex="5">
       </div>   
    <div fxLayout="column" fxFlex="90" class="custom-payroll-div">
                    <table class="table table-bordered tab-lap-desk-show ">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Particulars</th>
                          <th scope="col">Maximum Limit</th>
                          <th scope="col">Declared Amount</th>
                          <th scope="col">Submitted Amount</th>
                          <th scope="col">Verified Amount</th>
                          <th scope="col">Attachments</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Sec 80D - Medical Insurance Premium (If the policy covers a senior citizen then exemption si 50,000/-)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Sec 80DD - Handicapped Dependent</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                       
                      </tbody> 
                    </table>
                    <div class="mob-port-show" style="margin-left:9px;margin-right:9px;">
                     
                      <div class="card card-stats">
                        <div class="card-body ">
                          <div class="row">
                            <mat-accordion style="width:100%;">                              
                              <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title style="display: block !important;">
                                    <div>S.No</div>
                                    <div style="color: rgba(0,0,0,.54) !important;">01</div>
                                  </mat-panel-title>
                                  <mat-panel-description style="display: block !important;">
                                    <div style="color: rgba(0,0,0,.87) !important;">Particulars</div>
                                    <div>Sec 80D - Medical Insurance Premium (If the policy covers a senior citizen then exemption si 50,000/-)</div>
                                  </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div style="margin-top: 40px;">
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Maximum Limit
                                    </div>
                                    <div>
                                      1,000
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Declared Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Submitted Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Verified Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Attachments
                                    </div>
                                    <div>
                                      attachment.png
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Status
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Action
                                    </div>
                                    <div>
                                      <mat-icon>file_upload</mat-icon> 
                                    </div>
                                  </div>

                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>    
                        </div>
                      </div>
                      <div class="card card-stats">
                        <div class="card-body ">
                          <div class="row">
                            <mat-accordion style="width:100%;">  
                              <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title style="display: block !important;">
                                    <div>S.No</div>
                                    <div style="color: rgba(0,0,0,.54) !important;">02</div>
                                  </mat-panel-title>
                                  <mat-panel-description style="display: block !important;">
                                    <div style="color: rgba(0,0,0,.87) !important;">Particulars</div>
                                    <div>Sec 80DD - Handicapped Dependent</div>
                                  </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div style="margin-top: 40px;">
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Maximum Limit
                                    </div>
                                    <div>
                                      1,000
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Declared Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Submitted Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;">
                                      Verified Amount
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Attachments
                                    </div>
                                    <div>
                                      attachment.png
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Status
                                    </div>
                                    <div>
                                      
                                    </div>
                                  </div>
                                  <div style="display:flex;margin-bottom: 1rem;">
                                    <div style="width:50%;margin-left: 0;">
                                      Action
                                    </div>
                                    <div>
                                      <mat-icon>file_upload</mat-icon> 
                                    </div>
                                  </div>

                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                        </div>
                      </div>         
                    </div>
    </div>
    <div fxLayout="column" fxFlex="5">
    </div>            
        </div>
    </mat-card>
</div>                    
