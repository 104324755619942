import { Component,OnInit, Inject, ViewChild, ElementRef,HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup,UntypedFormBuilder,Validators} from '@angular/forms';
import { CrmService } from 'src/app/modules/crm/crm.service';  
import CryptoJS from 'crypto-js';
interface DialogData {
  notesRequired: boolean;  // ... other properties
}
@Component({
  selector: 'app-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss']
})
export class WebcamModalComponent implements OnInit {
  @ViewChild('videoElement')
  videoElement!: ElementRef;
  noteForm!: UntypedFormGroup;
  imageParams:any=[];
  activities:any = [];
  imageData:any;
  requiredField: any = 'Please enter Notes.';
  notesRequired:boolean = false;
  isCameraAvailable:any = true;
  isVedioOpen:any = false;
  location:any = '';
  constructor( public dialogRef: MatDialogRef<WebcamModalComponent>,
    private crm:CrmService,private formBuilder:UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
     }
     @HostListener('document:keydown.escape', ['$event'])
     handleEscape(event: KeyboardEvent): void {
       this.dialogRef.close();
     }
  ngOnInit(): void {
   this.assignvalues();
    this.noteForm=this.formBuilder.group({
        notes:["",Validators.required]
     });
    this.initCamera();
  }
  assignvalues()  {
    this.notesRequired = this.data?(this.data.notesRequired??false):false;
    this.location = this.data && this.data.location?this.data.location:'';
  }
  initCamera() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.isVedioOpen = true;
        this.videoElement.nativeElement.srcObject = stream;
      })
      .catch((error) => {
        this.isCameraAvailable = false;
        console.error('Error accessing webcam:', error);
      });
  }
  
  close(){
    this.closeCamera();
    this.dialogRef.close({        
      imageData:null,
      notes: this.notesRequired? this.noteForm.controls.notes.value:null
    });
  }

  capturePhoto() {
    const video = this.videoElement.nativeElement;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    canvas.width = video.videoWidth; // Set the canvas size to match the video feed
    canvas.height = video.videoHeight;
   
    context?context.drawImage(video, 0, 0, canvas.width, canvas.height):null;  // Draw the current frame from the video onto the canvas
    this.imageData = canvas.toDataURL('image/png');   // Get the image data from the canvas as a data URL
    // Pass the captured photo data to the parent component
   
    if(this.imageData){
      if(this.notesRequired?this.noteForm.controls.notes.value.toString().length>=10:true){
        this.dialogRef.close({        
          imageData:this.imageData,
          notes: this.notesRequired? this.noteForm.controls.notes.value:''
        });

        const stream = video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());  // Stop the webcam stream
        video.srcObject = null;
      }
    }
  }

  closeCamera(){
    if(this.isCameraAvailable && this.videoElement && this.videoElement.nativeElement){
      const stream = this.videoElement.nativeElement.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());  // Stop the webcam stream
      this.videoElement.nativeElement.srcObject = null;
    }
  }

  private dataURLtoBlob(dataURL: string): Blob {
    const arr = dataURL.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (mimeMatch) {
      const mimeType = mimeMatch[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mimeType });
    }
    return new Blob();
  }

  private calculateMD5(dataURL: string): string {
    const base64 = dataURL.split(',')[1]; // Extract base64 part
    const wordArray = CryptoJS.enc.Base64.parse(base64);
    const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
    return md5;
  }

  backArrow(){
    this.closeCamera();
    this.imageData = null;
    this.dialogRef.close();
  }


}
