<div fxLayout="column" >

    <mat-card-header class="heading">
      <mat-card-title class="headingtext">Reimbursement Type Wise Report</mat-card-title>
    </mat-card-header>
    
    <div fxLayout fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign.xs="center center" [formGroup]="searchForm">
      <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%">
        <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
          <mat-label>Select Year</mat-label>
          <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
            formControlName="fromDate" (click)="fromDate.open()">
          <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Reimbursement Type</mat-label>
            <mat-select required formControlName="reimbursementType">
                <ng-container>
                    <div *ngFor="let r of reimbursementTypeList">
                        <mat-option [value]="r.id" >{{r.name}}</mat-option>
                    </div>
                </ng-container>
            </mat-select>
            </mat-form-field>

      </div>
      <div fxLayout="row" fxFlex.xs="100%" class="showLine">
        <span>
          <button class="mx-2 my-2 button-save" (click)="Searchform()" mat-stroked-button>
            Search
          </button>
        </span>
        <span class="line" style="color:#1898D5;font-size:16px"></span>
        <button class="mx-2 my-2 mat-flat-button  button-clear" (click)="resetform()">
          <span>Clear</span>
        </button>
      </div>
    </div>
    <div fxLayout="row xs-column" fxLayoutAlign="end  center" style="margin-right: 3%;">
      <div>
        <span class="exlprint"> | </span>
        <a title="Download"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
        </a>
      </div>
    </div>


<div  class="tableP">
    <div class="mat-elevation-z1">
      <table id="table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef>S.No</th>
          <td mat-cell *matCellDef="let row; let i = index;">{{i+1}}</td>

        </ng-container>
        <ng-container matColumnDef="adate">
          <th mat-header-cell *matHeaderCellDef>Approve Date</th>
          <td mat-cell *matCellDef="let row;">{{row.empname}}</td>

        </ng-container>
        <ng-container matColumnDef="empname">
          <th mat-header-cell *matHeaderCellDef>Employee Name</th>
          <td mat-cell *matCellDef="let row"> {{row.attendancedate}} </td>
        </ng-container>
        <ng-container matColumnDef="reqAmount">
          <th mat-header-cell *matHeaderCellDef>Request Amount</th>
          <td mat-cell *matCellDef="let row"> {{row.firstlogintime | date:'dd-MM-yyyy'}} {{row.firstlogintime | date:'hh:mm:ss'}} </td>

        </ng-container>
        <ng-container matColumnDef="aproveon">
          <th mat-header-cell *matHeaderCellDef>Approve On</th>
          <td mat-cell *matCellDef="let row"> {{row.lastlogouttime | date:'hh:mm:ss' }} </td>

        </ng-container>
        <ng-container matColumnDef="aprAmount">
          <th mat-header-cell *matHeaderCellDef>Approve Amount</th>
          <td mat-cell *matCellDef="let row"> {{row.totalhours}} </td>

        </ng-container>
        <ng-container matColumnDef="aprBy">
          <th mat-header-cell *matHeaderCellDef>Approve By</th>
          <td mat-cell *matCellDef="let row">
          <ng-container *ngFor="let number of row.breaks">
            {{number}}<br>

          </ng-container>
        </td>
        </ng-container>
        <ng-container matColumnDef="paidOn">
          <th mat-header-cell *matHeaderCellDef>Paid On</th>
          <td mat-cell *matCellDef="let row"> {{row.breaktime}} </td>

        </ng-container>

        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
        </tr>

      </table>
      <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
    </div>
  </div>
</div>
