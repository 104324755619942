import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import htmlToPdfmake from 'html-to-pdfmake';
import moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import * as XLSX from 'xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-professional-tax-report',
  templateUrl: './professional-tax-report.component.html',
  styleUrls: ['./professional-tax-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ProfessionalTaxReportComponent implements OnInit {
  ProfessionalTaxForm: any = UntypedFormGroup;
  date = new UntypedFormControl(moment());
  max = new Date();
  minDate: any = new Date('2022-01-01');
  statehide: boolean = false;
  stateslist: any = [{ state_id: null, state: 'ALL' }];
  arrayValue: any = [
    { Value: 0, name: 'State-wise Report', checked: true },
    { Value: 1, name: 'Detailed Report', checked: false },
  ];
  displayedColumns: string[] = ['sno', 'state', 'payrange', 'tax', 'employees'];
  displayedColumns2: string[] = [
    'Employee_Id',
    'Employee_Name',
    'Gross_Salary',
    'Professionam_Tax',
  ];

  dataSource: MatTableDataSource<any> = <any>[];
  dataSource2: MatTableDataSource<any> = <any>[];

  pageLoading = true;
  pageLoading2 = true;
  arrayList: any = [];
  pipe = new DatePipe('en-US');
  userSession: any;
  employeeDetails: any;
  year: any;
  reporthide: boolean = false;
  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  monthdata: any;
  statewiseList: any = [];
  detailedList: any = [];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table')
  table!: ElementRef;
  detailreporthide: boolean = false;

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.getStatesForProfessionalTax();
  }

  constructor(
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    private PR: PayrollService,
    public spinner: NgxSpinnerService,
    private RS: ReportsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.ProfessionalTaxForm = this.formBuilder.group({
      fromDate: [new Date()],
      changePaygroup: [0],
      state: [''],
    });

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');

    this.ProfessionalTaxForm.get('state')?.valueChanges.subscribe(
      (selectedValue: any) => {
        if (selectedValue == 'All') {
          this.ProfessionalTaxForm.controls.changePaygroup.setValue(1);
        }
      }
    );
  }
  exportAsXLSX() {
    this.year = this.date.value._d.getFullYear();
    for (let i = 0; i < this.months.length; i++) {
      if (this.date.value._d.getMonth() == this.months[i].id) {
        this.monthdata = this.months[i].month;
        break;
      }
    }
    // var ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet('Payroll_report_for_financeteam_');
    var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('table')
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      this.reporthide ? 'Pttax_report_for_financeteam_' : 'Pttax_detail_report_'
    );

    /* save to file */
    // XLSX.writeFile('Payroll_report_for_financeteam_'+this.monthdata,'Payroll_report_for_financeteam_'+this.monthdata+'_'+this.year+'.xlsx')
    XLSX.writeFile(
      wb,
      this.reporthide
        ? 'Pttax_report_for_financeteam_' +
            this.monthdata +
            '_' +
            this.year +
            '.xlsx'
        : 'Pttax_detail_report_' + this.monthdata + '_' + this.year + '.xlsx'
    );
  }

  Searchform() {
    if (
      this.ProfessionalTaxForm.controls.state.value == '' &&
      this.ProfessionalTaxForm.controls.state.value != null
    ) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'State is not available',
      });
      return;
    } else {
      let data = {
        date: this.pipe.transform(this.date.value._d, 'yyyy-MM-dd'),
      };
      if (this.ProfessionalTaxForm.controls.changePaygroup.value == 0) {
        this.detailreporthide = false;
        this.reporthide = true;
        this.getProfessionalTaxValuesForChallanForReportsuser();
      } else {
        this.detailreporthide = true;
        this.reporthide = false;
        this.getProfessionalTaxDetailReportForReportsuser();
      }
    }
  }
  resetform() {
    this.detailreporthide = false;
    this.reporthide = false;
    this.statehide = false;
    this.ProfessionalTaxForm.controls.fromDate.setValue(new Date());
    this.ProfessionalTaxForm.controls.changePaygroup.setValue(0);
    this.ProfessionalTaxForm.controls.state.setValue('');
    this.ngOnInit();
 }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getPageSizes2(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource2.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource2.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource2.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource2.data.length);
    return customPageSizeArray;
  }
  public exportPDF(): void {
    this.year = this.date.value._d.getFullYear();
    for (let i = 0; i < this.months.length; i++) {
      if (this.date.value._d.getMonth() == this.months[i].id) {
        this.monthdata = this.months[i].month;
        break;
      }
    }
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: this.reporthide
            ? 'Professional Tax Report'
            : 'Professional Tax Detail Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: this.reporthide
              ? 'Professional Tax Report for ' +
                this.monthdata +
                ',' +
                this.year +
                ' \n\n'
              : 'Professional Tax Detail Report for ' +
                this.monthdata +
                ',' +
                this.year +
                '\n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          // {
          //   text:
          //     "Designation :  " + this.designationForPdf +"\n" +
          //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
          //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
          //   fontSize: 10,
          //   margin: [0, 0, 0, 20],
          //   alignment: 'left'
          // },
          html,
        ],
        pageOrientation: 'landscape', //'portrait'
      })
      .download(
        this.reporthide
          ? 'Pfofessional tax challan Report for ' +
              this.monthdata +
              ',' +
              this.year +
              '.pdf'
          : 'Professional tax detail Report for ' +
              this.monthdata +
              ',' +
              this.year +
              '.pdf'
      );
  }
  getProfessionalTaxValuesForChallanForReportsuser() {
    let data = {
      year_value: this.date.value._d.getFullYear(),
      month_value: this.date.value._d.getMonth() + 1,
      state_id_value:
        this.ProfessionalTaxForm.controls.state.value == 'All'
          ? null
          : Number(this.ProfessionalTaxForm.controls.state.value),
      rempid: this.userSession.id,
    };
    this.dataSource = new MatTableDataSource();
    this.PR.getProfessionalTaxValuesForChallanForReportsuser(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.reporthide = true;
          this.detailedList = result.data;
          this.detailedList[0].Gross_Salary == null || undefined
            ? (this.dataSource = new MatTableDataSource(result.data))
            : (this.dataSource = new MatTableDataSource());
          // this.dataSource = new MatTableDataSource(result.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
        }
      }
    );
  }
  getProfessionalTaxDetailReportForReportsuser() {
    let data = {
      year_value: this.date.value._d.getFullYear(),
      month_value: this.date.value._d.getMonth() + 1,
      state_id_value:
        this.ProfessionalTaxForm.controls.state.value == 'All'
          ? null
          : Number(this.ProfessionalTaxForm.controls.state.value),
      rempid: this.userSession.id,
    };
    this.dataSource2 = new MatTableDataSource();
    this.PR.getProfessionalTaxDetailReportForReportsuser(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.detailreporthide = true;
          this.reporthide = false;
          this.statewiseList = result.data;
          this.statewiseList[0].Gross_Salary != null
            ? (this.dataSource2 = this.statewiseList)
            : (this.dataSource2 = new MatTableDataSource());
          this.dataSource2.paginator = this.paginator;
          this.dataSource2.sort = this.sort;
          this.pageLoading2 = false;
        }
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getStatesForProfessionalTax() {
    let data = {
      year: this.date.value._d.getFullYear(),
      month: this.date.value._d.getMonth() + 1,
    };
    this.PR.getStatesForProfessionalTax(data).subscribe((result: any) => {
      if (result.status) {
        this.stateslist = result.data;
        if (result.data[0].status == 0) {
          this.statehide = true;
        } else {
          this.statehide = false;
        }
      }
    });
  }
}
