import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AttendanceService } from '../../attendance.service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-rotational-shift-configuration',
  templateUrl: './rotational-shift-configuration.component.html',
  styleUrls: ['./rotational-shift-configuration.component.scss']
})
export class RotationalShiftConfigurationComponent implements OnInit {
  userSession: any;
  constructor(private formBuilder: UntypedFormBuilder, private attendanceService: AttendanceService,
    private router: Router, private dialog: MatDialog) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
   }

  shiftRotationForm: any = UntypedFormGroup;
  shiftDataList: any = [];
  shiftSequenceList: any = [];
  addChecklistData: any = [];
  myControl: FormControl = new FormControl();
  locationId:any;
  selectedShiftList: any = [];
  isNewhire: boolean = false;
  isAdd: boolean = true;
  dataSource : MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  displayedColumns: string[] = ['sno','sequence','sequenceList','action'];
  isAlert = false;
  ngOnInit(): void {
    this.shiftRotationForm = this.formBuilder.group({
      rotationName: ["",Validators.required],
      location: [""],
      shift: [""],
    });
    this.getActiveShiftIds();
    this.getManagerLocation();
   
  }

  submit(){
    if(this.shiftRotationForm.valid){
     if (this.addChecklistData.length > 0) {
      for (let i = 0; i < this.addChecklistData.length; i++){
         this.selectedShiftList.push(this.addChecklistData[i].shiftid);
      }
      let data = {
        id_value: null,
        location_id_value: this.locationId,
        sequence_name_value:this.shiftRotationForm.controls.rotationName.value,
        shift_sequence_value: this.selectedShiftList,
        created_by_value: this.userSession.id,
      }
      this.attendanceService.setRotationShiftSequence(data).subscribe((res: any) => {
        if (res.status && res.data.statuscode == 0 ) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Attendance/ShiftRotationMaster"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Shift Rotation added successfully"
          });
        } else if (res.status && res.data.statuscode == 1){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Shift Rotation already exist"
          });
        }
         else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to add data"
          });
        }
      })

    }else{
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Please select atleast one shift"
      });
    }
  }
  }
  cancel(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate(["/Attendance/ShiftRotationMaster"]));
  }
  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
      }
    });
  }
  getManagerLocation() {
    let data ={
      manager_id_value:this.userSession.id,
     }
    this.attendanceService.getManagerWorkLocation(data).subscribe((res) => {
      if (res.status) {
      let data = res.data
        this.shiftRotationForm.controls.location.setValue(data.manager_location);
        this.locationId = data.locationid;
        this.getShiftSequenceList();
      }
    });
    
  }
  getShiftSequenceList() {
    let data = {
      manager_id_value: this.userSession.id,
      location_id_value: this.locationId,
    }
    this.attendanceService.getRotationShiftSequence(data).subscribe((res) => {
      if (res.status) {
        this.shiftSequenceList = res.data;
        this.dataSource = new MatTableDataSource(this.shiftSequenceList);
        this.pageLoading = false;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
         }
    });
  }

  onChange(id:any){
    this. addChecklistData.push(id);
    this.shiftRotationForm.controls.shift.setValue('')
  }
  removeText(index:number) {
    this.addChecklistData.splice(index, 1);
    this. addChecklistData.length == 0 ? this.isAlert=false:this.isAlert=true;
  }

  selectedShift(event:any) {
    this. addChecklistData.push(event.option.value);
    this.myControl.setValue('');
    this. addChecklistData.length > 0 ? this.isAlert=true:this.isAlert=false;
  }

  getOptionText(option:any) {
    return option.shiftname;
  }
  newAdd(){
    this.isAdd=false;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
      if (this.dataSource.data.length > 5) {
        customPageSizeArray.push(5);
      }
      if (this.dataSource.data.length > 10) {
        customPageSizeArray.push(10);
      }
      if (this.dataSource.data.length > 20) {
        customPageSizeArray.push(20);
      }
      customPageSizeArray.push(this.dataSource.data.length);
      return customPageSizeArray;
 }
 editEmployee(id: any, data: any) {}
}
