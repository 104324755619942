<div fxLayout="column" class="first-col custom-pgr-height-auto">
  <mat-card>
    <mat-card-header class="headerbox">
      <div fxLayout="row" fxFlex="50">
          <mat-card-title class="title"> Dashboard</mat-card-title>
      </div>
      </mat-card-header>
<div fxLayout="column">
  <form [formGroup]="financeDashboardForm" autocomplete="off">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" >

  <div class="mcard outline">
    <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
      <p class="card-category" style="color:#28acaf;font-size: 1.1rem;">Month</p>
      <mat-form-field appearance="outline"   style="width: 90%;">
      <mat-label>Month</mat-label>
      <mat-select formControlName="month" style="width: 85%;">
        <ng-container>
          <div *ngFor="let month of monthsdata">
              <mat-option [value]="month">{{month.display_value}}</mat-option>
          </div>
      </ng-container>
    </mat-select>                                  
  </mat-form-field>
  </div>
  </div>
  <div class="mcard outline">
    <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
      <p class="card-category" style="color:#28acaf;font-size: 1.1rem;">Total Departments</p>
      <mat-form-field appearance="outline"  style="width: 90%;">
      <mat-label>Departments</mat-label>
      <mat-select formControlName="dept" style="width: 85%;">
        <mat-option value=null>All</mat-option>
        <ng-container>
          <div *ngFor="let dept of availableDepartments">
             
              <mat-option [value]="dept.id">{{dept.deptname}}</mat-option>
          </div>
      </ng-container>
    </mat-select>                                  
  </mat-form-field>

    </div>
  </div>
  <div class="mcard outline">
    <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
      <p class="card-category" style="color:#28acaf;font-size: 1.1rem;margin-bottom: 1rem;">Total Employees</p>
        <h4 class="card-title fontStyle">{{empcount}}</h4>
    </div>
  </div>
  <div class="mcard outline">
    <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
      <p class="card-category" style="color:#28acaf;font-size: 1.1rem;margin-bottom: 1rem;">Gross Earnings</p>
        <h4 class="card-title fontStyle">{{grosssalary}}</h4>
    </div>
  </div>

</div>

<div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" fxlayoutGap="5px" style="padding-left: 9px;">

  <div fxLayout="column" fxFlex="49" fxFlex.xs="100">
    <div class="card card-stats outline" appMaterialElevation [defaultElevation]="defaultElevation">
      <div class="custom-investment-th">Gross Salary Breakdown by Departments</div>
      <div class="card card-stats">
        <div class="card-body ">
          <chart #chart [type]="type" [data]="data" [options]="options" style="width:100%;height: 100%"></chart>
        </div>
      </div>    
  </div>
  </div>

<div fxFlex="2"></div>

  <div fxLayout="column" fxLayout.xs="column" fxFlex="49" fxFlex.lt-md="100" >
    <div class="card card-stats outline" appMaterialElevation [defaultElevation]="defaultElevation" style="margin-right: 14px;">
      <div class="card-body" style="height: 140px;">
          <div class="column" >
  
                  <div class="numbers text-center">
                      <p class="card-category" style="color:#28acaf;font-size: 1.1rem;">Net Pay</p>
                 </div>
                  <div fxLayout="row" fxLayoutAlign="space-around center">
                    <div fxLayout="column">
                    <span class="fontStyle">{{netSalary}}</span>&nbsp;
                    <span class="fontStyle">{{SelDisplay}}</span>
                    </div>
                    <div fxLayout="column">
                      <span class="fontStyle" style="text-align: center;">{{deductions}}</span>&nbsp;
                      <span class="fontStyle">Deductions</span>
                      </div>
                  <!-- <div class="col-6" style="float: left;margin-left: 10px;">
                    <p style="text-align: center;color: #5a5c69!important;font-size: 1.25rem;">{{netSalary}}</p>
                    <p style="text-align: center;font-size: 1.20rem;display: inline-block;">{{SelDisplay}}</p>
                  </div>

                  <div class="col-4" style="float: right;margin-left: 42px;">
                    <p style="text-align:center;color: #5a5c69!important;font-size: 1.25rem;">{{deductions}}</p>
                    <p style="text-align:center;font-size: 1.20rem;display: inline-block;">Deductions</p>
                  </div> -->
                        </div>
          </div>
      </div>
  </div>
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" class="row" style="padding-right:15px;padding-bottom: 10px;">
        
    <div class="scard outline">
      <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
        <p class="card-category" style="color:#28acaf;font-size: 1.1rem;margin-bottom: 1rem;">EPF</p>
        <h4 class="card-title" style="color: #5a5c69!important;font-size: 1.25rem;">{{EPF}}</h4>
      </div> 
    </div>

    <div class="scard outline">
      <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
        <p class="card-category" style="color:#28acaf;font-size: 1.1rem;margin-bottom: 1rem;">ESI</p>
        <h4 class="card-title" style="color: #5a5c69!important;font-size: 1.25rem;">{{ESI}}</h4>
      </div>   
    </div>

    <div class="scard outline">
      <div class="numbers text-center" style="margin-top: 7px; margin-bottom: 21px;">
        <p class="card-category" style="color:#28acaf;font-size: 1.1rem;margin-bottom: 1rem;">PF Tax</p>
        <h4 class="card-title" style="color: #5a5c69!important;font-size: 1.2rem;">{{pftax}}</h4>
      </div>   
    </div>
  </div>  

  </div>

</div>
<div fxLayout.lt-lg="row" fxLayout.xs="row" ngStyle.lt-lg="height:200px" ngStyle.xs="height:60px">
</div>


</form>
</div>
  </mat-card>
</div>