
<div class="first-col" fxLayout="column" >


  <mat-card>
    <mat-card-header >
        <mat-card-title > Company Logo </mat-card-title>
    </mat-card-header>

      <div class="content">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="card no-value">
                    <div class="card-header no-value no-border">
                      <div class="card-head-row">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="column custom-view-mbt-1">
                                  <div class="col-md-4 productAddfromImages" *ngFor='let url of imageurls; let i = index'>
                                    <img class="img-fluid custom-image"  [src]="url.base64String">
                                    <div class="row " >
                                      <span class="btn btn-success btn-file mr-3 mx-2 my-2" *ngIf="!isFileImage">
                                        Change <input type="file"
                                                        class="form-control"
                                                        (change)="onSelectFile($event)"
                                                        accept="image/*" />
                                    </span>
                                    <span class="btn btn-primary btn-file mr-3 mx-2 my-2 custom-button-save" *ngIf="isFileImage" style="width: fit-content;" (click)="saveImage()">
                                        Save
                                    </span>
                                    <span class="btn btn-primary btn-danger mr-3 mx-2 my-2 custom-button-save" *ngIf="isRemoveImage" style="width: fit-content;" (click)="removeImage()">
                                      Remove
                                  </span>
                                  <span class="btn btn-primary btn-danger mr-3 mx-2 my-2 custom-button-save"  *ngIf="isFileImage" style="width: fit-content;" (click)="cancelImage()">
                                    Cancel
                                </span>

                                    </div>

                                    <!-- <button (click)="removeImage()" class="btn btn-xs btn-danger  mx-2 my-2 custom-font-white"*ngIf="isRemoveImage" >Remove</button> -->
                                    <!-- <button (click)="cancelImage()"class="btn btn-xs btn-danger  mx-2 my-2 custom-font-white" *ngIf="isFileImage">cancel</button> -->
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
         
        
      </div>

  </mat-card>

  </div>
