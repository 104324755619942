<div mat-dialog-content>
    <span fxLayoutAlign="center">
        <b style="font-size: 24px;
        color: red;
    "> Attention!</b> 
    </span>
    <br>
   <p>Please note that you are no longer authorized to add new employees. If you want to add new employees, please upgrade your plan or increase the user count in your current plan. For further assistance, please contact your admistrator. 
</p>
<span fxLayoutAlign="center">
<b style="font-size: 20px;">Thank you!</b>
</span>
   </div>
   <div mat-dialog-actions 
   fxLayout="row" fxLayoutAlign="center">
    <button [mat-dialog-close] class="m-1 mat-stroked-button button-save" type="submit" color="#28acaf" >
      <span class="mat-button-wrapper">Ok</span>
      <div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false"
        ng-reflect-trigger="[object HTMLButtonElement]"></div>
      <div class="mat-button-focus-overlay"></div>
    </button>
   </div>
