<div fxLayout="column" class="first-col">
  <mat-card>

    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Bank Wise Salary Summary </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="isdata">
        <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Generate New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>

    <form [formGroup]="summaryForm">
      <div fxLayout="column" autocomplete="off" *ngIf="isAdd">
        <div fxLayout="row">
          <div fxFlex="75" fxLayout.xs="column" class="dev-Pad">

            <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Month & Year</mat-label>
              <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder=""
                (click)="fromDate.open()" formControlName="fromDate" required />
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate">
              </mat-datepicker-toggle>
              <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear($event, fromDate)">
              </mat-datepicker>
              <mat-error class="con-error" *ngIf="summaryForm.controls.fromDate.errors?.required">
                Please select an option
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
              <mat-label>Bank Name</mat-label>
              <mat-select formControlName="bankName" required>
                <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
                <ng-container>
                  <span *ngFor="let list of filteredBankList">
                    <mat-option [value]="list.id">{{list.bankname}}</mat-option>
                  </span>
                </ng-container>
              </mat-select>
              <mat-error class="con-error" *ngIf="summaryForm.controls.bankName.errors?.required">
                Please select an option
              </mat-error>
            </mat-form-field>

            <div style="padding-top: 10px;">
              <button class="btn btn-primary mr-2 mb-2" (click)="search()">
                Search
              </button>
              <span class="line" style="color: #1898d5; font-size: 16px"></span>
              <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>

        <div fxLayout="row">
          <div *ngIf="payList" style="margin-left: 2%;width: 50%;">

            <div>
              <label formArrayName="selectedChecklist" *ngFor="let data of paygroupFormArray.controls; let i = index">
                <mat-checkbox [formControlName]="i"
                  [disabled]="paygroupList[i].category_name == 'ESI' || paygroupList[i].category_name == 'EPF'|| paygroupList[i].category_name == 'PT' || paygroupList[i].notprocessedcount !=0   "
                  (change)="onSelect()">
                  {{paygroupList[i].category_name}}-{{paygroupList[i].amount}}
                  <span *ngIf="paygroupList[i].notprocessedcount !=0 ">{{'-'}} {{paygroupList[i].notprocessedcount}} -
                    employee salary pending</span>
                </mat-checkbox>
                <!-- <input type="checkbox" [formControlName]="i" (change)="onSelect()" [disabled]="isDisable"> 
                    {{paygroupList[i].category_name}}-{{paygroupList[i].amount}} -->
              </label>
              <!-- <p formArrayName="selectedChecklist" *ngFor="let order of checklistsFormArray.controls; let i = index">
                    <input type="checkbox" [formControlName]="i" (click)="onSelect()">
                    {{paygroupList[i].category_name}}-{{paygroupList[i].amount}}
                </p> -->
              <div><strong> Total Amount: {{''}}{{totalPaygroupAmount}}</strong></div>
            </div>
            <br>
            <div fxLayoutAlign="start">
              <button class="btn btn-primary mr-2 mb-2" (click)="submit()">
                Submit
              </button>
              <!-- <span class="line" style="color: #1898d5; font-size: 16px"></span>
                <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    <span>Cancel</span>
                </button> -->
            </div>

          </div>
        </div>
      </div>


      <!-- <mat-divider></mat-divider> -->
      <div fxLayout="column" *ngIf="isdata">
        <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
          <mat-form-field fxFlex="28" class="mx-2 my-2" appearance="outline">
            <mat-label>Month and Year</mat-label>
            <input matInput readonly [matDatepicker]="fromDate" placeholder="From Date" [formControl]="date"
              [min]="minDate" [max]="maxDate" (click)="fromDate.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate startView="multi-year" (monthSelected)="setMonthAndYear3($event, fromDate)"
              panelClass="example-month-picker">
            </mat-datepicker>

          </mat-form-field>

        </div>
        <div fxLayout="column" class="table-pad">
          <div class="mat-elevation-z1  example-container">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>

              <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Date</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.createddate}} </span>
                </td>
              </ng-container>


              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Category</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.category}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="bank">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Bank Name</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.bankname}} </span>
                </td>
              </ng-container>


              <ng-container matColumnDef="paygroup">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Pay List</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.paygroupnames}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Amount</th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.amount}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"></th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button *ngIf="row.category=='SALARY'" (click)="viewPaygroup(row)" title="View">
                    <mat-icon mat-icon-button>visibility</mat-icon>
                  </button>

                  <button mat-icon-button *ngIf="row.category=='ESI'" (click)="esiView(row)" title="View">
                    <mat-icon mat-icon-button>visibility</mat-icon>
                  </button>

                  <button mat-icon-button *ngIf="row.category=='EPF'" (click)="epfPrint(row)" title="View">
                    <mat-icon mat-icon-button>visibility</mat-icon>
                  </button>

                  <button mat-icon-button *ngIf="row.category=='PT'" (click)="professionalTaxView(row)" title="View">
                    <mat-icon mat-icon-button>visibility</mat-icon>
                  </button>

                  <!-- <span>{{row.personal_email}} </span> -->
                </td>
              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" style="text-align: center;">No data found</td>
              </tr>
            </table>

            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>
        </div>

      </div>
    </form>

    <!-- pay group print table -->
    <!-- <div>
        <div id="table" #table="">
          <table mat-table [dataSource]="dataSource2" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef> S.No </th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }} </td>
          </ng-container>
    
              <ng-container matColumnDef="empid">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp ID</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.employeeId}} </span></td>
              </ng-container>

              <ng-container matColumnDef="empname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp Name </th>
                <td mat-cell *matCellDef="let row" ><span >{{row.employeeName}} </span></td>
              </ng-container>

              <ng-container matColumnDef="amount">
                 <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;" >Amount </th>
                 <td mat-cell *matCellDef="let row" ><span >{{row.Amount}} </span></td>
              </ng-container>

              <ng-container matColumnDef="accountnumber">
                <th mat-header-cell *matHeaderCellDef  >Account Number </th>
                <td mat-cell *matCellDef="let row" ><span >{{row.accountNumber}} </span></td>
              </ng-container>

                <ng-container matColumnDef="bank">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Bank</th>
                    <td mat-cell *matCellDef="let row" ><span >{{row.bankName}} </span></td>
                </ng-container>

                <ng-container matColumnDef="ifsc">
                        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">IFSC</th>
                        <td mat-cell *matCellDef="let row" ><span >{{row.ifscCode}} </span></td>
                </ng-container>

              <tr  mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table>
         
          <mat-paginator  #paginatorRef [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
      </div> -->
  </mat-card>
</div>