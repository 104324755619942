import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCurrentdaypunchdetailsComponent } from '../dialog-currentdaypunchdetails/dialog-currentdaypunchdetails.component';
import { MatSelect } from '@angular/material/select';
import { ReportsService } from '../../reports.service';
import { EmsService } from 'src/app/modules/ems/ems.service';
import * as XLSX from 'xlsx';
interface EmployeePunchDetail {
  empname: string;
  empid: string;
  punchtimes: Date[];
  categories: string[];
  attendanceSource: string[];
  location: string[];
}
@Component({
  selector: 'app-current-day-punch-details',
  templateUrl: './current-day-punch-details.component.html',
  styleUrls: ['./current-day-punch-details.component.scss']
})

export class CurrentDayPunchDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  displayedColumns: string[] = ['sno', 'empcode', 'empname', 'punchtimes', 'categories', 'attendanceSource', 'location', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  date: any = '';
  uniqueLocations: string[] = [];
  selectedLocation: string = 'All Locations';
  searchQuery: string = '';
  searchControl = new FormControl();
  filteredLocations: any = [];
  worklocationDetails: any = [];
  companyName: any = [];
  searchForm: any = UntypedFormGroup;
  pageLoading = true;
  userSession: any;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  selectedLocations: any = [];
  employeesDetails: any = [];
  filteredEmployeelist: any = [];
  employeeList: any = [];
  location_id: any;
  List: any = [];
  searchControlforEmp = new FormControl();

  constructor(
    private companyService: CompanySettingService,
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private emsService: EmsService
  ) {

    this.date = new Date().toLocaleDateString();

  }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.searchControl.valueChanges.subscribe((searchText: any) => {
      this.filterLocations(searchText)
    });
    this.companyName = sessionStorage.getItem("companyName");
    this.getWorkLocation();
    this.searchForm = this.formBuilder.group({
      location: ['', Validators.required],
      employee: ['']
    });
    this.searchForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      this.location_id = selectedValue;
      this.getEmployeelist();
    })
    this.searchControlforEmp.valueChanges.subscribe((searchText: any) => {
      this.filterEmployee(searchText)
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyCombinedFilter(): void {
    this.dataSource.filter = JSON.stringify({ location: this.selectedLocation, query: this.searchQuery });
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  customFilterPredicate(): (data: EmployeePunchDetail, filter: string) => boolean {
    return (data: EmployeePunchDetail, filter: string): boolean => {
      const filterObj = JSON.parse(filter);
      const locationMatch = filterObj.location === 'All Locations' || data.location.includes(filterObj.location);
      const nameMatch = !filterObj.query || data.empname.toLowerCase().includes(filterObj.query.toLowerCase());
      return locationMatch && nameMatch;
    };
  }

  getSerialNumber(index: number): number {
    return index + 1 + (this.paginator ? this.paginator.pageIndex * this.paginator.pageSize : 0);
  }

  filterLocations(searchText: any) {
    this.filteredLocations = this.worklocationDetails.filter((val: any) =>
      val.location.toLowerCase().includes(searchText.toLowerCase()) || val.cityname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredLocations.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails
    })
  }

  getDetails() {
    if (this.searchForm.valid) {
      // let locations = this.searchForm.controls.location.value;
      // for (let i = 0; i < locations.length; i++) {
      //   const element = locations[i];
      //   this.location_id.push(element.id)
      // }
      let data = {
        // location: JSON.stringify(this.location_id),
        location: this.location_id,
        employeeId: this.searchForm.controls.employee.value,
      }
      this.attendanceService.getCurrentdayAttendanceSummaryReport(data).subscribe((result: any) => {
        this.List = result.data;
        this.dataSource = new MatTableDataSource(this.List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        // this.location_id = [];
      })
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogCurrentdaypunchdetailsComponent, {
      width: '1200px', position: { top: `70px` },
      data: { attendanceid: item.attendanceid, }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  locationSelectAll(select: MatSelect, values: any, array: any) {
    this.locationIshide = true;
    this.locationIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.location.setValue(array)
    let locations = this.searchForm.controls.location.value;
    locations.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getEmployeelist();
  }
  locationDeselectAll(select: MatSelect) {
    this.locationIshide = false;
    this.locationIschecked = false
    this.selectedLocations = [];
    select.value = [];
    this.searchForm.controls.location.setValue('');
    this.selectedLocations = [];
    this.filteredEmployeelist = [];
  }
  onchangeLocation(value: any) {
    this.selectedLocations = [];
    let location = this.searchForm.controls.location.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    this.getEmployeelist();
  }

  getEmployeelist() {

    this.employeeList = [];
    this.filteredEmployeelist = [];
    this.emsService.getEmployeeDirectoryList(this.location_id).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {
        this.employeeList = res.data;
        this.filteredEmployeelist = this.employeeList;
      }
    })
    this.searchForm.controls.employee.setValue('0')
    // let locations = this.searchForm.controls.location.value;
    // for (let i = 0; i < locations.length; i++) {
    //   const element = locations[i];
    //   this.location_id.push(element.id)
    // }
    // let obj = {
    //   location_id: JSON.stringify(this.location_id),
    // };
    // this.reportsService.getEmployeslistByLocationId(obj).subscribe((res: any) => {
    //   if (res.status) {
    //     this.employeesDetails = [];
    //     this.employeesDetails = res.data;
    //     this.filteredEmployeelist = this.employeesDetails,
    //       this.searchForm.controls.employee.setValue('0');
    //   }
    // });
  }
  resetform() {
    this.searchForm.controls.location.setValue('');
    this.searchForm.controls.employee.setValue('');
    this.filteredEmployeelist = [];
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);
  }

  filterEmployee(searchText: any) {
    this.filteredEmployeelist = this.employeeList.filter((val: any) =>
      val.ename.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployeelist.length <= 0) {
      this.searchControlforEmp.setValue('');
    }
  }

  downloadPDF() {
    // let fromdate = (this.datePipe.transform(this.date, "dd-MM-YYYY"))
    let data = {
      'list': this.List,
      'report_date': this.date
    }
    this.reportsService.getAttendanceSummaryReportPdf(data).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'attendance_summary_report.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  exportAsXLSX() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attendance_Summary_Report');
    /* save to file */
    XLSX.writeFile(wb, 'Attendance_Summary_Report.xlsx');

  }



}
