<div fxLayout="column" class="first-col">
    <mat-card>
        <form [formGroup]="approvalForm" autocomplete="off">
            <mat-card-header class="headerbox">
                <mat-card-title class="title"> Document Approvals </mat-card-title>
            </mat-card-header>
            <br>
            <!-- <mat-divider></mat-divider> -->
            <div *ngIf="ishide" class="table-pad">
                <div class="example-container mat-elevation-z1">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S. No.</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="empid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                                Emp. Id
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{ row.empcode }} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">
                                Emp. Name
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <span>{{ row.empname }} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef></th>

                            <td mat-cell *matCellDef="let row">
                                <!-- <mat-icon title="Edit"  (click)="edit($event,row)" >edit</mat-icon> -->
                                <button mat-icon-button [tabIndex]="[1]" (keyup.enter)="preview($event, row)">
                                    <mat-icon title="Review & Approve"
                                        (click)="preview($event, row)">visibility</mat-icon>
                                </button>

                            </td>
                        </ng-container>

                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
            <div *ngIf="isview" class="div-pad">
                <div fxLayout="row wrap" fxLayout.xs="column">
                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Employee Name</mat-label>
                        <input formControlName="empname" matInput placeholder="" readonly [tabIndex]="[1]" />
                    </mat-form-field>
                    <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex.lt-lg="50" class="mx-2 my-2"
                        appearance="outline">
                        <mat-label>Employee Id</mat-label>
                        <input formControlName="empid" matInput placeholder="" readonly [tabIndex]="[2]" />
                    </mat-form-field>
                </div>
                <div style="padding-left: 1%;padding-right: 1% ;padding-bottom: 1%">
                    <div class="mat-elevation-z1 example-container">
                        <table mat-table [dataSource]="datadocumentsSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S.No</th>
                                <td mat-cell *matCellDef="let row; let i = index">
                                    <span>{{ i + 1 }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="document">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-break">
                                    Document Name
                                </th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <span>{{ row.description }} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="documentnumber">
                                <th mat-header-cell *matHeaderCellDef class="no-break">Document Number</th>
                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <span>{{ row.document_number }} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="file">
                                <th mat-header-cell *matHeaderCellDef>File</th>
                                <td mat-cell *matCellDef="let row">
                                    <span class="link" (click)="fileView(row)">{{ row.fname }}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>

                                <td mat-cell *matCellDef="let row" class="no-break">
                                    <!-- <mat-icon title="Edit"  (click)="edit($event,row)" >edit</mat-icon> -->
                                    <button mat-icon-button [tabIndex]="[2]" (keyup.enter)="approve($event, row)">
                                        <mat-icon title="Approve" (click)="approve($event, row)">check_box</mat-icon>

                                    </button>
                                    <button mat-icon-button [tabIndex]="[3]" (keyup.enter)="reject($event, row)">
                                        <mat-icon title="Reject" (click)="reject($event, row)">cancel</mat-icon>

                                    </button>

                                </td>
                            </ng-container>

                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="5" style="text-align: center">
                                    No data found
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Close</span>
                    </button>
                    <br />
                </div>
            </div>
        </form>
    </mat-card>
</div>