import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from '../../reusable-dialog/reusable-dialog.component';
import { MatRadioChange } from '@angular/material/radio';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin-tickets',
  templateUrl: './admin-tickets.component.html',
  styleUrls: ['./admin-tickets.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminTicketsComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    private moduleService: LoginService,
    private dialog: MatDialog,
    private mainService: MainService,
    private router: Router,
    public datePipe: DatePipe,
    public spinner: NgxSpinnerService) { }
  displayedColumns: string[] = ['sno', 'ticketno', 'organisation', 'issuedate', 'module', 'screen', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageSizeOptions: number[] = [5, 10, 25, 100]; // Choose your own page size options
  pageSize: number = 10;
  pageIndex: number = 0;


  pageLoading = true;
  adminTicketForm: any = UntypedFormGroup;
  ticketHistoryForm: any = UntypedFormGroup;
  allModuleDetails: any = [];
  selectedModule: any;
  fileURL: any;
  pdfName: any = null;
  document: boolean = false;
  iseditDoc: boolean = true;
  isFile: boolean = true;
  isView: boolean = false;

  statusList: any = [];
  priorityList: any = [];
  employeeList = [
    { id: 4, value: "Vinil" },
    { id: 3, value: "Mahesh" },
    { id: 5, value: "Pranitha" },
  ]
  minDate = new Date('2020/01/01'); maxDate = new Date();
  ticketId: any;
  userSession: any;
  requestId: any;
  ticketCode: any;
  issueRaisedDate: any;
  companyName: any;
  raisedEmployee: any;
  moduleName: any;
  screenName: any;
  empDescription: any;
  openTicketList: any = [];
  otherTicketList: any = [];
  isOpenList: boolean = true;
  ngOnInit(): void {
    this.companyName = sessionStorage.getItem('companyName');
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.adminTicketForm = this.formBuilder.group({
      document: [''],
      ticketId: [''],
      issueStatus: [''],
      asgEmployee: [],
      asgRemarks: [],
      priority: [],
    })
    this.ticketHistoryForm = this.formBuilder.group({
      fromDate: [new Date()],
      toDate: [new Date()],
    })
    this.getEmployeeTicketList();
    this.getStatusList();
    this.getPriorityList();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ispdf: boolean = false;
  file: any;

  onSelectFile(event: any) {

    this.iseditDoc = true;

    if (event.target.files[0].size <= 1242880) {
      //  var pdfArray =[];

      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');

      if (pdf[pdf.length - 1] == 'pdf' || pdf[pdf.length - 1] == 'jpg' || pdf[pdf.length - 1] == 'png') {
        this.isFile = true;

      } else {
        this.ispdf = true;
        this.isFile = false;

        this.open("Only JPG,PNG and PDF files supported", '8%', '500px', '250px', false, null)


      }

    } else {
      this.ispdf = false;
      this.isFile = false;

      this.open("File size must be less than 1MB", '8%', '500px', '250px', false, null)
    }
  }

  delete() {
    this.adminTicketForm.controls.document.setValue("");
  }
  selectedTabChange(data: any) {

  }
  getPriorityList() {
    this.mainService.getHelpPriorities().subscribe((res: any) => {
      if (res.status) {
        this.priorityList = res.data;
      }
    })
  }

  getStatusList() {
    this.mainService.getHelpTicketStatus().subscribe((res: any) => {
      if (res.status) {
        this.statusList = res.data;
      }
    })
  }

  fileView() {

    window.open(this.fileURL);

  }
  editdoc() {
    this.pdfName = null;
  }
  open(errormessages: any, top: any, width: any, height: any, flag: any, url: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, { position: { top: `70px` }, data: { "Message": errormessages, flag: flag } });
    dialogRef.afterClosed().subscribe(result => { });
  }

  getEmployeeTicketList() {
    this.openTicketList = [];
    this.otherTicketList = [];
    let data = {
      'from_date_value': this.datePipe.transform(this.ticketHistoryForm.controls.fromDate.value, "y-MM-d"),
      'to_date_value': this.datePipe.transform(this.ticketHistoryForm.controls.toDate.value, "y-MM-d")
    }
    this.mainService.getSprypleHelpTickets(data).subscribe((res: any) => {
      if (res.status && res.data.length > 0) {
        let list = res.data;
        list.forEach((e: any) => {
          if (e.ticketStatus == "Open") {
            this.openTicketList.push(e);
          } else if (e.ticketStatus != "Open") {
            this.otherTicketList.push(e);
          }
        })
        if (this.isOpenList == true) {
          this.dataSource = new MatTableDataSource(this.openTicketList);
          setTimeout(() => {
            this.pageLoading = false;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.spinner.hide();
          }, 500);

        } else {
          this.dataSource = new MatTableDataSource(this.otherTicketList);
          setTimeout(() => {
            this.pageLoading = false;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.spinner.hide();
          }, 500)
        }

      }
    })
  }

  submit() {
    if (this.adminTicketForm.valid) {
      let data = {
        'id_value': this.requestId == null || '' ? null : this.requestId,
        'ticket_id_value': this.ticketId,
        'employee_id_value': this.adminTicketForm.controls.asgEmployee.value,
        'created_by_value': this.userSession.id,
        'status_value': this.adminTicketForm.controls.issueStatus.value,
        'assign_description_value': this.adminTicketForm.controls.asgRemarks.value,
        'priority_value': this.adminTicketForm.controls.priority.value,
      }
      this.mainService.setHelpTicketAssignment(data).subscribe((res: any) => {
        if (res.data.statuscode == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Ticket assigned successfully."
          });
          this.clear();
        } else if (res.data.statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Ticket data modified successfully."
          });
          this.clear();
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to assign ticket."
          });
        }
      })
    }
  }
  searchform() {
    this.getEmployeeTicketList();
  }

  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/admin_ticket']));
  }

  back() {
    this.isView = false;
   this.ngAfterViewInit();
  }

  editEmployee(data: any) {
    this.isView = true;
    this.mainService.getTicketDetailsById(data.ticketId).subscribe((res: any) => {
      if (res.status) {
        this.ticketId = data.ticketId;
        let value = res.data;
        this.ticketCode = value.ticketCode;
        this.issueRaisedDate = value.createdOn;
        this.companyName = value.companyName;
        this.raisedEmployee = value.employeeName;
        this.moduleName = value.moduleName;
        this.screenName = value.screenName;
        this.empDescription = value.description;
        this.adminTicketForm.controls.asgRemarks.setValue(data.trackingDescription);
      }
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.getOpenTicketList();

    } else if (event.value == 2) {
      this.getRemaingTicketList();
    }
  }

  getOpenTicketList() {
    this.isOpenList = true;
    this.getEmployeeTicketList();

  }

  getRemaingTicketList() {
    this.isOpenList = false;
    this.getEmployeeTicketList();
  }

}

