import { Component, OnInit, ViewChild,Inject,ElementRef, LOCALE_ID,  } from '@angular/core';
import {UntypedFormGroup,UntypedFormBuilder,  Validators, } from '@angular/forms';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import {WebcamModalComponent} from "../../../../pages/webcam-modal/webcam-modal.component";
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {CrmService} from "../../crm.service";
import { DatePipe } from '@angular/common';
import { LeavesService } from '../../../leaves/leaves.service';
import {MapService} from "src/app/modules/crm/map.service"; 
import { Observable,of } from 'rxjs';
import { FormControl } from '@angular/forms';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
import CryptoJS from 'crypto-js';
@Component({
  selector: 'app-get-location',
  templateUrl: './get-location.component.html',
  styleUrls: ['./get-location.component.scss']
})
export class GetLocationComponent implements OnInit {
  companyForm:any;
  stateDetails:any = [];
  cityDetails:any = [];
  imageDataUrl:any = {imageData:null,notes:null};
  selected_location:any = null;
  userSession:any ;
  CountryDetails:any;
  formattedDate:any;
  formattedTime:any;
  timeStampVal:any;
  currentDate:any; 
  pipe:any; 
  infoContent:any;
  address:any;
  selectedLocation: google.maps.LatLngLiteral | undefined;
  zone: any;
  predictions: google.maps.places.AutocompletePrediction[] = [];
  @ViewChild('videoElement')
  videoElement!: ElementRef;
  @ViewChild('canvasElement')
  canvasElement!: ElementRef;
  downloadCapture:any = true;
  stopCamera:any = true;
  pageTitle:any = 'Add Client';
  location_icon_val:any = 'location_on';
  mode:any = 'map'; // details, map, image
  file:any;
  isFileImage:any= true;
  isCameraAvailable:any = true;
  isVedioOpen:any = false;
  dispayImage:any = true;
  isWebRestricted:any = 1;
  mapInitialized:any=false;
  mapOptions: google.maps.MapOptions = {
    zoom: 18,
  };
  @ViewChild('addressInput') addressInput!: ElementRef;
  marker:any= null; //google.maps.marker.AdvancedMarkerElement | undefined;
  geocoder: any;
  displayOptions: any[] = [];
  myControl = new FormControl();
 // markerInMap:any; // = new google.maps.Marker({});
  infoWindow:any; // = google.maps.InfoWindow;
  map:any = null; // google.maps.Map;
  center:any={lat :0,lng: 0};
  markerPositions: any=[]; 
  isSelected:any = false;
  filteredOptions: Observable<void> | undefined ;
  searchClientOnMap:any = false;
  fetchedAddress:any = null;
  autocomplete:any;
  client_id :any = 0;
  localdata:any;
  editMode:any = false;
  service:any;
  //companyinfo:any=[]; // get client info 
  constructor(@Inject(LOCALE_ID) private locale: string,


  
  public dialogRef: MatDialogRef<GetLocationComponent>,private crm:CrmService,private spinner: NgxSpinnerService,
    private FormBuilder:UntypedFormBuilder,private company:CompanySettingService,
    private dialog: MatDialog,private LMS:LeavesService, private mapService:MapService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
      if (this.userSession.isCrmWebDisabled !== undefined) {
        this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
      }
      this.companyForm=this.FormBuilder.group({
        companyname:[""],
        contact_person_name: [""],
        contact:[""],
        address1:["",[Validators.required ]],
        address2:[""],
        city: ["",Validators.required],
        state: ["",Validators.required],
        pincode: ["",Validators.required],
        country: ["",Validators.required],
        notes:[""],
        latitude:[""],
        longitude:[""],
       
       // client_status:["",[Validators.required]],   
       // demo_status:["",[Validators.required]]
      });
    
        if(this.data && this.data.client_id){
          this.client_id = this.data.client_id;
          this.getClientData();
          this.pageTitle = 'Edit Client';
        } else {
          this.editMode = true;
          this.getCountry();
        }
        this.setmap();
      
     }

  ngOnInit(): void {
    // if(this.mapInitialized){
    //   if(this.data && this.data.client_id){
    //     this.client_id = this.data.client_id;
    //     this.getClientData();
    //     this.pageTitle = 'Edit Client';
    //   } else {
    //     this.editMode = true;
    //     this.getCountry();
    //   }
    // }
  
     
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    if (this.userSession.isCrmWebDisabled !== undefined) {
      this.isWebRestricted = Number(this.userSession.isCrmWebDisabled );
    }
     
    this.mode = 'map';
    this.pipe = new DatePipe(this.locale);
    this.currentDate = new Date();
    this.formattedDate = this.pipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.formattedTime = this.currentDate.toLocaleTimeString(undefined, {hour12: false, hour: '2-digit',minute: '2-digit', second: '2-digit' });
    this.timeStampVal = this.currentDate.getTime();
    this.companyForm.get('country')?.valueChanges.subscribe((selectedValue: any) => {
      this.stateDetails= [];
      this.crm.getStates(selectedValue).subscribe((data)=>{
        this.stateDetails = data.data;
      })
    });

    this.companyForm.get('state')?.valueChanges.subscribe((selectedValue: any) => {
      this.companyForm.controls.city.setValue("");
      this.cityDetails = [];
       this.company.getCities(selectedValue).subscribe((data)=>{
            this.cityDetails=data.data
        })
      })
    //@ts-ignore

    // this.autocomplete = new google.maps.places.Autocomplete(
    //   //document.getElementById('autocomplete') as HTMLInputElement,
    //      (this.addressInput && this.addressInput.nativeElement)? this.addressInput.nativeElement:'',
    //      //{ types: ['geocode']   } // Restrict to addresses
    //    );
       //@ts-ignore
      //  this.autocomplete.addListener('place_changed', () => {
      //   let place = this.autocomplete.getPlace();
      //   if(place.geometry && place.geometry.location){
      //     let latlng = JSON.parse(JSON.stringify(place.geometry.location));
      //      this.createMarker(place);
      //       this.crm.fetchMapLocationDetails(latlng ).subscribe((data) => {
      //       this.fetchedAddress = data;     
      //       this.fixFetchedAddress(this.fetchedAddress,latlng);
      //     });
      //   }
      // });

     this.myControl.setValue('');
    this.myControl.valueChanges.subscribe((val: any) => {     
       this.displayOptions = []; 
      //if(!this.isSelected){
        if(val.toString().length>=3){
          this.initAsyncMapPlaces(val);
        }
    });
  }
 
    async initAsyncMapPlaces(val:any): Promise<void> {
    if(typeof val === 'string'){
      this.isSelected = false;
      
      this.service =  new google.maps.places.PlacesService(this.map);
      this.service.findPlaceFromQuery( {query: val,fields: ["name","geometry",'formatted_address', 'place_id', 'rating'] },(places:any, status:any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && places) {
          this.displayOptions = []; 
          places?.forEach(async ( place:any ) => {
            if(!this.displayOptions.includes(place))
            this.displayOptions?.push(place);
          });
        }
      });
    }
  }
  setmap(){
    if(!this.mapInitialized){
      this.spinner.show();
      setTimeout(() => {
      this.mapService.initMap().then(async() => {
        this.geocoder = new google.maps.Geocoder();
        this.spinner.hide();
        if(this.localdata && this.localdata.lat && this.localdata.lng)
        this.initAsyncMap({lat:Number(this.localdata.lat),lng:Number(this.localdata.lng)});
        else this.initAsyncMap();
        //@ts-ignore
        const [{ Map }, { AdvancedMarkerElement }] = await Promise.all([
          google.maps.importLibrary("marker"),
          google.maps.importLibrary("places"),
        ]);
        if(!this.map)
          this.map = google.maps.Map;
       // this.marker = google.maps.marker.AdvancedMarkerElement ;
       
            
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: this.center,
          zoom: 18, 
          mapTypeControl: false,
        }); 
        if(!this.marker)
          this.marker = new google.maps.Marker({ map:this.map });
        // Code to execute after the map has been initialized
        this.mapInitialized = true;
         this.clearNewClient();
      }).catch((error: any) => {
        // Handle any errors that occurred during map initialization
        console.error('Error initializing map:', error);
      });
    }, 200); 
    }
  }

  async initAsyncMap(coords:any = null)  {
    this.displayOptions = [];
    if(!coords){
      this.companyForm.controls.latitude.setValue(0);
    this.companyForm.controls.longitude.setValue(0);
    navigator.geolocation.getCurrentPosition( (position) => {
      this.center ={lat : position.coords.latitude,lng: position.coords.longitude};
      this.selected_location = this.center;
    },(error) => console.log(error));
    } else {
      this.companyForm.controls.latitude.setValue(coords.lat);
      this.companyForm.controls.longitude.setValue(coords.lng);
      this.center = coords;
    }
      if(this.mapInitialized){
        if(!this.map)
          this.map = google.maps.Map;
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: this.center,
          zoom: 18, 
          mapTypeControl: false,
        });
      }
      this.addMarker(coords);
    
  }
   
 
  createMarker(place:any) {
   // this.markers = [];
    //this.markerInMap?.Remove();
    if(this.mapInitialized){
      if (!place.geometry || !place.geometry.location) return;
      this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: place.geometry.location,
        zoom: 18, 
        mapTypeControl: false,
      }); 
    }
    this.addMarker(place.geometry.location);
    
    //this.marker = place.geometry.location;
   // this.map.setCenter(place.geometry.location);
   // google.maps.event.addListener(this.marker, "click", () => {
      //this.infoWindow.setContent(content);
    //  this.infoWindow.setPosition(place.geometry.location);
      //this.infoWindow? this.infoWindow.open(marker):null; 
    //});
  }

  
addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
   //@ts-ignore
   if(this.mapInitialized){
    this.marker = new google.maps.Marker({position, map:this.map });
   }
  
  //let latlng = JSON.parse(JSON.stringify(position));
  // this.crm.fetchMapLocationDetails(latlng ).subscribe((data) => {
  //   this.fetchedAddress = data;     
  //    this.fixFetchedAddress(this.fetchedAddress);
  // });
}

  selectValue(option:any) {
    this.displayOptions = [];
    this.isSelected = true;
    this.myControl.setValue(option.name + option.formatted_address);
    this.createMarker(option);
    this.selected_location = {lat: option.geometry.location.lat(),lng: option.geometry.location.lng() };
    this.crm.fetchMapLocationDetails(this.selected_location ).subscribe((data) => {
      this.fixFetchedAddress(data,this.selected_location,option);
      this.companyForm.controls.latitude.setValue(this.selected_location.lat);
      this.companyForm.controls.longitude.setValue(this.selected_location.lng);
    }); 
  }
  refreshMap(){
    this.myControl.setValue('');
    this.displayOptions =[];
    this.searchClientOnMap = false;
    this.fetchedAddress= null;
    this.selected_location = null;
   
    if(this.client_id){
      this.showLocalData();
    }else if(this.editMode && !this.client_id){
      this.companyForm.get('address2').reset();
      this.companyForm.get('address2').setErrors(null);
      this.companyForm.get('country').reset();
      this.companyForm.get('country').setErrors(null);
      this.companyForm.get('state').reset();
      this.companyForm.get('state').setErrors(null);
      this.companyForm.get('city').reset();
      this.companyForm.get('city').setErrors(null);
      this.companyForm.get('pincode').reset();
      this.companyForm.get('pincode').setErrors(null);
      this.companyForm.get('latitude').reset();
      this.companyForm.get('latitude').setErrors(null);
      this.companyForm.get('longitude').reset();
      this.companyForm.get('longitude').setErrors(null);
      this.companyForm.get('targetPrice').reset();
      this.companyForm.get('targetPrice').setErrors(null);
      this.initAsyncMap();      
    }
  }

  searchlocationOnMap(){
    this.searchClientOnMap = true;
    // if(!this.mapInitialized){
    //   this.mapService.initMap().then(() => {
    //     this.initAsyncMap();
        
    //     // Code to execute after the map has been initialized
    //     this.mapInitialized = true;
    //   }).catch((error: any) => {
    //     // Handle any errors that occurred during map initialization
    //     console.error('Error initializing map:', error);
    //   });
    // } 
    // this.initAsyncMap2();
  }

  async fixFetchedAddress(data:any = null,coords:any = null,option:any = null){  
    if(option && option.formatted_address){
      this.companyForm.controls.address1.setValue(option.formatted_address);
    } else {
      this.geocodeAddress(coords);
    }
    if(coords){
      this.companyForm.controls.latitude.setValue(coords.lat);
      this.companyForm.controls.longitude.setValue(coords.lng);
    }
    if(data && this.editMode){       
      this.companyForm.controls.address2.setValue(data.display_name);
      this.companyForm.controls.pincode.setValue(data.address.postcode);     
      this.getCountry(data.address.country,data.address);
      
    }
    else{
      this.companyForm.get('address2').reset();
      this.companyForm.get('address2').setErrors(null);
      this.companyForm.get('country').reset();
      this.companyForm.get('country').setErrors(null);
      this.companyForm.get('state').reset();
      this.companyForm.get('state').setErrors(null);
      this.companyForm.get('city').reset();
      this.companyForm.get('city').setErrors(null);
      this.companyForm.get('pincode').reset();
      this.companyForm.get('pincode').setErrors(null);
      this.companyForm.get('latitude').reset();
      this.companyForm.get('latitude').setErrors(null);
      this.companyForm.get('longitude').reset();
      this.companyForm.get('longitude').setErrors(null);
    }   
  
  }
  
  // openInfo(marker: MapMarker, content: any) {  
  //   this.infoContent = content;  
  //   this.infoWindow? this.infoWindow.open(marker):null; 
  // }

 

    // openInfoWindow(marker: MapMarker) {
    //     if (this.infoWindow != undefined) this.infoWindow.open(marker);
    // }
    
  async openWebcamModal() {
    if(this.mode === 'image')
    this.mode = 'details'
    else this.mode = 'image';
   // this.imageDataUrl = {imageData:null,notes:null};
   //this.initCamera();
    // const dialogRef = this.dialog.open(WebcamModalComponent,{width: '350px', disableClose: false });
    // await dialogRef.afterClosed().subscribe(result => {
    //   this.imageDataUrl = result;
    // });
  }

  async setLocationInMap() {
    
    if(this.mode === 'map')
      this.mode = 'details'
    else{  
        // if(!this.mapInitialized){
        //   this.mapService.initMap().then(() => {
        //     if(this.companyForm.controls.latitude.value && this.companyForm.controls.latitude.value){
        //       let position: google.maps.LatLng = JSON.parse(JSON.stringify({lat:Number(this.companyForm.controls.latitude.value),lng:Number(this.companyForm.controls.latitude.value)}));
        //       this.initAsyncMap(position);
        //     }  else this.initAsyncMap();
        //     this.mapInitialized = true;
        //   }).catch((error: any) => {
        //     console.error('Error initializing map:', error);
        //   });
        // }
        this.mode = 'map';      
      }    
    } 
  

  getClientLocationFromMap(){
    this.searchClientOnMap = false;
    this.editMode = true;
    // if(!this.mapInitialized){
    //   this.mapService.initMap().then(() => {
    //     this.initAsyncMap();
        
    //     // Code to execute after the map has been initialized
    //     this.mapInitialized = true;
    //   }).catch((error: any) => {
    //     // Handle any errors that occurred during map initialization
    //     console.error('Error initializing map:', error);
    //   });
    // }
      navigator.geolocation.getCurrentPosition( (position) => {
        this.selected_location = {lat: position.coords.latitude,lng: position.coords.longitude};
        this.crm.fetchMapLocationDetails(this.selected_location ).subscribe((data) => {
          this.fixFetchedAddress(data,this.selected_location);

          this.addMarker(this.selected_location);
          this.map.setCenter(this.selected_location);
          this.companyForm.controls.latitude.setValue(this.selected_location.lat);
          this.companyForm.controls.longitude.setValue(this.selected_location.lng);
        });         
      },(error) => console.log(error));
    
    // if(this.selected_location){
    //   this.companyForm.controls.latitude.setValue(this.selected_location.lat);
    //     this.companyForm.controls.longitude.setValue(this.selected_location.lng);
    //   this.map.setCenter({lat:this.selected_location.lat,lng:this.selected_location.lng});
    //   this.addMarker({lat:this.selected_location.lat,lng:this.selected_location.lng});
    //   this.fixFetchedAddress(this.selected_location.address);
    // }
  }


  companyFormReset(){
   this.companyForm.reset();
   this.companyForm.get('contact_person_name').reset();
   this.companyForm.get('contact_person_name').setErrors(null);
   this.companyForm.get('contact').reset();
   this.companyForm.get('contact').setErrors(null);
   this.companyForm.get('email').reset();
   this.companyForm.get('email').setErrors(null);
   this.companyForm.get('address1').reset();
   this.companyForm.get('address1').setErrors(null);
   this.companyForm.get('address2').reset();
   this.companyForm.get('address2').setErrors(null);
   this.companyForm.get('country').reset();
   this.companyForm.get('country').setErrors(null);
   this.companyForm.get('state').reset();
   this.companyForm.get('state').setErrors(null);
   this.companyForm.get('city').reset();
   this.companyForm.get('city').setErrors(null);
   this.companyForm.get('pincode').reset();
   this.companyForm.get('pincode').setErrors(null);
   this.companyForm.get('latitude').reset();
   this.companyForm.get('latitude').setErrors(null);
   this.companyForm.get('longitude').reset();
   this.companyForm.get('longitude').setErrors(null);
   this.companyForm.get('notes').reset();
   this.companyForm.get('notes').setErrors(null);
   this.companyForm.get('targetPrice').reset();
   this.companyForm.get('targetPrice').setErrors(null);
  }
/**Here saved location details send as response */
  submittedLocation(){  
    if(this.companyForm.valid){
      let companyinformation ={  
        address1:this.companyForm.controls.address1.value,
        address2:this.companyForm.controls.address2.value?this.companyForm.controls.address2.value:'',
        country:this.companyForm.controls.country.value,
        state:this.companyForm.controls.state.value,
        city:this.companyForm.controls.city.value,
        pincode:this.companyForm.controls.pincode.value,
        createdby:this.userSession.id,
        latitude:this.companyForm.controls.latitude.value,
        longitude:this.companyForm.controls.longitude.value,
       
      }
      
      this.dialogRef.close(companyinformation);
    }

  }
  
  getCountry(country:any=null,address:any = null){
    this.spinner.show();
    this.crm.getCountry(null,1,10).subscribe((results)=>{
      this.spinner.hide();
      this.CountryDetails=results.data;
      if(country){
        this.CountryDetails.forEach((c:any)=>{
          if( c.country.toString().toLowerCase().trim() === country.toString().toLowerCase().trim()){
            this.companyForm.patchValue({ country: c.id});
              if(address && address.state){
                this.getStatesc(c.id,address);
              }
            }
      });
      }
    });
  }

 


  backArrow(){
    if(this.mode == 'details'){
      this.imageDataUrl = {imageData:null,notes:null};
      this.dialogRef.close();
    }
    else {
      this.mode = 'details';
    }

  }

  
  clearNewClient(){
    
  }
  clearData(){
    this.dialogRef.close('');
  }
onInputChange(event:any) {
    if (!this.address) {
      this.predictions = [];
      return;
    }
 
    let autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions( { input: this.address  },
      (predictions: google.maps.places.AutocompletePrediction[]| null, status: google.maps.places.PlacesServiceStatus) => {
        this.zone.run(() => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.predictions = predictions?predictions :[];
          } else this.predictions = [];
          
        });
      }
    );
  }

  getLocationIconColor(){
    if(!(Number(this.companyForm.controls.latitude.value) && Number(this.companyForm.controls.longitude.value)) ){
      this.location_icon_val = 'location_off';
      return  'red';
    }
    else {
      this.location_icon_val = 'location_on';
      return 'green';
    }  
  }
  
  onSelectFile(event:any) {
    this.file=null;
    this.file = event.target.files[0];
    this.isFileImage = !this.isFileImage;
    let uploadeddata = this.file.type.split('/')
    if (this.file && uploadeddata[0] == "image") {
      if (this.file.size <= 1024000) {
        if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.imageDataUrl = {imageData:null,notes:null};
              this.imageDataUrl.imageData =   event.target.result;
            }
            reader.readAsDataURL(event.target.files[i]);
          }
        }
      }else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position:{top:`70px`},
          disableClose: true,
          data: 'error'
        });
        this.isFileImage = false;
      }
    }else {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      //       this.router.navigate(["/Admin/CompanyLogo"]));
      // let dialogRef = this.dialog.open(ReusableDialogComponent, {
      //   position:{top:`70px`},
      //   disableClose: true,
      //   data:"Please select valid image"
      // });
    }
  }

  initCamera() {
    this.dispayImage = false;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.isVedioOpen = true;
        this.videoElement.nativeElement.srcObject = stream;
      })
      .catch((error) => {
        this.isCameraAvailable = false;
        console.error('Error accessing webcam:', error);
      });
  }
  closeCamera(){
    if(this.isCameraAvailable && this.videoElement && this.videoElement.nativeElement){
      const stream = this.videoElement.nativeElement.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());  // Stop the webcam stream
      this.videoElement.nativeElement.srcObject = null;
      this.isVedioOpen = false;
      this.dispayImage = true;
    }
  }
  capturePhoto() {
     
    
    const video = this.videoElement.nativeElement;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    canvas.width = video.videoWidth; // Set the canvas size to match the video feed
    canvas.height = video.videoHeight;
   
    context?context.drawImage(video, 0, 0, canvas.width, canvas.height):null;  // Draw the current frame from the video onto the canvas
    if(canvas)
    this.imageDataUrl = {imageData:null,notes:null};
    this.imageDataUrl.imageData = canvas.toDataURL('image/png');;   // Get the image data from the canvas as a data URL
    // Pass the captured photo data to the parent component
    if(this.imageDataUrl && this.imageDataUrl.imageData){
        
        this.dispayImage = true;   
        const stream = video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());  // Stop the webcam stream
        video.srcObject = null;
        this.isVedioOpen = false;
    }
  }

  confirmImage(){
    this.mode = 'details';
  }
  cancelImage(){
    this.imageDataUrl = {imageData:null,notes:null};
    this.mode = 'details';
  }

  getCities(stateId:any,address:any=null){
    let city_selected  = false;
    this.spinner.show();
    this.crm.getCities(stateId).subscribe((data)=>{
      this.spinner.hide();
      this.cityDetails=data.data;
      if(address && address.city){        
        this.cityDetails.forEach((l:any)=>{
          if( l.location.toString().toLowerCase().trim() === address.city.toString().toLowerCase().trim()){ 
            city_selected = true;
            this.companyForm.patchValue({ city: l.id});
          }
      });
      if(!city_selected){
        this.cityDetails.forEach((l:any)=>{
          if( l.location.toString().toLowerCase().trim() === address.city.split(' ')[0].toString().toLowerCase().trim()){ 
            city_selected = true;
            this.companyForm.patchValue({ city: l.id});
          }
      });
      }
      }
    })
  }

  getStatesc(countryId:any,address:any=null){
    this.spinner.show();
    this.crm.getStates(countryId).subscribe((data)=>{
      this.spinner.hide();
      this.stateDetails=data.data;
      if(address && address.state){
        this.stateDetails.forEach((s:any)=>{
          if( s.state.toString().toLowerCase().trim() === address.state.toString().toLowerCase().trim()){
            this.companyForm.patchValue({ state: s.id});
            if(address && address.city){
              this.getCities(s.id,address);
            }
          }
      });
      }
    })
}

showData(){
  this.companyForm.patchValue({
    client_id:this.localdata.client_id,          
    companyname: this.localdata.client_name,
    contact_person_name: this.localdata.contact_person_name,
    contact:this.localdata.contact,
    email: this.localdata.email?.toLowerCase(),
    notes:this.localdata.notes,
    targetPrice:this.localdata.target_price
  });
  this.showLocalData();
  this.editMode = true;
}

showLocalData(){
  this.companyForm.patchValue({
    address1:this.localdata.address1,
    address2:this.localdata.address2,
    country: this.localdata.country,
    state: this.localdata.state,
    city: this.localdata.city,  
    pincode:this.localdata.pincode,   
    latitude:this.localdata.lat,
    longitude:this.localdata.lng,
  });
  var latlng:any = null;
  if(this.companyForm.controls.latitude.value && this.companyForm.controls.longitude.value){
      latlng = {lat:Number(this.companyForm.controls.latitude.value),lng:Number(this.companyForm.controls.longitude.value)};
  }
  //this.previousLocationOnMap();
  // if(!this.mapInitialized){     
  //   this.mapService.initMap().then(() => {
  //     this.initAsyncMap(latlng);
  //     this.addMarker(latlng);
  //     this.mapInitialized = true;
  //   }).catch((error: any) => {
  //     console.error('Error initializing map:', error);
  //   });
  // } else  {
    this.initAsyncMap(latlng);
  //  this.addMarker(latlng);
  //}
  this.searchClientOnMap = false;
}
getClientData() {
  this.spinner.show();
  this.crm.getCrmClientDataBySelection({clientId:this.client_id ,empId: this.userSession.id}).subscribe(async (results)=>{
    this.spinner.hide();
    if(results && results.data && results.data[0]){
        this.localdata = results.data[0];
        this.getCountry(this.localdata.country,{state:this.localdata.state_name.trim(), city:this.localdata.city_name.trim()} );       
        this.showData();
        this.imageDataUrl.imageData =  this.localdata.imageData;
      }  

  })
}

async geocodeAddress(coords:any) {
  let lat =  Number(coords.lat);
  let lng =  Number(coords.lng);
  this.geocoder.geocode({ location: {lat,lng} }, (results:any, status:any) => {
    if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
        this.companyForm.controls.address1.setValue(results[0].formatted_address);
    }
  });
}

  showSpinner(){
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }
 

}




