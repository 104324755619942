import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-upgrade-plan',
  templateUrl: './client-upgrade-plan.component.html',
  styleUrls: ['./client-upgrade-plan.component.scss']
})
export class ClientUpgradePlanComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  navigationToSubscription() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/Subscription']));
  }

}
