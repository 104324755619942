<div fxLayout="column" class="first-col">
       <mat-card>
              <mat-card-header>
                     <mat-card-title> Attendance Calendar</mat-card-title>
              </mat-card-header>
              <!-- <mat-divider></mat-divider> -->

              <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="column" fxLayoutGap="10px" fxLayoutGap.xs="1px">
                     <div fxFlex="45" fxLayout="column" fxFill>
                            <div class="demo-app-main">
                                   <full-calendar #calendar fxLayout="column" [options]="calendarOptions">
                                   </full-calendar>
                            </div>
                            <div style="display: flex;padding: 1em" class="example-container-2">
                                   <mat-icon class="green-icon">check_circle</mat-icon>Present &nbsp;&nbsp;
                                   <mat-icon class="red-icon">check_circle</mat-icon> Absent &nbsp;&nbsp;
                                   <mat-icon class="blue-icon">check_circle</mat-icon><span
                                          class="no-break">Week-Off</span> &nbsp;&nbsp;
                                   <mat-icon class="yellow-icon">check_circle</mat-icon> Holiday &nbsp;&nbsp;
                                   <mat-icon class="orange-icon">check_circle</mat-icon> Leave

                            </div>
                            <div style="display: flex;padding: 1em" class="example-container-2">
                                   <mat-icon class="light-icon">check_circle</mat-icon> Half Day &nbsp;&nbsp;
                                   <mat-icon class="wp-icon">check_circle</mat-icon> Week-off Present &nbsp;&nbsp;
                                   <mat-icon class="hp-icon">check_circle</mat-icon> Holiday Present
                            </div>

                     </div>
                     <div class="sub-section-2" fxLayout="column" fxFlex="55" fxLayoutGap="10px">
                            <div fxLayout="column" fxFlex="40">
                                   <div fxLayout="row" fxLayoutAlign="space-evently"
                                          [style.marginTop.px]="mobileQuery.matches ? 0 : 15"
                                          class="pending-attendance">
                                          <div style="padding-top: 10px; color: white; height: 45px" fxLayout="row">
                                                 <!-- <p>
                                                        <mat-icon>notifications</mat-icon>
                                                 </p> -->
                                                 <p style="padding-left: 16px;"> <b>Request on Behalf of Employee</b>
                                                 </p>
                                          </div>
                                   </div>
                                   <cdk-virtual-scroll-viewport class="example-viewport" style="height: 300px"
                                          itemSize="5">
                                          <mat-list class="time-attendance">
                                                 <ng-container *cdkVirtualFor="let item of notificationsData">
                                                        <mat-list-item fxLayoutAling.xs="space-between center">
                                                               <h3 matLine>{{ item.employee_name }}</h3>
                                                               <div class="no-break" ngStyle.xs="padding-left:5px">{{
                                                                      item.absent_date | date : "dd-MM-yyyy" }}</div>
                                                               <div style="padding-left: 10px">
                                                                      <a ngStyle.gt-md="padding-left: 10px"
                                                                             routerLink="/" [tabindex]="1"
                                                                             (keyup.enter)="onBehalfofRequestClick(item)"
                                                                             (click)="onBehalfofRequestClick(item)"
                                                                             class="no-break">
                                                                             Add a request
                                                                      </a>
                                                               </div>
                                                        </mat-list-item>
                                                 </ng-container>
                                          </mat-list>
                                   </cdk-virtual-scroll-viewport>
                            </div>
                            <div fxLayout="column" fxFlex="40">
                                   <div fxLayout="row" fxLayoutAlign="space-between" class="clock-attendance">
                                          <div style="padding-top: 10px; color: white">
                                                 <p style="padding-left: 16px;">
                                                        <b>Pending Approvals</b>
                                                 </p>
                                          </div>
                                   </div>
                                   <cdk-virtual-scroll-viewport class="example-viewport" style="height: 300px"
                                          itemSize="5">
                                          <mat-list class="time-attendance example-container">
                                                 <ng-container *cdkVirtualFor="let item of arrayList">
                                                        <mat-list-item>
                                                               <h3 matLine>{{ item.raisedbyname }}</h3>
                                                               <div class="no-break" ngStyle.xs="padding-left:5px">{{
                                                                      item.fromdate | date : "dd-MM-yyyy" }}</div>
                                                               <div style="padding-left: 10px">
                                                                      <a routerLink="/" [tabindex]="2" class="no-break"
                                                                             (keyup.enter)="changeTab(item)"
                                                                             (click)="changeTab(item)">
                                                                             Approve request
                                                                      </a>
                                                               </div>
                                                        </mat-list-item>
                                                 </ng-container>
                                          </mat-list>
                                   </cdk-virtual-scroll-viewport>
                            </div>
                     </div>
              </div>
       </mat-card>
</div>