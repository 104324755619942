<div fxLayout="column" class="first-col">
    <form [formGroup]="requestform" autocomplete="off">
        <mat-card class="mat-elevation-z0">
            <mat-card-header class="headerbox">
                <mat-card-title class="title">Reassign Rotation Shift </mat-card-title>
            </mat-card-header>
            <div fxLayout="column" class="dev-Pad">
                <form [formGroup]="searchform" autocomplete="off">
                    <div fxLayout="row" fxLayoutAlign.xs="center center">
                        <mat-form-field fxFlex.xs="100" fxFlex="28" class="mx-2 my-2" appearance="outline">
                            <mat-label>Location</mat-label>
                            <mat-select required formControlName="location">
                                <input matInput [formControl]="searchControl" type="text" placeholder="Search"
                                    class="search-align">
                                <ng-container>
                                    <div *ngFor="let b of availableLocations">
                                        <mat-option [value]="b.locationid">{{ b.location }}</mat-option>
                                    </div>
                                </ng-container>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="
                    searchform.controls.location.errors?.required">
                                {{ requiredOption }}
                            </mat-error>
                        </mat-form-field>
                        <!-- <div style="padding-top: 10px;">
                    <button class="btn btn-primary mr-2 mb-2" (click)="getShiftSequenceList()">
                        <span>Search</span>
                    </button>
    
                    <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div> -->
                    </div>
                </form>

                <div *ngIf="isShow">
                    <form [formGroup]="requestform">
                        <div fxLayout="row" fxLayoutAlign.xs="center center">
                            <mat-form-field fxFlex="28" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                                <mat-label>Rotation Shift</mat-label>
                                <mat-select formControlName="shift" required>
                                    <input matInput [formControl]="searchControlforShift" type="text"
                                        placeholder="Search" class="search-align">
                                    <ng-container *ngFor="let v of filteredShiftSequenceList">
                                        <mat-option [value]="v.id"
                                            (onSelectionChange)="shiftChange(v.id)">{{v.shift_sequence_name}}</mat-option>
                                    </ng-container>
                                </mat-select>

                                <mat-error class="con-error" *ngIf="requestform.controls.shift.errors?.required">
                                    {{requiredOption}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field fxFlex="28" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                                <mat-label>Applicable Date</mat-label>
                                <input matInput readonly [min]="minFromDate" [max]="maxFromDate"
                                    [matDatepicker]="fromDate" formControlName="fromDate" (click)="fromDate.open()">
                                <mat-datepicker-toggle class="datepicker" matSuffix
                                    [for]="fromDate"></mat-datepicker-toggle>
                                <mat-datepicker #fromDate></mat-datepicker>
                                <mat-error class="con-error" *ngIf="requestform.controls.fromDate.errors?.required">
                                    {{requiredOption}}
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <mat-divider></mat-divider>
                        <div fxLayout="column">
                            <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" class="dev-Pad"  style="padding-top: 5px;height: 70px;">
                    <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="50" class="mx-2 my-2" appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf">search</mat-icon>
                        <mat-label>Search </mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder=""
                            maxlength="100" />
                    </mat-form-field>
                </div> -->
                            <div class="table-pad">
                                <div class="example-container mat-elevation-z1">
                                    <table mat-table [dataSource]="dataSource" matSort>
                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selection.hasValue()"
                                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(row) : null"
                                                    [checked]="selection.isSelected(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef> S. No. </th>
                                            <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) +
                                                (paginator.pageIndex * paginator.pageSize) }}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="empid">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                                                ID </th>
                                            <td mat-cell *matCellDef="let row"> {{row.empcode}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="empname">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp.
                                                Name </th>
                                            <td mat-cell *matCellDef="let row"> {{row.empname}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="desig">
                                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Current
                                                Rotation</th>
                                            <td mat-cell *matCellDef="let row"> {{row.rotationShift}}</td>
                                        </ng-container>

                                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found
                                            </td>
                                        </tr>
                                    </table>
                                    <mat-paginator
                                        [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div fxLayoutAlign="center" *ngIf="arrayList.length > 0 ">
                            <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
                                <span>Submit</span>
                            </button>

                            <button class="btn btn-danger mr-2 mb-2" (click)="resetform()">
                                <span>Cancel</span>
                            </button>
                            <br />
                        </div>
                    </form>
                </div>


            </div>
        </mat-card>
    </form>






</div>