import { Component, OnInit,ViewChild,Inject,LOCALE_ID } from '@angular/core';
import { AbstractControl, UntypedFormGroup,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
import { ClaimsService } from '../../claims.service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxSpinnerService } from 'ngx-spinner';
import {LeavesService} from "src/app/modules/leaves/leaves.service";
import {CrmService} from 'src/app/modules/crm/crm.service';
import { MainService } from 'src/app/services/main.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface UserData {
  applied_on:Date;
  bill_date:Date;
  rbs_type: string;
  bill_no: string;
  rbs_type_id: number;
  bill_amount:number;
  approved_amount:number;
  notes: string;
  rbs_status: string;
  id: number;
  total: number;
}

@Component({
  selector: 'app-claims-user-request',
  templateUrl: './claims-user-request.component.html',
  styleUrls: ['./claims-user-request.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ClaimsUserRequestComponent implements OnInit {
  requestForm!: UntypedFormGroup;
  userSession:any;
  pipe = new DatePipe(this.locale);
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  isDelete: boolean = true;
  enable: any = null;
  valid: boolean = false;
  rbsData:any =[];
  rbsBalances:any =[];
  requestData:any = [];
  displayedColumns: string[] = ['sno','applied_on','rbs_type','bill_date','bill_amount','approved_amount','verified_by', 'claim_status', 'Action'];
  minDate:any;
  arrayValue: any;
  effective_date:any= new Date();
  dataSource: MatTableDataSource<UserData> = <any>[];
  selected_row:any;
  pageLoading = true;
  rbs_type_id:any = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  document: boolean = true;
  pdfName :any = null;
  fileURL:any;
  base64textString:any;
  iseditDoc:boolean=true;
  ispdf:boolean=false;
  file:any;
  isFile: boolean = true;
  screen_route:any = "/Claims/UserClaimRequest";
  activeModule:any;
  documentId:any=null;
  documentInfo:any=null;
  submitted:any=false;
  isRequestView = false;

  
  constructor(@Inject(LOCALE_ID) private locale: string,private claims:ClaimsService,private Company:CompanySettingService,private formBuilder: UntypedFormBuilder, 
  private router: Router, private dialog: MatDialog,private spinner: NgxSpinnerService, private LM: LeavesService,private crm:CrmService,private main:MainService ) { 

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.activeModule = JSON.parse(sessionStorage.getItem('activeModule') || '');
  }

  ngOnInit(): void {
    this.minDate = new Date();

    this.requestForm = this.formBuilder.group({
      applied_on: [{value:this.minDate,disabled: true}],
      rbs_type_id: ["",[Validators.required]],
     // request_date: ["",[Validators.required]],
      notes: ["",[Validators.required]],
      verify_comments:[''],
      bill_date: ['',[Validators.required]],
      bill_amount:["",[Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
      approved_amount:[{value:'',disabled: true}],
      bill_no:["",[Validators.required]],
      document:['']
    }, );
    this.requestForm.get('rbs_type_id')?.valueChanges.subscribe((selectedValue:any) => {
        this.rbs_type_id = selectedValue;
        if(selectedValue)
        this.getClaimsEmployeeBalances();
    });
    this.requestForm.get('bill_date')?.valueChanges.subscribe((selectedValue:any) => {
      if(selectedValue)
      this.getClaimsEmployeeBalances();
    });
    this.getReimbursementTypes();  
    this.getClaimsRequests();
  }
  getReimbursementTypes() {
    this.spinner.show();
    this.claims.getClaimsActiveReimbursementTypes().subscribe((res:any)=>{
      this.spinner.hide();
      if(res.status) {
        this.rbsData =  res.data;
      }
      else {
        this.rbsData = [];
      }
    });
  }
  getClaimsEmployeeBalances(){
    // let params={
    //   emp_id:this.userSession.id||0,
    //   rbs_type_id:this.rbs_type_id||0,
    //   selected_date:this.requestForm.controls.bill_date.value||'',
    //   claim_status:null
    // }

    // this.main.getCommonSprypleData('get_claims_employee_balances',params).subscribe((res:any)=>{
    //   this.spinner.hide();
    //   if(res.status) {
    //     this.rbsBalances =  res.data;
    //   }
    //   else {
    //     this.rbsBalances = [];
    //   }
    // });
  }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }

    }


    getClaimsRequests() {
      this.spinner.show();
      var obj = {
        emp_id:this.userSession.id,
        manager_id:0,
        claim_status:null,
        pagenumber:1,
        pagesize:5
    }
      this.claims.getClaimsReimbursementRequests(obj).subscribe((res:any)=>{
        this.spinner.hide();
        if(res.status) {
          this.requestData =  res.data;

          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          this.requestData = [];
        }
      })
    }

    Add() {
      this.selected_row = null;
      this.enable = null;
      this.isAdd = true;
      this.isdata = false;
      this.rbs_type_id = 0;
      this.requestForm.controls.rbs_type_id.setValue('');
      this.requestForm.controls.bill_date.setValue('');
      this.requestForm.controls.notes.setValue('');
      this.requestForm.controls.bill_no.setValue('');
      this.requestForm.controls.bill_amount.setValue('');
      this.requestForm.controls.applied_on.setValue(this.minDate);
          this.requestForm.controls.approved_amount.setValue('');
        this.requestForm.controls.verify_comments.setValue('');

    }

    cancel() {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([this.screen_route]));
    }

    view(row: any) {
      this.isView = true;
        this.isAdd = true;
        this.isdata = false;
        this.showdata(row);
      }

      edit(row: any) {
        this.isView = false;
        this.isAdd = true;
        this.isdata = false;
        this.showdata(row);
        }

      showdata(row: any){
        this.selected_row = row;
        this.enable = row.claim_id;
        this.rbs_type_id = row.rbs_type_id;
        this.requestForm.controls.applied_on.setValue(row.created_on);
        this.requestForm.controls.rbs_type_id.setValue(this.rbs_type_id);
        this.requestForm.controls.bill_no.setValue(row.bill_number);
        this.requestForm.controls.bill_date.setValue(row.bill_date);
        this.requestForm.controls.notes.setValue(row.description);
        this.requestForm.controls.bill_amount.setValue(row.bill_amount);
        if(row.approved_amount)
          this.requestForm.controls.approved_amount.setValue(row.approved_amount);
        if(row.verify_comments)
        this.requestForm.controls.verify_comments.setValue(row.verify_comments);

        if(row.filename && row.claim_file_id){
          this.spinner.show();
          this.crm.getCrmImageCaptured({filepath:row.filepath,filename:row.filename}).subscribe((imageData:any) => {
            this.spinner.hide();

            if(imageData.success){
              this.document = true;
              this.pdfName = row.filename;
              this.requestForm.controls.document.setValue(row.filename);
              this.requestForm.controls.document.clearValidators();
              this.requestForm.controls.document.updateValueAndValidity();
              this.iseditDoc=false;

              let TYPED_ARRAY = new Uint8Array(imageData.image.data);
              const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {  return data + String.fromCharCode(byte);   }, '');

              this.file = new Blob([TYPED_ARRAY]);
              this.fileURL = URL.createObjectURL(this.file);
            }
          })
        }
      }

validateDataForSave(){
  let status  = true;
  status = status && !!this.requestForm.controls.bill_no.value;
  status = status && !!this.requestForm.controls.bill_amount.value;
  status = status && !!this.rbs_type_id;
  status = status && !!this.requestForm.controls.bill_date.value;
   return status;

}

savedocument(data:any){
  if( this.file && this.file.name &&  data && data.filename){
    const formDt: FormData = new FormData();

    formDt.append('info', JSON.stringify(data));
    formDt.append('file',  this.file);
    this.spinner.show();
    this.crm.setCapturedImageForTracking(formDt).subscribe((result:any) => {
      this.spinner.hide();
      if(result && result.status){
      }
    });
  }
}




AddData(claim_status:any){
  if (!this.validateDataForSave()) {
    return;
  }

  let data = {
    claim_id: this.selected_row && this.selected_row.claim_id? this.selected_row.claim_id:0,
    emp_id:this.userSession.id,
    rbs_type_id: this.rbs_type_id ||0,
    bill_no:this.requestForm.controls.bill_no.value.trim().replace(/\s+/g, ' '),
    bill_date:this.pipe.transform(this.requestForm.controls.bill_date.value, 'yyyy-MM-dd'),
    bill_amount:Number(this.requestForm.controls.bill_amount.value).toFixed(2),
    notes:this.requestForm.controls.notes.value.trim().replace(/\s+/g, ' '),
    claim_status:  claim_status,
    created_by:this.userSession.id,
    created_on:this.pipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    fileName: this.file && this.file.name?this.file.name:null
  }

    if(data.rbs_type_id){
      let resMessage = 'Data not saved successfully';
        this.claims.setClaimsEmployeeReimbursementRequest(data).subscribe((res:any)=>{
          if(res.status) {
           if(!data.claim_id &&  this.file){
            this.savedocument(res.data[0]);
           }

            resMessage = 'Data saved successfully';
            this.enable = null;
            this.selected_row = null;
            this.isdata = true;
            this.isAdd = false;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([this.screen_route]));
            this.dataSource.paginator = this.paginator;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data:res.data[0].resultmsg,
          });
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: (res && res.data && res.data[0] && res.data[0].resultmsg)? res.data[0].resultmsg: "Unable to add Reimbursement type. Please try again "
          });
        }
      })
    }
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  getFormattedDate(date:any){
    return this.claims.getFormattedDate(date);
  }

  canceledit(event: any, id: any) {
    this.isdata = true;
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.isDelete = true;
    this.ngOnInit();
  }

  fileView(){
    window.open(this.base64textString);

 }

 handleReaderLoaded(event: ProgressEvent<FileReader>) {
  let binaryString = (event.target as FileReader).result;
  this.base64textString = btoa(binaryString as string);
}

onSelectFile(event: any) {
  this.iseditDoc=true;
  if (event.target.files.length != 0) {
    if (event.target.files[0].size <= 2097152) {
      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');
      if (pdf[pdf.length - 1] == 'pdf' || pdf[pdf.length - 1] == 'jpg' || pdf[pdf.length - 1] == 'png') {
        this.isFile = true;
      } else {
        this.isFile = false;
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          // data: this.EM13
          data: "Only PNG,JPEG,JPG & PDF format allowed"
        });
        this.requestForm.controls.document.setValue('');
      }
    } else {
      this.isFile = false;
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        // data: this.EM14
        data: "Please upload less than 2MB file"
      });
     this.requestForm.controls.document.setValue('');
    }
  } else {
    let dialogRef = this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      // data: this.EM18
      data: "Invalid document"
    });
    this.requestForm.controls.document.setValue('');
  }
}

open(errormessages:any,top:any,width:any,height:any,flag:any,url:any){
  let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
    position: { top: `70px` },
    disableClose: true,
    data: { message: errormessages, YES: 'YES', NO: 'No',url:url }
  });
  dialogRef.afterClosed().subscribe(result => {});
}
editdoc(){
  this.pdfName=null;
}
delete()  {
    this.requestForm.controls.document.setValue("");
    this.file = null;
    this.base64textString = null;
    this.pdfName = null;
  }
  deleteIcon(){
    this.isEdit = false;
    this.requestForm.controls.document.setValue('');
  }

getUploadDocument(){
  this.spinner.show();

  let info = {
    'employeeId':this.userSession.id,
    'candidateId':null,
    'filecategory':'',
    'moduleId':this.activeModule.moduleid,
    'requestId':0,
    'status':'Submitted'
  }
  this.LM.getFilesMaster(info).subscribe((result:any) => {
    if (result && result.status && result.data.length >0) {
     this.documentId = result.data[0].id;
      this.documentInfo = JSON.stringify(result.data[0])
      this.pdfName = result.data[0].fname

     result.data[0].employeeId=this.userSession.id;
     let info = result.data[0]
      this.LM.getProfileImage(info).subscribe((imageData:any) => {
        this.spinner.hide();

        if(imageData.success){
          this.document = true;

          this.requestForm.controls.document.setValue('');
          this.requestForm.controls.document.clearValidators();
          this.requestForm.controls.document.updateValueAndValidity();
          this.iseditDoc=false;

          let TYPED_ARRAY = new Uint8Array(imageData.image.data);
          const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {  return data + String.fromCharCode(byte);   }, '');

          const file = new Blob([TYPED_ARRAY], { type: "application/pdf" });
          this.fileURL = URL.createObjectURL(file);
        }
      })
    }
    else{
      this.spinner.hide();

    }

  })
  }
  deleteHolidayPopup(claim_status:any) {
    let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: "Are you sure you want to delete ?", YES: 'YES', NO: 'No' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'YES') {
        this.AddData(claim_status);
      }
    });
  }
}
