<div fxLayout="column" class="first-col">
    <mat-card>
      <mat-card-header class="headerbox">
          <mat-card-title class="title"> Client Interaction Log Report</mat-card-title>
        </mat-card-header>
       <mat-card-content>
        <div fxLayout="column" >
      <div fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" [formGroup]="searchForm">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxFlex.xs="100%">
            <mat-form-field fxFlex.xs="100%" fxFlex fxFlex.lt-lg="48" class="mx-1 my-1" appearance="outline">
                <mat-label>Employee Name</mat-label>
                <mat-select formControlName="Users" required tabIndex="3">
                  <mat-option value="0"> All Employees </mat-option>
                  <ng-container *ngFor="let e of employeelist">
                    <mat-option [value]="e.id">{{e.firstname}} {{e.lastname}}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
  
          <mat-form-field fxFlex.xs="100%" fxFlex class="mx-1 my-1" appearance="outline">
            <mat-label>From Date</mat-label>
            <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
              formControlName="fromDate" (click)="fromDate.open()" tabIndex="1">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
  
          <mat-form-field fxFlex.xs="100%" fxFlex class="mx-1 my-1" appearance="outline">
            <mat-label>To Date</mat-label>
            <input matInput readonly [matDatepicker]="toDate" placeholder="To Date" 
            [min]="minToDate" [max]="maxDate" formControlName="toDate" (click)="toDate.open()" tabIndex="2">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
  
          
    
        <div fxLayout="row" fxLayoutAlign.xs="center center">
          <span ngStyle.gt-md="margin-top: 5%" ngStyle.lt-lg="margin-top: 5%" ngStyle.xs="margin-top: 0px">
   
      <button  [tabIndex]="4" class="btn btn-primary mr-2 mb-2" (click)="Searchform()">
        Search
      </button>
  
    <span class="line" style="color:#1898D5;font-size:16px"></span>
    <button  [tabIndex]="5" class="btn btn-danger mr-2 mb-2" (click)="resetform()">
      <span>Clear</span>
    </button>
  </span>
  </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end" >
      <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign.gt-md="space-between center" style="height: 50px;">
        <mat-form-field fxFlex="100" fxFlex.lt-lg="100" fxFlex.xs="100%" appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search" #input [tabIndex]="4">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
        </mat-form-field>
      </div>
      </div>
</div>
      <div fxLayout="row xs-column" fxLayoutAlign="end  center" *ngIf="List.length > 0" style="margin-right: 3%;">
        <div>
          <span class="exlprint"> | </span>
          <button mat-icon-button>
            <a title="PDF" [tabIndex]="6" (keyup.enter)="exportPDF()"> <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px"></a>
         </button>
            <span class="exlprint"> | </span>
            <button mat-icon-button>
          <a title="Excel" [tabIndex]="7" (keyup.enter)="exportAsXLSX()"> <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
          </a>
          </button>
        </div>
      </div>
    </div>

  <div  fxLayout="column" class="table-pad">
      <div class="mat-elevation-z1 example-container">
        <div id="table" #table=''>
        <table   mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef>S.No</th>
            <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
        </ng-container>
          <ng-container matColumnDef="empname">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name</th>
            <td mat-cell *matCellDef="let row;">{{row.empname}}</td>
  
          </ng-container>
          <ng-container matColumnDef="attendancedate">
            <th mat-header-cell *matHeaderCellDef >Date</th>
            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate | date:'dd-MM-yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="startpoint">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Start Point</th>
            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.startpoint| date:'dd-MM-yyyy HH:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="traveltime">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Travel Time</th>
            <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.traveltime | date:'dd-MM-yyyy HH:mm:ss'}} </td>
  
          </ng-container>
          <ng-container matColumnDef="visittime">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Visit Time </th>
            <td mat-cell *matCellDef="let row"> {{row.visittime}} </td>
  
          </ng-container>
          <ng-container matColumnDef="kmstravelled">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">KMs Travelled</th>
            <td mat-cell *matCellDef="let row"> {{row.kmstravelled}} </td>
  
          </ng-container>
          <ng-container matColumnDef="endpoint">
           
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">End Point</th>
            <td mat-cell *matCellDef="let row"> {{row.endpoint}} </td> 
          </ng-container>
          <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
          </tr>
  
        </table>
         </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  </mat-card>
  </div>
  