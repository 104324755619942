<div fxLayout="column" class="first-col">
    <mat-card>
    <div class="section"><b>Induction Program</b></div>
    <mat-divider></mat-divider>
      
      <div fxLayout="column" >            
          <div fxLayout="column" class="tableP">
          <div class="mat-elevation-z1 example-container">
              <table mat-table [dataSource]="dataSource" matSort>
                  <ng-container matColumnDef="sno">
                      <th mat-header-cell *matHeaderCellDef > S.No </th>
                      <td mat-cell *matCellDef="let row;let i=index" >
                          <span>{{(i+1)}}</span> 
                         </td>
                  </ng-container>
                  <ng-container matColumnDef="programtype">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Program Type </th>
                      <td mat-cell *matCellDef="let row" >
                          <span>{{row.description}} </span>
                              </td>
                  </ng-container>
                
                  <ng-container matColumnDef="department">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header > Department </th>
                      <td mat-cell *matCellDef="let row" >
                          <span>{{row.departmentname}} </span>
  
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="conductby">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Conduct By </th>
                      <td mat-cell *matCellDef="let row" >
                          <span>{{row.conductedby}} </span>
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef> Date </th>
                      <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                          <span>{{row.schedule_date | date:'dd-MM-yyyy'}} </span>
                        </td>
                  </ng-container>

                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef> Time </th>
                    <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                        <span>{{row.schedule_starttime.substring(0,5)}} </span>
                      </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
                    <td mat-cell *matCellDef="let row" >
                        <span>{{row.status}} </span>
                      </td>
                </ng-container>
                 <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="7" style="text-align: center;">No data found</td>
                      </tr>
              </table>
  
          </div>
      </div>
      </div>
    
    <div class="section"><b>Onboarding Checklist</b></div>
  <mat-divider></mat-divider>
    
    <div fxLayout="column" >            
        <div fxLayout="column" class="tableP">
        <div class="mat-elevation-z1 example-container ">
            <table mat-table [dataSource]="dataSource2" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef > S.No </th>
                    <td mat-cell *matCellDef="let row;let i=index" >
                        <span>{{(i+1)}}</span> 
                        

                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef  > Name </th>
                    <td mat-cell *matCellDef="let row" >
                        <span>{{row.empname}} </span>

                    </td>
                </ng-container>
              
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                    <td mat-cell *matCellDef="let row" >
                        <span>{{row.department_name}} </span>

                    </td>
                </ng-container>
                <ng-container matColumnDef="checklist">
                    <th mat-header-cell *matHeaderCellDef > Checklist </th>
                    <td mat-cell *matCellDef="let row" >
                        <span>{{row.checklist_name}} </span>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
                    <td mat-cell *matCellDef="let row" >
                        <span>{{row.status}} </span>
                    </td>
                </ng-container>

               <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5" style="text-align: center;">No data found</td>
                    </tr>
            </table>

        </div>
    </div>
    </div>
</mat-card> 
</div>
