<div fxLayout="column">
  <div fxLayoutAlign="end">
    <span>
      <button mat-icon-button>
        <mat-icon class="close-icon" color="warn" title="Close" [mat-dialog-close]="data.NO">cancel</mat-icon>
      </button>
    </span>
  </div>

  <form [formGroup]="halfdayForm">
    <p style="color: red;">* &nbsp;<span>{{halfDayType}}</span>&nbsp; record already exist</p>

    <div>
      <p>* &nbsp;<span>Please select remaining half day regularization type</span></p>
      <div>
        <mat-form-field fxFlex="80" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
          <mat-label>Regularization Type</mat-label>
          <mat-select formControlName="regType" required>
            <!-- <mat-option>--select--</mat-option> -->
            <ng-container *ngFor="let e of regularType">
              <mat-option [value]="e.id">{{e.name}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="halfdayForm.controls['regType'].invalid">
            Please select option
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div fxLayoutAlign="center">
      <button class="btn btn-primary mr-2 mb-2" (click)="submit()">Submit</button>
    </div>
  </form>
</div>