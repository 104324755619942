<p>“Subscription Term” shall mean that period specified in a Schedule during which Customer will have on-line access and use of the Software through Spryple’s SaaS Services. The Subscription Term shall renew for successive 1 month/12- month periods unless either party delivers written notice of non-renewal to the other party at least 30 days prior to the expiration of the then-current Subscription Term. 

 

    SAAS SERVICES 
    
     
    
    During the Subscription Term, Customer will receive a nonexclusive, non-assignable, royalty free, worldwide right to access and use the SaaS Services solely for your internal business operations subject to the terms of this Agreement and up to the number of User Accounts documented in the Schedule. 
    
     
    
    Customer acknowledges that this Agreement is a services agreement and Spryple will not be delivering copies of the Software to Customer as part of the SaaS Services. 
    
     
    
     
    
    RESTRICTIONS 
    
     
    
    Customer shall not, and shall not permit anyone to: (i) copy or republish the SaaS Services or Software, (ii) make the SaaS Services available to any person other than authorized Identity Cube users, (iii) use or access the SaaS Services to provide service bureau, time-sharing or other computer hosting services to third parties, (iv) modify or create derivative works based upon the SaaS Services or Documentation, (v) remove, modify or obscure any copyright, trademark or other proprietary notices contained in the software used to provide the SaaS Services or in the Documentation, (vi) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Software used to provide the SaaS Services, except and only to the extent such activity is expressly permitted by applicable law, or (vii) access the SaaS Services or use the Documentation in order to build a similar product or competitive product. Subject to the limited licenses granted herein, Spryple shall own all right, title and interest in and to the Software, services, Documentation, and other deliverables provided under this SaaS Agreement, including all modifications, improvements, upgrades, derivative works and feedback related thereto and intellectual property rights therein. Customer agrees to assign all right, title and interest it may have in the foregoing to Spryple. 
    
     
    
    CUSTOMER RESPONSIBILITIES 
    
     
    
    Assistance. Customer shall provide commercially reasonable information and assistance to Spryple to enable Spryple to deliver the SaaS Services. Upon request from Spryple, Customer shall promptly deliver Customer Content to Spryple in an electronic file format specified and accessible by Spryple. Customer acknowledges that Spryple’s ability to deliver the SaaS Services in the manner provided in this SaaS Agreement may depend upon the accuracy and timeliness of such information and assistance. 
    
     
    
    Compliance with Laws. Customer shall comply with all applicable local, state, national and foreign laws in connection with its use of the SaaS Services, including those laws related to data privacy, international communications, and the transmission of technical or personal data. Customer acknowledges that Spryple exercises no control over the content of the information transmitted by Customer or the Identity Cube users through the SaaS Services. Customer shall not upload, post, reproduce or distribute any information, software or other material protected by copyright, privacy rights, or any other intellectual property right without first obtaining the permission of the owner of such rights. 
    
     
    
    Unauthorized Use; False Information. Customer shall: (a) notify Spryple immediately of any unauthorized use of any password or user id or any other known or suspected breach of security, (b) report to Spryple immediately and use reasonable efforts to stop any unauthorized use of the SaaS Services that is known or suspected by Customer or any Identity Cube user, and (c) not provide false identity information to gain access to or use the SaaS Services. 
    
     
    
    Administrator Access. Customer shall be solely responsible for the acts and omissions of its Administrator Users. Spryple shall not be liable for any loss of data or functionality caused directly or indirectly by the Administrator Users. 
    
     </p>
