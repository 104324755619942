<div fxLayout="column" fxFill class="custom-investment-of custom-pgr-height-auto first-col">
    <mat-card>
        <!-- <mat-card-header class="headerbox">
            <div fxLayout="row" fxFlex="50">
                <mat-card-title class="title"> Payroll Dashboard</mat-card-title>
            </div>
        </mat-card-header> -->

        <form [formGroup]="Empdashboardform" autocomplete="off">
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="2px"
                style="padding-top: 1%;">
                <div fxFlex="65" fxFlex.lt-lg="100" fxFill>
                    <div class="dashboard-1 custom-investment-th">Gross Salary Breakdown by Departments</div>

                    <div fxLayout="row" fxLayout.xs="column">

                        <div class="card card-stats custom-epf-mini-tab">
                            <div class="card-body ">
                                <div class="col-12">
                                    <div class="numbers text-center">
                                        <mat-form-field appearance="outline" fxFlex fxFlex.xs="100">
                                            <mat-label>EPF</mat-label>
                                            <mat-select formControlName="EPF" [tabIndex]="1">
                                                <ng-container>
                                                    <div *ngFor="let fy of EPFfulldetails">
                                                        <mat-option
                                                            [value]="fy.month_name">{{fy.month_name}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>


                                    </div>
                                    <div class="col-4" style="float: left;">
                                        <p style="text-align: center;color: #5a5c69!important;font-weight: 700;"
                                            class="no-break">Month</p>
                                        <p style="text-align: center;color: #5a5c69!important;">{{month}}</p>
                                    </div>
                                    <div class="col-8" style="float: left;">
                                        <p style="text-align: center;color: #5a5c69!important;font-weight: 700;">
                                            Deduction Amount</p>
                                        <p style="text-align: center;color: #5a5c69!important;">{{deduction}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-stats custom-esi-mini-tab">
                            <div class="card-body ">
                                <div class="col-12">
                                    <div class="numbers text-center">
                                        <mat-form-field appearance="outline" fxFlex fxFlex.xs="100">
                                            <mat-label>ESI</mat-label>
                                            <mat-select formControlName="ESI" [tabIndex]="2">
                                                <ng-container>
                                                    <div *ngFor="let fy of EPFfulldetails">
                                                        <mat-option
                                                            [value]="fy.month_name">{{fy.month_name}}</mat-option>
                                                    </div>
                                                </ng-container>

                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4" style="float: left;">
                                        <p style="text-align: center;color: #5a5c69!important;font-weight: 700;">Month
                                        </p>
                                        <p style="text-align: center;color: #5a5c69!important;">{{monthesi}}</p>
                                    </div>
                                    <div class="col-8" style="float: left;">
                                        <p style="text-align: center;color: #5a5c69!important;font-weight: 700;">
                                            Deduction Amount</p>
                                        <p style="text-align: center;color: #5a5c69!important;">{{deductionesi}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div fxFlex="35" fxFlex.lt-lg="100" fxFill>
                    <div class="custom-mob-paySlips">

                        <div class="card card-stats">
                            <div class="card-body ">
                                <div>
                                    <span style="font-size: 16px;">Pay Slips
                                    </span>
                                    <mat-divider></mat-divider>
                                    <div style="padding-top: 1%;">
                                        <br>
                                        <mat-form-field appearance="outline" fxFlex.lt-lg="50" fxFlex.xs="100">
                                            <mat-label>Pay Slips</mat-label>
                                            <mat-select formControlName="financial_year" [tabIndex]="3">
                                                <ng-container>
                                                    <div *ngFor="let fy of financeyears">
                                                        <mat-option
                                                            [value]="fy.return_value">{{fy.financial_year}}</mat-option>
                                                    </div>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-12" *ngFor="let array of dataSource;index as i;">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex.lt-lg="50">
                                        <span>{{array.salary_month}}</span>
                                        <span>
                                            <button mat-icon-button [tabIndex]="[4]" (keyup.enter)="payslipview(array)">
                                                <mat-icon style="cursor: pointer;" title="View"
                                                    (click)="payslipview(array)">visibility</mat-icon>
                                            </button>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </form>

        <!--  -->

    </mat-card>
    <br><br>
</div>