import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import htmlToPdfmake from 'html-to-pdfmake';
import { NgxSpinnerService } from 'ngx-spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import { ExcelServiceService } from 'src/app/modules/reports/excel-service.service';
import { DialogDetailComponent } from 'src/app/modules/reports/pages/dialog-detail/dialog-detail.component';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import * as XLSX from 'xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-attendance-detailed-report',
  templateUrl: './attendance-detailed-report.component.html',
  styleUrls: ['./attendance-detailed-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AttendanceDetailedReportComponent implements OnInit {
  List: any[] = [];
  employeelist: any;
  Users: any;
  minDate = new Date('2020/01/01');
  maxDate = new Date();
  userSession: any;
  date = new Date();
  firstDay: any;
  lastDay: any;
  startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  dateDayArray: any = [];
  obj: any;
  monthdata: any;
  year: any;
  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  headersList: any = [];
  @ViewChild('table') table!: ElementRef;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  filter = new FormControl();
  searchForm = this.formBuilder.group({
    fromDate: [new Date()],
    toDate: [new Date()],
    Users: ['0'],
  });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = [
    'sno',
    'empname',
    'attendancedate',
    'firstlogintime',
    'lastlogouttime',
    'totalhours',
    'breaks',
    'breaktime',
    'productivehours',
    'action',
  ];
  isLoading = false;
  searchControl = new FormControl('');
  filteredEmployeeList: any = [];
  constructor(
    public reportsService: ReportsService,
    public datePipe: DatePipe,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    private excelService: ExcelServiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.Searchform();
    this.getEmployeelist();
    this.getDateArray(this.startDate, this.endDate);
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmployees(searchText);
    });
  }
  getDateArray(start: any, end: any) {
    const arr = [];
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    for (const val of arr) {
      this.obj = {
        date: val,
        day: val.toLocaleDateString('en-US', { weekday: 'short' }),
      };

      this.dateDayArray.push(this.obj);
    }
  }
  getEmployeelist() {
    let obj = {
      remployee_id: this.userSession.id,
    };
    this.reportsService
      .getReportsuserEmployeesList(obj)
      .subscribe((res: any) => {
        if (res.status) {
          this.employeelist = [];
          this.employeelist = res.data;
          this.filteredEmployeeList = this.employeelist;
          this.searchForm.controls.Users.setValue('0');
        }
      });
  }
  //All Employees API
  Searchform() {
    this.List = [];
    this.dataSource = new MatTableDataSource(this.List);
    let fromDate = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'y-MM-dd'
    );
    let userId = this.searchForm.controls.Users.value;
    let data = {};
    if (userId == '0') {
      userId = null;
      data = {
        remployee_id: this.userSession.id,
        employee_id: userId,
        calendar_date: fromDate,
      };
    } else {
      data = {
        remployee_id: null,
        employee_id: userId,
        calendar_date: fromDate,
      };
    }
    this.spinner.show();
    this.isLoading = true;
    this.reportsService.getAttendanceMonthlyReportForReportsuser(data).subscribe((res: any) => {
      this.headersList = [];
      this.List = [];
      if (res.status) {
        let i = 0;
        res.data.forEach((e: any) => {
          if (i < 2) {
            let header = JSON.parse(e.result);
            this.headersList.push(header);
          } else {
            let header = JSON.parse(e.result);
            this.List.push(header);
          }
          i++;
        });
      }

      this.isLoading = false;
      this.dataSource = new MatTableDataSource(this.List);
      this.spinner.hide();
    },
      (error) => {
        this.isLoading = false;
        error.error.text;
        this.spinner.hide();
      }
    );
  }

  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(new Date());
    this.searchForm.controls.toDate.setValue(new Date());
    this.searchForm.controls.Users.setValue('0');
    this.Searchform();
  }
  openDialog(item: any): void {
    const dialogRef = this.dialog.open(DialogDetailComponent, {
      //  width: '500px',position:{top:`70px`},
      data: { attendanceid: item.attendanceid },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  exportAsXLSX() {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Monthly_Detail_Report');

    XLSX.writeFile(wb, date + '-' + 'Monthly_Detail_Report_for_manager.xlsx');
  }

  getColor(i: string): String {
    let color = '';
    if (i == 'P') {
      return (color = 'green');
    } else if (i == 'H') {
      return (color = '#800000');
    } else if (i == 'W') {
      return (color = 'blue');
    } else if (i == 'L') {
      return (color = 'orange');

    } else if (i == "HD") {
      return color = '#ce06e4';

    } else if (i == "WP") {
      return color = '#06c3e4';

    } else if (i == "HP") {
      return color = '#06e471';

    } else {
      return (color = 'red');
    }
  }
  public exportPDF(): void {
    let date = this.datePipe.transform(
      this.searchForm.controls.fromDate.value,
      'MM-YYYY'
    );
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake
      .createPdf({
        info: {
          title: date + '-' + 'Attendance Monthly Detailed Report',
          author: 'Sreeb tech',
          subject: 'Theme',
          keywords: 'Report',
        },
        footer: function (currentPage, pageCount) {
          return {
            margin: 10,
            columns: [
              {
                fontSize: 9,
                text: [
                  {
                    text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  },
                ],
                alignment: 'center',
              },
            ],
          };
        },
        content: [
          {
            text: date + ' - ' + 'Attendance Monthly Detailed Report \n\n',
            style: 'header',
            alignment: 'center',
            fontSize: 14,
          },
          // {
          //   text:
          //     "Designation :  " + this.designationForPdf +"\n" +
          //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
          //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
          //   fontSize: 10,
          //   margin: [0, 0, 0, 20],
          //   alignment: 'left'
          // },
          html,
        ],
        styles: {
          'html-table': {
            background: 'white', // it will add a yellow background to all <STRONG> elements
          },
        },
        pageOrientation: 'landscape',
        pageSize: 'A3',
        //'portrait'
      })
      .download('Attendance Monthly Detailed Report.pdf');
  }
  filterEmployees(searchText: any) {
    this.filteredEmployeeList = this.employeelist.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase())
    );
    if (this.filteredEmployeeList.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}
