import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CrmService } from '../../crm.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';

export interface UserData {
  demostatus: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-crm-client-status-master',
  templateUrl: './crm-client-status-master.component.html',
  styleUrls: ['./crm-client-status-master.component.scss']
})
export class CrmClientStatusMasterComponent implements OnInit {

  statusForm!: UntypedFormGroup;
  clientStatus: any;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  isDelete: boolean = true;
  enable: any = null;
  valid: boolean = false;
  displayedColumns: string[] = ['sno','clientStatus', 'status', 'Action'];
  arrayValue: any;
  dataSource: MatTableDataSource<UserData> = <any>[];
  pageLoading = true;
  statusData:any =[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  
  constructor(private crmService:CrmService,private Company:CompanySettingService,private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getstatuslist();
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getclientStatusVal();
    this.statusForm = this.formBuilder.group( {
        statusdata: ["",[Validators.required]],
      }, );
  }
  getstatuslist(){
    this.Company.getstatuslists().subscribe((result:any) => {     
      if(result.status){
        this.arrayValue = result.data;
      }
    })
  }

  status(status:any,id:any,display_name:any){
   // status(halfs.id,row.id,row.display_name)
      let data={
        client_status_id:id,
        client_status_name:display_name,
        is_active:status,
        empid:this.userSession.id
    }
    this.setclientStatusVal(data);
  }
  getclientStatusVal() {
    this.crmService.getCrmClientStatusValuesMaster(1,100).subscribe((res:any)=>{
      if(res.status) {
        this.statusData =  res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  setclientStatusVal(data:any) {
    data.client_status_name = data.client_status_name.trim();
    data.client_status_name  = data.client_status_name .replace(/\s+/g, ' ');

    // var filterdata = this.statusData.filter((cs:any)=>
    //     cs.display_name.toString().toLowerCase().includes(data.client_status_name.trim().toLowerCase()));   
    // if(filterdata && filterdata.length){
    //   let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //     position: { top: `70px` }, disableClose: true,   data: "Status already in the list."  });
    //   return;
    // }
    
    if(data.client_status_name && data.client_status_name.toString().length){
        this.crmService.setCrmClientStatusValues(data).subscribe((res:any)=>{
          if(res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Crm/CrmMastersForStatus"]));
            this.dataSource.paginator = this.paginator;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` }, disableClose: true,  data:res.data[0].resultmsg, });
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` }, disableClose: true, data: "Unable to add status. Please try again. "   });
        }
      })
    }
  }

  // cancel() {
  //   this.statusForm.reset();
  // }
  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Crm/CrmMastersForStatus"]));
    // this.enable = null;
    // this.departmentForm.reset();
    // this.isAdd = false;
    // this.isdata = true;
    // this.getDepartments();

  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    this.statusForm.controls.statusdata.setValue('')
  }
  

edit(w: any, i: any) {
    this.enable = i.id;
    this.isEdit = false;
    this.isDelete = false;
    this.isSave = true;
    this.statusForm.controls.statusdata.setValue(i.display_name);
  }

  save(event: any,i: any, status: any) {
        if (this.statusForm.valid) {
          this.enable = null;
          this.isEdit = true;
          this.isSave = false;
          this.isDelete = true;
            let data={
              client_status_id:i.id,
              client_status_name:status,
              is_active:true,
              empid:this.userSession.id
            }
            this.setclientStatusVal(data);
    }
  }

 AddData(){
  let data = {
    client_status_id:0,
    client_status_name:this.statusForm.controls.statusdata.value,
    is_active:true,
    empid:this.userSession.id
  }
  this.setclientStatusVal(data);
  
 }           
  canceledit(event: any, id: any) {
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.isDelete = true;
    this.ngOnInit();
  }
  
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
     
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  
  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    
  }

    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
  
    }
    delete(event:any,statusId:any) {
      let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: { message: "Are you sure you want to delete ?", YES: 'YES', NO: 'No' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'YES') {
          this.deleteStatus(event,statusId)
        }
      });
    }

    deleteStatus(event:any,statusId:any){
       
      this.crmService.deleteCrmClientStatusValues({statusId:statusId,empid:this.userSession.id}).subscribe((data:any)=>{
        if(data && data.status){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: data.data[0].resultmsg
          });
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Crm/CrmMastersForStatus"]));
        }else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: 'Status not deleted.'
          });
        }
      })
    }
  disableStatus(row:any){
      if(row && row.is_default)
        return true;
      else if(this.enable == row.id)
        return true;
      else return false;
    }
}


