<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="assetCategoryForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>Asset Category </mat-card-title>
            </mat-card-header>
            <!-- <mat-divider></mat-divider> -->
       
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" fxLayoutAlign="space-between">
               <span>
                <span>
                    <mat-form-field  fxFlex.xs="100%" class="mx-2 my-2" appearance="outline" style="width: 300px;">
                        <mat-label>Asset Category</mat-label>
                        <input type="text" formControlName="assetCategory"  matInput placeholder="Name" autocompleteOff>
                        <mat-error class="reg-error" *ngIf="assetCategoryForm.controls.assetCategory.hasError('required')">
                            Please enter asset category
                         </mat-error> 
                      </mat-form-field>
                </span>
                <span fxLayoutAlign.xs="center">
                    <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                (click)="saveRequest()" type="submit">
                <span>Submit</span>
                 </button>
                 <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                type="submit">
                <span>Update</span>
                 </button>
                 <button class="m-1 mat-stroked-button button-clear" (click)="resetform()">
                <span>Cancel</span>
                 </button>
               </span>
                 </span>
           
            <span style="padding-top: 15px; padding-right: 10px;">
                <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
              </span>
          </div>
           </mat-card>
    </form>

    <!-- <mat-divider></mat-divider> -->

    <div fxLayout="column" class="tableP">
    <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="type" style="width: 20%;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Category </th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>