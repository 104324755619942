import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CrmService } from '../../crm.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';

export interface UserData {
  demostatus: string;
  status: string;
  depthead: string;
  headcount: number;
  id: number;
  total: number;
}

@Component({
  selector: 'app-crm-demo-status',
  templateUrl: './crm-demo-status.component.html',
  styleUrls: ['./crm-demo-status.component.scss']
})
export class CrmDemoStatusComponent implements OnInit {

  demostatusForm!: UntypedFormGroup;
  demostatus: any;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  isDelete: boolean = true;
  enable: any = null;
  valid: boolean = false;
  activitystatus:any=null;
  statusData:any =[];
  todayDate:any=new Date();
  displayedColumns: string[] = ['sno','demoStatus', 'status', 'Action'];

  arrayValue: any;

  dataSource: MatTableDataSource<UserData> = <any>[];
  
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  companyDBName:any = environment.dbName;
    messagesDataList: any = [];
  
  LM: any;
  demoData: UserData[] | undefined;
 
  demoStatusVal: any=[];
  clientStatusVal!: never[];
  
  constructor(private crmService:CrmService,private Company:CompanySettingService,private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getstatuslist();
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getclientStatusVal();
    this.demostatusForm = this.formBuilder.group( {
        statusdata: ["",[Validators.required ]],
      }, );
  }
  getstatuslist(){
    this.Company.getstatuslists().subscribe((result:any) => {     
      if(result.status){
        this.arrayValue = result.data;
      }
    })
  }

  status(status:any,id:any,display_name:any){
   // status(halfs.id,row.id,row.display_name)
      let data={
        activity_status_id:id,
        activity_status_name:display_name,
        is_active:status,
        empid:this.userSession.id
    }
    this.setclientStatusVal(data);
  }
  getclientStatusVal() {
    this.crmService.getCrmActivityStatusValuesMaster(1, 100).subscribe((res:any)=>{
      if(res.status) {
        this.statusData =  res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else {
        this.clientStatusVal = [];
      }
    })
  }

  setclientStatusVal(data:any) {
      data.activity_status_name = data.activity_status_name.trim();
      data.activity_status_name  = data.activity_status_name .replace(/\s+/g, ' ');
       
      // var filterdata = this.statusData.filter((cs:any)=>
      //    cs.display_name.toString().toLowerCase().includes(data.activity_status_name.trim().toLowerCase()));   
      // if(filterdata && filterdata.length){
      //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
      //     position: { top: `70px` }, disableClose: true,   data: "Status already in the list."  });
      //     return;
      //   }
      if(data.activity_status_name && data.activity_status_name.toString().length){
        this.crmService.setCrmActivityStatusValues(data).subscribe((res:any)=>{
          if(res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Crm/CrmDemoStatus"]));
            this.dataSource.paginator = this.paginator;
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data:res.data[0].resultmsg,
          });
        }
        else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: "Unable to add demostatus. Please try again. "
          });
        }
      })
    }
  }

  Add() {
    this.isAdd = true;
    this.isdata = false;
    this.demostatusForm.controls.statusdata.setValue('')
  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Crm/CrmDemoStatus"]));
    // this.enable = null;
    // this.departmentForm.reset();
    // this.isAdd = false;
    // this.isdata = true;
    // this.getDepartments();

  }
  

edit(w: any, i: any) {
    
    this.enable = i.id;
    this.isEdit = false;
    this.isDelete = false;
    
    this.isSave = true;
    this.demostatusForm.controls.statusdata.setValue(i.display_name);
    // VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);

    // let params={
    //   activity_status_id:i.id,
    //   activity_status_name:this.demostatusForm.controls.statusdata.value,
    //   is_active:true,
    //   empid:this.userSession.id
    // }
    // this.setclientStatusVal(params);
  }

  save(event: any,i: any, demostatus: any) {
     
    if (this.demostatusForm.valid) {
      // this.validatedepartments(deptname)
      // this.validateupdatedepartments(id,deptname)
      this.enable = null;
      this.isEdit = true;
      this.isSave = false;
      this.isDelete = true;
     // if (this.valid) {
        let data={
          activity_status_id:i.id,
          activity_status_name:demostatus,
          is_active:true,
          empid:this.userSession.id
        }
        this.setclientStatusVal(data);
    //  }
 }
}

 AddData(){
  let data = {
    activity_status_id:0,
    activity_status_name:this.demostatusForm.controls.statusdata.value,
    is_active:true,
    empid:this.userSession.id
  }
  this.setclientStatusVal(data);
  // this.crmService.setCrmActivityStatusValues(data).subscribe((res:any)=>{
  //   if(res.status) {
  //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
  //     this.router.navigate(["/Crm/CrmDemoStatus"]));
  //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
  //     position: { top: `70px` },
  //     disableClose: true,
  //     data:res.data[0].resultmsg
  //   });
  //   }
  //   else {
      
  //   }
  // })
  
 }           
  canceledit(event: any, id: any) {
   
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.isDelete = true;
    this.ngOnInit();
  }





  
  
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
     
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }
  
  getMessages() {
    let data =
    {
      "code": null,
      "pagenumber": 1,
      "pagesize": 1000
    }
    
  }

    /**Search functionality */
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
  
    }
    delete(event:any,demoStatusId:any) {
      let dialogRef = this.dialog.open(ComfirmationDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: { message: "Are you sure you want to delete ?", YES: 'YES', NO: 'No' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'YES') {
          this.deleteDemoStatus(event,demoStatusId)
        }
      });
    }

    deleteDemoStatus(event:any,demoStatusId:any){
       
      this.crmService.deleteCrmActivityStatusValue({demoStatusId:demoStatusId,empid:this.userSession.id}).subscribe((data:any)=>{
        if(data && data.status){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: data.data[0].resultmsg
          });
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(["/Crm/CrmDemoStatus"]));
        }else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: 'Demo status not deleted.'
          });
        }
      })
    }
    disableStatus(row:any){
      if(row && row.is_default)
        return true;
      else if(this.enable == row.id)
        return true;
      else return false;
    }
}


