<div>
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Add Course</mat-card-title>
            <div fxLayout="row">
                <button mat-icon-button matTooltip="Add New Course">
                    <mat-icon style="font-size: 30px;overflow: visible;" *ngIf="addNewCourse"
                        (click)="addNew()">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <div style="margin:10px" *ngIf="!addNewCourse">
            <form [formGroup]="addCourse">
                <div>
                    <mat-form-field fxFlex="30" fxFlex.xs="100" appearance="outline">
                        <mat-label>Course</mat-label>
                        <input matInput formControlName="course" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" required></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>Pre-requisites</mat-label>
                        <textarea matInput formControlName="preRequisites" required></textarea>
                    </mat-form-field>
                </div>

                <div fxLayoutAlign="center" style="margin-top: 30px;">
                    <button class="btn btn-primary mr-2 mb-2" type="submit" (click)=" onSubmit()">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>
            </form>
        </div>
        <div style="margin:10px" *ngIf="addNewCourse">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef>S No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.sno}}</td>
                </ng-container>
                <ng-container matColumnDef="course">
                    <th mat-header-cell *matHeaderCellDef>Course</th>
                    <td mat-cell *matCellDef="let element"> {{element.course}}</td>
                </ng-container>
                <ng-container matColumnDef="requisites">
                    <th mat-header-cell *matHeaderCellDef>Pre-requisites</th>
                    <td mat-cell *matCellDef="let element"> {{element.requisites}}</td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-radio-group>
                            <mat-radio-button value="1">Active</mat-radio-button>
                            <mat-radio-button style="margin-left: 15px;" value="2">Inactive</mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button>
                            <mat-icon class="mr-2" title="Edit">
                                edit</mat-icon>
                        </button>&nbsp;
                        <button mat-icon-button>
                            <mat-icon class="mr-2" title="View"> visibility</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
</div>