<div fxLayout="column" *ngIf="addrenewalscreen">
    <mat-card>
        <mat-card class="cardP outline">
            <div class="section" fxLayout="row">
                <div>
                    <button mat-icon-button (click)="back()" matTooltip="Back">
                        <mat-icon style="font-size: 30px">arrow_back</mat-icon>
                    </button>
                </div>
                <div style="padding-top: 7px">
                    <b>Add Users</b>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayout.xs="column">
                <span style="padding-top: 30px">
                    <form [formGroup]="addausersForm">
                        <p>
                            Existing Users
                            <span style="padding-left: 26px">
                                <input style="width: 167px" type="number" [readonly]="true"
                                    formControlName="addexistingusers" /></span>
                        </p>
                    </form>
                    Add Users
                    <span style="padding-left: 32px">
                        <button class="plusminus" (click)="handleMinus()" [disabled]="disable">
                            -</button>&nbsp;
                        <input type="number" class="num" value="0" [(ngModel)]="addvalue"
                            (keypress)="onlyNumbers($event)" />&nbsp;
                        <button class="plusminus" (click)="handlePlus()">+</button>&nbsp;&nbsp;
                        <button class="btn-primary" (click)="addUsersDisplayInfo()" *ngIf="!addUsersDisplayInfohide">
                            Add
                        </button>
                    </span>
                </span>
            </div>
            <br />
            <div *ngIf="addUsersDisplayInfohide">
                <p>{{ addUsersDisplayInfoUserCount }}</p>
                <p>{{ addUsersDisplayInfovaliddate }}</p>
                <p>{{ addUsersDisplayInfoamount }}</p>
            </div>
            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2 custom" (click)="paynow(true)">
                    Pay Now
                </button>
                <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    Cancel
                </button>
            </div>
        </mat-card>
        <br />
        <mat-card class="cardP outline">
            <div class="section"><b>Renew Users</b></div>
            <div style="font-weight: bold">
                <span>Current Plan Validity:</span>&nbsp;<span>{{
                    currentplanValidityDate | date : "dd-MM-yyyy"
                    }}</span>
            </div>
            <!-- <div *ngIf="renewal" style="font-weight: bold">
                <span>Renewed Plan Validity:</span>&nbsp;<span>{{ expiryDate }}</span>
            </div> -->

            <div fxLayout="row wrap" fxLayout.xs="column">
                <span style="padding-top: 30px">
                    <form [formGroup]="renewalausersForm">
                        <p>
                            Existing Users
                            <span style="padding-left: 27px">
                                <input style="width: 167px" type="number" [readonly]="true"
                                    formControlName="renewalexistingusers" />
                            </span>
                        </p>
                        &nbsp;
                    </form>
                    Renew Users
                    <span style="padding-left: 17px">
                        <button class="plusminus" (click)="handleMinusrenew()" [disabled]="disablerenew">
                            -</button>&nbsp;
                        <input type="number" class="num" value="0" [readonly]="isrenewal"
                            [(ngModel)]="renewalvalue" />&nbsp;
                        <button class="plusminus" (click)="handlePlusrenew()" [disabled]="isrenewal">
                            +</button>&nbsp;&nbsp;
                    </span>
                </span>
            </div>
            <br />

            <div>
                <form [formGroup]="renewalausersForm">
                    <mat-radio-group class="example-radio-group" formControlName="renewtype"
                        *ngIf="adddisplayrenewalhide" [disabled]="isrenewal">
                        <mat-radio-button class="mr-2 custom-er-radio" color="primary"
                            *ngFor="let halfs of array; index as i" [value]="halfs.id">{{ halfs.value }}
                        </mat-radio-button>
                    </mat-radio-group>
                </form>
                <p *ngIf="isrenewal" style="color: red; text-align: center">
                    Users can be renewed 1 month prior to the validity date. i.e. on
                    <b>{{ resumevaliditydate }}</b>
                </p>
            </div>
            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2 custom" (click)="paynow(false)" *ngIf="!isrenewal">
                    Pay Now
                </button>
                <button class="btn btn-primary mr-2 mb-2 custom" (click)="paynow(false)" [disabled]="true"
                    *ngIf="isrenewal"
                    title="This feature can only be enabled within 24 hours prior to the payment date.">
                    Pay Now
                </button>
                <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    Cancel
                </button>
            </div>
        </mat-card>
    </mat-card>
</div>

<div fxLayout="column" *ngIf="resumesubscription">
    <mat-card>
        <mat-card class="cardP outline">
            <div class="div-Pad">
                Welcome back to Spryple!<br /><br />

                We're excited to see you again after some time away. It looks like you
                haven't renewed your subscription which has <b>{{ resumedate }}</b> as
                validity date. To ensure that you continue to enjoy all the benefits and
                features of our platform, we kindly ask you to renew your
                subscription.<br />

                Renewing your subscription is quick and easy. Simply hit the button
                below and you'll be back in action in no time. By renewing, you'll
                maintain access to all your data, settings, and any special offers or
                privileges you had before.<br />

                Thank you for choosing Spryple. We're here to make your experience the
                best it can be. If you have any questions or need assistance with the
                renewal process, our support team is here to help.<br />

                Renew your subscription today and rediscover all that Spryple has to
                offer!<br /><br />

                Best regards,<br />
                The Spryple Team<br /><br />
                <mat-divider></mat-divider><br />
                <mat-card>
                    Last validity date - <b>{{ resumevaliditydate }}</b> <br /><br />

                    Number of users - <b>{{ resumecount }}</b><br /><br />

                    Renewing your subscription extends the validity date to
                    <b>{{ resumedate }}</b> and costs Rs <b>{{ resumeamount }}</b>.<br /> </mat-card><br />
            </div>
            <div fxLayoutAlign="center">
                <button class="btn btn-primary my-class" (click)="resumesubscriptionpay()">
                    Resume my Subscription
                </button>
            </div>
        </mat-card>
    </mat-card>
</div>