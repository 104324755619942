import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent implements OnInit {
  showline: boolean = true;
  constructor() { }
  @Input()
  date!: string;
  @Input()
  content!: string;
  @Input()
  lengthOfTicketHistory!: any;
  @Input()
  index!: any;

  ngOnInit(): void {
    this.showline = this.index < this.lengthOfTicketHistory - 1;
  }

}
