<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="locationsForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>Locations</mat-card-title>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
            
        <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Location </mat-label>
                    <mat-select formControlName="location">
                        <ng-container *ngFor="let value of locationsList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                 </mat-form-field>
              
                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Sub Location </mat-label>
                    <mat-select formControlName="sublocation">
                        <ng-container *ngFor="let value of locationsList">
                            <mat-option [value]="value">{{value}}</mat-option>
                        </ng-container>
                    </mat-select>
                 </mat-form-field>
                
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Building Name</mat-label>
                    <input type="text" formControlName="buildingName"  matInput placeholder="Building Name" >
               <mat-error *ngIf="locationsForm.controls.buildingName.hasError('required')">
                Please Enter Building Name
               </mat-error>
                </mat-form-field>

             </div>

             <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Floor Name</mat-label>
                    <input type="text" formControlName="floorName"  matInput placeholder="Floor Name" >
                    <mat-error *ngIf="locationsForm.controls.floorName.hasError('required')">
                        Please Enter Floor Name
                       </mat-error>
                </mat-form-field>
              
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Room Name</mat-label>
                    <input type="text" formControlName="roomName"  matInput placeholder="Room Name" >
                </mat-form-field>
                
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Location Id</mat-label>
                    <input type="text" formControlName="locationId"  matInput placeholder="Location Id" >
               </mat-form-field>
             </div>

            <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </div>
        </mat-card>

    </form>
     <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%"  fxLayoutAlign="space-between center" *ngIf="isdata">
            <span style="padding-top: 5px; padding-left: 10px;">
             <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
              </span>
              <span style="padding-right: 7px;">
                <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="submit">
                    <span>Add</span>
                </button>
              </span>
            
         </div>

      <div fxLayout="column" class="tableP" *ngIf="isdata">
       <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="subLoc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Location</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="building">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Building</th>
                    <td mat-cell *matCellDef="let row"> {{row.email}}</td>
                </ng-container>
                <ng-container matColumnDef="floor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Floor </th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="room">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Room </th>
                    <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>