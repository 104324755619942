<!-- <mat-toolbar color="primary">
  <span>Card view demo</span>
  <button mat-stroked-button (click)="toggleGridColumns()" fxHide.lt-md>
    <span *ngIf="gridColumns === 4">Three Columns</span>
    <span *ngIf="gridColumns === 3">Four Columns</span>
  </button>
</mat-toolbar> -->
<br>
<div class="content">
  <h2>User Guide</h2>
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card ">
        <mat-card-header>
          <mat-card-title>Admin User Manual</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 50px;width: 50px;" src="assets/images/icons/admin.png">
        </div>
        <mat-card-content>
          <!-- <p>           EMS Admin Login        </p> -->
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
           <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/ESIFO75rYI1AgmO4Lrk6kgcB9jHAOqwhUAocRBpO1vaCXQ?e=fVdWve" >
            <button mat-stroked-button >VIEW</button>
          </a>  
       
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Employee Management</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 50px;width: 50px;" src="assets/images/icons/ems.png">
        </div>
        <mat-card-content>
          <!-- <p>           EMS Admin Login          </p> -->
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EWsffEyEHoVFqH-VABC4u1ABpwuOP-3aW5bvJxNO0YT6sQ?e=fLznkd" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Attendanance & Leave </mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 50px;width: 50px;" src="assets/images/icons/hr.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/Ef9k7LgBvZFFtHh4OY4WYS0Bv3_40eTAF2tXx0QK3wu1HA?e=y0TXQE" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>
    
     <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Payroll Management</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 50px;width: 50px;" src="assets/images/icons/payroll-img.png">
        </div>
        <mat-card-content>
          <!-- <p>            Payroll Management          </p> -->
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EWAk5x3FBORJkE75vW7OSwMBsFJbMjennnrwE0l4_w8YIA?e=afxkf5" >
            <button mat-stroked-button color="" >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Exit Management</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 60px;" src="assets/images/icons/exit.png">
        </div>
        <mat-card-content>
       </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EdcTsKs7pvhFs-gVQyM2_GgB8i6D-m32VGqv6jR2DE5-fg?e=1U8GAm" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Subscription</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 60px;" src="assets/images/icons/subscription.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EZ6FgEMaCslPmYaOUvWnaZ8BdCxSMS5ny0endq1b1JeVlQ?e=LZ75ad" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Spryple HCM</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 200px;" src="assets/images/icons/spryple_hr.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EWovpNniS4lOk0ub-LJl35sBOundZtCLFfMMvlSitm_c3Q?e=S8XUfi" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>
    
    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Spryple Lite</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 200px;" src="assets/images/icons/spryple_lite.png">
        </div>
        <mat-card-content>
          <!-- <p>  Spryple Lite </p> -->
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EWaAevREjLJFsq3qoZNXpQMBGtSNTPM19RQcolQvPzuHjA?e=aB3gGv" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Spryple CRM-Web</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 200px;" src="assets/images/icons/spryple_crm.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EcPKQBukVCVFjP8OjVtE7DMBudMpzvmUJJlHXgACxy6H9g?e=YyR3cb" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Spryple CRM-Mobile</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 200px;" src="assets/images/icons/spryple_crm.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/Ea7-JXm6gkFPi2GxogQs0vsBLY4Sbm8ITWXq9BHvFmUIZg?e=yvxODt" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>

    <div [fxFlex]="(100/gridColumns) + '%'" fxFlex.xs="100%" fxFlex.sm="33%">
      <mat-card class="mat-elevation-z4 card">
        <mat-card-header>
          <mat-card-title>Spryple CRM-Admin</mat-card-title>
        </mat-card-header>
        <br>
        <div fxLayoutAlign="center" >
          <img style="height: 60px;width: 200px;" src="assets/images/icons/spryple_crm.png">
        </div>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center" >
          <a target="_blank" href="https://sreebt-my.sharepoint.com/:b:/g/personal/no-reply_spryple_com/EcKQeNs4dNlKk0qSFpN0EuMBqZGds538Gn8s1baiJx-Bqg?e=RACez2" >
            <button mat-stroked-button >VIEW</button>
          </a> 
        </mat-card-actions>
      </mat-card>
    </div>


  </div>
</div>