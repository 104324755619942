import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-payroll-final-settlement',
  templateUrl: './payroll-final-settlement.component.html',
  styleUrls: ['./payroll-final-settlement.component.scss'],
})
export class PayrollFinalSettlementComponent implements OnInit {
  finalSettlementForm: any = UntypedFormControl;
  employeeList: any = [{ id: 1, name: 'Pranitha' }];
  DisplayName: any;
  displayFinalSettlement: boolean = false;

  Components = [
    { component: 'Basic', temp1: 1, deduction: 'PF', temp2: 7 },
    { component: 'HRA', temp1: 1, deduction: 'ESIC', temp2: 8 },
    { component: 'Conveyance', temp1: 1, deduction: 'TDS', temp2: 9 },
    {
      component: 'Spl.Allowance',
      temp1: 1,
      deduction: 'Loan/Advance',
      temp2: 10,
    },
    {
      component: 'Others(if any)',
      temp1: 1,
      deduction: 'Others (if any)',
      temp2: 11,
    },
    { component: 'Total Payable Amount', temp1: 1, deduction: '', temp2: 12 },
  ];

  Benefits = [
    { component: 'Leaves', temp1: 1000, deduction: 'Loan/Advance', temp2: 7 },
    {
      component: 'Gratuity',
      temp1: '1,50,000',
      deduction: 'Notice Period (No. of days)',
      temp2: 8,
    },
    {
      component: 'Hold Salary',
      temp1: '10000',
      deduction: 'Others (if any)',
      temp2: 9,
    },
  ];

  dataSource = this.Components;
  dataSource2 = this.Benefits;
  displayedColumns = ['component', 'temp1', 'deduction', 'temp2'];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.finalSettlementForm = this.formBuilder.group({
      employeeName: ['', Validators.required],
      empid: [''],
      empname: [''],
      designation: [''],
      dateofrelieving: [''],
      yrsOfService: [''],
      dateofjoin: [''],
    });
  }

  onSelection() {
    this.displayFinalSettlement = true;
    let data = {
      empname: this.finalSettlementForm.controls.employeeName.value,
    };
  }
  resetform() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/payrollFinalSettlement']));
  }

  public exportPDF(): void {
    // const month = 'May 2023'
    // const pdfTable = this.table.nativeElement;
    // var html = htmlToPdfmake(pdfTable.innerHTML);
    // pdfMake.createPdf({
    //   info: {
    //     title: 'Payroll Final Settlement'+ month,
    //     author:'Sreeb tech',
    //     subject:'Theme',
    //         keywords:'Report'
    //   },
    //   footer: function (currentPage, pageCount) {
    //     return {
    //       margin: 10,
    //       columns: [
    //         {
    //           fontSize: 9,
    //           text: [
    //             {
    //               text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
    //             }
    //           ],
    //           alignment: 'center'
    //         }
    //       ]
    //     };
    //   },
    //   content: [
    //     {
    //       text: +"\n\n",
    //       style: 'header',
    //       alignment: 'center',
    //       fontSize: 14
    //     },
    //     html
    //   ],
    //   pageOrientation: 'landscape'//'portrait'
    // }).download(this.reporthide?"Pfofessional tax challan Report for "+this.monthdata+","+this.year +".pdf":"Professional tax detail Report for "+this.monthdata+","+this.year +".pdf");
  }
}
