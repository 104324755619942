import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup,UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CrmService } from '../../crm.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ComfirmationDialogComponent } from 'src/app/pages/comfirmation-dialog/comfirmation-dialog.component';
export interface UserData {
  type: string;
  status: string;
  id: number;
  total: number;
}
@Component({
  selector: 'app-crm-client-associate-configures',
  templateUrl: './crm-client-associate-configures.component.html',
  styleUrls: ['./crm-client-associate-configures.component.scss']
})
  
export class CrmClientAssociateConfiguresComponent implements OnInit {
  arrayValue:any = [];
  typeForm!: UntypedFormGroup;
  typestatus: any;
  userSession:any;
  pipe = new DatePipe('en-US');
  issubmitted: boolean = false;
  isvalid: boolean = false;
  isView: boolean = false;
  isAdd: boolean = false;
  isdata: boolean = true;
  isEdit: boolean = true;
  isSave: boolean = false;
  isDelete: boolean = true;
  enable: any = null;
  valid: boolean = false;
  activitystatus:any=null;
  typeData:any =[];
  todayDate:any=new Date();
  displayedColumns: string[] = ['sno','type', 'status', 'Action'];
  masterTypes:any[] =[{key:'at',val:'Associate Type'},{key:'qt',val:'Qualification'},{key:'st',val:'Specialization'}];
  masterType:any = {key:'at',val:'Associate Type'};
  dataSource: MatTableDataSource<UserData> = <any>[];
  
  pageLoading = true;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  companyDBName:any = environment.dbName;
    messagesDataList: any = [];
  
  LM: any;
  demoData: UserData[] | undefined;
 
  demoStatusVal: any=[];
  clientStatusVal!: never[];
  constructor(private crmService:CrmService,private Company:CompanySettingService,private formBuilder: UntypedFormBuilder, private router: Router,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.getstatuslist();
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.typeForm = this.formBuilder.group( {
      typedata: ["",[Validators.required ]],
    }, );
    this.getTypes();
  }
  getstatuslist(){
    this.Company.getstatuslists().subscribe((result:any) => {     
      if(result.status){
        this.arrayValue = result.data;
      }
    })
  }

  changemasterType(m:any){
    this.masterType = m;
    this.getTypes();
  }

  cancel() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/Crm/CrmClientAssociateConfigures"]));
  }

  
  status(status:any,id:any,type:any){
    // status(halfs.id,row.id,row.display_name)
       let data={
         type_id:id,
         type:type,
         status:status,
         created_by:this.userSession.id
     }
     this.setTypes(data);
   }

   getTypes() {
   
    let params = {pagenumber:1,pagesize:100}
    if(this.masterType.key === 'at'){
      this.getAssociateTypes(params);
    }
    if(this.masterType.key === 'qt'){
      this.getQualificationTypes(params);
    }
    if(this.masterType.key === 'st'){
      this.getSpecializationTypes(params);
    }
  }
  getAssociateTypes(params:any){
    this.crmService.getCrmClientAssociateTypesMaster(params).subscribe((res:any)=>{
      if(res.status) {
        this.typeData =  res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
      else {
        this.typeData = [];
      }
    })
  }
  getQualificationTypes(params:any){
    this.crmService.getCrmClientAssociateQualificationTypesMaster(params).subscribe((res:any)=>{
      if(res.status) {
        this.typeData =  res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
      else {
        this.typeData = [];
      }
    })
  }

  getSpecializationTypes(params:any){
    this.crmService.getCrmClientAssociateSpecializationTypesMaster(params).subscribe((res:any)=>{
      if(res.status) {
        this.typeData =  res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
      else {
        this.typeData = [];
      }
    })
  }
   setTypes(data:any) {
       data.type = data.type.trim();
       data.type  = data.type.replace(/\s+/g, ' ');
       if(data.type && data.type.toString().length){
        if(this.masterType.key === 'at'){
          this.setAssociateType(data);
        }
        if(this.masterType.key === 'qt'){
          this.setQualificationType(data);
        }
        if(this.masterType.key === 'st'){
          this.setSpecializationType(data);
        }
     }
   }

   setAssociateType(data:any){
      this.crmService.setCrmClientAssociateType(data).subscribe((res:any)=>{
        if(res.status) {

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/Crm/CrmClientAssociateConfigures"]));
         // this.getTypes();
         // this.dataSource.paginator = this.paginator;
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data:res.data[0].resultmsg,
        });
      }
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to add type. Please try again. "
        });
      }
    })
  }

  setQualificationType(data:any){
    this.crmService.setCrmClientAssociateQualificationType(data).subscribe((res:any)=>{
      if(res.status) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/Crm/CrmClientAssociateConfigures"]));
       // this.getTypes();
       // this.dataSource.paginator = this.paginator;
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data:res.data[0].resultmsg,
      });
    }
    else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Unable to add type. Please try again. "
      });
    }
  })
}
 
setSpecializationType(data:any){

  this.crmService.setCrmClientAssociateSpecializationType(data).subscribe((res:any)=>{
    if(res.status) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(["/Crm/CrmClientAssociateConfigures"]));
    //  this.getTypes();
     // this.dataSource.paginator = this.paginator;
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data:res.data[0].resultmsg,
    });
  }
  else {
    let dialogRef = this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: "Unable to add type. Please try again. "
    });
  }
})
}

 Add() {
     this.isAdd = true;
     this.isdata = false;
     this.typeForm.controls.typedata.setValue('')
   }
 
 edit(w: any, i: any) {
     
     this.enable = i.id;
     this.isEdit = false;
     this.isDelete = false;
     
     this.isSave = true;
     this.typeForm.controls.typedata.setValue(i.type);
   }
 
   save(event: any,i: any, type: any) {
      
     if (this.typeForm.valid) {
       
       this.enable = null;
       this.isEdit = true;
       this.isSave = false;
       this.isDelete = true;
      // if (this.valid) {
         let data={
           type_id:i.id,
           type:type,
           status:true,
           created_by:this.userSession.id
         }
         this.setTypes(data);
     //  }
  }
 }
 
  AddData(){
   let data = {
     type_id:0,
     type:this.typeForm.controls.typedata.value,
     status:true,
     created_by:this.userSession.id
   }
   this.setTypes(data);
   
  }           
   canceledit(event: any, id: any) {
     this.enable = null;
     this.isEdit = true;
     this.isSave = false;
     this.isDelete = true;
     this.getTypes();
   }
 
   getPageSizes(): number[] {
     var customPageSizeArray = [];
     
     if (this.dataSource.data.length > 5) {
       customPageSizeArray.push(5);
     }
     if (this.dataSource.data.length > 10) {
       customPageSizeArray.push(10);
     }
     if (this.dataSource.data.length > 20) {
       customPageSizeArray.push(20);
      
     }
     customPageSizeArray.push(this.dataSource.data.length);
     return customPageSizeArray;
   }
   
   getMessages() {
     let data =
     {
       "code": null,
       "pagenumber": 1,
       "pagesize": 1000
     }
     
   }

     /**Search functionality */
     applyFilter(event: Event) {
       const filterValue = (event.target as HTMLInputElement).value;
       this.dataSource.filter = filterValue.trim().toLowerCase();
       if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
       }
   
     }
     
     disableStatus(row:any){
       if(row && row.is_default)
         return true;
       else if(this.enable == row.id)
         return true;
       else return false;
     }
}
