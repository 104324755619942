import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import htmlToPdfmake from 'html-to-pdfmake';
import moment from 'moment';
import { Moment } from 'moment';

import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import * as XLSX from 'xlsx';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-esireports',
  templateUrl: './esireports.component.html',
  styleUrls: ['./esireports.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ESIreportsComponent implements OnInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  userSession: any;
  ESIReport: any = UntypedFormGroup;
  minDateForESI: any = new Date('2022-01-01');
  maxDateForESI = new Date();
  dateESI = new UntypedFormControl(moment());
  message: any;
  setMonthAndYearESI(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.dateESI.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateESI.setValue(ctrlValue);
    datepicker.close();
  }
  stateslistESI: any = [];
  messageflagESI: boolean = true;
  monthdataESI: any;
  selectedStateIdESI: any;
  dataSourceESI: MatTableDataSource<any> = <any>[];
  displayedColumnsForESI: string[] = [
    'ipnumber',
    'ipname',
    'nofdays',
    'totawages',
    'ipcontribution',
    'reasonforzeroworkingdays',
    'lastworkingdays',
  ];
  @ViewChild('table') table!: ElementRef;
  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  year: any;
  pageLoading = true;
  searchControl = new FormControl('');
  filteredStates: any = [];
  List: any = [];
  constructor(
    public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    public router: Router,
    private payrollService: PayrollService,
    private LM: LeavesService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.ESIReport = this.formBuilder.group({
      fromDateForESI: [new Date()],
      state: [''],
    });

    this.ESIReport.get('dateESI')?.valueChanges.subscribe(
      (selectedValue: any) => {
        // this.getEsiValuesForChallanForReportsuser();
      }
    );

    this.ESIReport.get('state')?.valueChanges.subscribe(
      (selectedValue: any) => {
        this.selectedStateIdESI = selectedValue;
        // this.getEsiValuesForChallanForReportsuser();
      }
    );

    this.getStatesList(); /**for ESI report */

    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterStates(searchText)
    })
  }


  getStatesList() {
    this.payrollService.getCompanyEsiValues().subscribe((result: any) => {
      if (result.status && result.data.length > 0) {
        this.stateslistESI = result.data[0];
        this.filteredStates = this.stateslistESI;
      } else {
        this.stateslistESI = [];
      }
    });
  }

  public exportPDFforESI(): void {
    if (this.messageflagESI) {
      const pdfTable = this.table.nativeElement;
      var html = htmlToPdfmake(pdfTable.innerHTML);
      pdfMake
        .createPdf({
          info: {
            title: 'ESI Report',
            author: 'Sreeb tech',
            subject: 'Theme',
            keywords: 'Report',
          },
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  fontSize: 9,
                  text: [
                    {
                      text:
                        'Page ' + currentPage.toString() + ' of ' + pageCount,
                    },
                  ],
                  alignment: 'center',
                },
              ],
            };
          },
          content: [
            {
              text: 'Payroll Report\n\n',
              style: 'header',
              alignment: 'center',
              fontSize: 14,
            },

            html,
          ],
          pageOrientation: 'landscape', //'portrait'
        })
        .download('ESI Report.pdf');
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }

  exportAsXLSXforESI() {
    if (this.messageflagESI) {
      this.year = this.ESIReport.controls.fromDateForESI.value.getFullYear();
      for (let i = 0; i < this.months.length; i++) {
        if (
          this.ESIReport.controls.fromDateForESI.value.getMonth() ==
          this.months[i].id
        ) {
          this.monthdataESI = this.months[i].month;
          break;
        }
      }
      var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
        document.getElementById('table')
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'ESI_report_for_financeteam_');
      /* save to file */
      XLSX.writeFile(
        wb,
        'ESI_report_for_financeteam_' +
        this.monthdataESI +
        '_' +
        this.year +
        '.xlsx'
      );
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }

  Searchform() {

    this.getEsiValuesForChallanForReportsuser();
  }

  getEsiValuesForChallanForReportsuser() {
    let data = {
      year_value: this.dateESI.value._d.getFullYear(),
      month_value: this.dateESI.value._d.getMonth() + 1,
      stateid_value: Number(this.selectedStateIdESI),
      rempid: this.userSession.id,
    };
    this.dataSourceESI = new MatTableDataSource();
    this.payrollService
      .getEsiValuesForChallanForReportsuser(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.List = result.data,
            this.dataSourceESI = new MatTableDataSource(this.List);
          this.dataSourceESI.paginator = this.paginator;
          this.dataSourceESI.sort = this.sort;
          this.pageLoading = false;
          // this.validateEsiChallanDownload();
        }
      });
  }

  resetform() {
    this.ngOnInit();
    this.dateESI.reset();

  }

  getPageSizesESI(): number[] {
    var customPageSizeArray = [];
    if (this.dataSourceESI.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSourceESI.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSourceESI.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSourceESI.data.length);
    return customPageSizeArray;
  }
  filterStates(searchText: any) {
    this.filteredStates = this.stateslistESI.filter((val: any) =>
      val.state.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredStates.length <= 0) {
      this.searchControl.setValue('');
    }

  }

  downloadPDF() {

    let year_value = this.dateESI.value._d.getFullYear();
    let month_value = this.dateESI.value._d.getMonth() + 1;

    let data = {
      'list': this.List,
      'report_date': month_value + '/' + year_value,
    }
    this.reportsService.getESIReportPdf(data).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'esi.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
