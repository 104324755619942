import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PayrollService } from '../../payroll.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-income-tax-exemption',
  templateUrl: './income-tax-exemption.component.html',
  styleUrls: ['./income-tax-exemption.component.scss'],
})
export class IncomeTaxExemptionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private payrollService: PayrollService,
    public dialog: MatDialog,
    private LM: LeavesService
  ) { }
  employeeExemptionForm: any = UntypedFormGroup;
  sectionsList: any = [];
  categoryList: any = [];
  displayedColumns: string[] = [
    'id',
    'date',
    'section',
    'submittedAmount',
    'approvedAmount',
    // 'description',
    'status',
    'action',
  ];
  dataSource: any;
  id: any;
  fileURL: any;
  file: any;
  section: any;
  balance: any;
  amount: any;
  userSession: any;
  mappingId: any;
  pendingAmount: any;
  maximumLimit: any;
  descriptionForDisplay: string = '';
  descriptionList: any;
  iseditDoc: boolean = true;
  isFile: boolean = true;
  ispdf: boolean = true;
  pdfName: any = null;
  document: boolean = false;
  pageLoading = true;
  addNewField: boolean = true;
  displayTable: boolean = true;
  displayForm: boolean = false;
  displayMaxLimit: boolean = false;
  formData: FormData = new FormData();
  requestId: any;
  documentId: any = null;
  documentInfo: any = null;
  isRequestView = false;
  isApprove: boolean = false;
  pipe = new DatePipe('en-US');
  balanceAmount: any;
  isUpdate: boolean = false;
  ngOnInit(): void {
    this.employeeExemptionForm = this.formBuilder.group({
      section: ['', Validators.required],
      category: ['', Validators.required],
      declaredAmount: [''],
      receiptNumber: ['', Validators.required],
      submitAmount: ['', Validators.required],
      description: ['', Validators.required],
      document: [''],
      pendingAmount: [''],
      appliedDate: [''],
    });
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.employeeExemptionForm.controls.appliedDate.setValue(this.pipe.transform(new Date, 'dd-MM-YYYY'));
    this.getEmployeeTaxSavings();
    this.getIncomeTaxCategory();
    // this.employeeExemptionForm.get('submitAmount').valueChanges.subscribe((value:any) => {

    //   let submitAmt = this.employeeExemptionForm.controls.submitAmount.value;
    //   let pendingAmt = this.employeeExemptionForm.controls.pendingAmount.value;
    //   let remainPendingAmt = pendingAmt - submitAmt;
    //   if(submitAmt == ""){
    //     this.employeeExemptionForm.controls.pendingAmount.setValue(this.pendingAmount);

    //   } else if(remainPendingAmt < 0){
    //     let dialogRef = this.dialog.open(ReusableDialogComponent, {
    //         position: { top: `70px` },
    //         disableClose: true,
    //         data: 'Can not submit below amount of pending amount - ' + this.balanceAmount,
    //       });
    //       this.employeeExemptionForm.controls.submitAmount.setValue('');

    //     } else{
    //     this.employeeExemptionForm.controls.pendingAmount.setValue(remainPendingAmt);
    //   }
    // });
  }

  insertMaxExemAmount(data: any, mapId: any, description: any) {
    this.descriptionForDisplay = '';
    this.maximumLimit = '';
    this.employeeExemptionForm.controls.declaredAmount.setValue(data);
    this.mappingId = mapId;
    this.getEmployeeSectionWiseSavings();
    for (let i = 0; i < description.length; i++) {
      if (description.charCodeAt(i) == 40 || description.charCodeAt(i) == 41) {
      } else {
        this.descriptionForDisplay += description[i];
      }

    }
    this.descriptionList = this.descriptionForDisplay.split(",")
    this.maximumLimit = data;

    this.displayMaxLimit = true;
    if (this.pendingAmount == '') {
      this.balanceAmount = this.maximumLimit;

    }


  }

  getEmployeeSectionWiseSavings() {

    this.employeeExemptionForm.controls.pendingAmount.setValue('');
    let data = {
      mapping_id_value: this.mappingId,
      employee_id_value: this.userSession.id,
    };

    this.payrollService.getEmployeeSectionWiseSavings(data).subscribe((result: any) => {
      let list = result.data;
      let sectionIdfromForm =
        this.employeeExemptionForm.controls.section.value;
      let maxAmount =
        this.employeeExemptionForm.controls.declaredAmount.value;

      if (list != undefined) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].sectionId == sectionIdfromForm) {
            this.pendingAmount = maxAmount - list[i].savingAmount;
            this.balanceAmount = this.pendingAmount;
          }
        }
      }

      this.employeeExemptionForm.controls.pendingAmount.setValue(
        this.pendingAmount
      );
    });
  }

  fileView() {
    window.open(this.fileURL);
  }

  onSelectFile(event: any) {
    this.iseditDoc = false;

    if (event.target.files[0].size <= 1242880) {
      this.file = event.target.files[0];
      var pdf = this.file.name.split('.');

      if (pdf[pdf.length - 1] == 'pdf' || pdf[pdf.length - 1] == 'jpg' || pdf[pdf.length - 1] == 'png') {
        this.isFile = true;
      } else {
        this.ispdf = true;
        this.isFile = false;
      }
    } else {
      this.ispdf = false;
      this.isFile = false;

      // Swal.fire({title: '', text: 'File size is must be less than 15MB', color: "red", position: 'top'});
    }
  }

  editdoc() {
    this.pdfName = null;
  }

  deleteIcon() {
    this.employeeExemptionForm.controls.document.setValue('');
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  submit() {

    if (this.employeeExemptionForm.valid) {

      let value = this.employeeExemptionForm.controls.submitAmount.value;
      if (value > this.balanceAmount) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Can submit upto ' + this.balanceAmount + '/- only!',
        });
        this.employeeExemptionForm.controls.submitAmount.setValue('');
        return;
      }

      if (value >= 1) {

        let data = {
          id_value: this.id != null ? this.id : null,
          employee_id_value: this.userSession.id,
          section_id_value: this.employeeExemptionForm.controls.section.value,
          mapping_id_value: this.mappingId,
          receipt_number_value: this.employeeExemptionForm.controls.receiptNumber.value,
          amount_value: this.employeeExemptionForm.controls.submitAmount.value,
          description_value: this.employeeExemptionForm.controls.description.value,
          file_path_value: null,
          created_by_value: this.userSession.id,
          approved_amount_value: 0,
          status_value: 1, // 1-submitted , 2- approved , 3-rejected
          approved_by_value: null
        };
        this.payrollService.setPayrollEmployeeTaxSavings(data).subscribe((result: any) => {
          let resMessage = result.data[0].statuscode === 0 ? 'Submitted successfully.' : result.data[0].statuscode === 2 ? 'Data updated successfully.' : result.data[0].statuscode === 1 ? 'Already excemption limit reached' : 'Unable to save data'
          this.requestId = result.data[0].lastInsertId;
          if (result.status) {
            if (this.isFile = true) {
              this.LM.getFilepathsMaster(5).subscribe((result: any) => {
                if (result && result.status) {
                  let obj = {
                    id: null,
                    employeeId: this.userSession.id,
                    filecategory: 'INVESTMENTS',
                    moduleId: 5,
                    documentnumber: '',
                    fileName: this.file.name,
                    modulecode: result.data[0].module_code,
                    requestId: this.requestId,
                    status: 'Submitted',
                  };
                  this.LM.setFilesMaster(obj).subscribe((data) => {
                    if (data && data.status) {
                      let info = JSON.stringify(data.data[0]);
                      this.formData.append('info', info);
                      this.formData.append('file', this.file);
                      this.LM.setProfileImage(this.formData).subscribe(
                        (data) => {
                        }
                      );
                    }
                  });
                } else {

                  this.dialog.open(ConfirmationComponent, {
                    position: { top: `70px` },
                    disableClose: true,
                    data: {
                      Message: 'Please check uploaded file.',
                    },
                  });
                }
              });

            }

            /****--------------------- */
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: resMessage,
            });
            this.navigationToITexcemption();

          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to save data.',
            });
          }
        });

      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Submit Amount should not be 0',
        });
        this.employeeExemptionForm.controls.submitAmount.setValue('')
      }

    }

  }

  cancel() {
    this.addNewField = true;
    this.displayTable = true;
    this.displayForm = false;
    this.displayMaxLimit = false;
    this.navigationToITexcemption();
  }

  addNewSection() {
    this.addNewField = false;
    this.displayTable = false;
    this.displayForm = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getIncomeTaxSections() {
    this.displayMaxLimit = false;
    this.employeeExemptionForm.controls.section.setValue('')
    let data = {
      category_id_value: this.employeeExemptionForm.controls.category.value,
    };
    this.payrollService.getIncomeTaxSections(data).subscribe((result: any) => {
      this.sectionsList = result.data;

    });

  }

  getEmployeeTaxSavings() {
    let data = {
      employee_id_value: this.userSession.id,
      manager_id_value: null,
    };
    this.payrollService.getEmployeeTaxSavings(data).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading = false;
    });
  }

  Edit(value: any) {
    this.addNewField = false;
    this.displayTable = false;
    this.displayForm = true;
    this.isUpdate = true;
    this.isApprove = true;
    this.requestId = value.id;
    this.employeeExemptionForm.controls.category.setValue(value.categoryId);
    this.employeeExemptionForm.controls.category.disable();
    this.getIncomeTaxSections();
    this.employeeExemptionForm.controls.section.setValue(value.sectionId);
    this.employeeExemptionForm.controls.receiptNumber.setValue(value.receiptNumber);
    this.employeeExemptionForm.controls.submitAmount.setValue(value.savingAmount);
    this.employeeExemptionForm.controls.declaredAmount.setValue(value.maxExcemptedAmount);
    this.employeeExemptionForm.controls.description.setValue(value.description);
    let pendingAmount = value.maxExcemptedAmount - value.sumAmount;
    this.employeeExemptionForm.controls.pendingAmount.setValue(pendingAmount);
    this.descriptionForDisplay = '';
    let sectionDescription = value.sectionDescription;
    for (let i = 0; i < sectionDescription.length; i++) {
      if (
        sectionDescription.charCodeAt(i) == 40 ||
        sectionDescription.charCodeAt(i) == 41
      ) {
      } else {
        this.descriptionForDisplay += sectionDescription[i];
      }
    }
    this.mappingId = value.mappingId;
    this.id = value.id;
    this.displayMaxLimit = true;
    this.maximumLimit = value.maxExcemptedAmount;
    this.employeeExemptionForm.controls.appliedDate.setValue(this.pipe.transform(value.createdOn, 'dd-MM-YYYY'));
    this.getUploadDocument();
  }

  getIncomeTaxCategory() {
    this.payrollService.getIncomeTaxCategory().subscribe((result: any) => {
      this.categoryList = result.data;
    });

  }

  navigationToITexcemption() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Payroll/EmployeeITDeclaration']));
  }


  getUploadDocument() {
    let info = {
      'employeeId': this.userSession.id,
      'candidateId': null,
      'filecategory': 'INVESTMENTS',
      'moduleId': 5,
      'requestId': this.requestId,
      'status': 'Submitted'
    }
    this.LM.getFilesMaster(info).subscribe((result) => {
      if (result && result.status && result.data.length > 0) {
        this.documentId = result.data[0].id;
        this.documentInfo = JSON.stringify(result.data[0])
        this.pdfName = result.data[0].fname

        result.data[0].employeeId = this.userSession.id;
        let info = result.data[0];
        this.LM.getProfileImage(info).subscribe((imageData) => {
          if (imageData.success) {
            this.document = true;
            this.employeeExemptionForm.controls.document.value = true;
            this.employeeExemptionForm.controls.document.clearValidators();
            this.employeeExemptionForm.controls.document.updateValueAndValidity();
            this.iseditDoc = false;


            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '');
            var documentName = this.pdfName.split('.');
            // const file = new Blob([TYPED_ARRAY], { type: "application/pdf" });
            // this.fileURL = URL.createObjectURL(file);
            if (documentName[documentName.length - 1] == 'pdf') {
              const file = new Blob([TYPED_ARRAY], { type: 'application/pdf' });
              this.fileURL = URL.createObjectURL(file);
            } else {
              const file = new Blob([TYPED_ARRAY], { type: 'image/png' });
              this.fileURL = URL.createObjectURL(file);
            }
          }
        })
      }
      else {
      }
    })
  }

  viewData(value: any) {

    this.addNewField = false;
    this.displayTable = false;
    this.displayForm = true;

    value.statusName == 'Approved' ? this.isApprove = true : this.isApprove = false;
    this.requestId = value.id;
    this.employeeExemptionForm.controls.category.setValue(value.categoryId);
    this.employeeExemptionForm.controls.category.disable();
    this.employeeExemptionForm.controls.section.setValue(value.sectionId);
    this.employeeExemptionForm.controls.receiptNumber.setValue(value.receiptNumber);
    this.employeeExemptionForm.controls.submitAmount.setValue(value.savingAmount);
    this.employeeExemptionForm.controls.declaredAmount.setValue(value.maxExcemptedAmount);
    this.employeeExemptionForm.controls.description.setValue(value.description);
    this.getIncomeTaxSections();
    let pendingAmount = value.maxExcemptedAmount - value.sumAmount;
    this.employeeExemptionForm.controls.pendingAmount.setValue(pendingAmount);
    this.descriptionForDisplay = '';
    let sectionDescription = value.sectionDescription;
    for (let i = 0; i < sectionDescription.length; i++) {
      if (
        sectionDescription.charCodeAt(i) == 40 ||
        sectionDescription.charCodeAt(i) == 41
      ) {
      } else {
        this.descriptionForDisplay += sectionDescription[i];
      }
    }
    this.mappingId = value.mappingId;
    this.id = value.id;
    this.displayMaxLimit = true;
    this.maximumLimit = value.maxExcemptedAmount;
    this.getUploadDocument();
    this.employeeExemptionForm.controls.section.disable();
    this.employeeExemptionForm.controls.receiptNumber.disable();
    this.employeeExemptionForm.controls.submitAmount.disable();
    this.employeeExemptionForm.controls.declaredAmount.disable();
    this.employeeExemptionForm.controls.description.disable();
    this.employeeExemptionForm.controls.pendingAmount.disable();
  }

  onchangeAmount() {
    // this.dataSource.length == undefined ? this.employeeExemptionForm.controls.pendingAmount.setValue('') : this.pendingAmount;
    let submitAmt = this.employeeExemptionForm.controls.submitAmount.value;
    let pendingAmt = this.employeeExemptionForm.controls.pendingAmount.value;

    if (pendingAmt != undefined) {
      let remainPendingAmt = pendingAmt - submitAmt;
      if (submitAmt == "") {
        this.employeeExemptionForm.controls.pendingAmount.setValue(this.pendingAmount);
      } else if (remainPendingAmt < 0) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Submit amount can not be greater than Pending amount',
        });
        this.employeeExemptionForm.controls.submitAmount.setValue('');
        this.employeeExemptionForm.controls.pendingAmount.setValue(this.pendingAmount);
      }
      else {
        this.employeeExemptionForm.controls.pendingAmount.setValue(remainPendingAmt);
      }
    } else {
      if (submitAmt > this.maximumLimit) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Submit amount can not be greater than ' + this.maximumLimit,
        });
        this.employeeExemptionForm.controls.submitAmount.setValue('');
        this.employeeExemptionForm.controls.pendingAmount.setValue('');
      } else {
        pendingAmt = this.maximumLimit;
        this.employeeExemptionForm.controls.pendingAmount.setValue(pendingAmt - submitAmt);
      }
    }

  }
}
