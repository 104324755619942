import {Component, OnInit, ViewChild} from '@angular/core';
import {UserData} from "../../../attendance/models/EmployeeData";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";
import { ReviewAndApprovalsComponent } from 'src/app/modules/leaves/dialog/review-and-approvals/review-and-approvals.component';
import {MatDialog} from "@angular/material/dialog";
import {ReusableDialogComponent} from "../../../../pages/reusable-dialog/reusable-dialog.component";
import {DatePipe} from "@angular/common";
import { PayrollService } from '../../payroll.service';
import { UntypedFormGroup, UntypedFormBuilder,FormArray,FormControl, Validators, AbstractControl } from '@angular/forms';
// import {ConfirmationComponent} from "../../dialog/confirmation/confirmation.component";


@Component({
  selector: 'app-advance-salary-approval',
  templateUrl: './advance-salary-approval.component.html',
  styleUrls: ['./advance-salary-approval.component.scss']
})
export class AdvanceSalaryApprovalComponent implements OnInit {
  AdvanceSalaryRequestform!: UntypedFormGroup; 
  displayedColumns: string[] = ['sno','empcode','ename','gsalary','ramount','action']
  dataSource: MatTableDataSource<UserData>=<any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;


  titleName:any;
  reason:any;
  LM113:any;
  LM114:any;
  pipe = new DatePipe('en-US');
  userSession:any;
  arrayList:any=[];
  LM119:any;
  pageLoading=true;
  employeeEmailData: any = [];
  employeeId: any;
  isEdit: boolean = true;
  isSave: boolean = false;
  enable: any = null;
  constructor( private router: Router, public dialog: MatDialog,private PR:PayrollService,private formBuilder: UntypedFormBuilder,
   ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    // this.getErrorMessages('LM113')
    // this.getErrorMessages('LM114')
    // this.getErrorMessages('LM119')
    this.getEmployeeAdvanceSalaryRequests(this.userSession.id);
    this.AdvanceSalaryRequestform = this.formBuilder.group(
      {
        requestAmount:[''],
      });

  }

    applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    //
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
/**
 * on click review and approve
 * **/

 /** */

 getEmployeeAdvanceSalaryRequests(empid:any){
  let data = {
    empid:empid
  }
   this.PR.getEmployeeAdvanceSalaryRequests(data).subscribe((result:any)=>{
      if(result.status){
        this.dataSource = result.data;
        }
      else
      {

      }
    })

 }
  submit(approveddata: any, status: any, approverId: any) {

   let data = {
    empid:approveddata.employee_id,
    easrd_id_value:approveddata.id,
    paid_amount_value:approveddata.request_amount,
    created_by_value:this.userSession.id,
    reject_reason_value:null,
    status_value:"Approved"

   }
this.PR.setEmployeeAdvanceSalaryApprovalByManager(data).subscribe((result:any)=>{
  if(result.status){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(["/Payroll/advanceSalaryApproval"]));
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: 'Advance salary request approved successfully.'
          }); 

  }
  else{

          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: 'Unable to approve advance salary request.'
          }); 

  }
})
    
  }

  Reject(data: any, status: any, rejectId: any) {
    this.titleName="Reject"
    this.openDialog(data);
  }
  canceledit(event: any, id: any) {
    this.enable = null;
    this.isEdit = true;
    this.isSave = false;
    this.ngOnInit();

  }

  save(event: any, id: any, approveddata: any) {
 
    let data = {
     empid:approveddata.employee_id,
     easrd_id_value:approveddata.id,
     paid_amount_value:this.AdvanceSalaryRequestform.controls.requestAmount.value,
     created_by_value:this.userSession.id,
     reject_reason_value:null,
     status_value:"Approved"
 
    }
    this.PR.setEmployeeAdvanceSalaryApprovalByManager(data).subscribe((result:any)=>{
      if(result.status){
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["/Payroll/advanceSalaryApproval"]));
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position:{top:`70px`},
                disableClose: true,
                data: 'Advance salary request approved successfully.'
              }); 
    
      }
      else{
    
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position:{top:`70px`},
                disableClose: true,
                data: 'Unable to approve advance salary request.'
              }); 
    
      }
    })
 
  }
  Edit(i:any){
    this.AdvanceSalaryRequestform.controls.requestAmount.setValue(i.request_amount);
    this.enable = i.id;
    this.isEdit = false;
    this.isSave = true;

  }
  openDialog(approveddata:any): void {
    const dialogRef = this.dialog.open(ReviewAndApprovalsComponent, {
      width: '600px',position:{top:`100px`},
      data: {name: this.titleName, reason: this.reason,}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result!=undefined ){
        if(result !==true){
          let data = {
            empid:approveddata.employee_id,
            easrd_id_value:approveddata.id,
            paid_amount_value:approveddata.request_amount,
            created_by_value:this.userSession.id,
            reject_reason_value:result.reason,
            status_value:"Rejected"
        
           }
           this.PR.setEmployeeAdvanceSalaryApprovalByManager(data).subscribe((result:any)=>{
             if(result.status){
               this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                           this.router.navigate(["/Payroll/advanceSalaryApproval"]));
                     let dialogRef = this.dialog.open(ReusableDialogComponent, {
                       position:{top:`70px`},
                       disableClose: true,
                       data: 'Advance salary request rejected successfully.'
                     }); 
           
             }
             else{
           
                     let dialogRef = this.dialog.open(ReusableDialogComponent, {
                       position:{top:`70px`},
                       disableClose: true,
                       data: 'Unable to rejected advance salary request.'
                     }); 
           
             }
           })
        
        // //  this.leaveApprove(leave, 'Rejected', null);
        // this.employeeId = leave.empid;
          // this.getEmployeeEmailData(leave ,'Rejected',null);
        }
      }
    });
  }

  getErrorMessages(errorCode:any) {
    // this.LM.getErrorMessages(errorCode,1,1).subscribe((result)=>{
    //   if(result.status && errorCode == 'LM113')
    //   {
    //     this.LM113 = result.data[0].errormessage
    //   }
    //   else if(result.status && errorCode == 'LM114')
    //   {
    //     this.LM114 = result.data[0].errormessage
    //   }
    //   else if(result.status && errorCode == 'LM119')
    //   {
    //     this.LM119 = result.data[0].errormessage
    //   }
    // })
  }

  getPageSizes(): number[] {
     
  var customPageSizeArray = [];
  if (this.dataSource.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource.data.length);
  return customPageSizeArray;
  }
//   getEmployeeEmailData(leave: any, status: any, approverId: any) {
//      this.employeeEmailData = [];
//      this.employeeId = leave.empid;
//     this.emsService.getEmployeeEmailDataByEmpid(this.employeeId)
//       .subscribe((res: any) => {
//         this.employeeEmailData = JSON.parse(res.data[0].jsonvalu)[0];
//         this.leaveApprove(leave,status,approverId)
//       })
    
// }
  
}

