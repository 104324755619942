import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Moment } from 'moment';

import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserData } from 'src/app/modules/attendance/models/EmployeeData';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { PayrollService } from 'src/app/modules/payroll/payroll.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import * as XLSX from 'xlsx';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-epf-reports',
  templateUrl: './epf-reports.component.html',
  styleUrls: ['./epf-reports.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EPFReportsComponent implements OnInit {
  dataSource: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table') table!: ElementRef;

  EpfReport: any = UntypedFormGroup;
  maxDateForEPF: any = new Date();
  minDateForEPF: any = new Date('2022-01-01');
  date = new UntypedFormControl(moment());
  messageflag: boolean = true;
  datadatas: any = [];
  message: any;
  year: any;
  userSession: any;
  pageLoading = true;
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>

  ) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    this.getEpfValuesForChallanForReportuser();
    datepicker.close();

  }
  months = [
    { id: 0, month: 'Jan' },
    { id: 1, month: 'Feb' },
    { id: 2, month: 'Mar' },
    { id: 3, month: 'Apr' },
    { id: 4, month: 'May' },
    { id: 5, month: 'Jun' },
    { id: 6, month: 'Jul' },
    { id: 7, month: 'Aug' },
    { id: 8, month: 'Sep' },
    { id: 9, month: 'Oct' },
    { id: 10, month: 'Nov' },
    { id: 11, month: 'Dec' },
  ];
  monthdata: any;
  dataSourceForEPF: MatTableDataSource<any> = <any>[];
  datadata: any;
  displayedColumnsforEPF: string[] = [
    'sno',
    'uan',
    'empname',
    'gross',
    'epf',
    'eps',
    'edil',
    'epsvalue',
    'ee',
    'er',
    'ncp',
    'refunds',
  ];
  constructor(
    public formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    public router: Router,
    private payrollService: PayrollService,
    private LM: LeavesService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.EpfReport = this.formBuilder.group({
      fromDateForEPF: [new Date()],
    });
    this.getEpfValuesForChallanForReportuser();
  }

  getEpfValuesForChallanForReportuser() {
    let data = {
      year_value: this.date.value._d.getFullYear(),
      month_value: this.date.value._d.getMonth() + 1,
      rempid: this.userSession.id,
    };
    this.dataSourceForEPF = new MatTableDataSource();
    this.payrollService
      .getEpfValuesForChallanForReportuser(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.dataSourceForEPF = new MatTableDataSource(result.data);
          this.dataSourceForEPF.paginator = this.paginator;

          this.dataSourceForEPF.sort = this.sort;
          this.pageLoading = false;
          // this.validateEpfChallanDownload()

          this.datadata = result.data;
          for (let i = 0; i < this.datadata.length; i++) {
            this.datadatas[i] =
              this.datadata[i].UAN +
              '#~#' +
              this.datadata[i].Employee_Name +
              '#~#' +
              this.datadata[i].gross_salary +
              '#~#' +
              this.datadata[i].employee_epf_value +
              '#~#' +
              this.datadata[i].eps_wage +
              '#~#' +
              this.datadata[i].edli_wage +
              '#~#' +
              this.datadata[i].employer_eps_value +
              '#~#' +
              this.datadata[i].epf_eps_difference +
              '#~#' +
              (this.datadata[i].ncp_days == null
                ? 0
                : this.datadata[i].ncp_days) +
              '#~#' +
              (this.datadata[i].refund_of_advance == null
                ? 0
                : this.datadata[i].refund_of_advance) +
              '\n';
          }
        }
      });
  }

  applyFilterForEPF(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceForEPF.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceForEPF.paginator) {
      this.dataSourceForEPF.paginator.firstPage();
    }
  }
  getPageSizesforEPF(): number[] {
    var customPageSizeArray = [];
    if (this.dataSourceForEPF.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSourceForEPF.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSourceForEPF.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSourceForEPF.data.length);
    return customPageSizeArray;
  }

  public exportTextEPF(): void {
    if (this.messageflag) {
      const data = this.datadatas.toString().replace(/\,/g, '');
      const blob = new Blob([data], { type: 'application/octet-stream' });
      let urls: any = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(blob)
      );
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'EPF.txt');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // window.open(url);
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }

  exportAsXLSXEPF() {
    if (this.messageflag) {
      this.year = this.EpfReport.controls.fromDateForEPF.value.getFullYear();
      for (let i = 0; i < this.months.length; i++) {
        if (
          this.EpfReport.controls.fromDateForEPF.value.getMonth() ==
          this.months[i].id
        ) {
          this.monthdata = this.months[i].month;
          break;
        }
      }
      // var ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet('Payroll_report_for_financeteam_');
      var ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
        document.getElementById('table')
      );
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'EPF_challan_report');

      /* save to file */
      // XLSX.writeFile('Payroll_report_for_financeteam_'+this.monthdata,'Payroll_report_for_financeteam_'+this.monthdata+'_'+this.year+'.xlsx')
      XLSX.writeFile(
        wb,
        'epf_report_for_financeteam_' +
        this.monthdata +
        '_' +
        this.year +
        '.xlsx'
      );
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: this.message,
      });
    }
  }
}
