<div fxLayout="column" fxFill class="custom-investment-of custom-pgr-height-auto first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <div fxLayout="row" fxFlex="50">
                <mat-card-title class="title">Pay Schedule</mat-card-title>
            </div>
            </mat-card-header>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card">
        <form [formGroup]="payGroupRequestForm" autocomplete="off" style="width:100%">
            <div fxLayout.lt-lg="column" fxLayoutGap="15px" style="padding-left: 15px;padding-right: 15px;">
    
                <div fxLayout="column" fxFlex class="custom-payroll-div">
                    <div>
                            <p>
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>Payment Frequency</mat-label>
                                    <input formControlName="paymentFrequency" [ngClass]="{'disable':true}"  [readonly]="true"  matInput placeholder="" >
                                    <!-- <mat-select formControlName="paymentFrequency" [disabled]="true">
                    
                                        <mat-option value="Monthly">Monthly</mat-option>
                                    </mat-select> -->
                                </mat-form-field>
                            </p>
                            <p style="display: -webkit-box" fxLayout="row" fxLayoutAlign="space-evenly center">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>Company Payroll Cycle</mat-label>
                                    <input color="warn"  [ngClass]="{'disable':true}" formControlName="companyPayrollCycle" [readonly]="true"  matInput placeholder="" >
                                    <!-- <mat-select formControlName="paymentFrequency" [disabled]="true">
                    
                                        <mat-option value="Monthly">Monthly</mat-option>
                                    </mat-select> -->
                                </mat-form-field>
                           
                                <!-- <label style="margin-right: auto">Company Payroll Cycle</label>
                                <mat-form-field appearance="outline" ><input type="text" matInput [readonly]="true"  class="form-control" formControlName="companyPayrollCycle" placeholder="" style="background-color: #e8e8e8;;"></mat-form-field> -->
                             </p>
                            <p>
                                <label><b>Calculate monthly salary based on*</b></label>
                            </p>
                            <p>
                                    <mat-radio-group formControlName="caluculateSalaryBasedON" style="display: grid;">
                                        <mat-radio-button class="mr-2"  color="primary" [disabled]="!isadd" *ngFor="let halfs of arrayValue;index as i;" (change)="status(halfs.Value,'monthly')"  [value]="halfs.Value"  >{{halfs.name}}</mat-radio-button>
                                    </mat-radio-group>
                            </p>
                            <!-- <p class="mob-pe">
                                <label>Pay your employee on*</label>
                             </p>
                             <p class="custom-radio-pay-empoyee">
                                <mat-radio-group formControlName="pay_employee" style="display: grid;">
                                    <mat-radio-button class="mr-2"  color="primary" *ngFor="let halfs of arrayPayValue;index as i;" (change)="status(halfs.Value,'salary')"  [value]="halfs.Value"  >{{halfs.name}}</mat-radio-button>
                                </mat-radio-group>
                        </p>
                        <p *ngIf="payGroupRequestForm.controls.pay_employee.value == '1'">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Select Day</mat-label>
                                <mat-select formControlName="days_range">
                                  <mat-option value="1">1</mat-option>
                                  <mat-option value="2">2</mat-option>
                                  <mat-option value="3">3</mat-option>
                                  <mat-option value="4">4</mat-option>
                                  <mat-option value="5">5</mat-option>
                                  <mat-option value="6">6</mat-option>
                                  <mat-option value="7">7</mat-option>
                                  <mat-option value="8">8</mat-option>
                                  <mat-option value="9">9</mat-option>
                                  <mat-option value="10">10</mat-option>
                                  <mat-option value="11">11</mat-option>
                                  <mat-option value="12">12</mat-option>
                                  <mat-option value="13">13</mat-option>
                                  <mat-option value="14">14</mat-option>
                                  <mat-option value="15">15</mat-option>
                                  <mat-option value="16">16</mat-option>
                                  <mat-option value="17">17</mat-option>
                                  <mat-option value="18">18</mat-option>
                                  <mat-option value="19">19</mat-option>
                                  <mat-option value="20">20</mat-option>
                              </mat-select>

                            </mat-form-field>
                         </p>
                         <p *ngIf="payGroupRequestForm.controls.pay_employee.value == '1'">
                            If Payday is non working day,Pay on

                         </p>
                         <p *ngIf="payGroupRequestForm.controls.pay_employee.value == '1'">
                            <mat-radio-group fxLayout="row" formControlName="nonWorkingDayPaymentOption" style="display: grid;">

                                <mat-radio-button  class="mr-2"  color="primary"   [value]="false"  >Previous Working day</mat-radio-button>
                                <mat-radio-button class="mr-2"  color="primary"  [value]="true"  >Next Working day</mat-radio-button>
                            </mat-radio-group>

                        </p> -->
                      </div>
                </div>

                <div fxLayout="column" fxFlex class="custom-payroll-div">
                    <div>
                        <p>
                            <label>Do you want to have same payroll and leave window ?</label>
                         </p>
                         <p>
                            <mat-radio-group fxLayout="row" formControlName="payroll_leavewindow" style="display: grid;">

                                <mat-radio-button  class="mr-2"  color="primary"   [value]="true" [disabled]="!isadd" >Yes</mat-radio-button>
                                <mat-radio-button class="mr-2"  color="primary"  [value]="false" [disabled]="!isadd"  >No</mat-radio-button>
                            </mat-radio-group>
                         </p>
                         <!-- <p style="display: -webkit-box" fxLayout="row" fxLayoutAlign="space-evenly center">
                           
                            <label style="margin-right: auto">Company Payroll Cycle</label>
                            <mat-form-field appearance="outline" ><input type="text" matInput [readonly]="true"  class="form-control" formControlName="companyPayrollCycle" placeholder="" style="background-color: #e8e8e8;;"></mat-form-field>
                         </p> -->
                         <p><label><b>Payroll Window</b></label></p>
                         <p fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" >
                            <mat-form-field appearance="outline" >
                                <mat-label>From Date</mat-label>
                                <input formControlName="payrollFromDate" [ngClass]="{'disable':true}"  [readonly]="true"  matInput placeholder="" >
                            </mat-form-field>
                            <mat-form-field appearance="outline" >
                                <mat-label>To Date</mat-label>
                                    <input formControlName="payrollToDate" [ngClass]="{'disable':true}"  [readonly]="true" matInput placeholder="" >
                            </mat-form-field>

                         </p>
                         <p *ngIf="payGroupRequestForm.controls.payroll_leavewindow.value"><label><b>Leave Window</b></label></p>
                        <p *ngIf="!payGroupRequestForm.controls.payroll_leavewindow.value"><label>Select Leave Window</label></p>
                        <p fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center"  *ngIf="payGroupRequestForm.controls.payroll_leavewindow.value">
                            <mat-form-field appearance="outline" >
                                <mat-label>From Date</mat-label>
                                <input formControlName="leaveWindowStartDate" [ngClass]="{'disable':true}"  [readonly]="true"  matInput placeholder="" >
                            </mat-form-field>
                            <mat-form-field appearance="outline" >
                                <mat-label>To Date</mat-label>
                                   <input formControlName="leaveWindowEndDate" [ngClass]="{'disable':true}"  [readonly]="true" matInput placeholder="" >
                            </mat-form-field>

                         </p>
                         <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around" *ngIf="!payGroupRequestForm.controls.payroll_leavewindow.value">
                            <mat-form-field appearance="outline" fxFlex.xs="100" >
                                <mat-label>Previous Month</mat-label>
                                
                                <mat-select   formControlName="leaveWindowStartDate" [disabled]="payGroupRequestForm.controls.payroll_leavewindow.value === false ? false:true || !isadd">
                                    <mat-option value="20">20</mat-option>
                                    <mat-option value="21">21</mat-option>
                                    <mat-option value="22">22</mat-option>
                                  <mat-option value="23">23</mat-option>
                                  <mat-option value="24">24</mat-option>
                                  <mat-option value="25">25</mat-option>
                                  <mat-option value="26">26</mat-option>
                                  <mat-option value="27">27</mat-option>
                                  <mat-option value="28">28</mat-option>
                                  <mat-option value="LAST_BUT_2_DAYS">Last But 2 Days</mat-option>
                                  <mat-option value="LAST_BUT_1_DAY">Last But 1 Day</mat-option>
                                  <mat-option value="LAST_DAY">Last Day</mat-option>
                                </mat-select>

                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex.xs="100">
                                <mat-label>Present Month</mat-label>
                                <mat-select   formControlName="leaveWindowEndDate" [disabled]="true">
                                  <mat-option value="19">19</mat-option>   
                                  <mat-option value="20">20</mat-option>
                                  <mat-option value="21">21</mat-option>
                                  <mat-option value="22">22</mat-option>
                                  <mat-option value="23">23</mat-option>
                                  <mat-option value="24">24</mat-option>
                                  <mat-option value="25">25</mat-option>
                                  <mat-option value="26">26</mat-option>
                                  <mat-option value="27">27</mat-option>
                                  <mat-option value="28">28</mat-option>
                                  <mat-option value="LAST_BUT_3_DAYS">Last But 3 Days</mat-option>
                                  <mat-option value="LAST_BUT_2_DAYS">Last But 2 Days</mat-option>
                                  <mat-option value="LAST_BUT_1_DAY">Last But 1 Day</mat-option>
                                  <mat-option value="LAST_DAY">Last Day</mat-option>
                                </mat-select>
                                <!-- <input formControlName="leaveWindowEndDate" [readonly]="true" matInput placeholder="" > -->
                            </mat-form-field>
                         </div>
       
                </div>

                </div>
            </div>

            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2" (click)="setPayGroup()" type="submit" *ngIf="isadd">
                  <span>Save</span>
                </button>
               <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                  <span *ngIf="isadd">Cancel</span>
                  <span *ngIf="!isadd">Close</span>
                </button>
              </div>


            <!-- <div class="row tab-lap-desk-show" style="margin-bottom: 51px !important;margin-top: -47px;">
                <div class="col-sm-6 text-right" style="padding:0px">
                <button  class="btn btn-primary mr-2 mb-2" (click)="setPayGroup()"   type="submit">
                    <span>Save</span>
                </button>
                </div>
                <div class="col-sm-6">
                <button  class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    <span >Cancel</span>
                </button>
                </div>
            </div>
            <div class="row mob-port-show mob-buttons" style="margin-bottom: 15px !important;">
                <div class="col-sm-6 text-center mob-buttons-padding" >
                <button  class="btn btn-primary mr-2 mb-2" (click)="setPayGroup()"   type="submit">
                    <span>Save</span>
                </button>
                </div>
                <div class="col-sm-6 text-center">
                <button  class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    <span >Cancel</span>
                </button>
                </div>
            </div> -->


        </form>
      </div>
    </mat-card>
</div>
