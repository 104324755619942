import {  Component,  OnInit, Inject, ViewChild,LOCALE_ID,ViewChildren,QueryList, ElementRef, AfterViewInit  } from '@angular/core';
import { MapInfoWindow, MapMarker,GoogleMap } from '@angular/google-maps';
import { CrmService } from '../../crm.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { Subscription } from 'rxjs';
import { FormControl  } from '@angular/forms';
import { EncryptPipe } from 'src/app/custom-directive/encrypt-decrypt.pipe';
import { Router } from '@angular/router';
import { LeavesService } from '../../../leaves/leaves.service';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import {MapService} from "src/app/modules/crm/map.service"; 
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { UntypedFormBuilder, UntypedFormControl,FormGroup,Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-crm-live-tracking',
  templateUrl: './crm-live-tracking.component.html',
  styleUrls: ['./crm-live-tracking.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CrmLiveTrackingComponent implements OnInit,AfterViewInit {
    
    mapUpdate!: any;
  isUpdateMap:any = true;
 @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
 @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap | undefined;
 directionsRenderer:any; //new google.maps.DirectionsRenderer();
 directionsService:any; // new google.maps.DirectionsService();
 directionsResults:any =[];
 @ViewChildren('directionsRender') directionsRenders : QueryList<ElementRef<google.maps.DirectionsRenderer>> | undefined;
 directionsRenderOption:any ={};
 rendererOptions = { map: '', draggable: true };
 imageDataUrl:any = {imageData:null,notes:null};
 route_name :any = '/Crm/CrmLiveTracking';
 searchForm:  any = FormGroup;
 worklocationDetails: any[] = [];
 managersDetails: any[] = [];
 locationIshide: boolean = false;
 locationIschecked: boolean = false;
 mgrIshide: boolean = false;
 mgrIschecked: boolean = false;
 selectedLocations: any = [];
 selectedManagers: any = [];
 selectedManager:any = 0;
 companyName:any;
 is_super_admin:any = false;

 imageurls:any =[];
 infoContent :any;
 attendanceid: any;
  markerPositions: any=[]; 
  markerSelected:any = null;
 
  employeemarkers:any=[];
  location_icon_val:any = 'location_off';
  location_icon_color:any = 'red';
  trackMinimumTime:any = 5;
  emp_id:any =0;
  lastTrackData:any = null;
 //  center: google.maps.LatLngLiteral | undefined;
 center:any={lat :17.457183152970547,lng: 78.37056237270387};
 employee_last_location:any={lat :17.457183152970547,lng: 78.37056237270387};
 selected_location:any;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
   // mapTypeControl: true,
    scrollwheel: true,  
   // streetViewControl: true,
   // fullscreenControl: true,
    zoom: 24,
    backgroundColor :'green'
    };
  locationButton:any = document.createElement("button");
  selectedEmployeeControl = new FormControl(0); 
  selectedDateControl = new FormControl(0); 
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  map:any;
  
  TODAY_STR = new Date().toISOString().replace(/T.*$/, '');
  todayDate: any;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    }, customButtons: {
      
    },
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height:'auto'
  };
  isview:boolean=true;
  pipe = new DatePipe('en-US');
  userSession: any;
  calendarApi: any;
  notificationsData: any = [];
  tobevisited:any = [];
  sampleElement: any;
  companyinfo:any=[];
  shiftData: any;
  isNewclient: boolean = false;
  clientForm: any;
  companyForm:any;
  companyDBName: any = environment.dbName;
  markerPosition:any ={};
  loginToken: any;
  demoStatusVal:any =  {};
  attendanceData:any;
  selectedWidth:any = 0;
  file:any;
  imageInfo:any=null;
  formData: FormData = new FormData();
  selectedWidth2:any;
  isRemoveImage:boolean=true;
  formattedDate:any;
  selectedEmployee:any=0;
  activitiesData:any = [];
  totalEmployees:any = [];
  punchInEmployees:any = [];
  punchOutEmployees:any = [];
  absentEmployees:any=[];
    //----------------------------------------------
  mobileQuery!: MediaQueryList;
  encriptPipe= new EncryptPipe();
  emp_batterylevel:any = 0; 
  show_emp_trace:any = false;
  trackdata:any;
  attd_trackdata:any;
  fetchedAddress:any;
  mapActivity:any;
  employeeData:any=[];
  displayImage:any;
  maxDate:any = new Date();
  minDate:any = new Date();
  today:any = new Date();
  selectedDate:any = new Date();
  checkImage:any = false;
  markerInfo:any = {empname: null, time: null, location: null };
  mapInitialized:any=false;
  originhole:any =null;
  labelOriginFilled:any = null;
  liveTrackingStatus:any = 'POINT';
  counter:any = 0;
  act_counter:any = 0;
  letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  colors = ['paleblue','pink','purple','yellow','orange','green','red','blue','brown','darkgreen'];
  //distanceFromMap :any = 0;
  constructor(@Inject(LOCALE_ID) private locale: string,private mapService:MapService,public formBuilder: UntypedFormBuilder, 
  private router: Router,private spinner: NgxSpinnerService,private LMS:LeavesService,private companyService: CompanySettingService,
    private attendanceService:AttendanceService,public reportsService: ReportsService,private cd: ChangeDetectorRef,
    private crm:CrmService,public dialog: MatDialog, public sanitizer: DomSanitizer) {
      this.companyName = sessionStorage.getItem("companyName");
          if(!this.mapInitialized){
            this.mapService.initMap().then(() => {
              // Code to execute after the map has been initialized
              this.mapInitialized = true;
              this.originhole = new google.maps.Point(12,15);
              this.labelOriginFilled = new google.maps.Point(12,9);
              this.directionsRenderer = new google.maps.DirectionsRenderer();
              this.directionsService = new google.maps.DirectionsService();
              this.directionsRenderOption = { draggable:false, suppressInfoWindows:false,
                suppressMarkers: true,
                markerOptions:{ draggable: false,  clickable:false, },
                polylineOptions:{ strokeColor: '#0000FF', strokeOpacity: 0, strokeWeight: 0.5,
                               icons: [{
                                icon: {  path: google.maps.SymbolPath.CIRCLE,  fillOpacity: 0.5, scale: 1.5 },
                                offset:'0%', repeat: '0.5px' }],}  };
            }).catch((error: any) => {
              // Handle any errors that occurred during map initialization
              console.error('Error initializing map:', error);
            });
          }

    }

  ngOnInit(): void {
    this.lastTrackData = null;
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      });
    this.today = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    this.formattedDate = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    this.selectedDate =  this.formattedDate;
    this.maxDate =  new Date();
   this.minDate =  new Date();
   this.minDate.setMonth(this.minDate.getDate() - 90);
   this.selectedDateControl.setValue(this.selectedDate );
    this.imageurls =[];
      this.markerPositions = [];
      this.directionsResults = [];
      this.activitiesData = []; 
      this.attd_trackdata = [];
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
   if (this.userSession.is_super_admin =="1"){
      this.is_super_admin = true;
      this.getWorkLocation();
    }
    else {
      this.selectedManager = this.userSession.id;
      this.getCrmEmployeesForManagers(this.userSession.id);
      this.getCrmSelectedEmployeeTrackLocations(this.selectedEmployee);
      this.getEmpLiveStatus();
    }
    this.selectedWidth = 0;
    this.selectedWidth2 = 100;
  
   // this.distanceFromMap = 0;
    this.selectedEmployeeControl.valueChanges.subscribe((newValue: any) => {
      this.OnValueChanged('employee');
    });
    this.selectedDateControl.valueChanges.subscribe((newValue: any) => {
      this.formattedDate = this.pipe.transform(new Date(newValue), 'yyyy-MM-dd');
      this.OnValueChanged('date');
    });
    this.mapUpdate = setInterval(()=>{ this.getEmpLiveStatus(); }, 60000); 
  }

 ngAfterViewInit(): void {
  this.cd.detectChanges(); 
 }
 ngOnDestroy(): void{
  clearInterval(this.mapUpdate);
 } 

  OnValueChanged(type:any){  
    this.counter = 0;
     
    this.lastTrackData = null;   
    this.directionsResults = [];
    this.imageurls =[];
    this.markerPositions = [];
    this.activitiesData = []; 
    this.attd_trackdata = [];
     this.getCrmEmployeesForManagers(this.selectedManager);
     
    if(this.selectedEmployee === 0){
      if(this.today === this.formattedDate){
        this.setcurrentlocation();
      }
    } else {
      this.getEmpLiveStatus();          
    }
  }
  getCrmselectedEmployeeDetails(emp:any = null){
    this.employeeData = {   empname:'', contactnumber:''   };
    if(emp && emp.empid){
      this.employeeData = {
        empname:emp.empname,
        contactnumber:emp.contactnumber
      };
    }
  }

  
  getMLabel(pinLabel:any,pinColor:any= null){
    return{ text: pinLabel,color: pinColor?pinColor:'white',fontSize: "12px"};
  };
  getEmployeeInformation(selectedEmployee:any){
    this.spinner.show();
    this.attendanceService.getEmployeeInformation(selectedEmployee).subscribe(async (info: any) => {
      this.spinner.hide();
      if (info.status && info.data.length != 0) {
        this.employeeData  = JSON.parse(info.data[0].json)[0];
        }
    })
  }
 
 getMIcon(pinColor:any,svgtype:any = null,activityFound:any = false){
  
  
  var userimage = {
    //url: 'assets/img/bikeGreen.ico',
    url: 'assets/img/GoogleMapsMarkers/'+this.colors[this.act_counter%10]+'_Marker'+this.letters[this.counter%26] +'.png',
    scaledSize: new google.maps.Size(20, 24),
    origin: new google.maps.Point(0, 0),
   // anchor: new google.maps.Point(12,17),
    scale: 2,
    fillOpacity: 0.6,
    strokeWeight: 2
  };
 
  if(!activityFound){
    if(this.selectedEmployee){
      this.counter = this.counter+1;
      return userimage;
    }
  } 
  else{
    this.act_counter = this.act_counter + 1;           
    this.counter = 0;       
  }
  // var hexcolors =[{ Green: '#00FF00'},{ Blue: '#0000FF'},{Orange: '#FFA500'},
   // {Red: '#FF0000'},{Purple: '#800080'}, {Pink: '#FFC0CB'},{Yellow: '#FFFF00'},  {Salmon : '#FA8072'},  {Teal: '#008080'},
   // {Brown: '#A52A2A'}, {Gray: '#808080'},  {Black: '#000000'},{ White: '#FFFFFF'},{ Cyan: '#00FFFF'},{Magenta: '#FF00FF'},{Lime: '#00FF00'}];

  var hexColor = pinColor == 'Green' ? '#00FF00':(pinColor == 'Orange' ? '#FFA500': '#0000FF');
  // for(var i=0;i<hexcolors.length;i++){
  //       var val =  JSON.parse(JSON.stringify(hexcolors[i]));
  //       for (const key in val) {
  //         if(key === pinColor)
  //             hexColor = val[key]
  //       }
  // } 
  svgtype =svgtype?svgtype: 'svgfilled';
  var svg =  "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z";
  var svghole = "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
  var originhole =this.originhole;
  var svgfilled = "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
  var labelOriginFilled =  this.labelOriginFilled;
  var symbolRectangle= "M -2,0 0,-2 2,0 0,2 z";
  var symbolCross=  "M -2,-2 2,2 M 2,-2 -2,2";
  var svgtypes = [{svghole:svghole}, {originhole:originhole}, {svgfilled:svgfilled},{svg:svg}, 
                  {Rectangle:symbolRectangle},{Cross:symbolCross}];
 
  var svg_selected = svgfilled;
  
  for(var i=0;i<svgtypes.length;i++){
      for (const [key, value] of Object.entries( svgtypes[i])) {
        if(key === svgtype) svg_selected = value;
      }
    }

  var markerImage = {   
      path: svg_selected,
      anchor: new google.maps.Point(12,17),
      fillOpacity: 0.6,
      fillColor:hexColor,
      strokeWeight: 2,
      strokeColor: "white",
      scale: 2,
      labelOrigin: labelOriginFilled,
      rotation: 0    
  };
   
  return markerImage;
 }

 
  getCrmSelectedEmployeeTrackLocations(selectedEmployee:any){
    this.emp_batterylevel = 0;
    this.show_emp_trace = true;
      let obj = {
        empid:selectedEmployee,  
        manager_id:this.selectedManager, 
        date_selected:this.formattedDate,
        is_employee_selected:selectedEmployee?1:0,
        map_distance:0,
        trace_status:(this.liveTrackingStatus == 'POINT')? 1:0
      }       
    //   if(this.selectedEmployee == 0){
    //     this.liveTrackingStatus = 'POINT';
    //  }
    //   if(this.formattedDate != this.today){
    //     this.liveTrackingStatus = 'POINT';
    //   }
    if(this.show_emp_trace){
      this.getCrmSelectedEmployeeTrackLocations_live(selectedEmployee,obj);
      // if(this.liveTrackingStatus == 'LIVE'){
      //   this.getCrmSelectedEmployeeTrackLocations_live(selectedEmployee,obj);
      // } 
      // else {
      //   this.getCrmSelectedEmployeeTrackLocations_point(selectedEmployee,obj);
      // }
      }
  }

  getCrmSelectedEmployeeTrackLocations_point(selectedEmployee:any,obj:any){
    this.trackdata = [];
    this.spinner.show();
    this.crm.getCrmSelectedEmployeeTrackLocations(obj).subscribe(async (info: any) => {           
      this.spinner.hide();
      if (info.status && info.data.length != 0) {
          this.trackdata = info.data;
          for(var i=0;i<this.trackdata.length;i++){
            this.setMarkerInfoWindow(this.trackdata[i]);
            if(selectedEmployee && this.trackdata[i+1]){
              if(this.trackdata[i].trace_type != 'out'){
                this.calculateAndDisplayRoute(this.trackdata[i],this.trackdata[i+1]);
              }                 
            }
            if(i == (this.trackdata.length - 1)){
              this.center ={lat:Number(this.trackdata[i].lat), lng: Number(this.trackdata[i].lng)}; 
            }
          }
                   
        }
          
    })
  }

  getCrmSelectedEmployeeTrackLocations_live(selectedEmployee:any,obj:any){
    this.trackdata = [];
    this.spinner.show();
    this.crm.getCrmSelectedEmployeeTrackLocations_live(obj).subscribe(async (info: any) => {           
      this.spinner.hide();
      if (info.status && info.data.length != 0) {
          this.trackdata = info.data;
          this.act_counter = 0;
          for(var i=0;i<this.trackdata.length;i++){
            this.setMarkerInfoWindow(this.trackdata[i]);
            if(selectedEmployee && this.trackdata[i+1]){
              if(this.trackdata[i].trace_type != 'out'){
                this.calculateAndDisplayRoute(this.trackdata[i],this.trackdata[i+1]);
              }                 
            }
            if(i == (this.trackdata.length - 1)){
              this.center ={lat:Number(this.trackdata[i].lat), lng: Number(this.trackdata[i].lng)}; 
            }
          }
                   
        }
          
    })
  }

  setMarkerInfoWindow(e:any) {   
      let markerinfoSets = [];
      let activityFound = false;
       var infoCount=(e.lat && Number(e.lat) && e.lng && Number(e.lng) )?1:0;
      if(infoCount)
        infoCount = infoCount + ((e.activities && e.activities.length)?e.activities.length:0) + ((e.attendance && e.attendance.length)?e.attendance.length:0);
      if(infoCount === 1){
        markerinfoSets.push({
            empname:e.empname,
            time: this.getFormattedTime(e.created_on),
            mapActivity:'',
            checkImage:false,
            infoContent:'',
            displayImage:null,
            client:null,
            location:e.location,
            markerColor:e.markerColor,
            imageDisplayOnMap:Number(e.imageDisplayOnMap),
            display: true            
            //client:e.client_name
        });
      }
      else{
        if(e.activities && e.activities.length){          
              e.activities.forEach(async (a:any)=>{
                if(a.activity_type === 'VISIT'){
                  activityFound = true;
                  a.markerPosition  = {
                    empname:a.empname?a.empname:e.empname,
                    time: this.getFormattedTime(a.created_on),
                    mapActivity:a.activity_type,
                    checkImage:!!(a.imageData && a.imageData.success),
                    infoContent:a.notes||'',
                    displayImage:(a.imageData && a.imageData.success) ? a.imageData.image: null,// (a.imageData && a.imageData.image && a.imageData.image.data) ? this.getImageBase64Format(a.imageData) : null,
                    client:a.client_name||'',
                    lat:a.lat?a.lat:e.lat,
                    lng:a.lng?a.lng:e.lng,
                    markerColor:a.markerColor,
                    imageDisplayOnMap:Number(e.imageDisplayOnMap),
                    location:e.location,
                    display: false
                }
                markerinfoSets.push(a.markerPosition);
                } else {
                  a.markerPosition  = {
                    empname:a.empname?a.empname:e.empname,
                    time: this.getFormattedTime(a.created_on),
                   // mapActivity:a.activity_type,
                   // checkImage:!!(a.imageData && a.imageData.success),
                  //  infoContent:a.notes||'',
                  //  displayImage:(a.imageData && a.imageData.success) ? a.imageData.image: null,// (a.imageData && a.imageData.image && a.imageData.image.data) ? this.getImageBase64Format(a.imageData) : null,
                  //  client:a.client_name||'',
                    lat:a.lat?a.lat:e.lat,
                    lng:a.lng?a.lng:e.lng,
                  //  markerColor:a.markerColor,
                  //  imageDisplayOnMap:Number(e.imageDisplayOnMap),
                    location:e.location,
                    display: false
                }
                markerinfoSets.push(a.markerPosition);
                }
                if(await this.check_object(this.activitiesData,'id',a.id)){
                  this.activitiesData.push(a);
                }
              });
        }
        if(e.attendance && e.attendance.length){  
          activityFound = true;
          e.attendance.forEach(async (b:any)=>{
              b.markerPosition ={
                  empname:b.empname?b.empname:e.empname,
                  time: this.getFormattedTime(b.punchtime),
                  mapActivity:b.attendance_type,
                  checkImage:!!(b.imageData && b.imageData.success),
                  infoContent:b.notes||'',
                  displayImage:(b.imageData && b.imageData.success) ? b.imageData.image: null,
                  client:null,
                  location:e.location,
                  markerColor:b.markerColor,
                  imageDisplayOnMap:Number(e.imageDisplayOnMap),
                  display: false
                }
              markerinfoSets.push(b.markerPosition);
              if(await this.check_object(this.attd_trackdata,'requestid',b.requestid)){
                this.attd_trackdata.push(b);
              }
          });
        }
      }
       this.check_marker_existed(e,infoCount,markerinfoSets,activityFound);
    //this.infoWindow? this.infoWindow.open(marker):null; 
  }
  async check_object(data:any,key:any,val:any){
    let not_found = true;
    if(data && data.length){
      data.forEach((d:any) => {
        if(d[key] == val){
          not_found = false;
        }
      });
    }
    return not_found;
  }

  check_marker_existed(e:any,infoCount:any,markerinfoSets:any,activityFound:any = false){
    let marker_existed = false;
    for(let i=0;i<this.markerPositions.length;i++){
        
        if(!marker_existed){
          if(this.markerPositions[i].id == e.id){
            marker_existed = true;
          }
          else if(Number(e.lat) === Number(this.markerPositions[i].lat) &&  Number(e.lng) === Number(this.markerPositions[i].lng) ){
            marker_existed = true;
            this.markerPositions[i].infoCount += infoCount;
            markerinfoSets.forEach((element:any) => {
              this.markerPositions[i].markerInfo.push(element);
            });
            
              if(activityFound){
                this.markerPositions[i].activityFound = true;                            
                this.markerPositions[i].icon = this.getMIcon(e.markerColor,e.markerType,activityFound);
                this.markerPositions[i].label =  this.selectedEmployee ? (activityFound ? this.getMLabel(this.letters[this.act_counter]) : ''):this.getMLabel(e.empname.charAt(0));
               
            }
          }
        }
      }
    if(!marker_existed){
      this.markerPositions.push({ 
        lat:Number(e.lat), 
        lng: Number(e.lng),
        label: this.selectedEmployee ? (activityFound ? this.getMLabel(this.letters[this.act_counter]) : ''):this.getMLabel(e.empname.charAt(0)),
        icon:this.getMIcon(e.markerColor,e.markerType,activityFound),
      //  activityType:e.trace_type,
        empname:e.empname,
        location:e.location,
        description:'',
        id:e.id,
        empid:e.empid,
        type:e.trace_type,
        imageData:null,
        infoCount:infoCount,
        markerInfo:markerinfoSets,
        mouseOver:false,
        activityFound:activityFound
      });
    }
  }

  changeDisplayStatus(markerInfo:any){
    this.markerSelected.markerInfo.forEach((e:any)=>{e.display = false;})
    markerInfo.display = !markerInfo.display;
  }

  getMarkerClass(markerInfo:any){
    if(markerInfo.display){
      return 'mark-class-display';
    }
    else{
      return 'mark-class-normal';
    }
  }
  
 
  setcurrentlocation(){
    navigator.geolocation.getCurrentPosition( (position) => {
      this.center ={lat : position.coords.latitude,lng: position.coords.longitude};
      this.crm.fetchMapLocationDetails(this.center ).subscribe((data) => {
        this.markerPositions.push({ lat: position.coords.latitude, lng: position.coords.longitude,icon:this.getMIcon('Green','svghole',2)
                                   ,time:this.getFormattedTime(new Date()), 
                                    activityType:'Current Position',location:data.display_name,id:0,empid:0,type:'currentpossition' });
        //this.fetchedAddress = data.display_name;
      });
    },(error) => console.log(error));
  }
async  getCrmAttendanceTrackingDetails(selectedEmployee:any =null) {
    this.spinner.show();
      var obj = {
      rmid:(selectedEmployee?null:this.userSession.id),      
      empid:(selectedEmployee?selectedEmployee:null),
      tracking_type:'ALL',
      date_selected:this.formattedDate
    }
    this.attd_trackdata =[];
    this.crm.getCrmAttendanceTrackingDetails(obj).subscribe(async (info: any) => {
      this.spinner.hide();
     
      if (info.status && info.data.length != 0) {
        let dataCount = info.data.length??0;
        let scount = 1;
        this.attd_trackdata = info.data;
        this.attd_trackdata.forEach(async (e: any) => { 
          scount = scount+1;
          this.getImageData(e.attd_detail_id,e.empid,'attendance');
          this.markerPositions.push({ lat:Number(e.lat), lng: Number(e.lng),label:this.getMLabel(e.empname.charAt(0)),icon:this.getMIcon('Green','svgfilled'),
                                    activityType:' Punch'+(e.category?e.category:''),description:e.description,
                                    empname:e.empname,time:this.getFormattedTime(e.punchtime),location:e.location,
                                     id:e.attd_detail_id,empid:e.empid,type:'attendance' });
           
         
          })          
        }
    })
     
  }
  
  getActivitiesWithTrackingDetails(selectedEmployee:any){
    this.activitiesData=[];
    this.crm.getCrmActivitiesWithTrackingDetails({manager_id:null,empid:selectedEmployee,client_id:null,date_selected:this.formattedDate}).subscribe((results:any)=>{
      if(results.data && results.data.length){
        let dataCount = results.data.length??0;
        let scount = 1;
        results.data.forEach((e:any) => {
          scount = scount + 1;
            if(e.created_by == selectedEmployee){
              if(e.activity_type === 'VISIT'){
                this.getImageData(e.id,e.created_by,'visit');
              if(e.lat && e.lng && Number(e.lat) && Number(e.lng))
                e.location_icon_val = 'location_on';
              else
                e.location_icon_val = 'location_off';
              
              this.markerPositions.push({ lat:Number(e.lat), lng: Number(e.lng),label:this.getMLabel(e.empname.charAt(0)),icon:this.getMIcon('blue','svgfilled'),
                client:e.client_name, empname:e.empname,time:this.getFormattedTime(e.created_on),location:e.location,
                activityType:'VISIT' ,description:e.notes,id:e.id,empid:e.created_by,type:'visit'});
            }
            this.activitiesData.push(e);
          }
           
        });
         
      }
    
      
  })
}
   
  getCrmEmployeesForManagers(selectedManager:any = null){
    this.punchInEmployees = [];
    this.punchOutEmployees = [];
    this.totalEmployees = [];
    this.attendanceData = [];
    
    this.employeeData = {};
    if(selectedManager){
    this.crm.getCrmEmployeesForManagers({rm_id:selectedManager||0,selected_date:this.formattedDate}).subscribe((results:any)=>{
     this.getCrmSelectedEmployeeTrackLocations(this.selectedEmployee);
     //this.getEmpLiveStatus();
      this.attendanceData= (results && results.data)? results.data:[];

      if(this.attendanceData && this.attendanceData.length){
        
        this.attendanceData.forEach((a:any)=>{
          this.totalEmployees.push(a);
          if(this.selectedEmployee === a.empid){
            this.employeeData = {
              empname:a.empname,
              contactnumber:a.contactnumber
            } ;
          }
          if(a.punchouttime)
            this.punchOutEmployees.push(a); //= this.punchOutEmployees + 1;
          else if(a.punchintime)
            this.punchInEmployees.push(a); // = this.punchInEmployees + 1;
          else if(!a.punchintime && !a.punchouttime) 
            this.absentEmployees.push(a); // = this.absentEmployees + 1;
        });
      }
      this.attendanceData.unshift({empid:0,empname:'All ('+this.totalEmployees.length+')'});
      
    })
  }
  else{

  }
  }
 
  
  openInfoWindow(marker: MapMarker, markerPosition: any) {  
    this.markerSelected = markerPosition;
    if(this.markerSelected){
      this.markerSelected.mouseOver = true;
    } 
    this.infoWindow? this.infoWindow.open(marker):null; 
  }

  closeMouseOver(markerPosition:any){
    markerPosition.mouseOver = false;
    this.infoContent = null;
    this.markerSelected = null;
    this.infoWindow?.close();
  }
  
  selectClient(event:any,activity:any){
    let clientId=this.encriptPipe.transform(activity.client_id.toString());
    this.router.navigate(["/Crm/CrmClientEditAndUpdate",{clientId}])
  }
  checkActivityDetails(activity:any){
    this.activitiesData.forEach((a:any)=>{
      if(a.requestid !== activity.requestid){
        a.display = false;
      }   
    });
      if(activity && activity.display){
        activity.display = false;
      }
      else  activity.display = true;
  }
  getbackgroundColor(activity:any) {
    if(activity.nextFollowUpDate)
      return (new Date(activity.nextFollowUpDate) >  new Date()) ?  'aliceblue':'mistyrose' ;
    else 
      return (new Date(activity.created_on) >  new Date()) ?  'aliceblue':'mistyrose' ;
  }
  cancel() {
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    // this.router.navigate(["/crm/CrmLiveTracking"]));
  }
  closeInfo(): void {
    this.infoContent = null;
  }

  getImageDisplay(requestId:any,empid:any,type:any){
    var data = ``;
    this.imageurls.forEach((e:any)=>{
      if(e.requestId === requestId && e.empid === empid && e.type === type){         
        data = `${e.img}`;
      }
    });
  
    return data;
  }

  checkImageDisplay(requestId:any,empid:any,type:any){
    let checkAvailable = false;
    this.imageurls.forEach((e:any)=>{
      if(e.requestId === requestId && e.empid === empid && e.type === type){         
        if(e.img)
        checkAvailable= true;
      }
    });
    return checkAvailable;
  }
 
  getImageData(requestId:any,empid:any,category:any){
    let filecategory = '';
    if(category === 'attendance')
    filecategory = 'CRM_ATTENDANCE';
    if(category === 'visit')
    filecategory = 'CRM_VISITS';
    if(category === 'client')
    filecategory = 'CRM_CLIENTS';
    let info = {
      'employeeId': empid,
      "candidateId": null,
      "moduleId": 12,
      "filecategory":filecategory,
      "requestId": requestId,
      'status': 'Submitted'
    }
    this.crm.getCrmFilesMaster(info).subscribe((result) => {
        if(result && result.status && result.data[0]){
           
        this.imageInfo =JSON.stringify(result.data[0]);
        result.data[0].employeeId=0;
        let info = result.data[0]
        this.LMS.getProfileImage(info).subscribe((imageData) => {
          if(imageData.success){
            let TYPED_ARRAY = new Uint8Array(imageData.image.data);
            const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
              return data + String.fromCharCode(byte);
            }, '');
            let base64String= btoa(STRING_CHAR)
            this.imageurls.push({requestId:requestId,empid:empid,type:category,img:'data:image/png;base64,'+base64String});
          }
        })
    }
  })
}
getImageBase64Format(imageData:any){
  if(imageData){
    let TYPED_ARRAY = new Uint8Array(imageData.image.data);
    const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
      return data + String.fromCharCode(byte);
    }, '');
    let base64String= btoa(STRING_CHAR);
    return 'data:image/png;base64,'+base64String;
  }
  else return null;
}

getFormattedTime(date:any = null){
  if(!date) 
  return ''; //date = new Date();
  else date = new Date(date);
  return date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
}


getDataDiff(startDate:any, endDate:any) {
  var diff = endDate.getTime() - startDate.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
  var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
 // var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
  return  minutes;
};

getLocationIconColor(){
  let minutes = 0,days=0,hours=0,trackTime = '',color = 'red';
  this.location_icon_val = 'location_off';
  this.location_icon_color = 'red';
  // #FFAC1C -- orange,  #00FF40 -- green
      if(this.lastTrackData){
          trackTime =  this.lastTrackData.updated_on?this.lastTrackData.updated_on:(this.lastTrackData.created_on?this.lastTrackData.created_on:'');
          this.lastTrackData.trackTime = this.getFormattedTime(trackTime);
          this.employee_last_location.lat = this.lastTrackData.lat?this.lastTrackData.lat:0;
          this.employee_last_location.lng = this.lastTrackData.lng?this.lastTrackData.lng:0;
          if(trackTime != ''){
            var diff = new Date().getTime() - new Date(trackTime).getTime();
            days = Math.floor(diff / (60 * 60 * 24 * 1000));     
            hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
            minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));

            if(minutes == 0 || (minutes && (minutes < this.trackMinimumTime))){
              this.location_icon_val = 'location_on';
              this.location_icon_color = minutes < 1 ? '#00FF40':'#FFAC1C' ;
            }
          }
      }
  }
   
  fetchLocationOnMap(){
    if(this.employee_last_location.lat && this.employee_last_location.lng){
      this.center ={lat : Number(this.employee_last_location.lat),lng: Number(this.employee_last_location.lng)};
          
  }
}
 
fetchActivityLocationOnMap(activity:any){
  if(activity.lat && activity.lng){
    this.center ={lat : Number(activity.lat),lng: Number(activity.lng)};
  }
}

calculateAndDisplayRoute(track1:any,track2:any) {
  
  const selectedModes  = ['DRIVING','WALKING','BICYCLING','TRANSIT'];
 
  var getcolors = ['#9966ff','#00FF00','#FFFF00','#FFA500','#800080','#FFC0CB','#A52A2A','#808080','#00000','#00FFFF','#FF00FF','#00FF00','#FA8072','#008080'];
  let renderCount = track1.id % getcolors.length;
  //const selectedMode = selectedModes[1]; // 'DRIVING';
  var travelModes = [
            google.maps.TravelMode['DRIVING'], google.maps.TravelMode['WALKING'],
            google.maps.TravelMode['BICYCLING'], google.maps.TravelMode['TRANSIT']
            ];
  let origin = { lat: Number(track1.lat), lng: Number(track1.lng) };
  let destination = { lat: Number(track2.lat), lng: Number(track2.lng) };
  var selectedMode = travelModes[0];
  //if((origin.lat - destination.lat >= -0.01 && origin.lat - destination.lat <= 0.01) && (origin.lng - destination.lng >= -0.01 && origin.lng - destination.lng <= 0.01) )
  //  selectedMode = travelModes[1];
  // if(track1.details){
  //   let details = JSON.parse(track1.details);
  //   let response = JSON.parse(details.response);
  //   console.log(response);
  //   this.directionsResults.push(response);
  //   this.directionsRenderer.setDirections(response);
  // }
   {
  this.directionsService.route({origin: origin,destination: destination,  
      // Note that Javascript allows us to access the constant using square brackets and a string value as its "property."
      travelMode: selectedMode,
      //optimizeWaypoints: true,
       avoidFerries: true,avoidHighways: false,avoidTolls: true}).then((response:any) => {
     if(response.geocoded_waypoints[0].place_id != response.geocoded_waypoints[1].place_id){     
      // this.directionsRenderOption.polylineOptions.strokeColor = getcolors[0];
    //  this.distanceFromMap = this.distanceFromMap + parseFloat(response.routes[0].legs[0].distance.value);
        this.directionsResults.push(response);
        this.directionsRenderer.setDirections(response);
      
     }
    }).catch((e:any) => console.log("Directions request failed due to " + e));
  }
}
 

getLocationIconColorForActivity(activity:any){
  if(activity.lat && activity.lng){
    activity.location_icon_val = 'location_on';
  return 'green';
  }
  else{
    activity.location_icon_val = 'location_off';
    return  'red';
  }
}
  
computeTotalDistance(result: google.maps.DirectionsResult) {
    let total = 0;
    const myroute = result.routes[0];

    if (!myroute) {
      return;
    }

    for (let i = 0; i < myroute.legs.length; i++) {
      total += myroute.legs[i]!.distance!.value;
    }

    //total = total / 1000;
    return total;
    //(document.getElementById("total") as HTMLElement).innerHTML = total + " km";
}

computeTotalAverageTime(result: google.maps.DirectionsResult) {
  let total = 0;
  const myroute = result.routes[0];

  if (!myroute) {
    return;
  }

  for (let i = 0; i < myroute.legs.length; i++) {
    total += myroute.legs[i]!.duration!.value;
  }

  //total = total / 60;
  return total;
  //(document.getElementById("total") as HTMLElement).innerHTML = total + " km";
}

async  crmHaversineDistanceCalculation(location1:any,location2:any) {
  // distance between latitudes
  // and longitudes
  let lat1 =location1.lat; 
  let lon1 =location1.lng;
  let lat2 =location2.lat;
  let lon2 =location2.lng;
  
  let dLat = (lat2 - lat1) * Math.PI / 180.0;
  let dLon = (lon2 - lon1) * Math.PI / 180.0;           
  // convert to radiansa
  lat1 = (lat1) * Math.PI / 180.0;
  lat2 = (lat2) * Math.PI / 180.0;         
  // apply formulae
  let a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  let rad = 6371;
  let c = 2 * Math.asin(Math.sqrt(a));
  return rad * c;         
} 

getFormattedDateTime(date:any = null){
  if(!date) 
  date = new Date();
  else date = new Date(date);
  return this.pipe.transform(date, 'yyyy-MM-dd') +' '+date.toLocaleTimeString(this.locale, {hour12: true, hour: '2-digit',minute: '2-digit' });
}
 
onchangeManager(value:any) {
  this.selectedManager=value.manager_emp_id;
  this.selectedEmployee = 0; 
  //this.getCrmEmployeesForManagers(this.selectedManager);
 this.OnValueChanged(null);
    // let location = this.searchForm.controls.managers.value;
    // location.forEach((e: any) => {
    //   this.selectedManagers.push(Number(e.manager_emp_id));
    // });
    // this.getEmployeelistForSuperAdmin();
   
}
// managerDeselectAll(select: MatSelect) {
//   this.mgrIshide = false;
//   this.mgrIschecked = false
//   this.selectedManagers = [];
//   select.value = [];
//   this.searchForm.controls.managers.setValue('')
//   this.selectedManagers=[];
// }
onchangeLocation(value:any) {
  this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
 this.getManagersList();
}
getManagersList() {
  this.managersDetails =[];
  let data={
    location_id :JSON.stringify(this.selectedLocations),
    companyName : this.companyName
  }
  this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
        if (res.status && res.data.length > 0) {
      this.managersDetails = res.data;
     }
  });
  if(this.managersDetails && this.managersDetails.length){
    this.selectedManager = this.managersDetails[0].manager_emp_id;
    this.searchForm.controls.managers.setValue(this.managersDetails[0].manager_emp_id);
    this.getCrmEmployeesForManagers(this.selectedManager);
  }
  else {
    this.selectedManager = 0;
    this.getCrmEmployeesForManagers();
  }
}

locationDeselectAll(select: MatSelect) {
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('');
  this.managersDetails =[];
  this.selectedManager = 0;
  this.getCrmEmployeesForManagers();
  this.selectedEmployee = 0;
}

locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  // if(array.length >1){
  //   array.forEach((a:any)=>{
  //     a.dispay_name = a.location;
  //   });
  // }
  this.searchForm.controls.companylocation.setValue(array);
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

getWorkLocation() {
    this.managersDetails =[];
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      // this.worklocationDetails.forEach((w:any)=>{
      //   w.dispay_name = w.cityname+'-'+w.location;
      // });
  });
} 

getEmpLiveStatus(){
  let is_live = false;
  let activeChild = sessionStorage.getItem('activeChild') ? JSON.parse(sessionStorage.getItem('activeChild') ?? ''):'';
   if(activeChild && activeChild.routename && activeChild.routename == this.route_name && this.selectedEmployee && this.today == this.formattedDate){
    is_live = true;    
  } else {    
    clearInterval(this.mapUpdate);
  }
  if(is_live){
    this.lastTrackData = null;
    this.crm.getCrmEmpLiveStatus({empid:this.selectedEmployee}).subscribe(async (info: any) => {
      if (info.status && info.data[0]) {
        this.lastTrackData = info.data[0];
        if( this.lastTrackData  && this.lastTrackData.battery_level){
          this.emp_batterylevel =  Number(this.lastTrackData.battery_level).toFixed(0);
        }
        this.getLocationIconColor();
        }          
    })
    //this.getCrmSelectedEmployeeTrackLocations(this.selectedEmployee);
  }
   
}

getStatus(type:any){
  let status = false;
  if(this.liveTrackingStatus === type)
    status = true;
  return status;

}
getTrackData(tracktype:any){
    this.liveTrackingStatus = tracktype;
    this.OnValueChanged(null);
  }

  displayMarkerInGoogle(markerSelected:any) {
    let url = `https://maps.google.com/?q=${Number(markerSelected.lat)},${Number(markerSelected.lng)}`;
    window.open(url, '_blank');
  }

}