import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatRadioChange } from '@angular/material/radio';


export interface PeriodicElement {
  id: number;
  name: string;
  mobile: number;
  email: string;
  address: string;
  type: string;
}

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {
  assetListForm!: UntypedFormGroup;
  hardWaredisplayedColumns = ['sno','cname','mobile','email','type','date','tyofasset','status','action'];
  softwaredisplayedColumns = ['sno','cname','mobile','email','type','purchased','tyofasset','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(Sample_Data);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  isRequestView = false;
  isEditView=false;
  uniqueId: any = '';
  messagesDataList: any = [];
  requiredField: any;
  requiredOption: any;
  dataSave: any;
  dataNotSave: any;
  ///////////
  isAdd:boolean=false;
  isdata: boolean = true;
  dataView: boolean = false;
  assetCategoryList:any = ['Software','Hardware','Network','Stationary']
  assetTypeList:any = ['Laptop','Router','Software','Stationary']
  assetNameList: any = ['Dell', 'Hathway', 'Office 365', 'Mouse', 'Keyboard']
  isWarranty: any = [{ Value: 'Yes', name: 'Yes ' }, { Value: 'No', name: 'No' }];
  typeOfAssetList: any = ['Assignable', 'Non-assignable']
  assignableList: any = ['Assigned', 'Free', 'Damage', 'Retired']
  expWarranty: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder,private router: Router,private companyServices: CompanySettingService,) {
  }

  ngOnInit(): void {

  this.dataSource.paginator = this.paginator;
    this.assetListForm = this.formBuilder.group(
      {
        typeOfList: [false],
        assetId: [, Validators.required],
        assetCategory: [, Validators.required],
        assetName: [, Validators.required],
        assetType: [, Validators.required],
        vendorId: [, Validators.required],
        serialNo: [, Validators.required],
        purchaseDate: [, Validators.required],
        lifeCycleExpDate: [, Validators.required],
        warranty: [, Validators.required],
        typeOfAsset: [, Validators.required],
        warExpDate: [, Validators.required],
        assignable: [],
        //////// software
        softVersion: [,Validators.required],
        totalPurchased: [],
        productKey: [],
        licenseKey: [],
      });
      this.assetListForm.get('warranty')?.valueChanges.subscribe(selectedValue => {
        if (selectedValue == "Yes") {
          this.expWarranty = true;
        } else {
          this.expWarranty = false;
        }
         
      })
  }

ngAfterViewInit() {
     this.dataSource.paginator = this.paginator;
}
  radioChange(event: MatRadioChange) {
   this.dataView = event.value;
    if (event.value == false) {
      this.dataView = false;
    } else {
      this.dataView = true;
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  saveRequest() {
  }
  resetform() {

  }

  editRequest(event: any) {
  }
  updateRequest(){

  }
  deleteRequest(event: any) {
  }

  requestView(event: any) {
  }
  Add(){
    //this.assetListForm.controls.companyName.setValue('');
    this.isAdd = true;
    this.isdata = false;
  }
  cancel(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Asset/AssetList"]));
  }
}

const Sample_Data: PeriodicElement[] = [
  {id: 1, name: 'Sreeb Tech', mobile: 9666313001, email: 'sreeb@gmail.com',address:'Hitech city',type:'Software'},
  {id: 2, name: 'Sanela', mobile: 966666666, email: 'sanela@gmail.com',address:'Kondapur',type:'Hardware'},
  {id: 3, name: 'Sriram Hardwaress', mobile: 898989898, email: 'ram@gmail.com',address:'Madhapor',type:'Network'},
  {id: 4, name: 'ABC Tech', mobile: 568975698, email: 'abc@gmail.com',address:'Gachibowli',type:'Stationary'},
  {id: 5, name: 'Soft Soluntions', mobile: 9638527415, email: 'soft@gmail.com',address:'Gachibowli',type:'Software'},
  {id: 6, name: 'Dell ', mobile: 1478963255, email: 'dell@gmail.com',address:'Gachibowli',type:'Software'},
  {id: 7, name: 'Tech Mahindra', mobile: 1234569874, email: 'techm@gmail.com',address:'Hitech city',type:'Hardware'},
  {id: 8, name: 'Wipro', mobile: 8745693215, email: 'wipro@gmail.com',address:'Hyderabad',type:'Hardware'},
  {id: 9, name: 'Accenture', mobile: 7896541236, email: 'accenture@gmail.com',address:'Kondapur',type:'Network'},
  {id: 10, name: 'TATA Consultency', mobile: 6589471230, email: 'tcs@gmail.com',address:'Kondapur',type:'Hardware'},
  {id: 11, name: 'Cognizent', mobile: 3269857410, email: 'cognizent@gmail.com',address:'Hyderabad',type:'Network'},
];