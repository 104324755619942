

<!--<div class="example-container" class="h-100">-->
  <mat-toolbar>
    <div class="main-header">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" [class.disabled]="isPopupOpen()" >
    
      <div class="logo-header" style="padding-top: 5px;">
        <div class="custom-mob-icons" style="margin-top: 1% ">
          <a *ngIf="isSuperAdmin && !isProductAdmin" href="/#/main/client-superadmin-dashboard" (click)="onClickMainDashboard()" class="logo">
            <img [src]="pic" style="width: 25px;" alt="" class="logo-img mr-2">{{companyName}}</a>

            <a *ngIf="!isSuperAdmin && !isProductAdmin" href="/#/main/MainDashboard" (click)="onClickMainDashboard()" class="logo">
              <img [src]="pic" style="width: 25px;" alt="" class="logo-img mr-2">{{companyName}}</a>

              <a *ngIf="!isSuperAdmin && isProductAdmin" href="/#/Admin/product-admin-dashboard" (click)="onClickMainDashboard()" class="logo">
                <img [src]="pic" style="width: 25px;" alt="" class="logo-img mr-2">{{companyName}}</a>

          <!--<button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>-->
        </div>
        
        <div class="navbar-nav topbar-nav ml-md-auto mobile-view">
          <div>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle profile-pic"  data-toggle="dropdown" href="#"
              aria-expanded="false">
               <img *ngIf="!imageurls" src="assets/img/profile.jpg" alt="user-img" width="30"
               class="img-circle"><img *ngIf="imageurls" [src]="imageurls"  width="30"
               class="img-circle">
               <span>{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}
                {{this.usersession.lastname}}</span></a>
              <ul class="dropdown-menu dropdown-user">
                <li>
                  <div class="user-box">
                    <div *ngIf="!imageurls" class="u-img"><img src="assets/img/profile.jpg" alt="user" width="30" class="img-circle"></div>
                    <div *ngIf="imageurls" class="u-img" ><img [src]="imageurls"  width="30" class="img-circle"></div>
                    <div class="u-text">
                      <h4 class="text-overflow">{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}
                        {{this.usersession.lastname}}
                      </h4>
                      <p class="text-muted">{{this.usersession.officeemail}}</p>
                      <a (click)="editProfile()" [routerLink]="[]">Edit Profile</a>
                    </div>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="changePassword()"  ><i class="ti-settings"></i> Change Password</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
              </ul>
             </li>
           </div>
        </div>

      </div>

  <div fxLayout="row">

    <!-- profile -->
    <nav class="navbar navbar-header navbar-expand-lg tab-view">
      <!-- super dashboard -->
      <div *ngIf="isSuperAdmin" style="margin-top: 11px;">
        <a href="/#/main/MainDashboard" (click)="onClickSuperAdminDashboard()">
          <mat-icon style="font-size: 28px;
          color:#28acaf;" title="Dashboard">dashboard</mat-icon>
        </a>
        </div>
<!-- help desk -->
<div style="padding-left: 5px;">
  <button mat-icon-button [matMenuTriggerFor]="menu" style="padding-top: 2px;" title="Help" >
    <img src="assets/images/icons/help-desk.png" alt="user-img" width="25" class="img-circle">
  </button>

  <mat-menu #menu="matMenu">
    <span style="text-align: center;font-size: medium; font-weight:800;"> <p style="margin-bottom: 0px;" >Help</p></span>
   
    <button mat-menu-item >
      <img src="assets/images/icons/img_5.png" alt="user-img" width="25" class="img-circle">
      <span style="padding: 5px 5px 5px 5px;"> <a style="color: black;" target="_blank" [href]=" 'https://help.spryple.com ' " >User Guide</a></span>
     
    </button>

    <button mat-menu-item (click)="onClickFAQ()">
      <img src="assets/images/icons/img_4.png" alt="user-img" width="25" class="img-circle">
      <span style="padding: 5px 5px 5px 5px;"> <a href="/#/main/FAQ" style="color: black;">FAQ's</a></span>
    </button>

    <button mat-menu-item>
      <img src="assets/images/icons/img_7.png" alt="user-img" width="25" class="img-circle">
      <span style="padding: 5px 5px 5px 5px;"> <a target="_blank" [href]=" 'https://www.youtube.com/@SprypleHCM' " style="color: black;">Video Library</a></span>
    </button>

    <!-- <button mat-menu-item (click)="onClickTicket()">
      <img src="assets/images/icons/img_4.png" alt="user-img" width="25" class="img-circle">
      <span style="padding: 5px 5px 5px 5px;"> <a href="/#/main/raise_ticket" style="color: black;">Raise Ticket</a></span>
    </button> -->

  </mat-menu>

</div>

        <!-- user profile  -->
      <div class="container-fluid">
        <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">

          <li class="nav-item dropdown">
            <a class="dropdown-toggle profile-pic"  data-toggle="dropdown" href="#"
            aria-expanded="false"> <img *ngIf="!imageurls" src="assets/img/profile.jpg"
            alt="user-img" width="30" class="img-circle">
            <img *ngIf="imageurls" [src]="imageurls"  width="30"
            class="img-circle">
            <span >{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}
              {{this.usersession.lastname}}
            </span>
          </a>
            <ul class="dropdown-menu dropdown-user">
              <li>
                <div class="user-box">
                  <div *ngIf="!imageurls" class="u-img"><img src="assets/img/profile.jpg" alt="user" width="30" class="img-circle"></div>
                  <div *ngIf="imageurls" class="u-img" ><img [src]="imageurls"  width="30" class="img-circle"></div>
                  <div class="u-text">
                    <h4 class="text-overflow">{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}
                      {{this.usersession.lastname}}
                    </h4>
                    <p class="text-muted">{{this.usersession.officeemail}}</p>
                    <a (click)="editProfile()" [routerLink]="[]">Edit Profile</a>
                  </div>
                </div>
              </li>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="changePassword()"  ><i class="ti-settings"></i> Change Password</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    </div>
    </div>

      <div class="hd-bg" *ngIf="flag">
      <span >
        <!-- Welcome {{selectedModule?'to':''}} {{selectedModule?selectedModule:'Spryple'}} -->
        Welcome to {{selectedModule?selectedModule:companyName}}
      </span>
    </div>


    </div>
 </mat-toolbar>
<ngx-spinner size="medium" type='ball-fussion'></ngx-spinner>


