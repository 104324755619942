import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit {
  constructor() { }
  gridColumns = 4;

  toggleGridColumns() {
    this.gridColumns = this.gridColumns === 3 ? 4 : 3;
  }
moduleList:any = [ 
  {id:1, name:'Admin'},
  {id:2, name:'Attendance & Leave Management'},
  {id:3, name:'Employee Management'},
  {id:4, name:'Payroll Management'},
  {id:5, name:'Exit Management'},
  {id:6, name:'Spryple HCM'},
  {id:7, name:'Spryple Lite'},
  {id:8, name:'Subscription'},
]

panelOpenState = false;
  ngOnInit(): void {
  }

}
