<div fxLayout="column" fxFill class="custom-investment-of first-col">
  <mat-card fxFill>
    <mat-card-header class="headerbox">
      <mat-card-title class="title"> Reassign Pay Group</mat-card-title>
    </mat-card-header>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card">

      <div fxLayout="column" fxFlex="auto" class="custom-payroll-div">
        <form [formGroup]="ReAssignpaygroupform" autocomplete="off">
          <div class="form-row" fxLayout.lt-lg="column" style="padding-left: 1%;">
            <div fxLayout="row" fxLayout.xs="column">

              <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline" fxFlex="50">
                <mat-label>Employee Name</mat-label>
                <mat-select formControlName="empName" required [tabIndex]="1">
                  <input matInput [formControl]="searchControl" type="text" placeholder="  Search" class="search-align">
                  <ng-container>
                    <div *ngFor="let a of filteredEmpData">
                      <mat-option [value]="a">{{a.empname}}-{{a.empid}}</mat-option>
                    </div>
                  </ng-container>
                </mat-select>
                <mat-error class="con-error" *ngIf="ReAssignpaygroupform.controls.empName.errors?.required">
                  {{PR30}}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex.xs="100" class="mx-2 my-2" appearance="outline" fxFlex="50">
                <mat-label>Applicable Date</mat-label>
                <input matInput readonly [matDatepicker]="picker" placeholder="DD-MM-YYYY" formControlName="fromDate"
                  required [disabled]="true" (click)="picker.open()" />
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <!-- <input type="number" formControlName="currentCTC" matInput required oninput="validity.valid||(value='');"   [tabIndex]="2"> -->
                <mat-error class="con-error" *ngIf="ReAssignpaygroupform.controls.currentCTC.errors?.required">
                  {{PR1}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.xs="column" *ngIf="show">
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Current CTC</mat-label>
                <input formControlName="currentCTC" [attr.disabled]="true" [disabled]="true" [readonly]="true" required
                  matInput placeholder="" />
                <mat-error class="con-error" *ngIf="ReAssignpaygroupform.controls.currentCTC.errors?.required">
                  {{PR30}}
                </mat-error>

              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>Current Pay Group</mat-label>
                <input formControlName="currentpayGroup" [attr.disabled]="true" [disabled]="true" [readonly]="true"
                  required matInput placeholder="" />
                <mat-error class="con-error" *ngIf="ReAssignpaygroupform.controls.currentCTC.errors?.required">
                  <!-- {{PR30}} -->
                  Please add CTC
                </mat-error>

              </mat-form-field>
              <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                <mat-label>New CTC</mat-label>
                <input formControlName="newCTC" [attr.disabled]="true" required matInput placeholder="" />
                <mat-error class="con-error" *ngIf="ReAssignpaygroupform.controls.newCTC.errors?.required">
                  {{PR1}}
                </mat-error>
              </mat-form-field>
            </div>


            <!-- <span style="padding-top: 1%;" fxLayoutAlign.lt-lg="center" >
                    <button  class="btn btn-primary mr-2 mb-2" (click)="getPayGroupsForCtc()" [tabIndex]="3"  type="submit">
                      <span>Search</span>
                  </button>
                  <button  class="btn btn-danger mr-2 mb-2" (click)="clear()"  [tabIndex]="3"    type="submit">
                    <span>Clear</span>
                </button>
                  </span> -->



          </div>
          <div fxLayout="row" fxLayout.xs="column" style="padding-left: 15px;">
            <mat-radio-group formControlName="changePaygroup" style="display: grid;">
              <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;"
                [value]="halfs.Value">{{halfs.name}}</mat-radio-button>
            </mat-radio-group>
          </div>



          <div class="form-group row" style="margin-bottom: 15px !important;">
            <div class="col-sm-6 text-right" style="padding:0px">
              <button class="btn btn-primary custom mr-2 mb-2" (click)="ValidateSubmit()" type="submit">
                <span>Submit</span>
              </button>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </form>
        <div class="mat-elevation-z1 example-container">
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows *ngIf="hide">
            <ng-container matColumnDef="radio">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> </th>
              <td mat-cell *matCellDef="let element; let i=index;" class="custom-investment-td">
                <ng-container (click)="getComponentWiseValuesForPayGroupAssignment(element)">

                  <mat-radio-button style="margin-bottom: 0px;" value="element.id" (change)="data(element)"
                    [tabIndex]="i+3" (keyup.enter)="data(element)">
                  </mat-radio-button>

                </ng-container>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Name</th>
              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.name}} </td>
            </ng-container>
            <!-- Start Range Column -->
            <ng-container matColumnDef="Start_Range">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th">Start Range</th>
              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.startrange}} </td>
            </ng-container>

            <!-- End Range Column -->
            <ng-container matColumnDef="End_Range">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th">End Range</th>
              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.endrange}} </td>
            </ng-container>

            <!-- Earnings Column -->
            <ng-container matColumnDef="Earnings">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th">Earnings</th>
              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.Earnings}} </td>
            </ng-container>

            <!-- Deductions Column -->
            <ng-container matColumnDef="Deductions">
              <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Deductions</th>
              <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.Deductions}} </td>
            </ng-container>




            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]=" 
                          element == expandedElement ? 'expanded' : 'collapsed'
                        ">
                  <div class="table-expanded-description" style="width:100%;margin-left: 20%;">
                    <div class="table-responsive custom-investment-of">

                      <div>

                        <div fxLayout="column" fxFlex="40" class="custom-payroll-div">
                          <div>
                            <p><u>Component Type</u><br>
                              <a *ngFor="let a of activedata">
                                <span>{{a.component_name}}</span><br>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div fxLayout="column" fxFlex="40" class="custom-payroll-div">
                          <p><u>Calculation Type</u><br>
                            <a *ngFor="let a of activedata">
                              <span *ngIf="a.calculation_type != null">{{a.calculation_type}} </span>
                              <span
                                *ngIf="(a.calculation_type == 'null' || a.calculation_type ==null)&&(a.component_name !='Other Allowance')">Deduction</span>
                              <span *ngIf="(a.component_name =='Other Allowance')">-</span>
                              <br>
                            </a>
                          </p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row class="dashboard-1 custom-investment-header" *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [class.example-expanded-row]="expandedElement == row" (click)="
                expandedElement = expandedElement == row ? null : row
              " class="example-element-row" (click)="getActiveComponentsValuesForPayGroup(row.id)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

          </table>
        </div>
        <div class="form-group row" style="margin-bottom: 15px !important;" *ngIf="hide">
          <div class="col-sm-6 text-right" style="padding:0px">
            <button class="btn btn-primary custom mr-2 mb-2" (click)="modifyCtcOfAnEmployee()" type="submit">
              <span>Assign</span>
            </button>
          </div>
          <div class="col-sm-6">
            <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
              <span>Cancel</span>
            </button>
          </div>
        </div>




      </div>


    </div>





  </mat-card>

</div>