import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input'
import { Router } from '@angular/router';
import { LeavePoliciesDialogComponent } from 'src/app/modules/admin/dialog/leave-policies-dialog/leave-policies-dialog.component';

@Component({
  selector: 'app-chapter-questioner',
  templateUrl: './chapter-questioner.component.html',
  styleUrls: ['./chapter-questioner.component.scss']
})
export class ChapterQuestionerComponent implements OnInit {

  addQuestions: any = UntypedFormGroup;
  courseName: any = ['Java', 'ReactJS', 'Python'];
  addNewquestion: boolean = true;
  displayDurationForm: boolean = false;
  chapterNames: any = [];
  questions = [
    { sno: '1', question: 'In which language MYSQL is written?', A: 'PYTHON', B: 'C/C+', C: 'JAVA', D: 'COBOL' },
    { sno: '2', question: 'To see the list of options provided by MYSQL which of the following command is used?', A: 'HELP', B: '–HELP', C: '-- HELP', D: 'ELP-' },
    { sno: '3', question: ' What do you mean by HOST in MYSQL?', A: 'HOST is the user name.', B: 'HOST is the representation of where the MYSQL server is running.', C: 'HOST is the administration’s machine name.', D: 'COBOL' },
    { sno: '4', question: ' In which form MYSQL query results are displayed?', A: 'LIST', B: 'TUPLE', C: 'ROWS AND COLUMNS', D: 'CLIST AND TUPLES BOTHOBOL' },
  ]
  displaySingleAnsQuesOptions: boolean = false;
  displayMutliAnsQuesOptions: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.addQuestions = this.formBuilder.group({
      courseName: [''],
      chapterName: [''],
      duration: [''],
    })
    this.singleAnsQues();
  }

  addNewQuestion() {
    this.addNewquestion = false;
  }

  setDuration(event: any) {
    if (event.checked) {
      this.displayDurationForm = true;

    } else {
      this.displayDurationForm = false;
    }
  }

  displayChapterList() {
    this.chapterNames = ['Introduction', 'HTML', 'CSS']
  }
  TimeFormat(event: any) {
    let eventValue = event.keyCode;
    let currentValue = this.addQuestions.controls.duration.value;
    if (eventValue >= 48 && eventValue <= 57) {
      return true;
    }
    else {
      return false;
    }
  }

  singleAnsQues() {
    this.displaySingleAnsQuesOptions = true;
    this.displayMutliAnsQuesOptions = false;
  }

  mutliAnsQues() {
    this.displayMutliAnsQuesOptions = true;
    this.displaySingleAnsQuesOptions = false;
  }

  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/chapterQuestioner']));
  }
  deleteQuestion() {
    let dialogRef = this.dialog.open(LeavePoliciesDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: { message: "Are you sure you want to delete this question?", YES: 'YES', NO: 'No' }
    });
  }
}
