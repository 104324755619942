<div fxLayout="column" >
  <app-header></app-header>
</div>
  <div fxLayout="column" >

  <app-side-nav></app-side-nav>

</div>



<!-- <router-outlet></router-outlet> -->
<!-- <div class="example-container" [class.example-is-mobile]="mobileQuery.matches" style="height:100%" class="h-100">
  <mat-toolbar style="height:10%;padding-top: 0px">
    <div class="main-header custom-mob-main-header" style="min-height: 10%">
      <div class="logo-header">
        <div class="custom-mob-icons">

          <a href="/#/MainDashboard"(click)="onClickMainDashboard()" class="logo"><img [src]="pic" style="width: 25px;" alt="" class="logo-img mr-2">{{companyName}}</a>
          <button mat-icon-button style="z-index: 1111;" (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="navbar-nav topbar-nav ml-md-auto mobile-view">
          <!-- <mat-icon title="Main Dashboard" (click)="navigateToMainDashboard()" >dashboard</mat-icon> -->

         <!--  <div>
            <!-- <span class="mx-2 my-2" ><mat-icon title="Main Dashboard" (click)="navigateToMainDashboard()" >dashboard</mat-icon></span> -->
            <!--<ul>-->
          <!--   <li class="nav-item dropdown">
              <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false"> <img
                  *ngIf="!imageurls" src="assets/img/profile.jpg" alt="user-img" width="36" class="img-circle"><img
                  *ngIf="imageurls" [src]="imageurls" width="36"
                  class="img-circle custom-mob-img-circle"><span>{{this.usersession.firstname}}{{" "}}
                    {{this.usersession.middlename}}{{""}}{{this.usersession.lastname}}</span></a>
              <ul class="dropdown-menu dropdown-user custom-mob-ul">
                <li>
                  <div class="user-box">
                    <div *ngIf="!imageurls" class="u-img"><img src="assets/img/profile.jpg" alt="user" width="36"
                        class="img-circle"></div>
                    <div *ngIf="imageurls" class="u-img"><img [src]="imageurls" width="36" class="img-circle"></div>
                    <div class="u-text">
                      <h4>{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}{{this.usersession.lastname}}</h4>
                      <p class="text-muted">{{this.usersession.officeemail}}</p>
                      <a (click)="editProfile()" [routerLink]="">Edit Profile</a>
                    </div>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="changePassword()"><i class="ti-settings"></i> Change Password</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
              </ul>
              <!-- /.dropdown-user -->
           <!--  </li>
            <!--</ul>-->
            <!--<a class="dropdown-item" ><span class='material-icons' (click)="changePassword()" style="margin-top: 15px; cursor: pointer;" title="Change Password" >key</span> </a>-->
            <!--<a class="dropdown-item" href="Login"><span class="material-icons " (click)="logout()" style="margin-top: 15px; cursor: pointer;" title="Logout">logout</span></a>-->
         <!--  </div>
        </div>

      </div>
      <nav class="navbar navbar-header navbar-expand-lg tab-view">

        <div class="container-fluid">

          <!--<div class="navbar-minimize" *ngIf="checkHeadNav == 'admin'">-->
          <!--<button class="btn btn-minimize btn-rounded" (click)="setToggleSideBar(toggleValue)">-->
          <!--<i class="la la-navicon" *ngIf="checkToggleBar == 'Show'"></i>-->
          <!--<i class="la la-ellipsis-v" *ngIf="checkToggleBar == 'Hide'"></i>-->
          <!--</button>-->
          <!--</div>-->
        <!--   <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
            <span class="mx-2 my-2" style="width:50px;padding-top: 8px;"><mat-icon title="Main Dashboard" (click)="navigateToMainDashboard()" >dashboard</mat-icon></span>

            <!--<li class="nav-item dropdown hidden-caret">-->
            <!--<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--<i class="la la-bell"></i>-->
            <!--<span class="notification">3</span>-->
            <!--</a>-->
            <!--<ul class="dropdown-menu notif-box" aria-labelledby="navbarDropdown">-->
            <!--<li>-->
            <!--<div class="dropdown-title">You have 4 new notification</div>-->
            <!--</li>-->
            <!--<li>-->
            <!--<div class="notif-center">-->
            <!--<a href="#">-->
            <!--<div class="notif-icon notif-success"> <i class="la la-calendar-o"></i> </div>-->
            <!--<div class="notif-content">-->
            <!--<span class="block">New Leave Request</span>-->
            <!--<span class="time">5 minutes ago</span>-->
            <!--</div>-->
            <!--<div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>-->
            <!--</a>-->
            <!--<a href="#">-->
            <!--<div class="notif-icon notif-primary"> <i class="la la-check-circle"></i> </div>-->
            <!--<div class="notif-content">-->
            <!--<span class="block">Leave Accepted</span>-->
            <!--<span class="time">12 minutes ago</span>-->
            <!--</div>-->
            <!--<div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>-->
            <!--</a>-->
            <!--<a href="#">-->
            <!--<div class="notif-icon notif-danger">-->
            <!--<i class="la la-ban"></i>-->
            <!--</div>-->
            <!--<div class="notif-content">-->
            <!--<span class="block">Leave Rejected</span>-->
            <!--<span class="time">12 minutes ago</span>-->
            <!--</div>-->
            <!--<div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>-->
            <!--</a>-->
            <!--<a href="#">-->
            <!--<div class="notif-icon notif-danger"> <i class="la la-close"></i> </div>-->
            <!--<div class="notif-content">-->
            <!--<span class="block">Leave Cancelled</span>-->
            <!--<span class="time">17 minutes ago</span>-->
            <!--</div>-->
            <!--<div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>-->
            <!--</a>-->
            <!--</div>-->
            <!--</li>-->
            <!--<li>-->
            <!--<a class="see-all" href="javascript:void(0);"> <strong>Clear all notifications</strong> </a>-->
            <!--</li>-->
            <!--</ul>-->
            <!--</li>-->
            <!--<li class="nav-item dropdown hidden-caret">-->
            <!--<a title="Help" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--<i class="la la-info-circle"></i>-->
            <!--</a>-->
            <!--<div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
            <!--<a class="dropdown-item" href="#">Document Shortcut</a>-->
            <!--</div>-->
            <!--</li>-->

          <!--   <li class="nav-item dropdown">
              <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false"> <img
                  *ngIf="!imageurls" src="assets/img/profile.jpg" alt="user-img" width="36" class="img-circle"><img
                  *ngIf="imageurls" [src]="imageurls" width="36"
                  class="img-circle"><span>{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}{{this.usersession.lastname}}</span></a>
              <ul class="dropdown-menu dropdown-user">
                <li>
                  <div class="user-box">
                    <div *ngIf="!imageurls" class="u-img"><img src="assets/img/profile.jpg" alt="user" width="36"
                        class="img-circle"></div>
                    <div *ngIf="imageurls" class="u-img"><img [src]="imageurls" width="36" class="img-circle"></div>
                    <div class="u-text">
                      <h4>{{this.usersession.firstname}}{{" "}}{{this.usersession.middlename}}{{" "}}{{this.usersession.lastname}}</h4>
                      <p class="text-muted">{{this.usersession.officeemail}}</p>
                      <a (click)="editProfile()" [routerLink]="">Edit Profile</a>
                    </div>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="changePassword()"><i class="ti-settings"></i> Change Password</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
              </ul>
              <!-- /.dropdown-user -->
         <!--    </li>
            <!--<li >-->
            <!--<a class="dropdown-item" ><span class='material-icons' (click)="changePassword()" style="margin-top: 15px; cursor: pointer;" title="Password Change" >key</span> </a>-->
            <!--</li>-->

            <!--<li style="margin-right: 0px;">-->
            <!--<a class="dropdown-item" href="Login"><span class="material-icons " (click)="logout()" style="margin-top: 15px; cursor: pointer;" title="Logout">logout</span></a>-->

            <!--</li>-->
    <!--       </ul>
        </div>
      </nav>
    </div>
  </mat-toolbar>
  <mat-card style="height:90%;padding-top: 0px;padding-left: 0px;padding-right: 0px;">


    <mat-sidenav-container class="example-sidenav-container h-100" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
      <mat-sidenav #snav [opened]="mobileQuery.matches ? false : true" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
<mat-nav-list>

<span *ngFor="let item of menu">

 <span *ngIf="item.children && item.children.length > 0">
   <mat-accordion>
     <mat-expansion-panel [expanded]="true">
       <mat-expansion-panel-header>
         <mat-panel-title>
           <div fxLayout="row" fxLayoutAlign="space-between center" >
             <mat-icon>{{item.iconName}}</mat-icon>
             {{item.displayName}}
           </div>
         </mat-panel-title>
       </mat-expansion-panel-header>
       <span *ngFor="let child of item.children">
         <mat-list-item [routerLink]="child.route" [routerLinkActive]="['active']">
           <div fxLayout="row" fxLayoutAlign="space-between center" >
             <mat-icon>{{child.iconName}}</mat-icon>
             {{child.displayName}}
           </div>
         </mat-list-item>
       </span>
     </mat-expansion-panel>
   </mat-accordion>
 </span>



</span>

</mat-nav-list>
</mat-sidenav>

      <mat-sidenav-content>
        <router-outlet #outlet="outlet"></router-outlet>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-card>
</div> -->

<ngx-spinner size="medium" type='ball-fussion'></ngx-spinner>
