import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AttendanceService } from '../../attendance.service';
import { MatDialog } from '@angular/material/dialog';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserData } from '../../models/EmployeeData';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-permissions-request',
  templateUrl: './permissions-request.component.html',
  styleUrls: ['./permissions-request.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PermissionsRequestComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  permissionsForm: any = UntypedFormGroup;
  minpermissionDate = new Date();
  maxpermissionDate: any;
  pipe = new DatePipe('en-US');
  userSession: any;
  pageLoading = true;
  titleName: string = '';
  requestData: any;
  myDateFilter: any;
  isPermissionList = true;
  isNewPermission = false;
  displayedColumns: string[] = [
    'id',
    'usage_date',
    'permission_duration',
    'submission_status',
    'action',
  ];
  dataNotSave: any;
  dataSave: any;
  permissionId: any;
  isSubmit = true;
  enable: any = null;
  valid: boolean = false;
  dataSource: MatTableDataSource<UserData> = <any>[];
  usedDates: any = [];
  disableDates: any = [];
  datesList: any;
  weekoffs: any;
  holidays: any = [];
  leaves: any;
  workeddays: any;
  regularizationdays: any;
  permissionDate: any;
  leaveData: any;
  permissionDateFilter: any;
  FromDatesHalfDays: any = [];
  leaveDates: any = [];
  transformedLeaveDates: any = [];
  displayAddForm: boolean = true
  btnDisable:boolean = false;
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    private AS: AttendanceService,
    public dialog: MatDialog,
    private LM: LeavesService
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');
    this.getEmployeePermissions();
    this.getDaystobedisabledfromdate();
  }

  ngOnInit(): void {
    this.permissionsForm = this.formBuilder.group({
      permissionDate: [{ value: ' ' }, Validators.required],
      permissionDuration: ['', [Validators.required]],
      remarks: ['', [Validators.required]],
      editpermissionDuration: [],
      Date: [''],
      Duration: [''],
      reason: ['']
    });
  }


  numberOnlyWithoutZero(event: any) {

    let currentValue = this.permissionsForm.controls.permissionDuration.value;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 46) {
      return false;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      true;
    }
    if (currentValue.length === 0 && charCode === 48) {
      return false;
    }
    return true;
  }

  onchangeTime() {
    let value = this.permissionsForm.controls.permissionDuration.value;
    if (value > 100) {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Permitted upto 100 minutes only!',
      });
      this.permissionsForm.controls.permissionDuration.setValue('');
    }
  }

  addNewPermission() {
    this.isNewPermission = true;
    this.isPermissionList = false;
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  onSubmit() {
    this.newPermission();
  }

  newPermission() {
    if (this.permissionsForm.valid) {
      if (this.btnDisable) {
        return;
      }
      let data = {
        id: this.permissionId != null ? this.permissionId : null,
        employee_id_value: this.userSession.id,
        usage_date_value: this.pipe.transform(
          this.permissionsForm.controls.permissionDate.value,
          'yyyy-MM-dd'
        ),
        permission_duration_value: Number(
          this.permissionsForm.controls.permissionDuration.value
        ),
        remarks_value: this.permissionsForm.controls.remarks.value,
        submission_status_value: 'Submitted',
        applied_by_value: this.userSession.id,
        approved_by_value: null,
      };
      this.btnDisable = true;
      this.AS.setEmployeePermission(data).subscribe((result: any) => {
        if (result.status && result.data[0].statuscode == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Permission request is submitted successfully.',
          });
          this.navigationToPermissionReqScreen();
          this.btnDisable = false;
        } else if (result.status && result.data[0].statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist.',
          });
          this.btnDisable = false;
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to submit',
          });
          this.btnDisable = false;
        }
      });
    }

  }

  clear() {
    this.navigationToPermissionReqScreen();
    this.isNewPermission = true;
    this.isPermissionList = false;
  }
  editRequest(id: any, data: any) {
    var min = data.permission_duration.split(':');
    let minutes = Number(min[0]) * 60 + Number(min[1]);
    this.isSubmit = false;
    this.permissionsForm.controls.permissionDuration.setValue(Number(minutes));
    this.enable = data.id;
  }

  deleteRequest(data: any) {
    let deleteddata = {
      id: data.id,
      employee_id_value: this.userSession.id,
      usage_date_value: data.usage_date,
      permission_duration_value: data.permission_duration_value,
      remarks_value: data.remarks,
      submission_status_value: 'Deleted',
      applied_by_value: this.userSession.id,
      approved_by_value: null,
    };

    this.AS.setEmployeePermission(deleteddata).subscribe((res: any) => {
      if (res.status && res.data[0].statuscode == 3) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'The submitted permission request is deleted successfully',
        });
        this.navigationToPermissionReqScreen();
      }
    });
  }

  getEmployeePermissions() {
    let data = {
      employee_id: this.userSession.id,
    };
    this.AS.getEmployeePermissions(data).subscribe((res: any) => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].submission_status != 'Deleted') {
          this.usedDates.push(res.data[i]);
        }
      }
      if (res.status) {
        this.disableDates = res.data.map((record: any) => {
          let date = record.usage_date;
          return new Date(date + ' ' + '00:00:00');
        });
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    });
  }

  save(data: any) {
    if (this.btnDisable) {
      return;
    }
    if (this.permissionsForm.controls.permissionDuration.value > 0) {
      let savedata = {
        id: data.id,
        employee_id_value: this.userSession.id,
        usage_date_value: data.usage_date,
        permission_duration_value: Number(
          this.permissionsForm.controls.permissionDuration.value
        ),
        remarks_value: data.remarks,
        submission_status_value: 'Submitted',
        applied_by_value: this.userSession.id,
        approved_by_value: null,
      };
      this.btnDisable = true;
      this.AS.setEmployeePermission(savedata).subscribe((result: any) => {
        if (result.status && result.data[0].statuscode == 0) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Permission request is edited successfully.',
          });
          this.navigationToPermissionReqScreen();
          this.btnDisable = false;
        } else if (result.status && result.data[0].statuscode == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist.',
          });
          this.btnDisable = false;
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to submit',
          });
          this.btnDisable = false;
        }
      });
    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Duration value cannot be empty',
      });
    }

  }

  navigationToPermissionReqScreen() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Attendance/permissionsRequest']));
  }

  cancel() {
    this.navigationToPermissionReqScreen();
  }
  cancelRequest(id: any, data: any) {
    let deleteddata = {
      id: data.id,
      employee_id_value: this.userSession.id,
      usage_date_value: data.usage_date,
      permission_duration_value: data.permission_duration_value,
      remarks_value: data.remarks,
      submission_status_value: 'cancelled',
      applied_by_value: this.userSession.id,
      approved_by_value: null,
    };

    this.AS.setEmployeePermission(deleteddata).subscribe((res: any) => {
      if (res.status && res.data[0].statuscode == 4) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'The approved permission is cancelled successfully.',
        });
        this.navigationToPermissionReqScreen();
      }
    });
  }

  getDaystobedisabledfromdate() {
    this.LM.getDaysToBeDisabledFromDate(
      this.userSession.id,
      this.leaveData ? this.leaveData.id : null
    ).then((result) => {
      if (result && result.status) {
        if (this.usedDates.length > 0) {
          this.usedDates.forEach((i: any) => {
            if (i.submission_status != "Cancelled" && i.submission_status != 'Deleted') {
              this.holidays.push(i.usage_date);
            }
          });
        }
        this.permissionDate = result.data;
        for (var i = 0; i < result.data.length; i++) {
          /**Here If condition added for Removed date from disabled dates,if today is equal to present  */
          if (this.pipe.transform(new Date(), 'yyyy/MM/dd') == this.pipe.transform(new Date(result.data[i].edate), 'yyyy/MM/dd') && result.data[i].daytype == 'Present') { }
          else {
            result.data[i].first_half && result.data[i].second_half
              ? this.holidays.push(
                new Date(result.data[i].edate + ' ' + '00:00:00')
              )
              : this.FromDatesHalfDays.push(result.data[i])
          }
        }
        this.myDateFilter = (filterDate: Date): boolean => {
          let isValid = true;
          let transformedFilterDate = this.pipe.transform(
            filterDate,
            'yyyy/MM/dd'
          );
          this.holidays.forEach((holidayDate: any) => {
            if (
              this.pipe.transform(holidayDate, 'yyyy/MM/dd') ==
              transformedFilterDate
            ) {
              isValid = false;
            }
          });
          return isValid;
        };
        this.permissionsForm
          .get('permissionDate')
          .setValue(
            this.leaveData ? new Date(this.leaveData.permissionDate) : '',
            { emitEvent: false }
          );
      }
    });
  }

  clearDisplay() {
    this.navigationToPermissionReqScreen();
  }
  requestView(data: any) {

    var min = data.permission_duration.split(':');
    let minutes = Number(min[0]) * 60 + Number(min[1]);
    this.displayAddForm = false;
    this.isPermissionList = false;
    this.isNewPermission = true;
    this.permissionsForm.controls.Date.setValue(data.usage_date);
    this.permissionsForm.controls.Duration.setValue(minutes);
    this.permissionsForm.controls.reason.setValue(data.remarks)
  }
}

