<div fxLayout="column">
  <mat-card class="mat-elevation-z0">
    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title">
               <span *ngIf="isNew"  (click)="clear()">
                 <button mat-icon-button title="Back"> 
                <mat-icon>arrow_back</mat-icon>
              </button></span>
           <span> Help Desk  </span>    
       </mat-card-title>
       
       <div *ngIf="!isNew" style="margin-right: 2%;">
        <button class="add-buttton" (click)="newTicket()"  mat-button>
          <mat-icon>add_circle_outline</mat-icon>
          <span style="padding-left: 5px;">Add New Ticket</span>
        </button>
      </div>
    </mat-card-header>
    <br>
    <div fxLayout="column" *ngIf="isNew">
      <form [formGroup]="raiseTicketForm">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput readonly [matDatepicker]="appliedDate" placeholder="DD-MM-YYYY"
              formControlName="appliedDate" (click)="appliedDate.open()">
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="appliedDate"></mat-datepicker-toggle>
            <mat-datepicker #appliedDate></mat-datepicker>
          </mat-form-field>


          <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Module Name</mat-label>
            <mat-select formControlName="modules">
              <ng-container>
                <span *ngFor="let list of allModuleDetails">
                  <mat-option [value]="list.id">{{list.modulename}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Screens</mat-label>
            <mat-select formControlName="screens" required>
              <ng-container>
                <span *ngFor="let list of allScreensList">
                  <mat-option [value]="list.id">{{list.name}}</mat-option>
                </span>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="raiseTicketForm.controls.screens.errors?.required">
              Please select option
            </mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="description" required matInput maxlength="500"
              placeholder="Write something here"></textarea>
            <mat-error class="con-error"
              *ngIf="raiseTicketForm.controls.description.errors?.required || raiseTicketForm.controls.description.errors?.['whitespace']">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
          <div style="padding-bottom: 1.34375em; margin-left: 10px">
            <!-- <label style="margin-left: 10px">Supporting Document<mat-icon
                      title="Only pdf file upto 1MB can be uploaded" class="iconstyle">info</mat-icon>
                    </label> -->
            <br />
            <button class="link" style="margin-left: 10px" (click)="fileView()" *ngIf="pdfName">
              {{ pdfName }}
            </button>
            <input *ngIf="!pdfName" style="margin-left: 10px" type="file" formControlName="document"
              (change)="onSelectFile($event)" single accept="pdf" />
            &nbsp;
            <mat-icon title="Edit" *ngIf="pdfName" (click)="editdoc()">edit</mat-icon>
            &nbsp;

            <mat-icon title="Delete" *ngIf="raiseTicketForm.controls.document.value && iseditDoc"
              (click)="delete()">delete</mat-icon>
            &nbsp;

          </div>
        </div>
        <div *ngIf="displayStatus">
          <div fxLayout="row">

            <mat-form-field fxFlex="33" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Issue Status</mat-label>
              <input formControlName="issueStatus" matInput readonly>
            </mat-form-field>

          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Remarks</mat-label>
              <textarea formControlName="remarks" matInput readonly placeholder="Write something here"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="saveBtnView" fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" style="padding: 0 10px" (click)="submit()">
            <span *ngIf="!isEdit">Submit</span>
            <span *ngIf="isEdit">Update</span>
          </button>
          <button class="btn btn-danger mr-2 mb-2" (click)="clear()">
            <span>Cancel</span>
          </button>
          <br />
        </div>

        <div *ngIf="!saveBtnView">
          <mat-divider></mat-divider>
          <h2>Status Tracker</h2>
          <p><b>Ticket ID: &nbsp; </b><span>{{ticketCode}}</span> </p>
          <!-- <div>
        <ul>
          <li *ngFor="let status of ticketHistory">
            {{ status.description }}  - {{ status.createdOn | date :'dd-MM-yyyy h:mm a ' }}
          </li>
        </ul>
      </div> -->
          <div style="margin: 2%;">
            <ng-container *ngFor="let item of ticketHistory ;let i = index;">
              <app-ticket-status [date]="item.createdOn" [content]="item.description" [index]="i"
                [lengthOfTicketHistory]="this.lengthOfTicketHistory"></app-ticket-status>
            </ng-container>
          </div>
        </div>

      </form>
    </div>


    <!-- search tab -->
    <div fxLayout="column" *ngIf="!isNew">
      <form [formGroup]="ticketHistoryForm" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
        <div fxLayout="row" fxLayout.xs="column">
          <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
            <mat-label>From Date</mat-label>
            <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
              formControlName="fromDate" (click)="fromDate.open()" tabindex="2" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
            <mat-label>To Date</mat-label>
            <input matInput readonly [matDatepicker]="toDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
              formControlName="toDate" (click)="toDate.open()" tabindex="2" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign.lt-lg="center center" fxLayoutAlign.xs="center center">
          <span ngStyle.gt-md="margin-top: 5%">
            <button class="btn btn-primary mr-2 mb-2" (click)="searchform()" tabindex="3">
              Search
            </button>
            <span class="line" style="color: #1898d5; font-size: 16px"></span>
            <button class="btn btn-danger mr-2 mb-2" (click)="clear()" tabindex="4">
              <span>Clear</span>
            </button>
          </span>
        </div>
      </form>
      <mat-divider></mat-divider>
      <div fxLayout="column" style="padding-bottom: 1%;">

        <div fxLayout="row" fxLayoutAlign="end" style="margin-top: 5px;">
          <mat-form-field fxFlex="28" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
            <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
            <mat-label>Search</mat-label>
            <input (keyup)="applyFilter($event)" [tabIndex]="2" matInput maxlength="100">
          </mat-form-field>

        </div>

        <div fxLayout="column" class="table-pad">
          <div class="example-container mat-elevation-z1">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
              </ng-container>
              <ng-container matColumnDef="ticketno">
                <th mat-header-cell *matHeaderCellDef> Ticket No </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.ticketCode}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="issuedate">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Date </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.issueDate | date:'dd-MM-yyyy' }} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Module </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span>{{row.module}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="screen">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Screen</th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                  <span>{{row.screen}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">
                  <span>{{row.ticketStatus}}</span>
                  <!-- <span *ngIf=" row.status == 2">Resolved</span>
                <span *ngIf=" row.status == 3">Inprogress</span> -->
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let row">

                  <button mat-icon-button title="Edit" *ngIf="row.ticketStatus == 'Open' " (keyup.enter)="edit(row)">
                    <mat-icon style="overflow: visible;" (click)="edit(row)">edit</mat-icon>
                  </button>

                  <button mat-icon-button title="View" *ngIf="row.ticketStatus != 'Open' "
                    (keyup.enter)="viewStatus(row)">
                    <mat-icon style="font-size: 28px;overflow: visible;" (click)="viewStatus(row)">visibility</mat-icon>
                  </button>

                  <button mat-icon-button title="Delete" *ngIf="row.ticketStatus == 'Open' "
                    (keyup.enter)="deleteRecord(row)">
                    <mat-icon style="overflow: visible;" (click)="deleteRecord(row)">delete</mat-icon>
                  </button>

                </td>
              </ng-container>


              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
          </div>
        </div>

      </div>
    </div>
  </mat-card>

</div>