import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { changePassword } from 'src/app/models/changepassword';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-employee-reset-password',
  templateUrl: './employee-reset-password.component.html',
  styleUrls: ['./employee-reset-password.component.scss']
})
export class EmployeeResetPasswordComponent implements OnInit {

  passwordResetForm: any = UntypedFormGroup;
  changePasswordAddObj!: changePassword;
  employeeList: any = [];
  hide1 = true;
  hide2 = true;
  hide3 = true;
  worklocationDetails: any = [];
  companyDBName: any;
  availableDepartments: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanySettingService,
    private dialog: MatDialog,
    private loginService: LoginService,
    private router:Router
  ) { }
  empId:any;
  ngOnInit(): void {
    this.companyDBName = sessionStorage.getItem("companyName") ? sessionStorage.getItem("companyName") : null;
    this.passwordResetForm = this.formBuilder.group({
      department: [''],
      location: [''],
      empname: [''],
      existingUsername: [''],
      username: [''],
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: [''],
    })
    this.getWorkLocation();
    this.passwordResetForm.get('empname')?.valueChanges.subscribe((selectedValue:any)=>{
      this.empId =selectedValue;
      this.getEmployeeLoginDataByEmpId();
    })
  }
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyDBName }).subscribe((result) => {
      this.worklocationDetails = result.data;
    })
  }
  getDepartmentsMaster() {
    this.companyService.getMastertable('departmentsmaster', 1, 1, 1000, this.companyDBName).subscribe(data => {
      if (data.status) {
        this.availableDepartments = data.data;
      }
    })
  }
  enableDepartments() {
    // this.passwordResetForm.controls.empname.setValue('');
    this.passwordResetForm.controls.department.setValue('');
    this.getDepartmentsMaster();

  }
  getEmployeeListBasedonlocationIdandDepartmentId() {
    let data = {
      location_id: this.passwordResetForm.controls.location.value,
      department_id: this.passwordResetForm.controls.department.value,
    }
    this.companyService.getEmployeeListBasedonlocationIdandDepartmentId(data).subscribe((result: any) => {
      this.employeeList = result.data;
    })
  }

  getEmployeeLoginDataByEmpId() {
     this.companyService.getEmployeeLoginDataById(this.empId).subscribe((result: any) => {
     let value = result.data[0];
     this.passwordResetForm.controls.existingUsername.setValue(value.login);
     this.passwordResetForm.controls.oldPassword.setValue(value.password);
     this.passwordResetForm.controls.username.setValue(value.login);
    })
  }
  submit(){
    if(this.passwordResetForm.valid){

      let oldPwd = this.passwordResetForm.controls.oldPassword.value;
      let newPwd = this.passwordResetForm.controls.newPassword.value;
      let cnfPwd = this.passwordResetForm.controls.confirmPassword.value;

      if(oldPwd !='' && newPwd != '' && cnfPwd !=''){
        if(oldPwd === newPwd){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position:{top:`70px`},
            disableClose: true,
            data: 'Your new password cannot be same as the old password'
          });
        }
        else{
          let data = {
            'empId': this.empId,
            'email': this.passwordResetForm.controls.username.value,
            'oldPassword': oldPwd,
            'newPassword': newPwd,
          }
          this.loginService.changepassword(this.changePasswordAddObj).subscribe((data) => {

            if (data[0]==0) {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position:{top:`70px`},
                disableClose: true,
                data: "Password changed successfully. "
              });
              let login = "/Login"
              sessionStorage.removeItem('user')
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate([login]));

            }
            else if(data[0]== -1) {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position:{top:`70px`},
                disableClose: true,
                data: "Please enter correct old password"
              });
            }
            else{
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position:{top:`70px`},
                disableClose: true,
                data: "Your new password cannot be the same as previous passwords count of 3"
              });

            }
          });


        }

      }
    }
  }
  cancel(){

  }
}
