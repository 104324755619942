<!-- 
<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" >
  <mat-toolbar>
    <div class="main-header">
      <div class="logo-header">
        <a href="index.html" class="logo"><img [src]="pic" style="width: 25px;" alt="" class="logo-img mr-2">Sreeb Technologies</a>
        <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      </div>
      <nav class="navbar navbar-header navbar-expand-lg">
        <div class="container-fluid">
               <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
    
            <li class="nav-item dropdown hidden-caret">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="la la-bell"></i>
                <span class="notification">3</span>
              </a>
              <ul class="dropdown-menu notif-box" aria-labelledby="navbarDropdown">
                <li>
                  <div class="dropdown-title">You have 4 new notification</div>
                </li>
                <li>
                  <div class="notif-center">
                    <a href="#">
                      <div class="notif-icon notif-success"> <i class="la la-calendar-o"></i> </div>
                      <div class="notif-content">
                        <span class="block">New Leave Request</span>
                        <span class="time">5 minutes ago</span>
                      </div>
                      <div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>
                    </a>
                    <a href="#">
                      <div class="notif-icon notif-primary"> <i class="la la-check-circle"></i> </div>
                      <div class="notif-content">
                        <span class="block">Leave Accepted</span>
                        <span class="time">12 minutes ago</span>
                      </div>
                      <div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>
                    </a>
                    <a href="#">
                      <div class="notif-icon notif-danger">
                        <i class="la la-ban"></i>
                      </div>
                      <div class="notif-content">
                        <span class="block">Leave Rejected</span>
                        <span class="time">12 minutes ago</span>
                      </div>
                      <div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>
                    </a>
                    <a href="#">
                      <div class="notif-icon notif-danger"> <i class="la la-close"></i> </div>
                      <div class="notif-content">
                        <span class="block">Leave Cancelled</span>
                        <span class="time">17 minutes ago</span>
                      </div>
                      <div class="cls notif-icon ml-0 mr-1"> <i class="la la-close"></i> </div>
                    </a>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="javascript:void(0);"> <strong>Clear all notifications</strong> </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <a title="Help" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="la la-info-circle"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Document Shortcut</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false"> <img src="assets/img/profile.jpg" alt="user-img" width="36" class="img-circle"><span >{{empname}}</span></a>
              <ul class="dropdown-menu dropdown-user">
                <li>
                  <div class="user-box">
                    <div class="u-img"><img src="assets/img/profile.jpg" alt="user"></div>
                    <div class="u-text">
                      <h4>{{empname}}</h4>
                      <p class="text-muted">{{email}}</p>
                      <a href="edit-profile.html" class="btn btn-rounded btn-danger btn-sm">Edit Profile</a>
                    </div>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="ChangePassword"><i class="ti-settings"></i> Change Password</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="Login" (click)="logout()"><i class="fa fa-power-off"></i> Logout</a>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <span *ngFor="let item of menu">
          <span *ngIf="item.children && item.children.length > 0">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div fxLayout="row" fxLayoutAlign="space-between center" > 
                      <mat-icon>{{item.iconName}}</mat-icon>
                      {{item.displayName}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-list-item [routerLink]="child.route">
                    <div fxLayout="row" fxLayoutAlign="space-between center" > 
                      <mat-icon>{{child.iconName}}</mat-icon>
                      {{child.displayName}}
                    </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </span>
          <span *ngIf="!item.children || item.children.length === 0">
            <mat-list-item routerLink="[item.route]">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon>{{item.iconName}}</mat-icon>
                {{item.displayName}}
              </div>
            </mat-list-item>
          </span>
        </span>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
    <router-outlet #outlet="outlet"></router-outlet> 

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

 -->
 <router-outlet></router-outlet>
