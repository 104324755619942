<div fxLayout="column">
    <form [formGroup]="approveCabinForm" autocomplete="off">
        <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center">
            <div fxLayout="column"  *ngIf="!data">

                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Request for</mat-label>
                    <mat-select formControlName="requestType" required>
                        <ng-container>
                            <div *ngFor="let b of assetTypeList">
                              <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

            <mat-form-field  *ngIf="cabinAssignType" fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Assign Cabin ID</mat-label>
                    <mat-select formControlName="currentEmpId" required>
                        <ng-container>
                            <div *ngFor="let b of assetTypeList">
                              <mat-option value={{b}}>{{b}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

             <div fxLayout="row" fxLayout.xs="column" *ngIf="!cabinAssignType">
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                <mat-label>Employee ID</mat-label>
                  <input type="text" formControlName="currentEmpId"  matInput readonly>
                  </mat-form-field>

                  <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                   <mat-label>New Employee Name</mat-label>
                   <mat-select formControlName="newEmpId" required>
                <ng-container>
                    <div *ngFor="let b of assetTypeList">
                      <mat-option value={{b}}>{{b}}</mat-option>
                    </div>
                </ng-container>
            </mat-select>
                        </mat-form-field>
                 </div>
        
              <div *ngIf="!cabinAssignType" fxLayout="row" fxLayout.xs="column">
        <mat-label>Employee Name</mat-label>
        <mat-label>New Employee Name</mat-label>
                 </div>

              <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Description</mat-label>
                  <textarea formControlName="remarks" matInput placeholder="reason" maxlength="100"></textarea>
                </mat-form-field>
            </div>


            <mat-form-field *ngIf="data" fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                <mat-label>Reject Reason</mat-label>
              <textarea rows="5" [formControl]="rejectReason " matInput placeholder="Reason" maxlength="100"></textarea>
            </mat-form-field>

            <div fxLayoutAlign="center">
                <button *ngIf="!data && !cabinAssignType" class="m-1 mat-stroked-button button-save"
                    type="submit">
                    <span>Swap</span>
                </button>
                <button *ngIf="!data && cabinAssignType" class="m-1 mat-stroked-button button-save"
                type="submit">
                <span>Submit</span>
            </button>
            <button *ngIf="data" class="m-1 mat-stroked-button button-save"
                type="submit">
                <span>Reject</span>
                  </button>

                <button class="m-1 mat-stroked-button button-clear" (click)="close()">
                    <span>Cancel</span>
                </button>
              <br />
            </div>
        </div>
         </form>
</div>