<div fxLayout="column" class="first-col">
    <form [formGroup]="reportForm" autocomplete="off">
      <mat-card>
        <mat-card-header class="headerbox">
             <mat-card-title class="title"> Client's Report </mat-card-title>
        </mat-card-header>
        <div *ngIf="ishide"  class="div-pad" fxLayout="column">
            <div fxLayout="row" fxflex="100" fxLayout.xs="row" fxLayout.lt-lg="row" [formGroup]="searchForm" *ngIf="is_super_admin">
                <mat-form-field  fxFlex="50" fxFlex.xs="100"    class="mx-2 my-2" appearance="outline" *ngIf="is_super_admin" >
                    <mat-label>City-Branch </mat-label>
                    <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                        multiple #locationSelect>
                        <button mat-raised-button class="mat-primary fill text-sm"
                            (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                            *ngIf="!locationIshide">Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm"
                            (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                            Deselect All
                        </button>
                        <mat-option *ngFor="let res of worklocationDetails" [value]="res"
                            (click)="onchangeLocation(res)">
                            {{res.cityname}}-{{res.location}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                        Please select option
                    </mat-error>
                </mat-form-field>
                <mat-form-field   fxFlex="50" fxFlex.xs="100"   class="mx-2 my-2"  appearance="outline"  *ngIf="is_super_admin" >
                    <mat-label>Manager</mat-label>
                    <mat-select placeholder="" name="managers" formControlName="managers" required multiple
                        #managerSelect>
                        <button mat-raised-button class="mat-primary fill text-sm"
                            (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                            *ngIf="!mgrIshide">Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm"
                            (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                            Deselect All
                        </button>
                        <mat-option *ngFor="let res of managersDetails" [value]="res" (click)="onchangeManager(res)">
                            {{res.manager_name}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
                        Please select option
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign.gt-md="space-between center" style="height: 50px;">
              <span class="title"  fxFlex="50" fxLayoutAlign="start">
                <mat-label  *ngIf="!is_super_admin"> Client Report  </mat-label>
                </span>
                
                <span  fxFlex="50" fxLayoutAlign="end">
                    <button mat-icon-button (keyup.enter)="popup()" [tabIndex]="1">
                        <mat-icon class="styles" title='Select' (click)="popup()">blur_linear</mat-icon>  
                    </button>
                    <button mat-icon-button (keyup.enter)="filter()" [tabIndex]="2">
                        <mat-icon class="styles" title='Filter' (click)="filter()">filter_alt</mat-icon>
                    </button>
                    <button *ngIf="allfilterdata.length>0" mat-icon-button  (keyup.enter)="exportAsXLSX()" [tabIndex]="3">
                        <mat-icon class="styles" title='Download' (click)='exportAsXLSX()'>download</mat-icon>
                   </button>
                    <mat-form-field fxFlex="56" fxFlex.lt-lg="100" fxFlex.xs="100%" appearance="outline">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input [tabIndex]="4">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                    </mat-form-field>
                     </span>
            </div> 
              <div  id="table" class="example-container mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > S.No </th>
                            <td mat-cell *matCellDef="let row;let i=index" > <span>{{(i+1)}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="Organisation">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Organization </th>
                            <td mat-cell *matCellDef="let row" style="min-width: 200px;">  <span ><a (click)="selectClient($event,row)" style="color:darkslateblue; cursor: pointer;"> {{row.organization_name}}</a> </span></td>
                          </ng-container>
                        <ng-container matColumnDef="created_by">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef >Created By </th>
                            <td mat-cell *matCellDef="let row" style="min-width: 150px;">  <span> {{row.created_by}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="contact_person">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef >Contact Person </th>
                            <td mat-cell *matCellDef="let row" >  <span> {{row.contact_person}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="mobile_number">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef >Mobile Number </th>
                            <td mat-cell *matCellDef="let row"  style="white-space: nowrap;">  <span> {{row.mobile_number}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Address </th>                        
                            <td mat-cell *matCellDef="let row" style="white-space: initial;min-width: 250px;">  <span >{{row.address}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="country">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Country </th>                        
                            <td mat-cell *matCellDef="let row"  style="white-space: nowrap;">  <span >{{row.country}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="state">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > State </th>                        
                            <td mat-cell *matCellDef="let row" >  <span >{{row.state}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="city">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > City </th>                        
                            <td mat-cell *matCellDef="let row" >  <span >{{row.city}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="created_on" >
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Created On </th>                        
                            <td mat-cell *matCellDef="let row" style="white-space:nowrap;" >  <span >{{getFormattedDateTime(row.created_on||'')}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="updated_on">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Updated On </th>                        
                            <td mat-cell *matCellDef="let row" style="white-space:nowrap;">  <span >{{getFormattedDateTime(row.updated_on||'')}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="target_price">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef  style="text-align: right;"> Expected Price </th>                        
                            <td mat-cell *matCellDef="let row"  style="white-space: nowrap;text-align: right;">  <span >{{row.target_price | currency:'INR'}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="final_price">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef  style="text-align: right;"> Final Price </th>                        
                            <td mat-cell *matCellDef="let row"  style="white-space: nowrap;text-align: right;">  <span >{{row.final_price | currency:'INR'}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                     
                        <ng-container matColumnDef="notes">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef > Description </th>                        
                            <td mat-cell *matCellDef="let row" style="white-space: initial;min-width: 250px;">  <span >{{row.notes}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="collected_amount">
                            <th mat-header-cell class="header-cell" *matHeaderCellDef style="text-align: right;">Collected Amount </th>                        
                            <td mat-cell *matCellDef="let row" style="white-space: initial;text-align: right;">  <span >{{row.collected_amount | currency:'INR'}} </span> 
                                <!-- <span ><mat-icon (click)="view($event,row)">visibility</mat-icon> </span>    -->
                            </td>
                        </ng-container>
                      
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns3;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                        </tr>
                    </table>
               </div>
        </div>
        <div  *ngIf="isColumns">
            <form [formGroup]="reportpopupForm" autocomplete="off">
                <mat-checkbox (change)='onChange($event)' [checked]="ischecked"  [indeterminate] ="isindeterminate" color='primary'>Select All</mat-checkbox>
                <p formArrayName="earnings" *ngFor="let order of earningsFormArray.controls; let i = index">
                    <input type="checkbox" [formControlName]="i"> {{columns[i].name}}
                </p>
            
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" (click)="save()">Save</button>
                    <button class="btn btn-danger mr-2 mb-2" (click)="onNoClick()">Cancel</button>
                </div>
            </form>
        </div>
        <div  *ngIf="isview">
            <mat-card>
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between" >
                    <span fxLayoutAlign="end" class="mx-2 my-2">
                        <mat-icon class="styles" (click)="filter()">filter_alt</mat-icon>
                    </span>
                </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[0])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[0]==1"  (change)="empstatus($event,0)" color="primary"></mat-checkbox>Created By</div>
                    <ul *ngIf="ishiding[0] && (arrlist[0])=='1'" >
                        <p formArrayName="created_by" *ngFor="let order of created_by_FormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{created_by[i].empname}}
                        </p>
                            <!-- <div *ngFor="let b of employeestatus">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" ><mat-checkbox class="example-margin" color="primary"></mat-checkbox> {{b}}</div>
                            </div> -->
                    </ul>
                    <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[1])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[1]==1"  (change)="empstatus($event,1)" color="primary" ></mat-checkbox>Organisation </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[2])=='1'">  <mat-checkbox class="example-margin" [checked]="ishiding[2]==1"  (change)="empstatus($event,2)" color="primary"></mat-checkbox>Contact Person </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[3])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[3]==1"  (change)="empstatus($event,3)" color="primary"></mat-checkbox>Mobile Number </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[4])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[4]==1"  (change)="empstatus($event,4)" color="primary"></mat-checkbox>Address </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[5])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[5]==1"  (change)="empstatus($event,5)" color="primary"></mat-checkbox>Country  </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[6])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[6]==1"  (change)="empstatus($event,6)" color="primary"></mat-checkbox>State </div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[7])=='1'"> <mat-checkbox class="example-margin"  [checked]="ishiding[7]==1"  (change)="empstatus($event,7)" color="primary"></mat-checkbox>City</div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[8])=='1'"> <mat-checkbox class="example-margin" [checked]="ishiding[8]==1"  (change)="empstatus($event,8)" color="primary"></mat-checkbox>Created Time</div>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center" class="text2" *ngIf="(arrlist[9])=='1'"> <mat-checkbox class="example-margin"  [checked]="ishiding[9]==1"  (change)="empstatus($event,9)"  color="primary" ></mat-checkbox>Updated Time</div> -->
                    <h3 style="text-align:center;color: red;" *ngIf="arrlist.length>0">Note: Please select at least one option while filtering a field.</h3>
                    <div fxLayoutAlign="center">
                        <button class="btn btn-primary mr-2 mb-2" (click)="search()">Search</button>
                        <button class="btn btn-danger mr-2 mb-2" (click)="clearreport()">Cancel</button>
                    </div>
            </mat-card>
        </div>
      </mat-card>
    </form>
</div>