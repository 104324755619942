<div fxLayout="column" class="first-col">
  <mat-card>

    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Bank Master </mat-card-title>
      <div style="margin-right: 2%;" *ngIf="!isAdd">
        <button mat-icon-button style="padding-right: 40px;" (click)="add()" matTooltip="Add New">
          <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <form [formGroup]="bankForm" autocomplete="off" *ngIf="isAdd">
      <div class="tableP">
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Bank Name</mat-label>
            <input matInput placeholder="" formControlName="bankName" maxlength="100" required onlyAlphabets />
            <mat-error class="con-error" *ngIf="bankForm.controls.address1.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>IFSC Code</mat-label>
            <input type="text" matInput formControlName="ifsc" placeholder="" maxlength="10" required alphaNumericOnly
              onkeyup="this.value = this.value.toUpperCase();" />
            <mat-error class="con-error" *ngIf="bankForm.controls.address1.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Account Number</mat-label>
            <input matInput formControlName="accountNumber" maxlength="16" required onlyNumber />
            <mat-error class="con-error" *ngIf="bankForm.controls.address1.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>


        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Address1</mat-label>
            <input matInput placeholder="" formControlName="address1" maxlength="100" required />
            <mat-error class="con-error" *ngIf="bankForm.controls.address1.errors?.required">
              {{ msgEM1 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Address2</mat-label>
            <input type="text" matInput formControlName="address2" placeholder="" maxlength="100" />
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select required formControlName="country">
              <ng-container>
                <div *ngFor="let b of CountryDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.country }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="bankForm.controls.country.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>


        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
            <mat-label>State</mat-label>
            <mat-select required formControlName="state">
              <ng-container>
                <div *ngFor="let b of stateDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.state }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="bankForm.controls.state.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>City</mat-label>
            <mat-select required formControlName="city">
              <ng-container>
                <div *ngFor="let b of cityDetails">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.location }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="bankForm.controls.city.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.xs="100" fxFlex fxFlex.lt-lg="48" class="mx-2 my-2" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select required formControlName="status">
              <ng-container>
                <div *ngFor="let b of arrayValue">
                  <!-- <mat-option value="" disabled selected>Select </mat-option> -->
                  <mat-option [value]="b.id">{{ b.value }}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="con-error" *ngIf="bankForm.controls.status.errors?.required">
              {{ msgEM2 }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign.xs="center center">
          <mat-checkbox class="example-margin" (change)="epfchange()" formControlName="epf" [(ngModel)]="epfchecked">Is
            EPF</mat-checkbox>
          <mat-checkbox class="example-margin" (change)="esichange()" formControlName="esi" [(ngModel)]="esichecked">Is
            ESI</mat-checkbox>
          <mat-checkbox class="example-margin" (change)="ptchange()" formControlName="ptax" [(ngModel)]="ptchecked">Is
            Professional Tax</mat-checkbox>

        </div>


        <div fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" (click)="submit()" type="submit">
            <span *ngIf="!isEdit">Submit</span>
            <span *ngIf="isEdit">Update</span>
          </button>

          <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
            <span>Cancel</span>
          </button>
          <!--  <button class="btn btn-danger mr-2 mb-2">
              <span>Close</span>
            </button> -->
        </div>
      </div>

      <br>
    </form>
    <div class="tablePadding" *ngIf="!isAdd" style="margin-top:10px;">
      <div class="mat-elevation-z1 example-container">
        <div id="table" #table=''>
          <table id="table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef> S. No. </th>
              <td mat-cell *matCellDef="let row;let i = index;"> {{ (i+1) + (paginator.pageIndex * paginator.pageSize)
                }}</td>
            </ng-container>

            <ng-container matColumnDef="bankName">
              <th mat-header-cell *matHeaderCellDef>Bank Name</th>
              <td mat-cell *matCellDef="let row"><span>{{row.bankname}} </span></td>
            </ng-container>
            <ng-container matColumnDef="ifsc">
              <th mat-header-cell *matHeaderCellDef>IFSC Code</th>
              <td mat-cell *matCellDef="let row"><span>{{row.ifsccode}} </span></td>
            </ng-container>
            <ng-container matColumnDef="accountNumber">
              <th mat-header-cell *matHeaderCellDef>Account Number</th>
              <td mat-cell *matCellDef="let row"><span>{{row.accountnumber}} </span></td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row"><span>{{row.status}} </span></td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row; let i = index;">
                <button mat-icon-button [tabIndex]="[i+1]">
                  <mat-icon (click)="edit(row)" title="Edit">edit</mat-icon>
                </button>
                <!-- <button  mat-icon-button [tabIndex]="[i+1]" (keyup.enter)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)" *ngIf="enable == row.id">
                    <mat-icon (click)="submit($event,row.code,errorMessagesForm.controls.message.value.trim(),row.screenname)" title="Save" *ngIf="enable == row.id">save</mat-icon>

                </button> -->
                <!--  <button  mat-icon-button [tabIndex]="[i+1]">
                    <mat-icon   title="Cancel">cancel</mat-icon>
                </button> -->
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="5" style="text-align: center;">No data found</td>
            </tr>

          </table>
        </div>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

      </div>
    </div>
  </mat-card>
</div>