<div fxLayout="column" class="first-col">
<mat-card>

    <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-card-title class="title"> Induction </mat-card-title>
        <div style="margin-right: 2%;" *ngIf="isview">
           <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
                <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
              </button>
           </div>
   </mat-card-header>
   <div class="div-pad">
    <form [formGroup]="inductionForm" autocomplete="off">
      <div *ngIf="ishide">
        <br>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
          <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
            <mat-label>Program Type</mat-label>
            <input formControlName="programType" noLeadingWhitespace matInput placeholder="" maxlength="100" required>
            <mat-error class="con-error" *ngIf="inductionForm.controls.programType.errors?.required ">
              {{EM1}}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2"  (click)="setProgramsMaster()"   type="submit">
            <span>Submit</span>
          </button>
          <button  class="btn btn-danger mr-2 mb-2"  (click)="close()">
            <span >Cancel</span>
          </button>
          <br />
        </div>
      </div>
    </form>
    
      <div fxLayout="column" *ngIf="isview" >
        <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end" ngStyle.gt-md="padding-right:8px">
          <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
            <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline">
              <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
              <mat-label>Search</mat-label>
              <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
            </mat-form-field>
          </span>
        </div>
        <form [formGroup]="inductionForm" autocomplete="off">
      <div fxLayout="column" class="table-pad">
        <div class="mat-elevation-z1 example-container ">
           <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>

              <ng-container matColumnDef="programtype">
                <th mat-header-cell *matHeaderCellDef mat-sort-header >
                  Program Type </th>
                <td mat-cell *matCellDef="let row" class="no-break">
                  <span *ngIf="enable != row.id">{{row.description}} </span>
                  <span *ngIf="enable == row.id">
                                  <mat-form-field appearance="outline">
                                  <input formControlName="programType" matInput placeholder="" maxlength="100">
                               </mat-form-field>
                              </span>

                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 5%;"> Status </th>
                <td mat-cell *matCellDef="let row"  class="no-break">
                   <mat-radio-group  >
                    <mat-radio-button class="mr-2" color="primary" *ngFor="let halfs of arrayValue;index as i;" (change)="status(halfs.id,row)" [checked]="halfs.id == row.status"  [value]="halfs.id"  >{{halfs.name}}</mat-radio-button>
                  </mat-radio-group>

                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef >  </th>
                <td mat-cell *matCellDef="let row" class="no-break">
                   <mat-icon (click)="edit($event,row)" title="Edit" *ngIf="enable != row.id">edit</mat-icon>
                  <mat-icon (click)="save($event,row)" title="Save" *ngIf="enable == row.id">save</mat-icon>
                  <mat-icon (click)="canceledit($event,row.id)" title="Cancel" *ngIf="enable == row.id">cancel</mat-icon>

                </td>
              </ng-container>
              <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4" style="text-align: center;">No data found</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
        </div>
       </div>
      </form>
    </div>

  </div>


</mat-card>
</div>
