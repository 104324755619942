import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numberDotOnly]'
})
export class NumberDotOnlyDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private control: NgControl) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const key = event.key;
    if (!key.match(/^\d$|^\.$/)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (!value.match(this.regex)) {
      input.value = value.replace(/[^0-9.]/g, '');
      if (this.control && this.control.control) {
        this.control.control.setValue(input.value);
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedInput = clipboardData.getData('text');
      const next: string = (this.control?.control?.value || '') + pastedInput;
      if (!String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
