<div fxLayout="column" class="first-col">
       <mat-card>
              <mat-card-header class="headerbox">
                      <mat-card-title class="title"> Attendance Calendar </mat-card-title>
                    </mat-card-header>
            
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="1px">
                     <div fxFlex="55" fxFill>
                            <div class='demo-app-main'>

                                   <full-calendar #calendar fxLayout="column" [options]='calendarOptions'>
                                   </full-calendar>
                            </div>
                            <div style="display: flex;padding: 1em" class="example-container">
                                   <mat-icon class="green-icon">check_circle</mat-icon>Present &nbsp;&nbsp;
                                   <mat-icon class="red-icon">check_circle</mat-icon> Absent &nbsp;&nbsp;
                                   <mat-icon class="blue-icon">check_circle</mat-icon><span class="no-break">Week-Off</span> &nbsp;&nbsp;
                                   <mat-icon class="yellow-icon">check_circle</mat-icon> Holiday &nbsp;&nbsp;
                                   <mat-icon class="orange-icon">check_circle</mat-icon> Leave &nbsp;&nbsp;
                                                 </div>
                            <div style="display: flex;padding: 1em" class="example-container">
                                   <mat-icon class="light-icon">check_circle</mat-icon> Half Day &nbsp;&nbsp;
                                   <mat-icon class="wp-icon">check_circle</mat-icon> Week-off Present &nbsp;&nbsp;
                                   <mat-icon class="hp-icon">check_circle</mat-icon> Holiday Present
                            </div>

                     </div>

                     <div class="sub-section-2" fxLayout="column" fxFlex="45">
                      <div fxLayout="column">
                        <div fxLayout="row" class="clock-attendance" [style.marginTop.px]="mobileQuery.matches ? 0 : 10">
                               <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                                      fxLayoutAlign="space-between">
                                      <div fxLayout="row" fxFlex="50">
                                             <p>
                                                    <mat-icon>alarm</mat-icon>
                                             </p>
                                             <p><b> Check In/Out</b>
                                             </p>
                                      </div>
                                      <div fxLayout="row" fxFlex="50" fxLayoutAlign="end center">
                                             <p><b>{{todayDate }}</b></p>
                                      </div>
                               </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" class="time-attendance">
                               <p> First In {{firstIn}}</p>
                               <p>Last Out {{lastOut}}</p>
                        </div>
                 </div>

                       <div fxLayout="column">
                         <div fxLayout="row" class="clock-attendance">
                           <div style="padding-top:10px; color: white;height: 45px;" fxFlex="100" fxLayout="row"
                                fxLayoutAlign="space-between">
                             <div fxLayout="row" fxFlex="50">
                               <p>
                                 <mat-icon>filter_tilt_shift</mat-icon>
                               </p>
                               <p><b> Shifts</b>
                               </p>
                             </div>

                           </div>
                         </div>

                       </div>
                       <div fxLayout="column">
                        <div class="time-attendance">
                                    <span *ngFor="let item of shiftDetails">
                                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between" class="time-attendance">
                                      <div> <span><b>{{item.duration}}</b></span><br />
                                          <span style="white-space: nowrap;">{{item.fromdate |
                                          date:'dd-MM-yyyy'}} to {{item.todate |
                                                 date:'dd-MM-yyyy'}}</span>
                                          </div>
                                      <div style="white-space: nowrap;"><span><b>{{item.shiftname}}</b></span><br />
                                             <span> {{item.fromtime}}-{{item.totime}}</span>
                                      </div>
                                    </div>
                                    </span>
                                  </div>

                       </div>



                            <div fxLayout="column" style="height: 300px;">

                                   <div fxLayout="row" fxLayoutAlign="space-between"  class="pending-attendance">
                                          <div style="padding-top:10px; color: white;height: 45px;" fxLayout="row">
                                                 <p>
                                                        <mat-icon>notifications</mat-icon>
                                                 </p>
                                                 <p><b>Pending Attendance</b>
                                                 </p>
                                          </div>

                                   </div>
                                   <cdk-virtual-scroll-viewport class="example-viewport" style="height: 300px;"
                                          itemSize="5">

                                          <mat-list class="time-attendance">
                                                 <ng-container *cdkVirtualFor="let item of notificationsData">
                                                        <mat-list-item>
                                                               <h3 matLine> {{ item.absent_date |
                                                                      date:'dd-MM-yyyy'}} </h3>

                                                               <p> <a routerLink="/" (click)="onRequestClick(item)">Send
                                                                             a
                                                                             request</a>
                                                        </mat-list-item>
                                                 </ng-container>
                                          </mat-list>
                                   </cdk-virtual-scroll-viewport>

                            </div>

                     </div>
              </div>

       </mat-card>
</div>
