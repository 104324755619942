 <!-- <div style="overflow-y: scroll;"> -->
    <div fxLayoutAlign="center" style="width: auto;"> 
        <h3> CTC Break Down</h3>
    </div>
    <div style="height: 300px;">
        <div class="mat-elevation-z1">
        <!-- <div fxLayoutAlign="center"  fxLayout="row" style="margin-left: 200px;"><p>Earnings</p></div><br> -->
        <table mat-table id="table" [dataSource]="dataSource" matSort  #table>
            
            <ng-container matColumnDef="component">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;"> Component</th>
                <td mat-cell *matCellDef="let element" > {{element.component_name}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">Component Type </th>
                <td mat-cell *matCellDef="let element" > {{element.component_type}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;">Monthly </th>
                <td mat-cell *matCellDef="let element" > {{element.monthly_amount_value}} </td>
            </ng-container>
            <ng-container matColumnDef="annual">
                <th mat-header-cell *matHeaderCellDef style="background-color: #28acaf;color: white;font-size: medium;"> Annual </th>
                <td mat-cell *matCellDef="let element" > {{element.amount_value}} </td>
            </ng-container>
           
            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No data found</td>
            </tr> 
        </table>
       </div><br>
       <div  fxLayoutAlign="center">
        <button class="mx-2 my-2 btn btn-primary" id="button" (click)="copydata()" value="copy">Ok</button>
     </div>
    </div>
        <!-- <table mat-table [dataSource]="deductionDataSource" matSort  style="margin-right: 200px;">
            
            <ng-container matColumnDef="component">
                <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> </th>
                <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.component_name}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> </th>
                <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.amount_value}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> </th>
                <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.monthly_amount_value}} </td>
            </ng-container>
            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    
        <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No data found</td>
            </tr> 
        </table> -->
        
        
      
    <!-- </div> -->
    


 <!-- </div> -->

