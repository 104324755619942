import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl,ValidatorFn,ValidationErrors } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { Location } from '@angular/common';
import { AdminService } from 'src/app/modules/admin/admin.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from 'src/app/modules/leaves/dialog/confirmation/confirmation.component';
import { CrmService } from '../../crm.service';
import { MatDialog } from '@angular/material/dialog';
import { DayTable } from '@fullcalendar/daygrid';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { MatSelect } from '@angular/material/select';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-crm-device-approvals',
  templateUrl: './crm-device-approvals.component.html',
  styleUrls: ['./crm-device-approvals.component.scss'],
})
export class CrmDeviceApprovalsComponent implements OnInit {
  devicerequestform:any= UntypedFormGroup;
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  displayedColumns: string[] = ['id', 'emp_name', 'requestDate', 'device_changed', 'reason', 'status', 'action'];
  dataSource: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  arrayList: any = [];
  userSession: any;
  currentDate: Date = new Date();
  userData: any;
 
  pageLoading = true;
  isRequestView = false;
  isCommentView = false;
  isStatus = false;
  isEditView = false;
  uniqueId: any = '';
  requiredField: any;
  requiredOption: any;
  worklocationDetails: any[] = [];
  myDateFilter:any;  
  employeelist: any;
  workeddays: any;
  regularizationdays:any;
  companyDBName: any = environment.dbName;
  employeeEmailData: any = [];
  employeeId: any;
  workTypename: any;
  isWFO = false;
  requestDate:any = null;
  approval_status:any = 'Approve';
  isdisable:boolean=true;
  searchForm:  any = UntypedFormGroup;
  selectedManagers: any = [];
  managersDetails: any[] = [];
  companyName:any;
  selectedLocations: any = [];
  is_super_admin:any = false;
  mgrIshide: boolean = false;
  locationIshide: boolean = false;
  locationIschecked: boolean = false;
  mgrIschecked: boolean = false;
  approvalStatus:any = 'Pending';
  canManagerApproveDevices:any = 1;
  constructor(private formBuilder: UntypedFormBuilder,public dialog: MatDialog, public reportsService: ReportsService,
     public datePipe: DatePipe, private router: Router,  private crm:CrmService, private companyService: CompanySettingService,
    private location: Location, private adminService: AdminService,) {
      this.companyName = sessionStorage.getItem("companyName");
  }

  ngOnInit(): void {
    this.searchForm=this.formBuilder.group({
      companylocation: ["", Validators.required],
      managers:["", Validators.required],
      Users: ['0']
      });
    this.isEditView = false;
    this.isRequestView = true;
    this.userData = this.location.getState();
    this.userSession = JSON.parse(sessionStorage.getItem('user') || '');

   
   
    
    this.todayWithPipe = this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
    this.devicerequestform = this.formBuilder.group(
      {
        requestDate: [{ value:'', disabled: true }, Validators.required],
        empid: [{ value:'', disabled: true }, Validators.required],
        requestid:[{ value:'', disabled: true }, Validators.required],
        empname:[{ value:'', disabled: true }, Validators.required],
        device_changed:[{ value:'', disabled: true }, Validators.required],
        //approval_status:[{ value:'', disabled: true }, Validators.required],
        //approval_by:[{ value:'', disabled: true }, Validators.required],
        reason: ['', [Validators.required]]
     
      });
    if (this.userData.userData != undefined) {
      this.devicerequestform = this.formBuilder.group(
        {
          requestDate: [{ value:new Date(this.userData.userData.requestDate), disabled: true }, Validators.required],
          empid: [{ value:this.userData.userData.empid, disabled: true }, Validators.required],
          requestid:[{ value:this.userData.userData.requestid, disabled: true }, Validators.required],
          empname:[{ value:this.userData.userData.empname, disabled: true }, Validators.required],
          device_changed:[{ value:this.userData.userData.device_changed,  disabled: true }, Validators.required],
          //approval_status:[{ value:this.userData.userData.approval_status, disabled: true }, Validators.required],
          //approved_by:[{ value:this.userData.userData.approved_by, disabled: true }, Validators.required],
          reason:[{ value:this.userData.userData.reason, disabled: true }, Validators.required],
          
        });
    }

    if (this.userSession.is_super_admin =="1"){
      this.canManagerApproveDevices = 1;
      this.is_super_admin = true;
      this.getWorkLocation();
    }
    else {
      if(this.userSession.device_approval_for_super_admin_only === '1'){
        this.canManagerApproveDevices = 0;
        this.approvalStatus = 'Approved';
      }
      else this.canManagerApproveDevices = 1;
      this.Searchform(this.approvalStatus);
    }
    
  }

  ngAfterViewInit() {

  }
  getStatus(type:any){
    let status = false;
    if(this.approvalStatus === type)
      status = true;
    return status;

  }
 
  SearchformForSuperAdmin(){
    if(this.is_super_admin){
      this.dataSource = new MatTableDataSource();
      this.isEditView = false;
      this.isRequestView = true;
      let obj={
        "location_ids":JSON.stringify(this.selectedLocations),
        "manager_ids" :JSON.stringify(this.selectedManagers),
        selected_date: this.todayWithPipe,
        selected_type: this.approvalStatus
        };
      this.pageLoading=true;
        this.crm.getCrmDeviceApprovalsForSelectedManagers(obj).subscribe((res: any) => {
          this.dataSource = new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.pageLoading=false;
        });
      }
  }
  
Searchform(type:any) {
  this.approvalStatus = type;
  if(this.is_super_admin){
    this.SearchformForSuperAdmin();
    return;
  }
  else {
    this.dataSource = new MatTableDataSource();
    this.isEditView = false;
      this.isRequestView = true;
    let data = {
      manager_id:this.userSession.id,
      selected_date: this.todayWithPipe,
      selected_type: type
    }
    this.pageLoading=true;
    this.crm.getCrmDeviceApprovals(data).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
    });
  }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  saveRequest() {
   if (this.devicerequestform.invalid) {
      return;
    } else {

      let obj = {
       // "empid": this.userSession.id ?? '',
       requestId: this.devicerequestform.controls.requestid.value,
       managerId:this.userSession.id,
       approvalStatus: this.approval_status,
       approvedOn: this.todayWithPipe,
       reason_details: this.devicerequestform.controls.reason.value,
      };

      this.crm.setCrmDeviceApprovalStatusForEmployee(obj).subscribe((res: any) => {
        if(res.status){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: res.data && res.data[0] ? res.data[0].message:'Data saved successfully.'
          });
          dialogRef.afterClosed().subscribe(result => { this.resetform(); });
        }
          
      })
    }
  }

  resetform() {
    window.location.reload();
  }

  getPageSizes(): number[] {

  var customPageSizeArray = [];
  if (this.dataSource.data.length > 5) {
    customPageSizeArray.push(5);
  }
  if (this.dataSource.data.length > 10) {
    customPageSizeArray.push(10);
  }
  if (this.dataSource.data.length > 20) {
    customPageSizeArray.push(20);
  }
  customPageSizeArray.push(this.dataSource.data.length);
  return customPageSizeArray;
  }
  
  async editRequest(event: any) {
    this.uniqueId = event.id;    
    this.isRequestView = false;
    this.isEditView = true;
    this.isCommentView = false;    
    this.isdisable = false ;
    this.devicerequestform.controls.requestDate.setValue(new Date(event.requestDate));
     
    this.devicerequestform.controls.empid.setValue(event.emp_id); 
    this.devicerequestform.controls.requestid.setValue(event.id); 
    this.devicerequestform.controls.empname.setValue(event.emp_name); 
    this.devicerequestform.controls.device_changed.setValue(event.device_changed); 
    this.devicerequestform.controls.reason.setValue(event.reason); 
    //approval_status
  }

  getManagersList() {
    this.managersDetails =[];
    this.selectedManagers = [];
    this.mgrIshide = false;
    let data={
      location_id :JSON.stringify(this.selectedLocations),
      companyName : this.companyName
    }
    this.reportsService.getManagersListByLocation(data).subscribe((res: any) => {
          if (res.status && res.data.length > 0) {
              this.managersDetails = res.data;              
       }
    });
    this.Searchform(this.approvalStatus);
    
  }
  onchangeManager(value:any) {
  
    this.selectedManagers=[];
      let mgr = this.searchForm.controls.managers.value;
      mgr.forEach((e: any) => {
        this.selectedManagers.push(Number(e.manager_emp_id));
      });
      if(this.selectedManagers.length === this.managersDetails.length){
        this.mgrIshide = true;
      } else this.mgrIshide = false;
      this.Searchform(this.approvalStatus);
  }

  
  onchangeLocation(value:any) {
    this.selectedLocations=[];
    let location = this.searchForm.controls.companylocation.value;
    location.forEach((e: any) => {
      this.selectedLocations.push(e.id);
    });
    if(this.selectedLocations.length === this.worklocationDetails.length){
      this.locationIshide = true;
    } else this.locationIshide = false;
    this.getManagersList();
}

getWorkLocation() {
  this.managersDetails =[];
  this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result:any) => {
    this.worklocationDetails = result.data;
  })
} 
managerSelectAll(select: MatSelect, values: any, array: any) {
    this.mgrIshide = true;
    this.mgrIschecked = true;
    select.value = values;
    array = values;
    this.searchForm.controls.managers.setValue(array)
    let managerdata = this.searchForm.controls.managers.value;
    managerdata.forEach((e: any) => {
    this.selectedManagers.push(Number(e.manager_emp_id));
    });
    this.Searchform(this.approvalStatus);
``}
  
managerDeselectAll(select: MatSelect) {
  this.mgrIshide = false;
  this.mgrIschecked = false
  this.selectedManagers = [];
  select.value = [];
  this.searchForm.controls.managers.setValue('')
  this.selectedManagers=[];
  this.Searchform(this.approvalStatus);
}

locationSelectAll(select: MatSelect, values: any, array: any) {
  this.locationIshide = true;
  this.locationIschecked = true;
  select.value = values;
  array = values;
  this.searchForm.controls.companylocation.setValue(array)
  let locations = this.searchForm.controls.companylocation.value;
  locations.forEach((e: any) => {
    this.selectedLocations.push(e.id);
  });
  this.getManagersList();
}

locationDeselectAll(select: MatSelect) {
  
  this.managersDetails =[];
  this.locationIshide = false;
  this.locationIschecked = false
  this.selectedLocations = [];
  select.value = [];
  this.searchForm.controls.companylocation.setValue('')
  this.Searchform(this.approvalStatus);
}
}
