

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AttendanceService } from '../../attendance.service';
import { UserData } from '../../models/EmployeeData';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { LeavesService } from 'src/app/modules/leaves/leaves.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanySettingService } from 'src/app/services/companysetting.service';
import { HalfdayDialogboxComponent } from '../../models/halfday-dialogbox/halfday-dialogbox.component';
import { MatRadioChange } from '@angular/material/radio';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-hr-attendance-regularization',
  templateUrl: './hr-attendance-regularization.component.html',
  styleUrls: ['./hr-attendance-regularization.component.scss'],
})
export class HrAttendanceRegularizationComponent implements OnInit {
  attendanceRequestForm: any = UntypedFormGroup;
  displayedColumns: string[] = [
    'sno',
    'empoyeeid',
    'empname',
    'shift',
    'intime',
    'outtime',
    'action',
  ];
  displayedColumnsForApprovals: string[] = ['select','sno','empoyeeid','empname','shift', 'intime', 'outtime','action'];
  dataSource: MatTableDataSource<UserData> = <any>[];
  dataSource2: MatTableDataSource<UserData> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  arrayList: UserData[] = [];
  userSession: any;
  titleName: string = '';
  reason: any;
  requestData: any;
  leavetypeid: any;
  iseditouttime: boolean = false;
  enable: any = null;
  originalleavecount: any;
  leavecontvalue: any;
  isedited: boolean = false;
  isWeekOffOption: boolean = false;
  isHalfdayOption: boolean = false;
  isRadio: boolean = false;
  fullDayList :any = [];
  halfDayList :any = [];
  halfdayData:any;
  isHalfday:any;
  selection = new SelectionModel<any>(true, []);
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private LM: LeavesService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private companyService: CompanySettingService,
  ) {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.companyName = sessionStorage.getItem("companyName");
    // for(let i = 0; i <this.userSession.roles.length;i++){
    //   if(this.userSession.roles[i].role_name == "HR Manager"){
    //     this.getEmployeesForRegularization(this.userSession.roles[i].role_id)
    //   }
    // }
  }
  companyName: any;
  worklocationDetails: any = [];
  selectedLocation: any;
  isFullday: boolean = true;
  A1:any;
  A2:any;
  L1:any;
  L2:any;
  isHalfdayView:boolean = false;
  isPendingApprovalsView:boolean = false;
  selectedEmps: any = [];
  btnHide:boolean=false;
  btnDisable:boolean = false;
  searchControl = new FormControl('');
  filteredLocations: any = [];
  ngOnInit(): void {
    this.attendanceRequestForm = this.formBuilder.group({
      intime: [''],
      outtime: [''],
      location: [''],
      search: [''],
      intimeforApproval:[''],
      outtimeForApproval:[''],
    });
    this.getWorkLocation();
    this.attendanceRequestForm.get('location')?.valueChanges.subscribe((selectedValue: any) => {
      this.isRadio = true;
      this.selectedLocation = selectedValue;
      if(this.isFullday ==true  ){
        this.getEmployeesForRegularization();
      }else{
        this.getHalfdayEmployeesForRegularization();
      }
     
    });
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterLocations(searchText);
    });
  }
  getWorkLocation() {
    this.companyService.getactiveWorkLocation({ id: null, companyName: this.companyName }).subscribe((result) => {
      this.worklocationDetails = result.data;
      this.filteredLocations = this.worklocationDetails
    })
  }

  edit(data: any) {
    this.btnHide =true;
    let checkin = data.logintime != null ? data.logintime.split(':') : '';
    let checkout = data.logouttime != null ? data.logouttime.split(':') : '';
    let checkindata =
      data.logintime != null ? checkin[0] + checkin[1] + checkin[2] : '';
    let checkoutdata =
      data.logouttime != null ? checkout[0] + checkout[1] + checkout[2] : '';
    this.enable = data.id;
    this.attendanceRequestForm.controls.intime.setValue(checkindata);
    this.attendanceRequestForm.controls.outtime.setValue(checkoutdata);
  }

  canceledit() {
    this.enable = null;
    this.iseditouttime = false;
    this.btnHide =false;
  }

  saveRegularization(data: any) {
    let outTimeValid = this.attendanceRequestForm.controls.outtime.value;
    if (outTimeValid != null && outTimeValid != '' && outTimeValid != '000000') {

      let IHH = Number(
        this.attendanceRequestForm.controls.intime.value[0] +
        this.attendanceRequestForm.controls.intime.value[1]
      );
      let IMM = Number(
        this.attendanceRequestForm.controls.intime.value[2] +
        this.attendanceRequestForm.controls.intime.value[3]
      );
      let ISS = Number(
        this.attendanceRequestForm.controls.intime.value[4] +
        this.attendanceRequestForm.controls.intime.value[5]
      );
      let OHH = Number(
        this.attendanceRequestForm.controls.outtime.value[0] +
        this.attendanceRequestForm.controls.outtime.value[1]
      );
      let OMM = Number(
        this.attendanceRequestForm.controls.outtime.value[2] +
        this.attendanceRequestForm.controls.outtime.value[3]
      );
      let OSS = Number(
        this.attendanceRequestForm.controls.outtime.value[4] +
        this.attendanceRequestForm.controls.outtime.value[5]
      );
      let str1 = data.shift_start_time.split(':');
      let str2 = data.shift_end_time.split(':');

      // if (Number(str1[0] >= IHH)) {

      if (Number(str2[0] >= OHH)) {
        if (this.btnDisable) {
          return;
        }
        this.selectedEmps = [];
        let setdata = {
          emp_id: data.empid,
          shiftid: data.shiftid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          logintime: IHH + ':' + IMM + ':' + ISS,
          logouttime: OHH + ':' + OMM + ':' + OSS,
          worktype: data.worktypeid,
          raisedby: this.userSession.id,
          approvercomments: '',
          actionby: this.userSession.id,
          status: 'Approved',
          halfday: data.halfday == null ? 0 : data.halfday,
          locationid: data.locationid,
        };
        this.selectedEmps.push(setdata);

        let obj = {
          input_data: this.selectedEmps,
        }
        this.btnDisable = true;
        this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {
          
          if (result.status && result.data[0].validity_status == 1) {
            this.getEmployeesForRegularization();
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Attendance Regularization approved successfully',
            });
            this.btnDisable = false;
          } else if(result.status && result.data[0].validity_status== 0){
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Record already exist',
            });
            this.btnDisable = false;
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to save data',
            });
            this.btnDisable = false;
          }
        });
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Selected Time must less than shift time.',
        });
      }

      // } else {
      //   let dialogRef = this.dialog.open(ReusableDialogComponent, {
      //     position: { top: `70px` },
      //     disableClose: true,
      //     data: 'In time and Out time between 24 hours',
      //   });
      // }

    } else {
      let dialogRef = this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: "Please enter out time."
      });
    }
  }

  applyFilter(event: Event) {
    if(this.isHalfdayView ==false){
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }else{
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource2.filter = filterValue.trim().toLowerCase();
  
      if (this.dataSource2.paginator) {
        this.dataSource2.paginator.firstPage();
      }
    }

  }

  changeTab(elment: UserData) {
    this.router.navigate(['/Attendance/Approval'], {
      state: { userData: elment, url: 'ApprovedHistory' },
    });
  }

  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length < 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }

  setLeaveRegularization(data: any) {
    this.getLeavetypeIdForRegularization(data);
  }

  /**For getting full day employees list */
  getEmployeesForRegularization() {
    this.attendanceRequestForm.controls.search.setValue();
    this.fullDayList = [];
    this.halfDayList = [];
    let empList = [];
    let data = {
      empid: this.userSession.id,
      roleid: 6,
      location_id: this.selectedLocation,
    };
    this.spinner.show();
    this.attendanceService.getEmployeesForRegularization(data).subscribe((result: any) => {
      if (result.status) {
        empList = result.data;
        empList.forEach((e: any) => {
          if (e.halfday == null || (e.halfday==0 && e.isedit == 1)) {
            this.fullDayList.push(e);

          } else if (e.halfday !=null) {

            this.halfDayList.push(e);
          }
        })
        this.dataSource = new MatTableDataSource(this.fullDayList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
        if (result.week_off_option[0].weekoffs_in_regularization == 1) {
          this.isWeekOffOption = true;
        }
        this.spinner.hide();
      }
      this.spinner.hide();
    });
  }

  /**delete_attendance_for_regularization */
  deleteattendance(d: any) {
    // let data = {
    //   empid:1,
    //   regularaizedate:""
    // }
    // this.attendanceService.deleteAttendanceForRegularization(data).subscribe((result:any)=>{
    //    if(result.status){
    //     // return true;
    //    }
    // })
  }

  /**get_leavetype_id_for_regularization */
  getLeavetypeIdForRegularization(event: any) {
    let data = {
      employee_id: event.empid,
    };
    this.attendanceService.getLeavetypeIdForRegularization(data).subscribe((result: any) => {
        if (result.status) {
          this.leavetypeid = result.data[0].leavetypeid;
          this.setValidateLeave(event);
        }
      });
  }

  setValidateLeave(data: any) {
    var errorMessage = [];
    let obj = {
      empid: data.empid,
      fromDate: data.attendancedate,
      toDate: data.attendancedate,
      fromDateHalf: 0,
      toDateHalf: 0,
      leaveTypeId: this.leavetypeid,
      document: false,
      leaveId: null,
    };
    this.originalleavecount = '';
    this.LM.setValidateLeave(obj).subscribe(async (result: any) => {
      if (result && result.status) {
        var validLeave = JSON.parse(result.data[0].count_json);
        this.leavecontvalue = JSON.parse(result.data[0].count_json)[0].leavecount;
        this.isedited = false;

        /**
         * This condition is used for sandwich leave
         */

        let datas = {
          employee_id: data.empid,
          leavetype: this.leavetypeid,
          fromdate: data.attendancedate,
          todate: data.attendancedate,
          fromhalfdayleave: 0,
          tohalfdayleave: 0,
          leavecount: this.leavecontvalue,
          leavestatus: 'Approved',
          contactnumber: '',
          regularization_date: data.attendancedate,
        };

        this.attendanceService.setLeaveRegularization(datas).subscribe((result: any) => {
          if (result.status) {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Leave Regularization updated successfully',
            });
            this.getEmployeesForRegularization();
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: 'Unable to applied leave',
            });
          }
        });
      }
    });
  }

  setWeekOffRegularization() {

    if (this.selection.selected.length > 0) {
      this.selectedEmps = [];
      this.selection.selected.forEach((e: any) => {
        let setdata = {
          emp_id: e.empid,
          attdate: e.attendancedate,
        
        }
        this.selectedEmps.push(setdata)
      });
  
      let data = {
        input_data: this.selectedEmps,
      }
      this.spinner.show();
      this.attendanceService.setRegularizationWeekoffs(data).subscribe((result: any) => {
        if (result.status && result.data.successstate == 1) {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Week-off updated successfully',
          });
          this.selection.clear();
          this.getEmployeesForRegularization();
          this.spinner.hide();
        } else {
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to save data',
          });
          this.selection.clear();
          this.getEmployeesForRegularization();
          this.spinner.hide();
        }
  
      })
    } else {
      this.dialog.open(ReusableDialogComponent, {
        position: { top: `70px` },
        disableClose: true,
        data: 'Select atleast one employee.',
      });
      return;
    }
  }

  halfDayRegularization(data: any) {
    this.halfdayData = data;
    let givenData = {
      "halfDaytype":data.halfday,
      "attendancetype":true,
       }
          let dialogRef = this.dialog.open(HalfdayDialogboxComponent, {
              width: '500px',position:{top:`100px`},
              disableClose: true,
              data:givenData

            });
            dialogRef.afterClosed().subscribe(result => {

              if (result != undefined) {
                if (result == 'attendance') {
                  this.halfDayAttendance();

                }else{
                  this.halfDayLeave();

                }
              }
            });
  }
  
  halfDayAttendance(){
    this.setHalfdayAttendanceRegularization(this.halfdayData);
  }

  halfDayLeave(){
    this.setHalfdayLeaveRegularization(this.halfdayData);
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.isFullday =true;
      this.isPendingApprovalsView =false;
      this.isHalfdayView =false
      this.getEmployeesForRegularization();

     } else if(event.value == 2){
      this.isHalfdayView = true;
      this.isFullday =false;
      this.isPendingApprovalsView =false;
      this.getHalfdayEmployeesForRegularization();

     } else if(event.value ==3){
     
      this.isHalfdayView =false;
      this.isFullday =false;
      this.isPendingApprovalsView =true;
      this.getPendingAttendanceRegularizationsForManager();
     }
 }

   /**For getting halfday employees list */
   getHalfdayEmployeesForRegularization() {
    this.attendanceRequestForm.controls.search.setValue('');
    this.fullDayList = [];
    this.halfDayList = [];
    let empList = [];
    this.spinner.show();
    let data = {
      empid: this.userSession.id,
      roleid: 6,
      location_id: this.selectedLocation,
    };
    this.attendanceService.getHalfdayEmployeesForRegularization(data).subscribe((result: any) => {
      if (result.status) {
        empList = result.data;
        empList.forEach((e: any) => {
          if (e.halfday != null) {
            this.halfDayList.push(e);
          } 
        })
        this.dataSource2 = new MatTableDataSource(this.halfDayList);
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
        this.pageLoading = false;
        this.spinner.hide();
      }
    });
  }

  /*** halfday attendance save */
  setHalfdayAttendanceRegularization(data: any) {
    if (this.btnDisable) {
      return;
    }
    if(data.halfday =="A1" || data.halfday =="L1" ){
      this.isHalfday = 2;

    } else if(data.halfday =="A2" || data.halfday =="L2"){
      this.isHalfday = 1;
    }

    this.selectedEmps = [];
    let setdata = {
      emp_id: data.empid,
      shiftid: data.shiftid,
      fromdate: data.attendancedate,
      todate: data.attendancedate,
      logintime: data.halfday_starttime,
      logouttime: data.halfday_endtime,
      worktype: data.worktypeid,
      raisedby: data.empid,
      approvercomments: '',
      actionby: this.userSession.id,
      status: 'Approved',
      halfday:this.isHalfday,
      locationid: data.locationid,
    };
    this.selectedEmps.push(setdata);

    let obj = {
      input_data: this.selectedEmps,
    }
    this.spinner.show();
    this.btnDisable = true;
    this.attendanceService.setAttendanceRegularizationNew(obj).subscribe((result: any) => {

        if (result.status && result.data[0].validity_status== 1 ) {

          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Attendance regularization updated successfully',
          });
          this.spinner.show();
          this.getHalfdayEmployeesForRegularization();
          this.spinner.hide();
          this.btnDisable = false;
        } else if(result.status && result.data[0].validity_status== 0){
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Record already exist',
          });
          this.btnDisable = false;
        }
         else{
          let dialogRef = this.dialog.open(ReusableDialogComponent, {
            position: { top: `70px` },
            disableClose: true,
            data: 'Unable to update regularization',
          });
          this.btnDisable = false;
        }
      });
    }

  /*** halfday leave save */
  setHalfdayLeaveRegularization(data: any) {
    this.getHalfLeavetypeIdForRegularization(data);
  }

    /**get_leavetype_id_for_regularization */
    getHalfLeavetypeIdForRegularization(event: any) {
      let data = {
        employee_id: event.empid,
      };
      this.attendanceService.getLeavetypeIdForRegularization(data).subscribe((result: any) => {
          if (result.status) {
            this.leavetypeid = result.data[0].leavetypeid;
            this.setHalfValidateLeave(event);
          }
        });
    }
    /*** halfday leave validate */
    setHalfValidateLeave(data: any) {
      var errorMessage = [];
      if(data.halfday =="A1" || data.halfday =="L1" ){
        this.L1 = 0;
        this.L2 = 1;
      } else if(data.halfday =="A2" || data.halfday =="L2"){
        this.L1 = 1;
        this.L2 = 0;
      }

      let obj = {
        empid: data.empid,
        fromDate: data.attendancedate,
        toDate: data.attendancedate,
        fromDateHalf: this.L2,
        toDateHalf: this.L1,
        leaveTypeId: this.leavetypeid,
        document: false,
        leaveId: null,
      };
      this.originalleavecount = '';
      this.LM.setValidateLeave(obj).subscribe(async (result: any) => {
        if (result && result.status) {
          var validLeave = JSON.parse(result.data[0].count_json);
          this.leavecontvalue = JSON.parse(result.data[0].count_json)[0].leavecount;
          this.isedited = false;
          let value = result.data[0];
            let fhl = value.fhday;
            let shl = value.thday;
          /**
           * This condition is used for sandwich leave
           */
  
          let datas = {
            employee_id: data.empid,
            leavetype: this.leavetypeid,
            fromdate: data.attendancedate,
            todate: data.attendancedate,
            fromhalfdayleave: fhl,
            tohalfdayleave: shl,
            leavecount: this.leavecontvalue,
            leavestatus: 'Approved',
            contactnumber: '',
            regularization_date: data.attendancedate,
          };
          this.attendanceService.setLeaveRegularization(datas).subscribe((result: any) => {
            if (result.status) {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Half day leave regularization updated successfully',
              });
              this.getHalfdayEmployeesForRegularization();
            } else {
              let dialogRef = this.dialog.open(ReusableDialogComponent, {
                position: { top: `70px` },
                disableClose: true,
                data: 'Unable to apply leave',
              });
            }
          });
        }
      });
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
masterToggle() {
  // if there is a selection then clear that selection
  if (this.isSomeSelected()) {
    this.selection.clear();
  } else {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
}
isSomeSelected() {
  return this.selection.selected.length > 0;
}
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}

approveRegularization(){
  if (this.btnDisable) {
    return;
  }
  if (this.selection.selected.length > 0) {
    this.selectedEmps = [];
    this.selection.selected.forEach((e: any) => {
      let setdata = {
        emp_id: e.empid,
        shiftid: e.shiftid,
        fromdate: e.fromdate,
        todate: e.fromdate,
        logintime: e.shift_start_time,
        logouttime: e.shift_end_time,
        worktype: e.worktype_id,
        halfday:  e.halfdayattendance == null ? 0 : e.halfdayattendance,
        raisedby: e.empid,
        approvercomments: '',
        actionby: this.userSession.id,
        status: 'Approved',
        locationid: e.locationid,
      }
      this.selectedEmps.push(setdata)
    });

    let data = {
      input_data: this.selectedEmps,
    }
    this.btnDisable = true;
    this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity_status == 1) {
        this.getPendingAttendanceRegularizationsForManager();
         let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Attendance Regularization Approved successfully"
        });
        this.selection.clear();
        this.btnDisable = false;

      } else if(result.status && result.data[0].validity_status== 0){

        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Record already exist',
        });
        this.selection.clear();
        this.btnDisable = false;
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to Approve Attendance Regularization"
        });
        this.selection.clear();
        this.btnDisable = false;
      }

    })
  } else {
    this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: 'Select atleast one employee.',
    });
    return;
  }
}


rejectRegularization(){
  if (this.btnDisable) {
    return;
  }
  if (this.selection.selected.length > 0) {
    this.selectedEmps = [];
    this.selection.selected.forEach((e: any) => {
      let setdata = {
        emp_id: e.empid,
        shiftid: e.shiftid,
        fromdate: e.fromdate,
        todate: e.fromdate,
        logintime: e.shift_start_time,
        logouttime: e.shift_end_time,
        worktype: e.worktype_id,
        halfday: e.halfdayattendance == null ? 0 : e.halfdayattendance,
        raisedby: e.empid,
        approvercomments: '',
        actionby: this.userSession.id,
        status: 'Rejected',
        locationid: e.locationid,
      }
      this.selectedEmps.push(setdata)
    });

    let data = {
      input_data: this.selectedEmps,
    }
    this.btnDisable = true;
    this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity_status == 1) {
       this.getPendingAttendanceRegularizationsForManager();
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Attendance Regularization Rejected successfully"
        });
        this.selection.clear();
        this.btnDisable = false;
      } else if(result.status && result.data[0].validity_status== 0){
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Record already exist',
        });
        this.selection.clear();
        this.btnDisable = false;
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to Reject Attendance Regularization"
        });
        this.selection.clear();
        this.btnDisable = false;
      }

    })

  } else {
    this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: 'Select atleast one employee.',
    });
    return;
  }

}

getPendingAttendanceRegularizationsForManager(){
  let data = {
    empid:this.userSession.id
  }
  this.spinner.show();
  this.attendanceService.getPendingAttendanceRegularizationsForManager(data).subscribe((result:any)=>{
    if(result.status){
      this.isPendingApprovalsView = result.data.length > 0 ? this.isPendingApprovalsView = true:this.isPendingApprovalsView = false;
      this.dataSource = new MatTableDataSource(result.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.pageLoading=false;
      this.spinner.hide();
    }
  })
}


submitFulldayRegularization(){
  if (this.btnDisable) {
    return;
  }
  if (this.selection.selected.length > 0) {
    this.selectedEmps = [];
    this.selection.selected.forEach((e: any) => {
      let setdata = {
        emp_id: e.empid,
        shiftid: e.shiftid,
        fromdate: e.attendancedate,
        todate: e.attendancedate,
        logintime: e.shift_start_time,
        logouttime: e.shift_end_time,
        worktype: e.worktypeid,
        halfday: e.halfday == null ? 0 : e.halfday,
        raisedby: e.empid,
        approvercomments: '',
        actionby: this.userSession.id,
        status: 'Approved',
        locationid: e.locationid,
      }
      this.selectedEmps.push(setdata)
    });
    this.spinner.show();
    let data = {
      input_data: this.selectedEmps,
    }
    this.btnDisable = true;
    this.attendanceService.setAttendanceRegularizationNew(data).subscribe((result: any) => {
      if (result.status && result.data[0].validity_status == 1) {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Attendance Regularization Approved successfully"
        });
        this.selection.clear();
        this.getEmployeesForRegularization();
        this.spinner.show();
        this.btnDisable = false;
      } else if(result.status && result.data[0].validity_status== 0){

        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: 'Record already exist',
        });
        this.selection.clear();
        this.getEmployeesForRegularization();
        this.btnDisable = false;
      } else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "Unable to Approve Attendance Regularization"
        });
        this.selection.clear();
        this.getEmployeesForRegularization();
        this.btnDisable = false;
      }
    })
  } else {
    this.dialog.open(ReusableDialogComponent, {
      position: { top: `70px` },
      disableClose: true,
      data: 'Select atleast one employee.',
    });
    return;
  }
}


clear(){
  this.getEmployeesForRegularization();
}
filterLocations(searchText: any) {
  this.filteredLocations = this.worklocationDetails.filter((val:any) =>
    val.cityname.toLowerCase().includes(searchText.toLowerCase()) ||
    val.location.toLowerCase().includes(searchText.toLowerCase())
  );
  if(this.filteredLocations.length<=0){
    this.searchControl.setValue('');
  }else{
    
  }
}

getPageSizes2(): number[] {
  let customPageSizeArray = [];
  if (this.dataSource2 && this.dataSource2.data) {
    if (this.dataSource2.data.length < 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource2.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource2.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource2.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource2.data.length);
  } else {
    customPageSizeArray = [5, 10, 20]; // default options if dataSource2 is not defined
  }
  return customPageSizeArray;
}
}
