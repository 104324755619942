<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="notificationsForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header>
                <mat-card-title>Reorder Assets</mat-card-title>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
            
        <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
            <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                <mat-label>Applied Date </mat-label>
                <input matInput readonly required [matDatepicker]="appliedDate"
                    placeholder="Expiry Date" formControlName="appliedDate">
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="appliedDate"></mat-datepicker-toggle>
                <mat-datepicker #appliedDate></mat-datepicker>
              </mat-form-field>   
            
            <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                <mat-label>Asset Type</mat-label>
                <input type="text" formControlName="assetType"  matInput placeholder="Asset Type" required>
                <mat-error *ngIf="notificationsForm.controls.assetType.hasError('required')">
                    Please Asset Type
                   </mat-error>
            </mat-form-field>

                 <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Asset Name</mat-label>
                    <input type="text" formControlName="assetName"  matInput placeholder="Asset Name" required>
                    <mat-error *ngIf="notificationsForm.controls.assetName.hasError('required')">
                        Please Enter Asset Name
                       </mat-error>
                </mat-form-field>
            </div>

             <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Number of Assets</mat-label>
                    <input type="text" formControlName="totalAssets"  matInput placeholder="Number of Assets" required>
                    <mat-error *ngIf="notificationsForm.controls.totalAssets.hasError('required')">
                        Please Enter Number of Assets
                       </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Time Period</mat-label>
                    <input type="text" formControlName="timePeriod"  matInput placeholder="Time Period" required>
                    <mat-error *ngIf="notificationsForm.controls.timePeriod.hasError('required')">
                        Please Enter Time period
                       </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Before Date </mat-label>
                    <input matInput readonly required [matDatepicker]="beforeDate"
                        placeholder="Before Date" formControlName="beforeDate">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="beforeDate"></mat-datepicker-toggle>
                    <mat-datepicker #beforeDate></mat-datepicker>
                  </mat-form-field>  


             </div>
             <div fxLayout fxLayout="row" fxLayout.xs="column" fxFlex.gt-xs="50%" fxLayoutAlign.xs="center">
                <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Configuration</mat-label>
                    <textarea formControlName="configuration" matInput placeholder="Configuration" maxlength="100"></textarea>
                   </mat-form-field>
            </div>

            <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </div>
        </mat-card>

    </form>
    <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%"  fxLayoutAlign="space-between center" *ngIf="isdata">
        <span style="padding-top: 5px; padding-left: 10px;">
         <input  (keyup)="applyFilter($event)" type="text" class="search-hover" name="" placeholder="search here..." />
          </span>
          <span style="padding-right: 7px;">
            <button class="mx-1 mat-stroked-button button-save" style="background-color:#3f51b5;color:aliceblue" (click)="Add()" type="submit">
                <span>Add</span>
            </button>
          </span>
     </div>
      <div fxLayout="column" class="tableP" *ngIf="isdata">
        <div class="mat-elevation-z8">
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Applied Date</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset Type </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="expDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.email}}</td>
                </ng-container>
                <ng-container matColumnDef="warDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Assets</th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <!-- <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon> -->
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>