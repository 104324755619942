<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div fxLayout="row" fxLayoutAlign="center">
    <h1 mat-dialog-title>Detailed Overtime Report</h1>
</div>
<div fxLayout="column" >
<!-- <p> <strong>Date:</strong> &nbsp; {{selectedDate}} </p> -->
<div fxLayout="row xs.column" fxLayoutAlign="space-between center" >
    <span> <strong>Emp.ID: </strong> {{employeeId}} </span>
    <span> <strong>Emp.Name: </strong> {{empName}} </span>
</div>
</div>
<mat-divider></mat-divider>

<div mat-dialog-content>

    <div class="mat-elevation-z1" #table>
        <table id="table" mat-table [dataSource]="dataSource" *ngIf="this.dataSource.length > 0">
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let row; let i = index;">{{i+1}}</td>

            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let row" class="no-break"> {{row.attendancedate | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="ot">
                <th mat-header-cell *matHeaderCellDef>OT Hours</th>
                <td mat-cell *matCellDef="let row"> {{row.overtime_minutes}} </td>
            </ng-container>

            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No data found</td>
            </tr>
        </table>
        <div style="text-align: center;padding-top: 20px;" *ngIf="this.dataSource.length ==0">No data found in selected
            date
        </div>
    </div>

</div>
<div mat-dialog-actions fxLayoutAlign="center">
    <button class="m-1 mat-stroked-button button-save" (click)="onOkClick()" type="submit">
        <span>Ok</span>
    </button>


</div>