import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noLeadingWhitespace]'
})
export class NoLeadingWhitespaceDirectiveDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const value = this.ngControl?.value;
    if (value && value.startsWith(' ')) {
      this.ngControl?.control?.setValue(value.trim(), { emitEvent: false });
    }
  }

}
