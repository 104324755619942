import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-leave-policy-deduction',
  templateUrl: './leave-policy-deduction.component.html',
  styleUrls: ['./leave-policy-deduction.component.scss']
})
export class LeavePolicyDeductionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LeavePolicyDeductionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private router: Router) {
    dialogRef.disableClose = true;
  }

ngOnInit(): void {

}
close(){
this.router.navigate([this.data.url])
}
}
