<div fxLayout="column" class="first-col">
  <mat-card style="padding-top: 10px;" fxLayout="column">
    <mat-card-header>
      <mat-card-title style="text-align: center">Advance Salary Approval</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>

    <div class="mat-elevation-z1" style="margin-top: 2%;margin-left:15px;margin-bottom:20px; margin-right:10px;">
      <mat-card-content style="width:100%;overflow-x: auto">
        <form [formGroup]="AdvanceSalaryRequestform" autocomplete="off">
          <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef> S. No.</th>
              <td mat-cell *matCellDef="let element; let i=index;">
                {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
            </ng-container>
            <ng-container matColumnDef="empcode">
              <th mat-header-cell *matHeaderCellDef class="no-break"> Emp. ID </th>
              <td mat-cell *matCellDef="let row"> {{row.empid}}</td>
            </ng-container>
            <ng-container matColumnDef="ename" class="no-break">
              <th mat-header-cell *matHeaderCellDef class="no-break"> Emp. Name </th>
              <td mat-cell *matCellDef="let row"> {{row.employee_name}}</td>
            </ng-container>
            <!-- Progress Column  empid-->
            <ng-container matColumnDef="gsalary">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-break"> Gross Salary</th>
              <td mat-cell *matCellDef="let row" class="no-break"> {{row.gross_salary}}</td>
            </ng-container>
            <ng-container matColumnDef="ramount">
              <th mat-header-cell *matHeaderCellDef> Requested Amount </th>
              <td mat-cell *matCellDef="let row" class="no-break">
                <span *ngIf="enable != row.id">{{row.request_amount}}</span>
                <mat-form-field appearance="outline" *ngIf="enable === row.id">
                  <input formControlName="requestAmount" value="row.request_amount" matInput placeholder="">
                  <!-- <mat-error class="con-error" *ngIf="departmentForm.controls.department.errors?.required ||departmentForm.controls.department.errors?.['whitespace']">
                      {{msgEM1}}
                  </mat-error> -->
                </mat-form-field>

              </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row,let i = index" style="width:auto;white-space: nowrap;cursor: pointer;">
                <button mat-icon-button title="Edit" *ngIf="enable == null" [tabindex]="[i]" (keyup.enter)="Edit(row)">
                  <i class="material-icons" (click)="Edit(row)" title="Edit & Approve">edit</i>
                </button>
                <button mat-icon-button title="Approve" *ngIf="enable == null" [tabindex]="[i]"
                  (keyup.enter)="submit(row,'Approved',null)">
                  <i class="material-icons mr-1" (click)="submit(row,'Approved',null)" title="Approve">check_box</i>
                </button>
                <button mat-icon-button title="Reject" *ngIf="enable == null" [tabindex]="[i]"
                  (keyup.enter)="Reject(row,'Reject',null)">
                  <i class="material-icons mr-1" (click)="Reject(row,'Reject',null)"
                    title="Reject">cancel_presentation</i>
                </button>

                <button mat-icon-button *ngIf="enable == row.id"
                  (keyup.enter)="save($event,AdvanceSalaryRequestform.controls.requestAmount.value,row)">
                  <mat-icon (click)="save($event,AdvanceSalaryRequestform.controls.requestAmount.value,row)"
                    title="Save">save</mat-icon>
                </button>
                <button mat-icon-button *ngIf="enable == row.id" (keyup.enter)="canceledit($event,row.id)">
                  <mat-icon (click)="canceledit($event,row.id)" title="Cancel">cancel</mat-icon>
                </button>

                <!--<mat-icon (click)="leaveReject(row)" title="Reject" >cancel</mat-icon>-->
                <!--<mat-icon   title="view" (click)="leaveReview(row)">visibility</mat-icon>-->



                <!--<a routerLink="/" (click)="onApproveClick(row)">Review and Approve</a>-->
              </td>
            </ng-container>
            <!-- Color Column -->
            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9" style="text-align: center;">No data found</td>
            </tr>
          </table>
        </form>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </mat-card-content>
    </div>
  </mat-card>
</div>