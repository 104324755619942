import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttendanceService } from '../../attendance.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-weekoff-history-dialog',
  templateUrl: './weekoff-history-dialog.component.html',
  styleUrls: ['./weekoff-history-dialog.component.scss']
})
export class WeekoffHistoryDialogComponent implements OnInit {
  requestform!: UntypedFormGroup;
  displayedColumns: string[] = ['sno', 'fromdate', 'todate', 'weekoff'];
  dataSource: any = [];
  empCode: any;
  empName: any;
  empId: any;
  constructor(public dialogRef: MatDialogRef<WeekoffHistoryDialogComponent>,
    private attendanceService: AttendanceService, private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.empId = data.empId;
    this.empCode = data.empCode;
    this.empName = data.empName;
    this.viewData();
    }
  employeesList: any = [];
  ngOnInit(): void {
    this.requestform = this.formBuilder.group({
      shift: [''],
    });

  
  }

  viewData() {
    this.employeesList = [];
     let data = {
      "employee_id":this.empId,
     }
    this.attendanceService.getEmployeeWeekoffsHistory(data).subscribe((res) => {
      if (res.status) {
        this.employeesList = res.data;
        this.dataSource = this.employeesList;
         } else {
           this.employeesList = [];
         }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}