import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addcourse',
  templateUrl: './addcourse.component.html',
  styleUrls: ['./addcourse.component.scss']
})
export class AddcourseComponent implements OnInit {

  addNewCourse: boolean = true;
  addCourse: any = UntypedFormGroup;

  displayedColumns = ['sno', 'course', 'requisites', 'state', 'action']
  dataSource = [
    { sno: '1', course: 'My SQL', requisites: '' },
    { sno: '2', course: 'Angular', requisites: 'HTML,CSS,Javascript' },
    { sno: '3', course: 'Python', requisites: '' },
    { sno: '4', course: 'Javascript', requisites: '' },
    { sno: '5', course: 'Java', requisites: 'Computer Basics' },
    { sno: '6', course: 'React Js', requisites: 'HTML,CSS,Javascript' },
  ]
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.addCourse = this.formBuilder.group({
      course: [''],
      description: [''],
      preRequisites: ['']
    })
  }

  addNew() {
    this.addNewCourse = false;
  }
  onSubmit() {

  }
  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/addCourse']));
  }
}
