import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-chapter',
  templateUrl: './add-chapter.component.html',
  styleUrls: ['./add-chapter.component.scss']
})
export class AddChapterComponent implements OnInit {

  addChapter: any = UntypedFormGroup;
  addNewChapter: boolean = true;
  courseNames: any = [
    'Java', 'React', 'Python', 'Angular'
  ];
  types: any = [
    'Lectures', 'Pdf', 'Links'
  ]
  pdfName: any;
  fileURL: any;
  dataSource: any = [
    { sno: 1, courseName: 'Java', chapter: 'Introduction' },
    { sno: 2, courseName: 'Javascript', chapter: 'Introduction' },
    { sno: 3, courseName: 'Python', chapter: 'Introduction' },
    { sno: 4, courseName: 'MySQL', chapter: 'Introduction' },
  ];
  displayedColumns: any = ['sno', 'courseName', 'chapter']
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.addChapter = this.formBuilder.group({
      courseName: [''],
      chapter: [''],
      fileName: ['']
    })
  }

  addNew() {
    this.addNewChapter = false;
  }
  onSubmit() {

  }
  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/Admin/addChapter']));
  }
  fileView() {
    window.open(this.fileURL);
  }
}
