<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Branch Wise Monthly Summary Report</mat-card-title>
        </mat-card-header>
        <br />

        <mat-card-content>
            <form [formGroup]="SummaryReportForm" fxLayout="column">
                <div fxLayout="row" fxLayout.lt-lg="column">
                    <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
                        <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>City-Branch </mat-label>
                            <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                                multiple #locationSelect>
                                <button mat-raised-button class="mat-primary fill text-sm"
                                    (click)="locationSelectAll(locationSelect, worklocationDetails, SummaryReportForm.companylocation)"
                                    *ngIf="!locationIshide">Select All
                                </button>
                                <button mat-raised-button class="mat-accent fill text-sm"
                                    (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                                    Deselect All
                                </button>
                                <input matInput [formControl]="searchControlforLoc" type="text" placeholder="  Search"
                                    class="search-align">
                                <mat-option *ngFor="let res of filteredLocations" [value]="res"
                                    (click)="onchangeLocation(res)">
                                    {{res.cityname}}-{{res.location}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="con-error"
                                *ngIf="SummaryReportForm.controls.companylocation.errors?.required">
                                Please select option
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field fxFlex="46" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>Manager</mat-label>
                            <mat-select placeholder="" name="managers" formControlName="managers" required multiple
                                #managerSelect>
                                <button mat-raised-button class="mat-primary fill text-sm"
                                    (click)="managerSelectAll(managerSelect, managersDetails, SummaryReportForm.managers)"
                                    *ngIf="!mgrIshide">Select All
                                </button>
                                <button mat-raised-button class="mat-accent fill text-sm"
                                    (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                                    Deselect All
                                </button>
                                <input matInput [formControl]="searchControlforRM" type="text" placeholder="  Search"
                                    class="search-align">
                                <mat-option *ngFor="let res of filteredManagers" [value]="res"
                                    (click)="onchangeManager(res)">
                                    {{res.manager_name}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="con-error" *ngIf="SummaryReportForm.controls.managers.errors?.required">
                                Please select option
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex="50" fxLayout.xs="column">
                        <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>From Date</mat-label>
                            <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate"
                                placeholder="From Date" formControlName="fromDate" (click)="fromDate.open()"
                                tabIndex="1">
                            <mat-datepicker-toggle class="datepicker" matSuffix
                                [for]="fromDate"></mat-datepicker-toggle>
                            <mat-datepicker #fromDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="50" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                            <mat-label>To Date</mat-label>
                            <input matInput readonly [matDatepicker]="toDate" placeholder="To Date" [min]="minToDate"
                                [max]="maxDate" formControlName="toDate" (click)="toDate.open()" tabIndex="2">
                            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                            <mat-datepicker #toDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="50" fxLayout="row" fxLayoutAlign="end">
                        <button class="btn btn-primary mr-2 mb-2" (click)="Searchform()" tabIndex="2">
                            Search
                        </button>
                        <span class="line" style="color: #1898d5; font-size: 16px"></span>
                        <button class="btn btn-danger mr-2 mb-2" (click)="resetform()" tabIndex="3">
                            <span>Clear</span>
                        </button>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="end" *ngIf="List.length > 0">
                        <div style="padding-right: 2%;">
                            <span class="exlprint"> | </span>
                            <button mat-icon-button>
                                <a title="PDF" [tabIndex]="4" (keyup.enter)="downloadPDF()"> <img
                                        (click)="downloadPDF()" src="./assets/images/icons/pdf.png" width="20px"
                                        height="20px"></a>
                            </button>
                            <span class="exlprint"> | </span>
                            <button mat-icon-button>
                                <a title="Excel" [tabIndex]="5" (keyup.enter)="exportAsXLSX()"> <img
                                        (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div fxLayout="column" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <div id="table" #table=''>
                        <table mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S. No.</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>
                            <ng-container matColumnDef="empname">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Emp. Name</th>
                                <td mat-cell *matCellDef="let row;">{{row.empname}}</td>

                            </ng-container>
                            <ng-container matColumnDef="attendancedate">
                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.attendancedate |
                                    date:'dd-MM-yyyy'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="firstlogintime">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">First In</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.firstlogintime|
                                    date:'dd-MM-yyyy HH:mm:ss'}} </td>

                            </ng-container>
                            <ng-container matColumnDef="lastlogouttime">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Last Out</th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.lastlogouttime |
                                    date:'dd-MM-yyyy HH:mm:ss'}} </td>

                            </ng-container>
                            <ng-container matColumnDef="totalhours">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.totalhours}} </td>

                            </ng-container>

                            <ng-container matColumnDef="productivehours">
                                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Productive Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.productivehours}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ot">
                                <th mat-header-cell *matHeaderCellDef>OT Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.overtime_minutes}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ph">
                                <th mat-header-cell *matHeaderCellDef>Permission Hours</th>
                                <td mat-cell *matCellDef="let row"> {{row.permissionhours}} </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row" (keyup.enter)="openDialog(row)">
                                    <button mat-icon-button (click)="openDialog(row)" [tabIndex]="8">
                                        <a style="color: blue">
                                            <u>Details</u>
                                        </a>
                                    </button>

                                </td>

                            </ng-container>
                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" style="text-align: center;">No data found</td>
                            </tr>

                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

</div>