<div fxLayout="column" class="first-col">

    <mat-card>
        <mat-card-header class="headerbox">
            <mat-card-title class="title"> Company Information </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <form [formGroup]="companyForm" autocomplete="off" class="tableP" ngStyle.xs="padding-top:5%"
            ngStyle.lt-lg="padding-top:3%">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="center center">

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Full Company Name</mat-label>
                    <input formControlName="companyname" matInput placeholder="" maxlength="100"
                    alphaNumericSpace noLeadingWhitespace required [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="  companyForm.controls.companyname.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company website</mat-label>
                    <input formControlName="website" required matInput placeholder="" maxlength="100"
                        [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="companyForm.controls.website.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="con-error" *ngIf="companyForm.controls.website.errors?.pattern">
                        {{msgEM3}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>CIN</mat-label>
                    <input formControlName="cin" matInput placeholder="" maxlength="21" minlength="21" required
                        [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="companyForm.controls.cin.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="con-error" *ngIf="companyForm.controls.cin.errors?.minlength
                || companyForm.controls.cin.errors?.pattern">
                        CIN number must be 21 chars and valid pattern
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>GST Number</mat-label>
                    <input formControlName="gstnumber" matInput maxlength="15" minlength="15" required
                    alphaNumericOnly [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="companyForm.controls.gstnumber.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="con-error" *ngIf="companyForm.controls.gstnumber.errors?.minlength 
                || companyForm.controls.gstnumber.errors?.pattern">
                        GST number must be 15 chars and valid pattern
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company Established Date </mat-label>
                    <input matInput readonly [matDatepicker]="date" [max]="maxDate" placeholder=""
                        formControlName="established_date" required (click)="date.open()"
                        [ngClass]="{'disable': isdisabled}">
                    <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                    </mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                    <mat-error class="con-error" *ngIf="companyForm.controls.established_date.errors?.required">
                        {{msgEM2}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Primary Contact Email</mat-label>
                    <input formControlName="email" matInput maxlength="50" required [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="  companyForm.controls.email.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="con-error" *ngIf="  companyForm.controls.email.errors?.email">
                        {{msgEM3}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Primary Contact</mat-label>
                    <input type="text" formControlName="contact" onlyNumber matInput placeholder="" minlength="10"
                        maxlength="10" required [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="  companyForm.controls.contact.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="reg-error" *ngIf="companyForm.controls.contact.errors?.minlength ||
                  companyForm.controls.contact.errors?.maxlength">
                        Please enter valid number
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Secondary Contact</mat-label>
                    <input type="text" formControlName="secondarycontactnumber" onlyNumber matInput minlength="10"
                        maxlength="10" [ngClass]="{'disable': isdisabled}">
                    <!-- <mat-error class="con-error" *ngIf="  companyForm.controls.contact.errors?.required">
                      {{msgEM2}}
                </mat-error> -->
                    <mat-error class="reg-error" *ngIf="companyForm.controls.secondarycontactnumber.errors?.minlength ||
                companyForm.controls.secondarycontactnumber.errors?.maxlength">
                        Please enter valid number
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company Registered Address</mat-label>
                    <input formControlName="address1" matInput maxlength="100" required
                        [ngClass]="{'disable': isdisabled}">
                    <!-- <input matInput value="Sushi" [formControl]="foodCtrl" *ngIf="isview"> -->
                    <mat-error class="con-error" *ngIf="companyForm.controls.address1.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Work Location Address</mat-label>
                    <input formControlName="address2" matInput maxlength="100" [ngClass]="{'disable': isdisabled}">
                    <!-- <mat-error class="con-error" *ngIf="designationForm.controls.designation.errors?.required">
                      {{errorDesName}}
                  </mat-error> -->
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" required [ngClass]="{'disable': isdisabled}">
                        <ng-container>
                            <div *ngFor="let b of CountryDetails">
                                <mat-option [value]="b.id">{{b.country}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="  companyForm.controls.country.errors?.required">
                        {{msgEM2}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>State</mat-label>
                    <mat-select required formControlName="state" [ngClass]="{'disable': isdisabled}">
                        <ng-container>
                            <div *ngFor="let b of stateDetails">

                                <mat-option [value]="b.id">{{b.state}}</mat-option>
                            </div>

                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="  companyForm.controls.state.errors?.required">
                        {{msgEM2}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>City</mat-label>
                    <mat-select required formControlName="city" [ngClass]="{'disable': isdisabled}">
                        <ng-container>
                            <div *ngFor="let b of cityDetails">
                                <mat-option [value]="b.id">{{b.location}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="  companyForm.controls.city.errors?.required">
                        {{msgEM2}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="31" fxFlex.lt-lg="45" class="mx-2 my-2" appearance="outline">
                    <mat-label>Pincode</mat-label>

                    <input type="text" onlyNumber formControlName="pincode" matInput minlength="6" maxlength="6"
                        required [ngClass]="{'disable': isdisabled}">
                    <mat-error class="con-error" *ngIf="  companyForm.controls.pincode.errors?.required">
                        {{msgEM1}}
                    </mat-error>
                    <mat-error class="con-error"
                        *ngIf="  companyForm.controls.pincode.errors?.maxlength || companyForm.controls.pincode.errors?.minlength">
                        This field length should be 6 digits
                    </mat-error>
                    <!-- <input *ngIf="isview"  formControlName="firstname" matInput readonly> -->
                </mat-form-field>

            </div>
            <div fxLayoutAlign="center" class="" *ngIf="isadd">
                <button class="btn btn-primary mr-2 mb-2" [disabled]="!companyForm.valid" (click)="save()"
                    *ngIf="!isedit" type="button">
                    <span>Submit</span>
                </button>
                <button class="btn btn-primary mr-2 mb-2" [disabled]="!companyForm.valid" (click)="update()"
                    *ngIf="isedit" type="button">
                    <span>Save</span>
                </button>

                <button class="btn btn-danger mr-2 mb-2" *ngIf="!isedit" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <button class="btn btn-danger mr-2 mb-2" *ngIf="isedit" (click)="canceledit()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </form>
        <div fxLayoutAlign="center" class="" *ngIf="isview">
            <button class="btn btn-primary mr-2 mb-2" (click)="edit()" type="submit">
                <span>Edit</span>
            </button>


            <br />
        </div>

    </mat-card>

</div>