<!-- <button mat-icon-button class="close-button"  [mat-dialog-close]="true" style="float: right">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->
<!--<div class="mat-dialog-header">-->
    <div fxLayout="row" fxLayoutAlign="center">
        <h1 mat-dialog-title >{{data.name}}</h1>
      
      </div>
      
      <!--</div>-->
      <!--<div mat-dialog-content>-->
      
      <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content>
      
          <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
            <mat-form-field  fxFlex="100" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Reason<span *ngIf="data.name=='Reject'"  style="color: red"></span> </mat-label>
              <textarea formControlName="reason"  matInput placeholder="Reason" required></textarea>
              <mat-error class="con-error" *ngIf="form.controls.reason.errors?.required">
                <!-- {{LM1}} -->This Field is required
              </mat-error>
            </mat-form-field>
          </div>
      
        </div>
        <div mat-dialog-actions fxLayoutAlign="center">
          <button class="m-1 mat-stroked-button button-save" (click)="onOkClick()" type="submit">
            <span>Submit</span>
          </button>
      
          <button class="m-1 mat-stroked-button button-clear"  (click)="onNoClick()">
            <span >Cancel</span>
          </button>
      
        </div>
      </form>