
<mat-sidenav-container class="sidenav-container">

  <mat-sidenav-content class="custom-sidenav-container">
    <mat-card fxLayout="column" fxFlex=100%>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"
          style="padding-right: 17px;padding-top: 2%">
          <!-- first column -->
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="50"  ngStyle.xs="padding-top:5%">
            <mat-card fxLayout="row" fxLayout.lt-md="column" fxLayout.xs="column" ngClass.gt-sm="desktop" ngClass.lt-md="tab" ngClass.lt-sm="mobile"
              class="card outline">
              <div fxLayout="column" fxLayoutAlign="center" fxFlex="40">
                <span fxLayout="row" fxLayoutAlign="center" ngStyle.lt-md="padding-top:20px">
                  <label class="hoverable1" for="fileInput" *ngFor="let url of imageurls; let i = index">
                    <img class="img1" [src]= url.base64String>
                    <div class="hover-text"><mat-icon>camera_enhance</mat-icon></div>
                    <div class="background"></div>
                  </label>
                  <input id="fileInput" accept='image/*' type='file' (change)="onSelectFile($event)">
                </span>

              </div>

              <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="60" fxFlex.xs="100"
                style="overflow: auto" ngStyle.lt-md="padding-left: 5px;">
                <div fxLayout="column">
                  <span class="heading">{{ employeeNameh }}</span>
                  <span class="heading1">{{ employeeCode }}</span>
                  <span class="heading1">{{
                    employeeJoinDate | date: "dd-MM-YYYY"
                    }}</span>
                  <span class="heading1">{{ employeeDesignation }}</span>
                  <div ngStyle.gt-md="margin-top:5% ;" fxLayout="row" ngStyle.lt-md="margin-top:2% ;" fxLayout.xs="column" >
                    <span class="heading2">Reporting: </span>
                    <span class="heading2" ngStyle.gt-md="padding-left: 5px;">{{ empReportingManager }}</span>
                  </div>
                </div>
              </div>

            </mat-card>

            <mat-card class="card outline" fxLayout="column" fxFlex>
              <div fxLayout="row">
                <span class="title">Announcements</span>
              </div>

              <div class="scrollbox" style="margin: 10px; padding: 2px; height:230px">
                <div class="scrollbox-content">
                  <div *ngIf="announcementsDetails.length > 0">
                    <mat-card style="
                  padding: 2px;
                  margin-bottom: 10px
                " *ngFor="let items of announcementsDetails">
                      <div>
                        <p style="color: #295062; font-size: 18px;font-family: Roboto;">
                          <b>{{ items.topic }}</b>
                          <span>{{':'}}</span>&nbsp;
                          <span style="color: #28acaf; font-size: 16px;font-family: Roboto;"><b>{{ items.title }}</b></span>
                        </p>
                      </div>
                      <span *ngIf="!items.showMore" [innerHtml]>
                        <span [innerHtml]="trimString(items.description, 200)"></span>
                      </span>

                      <span *ngIf="items.showMore">
                        <span [innerHtml]="items.description"></span>
                      </span>
                      <div style="text-align: end" *ngIf="items.description.length > 200"
                        (click)="items.showMore = !items.showMore">
                        <span style="color: #28acaf">
                          Show
                          <span>{{ items.showMore ? "less" : "More" }}</span>
                        </span>
                      </div>
                    </mat-card>
                  </div>
                  <div fxLayout="column" fxLayoutGap="25px" style="padding-top: 15%" fxLayoutAlign="center center"
                    *ngIf="announcementsDetails.length == 0">
                    <mat-card style="padding: 10px">
                      <div>
                        <p><b>No Announcements</b></p>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>

          <!-- second column -->
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="50">

            <!-- alerts card -->
            <mat-card class="card outline" fxLayout="column" style="min-height: 510px;">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title">
                  <span>Alerts</span>
                </span>
                <span *ngIf="isManager" style="padding-top: 1%;">
                  <mat-radio-group>
                    <mat-radio-button (keyup.enter)="getEmployeeAttendanceNotifications()" class="mx2 my-2 mr-2" value="self" [checked]='true'
                      (click)="getEmployeeAttendanceNotifications()">
                      <span class="radioBtn">Self</span>
                    </mat-radio-button>
                    <mat-radio-button (keyup.enter)="getMyTeamApprovals()" class="mx2 my-2 mr-2" value="team" (click)="getMyTeamApprovals()">
                      <span class="radioBtn">Team</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </span>
              </div>
              <div class="scrollbox">
                <div class="scrollbox-content">
                  <div style="min-height: 60vh;max-height: 75vh;padding-top: 1%">
                 <!-- induction schedule alert -->

                 <div fxLayout="column" *ngIf="!teamAttendanceData && inductionAlert.length > 0">
                  <span style="padding-left:2%">
                    <p><b>Induction Program invitation</b></p>
                  </span>
                 <div *ngFor="let value of inductionAlert"
                      style="padding-left: 2%;padding-right: 2%;padding-bottom: 1%;">
                  <mat-card class="inductionStyle">
                    <span>{{value.description}}</span>
                    <p>{{value.schedule_date  | date: 'dd-MM-yyyy'}}{{''}} {{value.schedule_starttime |  slice:0:5}} {{'-'}} {{value.schedule_endtime |  slice:0:5}}</p>
                  </mat-card>
                </div>
                <br>
                </div>


                    <!-- self attendance -->
                    <div *ngIf="!teamAttendanceData">
                      <!-- regularization records -->
                      <div *ngIf="regularizationList.length > 0">
                        <span >
                          <p style="padding-left:2%"><b>Regularization</b></p>
                        </span>
                        <mat-list style="padding-top: 0px;" >
                          <ng-container *ngFor="let item of regularizationList">
                            <mat-list-item >
                        
                              <span fxFlex="70" matLine>
                                <div  class="truncate">
                                {{ item.absent_date | date: "dd-MM-yyyy" }}
                                <span> {{item.message}}</span>
                            </div>
                              </span>

                              <span fxFlex="30" fxLayoutAlign="end">
                                <a routerLink="/" style="padding-right: 10px;" matTooltip="Regularization"
                                  (click)="regularizationRequest(item)">
                                  <img src="assets\img\attendence-request.png" style="height: 35px" />
                                </a>
  
                              </span>
                         

                            </mat-list-item>
                          </ng-container>
                        </mat-list>
                        <mat-divider></mat-divider>
                      </div>
                      <!-- unregistred records  -->
                      <div *ngIf="notificationsData.length > 0">
                        <span >
                          <p style="padding-left:2%"><b>Pending Leave/Attendance</b></p>
                        </span>
                        <mat-list>
                          <ng-container *ngFor="let item of notificationsData">
                            <mat-list-item >
                        
                              <span fxFlex="70" matLine>
                                <div  class="truncate"
                                [matTooltip]=" !openSidenav ? 'Attendance not recorded'
                                : '' ">
                                {{ item.absent_date | date: "dd-MM-yyyy" }}
                                <span> {{"- Attendance not recorded"}}</span>
                            </div>
                              </span>

                              <span *ngIf="item.message == null || item.message == '' " fxFlex="30" fxLayoutAlign="end">
                                <a routerLink="/" style="padding-right: 10px;" matTooltip="Leave request"
                                  (click)="leaverequest(item)">
                                  <img src="assets\img\leave-request.png" style="height: 35px" />
                                </a>
                                <a style="padding-left: 5px;padding-right: 10px;" matTooltip="Attendance request" *ngIf="showAttendanceRequest()"
                                  routerLink="/" (click)="attendanceRequest(item)">
                                  <img src="assets\img\attendence-request.png" style="height: 28px" />
                                    </a>
                              </span>

                            </mat-list-item>
                          </ng-container>
                        </mat-list>
                      </div>
                      <div style="padding: 5%" *ngIf="notificationsData.length == 0">
                        <div fxLayoutAlign="center">
                          <p><b>No data found</b></p>
                        </div>
                      </div>
                    </div>

                    <!-- team attendance -->
                    <div *ngIf="teamAttendanceData">
                      <div>
                        <mat-list class="time-attendance">
                          <ng-container *ngFor="let item of notificationsData">
                            <mat-list-item>
                              <h3 matLine> {{ item.empname}} </h3>
                              <h3 matLine>Date : {{ item.fromdate | date:'dd-MM-yyyy'}}
                              </h3>
                              <p>
                                <a routerLink="/" (click)="approveRequest(item)">Approve attendance </a>
                              </p>
                            </mat-list-item>
                          </ng-container>
                          <ng-container *ngFor="let item of leavesRequestData">
                            <mat-list-item>
                              <h3 matLine> {{ item.emp_name}} </h3>
                              <h3 matLine>Date : {{ item.fromdate | date:'dd-MM-yyyy'}}
                              </h3>
                              <p>
                                <a routerLink="/" (click)="leaveReviewAndApprovals(item)">Approve leave</a>
                              </p>
                            </mat-list-item>
                          </ng-container>

                        </mat-list>
                      </div>
                      <div style="padding: 5%" *ngIf="notificationsData.length == 0 && leavesRequestData.length == 0">
                        <div fxLayoutAlign="center">
                          <p><b>No data found</b></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>


<script src="assets/js/core/jquery.3.2.1.min.js"></script>
<script src="assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min.js"></script>
<script src="assets/js/core/popper.min.js"></script>
<script src="assets/js/core/bootstrap.min.js"></script>
<!-- Chart JS -->
<script src="assets/js/chart.js/chart.min.js"></script>
<script src="assets/js/plugin/chartist/chartist.min.js"></script>
<script src="assets/js/plugin/chartist/plugin/chartist-plugin-tooltip.min.js"></script>
<script src="assets/js/plugin/bootstrap-toggle/bootstrap-toggle.min.js"></script>
<script src="assets/js/plugin/jquery-mapael/jquery.mapael.min.js"></script>
<script src="assets/js/plugin/jquery-mapael/maps/world_countries.min.js"></script>
<script src="assets/js/plugin/chart-circle/circles.min.js"></script>
<script src="assets/js/plugin/jquery-scrollbar/jquery.scrollbar.min.js"></script>
<script src="assets/js/main.js"></script>

