<mat-card>
<div fxLayout="column" class="first-col" style="font-size: small;">
      <div class="main-header_colored">
        <mat-card-header class="headerbox " fxLayout="row" fxLayoutAlign="space-between center" style="padding-right: 60px;text-align: right;">
          <mat-card-title class="title"> {{activeChild.screen_name}} </mat-card-title>
            <div style="margin-right: 2%;" >
              <button mat-icon-button (click)="selectAddClient()"  [disabled]="isWebRestricted? true : false"  matTooltip="Add Client" text="Add Client">Add Client
                <mat-icon style="font-size: 25px">add_circle</mat-icon>
              </button>
            </div>
        </mat-card-header> 
        <div fxLayout="row" fxLayoutAlign.xs="center" class="" fxLayoutAlign="start" style="font-size: smaller;">
          <span fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start"> 
            <mat-form-field  class="mx-2 my-2"   appearance="outline" style="padding-top: 2%;" >
              <mat-label>Dashboard</mat-label>
              <mat-select [(value)]="dashboard_id"  [formControl]="selectedDashboardControl"> 
                  <mat-option *ngFor="let d of dashboards" [value]="d.id" >{{ d.dashboard_name }}</mat-option>
              </mat-select>
            </mat-form-field>
            
              <!-- <mat-form-field class="mx-2 my-2" appearance="outline" style="padding-top: 2%;" *ngIf="!selectedAll">
                <mat-label>Created On</mat-label>
                <input matInput  [max]="maxDate" [matDatepicker]="selectedDate"  [formControl]="selectedDateControl"   (keypress)="false" 
                  placeholder="DD-MM-YYYY"   (click)="selectedDate.open()" />
                <mat-datepicker-toggle class="datepicker" matSuffix [for]="selectedDate"> </mat-datepicker-toggle>
                <mat-datepicker #selectedDate></mat-datepicker>
            </mat-form-field> -->
            <mat-form-field class="mx-2 my-2" appearance="outline" style="padding-top: 2%;" *ngIf="!selectedAll">
              <mat-label>From Date</mat-label>
              <input matInput  [max]="maxDate" [matDatepicker]="fromDate"  [formControl]="fromDateControl"   (keypress)="false" 
                placeholder="DD-MM-YYYY"   (click)="fromDate.open()" />
              <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"> </mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="mx-2 my-2" appearance="outline" style="padding-top: 2%;" *ngIf="!selectedAll">
            <mat-label>To Date</mat-label>
            <input matInput  [max]="maxDate" [matDatepicker]="toDate"  [formControl]="toDateControl"   (keypress)="false" 
              placeholder="DD-MM-YYYY"   (click)="toDate.open()" />
            <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"> </mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
            <mat-form-field  class="mx-2 my-2"   appearance="outline" style="padding-top: 2%;" *ngIf="!selectedAll && select_emp()">
              <mat-label>Employee</mat-label>
              <mat-select [(value)]="selectedEmployee"  [formControl]="selectedEmployeeControl"> 
                  <mat-option [value]="0" >All ({{attendanceData.length}})</mat-option>
                  <mat-option *ngFor="let emp of attendanceData" [value]="emp.empid" >{{ emp.empname }}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
         
            
       
        </div>
        <div fxLayout="row" fxLayoutAlign.xs="center" class="" fxLayoutAlign="end" style="font-size: smaller;">
          <span fxFlex="100" fxFlex.xs="100" fxLayoutAlign="start" style="align-items: self-end;"> 
            <mat-checkbox  class="mx-2 my-2"  (value)="selectedAll"  [formControl]="selectedAllControl"  style="padding-top: 2%;" (click)="changeSelectAll()">
              Select All
            </mat-checkbox>
            
          </span>
              <span fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end"> 
                  <mat-label style="align-self: center;"> {{totalClients}} Deals</mat-label>
              <mat-form-field  fxFlex.xs="100" fxFlex="50" fxFlex.lt-lg="100" class="mx-2 my-2" appearance="outline" style="">
                <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                <mat-label>Search</mat-label>
                <input (keyup)="applyFilter($event)" [tabIndex]="[2]"  matInput placeholder="" maxlength="100">
              </mat-form-field>
            </span>
        </div>
         
      </div>

      <mat-tab-group style="padding-top: 12%;display: table-column;">
        
          <mat-tab > 
          
            <div  fxFlex="100"  *ngFor="let row of clientFilteredList" style="padding-right:2%;display:block;width: 110%;">
              
                  <div class="dash-board-header" >
                    <div style="text-align:center; font-size:medium;font-weight: 500;width: 100%;place-content: space-evenly;   background-color:#9fcfe1;  color: black ;    margin-right: 6%; height: 25px;" >  
                        <span >{{row.type_name | titlecase}}</span>   
                    </div>
                    <div style="text-align: center; font-size: small;font-weight:400;width: 100%;place-content: space-evenly; background-color:#9fcfe1;  color: black ;    margin-right: 6%; height: 25px;" >  
                        <span  >{{row.data ? row.data.length:0}}&nbsp; Deals </span>
                    </div>
                  </div>
              
                  <div class="scroll-container" style="padding-top: 45%;height: 350px;">
                    <div *ngFor="let client of row.data"  style="display: block;padding-top: 2%;" >
                      
                          <div fxLayoutAlign="space-evenly center" class= "card mx-2 my-2" fxLayout="row" (click)="checkClientDetails(client)"  [ngStyle]="{'background': client.back_color,'color': (dashboard_selected.text_color?dashboard_selected.text_color:'black')}"  style="padding: 1%;font-size: small;font-weight: 400;border: 1px solid #ccc;">
                            <!-- <div [fxFlex]="'10%'" >
                              <button mat-icon-button matTooltip="" style="color:cadetblue;padding-right: 15px;">
                                <mat-icon class="icon" >keyboard_arrow_right</mat-icon>
                              </button>
                            </div> -->
                            <div [fxFlex]="'10%'" style="text-align: left;">                              
                              <mat-icon class="icon"  style="font-size:small;font-weight: 500;" [ngStyle]="{'color': getActivityTImeLapseColor(client),'display':(client.nextFollowUpDate?'block':'none')}">{{getActivityTImeLapseIcon(client)}}</mat-icon> 
                            </div>
                            <div [fxFlex]="'85%'" style="text-align: left;">
                              <span  class="">{{client.organization_name }}</span>
                            </div>
                            <div [fxFlex]="'5%'"  >
                              <span  fxLayoutAlign="end">
                                  <button  (click)="selectClient($event,client)"  mat-icon-button matTooltip="" style="text-align: center;padding-left: 20px; color:cadetblue;">
                                    <mat-icon class="icon"  style="font-size: small;">edit</mat-icon> 
                                  </button>
                              </span>
                            </div>
                          </div>
                          <div fxLayoutAlign="space-evenly left" class="card mx-2 my-2" fxLayout="column" *ngIf="client.display" style="color:black;padding: 2%;font-size: smaller;font-weight: 400;">
                            <div  *ngIf="client.emp_name ">
                              <span  class="text-success" style="font-weight: 500;">Created By:&nbsp;</span><span style="font-weight:400;color: darkgreen;"  class=""> {{client.emp_name }}</span>
                            </div>
                            <div *ngIf="client.created_on">
                              <span  class="text-primary" style="font-weight: 500;">Created On:&nbsp; </span><span style="font-weight:400;color:#DA1884;" class="">  {{getFormattedDateTime(client.created_on) }}</span>
                            </div>
                            <div *ngIf="client.nextFollowUpDate ">
                              <span class="text-primary" style="font-weight: 500;">Follow Up:&nbsp; </span><span style="font-weight:400;color:blueviolet;" class="">  {{getFormattedDateTime(client.nextFollowUpDate) }}</span>
                            </div>                            
                            <div  *ngIf="client.description && client.description.toString().length ">
                              <span class="text-warning" style="font-weight: 500;">Description:&nbsp;</span> 
                              <span style="font-weight:400;color: chocolate; word-break: break-all;"  class="">{{client.description.toString().substring(0,100) }}<b>{{(client.description.toString().length && client.description.toString().length > 100) ?'...':''}}</b></span>
                            </div>
                            
                          </div>
                          
                      </div>
                    </div>
                         
              </div>
                
          </mat-tab>
  </mat-tab-group>
</div>
<span *ngIf="dashboard_selected && dashboard_selected.back_color_json"> <b>Lead age:  &nbsp;&nbsp;&nbsp;&nbsp;</b>

  <span  *ngFor="let c of dashboard_selected.back_color_json"><mat-icon  [ngStyle]="{'color': c.color}"  style="font-size: large;margin-bottom: -10px;">radio_button_checked</mat-icon>
    {{ getBackDaysRange(c) }}
    <!-- {{c.min}} - {{(c.max === -1)?'Current':c.max}} Days  -->
      &nbsp;&nbsp;&nbsp;&nbsp;</span>
</span><b>Follow-up-time:&nbsp;</b>
<span ><mat-icon  [ngStyle]="{'color':'#5DADEC'}"  style="font-size: large;margin-bottom: -10px;">schedule</mat-icon>  Scheduled &nbsp;</span>
<span ><mat-icon  [ngStyle]="{'color':'#800020'}"  style="font-size: large;margin-bottom: -10px;">history</mat-icon> Exceeded  &nbsp;</span>

<span>

</span>
</mat-card>