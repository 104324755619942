<div fxLayout="column" fxFill class="custom-investment-of">
    <mat-card fxFill>
      <mat-card-header class="headerbox">
        <div fxFlex="100"  fxLayout="row">
          <span  (click)="Back()">
              <button mat-icon-button [tabIndex]="1" (keyup.enter)="Back()" matTooltip="Back"> <mat-icon>arrow_back</mat-icon></button>
             </span>
        <mat-card-title class="title">Pay Group Details</mat-card-title>
      </div>
      </mat-card-header>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="custom-card div-pad">
                <div fxLayout="column" fxFlex="100" class="custom-payroll-div">
                    <form [formGroup]="paygroupRequestForm" autocomplete="off">
                        <div fxLayout="row" fxLayoutAlign="end">
                           <mat-form-field appearance="outline" fxFlex="28" fxFlex.lt-lg="48" fxFlex.xs="100">
                                <mat-label>Pay Group</mat-label>
                                <mat-select formControlName="paygroupcomponent" [tabIndex]="2" >
                                  <ng-container>
                                    <div *ngFor="let b of paygroups">
                                      <mat-option [value]="b.id">{{b.group_name}}</mat-option>
                                    </div>

                                </ng-container>
                               </mat-select>
                            </mat-form-field>
                        </div>
                      </form>
                     <div fxLayout="column">
                      <div class="mat-elevation-z1 example-container">
                      <mat-table  [dataSource]="dataSource"   multiTemplateDataRows style="width:100%;display:table;">
                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                          <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Name</th>
                          <td  mat-cell  *matCellDef="let element" class="custom-investment-td"> {{element.component_name}} </td>
                        </ng-container>

                        <!-- Earning Type Name Column -->
                        <ng-container matColumnDef="Earning_Type">
                          <th   mat-header-cell *matHeaderCellDef class="custom-investment-th">  Type </th>
                          <td mat-cell *matCellDef="let element" class="custom-investment-td"> {{element.section}} </td>
                        </ng-container>

                        <!-- Calculation Type Column -->
                        <ng-container matColumnDef="Calculation_Type">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Calculation Type </th>
                            <td  mat-cell *matCellDef="let element" class="custom-investment-td">{{element.calculation_type}}
                            </td>
                          </ng-container>
                        <!-- Consider for EPF Column -->
                        <ng-container matColumnDef="Consider_for_EPF">
                          <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Consider for EPF </th>
                          <td  mat-cell *matCellDef="let element" class="custom-investment-td">{{element.epf?'Yes':'No'}}
                          </td>
                        </ng-container>
                        <!-- Consider for ESI Column -->
                        <ng-container matColumnDef="Consider_for_ESI">
                          <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Consider for ESI </th>
                          <td  mat-cell *matCellDef="let element" class="custom-investment-td">{{element.esi?'Yes':'No'}}
                          </td>
                        </ng-container>

                        <!-- Status Column -->
                         <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th"> Status </th>
                            <td  mat-cell *matCellDef="let element" class="custom-investment-td">{{element.status}}
                            </td>
                          </ng-container>
                          <!-- Action Column -->
                          <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef class="custom-investment-th">  </th>
                            <td  mat-cell *matCellDef="let element;let i =index;" class="custom-investment-td">
                              <!-- <mat-slide-toggle color="primary">
                            </mat-slide-toggle> -->
                            <button mat-icon-button title="Edit" [tabindex]="[i+3]" (keyup.enter)="edit(element,isadd)" *ngIf="element.status == 'Active' && element.section == 'Earnings' && isadd">
                              <mat-icon (click)="edit(element,isadd)" style="cursor: pointer;" title="Edit" *ngIf="element.status == 'Active' && element.section == 'Earnings'">edit</mat-icon>
                            </button>
                            <button mat-icon-button title="Configure" [tabindex]="[i+3]" (keyup.enter)="edit(element,isadd)" *ngIf="element.status == 'To Be Configured' && element.section == 'Earnings' && isadd">

                            <mat-icon (click)="edit(element,isadd)" style="cursor: pointer;" title="Configure" *ngIf="element.status == 'To Be Configured' && element.section == 'Earnings'">add_circle</mat-icon>
                            </button>
                            <button mat-icon-button title="Configure" [tabindex]="[i+3]" (keyup.enter)="edit(element,isadd)" *ngIf="isview">

                              <mat-icon (click)="edit(element,isadd)" style="cursor: pointer;" title="View" *ngIf="isview">visibility</mat-icon>
                              </button>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                              <div class="example-element-detail" [@detailExpand]="
                                  element == expandedElement ? 'collapsed' : 'collapsed'
                                ">
                                <div class="table-expanded-description" style="margin: auto;">
                                  <div class="table-responsive custom-investment-expand-of">
                                    <form [formGroup]="earningsRequestForm" autocomplete="off">
                                      <p>
                                          <mat-form-field appearance="outline" style="width: 100%;">
                                              <mat-label>Pay Group</mat-label>
                                              <mat-select formControlName="payGroup">

                                                  <mat-option value="Grade 1">Grade 1</mat-option>
                                                  <mat-option value="Grade 2">Grade 2</mat-option>
                                                  <mat-option value="Grade 3">Grade 3</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                      </p>
                                      <p>
                                          <mat-form-field appearance="outline" style="width: 100%;">
                                              <mat-label>Component Type*</mat-label>
                                              <mat-select formControlName="componentType">
                                                  <mat-option value="Component 1">Component 1</mat-option>
                                                  <mat-option value="Component 2">Component 2</mat-option>
                                                  <mat-option value="Component 3">Component 3</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                          <mat-form-field appearance="outline" style="width: 100%;">
                                              <mat-label>Name in Pay Slip*</mat-label>
                                              <input type="text" matInput class="form-control" formControlName="namePaySlip" placeholder="" >
                                          </mat-form-field>
                                      </p>
                                      <p>
                                          <label>Calculate Type</label>
                                      </p>
                                      <p>
                                              <mat-radio-group formControlName="monthly_salary" style="display: grid;">
                                                  <mat-radio-button class="mr-2"  color="primary" *ngFor="let halfs of arrayValue;index as i;" (change)="status(halfs.Value)"  [value]="halfs.Value"  >{{halfs.name}}</mat-radio-button>
                                              </mat-radio-group>
                                      </p>
                                      <p *ngIf="isShowCalculatedAmount == 'Flat Amount'">
                                          <mat-form-field appearance="outline" style="width: 100%;">
                                              <mat-label>Flat Amount</mat-label>
                                              <input type="text" matInput class="form-control" formControlName="flat_amount" placeholder="" >
                                          </mat-form-field>
                                       </p>
                                       <p *ngIf="isShowCalculatedAmount == 'basicPercentage'">
                                          <mat-form-field appearance="outline" style="width: 100%;">
                                              <mat-label>Percentage of Basic</mat-label>
                                              <input type="text" matInput class="form-control" formControlName="basic_percentage" noLeadingWhitespace >
                                          </mat-form-field>
                                       </p>

                                   <p>
                                      <mat-form-field appearance="outline" style="width: 100%;">
                                          <mat-label>Status</mat-label>
                                          <mat-select formControlName="status">
                                              <mat-option value="0">Active</mat-option>
                                              <mat-option value="1">Inactive</mat-option>
                                          </mat-select>

                                      </mat-form-field>
                                   </p>

                                       <div fxLayoutAlign="center">
                                          <button class="mx-1 mat-stroked-button button-save"  style="background-color:#3f51b5;color:aliceblue" (click)="setPayGroup()"   type="submit">
                                              <span>Submit</span>
                                          </button>

                                          <button class="mx-1  mat-stroked-button button-clear" style="background-color:#550534;color:aliceblue" (click)="cancel()">
                                              <span >Cancel</span>
                                          </button>
                                          <br />
                                      </div>
                                  </form>
                                  </div>
                                  </div>
                              </div>
                            </td>
                          </ng-container>

                        <tr mat-header-row  class="dashboard-1 custom-investment-header" *matHeaderRowDef="displayedColumns;"></tr>


                        <tr mat-row
                        *matRowDef="let row; columns: displayedColumns;"   [class.example-expanded-row]="expandedElement == row" (click)="
                        expandedElement = expandedElement == row ? null : row
                      " class="example-element-row"></tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                      <!-- <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row> -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7"style="text-align: center;">No data found</td>
                      </tr> 
                      </mat-table>
                      <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]" ></mat-paginator>
              
                  </div>
                   </div>
                 </div>
        
        </div>
    </mat-card>
</div>
