<!-- <button mat-icon-button class="close-button"  [mat-dialog-close]="true" style="float: right">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->
<!--<div class="mat-dialog-header">-->
    <div fxLayout="row" fxLayoutAlign="center">
        <h1 mat-dialog-title >{{data.name}}</h1>

      </div>

      <!--</div>-->
      <!--<div mat-dialog-content>-->

      <form [formGroup]="form" autocomplete="off">
        <div mat-dialog-content style="overflow: hidden">
            <div  fxLayout="initial" fxLayout.xs="column"  fxLayoutAlign.xs="center center">
               
              
              <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                <mat-label>Request Exit Date </mat-label>
                <input matInput readonly  [matDatepicker]="date" [min]="mindate"  [max]="maxdate"
                    placeholder="DD-MM-YYYY" formControlName="requestdate" [disabled]="true">
                 <mat-datepicker-toggle class="datepicker" matSuffix [for]="date">
                </mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>



                <mat-form-field fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Approve Exit Date </mat-label>
                    <input matInput readonly (click)="date1.open()"  [matDatepicker]="date1" [min]="mindate"  [max]="maxdate"
                        placeholder="DD-MM-YYYY" formControlName="approvedate">

                     <mat-datepicker-toggle class="datepicker" matSuffix [for]="date1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #date1></mat-datepicker>
                </mat-form-field>
              </div>
              <!--<div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">-->

              <!--</div>-->

          <div  fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
            <mat-form-field  fxFlex fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
              <mat-label>Reason</mat-label>
              <textarea formControlName="reason"  matInput placeholder="Reason" maxlength="400" required></textarea>
              <mat-error class="con-error" *ngIf="form.controls.reason.errors?.required">
              This field is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div mat-dialog-actions fxLayoutAlign="center">
          <button class="btn btn-primary mr-2 mb-2" (click)="onOkClick()" type="submit">
            <span>Submit</span>
          </button>

          <button class="btn btn-danger mr-2 mb-2"  (click)="onNoClick()">
            <span >Cancel</span>
          </button>

        </div>


      </form>

