import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AttendanceRoutingModule } from './attendance-routing.module';
import { ApprovalAttendanceComponent } from './pages/approval-attendance/approval-attendance.component';
import { EmployeDashboardComponent } from './pages/employe-dashboard/employe-dashboard.component';
import { ManagerDashboardComponent } from './pages/manager-dashboard/manager-dashboard.component';
import { MaterialModule } from '../../material/material.module';
import { AttendanceUploadexcelComponent } from './pages/attendance-uploadexcel/attendance-uploadexcel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogComponent } from './dialog/dialog.component';
import { ApprovalAttendanceListComponent } from './pages/approval-attendance-list/approval-attendance-list.component';
import { AttendanceRequestBehalfComponent } from './pages/attendance-request-behalf/attendance-request-behalf.component';
import { AttendanceRequestComponent } from './pages/attendance-request/attendance-request.component';
import { ShiftConfigureComponent } from './pages/shift-configure/shift-configure.component';
import { ApprovalHistoryComponent } from './pages/approval-history/approval-history.component';
import { ShiftChangeRequestComponent } from './pages/shift-change-request/shift-change-request.component';
import { ShiftChangeApproveComponent } from './pages/shift-change-approve/shift-change-approve.component';
import { WeekoffConfigureComponent } from './pages/weekoff-configure/weekoff-configure.component';
import { ShitConfigureHistoryDialogComponent } from './pages/shit-configure-history-dialog/shit-configure-history-dialog.component';
import { WeekoffHistoryDialogComponent } from './pages/weekoff-history-dialog/weekoff-history-dialog.component';
import { HrAttendanceRegularizationComponent } from './pages/hr-attendance-regularization/hr-attendance-regularization.component';
import { EmployeeAttendanceRegularaizationComponent } from './pages/employee-attendance-regularaization/employee-attendance-regularaization.component';
import { RegularaizationApprovalComponent } from './pages/regularaization-approval/regularaization-approval.component';
import { ManagerAttendanceRegularizationComponent } from './pages/manager-attendance-regularization/manager-attendance-regularization.component';
import { RotationalShiftConfigurationComponent } from './pages/rotational-shift-configuration/rotational-shift-configuration.component';
import { RotationalShiftAssignComponent } from './pages/rotational-shift-assign/rotational-shift-assign.component';
import { PermissionsRequestComponent } from './pages/permissions-request/permissions-request.component';
import { PermissionsApproveComponent } from './pages/permissions-approve/permissions-approve.component';
import { PermissionHistoryComponent } from './pages/permission-history/permission-history.component';
import { RotationalShiftReassignComponent } from './pages/rotational-shift-reassign/rotational-shift-reassign.component';
import { HalfdayDialogboxComponent } from './models/halfday-dialogbox/halfday-dialogbox.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HrRegularizationComponent } from './pages/hr-regularization/hr-regularization.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    ApprovalAttendanceListComponent,
    ApprovalAttendanceComponent,
    AttendanceRequestComponent,
    AttendanceRequestBehalfComponent,
    EmployeDashboardComponent,
    ManagerDashboardComponent,
    AttendanceUploadexcelComponent,
    DialogComponent,
    ShiftConfigureComponent,
    ApprovalHistoryComponent,
    ShiftChangeRequestComponent,
    ShiftChangeApproveComponent,
    WeekoffConfigureComponent,
    ShitConfigureHistoryDialogComponent,
    WeekoffHistoryDialogComponent,
    HrAttendanceRegularizationComponent,
    EmployeeAttendanceRegularaizationComponent,
    RegularaizationApprovalComponent,
    ManagerAttendanceRegularizationComponent,
    RotationalShiftConfigurationComponent,
    RotationalShiftAssignComponent,
    PermissionsRequestComponent,
    PermissionsApproveComponent,
    PermissionHistoryComponent,
    RotationalShiftReassignComponent,
    HalfdayDialogboxComponent,
    HrRegularizationComponent,
  ],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot({
      // showMaskTyped : true,
      // clearIfNotMatch : true
    }),
    SharedModule
  ],
  providers: [DatePipe]
})
export class AttendanceModule { }
