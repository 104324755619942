<div fxLayout="column" class="first-col">
    <form [formGroup]="mappingForm" autocomplete="off">
        <mat-card>
            <mat-card-header class="headerbox">
                <mat-card-title class="title">Department-Designation Mapping</mat-card-title>
            </mat-card-header>
            <div fxLayout="row" class="dev-Pad" fxLayout.xs="column">
                <mat-form-field fxFlex.xs="100" fxFlex="31.8" class="mx-2 my-2" appearance="outline">
                    <mat-label>Department</mat-label>
                    <mat-select required formControlName="department">
                        <input class="search-align" type="text" [formControl]="searchControl" placeholder="search"
                            matInput>
                        <ng-container *ngFor="let b of filteredDepartments">
                            <mat-option [value]="b.id" (click)="resetDesignations()">{{ b.deptname }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf="
                    mappingForm.controls.department.errors?.required">
                        This field is required.
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="31.8" fxFlex.xs="100" class="mx-2 my-2" appearance="outline">
                    <mat-label>Designations </mat-label>
                    <mat-select placeholder="" name="companylocation" formControlName="designation" required multiple
                        #DesignationSelect>
                        <button mat-raised-button class="mat-primary fill text-sm"
                            (click)="departmentSelectAll(DesignationSelect, availableDesignations, mappingForm.designation)"
                            *ngIf="!designationIshide">Select All
                        </button>
                        <button mat-raised-button class="mat-accent fill text-sm"
                            (click)="departmentDeselectAll(DesignationSelect)" *ngIf="designationIshide">
                            Deselect All
                        </button>
                        <input matInput [formControl]="searchControlfordesig" type="text" placeholder="  Search"
                            class="search-align">
                        <ng-container *ngFor="let res of filteredDesignations">
                            <mat-option [value]="res" (click)="onchangedepartment(res)">{{ res.designation
                                }}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error class="con-error" *ngIf=" mappingForm.controls.department.errors?.required">
                        Please select an option.
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="center">
                <button class="btn btn-primary mr-2 mb-2" (click)="setMapping()" type="submit">
                    <span>Submit</span>
                </button>

                <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
            <div fxLayout="column" class="table-pad">
                <div class="mat-elevation-z1 example-container">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ i + 1 + paginator.pageIndex * paginator.pageSize }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="department">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Department
                            </th>
                            <td mat-cell *matCellDef="let row"> {{row.department}} </td>
                        </ng-container>
                        <ng-container matColumnDef="designations">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Designations
                            </th>
                            <td mat-cell *matCellDef="let row"> {{row.designations}} </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4" style="text-align: center">
                                No data found
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>

                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                </div>
            </div>
        </mat-card>

    </form>

</div>