<div fxLayout="column">

  <mat-card-header>
    <mat-card-title> Onboarding Pending Checklist </mat-card-title>
  </mat-card-header>
  <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
    <form [formGroup]="checklistForm" autocomplete="off">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
        <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
          <mat-label>Employee Name</mat-label>
          <input formControlName="name" matInput placeholder="" readonly>
        </mat-form-field>

        <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
          <mat-label>Joining Date</mat-label>
          <input type="text" readonly formControlName="joinDate" matInput placeholder=""/>
        </mat-form-field>

        <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
          <mat-label>Designation</mat-label>
          <input formControlName="designation" readonly matInput placeholder="Designation">
        </mat-form-field>

      </div>

      <div style="margin-left: 2%;">
        <p formArrayName="selectedChecklist" *ngFor="let order of checklistsFormArray.controls; let i = index">
          <input type="checkbox" [formControlName]="i">
          {{checklistPoints[i].checklist_name}}
      </p>
        </div>
      <div fxLayoutAlign="center">
        <button class="m-1 mat-stroked-button button-save" (click)="saveRequest()" type="submit">
          <span>Save</span>
        </button>
        <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
          <span>Cancel</span>
        </button>
        <br />
      </div>
    </form>
  </div>

  

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center" *ngIf="isdata">
      <form [formGroup]="hrOnboardingForm" autocomplete="off" >
      <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
        <mat-label>Joining Date</mat-label>
        <input matInput readonly [matDatepicker]="searchDate" [min]="minDate" [max]="maxDate"
          placeholder="Joining Date" formControlName="searchDate" (click)="searchDate.open()">
        <mat-datepicker-toggle class="datepicker" matSuffix [for]="searchDate"></mat-datepicker-toggle>
        <mat-datepicker #searchDate></mat-datepicker>
      </mat-form-field>
    </form>
      <!-- <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select formControlName="statusUpdate" required>
              <ng-container>
                <div *ngFor="let b of visibleList">
                  <mat-option value={{b}}>{{b}}</mat-option>
                </div>
              </ng-container>
            </mat-select>
            <mat-error class="reg-error" *ngIf="hrOnboardingForm.controls.statusUpdate.hasError('required')">
              Please select assign type
            </mat-error>
          </mat-form-field> -->

      <mat-form-field fxFlex.xs="100" fxFlex="28" fxFlex.lt-lg="30" class="mx-2 my-2" appearance="outline">
        <mat-label>Search Employee</mat-label>
        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
      </mat-form-field>
      <span fxLayoutAlign="center" style="margin-top: 17px;">
        <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
         <span>Clear</span>
       </button>
     </span>

    </div>
    <div class="tableP" *ngIf="isdata">
      <div class="mat-elevation-z1">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S.No</th>
            <td mat-cell *matCellDef="let element; let i=index;">
              {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
          </ng-container>


          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Employee Name</th>
            <td mat-cell *matCellDef="let element"> {{element.empname}} </td>
          </ng-container>

          <ng-container matColumnDef="hiredate">
            <th mat-header-cell *matHeaderCellDef> Hired Date </th>
            <td mat-cell *matCellDef="let element"> {{element.hiredon | date:'dd-MM-yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="joindate">
            <th mat-header-cell *matHeaderCellDef> Joining Date </th>
            <td mat-cell *matCellDef="let element"> {{element.dateofjoin | date:'dd-MM-yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.final_status == 'Completed' ">
                Completed
              </span>
              <span *ngIf="element.final_status == 'Pending Checklist' ">
                Pending
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button matTooltip="Edit" *ngIf="row.final_status != 'Completed' "
               (click)="addChecklistOverview(row)">
                <mat-icon>edit</mat-icon>
              </button>
              <mat-icon title="Completed" *ngIf="row.final_status == 'Completed' ">edit</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" style="text-align: center;">No data found</td>
        </tr>
        </table>
        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
      </div>
    </div>
 
</div>