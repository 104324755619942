<div fxLayout="column" style="min-height: 700px;">
    <form [formGroup]="vendorDetailsForm" autocomplete="off">
        <mat-card fxLayout="column">
            <mat-card-header fxLayoutAlign="space-between center">
                <mat-card-title>Vendor Details</mat-card-title>
                <div style="margin-right: 3%;">
                    <span class="add-buttton" (click)="Add()">
                        <mat-icon style="font-size: 36px;">add_circle_outline</mat-icon>
                    </span>
                 </div>
            </mat-card-header>
       <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
       
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                             
                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Company Name</mat-label>
                    <input type="text" formControlName="companyName" required  matInput placeholder="Company Name" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.companyName.hasError('required')">
                       Please enter company name
                    </mat-error>
                  </mat-form-field>
                
                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Contact Number</mat-label>
                    <input type="text" formControlName="mobileCtrl" required matInput placeholder="Contact Number" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.mobileCtrl.hasError('required')">
                        Please enter 10 digits contact number
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="text" formControlName="emailCtrl" required  matInput placeholder="Email" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.emailCtrl.hasError('required')">
                       Please enter valid email
                    </mat-error>
                  </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                           
                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>GSTIN No</mat-label>
                    <input type="text" formControlName="gstCtrl"  matInput placeholder="GSTIN No" autocompleteOff>
                  </mat-form-field>
                
                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Type of service</mat-label>
                    <mat-select formControlName="typeOfServiceCtrl" multiple required>
                        <mat-select-trigger>
                          {{this.vendorDetailsForm.controls.typeOfServiceCtrl.value?.[0] || ''}}
                          <span *ngIf="(this.vendorDetailsForm.controls.typeOfServiceCtrl.value?.length || 0) > 1" class="example-additional-selection">
                            (+{{(this.vendorDetailsForm.controls.typeOfServiceCtrl.value?.length || 0) - 1}} {{this.vendorDetailsForm.controls.typeOfServiceCtrl.value?.length === 2 ? 'other' : 'others'}})
                          </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let list of servicesList" [value]="list">{{list}}</mat-option>
                      </mat-select>
                      <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.typeOfServiceCtrl.hasError('required')">
                        Please select type of service
                     </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" class="mx-2 my-2" appearance="outline">
                    <mat-label>Address</mat-label>
                    <input type="text" formControlName="addressCtrl" required matInput placeholder="Address" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.addressCtrl.hasError('required')">
                        Please enter address
                     </mat-error>
                  </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
               
              
                  <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>Country</mat-label>
                    <mat-select  formControlName="countryCtrl" required>
                        <ng-container>
                            <div *ngFor="let b of countryDetails">
                                <mat-option value={{b.id}}>{{b.country}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.countryCtrl.hasError('required')">
                        Please select country
                     </mat-error>
                </mat-form-field>
          
                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="stateCtrl" required>
                        <ng-container>
                            <div *ngFor="let b of stateDetails">
                               <mat-option value={{b.id}}>{{b.state}}</mat-option>
                            </div>
                       </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.stateCtrl.hasError('required')">
                        Please select state
                     </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex="50" class="mx-2 my-2" appearance="outline">
                    <mat-label>City</mat-label>
                    <mat-select  formControlName="cityCtrl" required placeholder="City">
                        <ng-container>
                            <div *ngFor="let b of cityDetails">
                                <mat-option value={{b.id}}>{{b.location}}</mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.stateCtrl.hasError('required')">
                        Please select city
                     </mat-error>
                </mat-form-field>
             </div>

             <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
             
                    <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Pincode</mat-label>
                    <input type="text" formControlName="pincodeCtrl" required matInput placeholder="Pincode" autocompleteOff>
                    <mat-error class="reg-error" *ngIf="vendorDetailsForm.controls.pincodeCtrl.hasError('required')">
                        Please enter pincode
                     </mat-error> 
                </mat-form-field>
                
                  <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea formControlName="descriptionCtrl" matInput placeholder="Description" maxlength="100"></textarea>
                   </mat-form-field>
             </div>

            <div fxLayoutAlign="center">
                <button *ngIf="!isRequestView && !isEditView" class="m-1 mat-stroked-button button-save"
                    (click)="saveRequest()" type="submit">
                    <span>Submit</span>
                </button>
                <button *ngIf="isEditView" class="m-1 mat-stroked-button button-save" (click)="updateRequest()"
                    type="submit">
                    <span>Update</span>
                </button>
                <button class="m-1 mat-stroked-button button-clear" (click)="cancel()">
                    <span>Cancel</span>
                </button>
                <br />
            </div>
        </div>
        </mat-card>

    </form> 
    <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="end">
        <mat-form-field fxFlex.xs="100%" appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div fxLayout="column" class="tableP" *ngIf="isdata">
    
        <div class="mat-elevation-z8">
           
            <table mat-table  [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No</th>
                    <td mat-cell *matCellDef="let row ;let i=index"> {{(i+1)}}</td>
                </ng-container>
            
                <ng-container matColumnDef="cname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
                    <td mat-cell *matCellDef="let row"> {{row.name}}</td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let row"> {{row.email}}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                    <td mat-cell *matCellDef="let row"> {{row.type}}</td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
                    <td mat-cell *matCellDef="let row"> {{row.address}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon  class="mr-2" title="view" (click)="requestView(row)">
                            visibility</mat-icon>
                        <mat-icon  class="mr-2" title="Edit" (click)="editRequest(row)">
                            edit</mat-icon>
                        <mat-icon class="mr-2" title="Delete"
                            (click)="deleteRequest(row)">delete</mat-icon>
                    </td>
                </ng-container>

                <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No data available</td>
                </tr>
            </table>
          
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        
        </div>
    </div>




</div>