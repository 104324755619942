


<div fxLayout="column"> 
  <div fxLayout="row wrap" style="width: auto;" fxLayoutAlign="space-between center" >
    <div fxLayoutAlign="start">
        <h3 style="margin: 0px;padding-top: 2%;"> <b><span>{{month}}{{'-'}}{{year}}  </span>&nbsp;- Employee List&nbsp;</b></h3>
      
    </div>

   <div fxLayoutAlign="end">
        <span>
            <button mat-icon-button>
        <mat-icon class="close-icon" color="warn" matTooltip="Close"  [mat-dialog-close]="data.NO" >cancel</mat-icon>
    </button> 
    </span>
    </div>

  </div>
        
  <div fxLayoutAlign="start" >
    <form [formGroup]="stateForm" >
      <mat-form-field appearance="outline" fxFlex class="mx-2 my-2" *ngIf="isPtView ">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <ng-container>
            <div *ngFor="let b of ptStateslist">
              <mat-option value="{{ b.id }}" >{{ b.state }}</mat-option>
            </div>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </form>
    <span>
      <button *ngIf="isPtView" mat-icon-button tabindex="5" (keyup.enter)="ptReportExportPDF()" style="margin-top: 10px;">
        <a title="PDF">
            <img (click)="ptReportExportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
       </button>
    </span>

  </div>

   <div fxLayout="row">

    <span  *ngIf="isEsiView" fxLayout="row" >
      <form [formGroup]="stateForm" >
        <mat-form-field appearance="outline" fxFlex class="mx-2 my-2">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <ng-container>
              <div *ngFor="let b of esiStateslist">
                <mat-option value="{{ b.state_id }}" >{{
                  b.state
                  }}</mat-option>
              </div>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </form>
          
      <button mat-icon-button  (keyup.enter)="esiExportAsXLSX()" style="margin-top: 6px;" >
        <a title="Excel">
            <img (click)="esiExportAsXLSX()" src="./assets/images/icons/excel.png" width="20px" height="20px" /></a>
       </button>
    </span>


      <button *ngIf="isPaygroupView" mat-icon-button tabindex="5" (keyup.enter)="paygroupExportPDF()">
        <a title="PDF">
            <img (click)="paygroupExportPDF()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
       </button>

       <button *ngIf="isEpfView"  mat-icon-button tabindex="5" (keyup.enter)="epfReportExportText()">
        <a title="Text">
            <img (click)="epfReportExportText()" src="./assets/images/icons/pdf.png" width="20px" height="20px" /></a>
       </button>
       
    </div>
  
</div>



<div style="padding-top: 10px;">

<div style="height: 400px">

   <!-- ESI report -->
  <div *ngIf="isEsiView" id="table" #table=''>
    <table id="table" mat-table [dataSource]="esiDataSource" matSort>
        <!-- <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef > S.No</th>
          <td mat-cell *matCellDef="let element; let i=index;">
           {{ (i+1) }} </td>
        </ng-container> -->
        <ng-container matColumnDef="ESI">
          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> ESI No </th>
          <td mat-cell *matCellDef="let row" >
            <span style="font-size: smaller;" *ngIf="row.ESI == 'Grand Total'"><b>{{row.ESI}}</b> </span>
            <span style="font-size: smaller;" *ngIf="row.ESI != 'Grand Total'">{{row.ESI}} </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Employee_Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;">Name </th>
          <td mat-cell *matCellDef="let row" ><span >{{row.Employee_Name}} </span></td>
        </ng-container>
        <ng-container matColumnDef="paid_days">
      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;" >Paid Days </th>
      <td mat-cell *matCellDef="let row" >
        <span *ngIf="row.ESI != 'Grand Total'">{{row.lopcount==null?0:row.lopcount}} </span>
        <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.lopcount==null?0:row.lopcount}}</b> </span>
      </td>
        </ng-container>
        <ng-container matColumnDef="Gross_Salary">
          <th mat-header-cell *matHeaderCellDef  >Gross Salary </th>
          <td mat-cell *matCellDef="let row" ><span *ngIf="row.ESI != 'Grand Total'">{{row.Gross_Salary}} </span>
            <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.Gross_Salary}}</b> </span>
          </td>
            </ng-container>
          <ng-container matColumnDef="employee_esi_value">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Employee</th>
              <td mat-cell *matCellDef="let row" >
                <span *ngIf="row.ESI != 'Grand Total'" >{{row.employee_esi_value}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.employee_esi_value}}</b></span>
              </td>
          </ng-container>
          <ng-container matColumnDef="employer_esi_value">
                  <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Employer</th>
                  <td mat-cell *matCellDef="let row" >
                    <span *ngIf="row.ESI != 'Grand Total'">{{row.employer_esi_value}} </span>
                    <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.employer_esi_value}}</b></span>
                  </td>
          </ng-container>
          <ng-container matColumnDef="total_contribution">
              <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Total</th>
              <td mat-cell *matCellDef="let row" >
                <span *ngIf="row.ESI != 'Grand Total'">{{row.total_contribution}} </span>
                <span *ngIf="row.ESI == 'Grand Total'"><b>{{row.total_contribution}}</b></span>
              </td>
          </ng-container>
         
        <tr  mat-header-row *matHeaderRowDef="esiDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: esiDisplayedColumns;"></tr>
       
    </table>
  </div>

  <!-- paygroup list -->
    <div *ngIf="isPaygroupView">
 
        <div id="table" #table="">
            <table mat-table [dataSource]="paygroupDataSource" matSort>
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No </th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  {{ (i + 1) }} </td>
            </ng-container>
      
                <ng-container matColumnDef="empid">
                  <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Emp ID</th>
                  <td mat-cell *matCellDef="let row" ><span >{{row.employeeId}} </span></td>
                </ng-container>
  
                <ng-container matColumnDef="empname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Emp Name </th>
                  <td mat-cell *matCellDef="let row" ><span >{{row.employeeName}} </span></td>
                </ng-container>
  
                <ng-container matColumnDef="amount">
                   <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;" >Amount </th>
                   <td mat-cell *matCellDef="let row" ><span >{{row.Amount}} </span></td>
                </ng-container>
  
                <ng-container matColumnDef="accountnumber">
                  <th mat-header-cell *matHeaderCellDef  >Account Number </th>
                  <td mat-cell *matCellDef="let row" ><span >{{row.accountNumber}} </span></td>
                </ng-container>
  
                  <ng-container matColumnDef="bank">
                      <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Bank</th>
                      <td mat-cell *matCellDef="let row" ><span >{{row.bankName}} </span></td>
                  </ng-container>
  
                  <ng-container matColumnDef="ifsc">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">IFSC</th>
                          <td mat-cell *matCellDef="let row" ><span >{{row.ifscCode}} </span></td>
                  </ng-container>
  
                <tr  mat-header-row *matHeaderRowDef="paygroupDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paygroupDisplayedColumns;"></tr>
            </table>
        </div>
   </div>

   <!-- professional tax -->
   <div *ngIf="isPtView" id="table" #table=''>
    <table id="table" mat-table [dataSource]="ptDataSource" matSort>

      <ng-container matColumnDef="Employee_Id">
        <th mat-header-cell *matHeaderCellDef> Employee Id</th>
        <td mat-cell *matCellDef="let row">
          <span  *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Employee_Id}}</b> </span>
          <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Employee_Id}} </span>

        </td>
      </ng-container>
      <ng-container matColumnDef="Employee_Name">
        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> Employee Name</th>
        <td mat-cell *matCellDef="let row">
         
          <span  *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Employee_Name}}</b> </span>
          <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Employee_Name}} </span>

          </td>
      </ng-container>
      <ng-container matColumnDef="Gross_Salary">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Gross Salary</th>
        <td mat-cell *matCellDef="let row">
          <span  *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Gross_Salary}}</b> </span>
          <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Gross_Salary}} </span>
          
        </td>
      </ng-container>
      <ng-container matColumnDef="Professionam_Tax">
        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Professional Tax </th>
        <td mat-cell *matCellDef="let row">
        
          <span  *ngIf="row.Employee_Id == 'Grand Total'"><b>{{row.Professionam_Tax}}</b> </span>
          <span *ngIf="row.Employee_Id != 'Grand Total'">{{row.Professionam_Tax}} </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="ptreportDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: ptreportDisplayedColumns;"></tr>
    </table>
  </div>

  <!-- epf report -->
  <div *ngIf="isEpfView" >
    <div id="table" #table=''>
      <table id="table" mat-table [dataSource]="epfDataSource" matSort>
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef > S.No</th>
            <td mat-cell *matCellDef="let element; let i=index;">
             {{ (i+1)}} </td>
          </ng-container>
          <ng-container matColumnDef="uan">
            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;"> UAN No </th>
            <td mat-cell *matCellDef="let row" ><span >{{row.UAN}} </span></td>
          </ng-container>
          <ng-container matColumnDef="empname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> Employee Name </th>
            <td mat-cell *matCellDef="let row" ><span >{{row.Employee_Name}} </span></td>
          </ng-container>
          <ng-container matColumnDef="gross">
        <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;" >Gross </th>
        <td mat-cell *matCellDef="let row" ><span >{{row.gross_salary}} </span></td>
          </ng-container>
          <ng-container matColumnDef="epf">
            <th mat-header-cell *matHeaderCellDef  >EPF </th>
            <td mat-cell *matCellDef="let row" ><span >{{row.employee_epf_value}} </span></td>
              </ng-container>
            <ng-container matColumnDef="eps">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">EPS</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.eps_wage}} </span></td>
            </ng-container>
            <ng-container matColumnDef="edil">
                    <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">EDLI</th>
                    <td mat-cell *matCellDef="let row" ><span >{{row.edli_wage}} </span></td>
            </ng-container>
            <ng-container matColumnDef="epsvalue">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">EPS</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.employer_eps_value}} </span></td>
        </ng-container>
            

            <ng-container matColumnDef="ee">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">EE</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.employee_epf_value}} </span></td>
            </ng-container>
            <ng-container matColumnDef='er'>
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">ER</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.epf_eps_difference}} </span></td>
            </ng-container>
            <ng-container matColumnDef="ncp">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">NCP</th>
                <td mat-cell *matCellDef="let row" ><span >{{row.ncp_days==null?0:row.ncp_days}}</span></td>
            </ng-container>
            <ng-container matColumnDef="refunds">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Refunds</th>
                <td mat-cell *matCellDef="let row" ><span >0 </span></td>
            </ng-container>
          <tr  mat-header-row *matHeaderRowDef="epfDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: epfDisplayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
          </tr>
      </table>
   </div>
      

    </div>
   <br>
   
</div>
</div>


