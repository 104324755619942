
    <div fxLayout="column" class="first-col">
        <mat-card>
          <mat-card-header class="headerbox">
              <mat-card-title class="title">Employee Activity Report</mat-card-title>
            </mat-card-header>
           <mat-card-content>
            <div fxLayout="column" >
              <div fxLayout="column" fxLayout.lt-lg="column">
                <div   fxLayout="column" fxLayout.lt-lg="column" fxLayoutAlign.xs="center" class="dev-Pad" [formGroup]="searchForm">
                  <div fxLayout="row" fxflex="100" fxLayout.xs="column" fxLayout.lt-lg="row" fxFlex.xs="50%" *ngIf="is_super_admin">
                    <mat-form-field  fxFlex="50" fxFlex.xs="100"  class="mx-2 my-2" appearance="outline">
                      <mat-label>City-Branch </mat-label>
                      <mat-select placeholder="" name="companylocation" formControlName="companylocation" required
                          multiple #locationSelect>
                          <button mat-raised-button class="mat-primary  text-sm"
                              (click)="locationSelectAll(locationSelect, worklocationDetails, searchForm.companylocation)"
                              *ngIf="!locationIshide">Select All
                          </button>
                          <button mat-raised-button class="mat-accent  text-sm"
                              (click)="locationDeselectAll(locationSelect)" *ngIf="locationIshide">
                              Deselect All
                          </button>
                          <mat-option *ngFor="let res of worklocationDetails" [value]="res"
                              (click)="onchangeLocation(res)">
                              {{res.cityname}}-{{res.location}}
                          </mat-option>
                      </mat-select>
                      <mat-error class="con-error" *ngIf="searchForm.controls.companylocation.errors?.required">
                          Please select option
                      </mat-error>
                   </mat-form-field>
                   
                   <mat-form-field   fxFlex="50" fxFlex.xs="100"  class="mx-2 my-2" appearance="outline">
                      <mat-label>Manager</mat-label>
                      <mat-select placeholder="" name="managers" formControlName="managers" required multiple
                          #managerSelect>
                          <button mat-raised-button class="mat-primary  text-sm"
                              (click)="managerSelectAll(managerSelect, managersDetails, searchForm.managers)"
                              *ngIf="!mgrIshide">Select All
                          </button>
                          <button mat-raised-button class="mat-accent  text-sm"
                              (click)="managerDeselectAll(managerSelect)" *ngIf="mgrIshide">
                              Deselect All
                          </button>
                          <mat-option *ngFor="let res of managersDetails" [value]="res" (click)="onchangeManager(res)">
                              {{res.manager_name}}
                          </mat-option>
                      </mat-select>
                      <mat-error class="con-error" *ngIf="searchForm.controls.managers.errors?.required">
                          Please select option
                      </mat-error>
                    </mat-form-field>
                 
                  </div>
                    <div fxLayout="row" fxflex="100" fxLayout.xs="column" fxLayout.lt-lg="row wrap" fxFlex.xs="100%" fxLayoutAlign="space-between">
                      <div fxLayoutAlign="space-between">
                        <mat-form-field   fxFlex.lt-lg="48"  class="mx-2 my-2" appearance="outline">
                          <mat-label>Select Employee</mat-label>
                          <mat-select formControlName="Users" required tabIndex="3">
      
                            <mat-option *ngIf="employeelist && employeelist.length" value="0"> All Employees </mat-option>
                            <ng-container *ngFor="let e of employeelist">
                              <mat-option [value]="e.empid">{{e.empname}} </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field  class="mx-2 my-2" appearance="outline">
                          <mat-label>From Date</mat-label>
                          <input matInput readonly [matDatepicker]="fromDate" [min]="minDate" [max]="maxDate" placeholder="From Date"
                            formControlName="fromDate" (click)="fromDate.open()" tabIndex="1">
                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="fromDate"></mat-datepicker-toggle>
                          <mat-datepicker #fromDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field   class="mx-2 my-2" appearance="outline">
                          <mat-label>To Date</mat-label>
                          <input matInput readonly [matDatepicker]="toDate" placeholder="To Date" 
                          [min]="minToDate" [max]="maxDate" formControlName="toDate" (click)="toDate.open()" tabIndex="2">
                          <mat-datepicker-toggle class="datepicker" matSuffix [for]="toDate"></mat-datepicker-toggle>
                          <mat-datepicker #toDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                      
                  </div>
                  <div fxLayout="row"  fxLayoutAlign="center">
                    <div fxflex="40" fxLayout="row" fxLayoutAlign="center">
                      <span style="margin-left: auto;">
                        <button  [tabIndex]="4" class="btn btn-primary mr-2 mb-2" (click)="Searchform()"> Search </button>
                        <span class="line" style="color:#1898D5;font-size:16px"></span>
                        <button  [tabIndex]="5" class="btn btn-danger mr-2 mb-2" (click)="resetform()">  <span>Clear</span> </button>
                      </span>
                    </div>
                  </div>
                  <div fxflex="30" fxLayout="row xs-column" fxLayoutAlign="end"  style="margin-right: 3%;">
                    <div *ngIf="List && List.length > 0">   
                        <button mat-icon-button>
                          <a title="PDF" [tabIndex]="6" (keyup.enter)="exportPDF()"> 
                            <img (click)="exportPDF()" src="./assets/images/icons/pdf.png"  width="20px" height="20px">
                          </a>
                        </button>
                        <span class="exlprint"> | </span>
                        <button mat-icon-button>
                          <a title="Excel" [tabIndex]="7" (keyup.enter)="exportAsXLSX()"> 
                            <img (click)="exportAsXLSX()" src="./assets/images/icons/excel.png">
                          </a>
                        </button>
                      </div> 
                      <div fxLayoutAlign="end">
                        <mat-form-field  appearance="outline" >
                          <mat-label>Search</mat-label>
                          <input (keyup)="applyFilter($event)" matInput placeholder="Search" [tabIndex]="4" #input>
                          <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        </mat-form-field>
                      </div> 
                  </div>
                </div>
                  
         
    
                  <div  fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1 example-container">
                      <div id="table" #table=''>
                      <table   mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="sno">
                          <th mat-header-cell *matHeaderCellDef style="text-align: right;">S.No</th>
                          <td mat-cell *matCellDef="let element; let i=index;" style="text-align: right;">
                              {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                      </ng-container>
                        <ng-container matColumnDef="emp_name">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: center;"> Name</th>
                          <td mat-cell *matCellDef="let row;">{{row.emp_name}}</td>
                
                        </ng-container>
                        <ng-container matColumnDef="date_selected">
                          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Date</th>
                          <td mat-cell *matCellDef="let row" style="white-space: nowrap;text-align: center;"> {{row.date_selected | date:'dd-MM-yyyy'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="firtlogin">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: center;">Punch In</th>
                          <td mat-cell *matCellDef="let row" style="white-space: nowrap;text-align: center;"> {{getFormattedTime(row.firstlogin)}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastlogout">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: center;">Punch Out</th>
                          <td mat-cell *matCellDef="let row" style="white-space: nowrap;text-align: center;"> {{getFormattedTime(row.lastlogout)}} </td>
                
                        </ng-container>
                        <ng-container matColumnDef="activity_total">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: right;">Total Visits</th>
                          <td mat-cell *matCellDef="let row"  style="text-align: right;"> {{row.activity_total}} </td>
                        </ng-container>
                        <ng-container matColumnDef="route_type">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: center;">Type</th>
                          <td mat-cell *matCellDef="let row"  style="text-align: center;"> <span>{{ transform(row.route_type) }} </span> </td>
                        </ng-container>
                        <ng-container matColumnDef="distance_km">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: right;">KMS Traveled</th>
                          <td mat-cell *matCellDef="let row"  style="text-align: right;"> {{ getDistance_km(row.distance_km) }} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="recompute" *ngIf="is_super_admin">           
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">
                            <div fxLayout="row" fxLayoutAlign="end">Recompute</div>
                          </th>
                         
                          <td mat-cell *matCellDef="let row">
                            <div fxLayout="row" fxLayoutAlign="end" *ngIf="row.re_compute == 1">
                              <span><a [routerLink]="[]" (click)="recalculate(row)" style="color:#00A9E0 !important">Recompute</a></span>
                            </div>
                          </td> 
                        </ng-container>
                        <ng-container matColumnDef="routes">
                          <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;text-align: center;"></th>
                          <td mat-cell *matCellDef="let row"  style="text-align: center;">    
                            <span *ngIf="row.distance_km > 0" style="color:#00A9E0 !important"> <button mat-icon-button style="padding-top: 5px;"> <a  (click)="displayRoutes(row)"><mat-icon>visibility</mat-icon></a> </button></span>
                           </td>
                        </ng-container>
                        <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="10" style="text-align: center;" >No data found</td>
                        </tr>
                
                      </table>
                      </div>
                      <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                  </div>
              </div>
            </div>
          </mat-card-content>
      </mat-card>
    </div>
      
