
<div fxLayout="column">
    <form [formGroup]="requestform" autocomplete="off">
<div style="width: auto;"> 
    <div fxLayoutAlign="space-between center">
        <div fxLayoutAlign="end">
        <h3> <b>Shift History&nbsp;</b></h3>
    </div>
<div>
            <button mat-icon-button>
        <mat-icon class="close-icon" color="warn" matTooltip="Close"  [mat-dialog-close]="data.NO" >cancel</mat-icon>
    </button> 
</div>
    </div>
    <div fxLayout="column">
        <p><b>ID:</b>&nbsp;<span>{{empCode}}</span></p>
        <p><b>Name:</b>&nbsp;<span>{{empName}}</span></p>
</div>
</div>

<div style="padding-top: 10px;">

<div style="height: 400px">
    <div class="example-container mat-elevation-z1">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno" >
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i=index;" >
                  {{ (i+1) }} </td>
              </ng-container>

            <ng-container matColumnDef="shift">
                <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Shift</th>
                <td mat-cell *matCellDef="let row">{{row.shiftname}}</td>
            </ng-container>

            <ng-container matColumnDef="fromdate">
                <th mat-header-cell *matHeaderCellDef> From  </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.fromdate | date:'dd-MM-yyyy'}} </td>
            </ng-container>


            <ng-container matColumnDef="todate">
                <th mat-header-cell *matHeaderCellDef > To  </th>
                <td mat-cell *matCellDef="let row" style="white-space: nowrap;"> {{row.todate | date:'dd-MM-yyyy'}} </td>
            </ng-container>
          
            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
              </tr>
        </table>
    </div>
   
</div>
</div>
</form>
</div>


