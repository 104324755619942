<div fxLayout="column" class="first-col">
    <mat-card class="mat-elevation-z0">

        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title"> Clients </mat-card-title>
            <div style="margin-right: 2%;" *ngIf="isdata">
                <button mat-icon-button style="padding-right: 40px;" (click)="Add()" matTooltip="Add New">
                    <mat-icon style="font-size: 30px;overflow: visible;">add_circle</mat-icon>
                </button>
            </div>
        </mat-card-header>
        <form [formGroup]="clientMasterForm" autocomplete="off">
            <div fxLayout="column" fxLayout.xs="column" *ngIf="isAdd">
                <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%">

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Company Name</mat-label>
                        <input formControlName="companyName" matInput placeholder="" appRemoveSpace required
                            maxlength="64">
                        <mat-error class="con-error"
                            *ngIf="clientMasterForm.controls.companyName.errors?.required ||clientMasterForm.controls.companyName.errors?.['whitespace']">
                            {{msgEM1}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Industry Type</mat-label>
                        <mat-select formControlName="IndustryType" required>
                            <ng-container>
                                <div *ngFor="let b of industryTypeList">
                                    <mat-option [value]="b.id">{{b.industry_type_name}}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="reg-error"
                            *ngIf="clientMasterForm.controls.IndustryType.hasError('required')">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input type="text" onlyAlphabets formControlName="contactName" matInput maxlength="50"
                            required />
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.contactName.hasError('required')">
                            This field is required.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="row">

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input type="text" onlyNumber formControlName="mobile" matInput maxlength="10" minlength="10"
                            (keypress)="stopLeadingZero($event)" required />
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.mobile.hasError('required')">
                            This field is required.
                        </mat-error>
                        <mat-error class="con-error" *ngIf="clientMasterForm.get('mobile').hasError('pattern')">
                            Please enter valid mobile number
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Company Email</mat-label>
                        <input type="text" formControlName="email" required matInput />

                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Address1</mat-label>
                        <input formControlName="address1" matInput placeholder="" appRemoveSpace required
                            maxlength="64">
                        <mat-error class="con-error"
                            *ngIf="clientMasterForm.controls.address1.errors?.required ||clientMasterForm.controls.address1.errors?.['whitespace']">
                            {{msgEM1}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.xs="row" style="padding-top: 1%">

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Address 2</mat-label>
                        <input formControlName="address2" matInput placeholder="" appRemoveSpace required
                            maxlength="64">
                        <mat-error class="con-error"
                            *ngIf="clientMasterForm.controls.address2.errors?.required ||clientMasterForm.controls.address2.errors?.['whitespace']">
                            {{msgEM1}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select formControlName="country" required>
                            <ng-container>
                                <div *ngFor="let b of countryDetails">
                                    <mat-option [value]="b.id">{{ b.country }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.country.errors?.required">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="state" required>
                            <ng-container>
                                <div *ngFor="let b of stateDetails">
                                    <mat-option [value]="b.id">{{ b.state }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.state.errors?.required">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>



                </div>
                <div fxLayout="row" fxLayout.xs="row">

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>City</mat-label>
                        <mat-select formControlName="city" required>
                            <ng-container>
                                <div *ngFor="let b of cityDetails">
                                    <mat-option [value]="b.id">{{ b.location }}</mat-option>
                                </div>
                            </ng-container>
                        </mat-select>
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.city.errors?.required">
                            Please select an option.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>Pincode</mat-label>
                        <input type="text" formControlName="pincode" matInput maxlength="6" minlength="6"
                        onlyNumber required />
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.pincode.errors?.required">
                            This field is required.
                        </mat-error>
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.pincode.errors?.minlength">
                            Pincode should be 6 digits.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                        <mat-label>GST Number</mat-label>
                        <input type="text" formControlName="gstNumber" matInput (paste)="(false)" maxlength="15"
                            minlength="6" alphaNumericOnly />
                        <mat-error class="reg-error" *ngIf="clientMasterForm.controls.gstNumber.errors?.minlength">
                            GST Number should be minimum 6 digits.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center">
                    <button class="btn btn-primary mr-2 mb-2" (click)="saveClient()" type="submit">
                        <span>Submit</span>
                    </button>

                    <button class="btn btn-danger mr-2 mb-2" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                    <br />
                </div>


            </div>
        </form>
        <!-- <mat-divider></mat-divider> -->
        <div fxLayout="column" *ngIf="isdata">
            <div fxLayout="row" fxLayoutAlign.xs="center" class="dev-Pad" fxLayoutAlign="end">
                <span style="height: 70px;" fxFlex="50" fxFlex.xs="100" fxLayoutAlign="end">
                    <mat-form-field fxFlex.xs="100" fxFlex="56" fxFlex.lt-lg="100" class="mx-2 my-2"
                        appearance="outline">
                        <mat-icon matSuffix style="color: #28acaf;">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input (keyup)="applyFilter($event)" matInput placeholder="" maxlength="100">
                    </mat-form-field>
                </span>
            </div>
            <form [formGroup]="clientMasterForm" autocomplete="off">
                <div fxLayout="column" class="table-pad">
                    <div class="mat-elevation-z1  example-container">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef>S.No</th>
                                <td mat-cell *matCellDef="let element; let i=index;">
                                    {{ (i+1) + (paginator.pageIndex * paginator.pageSize) }} </td>
                            </ng-container>

                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name</th>
                                <td mat-cell *matCellDef="let row"> {{row.company_name}} </td>

                            </ng-container>

                            <ng-container matColumnDef="contactName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Person</th>
                                <td mat-cell *matCellDef="let row"> {{row.contact_name}} </td>

                            </ng-container>

                            <ng-container matColumnDef="mobile">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
                                <td mat-cell *matCellDef="let row"> {{row.mobile_number}} </td>

                            </ng-container>

                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
                                <td mat-cell *matCellDef="let row"> {{row.city}} </td>

                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 60px"> Status
                                </th>
                                <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                                    <mat-radio-group>
                                        <mat-radio-button class="mr-2" color="primary"
                                            *ngFor="let halfs of arrayValue;index as i;"
                                            (change)="status(halfs)"
                                            [checked]="row.status"
                                            [value]="halfs.id">{{halfs.value}}</mat-radio-button>
                                    </mat-radio-group>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                    <button mat-icon-button *ngIf="enable != row.id" (keyup.enter)="edit($event,row)">
                                        <mat-icon (click)="edit($event,row)" title="Edit">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="updateClient($event,row.id,clientMasterForm.controls.department.value,row)">
                                        <mat-icon
                                            (click)="updateClient($event,row.id,clientMasterForm.controls.department.value,row)"
                                            title="Save">save</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="enable == row.id"
                                        (keyup.enter)="canceledit($event,row.id)">
                                        <mat-icon (click)="canceledit($event,row.id)" title="Cancel">cancel</mat-icon>
                                    </button>
                                </td>
                            </ng-container>


                            <tr class="headerbox title" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="3" style="text-align: center;">No data found</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="!pageLoading ? getPageSizes() : [5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </form>
        </div>


    </mat-card>
</div>