import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { MatTableDataSource } from "@angular/material/table";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { PayrollService } from '../../payroll.service';
import { Moment } from 'moment';
// import * as jsPDF from 'jspdf';
import * as _moment from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import {default as _rollupMoment} from 'moment';
const moment =  _moment;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-componet-wise-payroll-report',
  templateUrl: './componet-wise-payroll-report.component.html',
  styleUrls: ['./componet-wise-payroll-report.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ComponetWisePayrollReportComponent implements OnInit {
  date:any;
  statehide:boolean = false;
  tablecolumns: any
  datapdf: any;
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  searchForm!: UntypedFormGroup;
  displayedColumns: string[] = [];
  dataSource : MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  pageLoading = true;
  arrayList:any=[];
  pipe = new DatePipe('en-US');
  userSession:any;
  employeeDetails: any;
  year:any;
  max:any;
  minDate:any= new Date('2022-01-01');
  months=[{id:0,month:'Jan'},{id:1,month:'Feb'},{id:2,month:'Mar'},{id:3,month:'Apr'},{id:4,month:'May'},{id:5,month:'Jun'},{id:6,month:'Jul'},{id:7,month:'Aug'},{id:8,month:'Sep'},{id:9,month:'Oct'},{id:10,month:'Nov'},{id:11,month:'Dec'}]
  monthdata: any;
  stateslist:any=[{state_id:null,state:"ALL"}];
  headerColumns: any = [];
  constructor(private router: Router,public formBuilder: UntypedFormBuilder,private PR:PayrollService,public spinner :NgxSpinnerService,private RS:ReportsService) {
    this.displayedColumns = this.displayedColumns.filter(column => column !== 'actions');
 
   }
  @ViewChild('table') table!: ElementRef;

  ngOnInit(): void {
  this.date = new UntypedFormControl(moment().subtract(1, "month"))
    let prevDate = new Date();
    prevDate.setDate(0)
    this.max = prevDate;
    this.searchForm = this.formBuilder.group({
      fromDate:[prevDate],
      state:[""]
    });
   
    this.getComponentWisePayrollReports();

  }
  exportAsXLSX() {
    
    this.year=this.searchForm.controls.fromDate.value.getFullYear();
      for(let i =0;i<this.months.length;i++){
      if((this.searchForm.controls.fromDate.value).getMonth()==this.months[i].id){
       this.monthdata = this.months[i].month;
       break;
      }
    }
    // var ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet('Payroll_report_for_financeteam_');
    var ws:XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById('table'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'payroll_detailreport');

    /* save to file */
    // XLSX.writeFile('Payroll_report_for_financeteam_'+this.monthdata,'Payroll_report_for_financeteam_'+this.monthdata+'_'+this.year+'.xlsx')
    XLSX.writeFile(wb, 'payroll_detailreport'+this.monthdata+'_'+this.year+'.xlsx');

  }

  Searchform(){
    let data ={
      date:this.pipe.transform( this.date.value._d, 'yyyy-MM-dd')
    }
    // this.spinner.show();
    this.getComponentWisePayrollReports()
   

  }
  resetform(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(["/Payroll/componentWisePayrollReport"]));
    }
    getPageSizes(): number[] {
      var customPageSizeArray = [];
      
        if (this.dataSource.data.length > 5) {
          customPageSizeArray.push(5);
        }
        if (this.dataSource.data.length > 10) {
          customPageSizeArray.push(10);
        }
        if (this.dataSource.data.length > 20) {
          customPageSizeArray.push(20);
         
        }
        customPageSizeArray.push(this.dataSource.data.length);
        return customPageSizeArray;
   }

  public exportPDF1(): void {
    const pdfTable = this.table.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    pdfMake.createPdf({
      info: {
        title: "payroll Detail Report",
        author:'Sreeb tech',
        subject:'Theme',
            keywords:'Report'
      },
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      },
      content: [
        {
          text: "payroll Detail Report\n\n",
          style: 'header',
          alignment: 'center',
          fontSize: 12
        },
        // {
        //   text:
        //     "Designation :  " + this.designationForPdf +"\n" +
        //     "Employee Name and Id:  " + this.employeeNameForPdf + "\n" +
        //     "Year:  " + this.searchForm.controls.calenderYear.value+ "\n",
        //   fontSize: 10,
        //   margin: [0, 0, 0, 20],
        //   alignment: 'left'
        // },
        html
      ],
      pageOrientation:'landscape'
    }).download("payroll Detail Report.pdf");

  }

  public exportPDF():void{
    this.PR.generatePDF(this.tablecolumns, this.datapdf, 'payroll Detail Report');
  }

  getComponentWisePayrollReports(){
    let data ={
      year_value:this.date.value._d.getFullYear(),
      month_value:this.date.value._d.getMonth()+1,
      // state:this.searchForm.controls.state.value==''?null:Number(this.searchForm.controls.state.value)
    }
    this.dataSource=new MatTableDataSource();
    this.PR.getComponentWiseSalaryDetails(data).subscribe((result:any)=>{
      if(result.status){
        let datas :any = []
        for (let i = 0 ;i<result.data.length;i++){
          if(i>0){
            datas.push(result.data[i])
          }
        }
        this.datapdf = result.data
        this.tablecolumns = Object.keys(result.data[1]);
        this.displayedColumns = Object.keys(result.data[1]);
        this.displayedColumns.unshift('S.No');
        this.displayedColumns = this.displayedColumns.slice(0,-2);
        this.displayedColumns.push('Pay Slip');
        this.dataSource = new MatTableDataSource(datas);
        this.dataSource.paginator = this.paginator;
          // this.dataSource.paginator.pageSize=5;
          this.dataSource.sort = this.sort;
          this.pageLoading = false;
      }  
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
    }
  }
  onViewClick(element:any){
    let data = {
      'id' : element.NetSalaryId,
      'empid' : element.EmpId,
      'superadmin':true
    }

    this.router.navigate(["/Payroll/PaySlipsView"],{state: {userData:data}}); 
    }
}
