import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-checkin-out-summary-report-dialog',
  templateUrl: './checkin-out-summary-report-dialog.component.html',
  styleUrls: ['./checkin-out-summary-report-dialog.component.scss']
})
export class CheckinOutSummaryReportDialogComponent implements OnInit {
  empId: any;
  empName: any;
  present: any;
  absent: any;
  total: any;
  dataSource: MatTableDataSource<any> = <any>[];
  List: any = [];
  displayedColumns: any = ['sno', 'date', 'intime', 'outtime', 'totalHours', 'breaks', 'breakTime', 'productiveHours', 'otHours', 'permissionHours'];
  constructor() { }

  ngOnInit(): void {
  }

}
