import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { BankWiseSalaryDialogComponent } from '../bank-wise-salary-dialog/bank-wise-salary-dialog.component';
import { PayrollService } from '../../payroll.service';
import { Router } from '@angular/router';
import { ReusableDialogComponent } from 'src/app/pages/reusable-dialog/reusable-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import {default as _rollupMoment} from 'moment';
const moment = _moment;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-bank-wise-salary-summary',
  templateUrl: './bank-wise-salary-summary.component.html',
  styleUrls: ['./bank-wise-salary-summary.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BankWiseSalarySummaryComponent implements OnInit {

  summaryForm: any = UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, public dialog: MatDialog, private payrollService: PayrollService,
    private router: Router) { }
  displayedColumns: string[] = ['sno', 'year', 'category', 'bank', 'paygroup', 'amount', 'action'];
  displayedColumns2: string[] = ['sno', 'empid', 'empname', 'amount', 'accountnumber', 'bank', 'ifsc'];
  dataSource: MatTableDataSource<any> = <any>[];
  dataSource2: MatTableDataSource<any> = <any>[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatPaginator) paginator2!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('table') table!: ElementRef;
  pageLoading = true;
  viewdata: any
  date = new UntypedFormControl(moment());
  minDate: any;
  pipe = new DatePipe('en-US');
  max = new Date()
  maxDate: any = new Date();
  setMonthAndYear3(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    this.getPayrollBankPaymentsMappingSearch();
    datepicker.close();
  }
  banksList: any = []
  paygroupList: any = []
  payList: boolean = false;
  selectedEpfList: any = [];
  selectedPayList: any = [];
  isDisable: boolean = true;
  totalPaygroupAmount: any;
  esiEpfPtGroupList: any = [];
  isAdd: boolean = false;
  isdata: boolean = true;
  searchDate!: UntypedFormControl;
  isTable: boolean = false;
  searchControl = new FormControl('');
  filteredBankList: any = [];
  ngOnInit(): void {
    this.summaryForm = this.formBuilder.group({
      fromDate: [''],
      searchDate: [''],
      bankName: [''],
      selectedChecklist: new UntypedFormArray([]),

    })
    this.getBankDetails();
    this.summaryForm.get('fromDate')?.valueChanges.subscribe((selectedValue: any) => {
      this.paygroupList = [];
      this.selectedEpfList = [];
      this.summaryForm.controls.selectedChecklist.clear();
      this.payList = false;
    });
    this.summaryForm.get('bankName')?.valueChanges.subscribe((selectedValue: any) => {
      this.paygroupList = [];
      this.selectedEpfList = [];
      this.summaryForm.controls.selectedChecklist.clear();
      this.payList = false;
    })

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterBankList(searchText)
    })
  }

  get paygroupFormArray() {
    return this.summaryForm.controls.selectedChecklist as UntypedFormArray;
  }


  private addCheckboxesToForm() {
    this.selectedEpfList = [];
    for (let i = 0; i < this.paygroupList.length; i++) {
      if (this.paygroupList[i].category_name == "ESI" && this.paygroupList[i].notprocessedcount == 0 && this.paygroupList[i].amount != null ||
        this.paygroupList[i].category_name == "EPF" && this.paygroupList[i].notprocessedcount == 0 && this.paygroupList[i].amount != null ||
        this.paygroupList[i].category_name == "PT" && this.paygroupList[i].notprocessedcount == 0 && this.paygroupList[i].amount != null) {
        this.paygroupFormArray.push(new FormControl({ value: true, disabled: this.isDisable }));

      } else if (this.paygroupList[i].notprocessedcount != 0) {
        this.paygroupFormArray.push(new FormControl({ value: false, disabled: this.isDisable }))
      }
      else {
        this.paygroupFormArray.push(new FormControl({ value: false, disabled: this.isDisable }))
      }
      if (this.paygroupList[i].paygroup_id == null && this.paygroupList[i].notprocessedcount == 0 && this.paygroupList[i].amount != null) {
        this.selectedEpfList.push(this.paygroupList[i]);
      }
    }
    //  this.paygroupList.forEach(() => this.paygroupFormArray.push(new FormControl({ value: false, disabled:  this.isDisable })));
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.viewdata = ctrlValue._d;
    this.summaryForm.controls.fromDate.setValue(ctrlValue);
  }

  getBankDetails() {
    this.banksList = [];
    this.payrollService.getBankAccounts({}).subscribe((result: any) => {
      this.banksList = result.data;
      this.filteredBankList = this.banksList;
    });
  }

  getPaygroupList() {
    if (this.summaryForm.valid) {
      this.paygroupList = [];
      this.summaryForm.controls.selectedChecklist.clear();
      let year = this.summaryForm.controls.fromDate.value._d.getFullYear();
      let month = this.summaryForm.controls.fromDate.value._d.getMonth() + 1;

      let data = {
        "year_value": year,
        "month_value": month,
        "bank_id_value": this.summaryForm.controls.bankName.value,
      }
      this.payrollService.getPayrollBankPaymentsMapping(data).subscribe((res: any) => {
        if (res.status && res.data.length != 0) {
          this.payList = true;
          this.paygroupList = res.data;
          this.paygroupList.forEach((e: any) => {
            if (e.paygroup_id == null && e.notprocessedcount != 0) {
              this.isDisable = true;

            } else if (e.paygroup_id != null && e.notprocessedcount != 0) {
              this.isDisable = true;
            }
            else {
              this.isDisable = false;
            }
          })
          this.addCheckboxesToForm();
        }
      })
    }

  }

  onSelect() {
    this.selectedPayList = [];
    let sum = 0;
    const selectedPaygroupIds = this.summaryForm.controls.selectedChecklist.value
      .map((checked: any, i: any) => checked ? this.paygroupList[i] : null)
      .filter((v: any) => v !== null);

    selectedPaygroupIds.forEach((e: any) => {
      if (e.paygroup_id != null) {
        this.selectedPayList.push(e)
      }
    });
    // add total
    for (const item of this.selectedPayList) {
      sum += item.amount;
    }
    this.totalPaygroupAmount = sum;
  }

  getPayrollBankPaymentsMappingSearch() {
    let data = {
      year_value: this.date.value._d.getFullYear(),
      month_value: this.date.value._d.getMonth() + 1
    }
    this.payrollService.getPayrollBankPaymentsMappingSearch(data).subscribe((res: any) => {
      if (res.status && res.data.length != 0) {

        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pageLoading = false;
      }
    })
  }

  search() {
    this.getPaygroupList();
  }
  clear() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(["/Payroll/BankwiseSalarySummary"]));
  }

  submit() {

    if (this.summaryForm.valid) {

      if (this.selectedEpfList.length > 0 || this.selectedPayList.length > 0) {

        let year = this.summaryForm.controls.fromDate.value._d.getFullYear();
        let month = this.summaryForm.controls.fromDate.value._d.getMonth() + 1;
        this.esiEpfPtGroupList = [];

        if (this.selectedEpfList.length > 0) {

          for (let i = 0; this.selectedEpfList.length > i; i++) {
            this.esiEpfPtGroupList.push({
              "bank_id": this.summaryForm.controls.bankName.value,
              "category": this.selectedEpfList[i].category_name,
              "month_value": month,
              "year_value": year,
              "paygroup_list": [],
              "amount": this.selectedEpfList[i].amount != null ? this.selectedEpfList[i].amount : 0
            })
          }
        }
        let payGroupArr: any = [];
        for (let i = 0; this.selectedPayList.length > i; i++) {
          payGroupArr.push(this.selectedPayList[i].paygroup_id);
        }
        let data = [{
          "bank_id": this.summaryForm.controls.bankName.value,
          "category": "SALARY",
          "month_value": month,
          "year_value": year,
          "paygroup_list": payGroupArr,
          "amount": this.totalPaygroupAmount
        }]
        let finalData;
        let esiEpfLength = this.esiEpfPtGroupList.length;
        let paygrpLength = payGroupArr.length;

        if (paygrpLength > 0 && esiEpfLength == 0) {

          finalData = data;

        } else if (esiEpfLength > 0 && paygrpLength == 0) {
          finalData = this.esiEpfPtGroupList;

        } else {

          finalData = data.concat(this.esiEpfPtGroupList);
        }

        let info = {
          "bank_payments": finalData
        }

        this.payrollService.setPayrollBankPaymentsMapping(info).subscribe((res: any) => {
          if (res.status) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(["/Payroll/BankwiseSalarySummary"]));
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Data submitted successfully"
            });
          } else {
            let dialogRef = this.dialog.open(ReusableDialogComponent, {
              position: { top: `70px` },
              disableClose: true,
              data: "Data is not submit"
            });
          }
        })

      } /** empty list */
      else {
        let dialogRef = this.dialog.open(ReusableDialogComponent, {
          position: { top: `70px` },
          disableClose: true,
          data: "All employees monthly salary report is not generated"
        });
      }
    }  /*** form validation */

  }
  cancel() {
    this.selectedPayList = [];
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    // this.router.navigate(["/Payroll/BankwiseSalarySummary"]));
  }
  Add() {
    this.isAdd = true;
    this.isdata = false;
  }

  /**Search functionality */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];

    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);

    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }


  esiView(data: any) {
    let givenData = {
      "year_value": this.date.value._d.getFullYear(),
      "month_value": this.date.value._d.getMonth() + 1,
      "paygroup_value": data.paygroup_list,
      "category": data.category
    }
    let dialogRef = this.dialog.open(BankWiseSalaryDialogComponent, {
      width: '800px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });
  }
  epfPrint(data: any) {
    let givenData = {
      "year_value": this.date.value._d.getFullYear(),
      "month_value": this.date.value._d.getMonth() + 1,
      "paygroup_value": data.paygroup_list,
      "category": data.category
    }
    let dialogRef = this.dialog.open(BankWiseSalaryDialogComponent, {
      width: data.category == 'EPF' ? '70%' : '800px', position: { top: `100px` },
      disableClose: true,
      data: givenData
    });
  }

  professionalTaxView(data: any) {
    let givenData = {
      "year_value": this.date.value._d.getFullYear(),
      "month_value": this.date.value._d.getMonth() + 1,
      "paygroup_value": data.paygroup_list,
      "category": data.category
    }
    let dialogRef = this.dialog.open(BankWiseSalaryDialogComponent, {
      width: '800px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });

  }

  viewPaygroup(data: any) {
    let givenData = {
      "year_value": this.date.value._d.getFullYear(),
      "month_value": this.date.value._d.getMonth() + 1,
      "paygroup_value": data.paygroup_list,
      "category": data.category
    }
    let dialogRef = this.dialog.open(BankWiseSalaryDialogComponent, {
      width: '800px', position: { top: `100px` },
      disableClose: true,
      data: givenData

    });

  }
  filterBankList(searchText: any) {
    this.filteredBankList = this.banksList.filter((val: any) =>
      val.bankname.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredBankList.length <= 0) {
      this.searchControl.setValue('');
    }
  }
}
