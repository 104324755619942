import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelServiceService } from '../../excel-service.service';
import { ReportsService } from '../../reports.service';
import * as XLSX from 'xlsx';
import { AttendanceService } from 'src/app/modules/attendance/attendance.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require("html-to-pdfmake");


import * as _moment from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-late-attendance-report',
  templateUrl: './late-attendance-report.component.html',
  styleUrls: ['./late-attendance-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LateAttendanceReportComponent implements OnInit {
  attendanceReportList: any[] = [
  ];
  employeelist: any;
  Users: any;
  minDate = new Date('2020/01/01'); maxDate = new Date();
  userSession: any;
  date = new Date();
  firstDay: any;
  lastDay: any;
  startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  dateDayArray: any = [];
  obj: any;
  @ViewChild('table') table!: ElementRef;
  shiftDataList: any;
  minFromDate: Date;
  maxFromDate: Date | null;
  minToDate: Date | null;
  maxToDate: Date;
  currentDate: Date = new Date();
  pageLoading = true;
  constructor(public reportsService: ReportsService, public datePipe: DatePipe, public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog, private excelService: ExcelServiceService, private attendanceService: AttendanceService) {
    this.minFromDate = new Date();
    this.minFromDate.setDate(this.currentDate.getDate() - 90);
    this.maxFromDate = new Date();
    this.minToDate = new Date();
    this.minToDate.setDate(this.currentDate.getDate() - 90);
    this.maxToDate = new Date();
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sorter!: MatSort;
  filter = new UntypedFormControl();
  searchControl = new FormControl('');
  searchForm = this.formBuilder.group({ fromDate: [new Date()], toDate: [new Date()], user: ['0'], shift: ['0'] });
  dataSource: MatTableDataSource<any> = <any>[];
  displayedColumns: string[] = ['sno', 'empid', 'empname', 'shift', 'fromdate',
    'todate', 'intime', 'latehours'];
  isLoading = false;
  filteredEmployeeList: any = [];
  ngOnInit() {
    this.userSession = JSON.parse(sessionStorage.getItem('user') ?? '');
    this.getEmployeelist();
    this.getActiveShiftIds();
    this.Searchform();
    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterEmployeeList(searchText);
    })
  }
  getActiveShiftIds() {
    this.attendanceService.getActiveShiftIds().subscribe((res) => {
      if (res.status) {
        this.shiftDataList = res.data;
        this.searchForm.controls.shift.setValue('0');
      }
    })
  }


  getEmployeelist() {
    let obj = {
      "rm_id": this.userSession.id,
    };

    this.reportsService.getTotalEmployeslistByManagerId(obj).subscribe((res: any) => {
      if (res.status) {
        this.employeelist = [];
        this.employeelist = res.data;
        this.filteredEmployeeList = this.employeelist;
        this.searchForm.controls.user.setValue('0');
      }

    });
  }
  //All Employees API
  Searchform() {
    this.attendanceReportList = [];
    this.dataSource = new MatTableDataSource(this.attendanceReportList);
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "y-MM-dd");
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "y-MM-dd");
    let userId = this.searchForm.controls.user.value == '0' ? null : this.searchForm.controls.user.value;
    let shift = this.searchForm.controls.shift.value == '0' ? null : this.searchForm.controls.shift.value;

    let data = {};
    data = {
      "manager_empid": this.userSession.id,
      'employee_id': userId,
      'shift_id': shift,
      'from_date': fromDate,
      'to_date': toDate
    }


    this.isLoading = true;
    this.reportsService.getEmployeeLateAttendanceReport(data).subscribe((res: any) => {
      this.attendanceReportList = [];
      if (res.status) {
        let i = 0;
        this.attendanceReportList = res.data
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.attendanceReportList);
        this.dataSource.paginator = this.paginator;
        this.pageLoading = false;
      }


    }, error => {
      this.isLoading = false;
      error.error.text
    });
  }




  resetform() {
    this.dataSource.data = [];
    this.searchForm.reset();
    this.searchForm.controls.fromDate.setValue(new Date());
    this.searchForm.controls.toDate.setValue(new Date());
    this.searchForm.controls.user.setValue('0');
    this.searchForm.controls.shift.setValue('0');
    this.Searchform();



  }


  exportAsXLSX() {
    let fromDate = this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-yyyy");
    let toDate = this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-yyyy");
    
    // Define the header row with report name, from date, and to date
    const headerRow = [
      { A: `Report Name: Late Attendance Report`, B: `From Date: ${fromDate}`, C: `To Date: ${toDate}` }
    ];

    // Format the data to match the required columns
    const exportData = this.attendanceReportList.map((row, index) => ({
      "S.No": index + 1,
      "Employee ID": row.empid,
      "Employee Name": row.empname,
      "Shift": row.shiftname,
      "Date": this.datePipe.transform(row.attendancedate, "dd-MM-yyyy"),
      "Shift InTime": row.shiftstarttime,
      "In Time": row.actual_in_time,
      "Late Hours": row.latehours,
   
    }));
  // Create a worksheet from the header row
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(headerRow, { skipHeader: true });

  // Append the data rows to the worksheet
  XLSX.utils.sheet_add_json(ws, exportData, { origin: 'A2', skipHeader: false });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Late_attendance_Report');
  
    /* save to file */
    XLSX.writeFile(wb, fromDate + '-' + toDate + '-' + 'Late_attendance_Report.xlsx');

  }

  fromDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minToDate = event.value;
    if (event.value !== null) {
      this.maxToDate = new Date(
        // event!.value.getFullYear(),
        // event!.value.getMonth(),
        // event!.value.getDate() + 30
      );
    }
  }

  toDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.maxFromDate = event.value;
    // if (event.value !== null) {
    //   this.minFromDate = new Date(
    //     event!.value.getFullYear(),
    //     event!.value.getMonth(),
    //     event!.value.getDate() - 30
    //   );
    // }
  }
  getPageSizes(): number[] {
    var customPageSizeArray = [];
    if (this.dataSource.data.length > 5) {
      customPageSizeArray.push(5);
    }
    if (this.dataSource.data.length > 10) {
      customPageSizeArray.push(10);
    }
    if (this.dataSource.data.length > 20) {
      customPageSizeArray.push(20);
    }
    customPageSizeArray.push(this.dataSource.data.length);
    return customPageSizeArray;
  }


  filterEmployeeList(searchText: any) {
    this.filteredEmployeeList = this.employeelist.filter((val: any) =>
      val.empname.toLowerCase().includes(searchText.toLowerCase()));
    if (this.filteredEmployeeList.length <= 0) {
      this.searchControl.setValue('');
    }
  }

  downloadPDF() {
    let fromdate = (this.datePipe.transform(this.searchForm.controls.fromDate.value, "dd-MM-YYYY"))
    let todate = (this.datePipe.transform(this.searchForm.controls.toDate.value, "dd-MM-YYYY"))
    let data = {
      'list': this.attendanceReportList,
      'report_date': fromdate +' '+'_'+' '+todate
    }
    this.reportsService.lateAttendanceReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'late_attendance_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}

