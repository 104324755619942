import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'app-shift-employees-dialog',
  templateUrl: './shift-employees-dialog.component.html',
  styleUrls: ['./shift-employees-dialog.component.scss']
})
export class ShiftEmployeesDialogComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'shift', 'employee', 'status'];
  dataSource: any = [];
  date: any;
  rmId: any;
  constructor(public dialogRef: MatDialogRef<ShiftEmployeesDialogComponent>,
    private reportService: ReportsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.date = data.date_value;
    this.rmId = data.rm_id_value;
    }
  employeesList: any = [];
  ngOnInit(): void {
    this.viewData();
  }

  viewData() {
    let dateValue = this.date.split("-").reverse().join("-");
    this.employeesList = [];
     let data = {
      "rm_id_value":this.rmId,
      "date_value":dateValue
     }
    this.reportService.getShiftsEmployeesDetailReportByDate(data).subscribe((res) => {
      if (res.status) {
        this.employeesList = res.data;
        this.dataSource = this.employeesList;
         } else {
           this.employeesList = [];
         }
    })
  }

  downloadPDF() {
    let data = {
      'list': this.employeesList,
      'report_date':this.date
    }
    this.reportService.getShiftsEmployeesDetailReportPdf(data).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'shift_wise_report.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
