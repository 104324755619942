<div fxLayout="column" class="first-col">
    <mat-card>
        <mat-card-header class="headerbox" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title class="title">Subscription </mat-card-title>
            <div style="padding-right: 40px">
                <!-- <img (click)="invoice()" matTooltip="Invoice" src="./assets/images/icons/invoice.png"
                    style="width: 35px; height:35px" />&nbsp; -->
                <img (click)="manageusers()" matTooltip="Manage Users" src="./assets/images/icons/manage users.png"
                    style="width: 35px; height: 35px" />&nbsp;
                <img (click)="pay()" matTooltip="Change Plan" src="./assets/images/icons/pay.png"
                    style="width: 35px; height: 40px" />
            </div>
        </mat-card-header>

        <form [formGroup]="subscriptionForm" autocomplete="off">
            <div fxFlex="column">
                <div class="dev-Pad">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Company Code</mat-label>
                            <input formControlName="companyCode" matInput [ngClass]="{'disable':true}" [readonly]="true" />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Your Plan</mat-label>
                            <input formControlName="plan" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" 
                        fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Cost Per User/Year</mat-label>
                            <input formControlName="monthlyCost" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Users</mat-label>
                            <input formControlName="takenUsers" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                      
                    </div>

                    <div class="example-container mat-elevation-z1">
                        <table mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="sno" >
                            <th mat-header-cell *matHeaderCellDef>S.No</th>
                            <td mat-cell *matCellDef="let element; let i=index;" >
                              {{ (i+1) }} </td>
                          </ng-container>
                          <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Paid Date </th>
                            <td mat-cell *matCellDef="let row">
                              <span>{{row.payment_date | date:'dd-MM-yyyy'}}
                              </span>
                            </td>
                          </ng-container>
              
                          <ng-container matColumnDef="users">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Users Count</th>
                            <td mat-cell *matCellDef="let row">
                              <span>{{row.user_count}} </span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Paid Amount</th>
                            <td mat-cell *matCellDef="let row">
                              <span>{{row.amount_paid}} </span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="fromdate">
                            <th mat-header-cell *matHeaderCellDef>Valid From</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                              <span>{{row.valid_from | date:'dd-MM-yyyy'}} </span>
                            </td>
                          </ng-container>
              
                          <ng-container matColumnDef="todate">
                            <th mat-header-cell *matHeaderCellDef style="white-space: nowrap;">Valid To</th>
                            <td mat-cell *matCellDef="let row" style="white-space: nowrap;">
                              <span>{{row.valid_to | date:'dd-MM-yyyy'}} </span>
                            </td>
                          </ng-container>
                                                  
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No data found</td>
                          </tr>
                        </table>
                       </div>
                  
                  

                    <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                     
                         <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Users</mat-label>
                            <input formControlName="takenUsers" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex="32" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Total Paid Amount</mat-label>
                            <input formControlName="totalPaidAmt" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>
                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Next Renewal Date</mat-label>
                            <input formControlName="nextRenewal" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                     
                        

                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Last Renewal Date</mat-label>
                            <input formControlName="lastRenewal" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                    </div> -->



                    <div class="section"><b>Billing Details</b></div>
                    <mat-divider></mat-divider>
                    <div class="section1"><b>Billing Contact</b></div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input type="text" formControlName="contactPerson" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Company Name</mat-label>
                            <input type="text" formControlName="companyName" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Mobile</mat-label>
                            <input type="text" formControlName="mobile" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input type="text" formControlName="email" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>Industry Type</mat-label>
                            <input type="text" formControlName="industryType" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>

                        <mat-form-field *ngIf="isOther" fxFlex.xs="100" fxFlex="33" class="mx-2 my-2" appearance="outline">
                            <mat-label>Industry Type Name</mat-label>
                            <input type="text" formControlName="industryTypeOther" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>
                    </div>

                    <div class="section1"><b>Billing Address</b></div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Address 1</mat-label>
                            <input type="text" formControlName="address1" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Address 2</mat-label>
                            <input type="text" formControlName="address2" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Country</mat-label>
                            <input type="text" formControlName="country" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>

                       
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>State</mat-label>
                            <input type="text" formControlName="state" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>
                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>City</mat-label>
                            <input type="text" formControlName="city" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>

                        <mat-form-field fxFlex.xs="100" fxFlex class="mx-2 my-2" appearance="outline">
                            <mat-label>Pincode</mat-label>
                            <input type="text" formControlName="pincode" matInput [ngClass]="{'disable':true}" readonly/>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="center">
                        <mat-form-field fxFlex.xs="100" fxFlex="32" class="mx-2 my-2" appearance="outline">
                            <mat-label>GST Number</mat-label>
                            <input type="text" formControlName="gstNumber" matInput [ngClass]="{'disable':true}" readonly />
                        </mat-form-field>
                    </div>

                    <!-- for cancel subscription-->

                    <!-- <div fxLayoutAlign="center">
                        <button class="btn btn-primary custom mr-2 mb-2" (click)="cancelSubscription()" >
                            Cancel subscription
                        </button>
                       
                    </div> -->
                </div>
            </div>
        </form>
    </mat-card>
</div>