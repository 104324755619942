<div  fxLayout="row"   style="margin-bottom: 5px;color: white;align-items: center;" class="clock-attendance">  
  <!-- <div fxFlex="5" fxLayoutAlign="start" (click)="backArrow()" >
      <button class="btn"  style="background-color: unset;color: white;text-align-last: start;margin-left: -10px;margin-bottom: -5px;" type="button">
          <mat-icon style="font-weight: bold" title="Back">arrow_back</mat-icon>
      </button>
  </div> -->
  <div   fxFlex="95" fxLayoutAlign="center" style="font-weight: bold">
          <mat-label> Snap a Photo </mat-label>
  </div>
</div>
<div>
  <div *ngIf="isCameraAvailable">
    <video #videoElement  autoplay ></video>
    <div style="font-size: x-small;"  *ngIf="location"><mat-card> <b>Location:</b>  {{location}}   </mat-card></div> 
    <form [formGroup]="noteForm" autocomplete="off">
      <div fxLayout="row" > 
        <span > 
          <mat-form-field fxFlex appearance="outline" *ngIf="notesRequired">
              <textarea minlength="10" rows="4" cols="40" formControlName="notes" matInput placeholder="Notes.." maxlength="250" required="notesRequired">
              </textarea>
              <mat-error class="con-error" *ngIf="noteForm.controls.notes.errors?.required">
              {{requiredField}}
              </mat-error>
              <mat-error class="con-error" *ngIf="noteForm.controls.notes.errors?.minlength">
                  Please enter minimum 10 chars.
              </mat-error>
          </mat-form-field>
          </span>
      </div>

      <div fxLayout="row"   appearance="outline" style="margin-top: 10%; min-width:fit-content; justify-content: center;align-items: center;" >
        <button type="submit"  class="btn btn-outline-primary custom mr-2 mb-2" (click)="close()"  placeholder="Close" style=" min-width: fit-content;"> 
          <mat-label>Close</mat-label>
        </button>
        <button type="submit" *ngIf="isVedioOpen" class="btn btn-outline-primary custom mr-2 mb-2" (click)="capturePhoto()"  placeholder="Capture Photo" style=" min-width: fit-content;"> 
          <mat-label>Capture Photo</mat-label>
        </button>
      </div>
    </form>
  </div>
    <div  *ngIf="!isCameraAvailable"> 
      <mat-label >Camera not available</mat-label>
      <div fxLayout="row" appearance="outline" style="margin-top: 10%; min-width:fit-content;align-self: center;">
        <button type="submit" class="btn btn-outline-primary custom mr-2 mb-2" (click)="close()"  placeholder="Close" style=" min-width: fit-content;"> 
          <mat-label>Close</mat-label>
        </button>
      </div>
    </div>
</div>